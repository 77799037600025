import {
    ColDef,
    ValueFormatterParams,
    ICellRendererParams,
    ValueGetterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Button, message, Modal, Form, InputNumber, Input, Tooltip } from 'antd';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import useFetch from '../../../utils/Hooks/useFetch';
import ReactPaginate from 'react-paginate';
import './index.less';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import { addMiniDocumentLink, getShareLinkInfo } from '../../../service/requests/ShareMeeting';
import { showError } from '../../RecommendationComponent/util';
import { buildWebPrefix, HostType } from '../../../service/config';
import { EventList, uploadEventTrack } from '../../../service/requests/EventTrack';

const PAGE_SIZE = 50;

export default function ShareLink() {
    const [curPage, setCurPage] = useState(0);
    const [fetchData, setParams, refreshData] = useFetch({
        interfaces: getShareLinkInfo,
        initialParams: { current_page: 1, page_size: PAGE_SIZE },
    });
    const [showLoading, setShowLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const pageCount = useMemo(() => {
        if (fetchData.data) {
            return Math.ceil(fetchData.data?.total_count / PAGE_SIZE);
        }
        return 0;
    }, [fetchData]);
    const getTotalLink = useCallback((id, channel) => {
        let url = buildWebPrefix(HostType.PORTAL);
        return url + '/order/jump?docid=' + id + '&channel=' + channel;
    }, []);

    const colDefs = useMemo<ColDef[]>(() => {
        return [
            {
                headerName: '小程序链接',
                width: 360,
                valueGetter: (params: ValueGetterParams) => {
                    return getTotalLink(params.data.docId, encodeURI(params.data.channelName));
                },
                cellRenderer: (params: ICellRendererParams) => {
                    return (
                        <Tooltip title={params.value} placement="topLeft">
                            <span>{params.value}</span>
                        </Tooltip>
                    );
                },
            },
            {
                headerName: '渠道',
                field: 'channelName',
                width: 120,
            },
            {
                headerName: '文章id',
                width: 100,
                field: 'docId',
            },
            {
                headerName: '创建时间',
                field: 'createdAt',
                flex: 1,
                valueFormatter: (params: ValueFormatterParams) => {
                    return moment(params.value).format('YYYY-MM-DD HH:mm:ss');
                },
            },
            {
                headerName: '操作',
                pinned: 'right',
                resizable: false,
                width: 120,
                cellRenderer: (params: ICellRendererParams) => {
                    return (
                        <Button
                            style={{ margin: 'auto' }}
                            type="primary"
                            onClick={() => {
                                uploadEventTrack(EventList.MakeMiniShareLinkCopy, 2, params.data);
                                if (
                                    copy(
                                        getTotalLink(
                                            params.data.docId,
                                            encodeURI(params.data.channelName)
                                        )
                                    )
                                ) {
                                    message.success('复制成功');
                                } else {
                                    message.error('复制失败');
                                }
                            }}
                        >
                            复制链接
                        </Button>
                    );
                },
            },
        ];
    }, [getTotalLink]);
    const onFinish = useCallback(
        (value) => {
            setShowLoading(true);
            addMiniDocumentLink(value)
                .then((res) => {
                    if (res) {
                        message.success('添加成功');
                        setVisible(false);
                        refreshData();
                    }
                }, showError)
                .finally(() => {
                    setShowLoading(false);
                });
        },
        [refreshData]
    );

    return (
        <>
            <Button
                type="primary"
                onClick={() => {
                    uploadEventTrack(EventList.MakeMiniShareLinkAdd, 2);
                    setVisible(true);
                }}
            >
                创建新链接
            </Button>
            <Modal
                title="创建新链接"
                destroyOnClose
                footer={null}
                visible={visible}
                onCancel={() => setVisible(false)}
                centered
            >
                <Form labelCol={{ span: 5 }} wrapperCol={{ span: 16 }} onFinish={onFinish}>
                    <Form.Item
                        label="渠道"
                        name="channel"
                        rules={[{ required: true, message: '请输入渠道' }]}
                    >
                        <Input min={0} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        label="文章ID"
                        name="doc_id"
                        rules={[{ required: true, message: '请输入文章ID' }]}
                    >
                        <InputNumber min={0} style={{ width: '100%' }} />
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button type="primary" htmlType="submit" loading={showLoading}>
                            确定
                        </Button>
                        <Button type="text" onClick={() => setVisible(false)}>
                            取消
                        </Button>
                    </div>
                </Form>
            </Modal>
            <div className="ag-theme-alpine" style={{ marginTop: '16px', width: '100%' }}>
                <AgGridReact
                    animateRows
                    columnDefs={colDefs}
                    defaultColDef={{ resizable: true, width: 160 }}
                    rowData={fetchData.data?.page_data ?? []}
                    domLayout="autoHeight"
                    enableCellTextSelection
                    ensureDomOrder
                />
                {pageCount > 0 && (
                    <ReactPaginate
                        className="pagination"
                        activeClassName="active"
                        previousLabel={<LeftOutlined style={{ fontSize: 12 }} />}
                        nextLabel={<RightOutlined style={{ fontSize: 12 }} />}
                        forcePage={curPage}
                        pageCount={pageCount}
                        onPageChange={(selectedItem) => {
                            setCurPage(selectedItem.selected);
                            setParams({
                                current_page: selectedItem.selected + 1,
                                page_size: PAGE_SIZE,
                            });
                        }}
                    />
                )}
            </div>
        </>
    );
}
