import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './../Quotation/BondsQuotation/BondsQuotation.module.scss';
import { Button, Modal, Pagination, Spin, message } from 'antd';
import moment from 'moment';
import { showError } from '../RecommendationComponent/util';
import exportXls, { ChangeProducPriceToExcelData } from '../../utils/Hooks/useExportExcel';
import { EventList, uploadEventTrack } from '../../service/requests/EventTrack';
import { useRequest } from 'ahooks';
import {
    ProductPriceListReq,
    addProductPrice,
    deleteProductPrice,
    getAccountAllProductPriceList,
    getAllProductPriceList,
    getProductPriceList,
    updateProductPrice,
} from './request';
import { Asset, AssetType, GinkgoTransFormRefType } from '../Transaction/type';
import ElnForm from '../Transaction/components/TransForm/Forms/ElnForm';
import FilterHeader from './FilterHeader';
import ProductsPriceTable from './ProductsPriceTable';
import BondFilter from './BondFilter';
import BondForm from './Form/Bond';
import { FormInstance } from 'antd/es/form/Form';
import AQDQForm from './Form/AQDQ';
import FCNForm from './Form/FCN';
import OptionForm from './Form/Option';
import FundFilter from './FundFilter';
import FundForm from './Form/Fund';

const PAGE_SIZE = 30;
export enum ModalType {
    Create = 'create',
    Edit = 'edit',
    Copy = 'copy',
}
interface GKProductsPriceProps {
    type: AssetType;
    tableHeight?: number;
    defaultSelectedKeys?: React.Key[];
    accountId?: string;
    onSelectionChange?: (BondQuoteList: Asset[]) => void;
}
const GKProductsPrice = ({
    type,
    tableHeight,
    defaultSelectedKeys,
    accountId,
    onSelectionChange,
}: GKProductsPriceProps) => {
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState<ModalType>();
    const [editAsset, setEditAsset] = useState<Asset>();
    const [curPage, setCurPage] = useState(1);
    const [filter, setFilter] = useState<Record<string, string | string[]>>();
    const formRef = useRef<GinkgoTransFormRefType>(null);
    const form2Ref = useRef<FormInstance>(null);
    const [formBtnLoading, setFormBtnLoading] = useState(false);
    const { data: quoteListData, params: quotationsParams, loading, run: refresh } = useRequest(
        onSelectionChange ? getAccountAllProductPriceList : getProductPriceList,
        {
            defaultParams: [
                {
                    current_page: 1,
                    account_id: accountId,
                    page_size: PAGE_SIZE,
                    asset_type_code: type,
                    ext_info: {
                        underlyings: [],
                        bank_ids: [],
                        update_time_type: onSelectionChange ? 1 : undefined,
                    },
                },
            ],
        }
    );
    const onFilterChange = useCallback(
        (item: any) => {
            setFilter((value) => {
                if (value || item) {
                    let newValue = { ...value, ...item, current_page: '1' };
                    return newValue;
                }
                return undefined;
            });
        },
        [setFilter]
    );
    useEffect(() => {
        if (filter) {
            if (filter.current_page && parseInt(filter.current_page.toString()) !== curPage) {
                setCurPage(parseInt(filter.current_page.toString()));
            }
            const newParam: ProductPriceListReq = {
                ...quotationsParams[0]!,
            };
            if (filter.current_page) {
                newParam.current_page = parseInt(filter.current_page as string);
                delete filter.current_page;
            }
            newParam.ext_info = { ...newParam.ext_info, ...filter };
            if (newParam.ext_info.sort_field && Array.isArray(newParam.ext_info.sort_field)) {
                newParam.ext_info.sort_field = newParam.ext_info.sort_field[1];
            }
            if (!filter.residual_maturity_from && !filter.residual_maturity_to) {
                delete newParam.ext_info.residual_maturity_from;
                delete newParam.ext_info.residual_maturity_to;
            }
            if (!filter.sort_field && !filter.sort_order) {
                delete newParam.ext_info.sort_field;
                delete newParam.ext_info.sort_order;
            }
            if (onSelectionChange) {
                newParam.current_page = 0;
            }
            refresh(newParam);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, refresh]);

    const onModalCancelClick = () => {
        setShowModal(false);
    };
    const confirmAction = (values: any) => {
        setFormBtnLoading(true);
        if (modalType === ModalType.Create || modalType === ModalType.Copy) {
            addProductPrice({ asset: { ...values, asset_type_code: type } })
                .then((res) => {
                    setShowModal(false);
                    refresh(quotationsParams[0]!);
                    message.success({
                        content:
                            modalType === ModalType.Copy
                                ? '复制产品价格成功！'
                                : '新增产品价格成功！',
                    });
                }, showError)
                .finally(() => {
                    setFormBtnLoading(false);
                });
        } else {
            let extInfo = { ...editAsset?.ext_info, ...values.ext_info };
            let newAsset = { ...editAsset, ...values, asset_type_code: type };
            newAsset.ext_info = extInfo;
            updateProductPrice({
                asset: newAsset,
            })
                .then((res) => {
                    setShowModal(false);
                    refresh(quotationsParams[0]!);
                    message.success({
                        content: '修改产品价格成功！',
                    });
                }, showError)
                .finally(() => {
                    setFormBtnLoading(false);
                });
        }
    };
    const onModalConfirmClick = () => {
        if (formRef.current?.getFieldsValues) {
            formRef.current?.getFieldsValues().then((values: any) => {
                confirmAction(values);
            });
        }
        if (form2Ref.current) {
            form2Ref.current.validateFields().then((values: any) => {
                confirmAction(values);
            });
        }
    };
    const handlePageChange = (page: number, pageSize?: number) => {
        setCurPage(page);
        const newParam = {
            ...quotationsParams[0]!,
            current_page: page,
            page_size: pageSize,
        };
        refresh(newParam);
    };
    const onRemoveClick = (asset: Asset) => {
        Modal.confirm({
            title: '确认删除产品价格？',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                uploadEventTrack(EventList.QuotationProductPriceDataAction, 2, {
                    button_name: 'remove',
                    asset_type: AssetType[type],
                });
                deleteProductPrice({ isin: asset.isin }).then((res) => {
                    refresh(quotationsParams[0]!);
                }, showError);
            },
        });
    };
    const onCreateClick = () => {
        uploadEventTrack(EventList.QuotationBondsButtonClick, 2, {
            button_name: 'add',
            bond_type: type,
        });

        setModalType(ModalType.Create);
        setEditAsset(undefined);
        setShowModal(true);
    };
    const onEditClick = (asset: Asset) => {
        uploadEventTrack(EventList.QuotationProductPriceDataAction, 2, {
            button_name: 'edit',
            asset_type: AssetType[type],
        });
        setModalType(ModalType.Edit);
        setEditAsset(asset);
        setShowModal(true);
    };
    const onCopyClick = (asset: Asset) => {
        uploadEventTrack(EventList.QuotationProductPriceDataAction, 2, {
            button_name: 'copy',
            asset_type: AssetType[type],
        });
        setModalType(ModalType.Copy);
        setEditAsset(asset);
        setShowModal(true);
    };
    const exportExcel = useCallback(() => {
        uploadEventTrack(EventList.QuotationBondsButtonClick, 2, {
            button_name: 'export_excel',
            bond_type: type,
        });
        const param = {
            ...quotationsParams[0]!,
        } as ProductPriceListReq;
        getAllProductPriceList(param).then((res) => {
            if (res.data.assets?.length > 0) {
                exportXls(`${AssetType[type]}-${moment().format('yyyyMMDDHHmmss')}`, [
                    ChangeProducPriceToExcelData(res.data.assets, type),
                ]);
            } else {
                Modal.error({
                    content: '该筛选条件下无结果',
                });
            }
        }, showError);
    }, [quotationsParams, type]);

    const renderFilter = (type: AssetType) => {
        switch (type) {
            case AssetType.CorporateBond:
            case AssetType.GovernmentBond:
                return <BondFilter type={type} onFilterChange={onFilterChange} />;
            case AssetType.ELN:
            case AssetType.DQ:
            case AssetType.AQ:
            case AssetType.FCN:
            case AssetType.Option:
                return (
                    <FilterHeader
                        type={type}
                        isDetail={onSelectionChange !== undefined}
                        onChange={(value) => {
                            setCurPage(1);
                            const newParam: ProductPriceListReq = {
                                ...quotationsParams[0]!,
                                current_page: 1,
                                ext_info: {
                                    ...quotationsParams[0]?.ext_info,
                                    ...value,
                                },
                            };
                            refresh(newParam);
                        }}
                        isQuote={!!onSelectionChange}
                        accountId={accountId}
                    />
                );
            case AssetType.Fund:
                return (
                    <FundFilter
                        onChange={(value) => {
                            const newParam: ProductPriceListReq = {
                                ...quotationsParams[0]!,
                                ext_info: {
                                    asset_name: value,
                                    fund_manager: value,
                                    filter_type: 2,
                                },
                            };
                            refresh(newParam);
                        }}
                    />
                );
        }
    };

    const renderForm = (type: AssetType) => {
        switch (type) {
            case AssetType.CorporateBond:
            case AssetType.GovernmentBond:
                return (
                    <BondForm ref={form2Ref} asset={editAsset} modalType={modalType!} type={type} />
                );
            case AssetType.ELN:
                return <ElnForm asset={editAsset} ref={formRef} />;
            case AssetType.AQ:
            case AssetType.DQ:
                return <AQDQForm asset={editAsset} ref={form2Ref} type={type} />;
            case AssetType.FCN:
                return <FCNForm asset={editAsset} ref={form2Ref} type={type} />;
            case AssetType.Option:
                return <OptionForm asset={editAsset} ref={form2Ref} type={type} />;
            case AssetType.Fund:
                return (
                    <FundForm asset={editAsset} modalType={modalType!} ref={form2Ref} type={type} />
                );
            default:
                return <></>;
        }
    };
    return (
        <>
            <div className={styles.content}>
                <div className={styles.header}>
                    {renderFilter(type)}
                    {!onSelectionChange && (
                        <div className={styles.tableTop}>
                            <div className={styles.updateTime}>
                                {(type === AssetType.CorporateBond ||
                                    type === AssetType.GovernmentBond) &&
                                quoteListData?.data.price_update_at
                                    ? `更新时间: ${moment(
                                          quoteListData.data.price_update_at
                                      ).format('yyyy-MM-DD HH:mm:ss')}`
                                    : ''}
                            </div>
                            <div className={styles.btnArea}>
                                <Button type="primary" onClick={onCreateClick}>
                                    增加
                                </Button>
                                <Button type="primary" onClick={exportExcel}>
                                    导出Excel
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                <Modal
                    visible={showModal}
                    width={1000}
                    title={
                        modalType === ModalType.Create
                            ? '新增产品价格'
                            : modalType === ModalType.Edit
                            ? '修改产品价格'
                            : '复制产品价格'
                    }
                    cancelText="取消"
                    okText="确定"
                    onCancel={onModalCancelClick}
                    onOk={onModalConfirmClick}
                    destroyOnClose
                    okButtonProps={{
                        loading: formBtnLoading,
                        disabled: formBtnLoading,
                    }}
                >
                    {renderForm(type)}
                </Modal>

                <Spin tip="加载中" spinning={loading}>
                    {quoteListData && (
                        <>
                            <ProductsPriceTable
                                type={type}
                                data={quoteListData.data.assets}
                                onRemoveClick={onRemoveClick}
                                onEditClick={onEditClick}
                                onCopy={onCopyClick}
                                onFiledSort={onFilterChange}
                                tableHeight={tableHeight}
                                defaultSelectedKeys={defaultSelectedKeys}
                                onSelectionChange={onSelectionChange}
                            />
                            {!onSelectionChange && (
                                <Pagination
                                    className={styles.pagination}
                                    current={curPage}
                                    pageSize={PAGE_SIZE}
                                    total={quoteListData.data.total_count}
                                    onChange={handlePageChange}
                                    showSizeChanger={false}
                                    size="small"
                                />
                            )}
                        </>
                    )}
                </Spin>
            </div>
        </>
    );
};

export default GKProductsPrice;
