import axios from '../../../service/tools';

interface AddBankStandardData {
    bank_zh: string;
    bank_logo: string;
    charge_value_list?: {
        service: string;
        type: string;
        fee_value: string;
        remark?: string;
    }[];
}

export const addBankStandard = (data: AddBankStandardData) => {
    return axios.post('/v1/admin/chargeStandard/add', { data });
};
export interface ChargeModel {
    id: number;
    service: string;
    service_zh: string;
    type: string;
    type_zh: string;
    fee_value: string;
    remark?: string;
}
export interface BankChargeStandard {
    bank_id: number;
    bank_zh: string;
    bank_logo?: string;
    charge_value_list: ChargeModel[];
    customValueList?: { [key: string]: any };
}
export interface QueryType {
    service: string;
    type_list: ChargeModel[];
}
export const getBankStandards = () => {
    return axios.post<{ bank_charge_list: BankChargeStandard[] }>('/v1/admin/chargeStandard/query');
};

export const getQueryType = () => {
    return axios.post<{ service_list: QueryType[] }>('/v1/admin/chargeStandard/query/type');
};

export const updateBankStandard = (data: BankChargeStandard) => {
    return axios.post('/v1/admin/chargeStandard/updateByBank', { data });
};

export const deleteBankStandard = (data: { bank_id: number }) => {
    return axios.post('/v1/admin/chargeStandard/delete', { data });
};
export enum UploadFileType {
    BankLogo = 'bank_logo',
    Receipt = 'bank_receipt',
    DocPoster = 'doc_poster',
    track = 'customer_track',
    banner = 'banner',
    newly_signed_file = 'newly_signed_file',
}
export type CommonUploadFileResponse = {
    url: string;
    id?: number;
    size: number;
    width: number;
    height: number;
};
export const CommonUploadFile = (data: {
    data_url: string;
    target_type: UploadFileType;
    file_name?: string;
}) => {
    return axios.post<CommonUploadFileResponse>('/v1/admin/upload_file', {
        data,
    });
};
