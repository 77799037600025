import React, { useEffect, useState } from 'react';
import LKRadioGroup, { LKRadioGroupProps } from '../../../components/LKRadioGroup';
import {
    UserAccountDetailInfo,
    getUserAllAccount,
} from '../../../service/requests/HistoricalTransactions';
import { useParams } from 'react-router-dom';
import AssetPositionTable from '../Component/AssetPositionTable';
import { PositionAssetList, PositionAssetSummary } from '../type';
import { queryAssetList, queryAssetSummary } from '../request';
import AssetCommonTable from '../Component/AssetCommonTable';
import { Button, Space } from 'antd';
import { exportExcel as downloadExcel } from '../util';
import moment from 'moment';
const FamilySubAccount = () => {
    const [dataSource, setDataSource] = useState<LKRadioGroupProps['datasource']>([]);
    const { account_id, client_name, customer_id } = useParams<{
        account_id: string;
        customer_id: string;
        client_name: string;
    }>();
    const [accountList, setAccountList] = useState<UserAccountDetailInfo[]>([]);
    const [selectAccount, setSelectAccount] = useState(account_id);
    useEffect(() => {
        getUserAllAccount({ customer_id }).then((res) => {
            let data: LKRadioGroupProps['datasource'] = [
                {
                    label: 'All',
                    value: 'all',
                    width: '50px',
                },
            ];
            setAccountList(res.data);
            res.data.forEach((item) => {
                data.push({
                    label: `${client_name}-${item.bank_name}-${item.vendor_sub_account_id}`,
                    value: item.account_id,
                    btnStyle: {
                        paddingLeft: '12px',
                        paddingRight: '12px',
                    },
                });
            });
            data.sort((a, b) => {
                if (a.label === 'All') return -1;
                if (b.label === 'All') return 1;
                return a.label.localeCompare(b.label);
            });
            setDataSource(data);
        });
    }, [client_name, customer_id]);
    const [assetSummary, setAssetSummary] = React.useState<PositionAssetSummary>();
    const [assetList, setAssetList] = React.useState<PositionAssetList[]>();
    useEffect(() => {
        queryAssetSummary({
            account_id: selectAccount === '' || selectAccount === 'all' ? undefined : selectAccount,
            client_id: customer_id,
        }).then((res) => {
            setAssetSummary(res.data);
        });
        queryAssetList({
            account_id: selectAccount === '' || selectAccount === 'all' ? undefined : selectAccount,
            client_id: customer_id,
        }).then((res) => {
            setAssetList(res.data);
        });
    }, [customer_id, selectAccount]);
    const handleClick = () => {
        const tableIds =
            assetList?.map((item) => `Position_Asset_${item.asset_types[0].desc}`) ?? [];
        let elements = document.querySelectorAll('.ant-table-measure-row');
        elements.forEach((element) => {
            element.remove();
        });
        let fileName = '';
        if (selectAccount === 'all') {
            fileName = `Position-${client_name}-${moment().format('MMDD')}`;
        } else {
            const account = accountList.filter((item) => item.account_id === selectAccount)[0];
            fileName = `Position-${account.vendor_sub_account_id}-${moment().format('MMDD')}`;
        }
        downloadExcel(
            ['Position_detail_Total_Table', 'Position_Detail_List_Table', ...tableIds],
            fileName
        );
    };
    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <LKRadioGroup
                    value={selectAccount}
                    datasource={dataSource}
                    defaultValue={''}
                    onChange={(e) => {
                        setSelectAccount(e as string);
                    }}
                />
                <Button onClick={handleClick}>{'Export Excel'}</Button>
            </div>
            <AssetPositionTable
                key={'total'}
                id="Position_detail_Total_Table"
                data={[
                    {
                        title: 'Total',
                        amount: `${assetSummary?.total_amount} ${assetSummary?.currency}` ?? '-',
                    },
                ]}
            />
            <AssetPositionTable
                key={'asset'}
                id="Position_Detail_List_Table"
                data={
                    assetSummary?.asset_position_percents?.map((item) => ({
                        title:
                            (item.asset_types[0] ?? '').desc.indexOf('Bond') > -1
                                ? 'Bond'
                                : item.asset_types[0].desc,
                        amount: `${item.amount} ${assetSummary.currency}`,
                    })) ?? []
                }
            />
            {selectAccount === 'all' && (
                <AssetPositionTable
                    key={'asset'}
                    id="Position_Detail_List_Table"
                    data={
                        assetSummary?.account_position_percents?.map((item) => ({
                            title: `${item.client.name}(${item.client.id})-${item.account.bank_name}-${item.account.vendor_sub_account_id}`,
                            amount: `${item.amount} ${assetSummary.currency}`,
                        })) ?? []
                    }
                />
            )}

            {assetList?.map((item) => {
                return (
                    <AssetCommonTable
                        data={item}
                        id={`Position_Asset_${item.asset_types[0].desc}`}
                        key={item.asset_types[0].code}
                        withClick={false}
                    />
                );
            })}
        </Space>
    );
};

export default FamilySubAccount;
