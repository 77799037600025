import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { queryCloseTranscationOrder } from '../../../service/requests/HistoricalTransactions';
import { Form, Select } from 'antd';
import { css } from '@emotion/css';
import { quoteOrder, quoteOrderList } from '../../../service/type';
const { Option } = Select;

const SelectPositionFormItem = ({
    account_id,
    type,
    selectData,
}: {
    account_id: string;
    type: 'options' | 'accum' | 'decum' | 'fcn';
    selectData: (data?: quoteOrderList['order_info_list'][number]) => void;
}) => {
    const [historyData, setHistoryData] = useState<Array<quoteOrder>>();
    useEffect(() => {
        if (account_id.length > 0) {
            queryCloseTranscationOrder({ account_id: account_id, product_type: type }).then(
                (res) => {
                    setHistoryData(res.data);
                }
            );
        }
    }, [account_id, type]);
    const onChange = useCallback(
        (value) => {
            selectData(historyData?.[value]);
        },
        [historyData, selectData]
    );

    const styles = css`
        border-bottom: 1px solid #e8e8e8;
    `;
    const options = useMemo(
        () =>
            historyData?.map((item, index) => (
                <Option className={styles} value={index} key={item.order_id}>
                    {item.name}
                </Option>
            )),
        [historyData, styles]
    );
    return (
        <Form.Item
            label="选择产品"
            name="position_index"
            rules={[{ required: true, message: '请选择银行' }]}
        >
            <Select onChange={onChange}>{options}</Select>
        </Form.Item>
    );
};
export default SelectPositionFormItem;
