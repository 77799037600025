import {
    ColDef,
    ValueFormatterParams,
    ICellRendererParams,
    ValueGetterParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Button, Tooltip, message } from 'antd';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import useFetch from '../../../utils/Hooks/useFetch';
import { getPushHistory, cancelPushTask } from '../../../service/requests/PushCenter';
import ReactPaginate from 'react-paginate';
import './index.less';
import { showError } from '../../RecommendationComponent/util';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const TooltipCell = ({ text }: { text: string }) => (
    <Tooltip title={text} placement="topLeft">
        <span>{text}</span>
    </Tooltip>
);

const PAGE_SIZE = 15;

export default function PushHistory() {
    const [curPage, setCurPage] = useState(0);
    const [fetchData, setParams, refreshData] = useFetch({
        interfaces: getPushHistory,
        initialParams: { offset: 0, limit: PAGE_SIZE },
    });
    const pageCount = useMemo(() => {
        if (fetchData.data) {
            return Math.ceil(fetchData.data?.size / PAGE_SIZE);
        }
    }, [fetchData]);

    const colDefs = useMemo<ColDef[]>(() => {
        return [
            {
                headerName: '发送对象',
                valueGetter: (params: ValueGetterParams) => {
                    let names = [];
                    if (params.data.users?.length) {
                        for (let user of params.data.users) {
                            names.push(user.name);
                        }
                    } else {
                        return '所有人';
                    }
                    return names.join('，');
                },
                cellRenderer: (params: ICellRendererParams) => <TooltipCell text={params.value} />,
            },
            {
                headerName: '状态',
                field: 'status',
                width: 120,
                cellRenderer: (params: ICellRendererParams) => {
                    switch (params.value) {
                        case 1:
                            return <span className="status-waiting">等待中</span>;
                        case 2:
                            return <span className="status-pushing">推送中</span>;
                        case 3:
                            return <span className="status-pushed">已推送</span>;
                        case 4:
                            return '推送失败';
                        case 5:
                            return '部分失败';
                        default:
                            return <span className="status-cancelled">已取消</span>;
                    }
                },
            },
            {
                headerName: '发送时间',
                valueGetter: (params: ValueGetterParams) => {
                    return params.data.push_at !== 0 ? params.data.push_at : params.data.created_at;
                },
                valueFormatter: (params: ValueFormatterParams) => {
                    return moment(params.value).format('YYYY-MM-DD HH:mm:ss');
                },
            },
            {
                headerName: '标题',
                field: 'title',
                cellRenderer: (params: ICellRendererParams) => <TooltipCell text={params.value} />,
            },
            {
                headerName: '内容',
                field: 'body',
                cellRenderer: (params: ICellRendererParams) => <TooltipCell text={params.value} />,
            },
            {
                headerName: '操作人',
                flex: 1,
                minWidth: 120,
                valueGetter: (params: ValueGetterParams) => params.data.by_user?.name ?? '',
            },
            {
                headerName: '操作',
                pinned: 'right',
                resizable: false,
                width: 120,
                cellRenderer: (params: ICellRendererParams) => {
                    if (params.data.status === 1) {
                        return (
                            <Button
                                type="link"
                                danger
                                onClick={() => {
                                    cancelPushTask({ task_id: params.data.task_id })
                                        .then(() => {
                                            message.success('任务已取消');
                                        }, showError)
                                        .finally(() => {
                                            refreshData();
                                        });
                                }}
                            >
                                取消
                            </Button>
                        );
                    }
                    return null;
                },
            },
        ];
    }, [refreshData]);

    return (
        <>
            <div className="ag-theme-alpine" style={{ marginTop: '16px', width: '100%' }}>
                <AgGridReact
                    animateRows
                    columnDefs={colDefs}
                    defaultColDef={{ resizable: true, width: 160 }}
                    rowData={fetchData.data?.tasks ?? []}
                    domLayout="autoHeight"
                    enableCellTextSelection
                    ensureDomOrder
                />
                {pageCount && (
                    <ReactPaginate
                        className="pagination"
                        activeClassName="active"
                        previousLabel={<LeftOutlined style={{ fontSize: 12 }} />}
                        nextLabel={<RightOutlined style={{ fontSize: 12 }} />}
                        forcePage={curPage}
                        pageCount={pageCount}
                        onPageChange={(selectedItem) => {
                            setCurPage(selectedItem.selected);
                            setParams({
                                offset: selectedItem.selected * PAGE_SIZE,
                                limit: PAGE_SIZE,
                            });
                        }}
                    />
                )}
            </div>
        </>
    );
}
