import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import { getOrderTemplate } from '../../../service/requests/OrderRequest';
import querySearch from 'query-string';
import EmailTemplate from '../../../components/EmailTemplate';
import { useRequest } from 'ahooks';
import { getBondQuotesDetails } from '../request';
import DetailTables from '../../../components/DetailTables';

const BondQuoteEmail = () => {
    const location = useLocation();
    const { id } = querySearch.parse(location.search) as {
        id: string;
    };
    const { data: detailsData } = useRequest(getBondQuotesDetails, {
        defaultParams: [
            {
                product_quote_id: id,
            },
        ],
    });
    const { data: emailTemplate } = useRequest(getOrderTemplate, {
        defaultParams: [
            {
                type: 2,
                order_id: parseInt(id),
            },
        ],
    });

    return (
        <div className={styles.content}>
            <div className={styles.email}>
                {emailTemplate && (
                    <EmailTemplate
                        to={emailTemplate?.data.template.to.toString() || ''}
                        cc={emailTemplate?.data.template.cc.toString() || ''}
                        subject={emailTemplate?.data.template.subject.toString() || ''}
                        body={emailTemplate?.data.template.body || ''}
                    />
                )}
            </div>
            <div className={styles.detail}>
                {detailsData?.data && (
                    <DetailTables
                        title="下单信息核对"
                        details={detailsData.data.order_details.map((od) => ({
                            header: od.title,
                            fields: od.detail_fields,
                        }))}
                    />
                )}
            </div>
        </div>
    );
};

export default BondQuoteEmail;
