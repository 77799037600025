import axios from '../../service/tools';
import { SyncPosterType } from '../SyncArticle/type';
export type SyncArtclePushHistory = {
    push_histories: {
        id: number;
        doc_id: number;
        created_at: number;
        creator: {
            id: number;
            name: string;
            mobile: string;
            valid: boolean;
            huobanId: number;
            feishuId: string;
            feishuOpenId: string;
            jiraId: string;
            feishuAvatar: string;
        };
    }[];
};
export enum ArticleType {
    Featured = 1,
    HouseKeeper = 2,
    Banner = 3,
}
export type BannerListType = {
    updated_at: number;
    push_task_status: {
        code: number;
        desc: string;
    };
    doc_url: string;
    online: 1 | 2; //是否上线
    doc_id: number;
    id: number;
    title: string;
    //是否精选
    creator: string;
};
export interface BannerSaveReq {
    doc_url: string;
    id: number;
    poster_url: string;
}
export const bannerConfigSave = (data: BannerSaveReq) => {
    return axios.post('/v1/admin/banner/save ', { data });
};

export const getPosterList = () => {
    return axios.post<{
        posters: SyncPosterType[];
    }>('/v1/admin/banner/default_posters');
};
export const bannerList = (data: { current_page: number; page_size: number }) => {
    return axios.post<{
        total_pages: number;
        current_page: number;
        total_count: number;
        banners: BannerListType[];
    }>('/v1/admin/banner/page', { data });
};

//更新文章上线状态
export const updateOnlineStatus = (data: { banner_id: number; online_status: number }) => {
    return axios.post('/v1/admin/banner/update_online_status', { data });
};
export const bannerDetail = (data: { banner_id: number }) => {
    return axios.post<{
        banner: {
            title: string;
            doc_url: string;
            release_channel: number[];
            tags: { name: string; id: number }[];
            poster_url: string;
            featured: 1 | 2;
            tag_info_v2s: {
                id: number;
                name: string;
            }[];
        };
    }>('/v1/admin/banner/detail', { data });
};

export const selectBannerPushHistory = (data: { banner_id: number }) => {
    return axios.post<SyncArtclePushHistory>('/v1/admin/banner/app_push/history', { data });
};
/**
 *     "doc_id": 0,
    "push_title": "",
    "push_body": "",
    "all_user_or_only_myself": 0,
    "push_at": 0,
    "by_user_id": "",
    "is_persistent": 0,
    "message_type": 0
*/

export const createBannerPush = (data: {
    banner_id: number;
    title: string;
    body: string;
    is_all_users: 1 | 2; // 1-所有用户, 2-仅自己
    push_at: number;
    by_user_id: string;
    is_persistent: 1 | 2; // 通知是否存入消息中心, 1-是, 2-否, 默认1
}) => {
    return axios.post('/v1/admin/banner/app_push/add', { data });
};
