import { Form, Input, InputNumber, Select } from 'antd';
import {
    ColDef,
    ICellRendererParams,
    ValueFormatterParams,
    ValueGetterParams,
} from 'ag-grid-community';
import React from 'react';
import { quoteOrderList } from '../../../service/type.js';
import { FormatDate, FormatNumber, FormatPercentNumber, FormatPriceNumber } from '../../../utils';
import moment from 'moment';

export const ORDER_STATUS_LIST = ['待确认', '订单取消', '委托成功', '下单失败'];

export const COLUMNS_TO_AUTOSIZE = ['product', 'share', 'limit_order_price', 'knock_out', 'qty'];

export function getSharedColumns(curEditingId: number | undefined): ColDef[] {
    return [
        { field: 'order_id', headerName: 'ID', width: 80 },
        {
            field: 'status',
            headerName: '订单状态',
            width: curEditingId === undefined ? 112 : 136,
            cellRenderer: (params: ICellRendererParams) =>
                curEditingId === params.node.__objectId ? (
                    <Form.Item name="status">
                        <Select style={{ width: '100px' }}>
                            {ORDER_STATUS_LIST.map((value, index) => (
                                <Select.Option value={index + 1} key={index}>
                                    {value}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                ) : params.value === 5 ? (
                    '已完成'
                ) : (
                    ORDER_STATUS_LIST[params.value - 1]
                ),
        },
        { field: 'customer_id', headerName: '客户ID', width: 96 },
        { field: 'customer_name', headerName: '客户名' },
        { field: 'product', headerName: '产品' },
    ];
}

const anotherShareColumns: ColDef[] = [
    { field: 'matched_order_id', headerName: 'ID', width: 70 },
    { field: '', headerName: '订单状态', width: 110, cellRenderer: () => '已完成' },
    { field: 'customer_id', headerName: '客户ID' },
    { field: 'customer_name', headerName: '客户名' },
    {
        field: '',
        headerName: '交易账户',
        width: 180,
        cellRenderer: (params: ICellRendererParams) =>
            params.data.bank_name +
            '-' +
            params.data.account_name +
            '-' +
            (params.data.vendor_account_id ?? '').substring(
                (params.data.vendor_account_id ?? '').length - 2
            ),
    },
];
export function getCashColumns(): ColDef[] {
    return [
        ...anotherShareColumns,
        {
            field: 'action',
            headerName: '交易方向',
            width: 110,
            cellRenderer: (params: ICellRendererParams) =>
                params.data.action === 1 ? '转入' : '转出',
        },
        {
            field: 'currency',
            headerName: '货币',
            width: 110,
            cellRenderer: (params: ICellRendererParams) => currenyToChinese(params.data.currency),
        },
        { field: 'occur_amount', headerName: '数量($)', width: 110 },
        {
            field: '',
            headerName: '转出目的地账户',
            width: 180,
            cellRenderer: (params: ICellRendererParams) => {
                if (params.data.action === 1) {
                    return 'n/a';
                } else {
                    return `${params.data.to_account_bank_name}-${params.data.to_account_name}-${(
                        params.data.to_account_vendor_account_id ?? ''
                    ).substring((params.data.to_account_vendor_account_id ?? '').length - 2)}`;
                }
            },
        },
        {
            field: '',
            headerName: '手续费($)',
            width: 110,
            cellRenderer: (params: ICellRendererParams) => {
                return params.data.fee + currenyToChinese(params.data.fee_currency);
            },
        },
        {
            field: '',
            headerName: '下单时间',
            width: 120,
            cellRenderer: (params: ICellRendererParams) =>
                moment(params.data.matched_at).format('YYYY-MM-DD'),
        },
    ];
}
export function getLoanColumns(): ColDef[] {
    return [
        ...anotherShareColumns,
        {
            field: '',
            headerName: '交易方向',
            width: 120,
            cellRenderer: (params: ICellRendererParams) =>
                params.data.action === 1 ? '贷款' : '还款',
        },
        { field: 'currency', headerName: '货币类型', width: 110 },
        { field: 'occur_amount', headerName: '交易数量($)', width: 150 },
        {
            field: 'loan_rate',
            headerName: '贷款/所还贷款利率',
            width: 170,
            cellRenderer: (params: ICellRendererParams) => `${params.data.loan_rate}%`,
        },
        {
            field: '',
            headerName: '起始日期',
            width: 130,
            cellRenderer: (params: ICellRendererParams) =>
                moment(params.data.begin_at).format('YYYY-MM-DD'),
        },
        {
            field: '',
            headerName: '结束日期',
            width: 120,
            cellRenderer: (params: ICellRendererParams) =>
                moment(params.data.end_at).format('YYYY-MM-DD'),
        },
        { field: 'loan_order_id', headerName: '挂钩贷款订单ID', width: 170 },
        { field: 'termination_fee', headerName: '手续费($)', width: 120 },
        {
            field: '',
            headerName: '下单时间',
            width: 120,
            cellRenderer: (params: ICellRendererParams) =>
                moment(params.data.matched_at).format('YYYY-MM-DD'),
        },
    ];
}
export function getDepositColumns(): ColDef[] {
    return [
        ...anotherShareColumns,
        {
            field: '',
            headerName: '交易方向',
            width: 120,
            cellRenderer: (params: ICellRendererParams) =>
                params.data.action === 1 ? '存款' : '取款',
        },
        { field: 'currency', headerName: '货币类型', width: 110 },
        {
            headerName: '交易数量($)',
            width: 150,
            cellRenderer: (params: ICellRendererParams) => {
                let a = params.data.occur_balance?.replaceAll(',', '');
                let b = params.data.fill_balance?.replaceAll(',', '');
                if (params.data.action === 1) {
                    return (Number.parseFloat(a) + Number.parseFloat(b) ?? 0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                } else {
                    return params.data.amount ?? '0';
                }
            },
        },
        {
            field: 'loan_rate',
            headerName: '存款/所提存款利率',
            width: 170,
            cellRenderer: (params: ICellRendererParams) => `${params.data.interest}%`,
        },
        {
            field: '',
            headerName: '起始日期',
            width: 130,
            cellRenderer: (params: ICellRendererParams) =>
                moment(params.data.begin_at).format('YYYY-MM-DD'),
        },
        {
            field: '',
            headerName: '结束日期',
            width: 120,
            cellRenderer: (params: ICellRendererParams) =>
                moment(params.data.end_at).format('YYYY-MM-DD'),
        },
        {
            field: 'deposit_matched_order_id',
            headerName: '挂钩贷款订单ID',
            width: 170,
            cellRenderer: (params: ICellRendererParams) =>
                params.data.deposit_matched_order_id ?? 'n/a',
        },
        {
            field: 'termination_fee',
            headerName: '手续费($)',
            width: 120,
            cellRenderer: (params: ICellRendererParams) =>
                params.data.fee ? `${params.data.fee} ${params.data.feeCurrency}` : 'n/a',
        },
        {
            field: '',
            headerName: '下单时间',
            width: 120,
            cellRenderer: (params: ICellRendererParams) =>
                moment(params.data.matched_at).format('YYYY-MM-DD'),
        },
    ];
}

export function getFxColumns(): ColDef[] {
    return [
        ...anotherShareColumns,
        { field: 'from_currency', headerName: '卖出货币', width: 110 },
        { field: 'to_currency', headerName: '买入货币', width: 110 },
        { field: 'fx', headerName: '汇率' },

        { field: 'to_amount', headerName: '买入数量($)', width: 130 },
        {
            field: '',
            headerName: '成交日期',
            width: 120,
            flex: 1,
            cellRenderer: (params: ICellRendererParams) =>
                moment(params.data.matched_at).format('YYYY-MM-DD'),
        },
    ];
}
export function getStockColumns(): ColDef[] {
    return [
        ...anotherShareColumns,
        {
            field: '',
            headerName: '交易类型',
            cellRenderer: (params: ICellRendererParams) =>
                params.data.action === 1 ? '买入' : '卖出',
        },
        {
            field: '',
            headerName: '股票名称',
            cellRenderer: (params: ICellRendererParams) => params.data.quote?.display_name ?? '',
        },
        { field: 'matched_price', headerName: '成交价格' },

        { field: 'matched_qty', headerName: '成交数量' },
        {
            field: '',
            headerName: '成交日期',
            width: 120,
            cellRenderer: (params: ICellRendererParams) =>
                moment(params.data.matched_at).format('YYYY-MM-DD'),
        },
        {
            field: 'occur_amount',
            headerName: '成交总额',
            width: 120,
        },
        {
            field: '',
            headerName: '费用',
            width: 120,
            cellRenderer: (params: ICellRendererParams) =>
                FormatNumber(
                    (
                        Number.parseFloat(
                            (params.data.occur_amount ?? '').toString().replace(/,/gi, '')
                        ) -
                        Number.parseFloat(
                            (params.data.matched_amount ?? '').toString().replace(/,/gi, '')
                        )
                    ).toFixed(3)
                ),
        },
    ];
}
const NumericFormatter = (params: ValueFormatterParams, precision?: number) => {
    if (params.colDef.headerName?.includes('%')) {
        precision = 2;
    }
    let formatted: string;
    switch (typeof params.value) {
        case 'number':
            formatted = FormatNumber(params.value, precision);
            break;
        case 'string':
            let num = parseFloat(params.value);
            if (Number.isNaN(num)) {
                return params.value;
            } else {
                formatted = FormatNumber(num, precision);
            }
            break;
        default:
            return params.value;
    }
    if (params.colDef.headerName?.includes('%')) {
        return `${formatted}%`;
    } else {
        return formatted;
    }
};

interface ProductDef {
    name: string;
    type: string;
    getClientTemplate?: (data: quoteOrderList['order_info_list'][number]) => string;
    getOwnColumns?: (curEditingId: number | undefined) => ColDef[];
}

const sharedOwnColumns = {
    share: { field: 'share', headerName: '股票' },
    tenor: { field: 'tenor', headerName: '期限' },
    order_type: { field: 'order_type', headerName: '订单类型' },
    up_front: { field: 'up_front', headerName: '佣金%', valueFormatter: NumericFormatter },
    created_at: {
        field: 'created_at',
        headerName: '下单时间',
        width: 160,
        valueFormatter: (params: ValueFormatterParams) =>
            FormatDate(params.value, 'dd/MM/yyyy.HH:mm'),
    },
    guaranteed_period: { field: 'guaranteed_period', headerName: '保证期' },
    strike_price: {
        field: 'strike_price',
        headerName: '成交价',
        valueGetter: (params: ValueGetterParams) => {
            return params.data?.matched_order?.ext_fields?.strike_price ?? 'n/a';
        },
    },
    limit_order_price: (curEditingId: number | undefined) => ({
        field: 'limit_order_price',
        headerName: '股票限价',
        width: curEditingId === undefined ? 108 : 128,
        valueFormatter: NumericFormatter,
        cellRenderer: (params: ICellRendererParams) =>
            params.data.order_type === '限价单' && curEditingId === params.node?.__objectId ? (
                <Form.Item name="limit_order_price">
                    <InputNumber controls={false} min={0} />
                </Form.Item>
            ) : (
                params.valueFormatted
            ),
    }),
    strike_price_ratio: (curEditingId: number | undefined) => ({
        field: 'strike_price_ratio',
        width: curEditingId === undefined ? 108 : 160,
        valueFormatter: NumericFormatter,
        cellRenderer: (params: ICellRendererParams) =>
            curEditingId === params.node.__objectId ? (
                <Form.Item name="strike_price_ratio">
                    <InputNumber
                        style={{ width: 120 }}
                        addonAfter="%"
                        controls={false}
                        precision={3}
                        min={0}
                    />
                </Form.Item>
            ) : (
                params.valueFormatted
            ),
    }),
    qty: (curEditingId: number | undefined) => ({
        field: 'qty',
        width: 128,
        valueFormatter: NumericFormatter,
        cellRenderer: (params: ICellRendererParams) =>
            curEditingId === params.node?.__objectId ? (
                <Form.Item name="qty">
                    <InputNumber controls={false} min={0} />
                </Form.Item>
            ) : (
                params.valueFormatted
            ),
    }),
    knock_out: (curEditingId: number | undefined) => ({
        field: 'knock_out',
        headerName: '敲出价%',
        valueFormatter: NumericFormatter,
        width: curEditingId === undefined ? 108 : 160,
        cellRenderer: (params: ICellRendererParams) =>
            curEditingId === params.node.__objectId ? (
                <Form.Item name="knock_out">
                    <InputNumber
                        style={{ width: 120 }}
                        addonAfter="%"
                        controls={false}
                        precision={3}
                        min={0}
                    />
                </Form.Item>
            ) : (
                params.valueFormatted
            ),
    }),
};

function getAqDqOwnColumns(
    type: 'accum' | 'decum'
): (curEditingId: number | undefined) => ColDef[] {
    return (curEditingId) => [
        { ...sharedOwnColumns.share },
        { ...sharedOwnColumns.tenor },
        { ...sharedOwnColumns.guaranteed_period },
        { ...sharedOwnColumns.order_type },
        { ...sharedOwnColumns.limit_order_price(curEditingId) },
        { ...sharedOwnColumns.knock_out(curEditingId) },
        { ...sharedOwnColumns.strike_price_ratio(curEditingId), headerName: '执行价%' },
        { ...sharedOwnColumns.up_front },
        {
            ...sharedOwnColumns.qty(curEditingId),
            headerName: type === 'accum' ? '每日买入股数' : '每日卖出股数',
        },
        {
            field: 'sold_out_days',
            headerName: type === 'accum' ? '买入天数' : '卖出天数',
            width: curEditingId === undefined ? 96 : 128,
            valueFormatter: (params: ValueFormatterParams) => {
                if (params.value) {
                    return params.value;
                } else {
                    return parseInt(params.data.tenor) * 30;
                }
            },
            cellRenderer: (params: ICellRendererParams) =>
                curEditingId === params.node.__objectId ? (
                    <Form.Item name="sold_out_days">
                        <InputNumber controls={false} min={0} />
                    </Form.Item>
                ) : (
                    params.valueFormatted
                ),
        },
        { field: 'frequency', headerName: '交割频率' },
        { ...sharedOwnColumns.created_at },
    ];
}

function getAqDqClientTemplate(type: 'accum' | 'decum'): (data: any) => string {
    return (data) =>
        data.order_type === '限价单'
            ? `产品：${data.product}
股票：${data.share}
期限：${data.tenor}
保证期：${data.guaranteed_period}
订单类型：${data.order_type}
股票限价：${FormatPriceNumber(data.limit_order_price)} ${data.currency}
敲出价：${FormatPercentNumber(data.knock_out)}%
执行价：${FormatPercentNumber(data.strike_price_ratio)}%
每日${type === 'accum' ? '买入' : '卖出'}股数：${data.qty}
交割频率：${data.frequency}`
            : `产品：${data.product}
股票：${data.share}
期限：${data.tenor}
保证期：${data.guaranteed_period}
订单类型：${data.order_type}
敲出价：${FormatPercentNumber(data.knock_out)}%
执行价：${FormatPercentNumber(data.strike_price_ratio)}%
每日${type === 'accum' ? '买入' : '卖出'}股数：${data.qty}
交割频率：${data.frequency}`;
}

export const PRODUCT_DEFS: ProductDef[] = [
    {
        name: '股票',
        type: 'stock',
        getClientTemplate: getAqDqClientTemplate('accum'),
        getOwnColumns: () => [],
    },
    {
        name: '期权',
        type: 'options',
        getClientTemplate: (data) =>
            data.order_type === '限价单'
                ? `产品：${data.product}
股票：${data.share}
期限：${data.tenor}
订单类型：${data.order_type}
股票限价：${FormatPriceNumber(data.limit_order_price)} ${data.currency}
行权价：${FormatPercentNumber(data.strike_price_ratio)}%
期权费：${FormatPercentNumber(data.option_premium_ratio)}%
下单数量：${FormatNumber(data.qty)}`
                : `产品：${data.product}
股票：${data.share}
期限：${data.tenor}
订单类型：${data.order_type}
行权价：${FormatPercentNumber(data.strike_price_ratio)}%
期权费：${FormatPercentNumber(data.option_premium_ratio)}%
下单数量：${FormatNumber(data.qty)}`,
        getOwnColumns: (curEditingId) => [
            { ...sharedOwnColumns.share },
            { ...sharedOwnColumns.tenor },
            { ...sharedOwnColumns.order_type },
            { ...sharedOwnColumns.limit_order_price(curEditingId) },
            { ...sharedOwnColumns.strike_price },
            { ...sharedOwnColumns.strike_price_ratio(curEditingId), headerName: '行权价%' },
            {
                field: 'option_premium_ratio',
                headerName: '期权费%',
                width: curEditingId === undefined ? 108 : 160,
                valueFormatter: NumericFormatter,
                cellRenderer: (params: ICellRendererParams) => {
                    return curEditingId === params.node.__objectId ? (
                        <Form.Item name="option_premium_ratio">
                            <InputNumber
                                style={{ width: 120 }}
                                addonAfter="%"
                                controls={false}
                                precision={3}
                                min={0}
                            />
                        </Form.Item>
                    ) : (
                        params.valueFormatted
                    );
                },
            },
            { ...sharedOwnColumns.up_front },
            { ...sharedOwnColumns.qty(curEditingId), headerName: '下单数量' },
            { field: 'currency', headerName: '币种', width: 80 },
            { ...sharedOwnColumns.created_at },
            { field: 'option_type', headerName: '期权类型' },
        ],
    },
    {
        name: 'AQ',
        type: 'accum',
        getClientTemplate: getAqDqClientTemplate('accum'),
        getOwnColumns: getAqDqOwnColumns('accum'),
    },
    {
        name: 'DQ',
        type: 'decum',
        getClientTemplate: getAqDqClientTemplate('decum'),
        getOwnColumns: getAqDqOwnColumns('decum'),
    },
    {
        name: 'FCN',
        type: 'fcn',
        getClientTemplate: (data) =>
            data.order_type === '限价单'
                ? `产品：${data.product}
期限：${data.tenor}
保证期：${data.guaranteed_period}
票息：${FormatPercentNumber(data.coupon)}% (${data.coupon_frequency})
订单类型：${data.order_type}
股票限价：${data.limit_order_share} - ${FormatPriceNumber(data.limit_order_price)} ${data.currency}
敲出价：${FormatPercentNumber(data.knock_out)}% (${data.knock_out_type})
执行价：${FormatPercentNumber(data.strike_price_ratio)}%
投资金额：${FormatNumber(data.trade_amount, 2)} ${data.currency}`
                : `产品：${data.product}
期限：${data.tenor}
保证期：${data.guaranteed_period}
票息：${FormatPercentNumber(data.coupon)}% (${data.coupon_frequency})
订单类型：${data.order_type}
敲出价：${FormatPercentNumber(data.knock_out)}% (${data.knock_out_type})
执行价：${FormatPercentNumber(data.strike_price_ratio)}%
投资金额：${FormatNumber(data.trade_amount, 2)} ${data.currency}`,
        getOwnColumns: (curEditingId) => [
            { ...sharedOwnColumns.tenor },
            { ...sharedOwnColumns.guaranteed_period },
            { field: 'coupon', headerName: '票息%', valueFormatter: NumericFormatter },
            { field: 'coupon_frequency', headerName: '交付频率' },
            { field: 'knock_in', headerName: '敲入价%', valueFormatter: NumericFormatter },
            { ...sharedOwnColumns.knock_out(curEditingId) },
            {
                field: 'knock_out_type',
                headerName: '观察频率',
                width: curEditingId === undefined ? 100 : 164,
                cellRenderer: (params: ICellRendererParams) => {
                    return curEditingId === params.node.__objectId ? (
                        <Form.Item name="knock_out_type">
                            <Select style={{ width: 128 }}>
                                <Select.Option value="每日观察">每日观察</Select.Option>
                                <Select.Option value="周期结束观察">周期结束观察</Select.Option>
                            </Select>
                        </Form.Item>
                    ) : (
                        params.value
                    );
                },
            },
            { ...sharedOwnColumns.strike_price_ratio(curEditingId), headerName: '执行价%' },
            { ...sharedOwnColumns.up_front },
            { ...sharedOwnColumns.order_type },
            {
                field: 'limit_order_share',
                headerName: '限价股票',
                width: curEditingId === undefined ? 108 : 148,
                cellRenderer: (params: ICellRendererParams) => {
                    return curEditingId === params.node.__objectId && params.value !== 'n/a' ? (
                        <Form.Item name="limit_order_share">
                            <Input style={{ width: 112 }} />
                        </Form.Item>
                    ) : (
                        params.value
                    );
                },
            },
            { ...sharedOwnColumns.limit_order_price(curEditingId) },
            {
                field: 'trade_amount',
                headerName: '投资金额',
                width: curEditingId === undefined ? 108 : 128,
                valueFormatter: NumericFormatter,
                cellRenderer: (params: ICellRendererParams) => {
                    return curEditingId === params.node.__objectId ? (
                        <Form.Item name="trade_amount">
                            <InputNumber controls={false} min={0} />
                        </Form.Item>
                    ) : (
                        params.valueFormatted
                    );
                },
            },
            { ...sharedOwnColumns.created_at },
        ],
    },
    {
        name: '现金',
        type: 'cash',
    },
    {
        name: '换汇',
        type: 'fx',
    },
    {
        name: '贷款',
        type: 'loan',
    },
    {
        name: '存款',
        type: 'deposit',
    },
];
const currenyToChinese = (currency: string | null | undefined) => {
    if (!currency) return '/';
    switch (currency) {
        case 'CNH':
            return '人民币';
        case 'USD':
            return '美元';
        case 'HKD':
            return '港币';
        case 'SGD':
            return '新币';
        case 'CHF':
            return '瑞士法郎';
    }
    return currency;
};
