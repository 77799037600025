import React, { useMemo, useState } from 'react';
import { Button, Collapse, Space, Table, Modal } from 'antd';
import PanelHeader from './PanelHeader';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useCallback } from 'react';
import type { RecomProductList } from '../../service/type';
import { showError, stopPropagation } from './util';
import { deleteRecomProduct } from '../../service';

const { confirm } = Modal;
const { Panel } = Collapse;

const PanelComponent: React.FC<{
    recomProduct: RecomProductList[0];
    handleRefreshList: () => void;
}> = ({ recomProduct, handleRefreshList }) => {
    const columns = useMemo(() => {
        return [
            {
                title: '产品名',
                dataIndex: 'name',
            },
            {
                title: 'ISIN',
                dataIndex: 'isin',
            },
            {
                title: '操作',
                key: 'action',
                width: 420,
                render: ({ isin, tag_id }: { isin: string; tag_id: string }) => (
                    <Space size="middle">
                        <Button
                            type="primary"
                            danger
                            onClick={() => {
                                confirm({
                                    title: '确认删除?',
                                    icon: <ExclamationCircleOutlined />,
                                    onOk() {
                                        deleteRecomProduct({
                                            isin,
                                            tag_id,
                                        }).then(handleRefreshList, showError);
                                    },
                                });
                            }}
                        >
                            删除
                        </Button>
                    </Space>
                ),
            },
        ];
    }, [handleRefreshList]);
    const [activeKey, setActiveKey] = useState<string[]>([]);
    const setActive = useCallback(() => {
        //添加产品后需要自动展开，所以需要受控
        setActiveKey(['1']);
    }, []);
    return (
        <Collapse
            className="Asset-Allocation-Plan-Panel"
            style={{ marginTop: 18 }}
            activeKey={activeKey}
            onChange={(target) =>
                Array.isArray(target) ? setActiveKey(target) : setActiveKey([target])
            }
        >
            <Panel
                header={
                    <PanelHeader
                        onClick={stopPropagation}
                        recomProduct={recomProduct}
                        handleRefreshList={handleRefreshList}
                        setActive={setActive}
                    />
                }
                key={'1'}
            >
                <Table
                    columns={columns}
                    dataSource={recomProduct.product_list}
                    pagination={false}
                    rowKey="isin"
                    scroll={{ x: true }}
                />
            </Panel>
        </Collapse>
    );
};

export default PanelComponent;
