import React, { useEffect, useMemo, useState } from 'react';
import { AccountManageType, TransStatus, TransactionOrderType, TransactionType } from '../../type';
import styles from './index.module.less';
import LKRadioGroup from '../../../../components/LKRadioGroup';
import { Form, Select } from 'antd';
import LKCheckbox from '../../../../components/LKCheckbox';
import { useRequest } from 'ahooks';
import { searchAccountWithNumber } from '../../request';
import { useForm } from 'antd/lib/form/Form';
export interface TransactionHeaderProps {
    account_id?: string;
    account_manage_type?: number;
    transaction_status?: number[];
    transaction_type?: number[];
    event_type?: number[];
    account_number?: string;
}
const TransactionHeader = ({
    onChange,
    initValues,
}: {
    onChange: (parmas: TransactionHeaderProps) => void;
    initValues?: TransactionHeaderProps;
}) => {
    const accountOptions = Object.keys(AccountManageType)
        .filter((key) => !isNaN(Number(AccountManageType[key as keyof typeof AccountManageType])))
        .map((key) => ({
            label: key,
            value: Number(AccountManageType[key as keyof typeof AccountManageType]),
            width: '200px',
        }));
    const statusOptions = Object.keys(TransStatus)
        .filter((key) => !isNaN(Number(TransStatus[key as keyof typeof TransStatus])))
        .map((key) => ({
            label: key,
            value: Number(TransStatus[key as keyof typeof TransStatus]),
            width: '200px',
        }));
    const typeOptions = Object.keys(TransactionOrderType)
        .filter(
            (key) => !isNaN(Number(TransactionOrderType[key as keyof typeof TransactionOrderType]))
        )
        .map((key) => ({
            label: key,
            value: Number(TransactionOrderType[key as keyof typeof TransactionOrderType]),
            width: '200px',
        }));
    const eventOptions = Object.keys(TransactionType)
        .filter(
            (key) => TransactionOrderType[key as keyof typeof TransactionOrderType] === undefined
        )
        .filter((key) => !isNaN(Number(TransactionType[key as keyof typeof TransactionType])))
        .map((key) => ({
            label: key,
            value: Number(TransactionType[key as keyof typeof TransactionType]),
            width: '200px',
        }));
    const [searchKey, setSearchKey] = useState('');
    const { data: accountList, run, cancel } = useRequest(searchAccountWithNumber, {
        manual: true,
        defaultParams: [
            {
                account_number: '',
            },
        ],
    });
    const [form] = useForm();
    useEffect(() => {
        if (initValues) {
            if (initValues.account_number && initValues.account_number !== '') {
                setSearchKey(initValues.account_number);
            }
            form.setFieldsValue({
                ...initValues,
            });
        } else {
            form.setFieldsValue({
                account_manage_type: 1,
                transaction_status: [
                    TransStatus.Created,
                    TransStatus.Traded,
                    TransStatus.Settled,
                    TransStatus.Verified,
                    TransStatus.Valued,
                    TransStatus.Placeable,
                ],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        // if (searchKey === '') {
        //     return;
        // }
        run({
            account_number: searchKey,
        });
        return () => {
            cancel();
        };
    }, [cancel, run, searchKey]);
    const handleSearch = (searchKey: string) => {
        setSearchKey(searchKey);
    };
    const options = useMemo(() => {
        if ((accountList?.data ?? []).length > 0) {
            return accountList?.data.map((item) => {
                return (
                    <Select.Option value={item.account_id} key={item.account_id}>
                        {item.bank_name +
                            ' - ' +
                            item.vendor_sub_account_id +
                            ' - ' +
                            item.account_name}
                    </Select.Option>
                );
            });
        }
        return undefined;
    }, [accountList]);

    return (
        <Form
            form={form}
            style={{ width: '100%' }}
            labelAlign="right"
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 22 }}
            onValuesChange={() => {
                let values = form.getFieldsValue();
                if (values.account_id === undefined) {
                    setSearchKey('');
                }
                onChange({
                    account_id: values.account_id ?? '',
                    transaction_type: values.transaction_type,
                    account_manage_type: values.account_manage_type,
                    transaction_status: values.transaction_status,
                    account_number:
                        accountList?.data?.find((item) => item.account_id === values.account_id)
                            ?.vendor_sub_account_id ?? '',
                    event_type: values.event_type,
                });
            }}
        >
            <Form.Item label="Account Number" name="account_id">
                <Select
                    style={{ width: 'calc(87.5% - 10px)' }}
                    showSearch
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={handleSearch}
                    placeholder={'Enter account number'}
                    allowClear
                >
                    {options}
                </Select>
            </Form.Item>
            <Form.Item label="Accounts" name="account_manage_type">
                <LKRadioGroup className={styles.value} datasource={accountOptions} />
            </Form.Item>
            <Form.Item label="Trans. Status" name="transaction_status">
                <LKCheckbox withReset className={styles.value} datasource={statusOptions} />
            </Form.Item>
            <Form.Item label="Trans. Type" name="transaction_type">
                <LKCheckbox withReset className={styles.value} datasource={typeOptions} />
            </Form.Item>
            <Form.Item label="Events. Type" name="event_type">
                <LKCheckbox withReset className={styles.value} datasource={eventOptions} />
            </Form.Item>
        </Form>
    );
};

export default TransactionHeader;
