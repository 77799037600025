import { Button, Empty, Result } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getRecordList, setRecordTags } from '../../../../store/actions/actions';
import { LKRootState } from '../../../../store/store';
import RecordItem from './component/RecordItem';
import RecordTags from './component/RecordTags';
import style from './index.module.scss';
import { getTrackRecordTags } from './request';
import { CustomerDetailCardProps } from '../CustomerDetail/type';
interface CustomerRecordProps extends CustomerDetailCardProps {
    addCallback: () => void;
}
const CustomerRecord = ({ customer_id, addCallback }: CustomerRecordProps) => {
    const disptch = useDispatch();

    const track_list = useSelector((state: LKRootState) => state.customer.track_list);
    const tags = useSelector((state: LKRootState) => state.customer.tags);
    const [showError, setShowError] = useState(false);

    const history = useHistory();
    const request = useCallback(() => {
        getTrackRecordTags()
            .then((res) => {
                disptch(setRecordTags(res.data.customer_track_tags));
                setShowError(false);
            })
            .catch((e) => {
                setShowError(true);
            });
    }, [disptch, setShowError]);
    useEffect(() => {
        request();
    }, [customer_id, request]);
    useEffect(() => {
        disptch(getRecordList(customer_id));
    }, [customer_id, disptch]);
    return (
        <>
            {showError && (
                <Result
                    status="error"
                    title="加载失败，请点击重试"
                    extra={
                        <Button
                            type="primary"
                            onClick={() => {
                                request();
                            }}
                        >
                            重试
                        </Button>
                    }
                />
            )}

            {!showError && (
                <div className={style.content}>
                    {(track_list?.customer_track_logs ?? []).length > 0 && (
                        <div className={style.header}>
                            <RecordTags
                                tags={(tags ?? []).map((item) => item.name)}
                                touchBack={(tag) => {
                                    history.push({
                                        pathname: `/app/customer/list/trackRecord/${customer_id}/${tag}`,
                                    });
                                }}
                            />
                        </div>
                    )}
                    {(track_list?.customer_track_logs ?? []).length === 0 && (
                        <Empty description="暂无记录" style={{ marginTop: '50px' }}>
                            <Button type="primary" onClick={addCallback}>
                                新增记录
                            </Button>
                        </Empty>
                    )}
                    <div className={style.list}>
                        {(track_list?.customer_track_logs ?? []).map((item) => (
                            <RecordItem key={item.id} data={item} customer_id={customer_id} />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default CustomerRecord;
