import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import AccountItem from './FormItems/AccountItem';
import {
    Asset,
    GinkgoTransFormRefType,
    TransAQExtInfo,
    TransStatus,
    TransactionInfo,
} from '../../../type';
import DateItem from './FormItems/DateItem';
import { Form } from 'antd';
import LKRadioGroup, { LKRadioGroupProps } from '../../../../../components/LKRadioGroup';
import { useForm } from 'antd/lib/form/Form';
import { GinkgoInput, GinkgoInputNumber } from './FormItems/GinkgoFieldItems';
import BondValidity from './FormItems/BondValidity';
import { useLatest } from 'ahooks';
import SearchUnderlying from './FormItems/SearchUnderlying';
import TenorItem from './FormItems/TenorItem';
import moment from 'moment';
const OptionForm = forwardRef<GinkgoTransFormRefType, TransactionInfo>(
    (data: TransactionInfo, onRef) => {
        const [form] = useForm();
        const [priceType, setPriceType] = useState<number>();
        const [dayOrder, setDayOrder] = useState<number>();
        const latestDayOrder = useLatest(dayOrder);
        const [currentPrice, setCurrentPrice] = useState<Asset>();
        const latestCurrentPrice = useLatest(currentPrice);
        const [timezone, setTimezone] = useState(data.time_zone || '');

        useImperativeHandle(onRef, () => {
            return {
                getFieldsValues: async (validate) => {
                    if (validate === false) {
                        return new Promise((resolve) => {
                            return resolve(configValues(form.getFieldsValue()));
                        });
                    } else {
                        return form.validateFields().then(configValues);
                    }
                },
            };
        });

        const configValues = (values: any) => {
            return {
                ...values,
                client_id: data.client_id,
                transaction_type: data.type.code,
                id: data.id,
                ext_info: {
                    ...data.ext_info,
                    ...values.ext_info,
                    is_day_order: dayOrder,
                    underlyings: [latestCurrentPrice.current?.isin],
                    underlying_name: latestCurrentPrice.current?.name,
                    underlying_spec_name: latestCurrentPrice.current?.spec_name,
                    underlying_open_price: latestCurrentPrice.current?.underlying_open_price,
                    price: values.ext_info?.price?.toString(),
                },
                status_code: data.status.code,
            };
        };

        const priceTypeOptions: LKRadioGroupProps['datasource'] = [
            {
                label: 'LMT',
                value: 1,
                width: '12.5%',
            },
            {
                label: 'MKT',
                value: 2,
                width: '12.5%',
            },
            {
                label: 'Market Close',
                value: 3,
                width: '25%',
            },
            {
                label: 'Market Open',
                value: 4,
                width: '25%',
            },
            {
                label: 'VWAP',
                value: 5,
                width: '12.5%',
            },
        ];

        const optionTypeOptions: LKRadioGroupProps['datasource'] = [
            //1-European Call, 2-European Put , 3-American Call, 4-American Put
            {
                label: 'European Call',
                value: 1,
                width: '25%',
            },
            {
                label: 'European Put',
                value: 2,
                width: '25%',
            },
            {
                label: 'American Call',
                value: 3,
                width: '25%',
            },
            {
                label: 'American Put',
                value: 4,
                width: '25%',
            },
        ];

        const PlaceableReadOnly = useMemo(() => {
            return data.status.code !== TransStatus.Created;
        }, [data]);
        const TradedReadOnly = useMemo(() => {
            return data.status.code === TransStatus.Traded;
        }, [data]);
        const PlaceableRequired = useMemo(() => {
            return data.status.code === TransStatus.Created;
        }, [data]);
        const TradedRequired = useMemo(() => {
            return data.status.code === TransStatus.Placeable;
        }, [data]);
        useEffect(() => {
            let ext_info = data.ext_info as TransAQExtInfo;
            setDayOrder(ext_info.is_day_order);
            setPriceType((data.ext_info as any).price_type);
            form.setFieldsValue({
                ...data,
                trade_date:
                    typeof data.trade_date === 'number' ? data.trade_date : moment().valueOf(),
            });
        }, [data, form]);
        const underlying = useMemo(() => {
            if (data) {
                return (data?.ext_info as TransAQExtInfo)?.underlyings
                    ? (data?.ext_info as TransAQExtInfo).underlyings![0]
                    : undefined;
            }
        }, [data]);
        return (
            <Form labelCol={{ span: 6 }} labelWrap wrapperCol={{ span: 18 }} form={form}>
                <AccountItem
                    clientId={data.client_id}
                    name={['ext_info', 'account_id']}
                    form={form}
                    readOnlyValue={data.ext_info.account_id}
                    selectCallback={(bank) => {}}
                    width="calc(62.5% - 10px)"
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                />
                <SearchUnderlying
                    withCurrency={data !== undefined}
                    form={form}
                    needPrice={data === undefined}
                    readOnly={PlaceableReadOnly}
                    isin={underlying}
                    required={PlaceableRequired}
                    width="calc(62.5% - 10px)"
                    currentPrice={(price) => {
                        setTimezone(price?.time_zone || '');
                        setCurrentPrice(price);
                        // handleChangePrice(price);
                    }}
                />

                <Form.Item
                    label="Option Type"
                    name={['ext_info', 'option_type']}
                    rules={[{ required: PlaceableRequired, message: 'Option Type is required' }]}
                >
                    <LKRadioGroup datasource={optionTypeOptions} readOnly={PlaceableReadOnly} />
                </Form.Item>
                <TenorItem
                    type="onlyMonth"
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                />
                <Form.Item
                    label="Strike"
                    name={['ext_info', 'strike_price_rate']}
                    rules={[{ required: PlaceableRequired, message: 'Strike is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        addonAfter="%"
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>

                <Form.Item
                    label="Option Fee(%)"
                    name={['ext_info', 'option_fee_rate']}
                    rules={[{ required: PlaceableRequired, message: 'Option Fee(%) is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        addonAfter="%"
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>

                <Form.Item
                    label="No. of Shares"
                    name={['ext_info', 'number_of_shares']}
                    rules={[
                        {
                            required: PlaceableRequired,
                            message: 'No. of Shares is required',
                        },
                    ]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>

                <Form.Item
                    label="Price Type"
                    name={['ext_info', 'price_type']}
                    rules={[{ required: PlaceableRequired, message: 'Price Type is required' }]}
                >
                    <LKRadioGroup
                        datasource={priceTypeOptions}
                        onChange={(value) => {
                            setPriceType(value as number);
                        }}
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>
                {priceType === 1 && (
                    <Form.Item
                        label="Price"
                        name={['ext_info', 'price']}
                        rules={[{ required: PlaceableRequired, message: 'Price is required' }]}
                    >
                        <GinkgoInputNumber
                            style={{ width: 'calc(62.5% - 10px)' }}
                            readOnly={PlaceableReadOnly}
                        />
                    </Form.Item>
                )}
                <Form.Item
                    name={['ext_info', 'validity']}
                    label="Validity"
                    rules={[
                        {
                            required: PlaceableRequired,
                            message: 'Validity is required',
                            validator(rule, value, callback) {
                                if (latestDayOrder.current === 1) {
                                    callback();
                                    return;
                                }
                                if (!value) {
                                    callback('Validity is required');
                                    return;
                                }
                                callback();
                            },
                        },
                    ]}
                >
                    <BondValidity
                        readOnly={PlaceableReadOnly}
                        isDayOrder={(data.ext_info as any).is_day_order}
                        dayChange={(dayOrder) => {
                            setDayOrder(dayOrder ? 1 : 2);
                            form.validateFields([['ext_info', 'validity']]);
                        }}
                    />
                </Form.Item>

                <Form.Item label="Custodian Trade ID" name={['ext_info', 'custodian_trade_id']}>
                    <GinkgoInput
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>

                <Form.Item label="ISIN" name={['ext_info', 'isin']}>
                    <GinkgoInput
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Upfront(%)"
                    name={['ext_info', 'up_front_rate']}
                    rules={[{ required: TradedRequired, message: 'Upfront(%) is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                        addonAfter="%"
                    />
                </Form.Item>
                <Form.Item
                    label="Upfront($)"
                    name={['ext_info', 'up_front_amount']}
                    rules={[{ required: TradedRequired, message: 'Upfront($) is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Fill Option Fee(%)"
                    name={['ext_info', 'fill_option_fee_rate']}
                    rules={[
                        { required: TradedRequired, message: 'Fill Option Fee(%) is required' },
                    ]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        addonAfter="%"
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>

                <Form.Item
                    label="Fill No. of Shares"
                    name={['ext_info', 'fill_number_of_shares']}
                    rules={[
                        {
                            required: TradedRequired,
                            message: 'Fill No. of Shares is required',
                        },
                    ]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Fill Price"
                    name={['ext_info', 'fill_price']}
                    rules={[{ required: TradedRequired, message: 'Fill Price is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <DateItem
                    label="Maturity Date"
                    name={['ext_info', 'maturity_date']}
                    width="calc(62.5% - 10px)"
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                    timezone={timezone}
                />
                <DateItem
                    label="Trade Date"
                    name="trade_date"
                    width="calc(62.5% - 10px)"
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                    timezone={timezone}
                />
                <DateItem
                    label="Value Date"
                    name="value_date"
                    width="calc(62.5% - 10px)"
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                    timezone={timezone}
                />
            </Form>
        );
    }
);

export default OptionForm;
