import React, { useContext, useEffect, useState } from 'react';
import * as echarts from 'echarts';
import { EChartOption } from 'echarts';
import style from './index.module.scss';
import { BankInfo, CustomerAssetModel } from '../../../../service/requests/CustomerManage';
import { Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { MobileStateContext } from '../../../../App';
const { Panel } = Collapse;
const PositionCharts = ({
    data,
    resize,
}: {
    data?: CustomerAssetModel['customer_asset_summary'];
    resize: number;
}) => {
    const [echart, setEchart] = useState<echarts.ECharts>();
    const mobileState = useContext(MobileStateContext);
    useEffect(() => {
        if (echart) {
            setTimeout(() => {
                echart.resize();
            }, 100);
        }
    }, [resize, echart]);
    useEffect(() => {
        let context = document.getElementById('main') as HTMLDivElement;
        if (data && context) {
            /// itemStyle.color 可以改变label颜色
            let map = data.asset_distribution?.position_percent_by_bank?.map((item) => {
                return {
                    value: Number.parseFloat(item.percent),
                    name: `${item.bank_name} ${Number.parseFloat(item.percent)}%`,
                };
            });
            const option: EChartOption = {
                color: ['#165DFF', '#722ED1', '#F7BA1E', '#14C9C9'],
                legend: {
                    top: 'bottom',
                    left: 'center',
                    // orient: 'vertical',
                },
                toolbox: {
                    show: false,
                    feature: {
                        mark: { show: true },
                        dataView: { show: true, readOnly: false },
                        restore: { show: true },
                        saveAsImage: { show: true },
                    },
                },
                grid: {
                    width: 'auto',
                    top: '0%',
                },
                series: [
                    {
                        name: '暂无银行数据',
                        type: 'pie',
                        radius: ['50%', '60%'],
                        center: ['50%', '40%'],
                        // roseType: 'radius',
                        itemStyle: {
                            borderRadius: 5,
                        },
                        label: {
                            show: true,
                            position: 'center',
                            formatter: () => {
                                return '银行分布';
                            },
                            color: '#333',
                            lineHeight: 20,
                            fontSize: 14,
                            padding: 0,
                        },
                        emphasis: {
                            label: {
                                show: true,
                            },
                        },
                        data: map,
                    },
                ],
            };
            if (context) {
                var myChart = echarts.init(context);
                setEchart(myChart);
                myChart.resize();
                myChart.setOption(option);
            }
        }
    }, [data, mobileState.IsMobileMachine]);
    return (
        <>
            <div
                id="main"
                style={
                    mobileState.IsMobileMachine
                        ? { width: '100%', height: '240px' }
                        : { width: '36%', height: '100%' }
                }
            />
            <Collapse
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}
                expandIconPosition={'right'}
                className={style.banks}
                ghost
                // onChange={headerChangeFoldStatus}
            >
                {data?.asset_distribution?.position_percent_by_bank?.map((item, index) => {
                    return (
                        <Panel key={index} header={<PanelHeader item={item} />}>
                            <div className={style.bankDetail} key={index}>
                                {item.account_assets?.map((account) => (
                                    <AccountItem data={account} key={account.account_id} />
                                ))}
                            </div>
                        </Panel>
                    );
                })}
            </Collapse>
        </>
    );
};
const PanelHeader = ({ item }: { item: BankInfo }) => {
    return (
        <div style={{ display: 'flex', flex: '1', alignItems: 'center' }}>
            <img
                src={item.bank_logo}
                alt=""
                style={{ width: '24px', height: '24px', marginRight: '10px' }}
            />
            <div>{item.bank_name}</div>
            <div style={{ flex: '1', width: '0', textAlign: 'right' }}>${item.total_asset}</div>
        </div>
    );
};
const accountTypeName = ['常规', '专户', '全委'];
const AccountItem = ({ data }: { data: BankInfo['account_assets'][number] }) => {
    return (
        <div className={style.account}>
            <div className={`${style.label} ${style['type' + (data.account_tag ?? 1).toString()]}`}>
                {accountTypeName[(data.account_tag ?? 1) - 1]}
            </div>
            <div className={style.accountTitle}>{`${data.account_type}-${
                data.account_display_name ?? data.account_name
            }${data.vendor_sub_account_id ? `-${data.vendor_sub_account_id}` : ''}`}</div>
            <div className={style.value}>${data.c_net_asset}</div>
        </div>
    );
};
export default PositionCharts;
