import React from 'react';
import { useMemo } from 'react';
import {
    Asset,
    AssetOptionExtInfo,
    AssetType,
    BankType,
    CapitalCallFrequency,
    FundStruct,
    RedemptionFrequency,
    TransELNExtInfo,
} from '../Transaction/type';
import { CopyOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import styles from './ProductsPriceTable/index.module.scss';
import moment from 'moment';
import { FormatNumber, math } from '../../utils';
import { ProductQuoteDetailType } from '../GKQuote/type';
import { EditableCellType } from '../GKQuote/component/EditableCell';
import { ColumnType, ColumnsType } from 'antd/es/table';
import { aqdqObservationFrequency, fcnCouponFrequency, OptionSide, OptionType } from './Form/data';

export const QuoteSubListMoreColums = ({
    type,
    data,
}: {
    type: AssetType;
    data: ProductQuoteDetailType;
}) => {
    const columns = useMemo<ColumnsType<any>>(() => {
        switch (type) {
            case AssetType.FCN:
                return [
                    {
                        title: '下单类型',
                        dataIndex: ['ext_info', 'price_type'],
                        editable: data.status.code < 3,
                        type: EditableCellType.Select,
                        options: [
                            { key: '限价单', value: 1 },
                            { key: '市价单', value: 2 },
                            { key: 'Market Close', value: 3 },
                            { key: 'Market Open', value: 4 },
                            { key: 'VWAP', value: 5 },
                            // VWAP、Market Open、Market Close
                        ],
                        width: 150,
                        render(value) {
                            switch (value) {
                                case 1:
                                    return '限价单';
                                case 2:
                                    return '市价单';
                                case 5:
                                    return 'VWAP';
                                case 4:
                                    return 'Market Open';
                                case 3:
                                    return 'Market Close';
                                default:
                                    return '--';
                            }
                        },
                    },
                    {
                        title: '限价金额',
                        dataIndex: ['ext_info', 'price'],
                        editable: data.status.code < 3,
                        type: EditableCellType.Input,
                        width: 150,
                        render(value, record, index) {
                            return record.ext_info.price_type === 1 ? value : '--';
                        },
                    },
                    {
                        title: `交易金额`,
                        dataIndex: ['ext_info', 'nominal_amount'],
                        editable: data.status.code < 3,
                        type: EditableCellType.Input,
                        width: 150,
                    },
                ];
            case AssetType.ELN:
                return [
                    {
                        title: '下单类型',
                        dataIndex: ['ext_info', 'price_type'],
                        editable: data.status.code < 3,
                        type: EditableCellType.Select,
                        options: [
                            { key: '限价单', value: 1 },
                            { key: '市价单', value: 2 },
                            { key: 'Market Close', value: 3 },
                            { key: 'Market Open', value: 4 },
                            { key: 'VWAP', value: 5 },
                            // VWAP、Market Open、Market Close
                        ],
                        width: 150,
                        render(value) {
                            switch (value) {
                                case 1:
                                    return '限价单';
                                case 2:
                                    return '市价单';
                                case 5:
                                    return 'VWAP';
                                case 4:
                                    return 'Market Open';
                                case 3:
                                    return 'Market Close';
                                default:
                                    return '--';
                            }
                        },
                    },
                    {
                        title: '限价金额',
                        dataIndex: ['ext_info', 'price'],
                        editable: data.status.code < 3,
                        type: EditableCellType.Input,
                        width: 150,
                        render(value, record, index) {
                            return record.ext_info.price_type === 1 ? value : '--';
                        },
                    },
                    {
                        title: `交易金额`,
                        dataIndex: ['ext_info', 'nominal_amount'],
                        editable: data.status.code < 3,
                        type: EditableCellType.Input,
                        width: 150,
                    },
                ];
            case AssetType.Option:
                return [
                    {
                        title: '下单类型',
                        dataIndex: ['ext_info', 'price_type'],
                        editable: data.status.code < 3,
                        type: EditableCellType.Select,
                        options: [
                            { key: '限价单', value: 1 },
                            { key: '市价单', value: 2 },
                            { key: 'Market Close', value: 3 },
                            { key: 'Market Open', value: 4 },
                            { key: 'VWAP', value: 5 },
                            // VWAP、Market Open、Market Close
                        ],
                        width: 150,
                        render(value) {
                            switch (value) {
                                case 1:
                                    return '限价单';
                                case 2:
                                    return '市价单';
                                case 5:
                                    return 'VWAP';
                                case 4:
                                    return 'Market Open';
                                case 3:
                                    return 'Market Close';
                                default:
                                    return '--';
                            }
                        },
                    },
                    {
                        title: '限价金额',
                        dataIndex: ['ext_info', 'price'],
                        editable: data.status.code < 3,
                        type: EditableCellType.Input,
                        width: 150,
                        render(value, record, index) {
                            return record.ext_info.price_type === 1 ? value : '--';
                        },
                    },
                    {
                        title: `交易股数`,
                        dataIndex: ['ext_info', 'trade_qty'],
                        editable: data.status.code < 3,
                        type: EditableCellType.Input,
                        width: 150,
                    },
                ];
            case AssetType.DQ:
            case AssetType.AQ:
                return [
                    {
                        title: '下单类型',
                        dataIndex: ['ext_info', 'price_type'],
                        editable: data.status.code < 3,
                        type: EditableCellType.Select,
                        options: [
                            { key: '限价单', value: 1 },
                            { key: '市价单', value: 2 },
                            { key: 'Market Close', value: 3 },
                            { key: 'Market Open', value: 4 },
                            { key: 'VWAP', value: 5 },
                            // VWAP、Market Open、Market Close
                        ],
                        width: 150,
                        render(value) {
                            switch (value) {
                                case 1:
                                    return '限价单';
                                case 2:
                                    return '市价单';
                                case 5:
                                    return 'VWAP';
                                case 4:
                                    return 'Market Open';
                                case 3:
                                    return 'Market Close';
                                default:
                                    return '--';
                            }
                        },
                    },
                    {
                        title: '限价金额',
                        dataIndex: ['ext_info', 'price'],
                        editable: data.status.code < 3,
                        type: EditableCellType.Input,
                        width: 150,
                        render(value, record, index) {
                            return record.ext_info.price_type === 1 ? value : '--';
                        },
                    },
                    {
                        title: type === AssetType.AQ ? '每天买入股数' : '每天卖出股数',
                        dataIndex: ['ext_info', 'day_volume'],
                        editable: data.status.code < 3,
                        type: EditableCellType.Input,
                        width: 150,
                    },
                    {
                        title: `合计交易金额`,
                        dataIndex: ['ext_info', 'total_amount'],
                        editable: data.status.code < 3,
                        type: EditableCellType.Input,
                        width: 150,
                    },
                ];
            case AssetType.GovernmentBond:
            case AssetType.CorporateBond:
                return [
                    {
                        title: `名义金额-${data.currency}`,
                        dataIndex: ['ext_info', 'nominal_amount'],
                        editable: data.status.code < 3,
                        type: EditableCellType.Input,
                        width: 150,
                    },
                    {
                        title: `交割金额-${data.currency}`,
                        dataIndex: ['ext_info', 'delivery_amount'],
                        width: 150,
                    },
                    {
                        title: '下单类型',
                        dataIndex: ['ext_info', 'price_type'],
                        editable: data.status.code < 3,
                        type: EditableCellType.Select,
                        options: [
                            { key: '限价单', value: 1 },
                            { key: '市价单', value: 2 },
                            { key: 'Market Close', value: 3 },
                            { key: 'Market Open', value: 4 },
                            { key: 'VWAP', value: 5 },
                            // VWAP、Market Open、Market Close
                        ],
                        width: 150,
                        render(value) {
                            switch (value) {
                                case 1:
                                    return '限价单';
                                case 2:
                                    return '市价单';
                                case 5:
                                    return 'VWAP';
                                case 4:
                                    return 'Market Open';
                                case 3:
                                    return 'Market Close';
                                default:
                                    return '--';
                            }
                        },
                    },
                    {
                        title: '限价%',
                        dataIndex: ['ext_info', 'price'],
                        editable: data.status.code < 3,
                        type: EditableCellType.Input,
                        width: 150,
                        render(value, record, index) {
                            return record.order_type === 2 ? value : '--';
                        },
                    },
                ];
            default:
                return [];
        }
    }, [data.currency, data.status.code, type]);
    return {
        columns,
    };
};
export const QouteHook = ({ type, isSub }: { type: AssetType; isSub?: boolean }) => {
    const columns = useMemo<ColumnsType<any>>(() => {
        const dateFormat = 'yyyy-MM-DD';
        switch (type) {
            case AssetType.ELN:
                let a: ColumnsType<Asset> = [
                    {
                        title: '更新时间',
                        dataIndex: 'updated_at',
                        width: 200,
                        render(value) {
                            return moment(value).format('YYYY-MM-DD HH:mm:ss');
                        },
                    },
                ];
                let ret: ColumnsType<Asset> = [
                    {
                        title: (
                            <div>
                                现价 <br /> <span>$</span>
                            </div>
                        ),
                        dataIndex: ['ext_info', 'underlying_open_price'],
                        width: 125,
                    },
                    {
                        title: (
                            <div>
                                年化收益率 <br /> <span>%</span>
                            </div>
                        ),
                        dataIndex: ['ext_info', 'annualized_return_rate'],
                        width: 120,
                        render(value, record) {
                            if (
                                (record.ext_info as TransELNExtInfo).issue_price_rate &&
                                (record.ext_info as TransELNExtInfo).tenor
                            ) {
                                let result = `(1 - ${
                                    (record.ext_info as TransELNExtInfo).issue_price_rate
                                } / 100) / (${
                                    (record.ext_info as TransELNExtInfo).issue_price_rate
                                } / 100) * 360 / ${
                                    (record.ext_info as TransELNExtInfo).tenor
                                } * 100`;
                                return (
                                    math.format(math.evaluate(result), {
                                        notation: 'fixed',
                                        precision: 2,
                                    }) + '%'
                                );
                            }
                            return '-';
                        },
                    },
                    {
                        title: '发行日',
                        dataIndex: ['ext_info', 'issue_date'],
                        width: 100,
                        render(value) {
                            return moment(value).format('YYYY-MM-DD');
                        },
                    },
                    {
                        title: '到期日',
                        dataIndex: ['ext_info', 'maturity_date'],
                        width: 100,
                        render(value) {
                            return moment(value).format('YYYY-MM-DD');
                        },
                    },
                    {
                        title: '期限',
                        dataIndex: ['ext_info', 'tenor'],
                        width: 70,
                        render(value) {
                            return value + '天';
                        },
                    },

                    {
                        title: '敲出价%',
                        dataIndex: ['ext_info', 'knock_out_price_rate'],
                        width: 100,
                        render: (value) => `${FormatNumber(value, 2)}%`,
                    },
                    {
                        title: '敲出价$',
                        width: 100,
                        dataIndex: ['ext_info', 'knock_out_price'],
                        render: (value) => `${FormatNumber(value, 3)}`,
                    },
                    {
                        title: '发行价%',
                        dataIndex: ['ext_info', 'issue_price_rate'],
                        width: 100,
                        render(value) {
                            if (value) {
                                return value + '%';
                            }
                            return '-';
                        },
                    },
                    {
                        title: '发行价$',
                        dataIndex: ['ext_info', 'issue_price_dollar'],
                        width: 100,
                        render(value, record) {
                            if (
                                (record.ext_info as TransELNExtInfo).issue_price_rate &&
                                (record.ext_info as TransELNExtInfo).underlying_open_price
                            ) {
                                return math
                                    .evaluate(
                                        `(
                                    (${
                                        (record.ext_info as TransELNExtInfo).issue_price_rate
                                    } / 100) *
                                    ${(record.ext_info as TransELNExtInfo).underlying_open_price}
                                )`,
                                        { notation: 'fixed', precision: 3 }
                                    )
                                    .toFixed(3);
                            }
                            return '-';
                        },
                    },
                    {
                        title: '执行价%',
                        dataIndex: ['ext_info', 'strike_price_rate'],
                        width: 100,
                        render(value) {
                            if (value) {
                                return FormatNumber(value, 2) + '%';
                            }
                            return '-';
                        },
                    },
                    {
                        title: '执行价$',
                        dataIndex: ['ext_info', 'strike_price'],
                        width: 100,
                        render(value, record) {
                            return FormatNumber(value, 3);
                        },
                    },
                    {
                        title: '观察频率',
                        dataIndex: ['ext_info', 'observation_frequency'],
                        width: 100,
                        render(value) {
                            switch (value) {
                                case 1:
                                    return '每天';
                                case 2:
                                    return '每月';
                                case 3:
                                    return '连续';
                                default:
                                    return '--';
                            }
                        },
                    },
                    {
                        title: '发行方',
                        dataIndex: ['ext_info', 'issuer'],
                        width: 100,
                    },
                ];
                let result = isSub ? ret : [...a, ...ret];
                return [
                    {
                        title: '标的',
                        width: 200,
                        fixed: isSub ? undefined : 'left',
                        render(value, record, index) {
                            return `${
                                record?.ext_info?.underlying_spec_name ??
                                record?.ext_info?.underlying_name
                            } | ${record?.ext_info?.ticker}`;
                        },
                    },
                    ...result,
                ];
            case AssetType.Option:
                let option: ColumnsType<Asset> = [
                    {
                        title: '更新时间',
                        dataIndex: 'updated_at',
                        width: 200,
                        render(value) {
                            return moment(value).format('YYYY-MM-DD HH:mm:ss');
                        },
                    },
                ];
                let optionRet: ColumnsType<Asset> = [
                    {
                        title: '方向',
                        width: 100,
                        dataIndex: ['ext_info', 'option_side'],
                        render(value) {
                            return value === 1 ? '买入' : '卖出';
                        },
                    },
                    {
                        title: '期权类型',
                        width: 140,
                        dataIndex: ['ext_info', 'option_type'],
                        render(value, record) {
                            //1-European Call, 2-European Put , 3-American Call, 4-American Put
                            switch (value) {
                                case 1:
                                    return 'European Call';
                                case 2:
                                    return 'European Put';
                                case 3:
                                    return 'American Call';
                                case 4:
                                    return 'American Put';
                                default:
                                    return '--';
                            }
                        },
                    },
                    {
                        title: (
                            <div>
                                现价 <br /> <span>$</span>
                            </div>
                        ),
                        dataIndex: ['ext_info', 'underlying_open_price'],
                        width: 125,
                        render(value, record) {
                            return FormatNumber(value, 3);
                        },
                    },
                    {
                        title: '盈亏平衡$',
                        dataIndex: ['ext_info', 'break_even_price'],
                        width: 125,
                        render(value, record) {
                            return FormatNumber(value, 3);
                        },
                    },
                    {
                        title: (
                            <div>
                                年化收益率 <br /> <span>%</span>
                            </div>
                        ),
                        dataIndex: ['ext_info', 'annualised_rate_of_return'],
                        width: 120,
                        render(value, record) {
                            let ext_info = record.ext_info as AssetOptionExtInfo;
                            if (ext_info.option_side === 1) {
                                return '-';
                            }
                            if (value) return FormatNumber(value, 2) + '%';
                            return '-';
                        },
                    },
                    {
                        title: '期限',
                        dataIndex: ['ext_info', 'tenor'],
                        width: 70,
                        render(value: string) {
                            return value.replace('M', '个月');
                        },
                    },

                    {
                        title: '行权价%',
                        dataIndex: ['ext_info', 'strike_price_rate'],
                        width: 100,
                        render: (value) => `${FormatNumber(value, 2)}%`,
                    },
                    {
                        title: '行权价$',
                        width: 100,
                        dataIndex: ['ext_info', 'strike_price'],
                        render: (value) => `${FormatNumber(value, 3)}`,
                    },

                    {
                        title: '期权费%',
                        dataIndex: ['ext_info', 'option_fee_rate'],
                        width: 100,
                        render(value) {
                            if (value) {
                                return FormatNumber(value, 2) + '%';
                            }
                            return '-';
                        },
                    },
                    {
                        title: '期权费$',
                        dataIndex: ['ext_info', 'option_fee'],
                        width: 100,
                        render(value, record) {
                            return FormatNumber(value, 3);
                        },
                    },

                    {
                        title: '发行方',
                        dataIndex: ['ext_info', 'issuer'],
                        width: 100,
                    },
                ];
                let optResult = isSub ? optionRet : [...option, ...optionRet];
                return [
                    {
                        title: '标的',
                        width: 200,
                        fixed: isSub ? undefined : 'left',
                        render(value, record, index) {
                            return `${
                                record?.ext_info?.underlying_spec_name ??
                                record?.ext_info?.underlying_name
                            } | ${record?.ext_info?.ticker}`;
                        },
                    },
                    ...optResult,
                ];
            case AssetType.CorporateBond:
                if (isSub) {
                    return [
                        {
                            title: 'Ticker',
                            dataIndex: 'ticker',
                            fixed: isSub ? undefined : 'left',
                            width: 100,
                        },
                        {
                            title: (
                                <div>
                                    价格 <br /> <span>%</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'ask_price'],
                            width: 150,
                            render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                        },
                        {
                            title: (
                                <div>
                                    含息价格 <br /> <span>%</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'ask_price_plus_accrued_interest'],
                            width: 150,
                            render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                        },
                        {
                            title: (
                                <div>
                                    最差收益率 <br /> <span>%</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'ytw'],
                            width: 150,
                            render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                        },
                        {
                            title: (
                                <div>
                                    到期收益率 <br /> <span>%</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'ytm'],
                            width: 150,
                            render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                        },
                        {
                            title: (
                                <div>
                                    年化票息 <br /> <span>%</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'coupon'],
                            width: 150,
                            render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                        },
                        {
                            title: 'ISIN',
                            dataIndex: 'isin',
                            width: 150,
                        },
                        {
                            title: (
                                <div>
                                    信用评级 <br /> <span>S&P</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'credit_rating_sp'],
                            width: 150,
                        },
                        {
                            title: '发行方-英',
                            dataIndex: ['ext_info', 'issuer_en'],
                            width: 150,
                        },
                        {
                            title: '发行方-中',
                            dataIndex: ['ext_info', 'issuer_cn'],
                            width: 150,
                        },
                        {
                            title: '久期',
                            dataIndex: ['ext_info', 'duration'],
                            width: 150,
                        },
                        {
                            title: '优先级',
                            dataIndex: ['ext_info', 'seniority'],
                            width: 150,
                        },
                        {
                            title: '下一赎回日',
                            dataIndex: ['ext_info', 'next_call_date'],
                            width: 150,
                            render: (value) => (value ? moment(value).format(dateFormat) : ''),
                        },
                        {
                            title: '到期日',
                            dataIndex: ['ext_info', 'maturity_date'],
                            width: 150,
                            render: (value) => (value ? moment(value).format(dateFormat) : ''),
                        },
                        {
                            title: '到期时间',
                            dataIndex: ['ext_info', 'residual_maturity'],
                            width: 150,
                        },
                    ];
                } else {
                    return [
                        {
                            title: 'Ticker',
                            dataIndex: 'ticker',
                            fixed: isSub ? undefined : 'left',
                            width: 100,
                        },
                        {
                            title: 'ISIN',
                            dataIndex: 'isin',
                            width: 150,
                        },
                        {
                            title: (
                                <div>
                                    信用评级 <br /> <span>S&P</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'credit_rating_sp'],
                            width: 150,
                        },
                        {
                            title: '发行方-英',
                            dataIndex: ['ext_info', 'issuer_en'],
                            width: 150,
                        },
                        {
                            title: '发行方-中',
                            dataIndex: ['ext_info', 'issuer_cn'],
                            width: 150,
                        },
                        {
                            title: (
                                <div>
                                    价格 <br /> <span>%</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'ask_price'],
                            width: 150,
                            render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                        },
                        {
                            title: (
                                <div>
                                    含息价格 <br /> <span>%</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'ask_price_plus_accrued_interest'],
                            width: 150,
                            render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                        },
                        {
                            title: (
                                <div>
                                    最差收益率 <br /> <span>%</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'ytw'],
                            width: 150,
                            render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                        },
                        {
                            title: (
                                <div>
                                    到期收益率 <br /> <span>%</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'ytm'],
                            width: 150,
                            render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                        },
                        {
                            title: (
                                <div>
                                    年化票息 <br /> <span>%</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'coupon'],
                            width: 150,
                            render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                        },
                        {
                            title: '久期',
                            dataIndex: ['ext_info', 'duration'],
                            width: 150,
                        },
                        {
                            title: '优先级',
                            dataIndex: ['ext_info', 'seniority'],
                            width: 150,
                        },
                        {
                            title: '下一赎回日',
                            dataIndex: ['ext_info', 'next_call_date'],
                            width: 150,
                            render: (value) => (value ? moment(value).format(dateFormat) : ''),
                        },
                        {
                            title: '到期日',
                            dataIndex: ['ext_info', 'maturity_date'],
                            width: 150,
                            render: (value) => (value ? moment(value).format(dateFormat) : ''),
                        },
                        {
                            title: '到期时间',
                            dataIndex: ['ext_info', 'residual_maturity'],
                            width: 150,
                        },
                    ];
                }
            case AssetType.GovernmentBond:
                if (isSub) {
                    return [
                        {
                            title: (
                                <div>
                                    价格 <br /> <span>%</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'ask_price'],
                            width: 150,
                            render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                        },
                        {
                            title: (
                                <div>
                                    含息价格 <br /> <span>%</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'ask_price_plus_accrued_interest'],
                            width: 150,
                            render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                        },
                        {
                            title: (
                                <div>
                                    到期收益率 <br /> <span>%</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'ytm'],
                            width: 150,
                            render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                        },
                        {
                            title: 'ISIN',
                            dataIndex: 'isin',
                            width: 150,
                        },
                        {
                            title: (
                                <div>
                                    年化票息 <br /> <span>%</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'coupon'],
                            width: 150,
                            render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                        },
                        {
                            title: '久期',
                            dataIndex: ['ext_info', 'duration'],
                            width: 150,
                        },
                        {
                            title: '到期日',
                            dataIndex: ['ext_info', 'maturity_date'],
                            width: 150,
                            render: (value) => (value ? moment(value).format(dateFormat) : ''),
                        },
                        {
                            title: '到期时间',
                            dataIndex: ['ext_info', 'residual_maturity'],
                            width: 150,
                        },
                    ];
                } else {
                    return [
                        {
                            title: 'ISIN',
                            dataIndex: 'isin',
                            fixed: isSub ? undefined : 'left',
                            width: 150,
                        },
                        {
                            title: (
                                <div>
                                    价格 <br /> <span>%</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'ask_price'],
                            width: 150,
                            render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                        },
                        {
                            title: (
                                <div>
                                    含息价格 <br /> <span>%</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'ask_price_plus_accrued_interest'],
                            width: 150,
                            render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                        },
                        {
                            title: (
                                <div>
                                    到期收益率 <br /> <span>%</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'ytm'],
                            width: 150,
                            render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                        },
                        {
                            title: (
                                <div>
                                    年化票息 <br /> <span>%</span>
                                </div>
                            ),
                            dataIndex: ['ext_info', 'coupon'],
                            width: 150,
                            render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                        },
                        {
                            title: '久期',
                            dataIndex: ['ext_info', 'duration'],
                            width: 150,
                        },
                        {
                            title: '到期日',
                            dataIndex: ['ext_info', 'maturity_date'],
                            width: 150,
                            render: (value) => (value ? moment(value).format(dateFormat) : ''),
                        },
                        {
                            title: '到期时间',
                            dataIndex: ['ext_info', 'residual_maturity'],
                            width: 150,
                        },
                    ];
                }
            case AssetType.DQ:
            case AssetType.AQ:
                let aq: ColumnType<Asset> = {
                    title: '更新时间',
                    dataIndex: 'updated_at',
                    width: 200,
                    render(value) {
                        return moment(value).format('YYYY-MM-DD HH:mm:ss');
                    },
                };
                let aqret: ColumnsType<Asset> = [
                    {
                        title: '标的',
                        fixed: isSub ? undefined : 'left',
                        width: 200,
                        render(value, record, index) {
                            return `${
                                record?.ext_info?.underlying_spec_name ??
                                record?.ext_info?.underlying_name
                            } | ${record?.ext_info?.ticker}`;
                        },
                    },
                    {
                        title: '杠杆',
                        dataIndex: ['ext_info', 'leverage'],
                        width: 100,
                        render(value) {
                            return value === 2 ? '双倍' : '无双倍';
                        },
                    },
                    {
                        title: '保证期',
                        dataIndex: ['ext_info', 'guaranteed_period'],
                        width: 100,
                        render(value) {
                            switch (value) {
                                case 'No Guarantee':
                                    return '无保证期';
                                case '14D':
                                    return '14天';
                                case '1M':
                                    return '1个月';
                                case '2M':
                                    return '2个月';
                                case '3M':
                                    return '3个月';
                                default:
                                    return '--';
                            }
                        },
                    },
                    {
                        title: '期限',
                        dataIndex: ['ext_info', 'tenor'],
                        width: 100,
                        render(value) {
                            const tenorOptions = Array.from(Array(12), (v, k) => ({
                                label: `${k + 1}M`,
                                value: `${k + 1}个月`,
                            }));
                            return tenorOptions.find((item) => item.label === value)?.value;
                        },
                    },
                    {
                        title: (
                            <div>
                                现价 <br /> <span>$</span>
                            </div>
                        ),
                        dataIndex: ['ext_info', 'underlying_open_price'],
                        width: 125,
                        render(value) {
                            return FormatNumber(value, 3);
                        },
                    },

                    {
                        title: '敲出价%',
                        dataIndex: ['ext_info', 'knock_out_price_rate'],
                        width: 100,
                        render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                    },
                    {
                        title: '敲出价$',
                        width: 100,
                        dataIndex: ['ext_info', 'knock_out_price'],
                        render: (value) => `${FormatNumber(value, 3)}`,
                    },

                    {
                        title: '执行价%',
                        dataIndex: ['ext_info', 'strike_price_rate'],
                        width: 100,
                        render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                    },
                    {
                        title: '执行价$',
                        dataIndex: ['ext_info', 'strike_price'],
                        width: 100,
                        render(value, record) {
                            return FormatNumber(value, 3);
                        },
                    },
                    {
                        title: '敲出观察频率',
                        dataIndex: ['ext_info', 'observation_frequency'],
                        width: 100,
                        render(value) {
                            switch (value) {
                                case 1:
                                    return '每天';
                                case 2:
                                    return '每两周';
                                case 3:
                                    return '每月';
                                case 4:
                                    return '到期';
                                default:
                                    return '--';
                            }
                        },
                    },
                    {
                        title: type === AssetType.AQ ? '买入天数' : '卖出天数',
                        dataIndex: ['ext_info', 'trade_days'],
                        width: 100,
                    },
                    {
                        title: '发行方',
                        dataIndex: ['ext_info', 'issuer'],
                        width: 100,
                    },
                ];
                if (isSub === false) {
                    aqret.splice(1, 0, aq);
                }
                return aqret;
            case AssetType.FCN:
                let fcn: ColumnType<Asset> = {
                    title: '更新时间',
                    dataIndex: 'updated_at',
                    width: 200,
                    render(value) {
                        return moment(value).format('YYYY-MM-DD HH:mm:ss');
                    },
                };
                let fcnret: ColumnsType<Asset> = [
                    {
                        title: '标的',
                        width: 200,
                        fixed: isSub ? undefined : 'left',
                        render(value, record, index) {
                            return `${
                                record?.ext_info?.underlying_spec_name ??
                                record?.ext_info?.underlying_name
                            } | ${record?.ext_info?.ticker}`;
                        },
                    },
                    {
                        title: (
                            <div>
                                现价 <br /> <span>$</span>
                            </div>
                        ),
                        dataIndex: ['ext_info', 'underlying_open_price'],
                        width: 125,
                    },
                    {
                        title: (
                            <div>
                                年化票息 <br /> <span>%</span>
                            </div>
                        ),
                        dataIndex: ['ext_info', 'annualized_coupon'],
                        width: 125,
                        render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                    },
                    {
                        title: '保证期',
                        dataIndex: ['ext_info', 'guaranteed_period'],
                        width: 100,
                        render(value) {
                            switch (value) {
                                case 'No Guarantee':
                                    return '无保证期';
                                case '14D':
                                    return '14天';
                                case '1M':
                                    return '1个月';
                                case '2M':
                                    return '2个月';
                                case '3M':
                                    return '3个月';
                                default:
                                    return '--';
                            }
                        },
                    },
                    {
                        title: '期限',
                        dataIndex: ['ext_info', 'tenor'],
                        width: 100,
                        render(value) {
                            const tenorOptions = Array.from(Array(12), (v, k) => ({
                                label: `${k + 1}M`,
                                value: `${k + 1}个月`,
                            }));
                            return tenorOptions.find((item) => item.label === value)?.value;
                        },
                    },
                    {
                        title: '执行价%',
                        dataIndex: ['ext_info', 'strike_price_rate'],
                        width: 100,
                        render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                    },
                    {
                        title: '执行价$',
                        dataIndex: ['ext_info', 'strike_price'],
                        width: 100,
                        render(value, record) {
                            return FormatNumber(value, 3);
                        },
                    },

                    {
                        title: '敲出价%',
                        dataIndex: ['ext_info', 'knock_out_price_rate'],
                        width: 100,
                        render: (value) => (value ? FormatNumber(value, 2) + '%' : '--'),
                    },
                    {
                        title: '敲出价$',
                        width: 100,
                        dataIndex: ['ext_info', 'knock_out_price'],
                        render: (value) => `${FormatNumber(value, 3)}`,
                    },

                    {
                        title: '敲出观察频率',
                        dataIndex: ['ext_info', 'observation_frequency'],
                        width: 100,
                        render(value) {
                            switch (value) {
                                case 1:
                                    return '每天';
                                case 2:
                                    return '每两周';
                                case 3:
                                    return '每月';
                                case 4:
                                    return '到期';
                                default:
                                    return '--';
                            }
                        },
                    },
                    {
                        title: '票息频率',
                        dataIndex: ['ext_info', 'coupon_frequency'],
                        width: 100,
                        render(value) {
                            switch (value) {
                                case 1:
                                    return '每两周';
                                case 2:
                                    return '每月';
                                case 3:
                                    return '每两月';
                                case 4:
                                    return '每季度';
                                case 5:
                                    return '每半年';
                                case 6:
                                    return '每年';
                                default:
                                    return '--';
                            }
                        },
                    },
                    {
                        title: '发行方',
                        dataIndex: ['ext_info', 'issuer'],
                        width: 100,
                    },
                ];
                //fcn 数组插入 fcnret 中的第一个元素
                if (isSub === false) {
                    fcnret.splice(1, 0, fcn);
                }
                return fcnret;
            default:
                return [];
        }
    }, [isSub, type]);

    return {
        columns,
    };
};
export const ExcelExportHook = ({ list, asset }: { list: ColumnsType<Asset>; asset: Asset }) => {
    let obj: { [key: string]: any } = {};
    //根据 asset 和 list 生成 obj ,key为 list 中每个元素的 title
    list.forEach((item: ColumnType<Asset>) => {
        let { title, dataIndex, render } = item;
        // dataIndex 是属性的路径，可能为数组，通过 dataIndex 从 asset 中取值
        if (typeof title === 'string' && title.length > 0) {
            let result;
            if (Array.isArray(dataIndex)) {
                let value = dataIndex.reduce((prevValue: any, currentValue: any) => {
                    if (prevValue && prevValue[currentValue]) {
                        return prevValue[currentValue];
                    } else {
                        return '';
                    }
                }, asset);
                result = value;
            } else {
                result = asset[(dataIndex as string) as keyof Asset];
            }
            if (render) {
                result = render(result, asset, 0);
            }
            obj[title] = result;
        }
    });
    return obj;
};
export const PriceTableHook = ({
    type,
    onEdit,
    onRemove,
    onCopy,
    withoutAction,
}: {
    type: AssetType;
    withoutAction?: boolean;
    onEdit?: (record: Asset) => void;
    onCopy?: (record: Asset) => void;
    onRemove?: (record: Asset) => void;
}) => {
    const dateFormat = 'yyyy-MM-DD';
    const getColumns = (type: AssetType) => {
        let ret: ColumnsType<Asset> = [];
        switch (type) {
            case AssetType.CorporateBond:
            case AssetType.GovernmentBond:
                ret = [
                    {
                        title: 'Ticker',
                        dataIndex: 'ticker',
                        width: 100,
                    },
                    {
                        title: 'ISIN',
                        dataIndex: 'isin',
                        width: 150,
                    },
                    {
                        title: (
                            <div>
                                信用评级 <br /> <span>S&P</span>
                            </div>
                        ),
                        dataIndex: ['ext_info', 'credit_rating_sp'],
                        width: 150,
                    },
                    {
                        title: ' 发行方-英',
                        dataIndex: ['ext_info', 'issuer_en'],
                        width: 225,
                    },
                    {
                        title: ' 发行方-中',
                        dataIndex: ['ext_info', 'issuer_cn'],
                        width: 175,
                    },
                    {
                        title: (
                            <div>
                                指导价格 <br /> <span>%</span>
                            </div>
                        ),
                        dataIndex: ['ext_info', 'ask_price'],
                        width: 125,
                        sorter: true,
                        showSorterTooltip: false,
                        render: (value) => (value ? `${value.toFixed(2)}%` : ''),
                    },
                    {
                        title: (
                            <div>
                                指导含息价格 <br /> <span>%</span>
                            </div>
                        ),
                        dataIndex: ['ext_info', 'ask_price_plus_accrued_interest'],
                        width: 125,
                        render: (value) => (value ? `${value.toFixed(2)}%` : ''),
                    },
                    {
                        title: (
                            <div>
                                最差收益率 <br /> <span>%</span>
                            </div>
                        ),
                        dataIndex: ['ext_info', 'ytw'],
                        width: 100,
                        render: (value) => (value ? `${value.toFixed(2)}%` : ''),
                    },
                    {
                        title: (
                            <div>
                                到期收益率 <br /> <span>%</span>
                            </div>
                        ),
                        dataIndex: ['ext_info', 'ytm'],
                        width: 125,
                        sorter: true,
                        showSorterTooltip: false,
                        render: (value) => (value ? `${value.toFixed(2)}%` : ''),
                    },
                    {
                        title: (
                            <div>
                                年化票息 <br /> <span>%</span>
                            </div>
                        ),
                        dataIndex: ['ext_info', 'coupon'],
                        width: 125,
                        sorter: true,
                        showSorterTooltip: false,
                        render: (value) => (value ? `${value.toFixed(3)}%` : ''),
                    },
                    {
                        title: '久期',
                        dataIndex: ['ext_info', 'duration'],
                        width: 100,
                        render: (value) => (value ? `${value.toFixed(2)}` : ''),
                    },
                    {
                        title: '优先级',
                        dataIndex: ['ext_info', 'seniority'],
                        width: 125,
                    },
                    {
                        title: '下一赎回日',
                        dataIndex: ['ext_info', 'next_call_date'],
                        width: 120,
                        render: (value) => (value ? moment(value).format(dateFormat) : ''),
                    },
                    {
                        title: '到期日',
                        dataIndex: ['ext_info', 'maturity_date'],
                        width: 120,
                        render: (value) => (value ? moment(value).format(dateFormat) : ''),
                    },
                    {
                        title: '到期时间',
                        dataIndex: ['ext_info', 'residual_maturity'],
                        width: 120,
                    },
                ];
                if (type === AssetType.GovernmentBond) {
                    let fields = [
                        'ISIN',
                        '指导价格%',
                        '指导含息价格%',
                        '到期收益率%',
                        '年化票息%',
                        '久期',
                        '到期日',
                        '到期时间',
                    ];
                    ret = ret.filter((i: ColumnType<Asset>) => fields.includes(i.title as string));
                }
                ret[0] = { ...ret[0], fixed: 'left' };
                if (!withoutAction) {
                    ret.push({
                        title: '',
                        render: (_, record) => {
                            return (
                                <div className={styles.btn_area}>
                                    {type !== AssetType.GovernmentBond && (
                                        <Button
                                            className={styles.btn}
                                            onClick={() => {
                                                onEdit && onEdit(record);
                                            }}
                                        >
                                            <Tooltip title="编辑">
                                                <EditOutlined />
                                            </Tooltip>
                                        </Button>
                                    )}

                                    <Button
                                        className={styles.btn}
                                        onClick={() => {
                                            onRemove && onRemove(record);
                                        }}
                                    >
                                        <Tooltip title="移除">
                                            <DeleteOutlined />
                                        </Tooltip>
                                    </Button>
                                </div>
                            );
                        },
                        fixed: 'right',
                        width: 80,
                    });
                }
                return ret;
            case AssetType.AQ:
            case AssetType.DQ:
                ret = [
                    {
                        title: 'Updated Time',
                        dataIndex: 'updated_at',
                        fixed: 'left',
                        render: (value) => getUpdateDuration(value),
                        width: 100,
                    },
                    {
                        title: 'Underlying | Ticker',
                        dataIndex: 'ticker',
                        fixed: 'left',
                        width: 70,
                        render(_, record) {
                            return `${
                                record.ext_info.underlying_spec_name ??
                                record.ext_info.underlying_name
                            } | ${record.ext_info.ticker}`;
                        },
                    },
                    {
                        title: 'Bank',
                        dataIndex: ['ext_info', 'bank_id'],
                        width: 50,
                        render(value) {
                            return Object.keys(BankType).find(
                                (key) => BankType[key as keyof typeof BankType] === value
                            );
                        },
                    },
                    {
                        title: 'Leverage',
                        dataIndex: ['ext_info', 'leverage'],
                        width: 50,
                        render(value) {
                            return value === 2 ? 'leveraged' : 'non-leveraged';
                        },
                    },
                    {
                        title: 'Guaranteed Period',
                        dataIndex: ['ext_info', 'guaranteed_period'],
                        width: 70,
                    },
                    {
                        title: 'Tenor',
                        dataIndex: ['ext_info', 'tenor'],
                        width: 40,
                    },
                    {
                        title: 'Current Stock Price',
                        dataIndex: ['ext_info', 'underlying_open_price'],
                        render: (value) => FormatNumber(value, 3),
                        width: 70,
                    },
                    {
                        title: 'Knock out%',
                        dataIndex: ['ext_info', 'knock_out_price_rate'],
                        render: (value) => `${FormatNumber(value, 2)}%`,
                        width: 50,
                    },
                    {
                        title: 'Knock out$',
                        dataIndex: ['ext_info', 'knock_out_price'],
                        render: (value) => `${FormatNumber(value, 3)}`,
                        width: 50,
                    },
                    {
                        title: 'Strike%',
                        dataIndex: ['ext_info', 'strike_price_rate'],
                        render: (value) => `${FormatNumber(value, 2)}%`,
                        width: 50,
                    },
                    {
                        title: 'Strike$',
                        dataIndex: ['ext_info', 'strike_price'],
                        render: (value) => `${FormatNumber(value, 3)}`,
                        width: 50,
                    },
                    {
                        title: 'Number of Trade Days',
                        dataIndex: ['ext_info', 'trade_days'],
                        width: 50,
                    },
                    {
                        title: 'Issuer',
                        dataIndex: ['ext_info', 'issuer'],
                        width: 40,
                    },
                    {
                        title: 'Observation Frequency',
                        dataIndex: ['ext_info', 'observation_frequency'],
                        width: 50,
                        render: (value) =>
                            Object.keys(aqdqObservationFrequency).find(
                                (key) =>
                                    aqdqObservationFrequency[
                                        key as keyof typeof aqdqObservationFrequency
                                    ] === value
                            ),
                    },
                ];
                if (!withoutAction) {
                    ret.push({
                        title: '',
                        fixed: 'right',
                        width: 80,
                        render: (_, record) => {
                            return (
                                <div className={styles.btn_area}>
                                    <Button
                                        className={styles.btn}
                                        onClick={() => {
                                            onEdit && onEdit(record);
                                        }}
                                    >
                                        <Tooltip title="编辑">
                                            <EditOutlined />
                                        </Tooltip>
                                    </Button>
                                    <Button
                                        className={styles.btn}
                                        onClick={() => {
                                            onCopy && onCopy(record);
                                        }}
                                    >
                                        <Tooltip title="复制">
                                            <CopyOutlined />
                                        </Tooltip>
                                    </Button>
                                    <Button
                                        className={styles.btn}
                                        onClick={() => {
                                            onRemove && onRemove(record);
                                        }}
                                    >
                                        <Tooltip title="移除">
                                            <DeleteOutlined />
                                        </Tooltip>
                                    </Button>
                                </div>
                            );
                        },
                    });
                }
                return ret;
            case AssetType.ELN:
                ret = [
                    {
                        title: 'Updated Time',
                        dataIndex: 'updated_at',
                        fixed: 'left',
                        render: (value) => getUpdateDuration(value),
                        width: 100,
                    },
                    {
                        title: 'Underlying | Ticker',
                        dataIndex: ['ext_info', 'underlyings', 0],
                        width: 240,
                        fixed: 'left',
                        render(_, record) {
                            return `${
                                record.ext_info.underlying_spec_name ||
                                record.ext_info.underlying_name
                            } | ${record.ext_info.ticker}`;
                        },
                    },
                    {
                        title: 'Bank',
                        dataIndex: ['ext_info', 'bank_id'],
                        width: 70,
                        render(value) {
                            return Object.keys(BankType).find(
                                (key) => BankType[key as keyof typeof BankType] === value
                            );
                        },
                    },
                    {
                        title: 'Type',
                        dataIndex: ['ext_info', 'eln_type'],
                        width: 100,
                        render(value) {
                            return value === 1 ? '敲出ELN' : 'ELN';
                        },
                    },
                    {
                        title: 'Issue Date',
                        dataIndex: ['ext_info', 'issue_date'],
                        width: 100,
                        render(value) {
                            return moment(value).format('YYYY-MM-DD');
                        },
                    },
                    {
                        title: 'Maturity Date',
                        dataIndex: ['ext_info', 'maturity_date'],
                        width: 100,
                        render(value) {
                            return moment(value).format('YYYY-MM-DD');
                        },
                    },
                    {
                        title: 'Tenor',
                        dataIndex: ['ext_info', 'tenor'],
                        width: 70,
                        render(value) {
                            return value + '天';
                        },
                    },
                    {
                        title: 'Observation Frequency',
                        dataIndex: ['ext_info', 'observation_frequency'],
                        width: 100,
                        render(value) {
                            switch (value) {
                                case 1:
                                    return '每天';
                                case 2:
                                    return '每月';

                                default:
                                    return '连续';
                            }
                        },
                    },
                    {
                        title: 'Current Stock Price',
                        dataIndex: ['ext_info', 'underlying_open_price'],
                        width: 125,
                    },
                    {
                        title: 'Knock out%',
                        dataIndex: ['ext_info', 'knock_out_price_rate'],
                        width: 100,
                        render(value) {
                            if (value) {
                                return value + '%';
                            }
                            return '-';
                        },
                    },
                    {
                        title: 'Knock Out$',
                        width: 100,
                        render(value, record) {
                            if (
                                (record.ext_info as TransELNExtInfo).knock_out_price_rate &&
                                (record.ext_info as TransELNExtInfo).underlying_open_price
                            ) {
                                return math
                                    .evaluate(
                                        `(
                                    (${
                                        (record.ext_info as TransELNExtInfo).knock_out_price_rate
                                    } / 100) *
                                    ${(record.ext_info as TransELNExtInfo).underlying_open_price}
                                )`,
                                        { notation: 'fixed' }
                                    )
                                    .toFixed(3);
                            }
                        },
                    },
                    {
                        title: 'Issue',
                        dataIndex: ['ext_info', 'issue_price_rate'],
                        width: 100,
                        render(value) {
                            if (value) {
                                return value + '%';
                            }
                            return '-';
                        },
                    },
                    {
                        title: 'Issue$',
                        width: 100,
                        render(value, record) {
                            if (
                                (record.ext_info as TransELNExtInfo).issue_price_rate &&
                                (record.ext_info as TransELNExtInfo).underlying_open_price
                            ) {
                                return math
                                    .evaluate(
                                        `(
                                    (${
                                        (record.ext_info as TransELNExtInfo).issue_price_rate
                                    } / 100) *
                                    ${(record.ext_info as TransELNExtInfo).underlying_open_price}
                                )`,
                                        { notation: 'fixed', precision: 3 }
                                    )
                                    .toFixed(3);
                            }
                        },
                    },
                    {
                        title: 'Strike%',
                        dataIndex: ['ext_info', 'strike_price_rate'],
                        width: 100,
                        render(value) {
                            if (value) {
                                return value + '%';
                            }
                            return '-';
                        },
                    },
                    {
                        title: 'Strike$',
                        width: 100,
                        render(value, record) {
                            if (
                                (record.ext_info as TransELNExtInfo).strike_price_rate &&
                                (record.ext_info as TransELNExtInfo).underlying_open_price
                            ) {
                                let result = math.evaluate(
                                    `(
                                    (${
                                        (record.ext_info as TransELNExtInfo).strike_price_rate
                                    } / 100) *
                                    ${(record.ext_info as TransELNExtInfo).underlying_open_price}
                                )`,
                                    { notation: 'fixed' }
                                ) as number;
                                return result.toFixed(3);
                            }
                        },
                    },
                    {
                        title: 'Annualised Rate of Return',
                        dataIndex: ['ext_info', 'annualized_return_rate'],
                        width: 120,
                        render(value, record) {
                            if (
                                (record.ext_info as TransELNExtInfo).issue_price_rate &&
                                (record.ext_info as TransELNExtInfo).tenor
                            ) {
                                let result = `(1 - ${
                                    (record.ext_info as TransELNExtInfo).issue_price_rate
                                } / 100) / (${
                                    (record.ext_info as TransELNExtInfo).issue_price_rate
                                } / 100) * 360 / ${
                                    (record.ext_info as TransELNExtInfo).tenor
                                } * 100`;
                                return (
                                    math.format(math.evaluate(result), {
                                        notation: 'fixed',
                                        precision: 2,
                                    }) + '%'
                                );
                            }
                            return '-';
                        },
                    },
                    {
                        title: 'Issuer',
                        dataIndex: ['ext_info', 'issuer'],
                        width: 100,
                    },
                ];
                if (!withoutAction) {
                    ret.push({
                        title: '',
                        fixed: 'right',
                        width: 120,
                        render: (_, record) => {
                            return (
                                <div className={styles.btn_area}>
                                    <Button
                                        className={styles.btn}
                                        onClick={() => {
                                            onEdit && onEdit(record);
                                        }}
                                    >
                                        <Tooltip title="编辑">
                                            <EditOutlined />
                                        </Tooltip>
                                    </Button>
                                    <Button
                                        className={styles.btn}
                                        onClick={() => {
                                            onCopy && onCopy(record);
                                        }}
                                    >
                                        <Tooltip title="复制">
                                            <CopyOutlined />
                                        </Tooltip>
                                    </Button>
                                    <Button
                                        className={styles.btn}
                                        onClick={() => {
                                            onRemove && onRemove(record);
                                        }}
                                    >
                                        <Tooltip title="移除">
                                            <DeleteOutlined />
                                        </Tooltip>
                                    </Button>
                                </div>
                            );
                        },
                    });
                }
                return ret;
            case AssetType.FCN:
                ret = [
                    {
                        title: 'Updated Time',
                        dataIndex: 'updated_at',
                        fixed: 'left',
                        render: (value) => getUpdateDuration(value),
                        width: 130,
                    },
                    {
                        title: 'Underlying | Ticker',
                        dataIndex: 'ticker',
                        width: 100,
                        render(_, record) {
                            return `${
                                record.ext_info.underlying_spec_name ??
                                record.ext_info.underlying_name
                            } | ${record.ext_info.ticker}`;
                        },
                        fixed: 'left',
                    },
                    {
                        title: 'Bank',
                        dataIndex: ['ext_info', 'bank_id'],
                        width: 70,
                        render(value) {
                            return Object.keys(BankType).find(
                                (key) => BankType[key as keyof typeof BankType] === value
                            );
                        },
                    },
                    {
                        title: 'Annualized Coupon',
                        dataIndex: ['ext_info', 'annualized_coupon'],
                        width: 100,
                        render: (value) => `${FormatNumber(value, 2)}%`,
                    },
                    {
                        title: 'Guaranteed Period',
                        dataIndex: ['ext_info', 'guaranteed_period'],
                        width: 100,
                    },
                    {
                        title: 'Tenor',
                        dataIndex: ['ext_info', 'tenor'],
                        width: 100,
                    },
                    {
                        title: 'Current Stock Price',
                        dataIndex: ['ext_info', 'underlying_open_price'],
                        render: (value) => FormatNumber(value, 3),
                        width: 100,
                    },
                    {
                        title: 'Knock out%',
                        dataIndex: ['ext_info', 'knock_out_price_rate'],
                        render: (value) => `${FormatNumber(value, 2)}%`,
                        width: 100,
                    },
                    {
                        title: 'Knock out$',
                        dataIndex: ['ext_info', 'knock_out_price'],
                        render: (value) => `${FormatNumber(value, 3)}`,
                        width: 100,
                    },
                    {
                        title: 'Strike%',
                        dataIndex: ['ext_info', 'strike_price_rate'],
                        render: (value) => `${FormatNumber(value, 2)}%`,
                        width: 100,
                    },
                    {
                        title: 'Strike$',
                        dataIndex: ['ext_info', 'strike_price'],
                        render: (value) => `${FormatNumber(value, 3)}`,
                        width: 100,
                    },
                    {
                        title: 'Coupon Frequency',
                        dataIndex: ['ext_info', 'coupon_frequency'],
                        width: 100,
                        render: (value) =>
                            Object.keys(fcnCouponFrequency).find(
                                (key) =>
                                    fcnCouponFrequency[key as keyof typeof fcnCouponFrequency] ===
                                    value
                            ),
                    },
                    {
                        title: 'Issuer',
                        dataIndex: ['ext_info', 'issuer'],
                        width: 100,
                    },
                    {
                        title: 'Observation Frequency',
                        dataIndex: ['ext_info', 'observation_frequency'],
                        width: 100,
                        render: (value) =>
                            Object.keys(aqdqObservationFrequency).find(
                                (key) =>
                                    aqdqObservationFrequency[
                                        key as keyof typeof aqdqObservationFrequency
                                    ] === value
                            ),
                    },
                ];
                if (!withoutAction) {
                    ret.push({
                        title: '',
                        fixed: 'right',
                        width: 80,
                        render: (_, record) => {
                            return (
                                <div className={styles.btn_area}>
                                    <Button
                                        className={styles.btn}
                                        onClick={() => {
                                            onEdit && onEdit(record);
                                        }}
                                    >
                                        <Tooltip title="编辑">
                                            <EditOutlined />
                                        </Tooltip>
                                    </Button>
                                    <Button
                                        className={styles.btn}
                                        onClick={() => {
                                            onCopy && onCopy(record);
                                        }}
                                    >
                                        <Tooltip title="复制">
                                            <CopyOutlined />
                                        </Tooltip>
                                    </Button>
                                    <Button
                                        className={styles.btn}
                                        onClick={() => {
                                            onRemove && onRemove(record);
                                        }}
                                    >
                                        <Tooltip title="移除">
                                            <DeleteOutlined />
                                        </Tooltip>
                                    </Button>
                                </div>
                            );
                        },
                    });
                }
                return ret;
            case AssetType.Option:
                ret = [
                    {
                        title: 'Updated Time',
                        dataIndex: 'updated_at',
                        fixed: 'left',
                        render: (value) => getUpdateDuration(value),
                        width: 130,
                    },
                    {
                        title: 'Underlying | Ticker',
                        dataIndex: 'ticker',
                        width: 100,
                        render(_, record) {
                            return `${
                                record.ext_info.underlying_spec_name ??
                                record.ext_info.underlying_name
                            } | ${record.ext_info.ticker}`;
                        },
                        fixed: 'left',
                    },
                    {
                        title: 'Bank',
                        dataIndex: ['ext_info', 'bank_id'],
                        width: 70,
                        render(value) {
                            return Object.keys(BankType).find(
                                (key) => BankType[key as keyof typeof BankType] === value
                            );
                        },
                    },
                    {
                        title: 'Side',
                        dataIndex: ['ext_info', 'option_side'],
                        width: 70,
                        render(value) {
                            return Object.keys(OptionSide).find(
                                (key) => OptionSide[key as keyof typeof OptionSide] === value
                            );
                        },
                    },
                    {
                        title: 'Option Type',
                        dataIndex: ['ext_info', 'option_type'],
                        width: 100,
                        render(value) {
                            return Object.keys(OptionType).find(
                                (key) => OptionType[key as keyof typeof OptionType] === value
                            );
                        },
                    },
                    {
                        title: 'Tenor',
                        dataIndex: ['ext_info', 'tenor'],
                        width: 100,
                    },
                    {
                        title: 'Current Stock Price',
                        dataIndex: ['ext_info', 'underlying_open_price'],
                        render: (value) => FormatNumber(value, 3),
                        width: 100,
                    },

                    {
                        title: 'Strike%',
                        dataIndex: ['ext_info', 'strike_price_rate'],
                        render: (value) => `${FormatNumber(value, 2)}%`,
                        width: 80,
                    },
                    {
                        title: 'Strike$',
                        dataIndex: ['ext_info', 'strike_price'],
                        render: (value) => `${FormatNumber(value, 3)}`,
                        width: 80,
                    },
                    {
                        title: 'Option Fee%',
                        dataIndex: ['ext_info', 'option_fee_rate'],
                        render: (value) => `${FormatNumber(value, 2)}%`,
                        width: 80,
                    },
                    {
                        title: 'Option Fee$',
                        dataIndex: ['ext_info', 'option_fee'],
                        render: (value) => `${FormatNumber(value, 3)}`,
                        width: 80,
                    },
                    {
                        title: 'Annualised Rate of Return',
                        dataIndex: ['ext_info', 'annualised_rate_of_return'],
                        render: (value) => (value ? `${FormatNumber(value, 2)}%` : '-'),
                        width: 80,
                    },
                    {
                        title: ' Breakeven Price',
                        dataIndex: ['ext_info', 'break_even_price'],
                        render: (value) => `${FormatNumber(value, 3)}`,
                        width: 80,
                    },
                    {
                        title: 'Issuer',
                        dataIndex: ['ext_info', 'issuer'],
                        width: 100,
                    },
                ];
                if (!withoutAction) {
                    ret.push({
                        title: '',
                        fixed: 'right',
                        width: 80,
                        render: (_, record) => {
                            return (
                                <div className={styles.btn_area}>
                                    <Button
                                        className={styles.btn}
                                        onClick={() => {
                                            onEdit && onEdit(record);
                                        }}
                                    >
                                        <Tooltip title="编辑">
                                            <EditOutlined />
                                        </Tooltip>
                                    </Button>
                                    <Button
                                        className={styles.btn}
                                        onClick={() => {
                                            onCopy && onCopy(record);
                                        }}
                                    >
                                        <Tooltip title="复制">
                                            <CopyOutlined />
                                        </Tooltip>
                                    </Button>
                                    <Button
                                        className={styles.btn}
                                        onClick={() => {
                                            onRemove && onRemove(record);
                                        }}
                                    >
                                        <Tooltip title="移除">
                                            <DeleteOutlined />
                                        </Tooltip>
                                    </Button>
                                </div>
                            );
                        },
                    });
                }
                return ret;
            case AssetType.Fund:
                ret = [
                    {
                        title: 'Fund Name',
                        dataIndex: 'name',
                        width: 250,
                    },
                    {
                        title: 'Fund Manager',
                        dataIndex: ['ext_info', 'fund_manager'],
                        width: 150,
                    },
                    {
                        title: 'Fund Struct',
                        dataIndex: ['ext_info', 'fund_struct_type'],
                        render(value) {
                            return Object.keys(FundStruct).find(
                                (key) => FundStruct[key as keyof typeof FundStruct] === value
                            );
                        },
                        width: 100,
                    },
                    {
                        title: 'Currency',
                        dataIndex: 'currency',
                        width: 100,
                    },
                    {
                        title: 'ISIN',
                        dataIndex: 'isin',
                        width: 150,
                    },
                    {
                        title: 'Description',
                        width: 300,
                        render(value, record, index) {
                            let recordValue = record.ext_info as Record<string, any>;
                            if (recordValue?.fund_struct_type === 1) {
                                return `${
                                    Object.keys(RedemptionFrequency).find(
                                        (key) =>
                                            RedemptionFrequency[
                                                key as keyof typeof RedemptionFrequency
                                            ] === recordValue?.redemption_frequency_type
                                    ) ?? ''
                                } Redemption,${recordValue?.redemption_notice} Days Notice`;
                            } else {
                                return `${
                                    Object.keys(CapitalCallFrequency).find(
                                        (key) =>
                                            CapitalCallFrequency[
                                                key as keyof typeof CapitalCallFrequency
                                            ] === recordValue?.capital_call_frequency_type
                                    ) ?? ''
                                } Call`;
                            }
                        },
                    },
                ];
                if (!withoutAction) {
                    ret.push({
                        title: '',
                        fixed: 'right',
                        width: 80,
                        render: (_, record) => {
                            return (
                                <div className={styles.btn_area}>
                                    <Button
                                        className={styles.btn}
                                        onClick={() => {
                                            onEdit && onEdit(record);
                                        }}
                                    >
                                        <Tooltip title="编辑">
                                            <EditOutlined />
                                        </Tooltip>
                                    </Button>
                                    <Button
                                        className={styles.btn}
                                        onClick={() => {
                                            onCopy && onCopy(record);
                                        }}
                                    >
                                        <Tooltip title="复制">
                                            <CopyOutlined />
                                        </Tooltip>
                                    </Button>
                                    <Button
                                        className={styles.btn}
                                        onClick={() => {
                                            onRemove && onRemove(record);
                                        }}
                                    >
                                        <Tooltip title="移除">
                                            <DeleteOutlined />
                                        </Tooltip>
                                    </Button>
                                </div>
                            );
                        },
                    });
                }
                return ret;
        }
    };
    const columns = getColumns(type);
    const newColumns = adaptiveTableWidth(columns, type);
    return {
        columns: newColumns,
    };
};

function adaptiveTableWidth(columns: any, type: AssetType) {
    let widthLv1Title = [
        'Bank',
        'Tenor',
        'Side',
        '久期',
        'Strike%',
        'Strike$',
        'Type',
        'Issue',
        'Issue$',
        'Breakeven Price',
    ];
    let widthLv2Title = [
        'Updated Time',
        'Current Stock Price',
        'Knock out%',
        'Knock out$',
        'Knock Out$',
        'Option Fee%',
        'Option Fee$',
        'Number of Trade Days',
        'Issuer',
        'Coupon Frequency',
    ];
    let widthLv3Title = [
        // 'Underlying | Ticker',
        'Leverage',
        'Guaranteed Period',
        'Observation Frequency',
        'Issue Date',
        'Maturity Date',
        'Annualised Rate of Return',
        'Annualized Coupon',
        'Option Type',
        '到期时间',
    ];

    let widthLv4Title = ['Updated Time', 'ISIN', 'Underlying | Ticker'];

    const isLargeScreen = window.innerWidth > 1440;

    const widthLv1 = isLargeScreen ? 60 : 60;
    const widthLv2 = isLargeScreen ? 60 : 60;
    const widthLv3 = isLargeScreen ? 85 : 85;
    const widthLv4 = isLargeScreen ? 110 : 110;
    const widthLv5 = isLargeScreen ? 120 : 120;

    return columns?.map((item: any) => {
        const newItem = { ...item };
        if (widthLv1Title.includes(newItem.title)) {
            newItem.width = widthLv1;
        } else if (widthLv2Title.includes(newItem.title)) {
            newItem.width = widthLv2;
        } else if (widthLv3Title.includes(newItem.title)) {
            newItem.width = widthLv3;
        } else if (widthLv4Title.includes(newItem.title)) {
            newItem.width = widthLv4;
        } else {
            newItem.width = widthLv5;
        }
        return newItem;
    });
}

function getUpdateDuration(timestamp: number) {
    const now = moment();
    const lastUpdated = moment(timestamp);

    const duration = moment.duration(now.diff(lastUpdated));

    if (duration.asSeconds() < 60) {
        return Math.floor(duration.asSeconds()) + 's ago';
    } else if (duration.asMinutes() < 60) {
        return Math.floor(duration.asMinutes()) + 'm ago';
    } else if (duration.asHours() < 24) {
        return Math.floor(duration.asHours()) + 'h ago';
    } else if (duration.asDays() < 7) {
        return Math.floor(duration.asDays()) + 'd ago';
    } else if (duration.asWeeks() < 4) {
        return Math.floor(duration.asWeeks()) + 'w ago';
    } else if (duration.asMonths() < 12) {
        return Math.floor(duration.asMonths()) + 'mo ago';
    } else {
        return Math.floor(duration.asYears()) + 'y ago';
    }
}
