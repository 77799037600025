import React from 'react';
import styles from './BarChart.module.scss';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import classnames from 'classnames';

interface BarChartProps {
    title: string;
    time: string;
    count: number;
    unit: string;
    keys: string[];
    data: number[];
    onTrigger?: (num: number) => void;
}
const BarChart = (props: BarChartProps) => {
    const { title, time, count, unit, keys, data, onTrigger } = props;
    const option = {
        xAxis: {
            type: 'value',
            splitLine: false,
            show: false,
        },
        yAxis: {
            type: 'category',
            data: keys,
            axisTick: {
                alignWithLabel: true,
            },
            inverse: true,
            axisLine: {
                onZero: false,
            },
            offset: 1,
        },
        grid: {
            top: '10px',
            bottom: '30px',
            left: '65px',
            right: '20px',
        },
        series: [
            {
                data,
                type: 'bar',
                barWidth: '12px',
                label: {
                    show: true,
                    position: 'right',
                },
                itemStyle: {
                    color: {
                        colorStops: [
                            {
                                offset: 0,
                                color: '#F3BD11',
                            },
                            {
                                offset: 1,
                                color: '#FFD757',
                            },
                        ],
                    },
                },
            },
        ],
    };
    return (
        <div className={styles.pannel}>
            <div className={styles.pannelTitle}>{title}</div>
            <div className={styles.time}>
                {onTrigger && (
                    <LeftOutlined
                        className={classnames({
                            [styles['icon']]: true,
                            [styles['left']]: true,
                        })}
                        onClick={() => {
                            onTrigger(-1);
                        }}
                    />
                )}
                {time}
                {onTrigger && (
                    <RightOutlined
                        className={classnames({
                            [styles['icon']]: true,
                            [styles['right']]: true,
                        })}
                        onClick={() => {
                            onTrigger(1);
                        }}
                    />
                )}
            </div>
            <div>
                <span className={styles.count}>{count}</span>
                <span className={styles.countUnit}>{unit}</span>
            </div>
            <div>
                <ReactEChartsCore echarts={echarts} option={option} style={{ height: '230px' }} />
            </div>
        </div>
    );
};

export default BarChart;
