import { HostType } from '../../../service/config';
import { multiHostAxios } from '../../../service/tools';

const axios = multiHostAxios(HostType.NOTIFY);

export const getNotifyNodeList = () => {
    return axios.post<NotifyNode[]>('/v1/admin/notify/NotifyNode/list');
};

export const addNotifyNode = (data: { name: string }) => {
    return axios.post('/v1/admin/notify/NotifyNode/create', { data });
};

export const addNodeReceiver = (data: {
    channel: string;
    ident: string;
    expire: number;
    jsonPath: boolean;
    nodeId: number;
}) => {
    return axios.post('/v1/admin/notify/NodeReceiver/create', { data });
};

export const updateNodeReceiver = (data: {
    id: number;
    channel: string;
    ident: string;
    expire: number;
    jsonPath: boolean;
    nodeId: number;
}) => {
    return axios.post('/v1/admin/notify/NodeReceiver/update', { data });
};

export const deleteNodeReceiver = (data: { id: number }) => {
    return axios.post('/v1/admin/notify/NodeReceiver/delete', { data });
};
