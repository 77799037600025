import { Button, Divider, Modal, Table, TableColumnType } from 'antd';
import React, { useRef, useState } from 'react';
import { BankFlow, ReconciliationDetail, SystemFlow, VerifiedStatus } from '../../type';
import moment from 'moment';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { EditableCellType, EditableRow, SystemEditableCell } from '../SystemEditCell';
import { useRequest } from 'ahooks';
import { getUnmatchSystemFlow } from '../../request';
import { TransactionType } from '../../../Transaction/type';
import { FormatNumber } from '../../../../utils';
const BankAndSystemFlow = ({
    data,
    bankFlows,
    setBankFlows,
}: {
    data: ReconciliationDetail;
    bankFlows: BankFlow[];
    setBankFlows: React.Dispatch<React.SetStateAction<BankFlow[]>>;
}) => {
    const [showModal, setShowModal] = useState(false);
    const currentSystemFlow = useRef<SystemFlow | undefined>(undefined);
    const currentBankIndex = useRef<number | undefined>(undefined);
    const { data: system_flows, refresh } = useRequest(getUnmatchSystemFlow, {
        defaultParams: [
            {
                account_id: data.account_id,
                asset_name: data.asset_name,
                asset_type: data.asset_type.code,
                isin: data.isin,
                currency: data.currency,
            },
        ],
    });
    const defaultColumns: (TableColumnType<BankFlow> & {
        editable?: boolean;
        verifiedStatus?: number; //1 通过 2 未通过
        children: (TableColumnType<any> & {
            editable?: boolean;
            verifiedStatus?: number; //1 通过 2 未通过
        })[];
        onCell?: any;
    })[] = [
        {
            title: 'Custodian Flow',
            width: 300,
            children: [
                {
                    title: 'Trade Date',
                    width: 70,
                    dataIndex: 'trade_date',
                    align: 'center',
                    render(value: any, record, index: number) {
                        if (!value) return '-';
                        return moment(Number.parseInt(value)).format('YYYY-MM-DD');
                    },
                },
                {
                    title: 'Value Date',
                    width: 70,
                    align: 'center',
                    dataIndex: 'value_date',
                    render(value: any, record: any, index: number) {
                        if (!value) return '-';
                        return moment(Number.parseInt(value)).format('YYYY-MM-DD');
                    },
                },
                {
                    title: 'Description',
                    width: 160,
                    align: 'center',
                    dataIndex: 'description',
                },
            ],
        },
        {
            title: '',
            width: 100,
            children: [
                {
                    title: 'Verified Amount',
                    width: 100,
                    dataIndex: 'verified_amount',
                    align: 'center',
                    editable: data.status !== 2,
                },
            ],
        },
        {
            title: 'System Flow',
            width: 450,
            children: [
                {
                    title: 'Trade Date',
                    width: 100,
                    align: 'center',
                    render(value: any, record: BankFlow, index: number) {
                        if (!record.flow?.transaction.trade_date) return '-';
                        return moment(record.flow?.transaction.trade_date).format('YYYY-MM-DD');
                    },
                },
                {
                    title: 'Value Date',
                    width: 100,
                    align: 'center',
                    render(value: any, record: BankFlow, index: number) {
                        if (!record.flow?.transaction.value_date) return '-';
                        return moment(record.flow?.transaction.value_date).format('YYYY-MM-DD');
                    },
                },
                {
                    title: 'Presume Amount',
                    width: 100,
                    align: 'center',
                    dataIndex: ['flow', 'presume_amount'],
                    render(value: any, record: any, index: number) {
                        if (record.flow?.presume_amount) {
                            return FormatNumber(record.flow?.presume_amount, 2);
                        } else {
                            return '-';
                        }
                    },
                },
                {
                    title: 'Transaction Type',
                    width: 100,
                    align: 'center',
                    dataIndex: ['flow', 'transaction_type'],
                    render(value: any, record: BankFlow, index: number) {
                        if (!record.flow?.transaction?.type.desc) return '-';
                        return record.flow?.transaction.type.desc;
                    },
                },
                {
                    title: 'action',
                    width: 50,
                    align: 'center',
                    render(value: any, record: any, index: number) {
                        // if (data.status === VerifiedStatus.Verified) return '-';
                        return record.flow ? (
                            <Button
                                disabled={data.status === VerifiedStatus.Verified}
                                onClick={() => handleRowDelete(index)}
                            >
                                <MinusSquareOutlined />
                            </Button>
                        ) : (
                            <Button
                                disabled={data.status === VerifiedStatus.Verified}
                                onClick={() => handleRowAdd(index)}
                            >
                                <PlusSquareOutlined />
                            </Button>
                        );
                    },
                },
            ],
        },
    ];
    const modalColumns: TableColumnType<any>[] = [
        {
            title: 'Trade Date',
            width: '20%',
            dataIndex: ['transaction', 'trade_date'],
            render(value: any, record: any, index: number) {
                return value ? moment(Number.parseInt(value)).format('YYYY-MM-DD') : '-';
            },
        },
        {
            title: 'Value Date',
            width: '20%',
            dataIndex: ['transaction', 'value_date'],
            render(value: any, record: any, index: number) {
                return value ? moment(Number.parseInt(value)).format('YYYY-MM-DD') : '-';
            },
        },
        {
            title: 'Presume Amount',
            width: '20%',
            dataIndex: 'presume_amount',
            render(value: any, record: any, index: number) {
                if (value) {
                    return FormatNumber(value, 2);
                } else {
                    return '-';
                }
            },
        },
        {
            title: 'Transaction Desciption',
            width: 'auto',
            render(value: any, record: any, index: number) {
                let type = '';
                if (!record.transaction?.type?.desc) {
                    type = '-';
                } else {
                    type = record.transaction.type.desc;
                }
                if (record.transaction?.assets?.length === 0) {
                    return type;
                }
                if (record.transaction?.type?.code === TransactionType.Transfer) {
                    return `${type}, -`;
                }
                if (record.transaction?.assets?.length > 0) {
                    let asset = record.transaction?.assets?.[0];
                    return `${type}, ${asset.spen_name ?? asset.name}`;
                } else {
                    return type;
                }
            },
        },
    ];
    const columns = defaultColumns?.map((col) => {
        if (col.children) {
            let children = col.children.map((item) => {
                if (!item.editable) {
                    return item;
                } else {
                    return {
                        ...item,
                        onCell: (record: BankFlow) => {
                            return {
                                record,
                                editable: item.editable,
                                dataIndex: item.dataIndex,
                                verifiedStatus:
                                    record.verified_amount === record?.flow?.presume_amount ? 1 : 2,
                                type: EditableCellType.Input,
                                handleSave: handleCellSave,
                                title: item.title,
                            };
                        },
                    };
                }
            });
            return {
                ...col,
                children,
            };
        } else {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record: BankFlow) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    handleSave: handleCellSave,
                    verifiedStatus: record.verified_amount === record.flow?.presume_amount ? 1 : 2,
                    type: EditableCellType.Input,
                    title: col.title,
                }),
            };
        }
    });
    const handleCellSave = (record: BankFlow) => {
        let newData = [...bankFlows];
        const index = newData.findIndex((item) => record.id === item.id);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...record,
        });
        setBankFlows(newData);
    };
    const handleRowAdd = (selectIndex: number) => {
        currentBankIndex.current = selectIndex;
        setShowModal(true);
    };
    const handleRowDelete = (selectIndex: number) => {
        let newData = [...bankFlows];
        newData[selectIndex].flow = undefined;
        setBankFlows(newData);
    };
    const onDone = () => {
        setShowModal(false);
        if (currentBankIndex.current === undefined) return;
        let newData = [...bankFlows];
        newData[currentBankIndex.current!] = {
            ...newData[currentBankIndex.current!],
            flow: currentSystemFlow.current,
        };
        currentSystemFlow.current = undefined;
        currentBankIndex.current = undefined;
        setBankFlows(newData);
    };
    const onNewTrade = () => {
        window.open(`/#/app/trade/transaction`, '_blank');
    };
    const onRefresh = () => {
        refresh();
    };
    return (
        <>
            <Table
                size="small"
                components={{
                    body: {
                        cell: SystemEditableCell,
                        row: EditableRow,
                    },
                }}
                bordered
                columns={columns}
                dataSource={bankFlows}
                pagination={false}
                scroll={{ x: 1200 }}
                rowKey={'id'}
            />

            <Modal
                title="Choose a System Transaction Account Flow"
                visible={showModal}
                destroyOnClose
                maskClosable
                footer={null}
                width={800}
                onCancel={() => {
                    setShowModal(false);
                }}
            >
                <Table
                    columns={modalColumns}
                    size="small"
                    dataSource={system_flows?.data?.system_flows ?? []}
                    pagination={false}
                    rowKey={(record) => record.id}
                    rowSelection={{
                        type: 'radio',
                        onSelect(record, selected, selectedRows) {
                            currentSystemFlow.current = record;
                        },
                        getCheckboxProps: (record) => ({
                            disabled:
                                bankFlows?.findIndex((item) => item.flow?.id === record.id) > -1,
                        }),
                    }}
                    scroll={{ y: 500 }}
                />
                <Divider />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onRefresh}>Refresh</Button>
                    <Button onClick={onNewTrade}>New Trade</Button>
                    <Button onClick={onDone} type="primary">
                        Done
                    </Button>
                </div>
            </Modal>
        </>
    );
};
export default BankAndSystemFlow;
