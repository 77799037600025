import style from './index.module.scss';
import React, { useMemo, useEffect, useState, useCallback, useRef, useContext } from 'react';
import { Collapse, Modal } from 'antd';
import CustomerInfo, { CustomerInfoRef } from '../CustomerInfo';
import { CaretRightOutlined } from '@ant-design/icons';
import { CustomerManageData } from '../../../../service/requests/CustomerManage';
import LookButton from '../../../../components/LookButton';
import CustomerPosition from '../CustomerPosition';
import CustomerAsset from '../CustomerAssets';
import { stopPropagation } from '../../../RecommendationComponent/util';
import AddRealAccount from '../../../AssetManagement/Assets/components/AddRealAccount';
import CustomerActivity from '../CustomerActivity';
import TranscationRecord, { TranscationRecordRef } from '../TranscationRecord';
import { EventList, uploadEventTrack } from '../../../../service/requests/EventTrack';
import ConfigReviewModal from '../ConfigReviewModal';
import CustomerRecord from '../Record';
import RecordListModal, { RecordListModalType } from '../Record/component/Modal';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LKRootState } from '../../../../store/store';
import { Avatars } from '../UserCard';
import { MobileStateContext } from '../../../../App';
import { CustomerData, CustomerDetailCardType, CustomerElementType, dangerMap } from './type';
import { refreshDetailCard } from '../../../../store/actions/actions';

const { Panel } = Collapse;

const CustomerDetail = ({
    customerId,
    contactChild,
    positionEdit,
    infoEdit,
    searchChild,
    banksInfo,
    currentActiveKey,
}: {
    customerId: number;
    contactChild: () => void;
    positionEdit: () => void;
    searchChild: (id: number) => void;
    banksInfo: CustomerData['bankAccounts'];
    currentActiveKey: CustomerDetailCardType;
    infoEdit: (customerInfo: CustomerManageData['pageData'][number]) => void;
}) => {
    const configReviewModalState = useState(false);

    const history = useHistory();
    const recordModalState = useState(false);
    const customerInfoRef = useRef<CustomerInfoRef>(null);
    const customerTranscationRef = useRef<TranscationRecordRef>(null);

    const data = useSelector((state: LKRootState) => state.customer.customerModel);
    const activityModel = useSelector((state: LKRootState) => state.customer.activityModel);
    const assetModel = useSelector((state: LKRootState) => state.customer.assetModel);
    const positionModel = useSelector((state: LKRootState) => state.customer.positionModel);

    const mobileState = useContext(MobileStateContext);
    const [resize, setResize] = useState(0);
    const [addVisible, setAddVisible] = useState(false);
    // const [activeKeys, setActiveKeys] = useState(['asset', 'position', 'transaction', 'activity']);
    const [activeKeys, setActiveKeys] = useState([
        CustomerDetailCardType.Asset,
        CustomerDetailCardType.Position,
        CustomerDetailCardType.TranscationRecord,
        CustomerDetailCardType.Activity,
    ]);

    useEffect(() => {
        if (!mobileState.IsMobileMachine) {
            return;
        }
        setActiveKeys([currentActiveKey]);
        setTimeout(() => {
            const element = document.getElementById(`panel-${currentActiveKey}`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 300);
    }, [currentActiveKey, mobileState.IsMobileMachine]);

    const traders = useMemo(() => {
        let traders: CustomerManageData['pageData'][number]['rms'] = [];
        if (banksInfo) {
            banksInfo.forEach((item) => {
                if (item.traders) {
                    traders = traders.concat(item.traders);
                }
            });
        }
        //去除重复
        const map = new Map();
        traders.forEach((item) => {
            if (!map.has(item.id)) {
                map.set(item.id, item);
            }
        });
        return Array.from(map.values());
    }, [banksInfo]);
    const positionData = useMemo<CustomerElementType | null>(() => {
        let str = '';
        if (positionModel && positionModel.account_position_number_infos) {
            let array = positionModel.account_position_number_infos.map((item) => {
                return `${item.title}   (${item.number})`;
            });
            str = Array.from(array).join('  ');
        }
        let title = `持仓信息`;
        let right = str;
        return {
            title,
            right,
            content: <CustomerPosition customer_id={customerId} />,
            key: CustomerDetailCardType.Position,
            action: [
                <LookButton onClick={positionEdit} key="CustomerPostionEditBtn">
                    持仓编辑
                </LookButton>,
            ],
        };
    }, [customerId, positionEdit, positionModel]);
    const infoData = useMemo<CustomerElementType | null>(() => {
        let title = `客户信息（ID：${customerId}）`;
        let right = data ? (
            <div className={style.rmic}>
                {data.rms?.length > 0 && <Avatars title="RM:" list={data.rms} showDetail />}
                {data.ics?.length > 0 && <Avatars title="IC:" list={data.ics} showDetail />}
                {traders.length > 0 && <Avatars title="交易员:" list={traders} showDetail />}
            </div>
        ) : (
            <></>
        );
        return {
            title,
            right,
            content: (
                <CustomerInfo
                    customer_id={customerId}
                    banksInfo={banksInfo}
                    ref={customerInfoRef}
                    searchChild={searchChild}
                />
            ),
            key: CustomerDetailCardType.Info,
            action: data
                ? [
                      <LookButton
                          onClick={() => {
                              uploadEventTrack(EventList.CustomerListInfoBottomOptionsClick, 2, {
                                  name: '编辑账户',
                              });
                              infoEdit(data);
                          }}
                          key={'editinfo'}
                      >
                          编辑账户
                      </LookButton>,
                      <LookButton
                          onClick={() => {
                              uploadEventTrack(EventList.CustomerListInfoBottomOptionsClick, 2, {
                                  name: 'KYC信息',
                              });
                              window.open(data.kycUrl);
                          }}
                          key={'kycinfo'}
                          disabled={data.kycUrl === null || data.kycUrl === ''}
                      >
                          KYC信息
                      </LookButton>,
                      <LookButton
                          key={'subaccount'}
                          disabled={data.isChildUser === 1}
                          onClick={() => {
                              uploadEventTrack(EventList.CustomerListInfoBottomOptionsClick, 2, {
                                  name: '关联子账户',
                              });
                              contactChild();
                          }}
                      >
                          关联子账户
                      </LookButton>,
                      <LookButton
                          key={'customUploadBtn'}
                          onClick={() => {
                              uploadEventTrack(EventList.CustomerListInfoBottomOptionsClick, 2, {
                                  name: '上传文件',
                              });
                              if (customerInfoRef) {
                                  customerInfoRef.current!.showUploadModal();
                              }
                          }}
                      >
                          上传文件
                      </LookButton>,
                  ]
                : [],
        };
    }, [data, customerId, traders, banksInfo, searchChild, infoEdit, contactChild]);
    const assetData = useMemo<CustomerElementType | null>(() => {
        let title = '资产信息';
        let right = assetModel ? (
            <span>
                {`净抵押值：${
                    assetModel.customer_asset_summary.asset?.c_net_margin_avail ?? '无'
                } $`}
                <span style={{ marginLeft: '10px' }} />
                集中度：
                <span
                    style={{ color: dangerMap[assetModel?.customer_asset_summary.concentration] }}
                >
                    {assetModel.customer_asset_summary.concentration ?? '无'}
                </span>
                <span style={{ marginLeft: '10px' }} />
                安全垫：
                <span
                    style={{
                        color:
                            Number.parseInt(
                                assetModel.customer_asset_summary.asset?.risk_rate ?? '100'
                            ) ?? 0 >= 15
                                ? ''
                                : '#ED3B00',
                    }}
                >
                    {assetModel.customer_asset_summary.asset?.risk_rate ?? '无'}
                </span>
            </span>
        ) : (
            ''
        );
        return {
            title,
            right,
            content: <CustomerAsset resize={resize} customer_id={customerId} />,
            key: CustomerDetailCardType.Asset,
            action: assetModel
                ? [
                      <LookButton
                          disabled={
                              assetModel.bi_review_url === null ||
                              assetModel.bi_review_url === '' ||
                              assetModel.bi_review_url === undefined
                          }
                          onClick={() => {
                              configReviewModalState[1](true);
                          }}
                          key={'jumpBi'}
                      >
                          账户Review看板
                      </LookButton>,
                      <LookButton onClick={() => setAddVisible(true)} key={'addaccount'}>
                          添加银行账户
                      </LookButton>,
                  ]
                : [],
        };
    }, [assetModel, resize, customerId, configReviewModalState]);
    const transcationRecordData = useMemo<CustomerElementType | null>(() => {
        if (!data?.portalUid) return null;
        return {
            title: '交易记录',
            right: '',
            content: (
                <TranscationRecord
                    ref={customerTranscationRef}
                    customerName={data?.name}
                    operated_uid={data?.portalUid}
                    customer_id={0}
                />
            ),
            key: CustomerDetailCardType.TranscationRecord,
            action: [
                <LookButton
                    onClick={() => {
                        if (customerTranscationRef.current) {
                            customerTranscationRef.current.exportExcel();
                        }
                    }}
                    key={'exportList'}
                >
                    导出
                </LookButton>,
            ],
        };
    }, [data]);
    const track_list = useSelector((state: LKRootState) => state.customer.track_list);

    const recordData = useMemo<CustomerElementType | null>(() => {
        if (!data?.portalUid) return null;
        return {
            title: '客户记录',
            right: '',
            content: (
                <CustomerRecord
                    customer_id={customerId}
                    addCallback={() => {
                        uploadEventTrack(EventList.CustomerListRecordADD, 2);
                        recordModalState[1](true);
                    }}
                />
            ),
            key: CustomerDetailCardType.Record,
            action: [
                <LookButton
                    disabled={track_list?.total_count === 0}
                    onClick={() => {
                        uploadEventTrack(EventList.CustomerListRecordAll, 2);
                        history.push({
                            pathname: `/app/customer/list/trackRecord/${customerId}/all`,
                        });
                    }}
                    key={'ViewAll'}
                >
                    {track_list?.total_count > 0
                        ? `全部记录(${track_list?.total_count})`
                        : '全部记录'}
                </LookButton>,
                <LookButton
                    onClick={() => {
                        uploadEventTrack(EventList.CustomerListRecordADD, 2);
                        recordModalState[1](true);
                    }}
                    key={'AddRecord'}
                >
                    新增记录
                </LookButton>,
            ],
        };
    }, [customerId, data, history, recordModalState, track_list]);

    const activityData = useMemo<CustomerElementType | null>(() => {
        let title = '客户动态';
        let right = activityModel ? (
            <div>
                <span>最近登录：{activityModel.latest_login_time ?? '无'}</span>
                <span style={{ marginLeft: '20px' }}>{`APP版本：${
                    activityModel.app_ver ?? '无'
                }`}</span>
            </div>
        ) : (
            ''
        );
        return {
            title,
            right,
            content: <CustomerActivity customer_id={customerId} />,
            key: CustomerDetailCardType.Activity,
            action: [],
        };
    }, [activityModel, customerId]);

    const configData = useMemo<{ [key: string]: CustomerElementType | null }>(() => {
        return {
            infoData,
            recordData,
            activityData,
            assetData,
            positionData,
            transcationRecordData,
        };
    }, [activityData, assetData, infoData, positionData, recordData, transcationRecordData]);

    const headerChangeFoldStatus = useCallback(
        (e: string[] | string) => {
            let foldStatus: '1' | '2' = '1';
            let activeKey = '';
            if (activeKeys.length > e.length) {
                foldStatus = '2';
                let set = new Set(e);
                let result = activeKeys.filter((item) => !set.has(item));
                activeKey = result[0];
            } else {
                let set = new Set(activeKeys);
                let result = (e as string[]).filter(
                    (item) => !set.has(item as CustomerDetailCardType)
                );
                activeKey = result[0];
                foldStatus = '1';
            }
            setActiveKeys((e as string[]).map((item) => item as CustomerDetailCardType));
            uploadEventTrack(EventList.CustomerListFoldStatus, 2, {
                statu: foldStatus,
                name: activeKey,
            });
            if ((e as string[]).findIndex((item) => item === 'asset') > -1) {
                setResize((resize) => {
                    return resize + 1;
                });
            }
        },
        [activeKeys]
    );
    const dispatch = useDispatch();
    const refreshCard = (cardType: CustomerDetailCardType) => {
        dispatch(refreshDetailCard(cardType));
    };

    return (
        <div className={style.content}>
            <div onClick={stopPropagation}>
                <ConfigReviewModal visibleState={configReviewModalState} id={customerId} />
                {recordModalState[0] && (
                    <RecordListModal
                        isList={false}
                        customer_id={customerId}
                        visibleState={recordModalState}
                        modalType={RecordListModalType.Add}
                    />
                )}
                <Modal
                    title={`添加账户: ${data?.mobile}`}
                    destroyOnClose
                    footer={null}
                    visible={addVisible}
                    okText="确认"
                    cancelText="取消"
                    onCancel={() => setAddVisible(false)}
                    centered
                    width={560}
                >
                    <AddRealAccount
                        setRefreshList={() => refreshCard(CustomerDetailCardType.Asset)}
                        userUid={data?.portalUid ?? ''}
                        close={() => setAddVisible(false)}
                        copy_id={0}
                    />
                </Modal>
            </div>
            <Collapse
                expandIconPosition={'right'}
                defaultActiveKey={activeKeys}
                activeKey={activeKeys}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 270 : 90} />}
                className={style['Customer-Detail-Collapse']}
                onChange={headerChangeFoldStatus}
            >
                {(Object.keys(configData) ?? []).map((key, index) => {
                    let item = configData[key];
                    if (item) {
                        return (
                            <Panel
                                key={item.key}
                                className={style.panleItem}
                                forceRender={item.key === CustomerDetailCardType.Info}
                                header={PanelHeader({
                                    key: item.key,
                                    title: item.title,
                                    right: item.right,
                                })}
                                id={`panel-${item.key}`}
                            >
                                <div className={style.itemContent} key={`${index}top`}>
                                    {item.content}
                                </div>
                                {item.action && (
                                    <div className={style.actions} key={`${index}bottom`}>
                                        {item.action}
                                    </div>
                                )}
                            </Panel>
                        );
                    }
                    return <></>;
                })}
            </Collapse>
        </div>
    );
};
export default CustomerDetail;
const PanelHeader = ({
    title,
    right,
    key,
}: {
    key: string;
    title: string;
    right: string | React.ReactNode;
}) => {
    return (
        <div className={style.header} key={key}>
            <div className={style.left}>{title}</div>
            {typeof right === 'string' && <div className={style.right}>{right}</div>}
            {typeof right !== 'string' && right}
        </div>
    );
};
