import { Form, FormInstance, Input, Select } from 'antd';
import React, { ReactNode, useContext, useEffect, useRef } from 'react';
import { QuoteEntity, QuoteRelatedTargetAction } from '../../type';
import { GinkgoInputNumber } from '@src/modules/Transaction/components/TransForm/Forms/FormItems/GinkgoFieldItems';
export enum EditableCellType {
    Input = 'Input',
    Select = 'Select',
}

export interface EditableCellProps<Item> {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: any;
    record: Item;
    addonAfter?: ReactNode;
    required?: boolean;
    handleSave: (record: Item, relatedTarget?: QuoteRelatedTargetAction) => void;
    options?: { key: string; value: string }[];
    type?: EditableCellType;
}
const EditableContext = React.createContext<FormInstance<any> | null>(null);

const EditableRow = ({ index, ...props }: { index: number }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    addonAfter,
    required,
    handleSave,
    type,
    options,
    ...restProps
}: EditableCellProps<QuoteEntity>) => {
    const inputRef = useRef<any>(null);
    const form = useContext(EditableContext)!;
    const toggleEdit = () => {
        // setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex as keyof QuoteEntity] });
    };
    useEffect(() => {
        if (form && record) {
            form.setFieldsValue(record);
        }
    }, [record, form]);
    const save = async (id?: QuoteRelatedTargetAction) => {
        try {
            const values = await form.getFieldsValue();
            values.ext_info.price = values.ext_info.price?.toString();
            toggleEdit();
            handleSave({ ...record, ...values }, id);
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    const renderContent = () => {
        let item: ReactNode;
        switch (type) {
            case EditableCellType.Input:
                item =
                    record.ext_info.price_type !== 1 &&
                    JSON.stringify(dataIndex) === JSON.stringify(['ext_info', 'price']) ? (
                        <div style={{ textAlign: 'center' }}>--</div>
                    ) : (
                        <GinkgoInputNumber
                            ref={inputRef}
                            onPressEnter={() => save()}
                            onBlur={(e) => {
                                let id = (e.relatedTarget as HTMLElement)
                                    ?.id as QuoteRelatedTargetAction;
                                save(id);
                            }}
                            key={typeof type}
                            required={required}
                            addonAfter={addonAfter}
                        />
                    );
                break;
            case EditableCellType.Select:
                item = (
                    <Select onSelect={save} key={typeof type}>
                        {options?.map((item) => (
                            <Select.Option key={item.key} value={item.value}>
                                {item.key}
                            </Select.Option>
                        ))}
                    </Select>
                );
        }

        return (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: !(
                            record.ext_info.price_type !== 1 ||
                            JSON.stringify(dataIndex) === JSON.stringify(['ext_info', 'price'])
                        ),
                        message: `${title} is required.`,
                    },
                ]}
            >
                {item}
            </Form.Item>
        );
    };
    let childNode = children;
    if (editable) {
        childNode = editable ? (
            renderContent()
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 24 }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};
export { EditableCell, EditableRow };
