import React, { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { searchProduct } from '../../../../request';
import { Asset, AssetType } from '../../../../type';
import { Form, FormInstance, Select } from 'antd';
import { GinkgoInput, GinkgoSelect } from './GinkgoFieldItems';
const { Option } = Select;
const SearchUnderlying = ({
    readOnly,
    form,
    width,
    required,
    currentPrice,
    isin,
    withCurrency,
    needPrice,
    ticker,
}: {
    readOnly?: boolean;
    required?: boolean;
    form?: FormInstance;
    ticker?: string;
    width?: string;
    withCurrency: boolean;
    needPrice?: boolean;
    isin?: string;
    currentPrice: (asset?: Asset) => void;
}) => {
    const [currentProduct, setCurrentProduct] = useState<Asset>();
    const [searchKey, setSearchKey] = useState<string>('');
    const [dataList, setDatalist] = useState<Asset[]>([]);
    const [readOnlyValue, setReadOnlyValue] = useState<string | undefined>();
    const { data: productList, run, cancel } = useRequest(searchProduct, {
        manual: true,
    });
    useEffect(() => {
        productList?.data?.assets && setDatalist(productList?.data?.assets);
        if (
            productList?.data?.assets?.length === 1 &&
            productList.data.assets[0].ticker === ticker
        ) {
            currentPrice(productList?.data?.assets?.[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productList, ticker]);
    useEffect(() => {
        searchKey &&
            run({
                asset_types: [AssetType.Equities, AssetType.Fund],
                ticker: searchKey,
                is_need_price: needPrice ? 1 : undefined,
            });
        return () => {
            cancel();
        };
    }, [cancel, needPrice, run, searchKey]);
    useEffect(() => {
        if (ticker) {
            setSearchKey(ticker);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (isin) {
            searchProduct({
                asset_types: [AssetType.Equities, AssetType.Fund],
                isin: isin,
                is_need_price: 1,
            }).then((res) => {
                if (res.data.assets.length > 0) {
                    let asset = res.data.assets?.[0];
                    setDatalist([asset]);
                    if (form) {
                        form.setFieldsValue({
                            ext_info: {
                                ...form.getFieldValue('ext_info'),
                                ticker: `${asset.ticker}`,
                            },
                        });
                        setReadOnlyValue(`${asset.ticker}-${asset.spec_name ?? asset.name}`);
                    }
                    currentPrice(asset);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isin]);
    useEffect(() => {
        if (form && currentProduct) {
            form.setFieldsValue({
                ext_info: {
                    ...form.getFieldValue('ext_info'),
                    currency: currentProduct.currency,
                },
            });
        }
    }, [form, currentProduct]);

    return (
        <>
            <Form.Item
                label="Underlying | Ticker"
                name={['ext_info', 'ticker']}
                rules={[{ required: required, message: 'Underlying is required' }]}
            >
                <GinkgoSelect
                    showSearch
                    readOnly={readOnly}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    notFoundContent={null}
                    filterOption={false}
                    placeholder={'Enter to search'}
                    readonlyvalue={readOnlyValue}
                    style={{ width: width }}
                    onSearch={(value) => setSearchKey(value)}
                    onSelect={(value: string) => {
                        let result = productList?.data?.assets?.find((item) => {
                            if (item.ticker === value) {
                                currentPrice(item);
                            }
                            return item.ticker === value;
                        });
                        setCurrentProduct(result);
                    }}
                >
                    {(dataList ?? []).map((asset) => {
                        return (
                            <Option key={asset.isin} value={asset.ticker}>
                                {`${asset.ticker}-${asset.spec_name ?? asset.name}`}
                            </Option>
                        );
                    })}
                </GinkgoSelect>
            </Form.Item>
            {withCurrency && (
                <Form.Item
                    name={['ext_info', 'currency']}
                    label="Currency"
                    rules={[{ required: required, message: 'Currency is required' }]}
                >
                    <GinkgoInput readOnly style={{ width: width }} />
                </Form.Item>
            )}
        </>
    );
};

export default SearchUnderlying;
