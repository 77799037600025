import { Button, DatePicker, message, Modal, Spin } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select, Radio, Checkbox } from 'formik-antd';
import moment from 'moment';
import React, { useCallback } from 'react';
import { useState } from 'react';
import { showError } from '../../RecommendationComponent/util';
import {
    SimpleUser,
    searchUserVague,
    LandingPage,
    getLandingPageList,
    getCustomerStatus,
    ValidGroupInfo,
} from '../../../service/requests/PushCenter';
import { useEffect } from 'react';
import './index.less';
import { createPushTask } from '../../../service/requests/PushCenter';
import { EventList, uploadEventTrack } from '../../../service/requests/EventTrack';

export default function CreatePush() {
    const curMoment = moment();
    const [loading, setLoading] = useState(false);
    const [landingPages, setLandingPages] = useState<LandingPage[]>([]);
    const [currentLP, setCurrentLP] = useState<LandingPage | undefined>(undefined);
    const [validGroupInfo, setValidGroupInfo] = useState<ValidGroupInfo[]>([]);
    useEffect(() => {
        getLandingPageList().then((res) => setLandingPages(res.data.lps), showError);
        getCustomerStatus().then((res) => {
            setValidGroupInfo(res.data);
        }, showError);
    }, []);

    const [searchedUser, setSearchedUser] = useState<SimpleUser[]>([]);
    const handleSearchChange = useCallback((keyword: string) => {
        if (keyword) {
            searchUserVague({ keyword }).then((res) => {
                setSearchedUser(res.data);
            }, showError);
        }
    }, []);
    const selectLP = useCallback(
        (index) => {
            setCurrentLP(landingPages[index]);
            uploadEventTrack(EventList.AddPushFilter, 2, {
                name: landingPages[index].name,
            });
        },
        [landingPages]
    );

    return (
        <>
            <Spin spinning={loading}>
                <div className="content">
                    <Formik
                        initialValues={{
                            title: '',
                            body: '',
                            is_all_users: 1,
                            uids: [],
                            uidsSelect: [],
                            lp: '',
                            pushAtOnce: true,
                            push_at: 0,
                            is_persistent: 2,
                            message_type: 2,
                            debug: 2,
                        }}
                        validate={(values) => {
                            let res: { [key: string]: string } = {};
                            let finalValues: { [key: string]: any } = { ...values };

                            if (!values.title) {
                                res.title = '请输入PUSH标题';
                            }
                            if (!values.body) {
                                res.body = '请输入PUSH内容';
                            }
                            if (values.is_all_users === 2 && !values.uids.length) {
                                res.uids = '请选择至少一个发送对象';
                            }

                            if (values.is_all_users === 3 && !values.uidsSelect.length) {
                                res.uids = '请选择至少一组发送对象';
                            }

                            if (values.lp.toString().length === 0) {
                                res.lp = '请选择一个落地页';
                            }
                            currentLP?.paramsMap.forEach((item) => {
                                if (item.defaultValue.length === 0) {
                                    if (
                                        !(
                                            item.defaultValue === '' &&
                                            finalValues[item.key] !== undefined &&
                                            finalValues[item.key].length > 0
                                        )
                                    ) {
                                        res[item.key] = '请输入' + item.hint;
                                    }
                                }
                            });
                            return res;
                        }}
                        onSubmit={(values, formikHelpers) => {
                            let login = localStorage.getItem('login');
                            try {
                                if (!login) throw new Error();
                                let by_user_id = JSON.parse(login).uid;
                                if (!by_user_id) throw new Error();
                                let finalValues: { [key: string]: any } = { ...values };
                                let startStr = '?';
                                currentLP?.paramsMap.forEach((element) => {
                                    if (element.defaultValue !== '') {
                                        startStr += element.key + '=' + element.defaultValue + '&';
                                    } else {
                                        startStr +=
                                            element.key + '=' + finalValues[element.key] + '&';
                                    }
                                });
                                if (startStr.length === 1) {
                                    startStr = '';
                                } else {
                                    startStr = startStr.substring(0, startStr.length - 1);
                                }
                                let uids: string[] = [];
                                if (values.is_all_users === 3) {
                                    values.uidsSelect.forEach((selectIndex) => {
                                        let res = validGroupInfo[
                                            selectIndex
                                        ].customers.map((item) => item.id.toString());
                                        uids = uids.concat(res);
                                    });
                                }
                                const { title, body } = values;
                                Modal.confirm({
                                    width: 500,
                                    title: '这条push将推送给所选用户，点击确认发送后正式发送',
                                    content: (
                                        <div
                                            style={{ whiteSpace: 'pre-line' }}
                                        >{`Push标题：${title}\nPush内容：${body}`}</div>
                                    ),
                                    cancelText: '取消',
                                    okText: '确认发送',
                                    onOk: () => {
                                        setLoading(true);
                                        createPushTask({
                                            ...values,
                                            lp: currentLP!.lp_prefix + startStr,
                                            by_user_id,
                                            uids: uids.length > 0 ? uids : values.uids,
                                            is_all_users: values.is_all_users === 1 ? 1 : 2,
                                        })
                                            .then(() => {
                                                message.success('创建成功');
                                                formikHelpers.resetForm();
                                            }, showError)
                                            .finally(() => {
                                                setLoading(false);
                                            });
                                    },
                                });
                            } catch {
                                setLoading(false);
                                message.error('无法获取登陆信息，请重新登录');
                            }
                        }}
                    >
                        {({ values, submitForm, setFieldValue }) => (
                            <Form style={{ minWidth: '700px' }}>
                                <Form.Item name="title">
                                    <div className="form-item-label">标题</div>
                                    <Input
                                        name="title"
                                        showCount
                                        maxLength={20}
                                        placeholder="请输入PUSH标题"
                                    />
                                </Form.Item>
                                <Form.Item name="body">
                                    <div className="form-item-label">内容</div>
                                    <Input.TextArea
                                        rows={4}
                                        name="body"
                                        showCount
                                        maxLength={50}
                                        placeholder="请输入PUSH内容"
                                    />
                                </Form.Item>
                                <Form.Item name="is_all_users" className="form-omit-explain">
                                    <span className="form-item-label">发送对象</span>
                                    <Radio.Group name="is_all_users">
                                        <Radio name="is_all_users" value={1}>
                                            所有用户
                                        </Radio>
                                        <Radio name="is_all_users" value={3}>
                                            按状态发送
                                        </Radio>
                                        <Radio name="is_all_users" value={2}>
                                            指定用户
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {values.is_all_users === 2 && (
                                    <Form.Item name="uids">
                                        <Select
                                            name="uids"
                                            mode="multiple"
                                            allowClear
                                            placeholder="输入ID或用户名进行添加"
                                            onSearch={handleSearchChange}
                                            optionFilterProp="children"
                                            optionLabelProp="label"
                                        >
                                            {searchedUser.map((user) => (
                                                <Select.Option
                                                    value={user.uid}
                                                    key={user.uid}
                                                    label={user.name}
                                                >
                                                    {user.name} ({user.uid})
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )}
                                {values.is_all_users === 3 && (
                                    <Form.Item name="uidsSelect">
                                        <Checkbox.Group name="uidsSelect">
                                            {validGroupInfo.map((item, index) => (
                                                <Checkbox
                                                    name="uiditem"
                                                    key={item.state}
                                                    value={index}
                                                >
                                                    {item.state + ` (${item.customers.length})`}
                                                </Checkbox>
                                            ))}
                                        </Checkbox.Group>
                                    </Form.Item>
                                )}
                                <Form.Item name="lp">
                                    <span className="form-item-label">落地页</span>
                                    <Select
                                        name="lp"
                                        style={{ minWidth: '112px', width: 'auto' }}
                                        placeholder="选择一个落地页"
                                        onChange={selectLP}
                                    >
                                        {landingPages.map((landingPage, index) => (
                                            <Select.Option value={index} key={landingPage.lp}>
                                                {landingPage.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                {/* {currentLP !== undefined && */}
                                {currentLP?.paramsMap.map((item) => {
                                    if (item.defaultValue.length === 0) {
                                        return (
                                            <Form.Item name={item.key} key={item.key} required>
                                                <span className="form-item-label">{item.hint}</span>
                                                <Input name={item.key} />
                                            </Form.Item>
                                        );
                                    }
                                })}
                                <Form.Item name="pushAtOnce" className="form-omit-explain">
                                    <span className="form-item-label">发送时间</span>
                                    <Radio.Group name="pushAtOnce">
                                        <Radio name="pushAtOnce" value>
                                            立即发送
                                        </Radio>
                                        <Radio
                                            name="pushAtOnce"
                                            value={false}
                                            disabled={process.env.REACT_ADMIN_ENV === 'stage'}
                                        >
                                            定时发送
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>

                                {!values.pushAtOnce && (
                                    <>
                                        <DatePicker
                                            defaultValue={curMoment}
                                            disabledDate={(moment) => {
                                                if (moment.isBefore(curMoment)) {
                                                    return true;
                                                }
                                                return false;
                                            }}
                                            onChange={(moment) => {
                                                if (moment) {
                                                    setFieldValue('push_at', moment.valueOf());
                                                }
                                            }}
                                            allowClear={false}
                                            inputReadOnly
                                            showTime
                                        />
                                    </>
                                )}
                                <Form.Item name="is_persistent" className="form-omit-explain">
                                    <span className="form-item-label">是否在消息中心留存</span>
                                    <Radio.Group name="is_persistent">
                                        <Radio name="is_persistent" value={2}>
                                            否
                                        </Radio>
                                        <Radio name="is_persistent" value={1}>
                                            是
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                {values.is_persistent === 1 && (
                                    <>
                                        <Form.Item
                                            name="message_type"
                                            className="form-omit-explain"
                                        >
                                            <span className="form-item-label">消息分类</span>
                                            <Radio.Group name="message_type">
                                                <Radio name="message_type" value={1}>
                                                    风险警告
                                                </Radio>
                                                <Radio name="message_type" value={2}>
                                                    系统通知
                                                </Radio>
                                                <Radio name="message_type" value={3}>
                                                    产品报价
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </>
                                )}
                                {process.env.REACT_ADMIN_ENV === 'dev' && (
                                    <Form.Item name="debug" className="form-omit-explain">
                                        <span className="form-item-label">isDebug</span>
                                        <Radio.Group name="debug">
                                            <Radio name="debug" value={1}>
                                                是
                                            </Radio>
                                            <Radio name="debug" value={2}>
                                                否
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                )}
                                <Button
                                    onClick={submitForm}
                                    style={{ display: 'block', marginTop: '16px' }}
                                    type="primary"
                                >
                                    发送
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Spin>
        </>
    );
}
