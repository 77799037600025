import { CheckOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './index.module.scss';
import DataAudit from './DataAudit/DataAudit';
import useFetch from '../../../../utils/Hooks/useFetch';
import { getUnreviewAccount_list } from '../../../../service';
import { Button } from 'antd';
const AuditPosition = () => {
    const [currentIndex, setCurrentIndex] = useState(1);
    const [needCheck, setNeedCheck] = useState(false);
    const [check_account] = useMemo(() => {
        const urlParams = new URLSearchParams(window.location.hash.split('?')[1]);
        const check_account = urlParams.get('check_account');
        const check_date = urlParams.get('check_date');
        if (check_account !== null && check_date !== null) {
            setNeedCheck(true);
        }
        return [check_account, check_date];
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [fetchState, setParams, setFreshData] = useFetch({
        interfaces: getUnreviewAccount_list,
        initialParams: null,
        initialData: {
            account_count: 0,
            account_summaries: [],
        },
    });
    useEffect(() => {
        if (needCheck) {
            let index = (fetchState.data?.account_summaries ?? []).findIndex((item) => {
                return item.account_id === check_account;
            });
            if ((fetchState.data?.account_summaries ?? []).length > 0) {
                setCurrentIndex(index + 1);
                setTimeout(() => {
                    setNeedCheck(false);
                }, 0);
            }
        }
    }, [needCheck, check_account, fetchState.data]);
    // useEffect(() => {
    //     if ((fetchState.data?.account_summaries ?? []).length > 0) {
    //         if (fetchState.data!.account_summaries!.length < currentIndex) {
    //             setCurrentIndex((fetchState.data?.account_summaries ?? []).length);
    //         }
    //     }
    // }, [fetchState.data, currentIndex]);
    const clickForward = useCallback(() => {
        if (currentIndex < (fetchState.data?.account_count || 0)) {
            setCurrentIndex(currentIndex + 1);
        }
    }, [fetchState, currentIndex]);

    const clickBack = useCallback(() => {
        if (currentIndex > 1) {
            setCurrentIndex(currentIndex - 1);
        }
    }, [currentIndex]);
    const freshData = useCallback(() => {
        if (
            currentIndex === (fetchState.data?.account_summaries ?? []).length &&
            currentIndex !== 1
        ) {
            setCurrentIndex(currentIndex - 1);
        }
        setFreshData();
    }, [setFreshData, currentIndex, fetchState.data]);

    const account_id = fetchState.data?.account_summaries[currentIndex - 1];
    return (
        <>
            {(fetchState.data?.account_count || 0) > 0 && (
                <>
                    <div className={styles.header}>
                        <div className={styles['header-left']}>
                            <div className={styles.title}>
                                <div>
                                    {fetchState.data?.account_summaries[currentIndex - 1].bank_name}{' '}
                                    -{' '}
                                    {
                                        fetchState.data?.account_summaries[currentIndex - 1]
                                            .account_name
                                    }{' '}
                                    -{' '}
                                    {(account_id?.vendor_account_id ?? '').substring(
                                        (account_id?.vendor_account_id ?? '').length - 2
                                    )}
                                </div>
                                <div className={styles.step}>
                                    ({currentIndex}/{fetchState.data?.account_count})
                                </div>
                            </div>
                            <div>
                                账户号:
                                {
                                    fetchState.data?.account_summaries[currentIndex - 1]
                                        .vendor_account_id
                                }
                            </div>
                        </div>
                        <div className={styles['header-right']}>
                            <div className={styles.pre}>
                                {currentIndex > 1 && (
                                    <Button type="default" onClick={clickBack}>
                                        上一个
                                    </Button>
                                )}
                            </div>
                            <div className={styles.next}>
                                {currentIndex < (fetchState.data?.account_count || 0) && (
                                    <Button type="text" onClick={clickForward}>
                                        下一个
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                    {fetchState.data && (
                        <DataAudit
                            key={
                                fetchState.data.account_summaries[currentIndex - 1].account_id +
                                fetchState.data.account_summaries[currentIndex - 1].update_date
                            }
                            account={fetchState.data.account_summaries[currentIndex - 1]}
                            setFreshData={freshData}
                        />
                    )}
                </>
            )}

            {!fetchState.isLoading && fetchState.data?.account_count === 0 && (
                <div className={styles.done}>
                    <CheckOutlined style={{ fontSize: 40, marginBottom: 20 }} />
                    <div>已审核完所有数据</div>
                    <div>请关闭窗口</div>
                </div>
            )}
        </>
    );
};

export default AuditPosition;
