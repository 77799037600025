import { Button, Form, Input, message, Modal } from 'antd';
import React, { useCallback } from 'react';
import { AuthorityTableRow, DefaultModalProps } from '.';
import { showError } from '../../RecommendationComponent/util';
import { createMenuAuthority } from './requests';

interface AddMenuModalProps extends DefaultModalProps {
    parentMenu?: AuthorityTableRow;
}

export default function AddMenuModal(props: AddMenuModalProps) {
    const { parentMenu, setVisible, refreshData } = props;

    const handleFormFinish = useCallback(
        (values) => {
            createMenuAuthority({ ...values, parentId: parentMenu?.id ?? 0 }).then(() => {
                message.success('添加成功');
                refreshData();
                setVisible(false);
            }, showError);
        },
        [parentMenu, refreshData, setVisible]
    );

    return (
        <Modal
            {...props}
            centered
            destroyOnClose
            title="添加菜单"
            footer={null}
            onCancel={() => setVisible(false)}
        >
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ offset: 1, span: 16 }}
                onFinish={handleFormFinish}
            >
                <Form.Item label="父菜单">{parentMenu?.name ?? '无'}</Form.Item>
                <Form.Item
                    label="菜单名称"
                    required
                    name="name"
                    rules={[{ required: true, message: '请输入内容' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="菜单入口"
                    required
                    name="entrance"
                    rules={[{ required: true, message: '请输入内容' }]}
                >
                    <Input />
                </Form.Item>
                <div
                    style={{
                        paddingTop: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        columnGap: '64px',
                    }}
                >
                    <Button onClick={() => setVisible(false)}>取消</Button>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </div>
            </Form>
        </Modal>
    );
}
