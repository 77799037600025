import { ColDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {
    Button,
    Checkbox,
    Col,
    Form,
    message,
    Modal,
    Pagination,
    Radio,
    Row,
    Select,
    Tooltip,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EventList, uploadEventTrack } from '../../service/requests/EventTrack';
import usePageState from '../../utils/Hooks/usePageState';
import {
    addUserToGroup,
    Group,
    queryAllGroups,
    queryUsers,
    removeUserFromGroup,
    RoleLevel,
    setGroupManager,
    setGroupOwner,
} from './request';
const NEWSPAGESIZE = 12;

enum ActionType {
    add = 'add',
    remove = 'remove',
    setOwner = 'setOwner',
    setManager = 'setManager',
}
const IMManage = () => {
    const { dispacth, selector } = usePageState();

    const [offset, setOffset] = useState(selector?.page ?? 0);
    const [totalNum, setTotalNum] = useState(0);

    const [refreshList, setRefreshList] = useState(0);
    const [visible, setVisible] = useState(false);

    const handleRefreshList = useCallback(() => setRefreshList((num) => num + 1), []);
    const [currentSelect, setCurrentSelect] = useState<undefined | number>(undefined);
    const [users, setUsers] = useState<Group['members']>([]);
    const [loading, setLoading] = useState(false);
    const [actionType, setActionType] = useState<ActionType>(ActionType.add);
    const [infoList, setInfoList] = useState<Group[]>([]);
    const [form] = useForm();
    useEffect(() => {
        queryAllGroups({ limit: NEWSPAGESIZE, offset }).then((res) => {
            setInfoList(res.data.groups);
            setTotalNum(res.data.totalCount);
        });
    }, [offset, refreshList]);
    useEffect(() => {
        queryUsers().then((res) => {
            setUsers(res.data);
        });
    }, []);
    const handlePaginationChange = useCallback(
        (page: number) => {
            setOffset((page - 1) * NEWSPAGESIZE);
            dispacth({ page: (page - 1) * NEWSPAGESIZE });
            setCurrentSelect(undefined);
        },
        [dispacth]
    );
    const actionHandler = (action: ActionType, rowIndex: number) => {
        setActionType(action);
        setCurrentSelect(rowIndex);
        setVisible(true);
        uploadEventTrack(EventList.IMManageIMClick, 2, {
            action_type: action,
        });
    };
    const modalTitle = useMemo(() => {
        switch (actionType) {
            case ActionType.add:
                return '新增人';
            case ActionType.remove:
                return '移除人';
            case ActionType.setOwner:
                return '设置群主';
            case ActionType.setManager:
                return '设置管理员';
            default:
                return '';
        }
    }, [actionType]);
    const initValues = useMemo(() => {
        if (currentSelect === undefined) return {};
        switch (actionType) {
            case ActionType.add:
                return {};
            case ActionType.remove:
                return {};
            case ActionType.setOwner:
                return {
                    uid: infoList[currentSelect].members.find((item) => {
                        return item.roleLevel === RoleLevel.Owner;
                    })?.userId,
                };
            case ActionType.setManager:
                return {
                    uids: (
                        infoList[currentSelect].members.filter(
                            (item) => item.roleLevel === RoleLevel.Manager
                        ) ?? []
                    ).map((item) => item.userId),
                };
            default:
                return {};
        }
    }, [actionType, currentSelect, infoList]);
    useEffect(() => {
        if (form) {
            form.setFieldsValue(initValues);
        }
    }, [form, initValues]);
    const handleOk = useCallback(() => {
        form.submit();
    }, [form]);

    const modalMembers = useMemo(() => {
        if (currentSelect === undefined) return [];
        switch (actionType) {
            case ActionType.add:
                //筛选出当前选中的群组中没有的用户
                return users.filter((item) => {
                    return !infoList[currentSelect].members.some((member) => {
                        return member.userId === item.userId;
                    });
                });
            // return users;
            case ActionType.remove:
                return infoList[currentSelect].members.filter(
                    (item) => item.roleLevel !== RoleLevel.Owner
                );
            case ActionType.setOwner:
                return infoList[currentSelect].members;
            case ActionType.setManager:
                return infoList[currentSelect].members.filter(
                    (item) => item.roleLevel !== RoleLevel.Owner
                );
            default:
                return [];
        }
    }, [currentSelect, actionType, users, infoList]);

    const onFinish = useCallback(
        (values) => {
            if (typeof currentSelect === 'number') {
                let promise = null;
                switch (actionType) {
                    case ActionType.add: {
                        promise = addUserToGroup({
                            groupId: infoList[currentSelect].basic.groupId,
                            uids: values.uids,
                        });
                        break;
                    }
                    case ActionType.remove: {
                        promise = removeUserFromGroup({
                            groupId: infoList[currentSelect].basic.groupId,
                            uids: values.uids,
                        });
                        break;
                    }
                    case ActionType.setOwner: {
                        promise = setGroupOwner({
                            groupId: infoList[currentSelect].basic.groupId,
                            uid: values.uid,
                        });
                        break;
                    }
                    case ActionType.setManager: {
                        promise = setGroupManager({
                            groupId: infoList[currentSelect].basic.groupId,
                            uids: values.uids,
                        });
                        break;
                    }
                }
                setLoading(true);
                promise
                    ?.then(() => {
                        handleRefreshList();
                        setVisible(false);
                        message.success(modalTitle + '成功');
                    })
                    .catch((err) => {
                        message.error(err.msg);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        },
        [actionType, currentSelect, handleRefreshList, infoList, modalTitle]
    );
    const columnDefs = useMemo<ColDef[]>(
        () => [
            {
                headerName: '创建时间',
                field: 'title',
                width: 180,
                cellRenderer: (params: ICellRendererParams) => {
                    if (moment.isDate(params.value)) {
                        let timeStr = moment(params.value).format('YYYY-MM-DD HH:mm:ss');
                        return <div>{timeStr}</div>;
                    } else {
                        return <div>{params.value}</div>;
                    }
                },
                valueGetter: (params: ValueGetterParams) => {
                    return params.data.basic.createTime;
                },
            },
            {
                headerName: '群名称/备注',
                valueGetter: (params: ValueGetterParams) => {
                    return params.data.basic.remark ?? params.data.basic.name;
                },
                width: 200,
            },
            {
                headerName: '群成员列表',
                width: 200,
                flex: 1,
                cellRenderer: (params: ICellRendererParams) => {
                    if (!params.data.members) return null;
                    let data = params.data as Group;
                    let memberNames = (data.members ?? [])
                        .map((item) => item.remarkName ?? item.name)
                        .join('、');
                    return (
                        <Tooltip title={memberNames}>
                            <div>{`${data.members.length}人：${memberNames}`}</div>
                        </Tooltip>
                    );
                },
            },

            {
                headerName: '操作',
                pinned: 'right',
                width: 410,
                cellRenderer: (params: ICellRendererParams) => (
                    <>
                        <Button
                            type="primary"
                            onClick={() => actionHandler(ActionType.add, params.rowIndex)}
                        >
                            新增人
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => actionHandler(ActionType.remove, params.rowIndex)}
                            disabled={params.data.state_code > 2}
                        >
                            移除人
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => actionHandler(ActionType.setOwner, params.rowIndex)}
                        >
                            设置群主
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => actionHandler(ActionType.setManager, params.rowIndex)}
                        >
                            设置管理员
                        </Button>
                    </>
                ),
            },
        ],
        []
    );

    return (
        <>
            <Modal
                visible={visible}
                title={modalTitle}
                onCancel={() => setVisible(false)}
                width={800}
                closable={false}
                maskClosable={false}
                onOk={handleOk}
                confirmLoading={loading}
            >
                {/* {actionType !== ActionType.setManager && (
                    <div style={{ marginBottom: '10px' }}>
                        确认提交后，会在群聊中立刻生效并触发群内通知，请谨慎操作
                    </div>
                )} */}
                <Form onFinish={onFinish} form={form} initialValues={initValues}>
                    <MemberList members={modalMembers} actionType={actionType} />
                </Form>
            </Modal>
            <div className="ag-theme-alpine">
                <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={{ resizable: true, width: 96, minWidth: 68, suppressMenu: true }}
                    rowData={infoList}
                    domLayout="autoHeight"
                    className="CustomerList__Table"
                />
            </div>
            {totalNum > 0 && infoList.length > 0 ? (
                <Pagination
                    onChange={handlePaginationChange}
                    total={totalNum}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 30,
                    }}
                    pageSize={NEWSPAGESIZE}
                    defaultCurrent={offset / NEWSPAGESIZE + 1}
                    showSizeChanger={false}
                />
            ) : null}
        </>
    );
};
const MemberList = ({
    members,
    actionType,
}: {
    members: Group['members'];
    actionType: ActionType;
}) => {
    return (
        <>
            {actionType === ActionType.add && (
                <Form.Item
                    name="uids"
                    label="新增人"
                    rules={[{ required: true, message: '请选择新增人' }]}
                >
                    <Select mode="multiple" placeholder="请选择新增人" optionFilterProp="label">
                        {members.map((item) => (
                            <Select.Option
                                key={item.userId}
                                value={item.userId}
                                label={item.remarkName ?? item.name}
                            >
                                {item.remarkName ?? item.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            )}
            {actionType === ActionType.remove && (
                <Form.Item name="uids">
                    <Checkbox.Group>
                        <Row>
                            {members.map((item) => {
                                return (
                                    <Col key={item.userId}>
                                        <Checkbox value={item.userId}>
                                            {item.remarkName ?? item.name}
                                        </Checkbox>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
            )}
            {actionType === ActionType.setOwner && (
                <Form.Item name="uid">
                    <Radio.Group>
                        <Row>
                            {members.map((item) => {
                                return (
                                    <Col key={item.userId}>
                                        <Radio value={item.userId}>
                                            {item.remarkName ?? item.name}
                                        </Radio>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Radio.Group>
                </Form.Item>
            )}
            {actionType === ActionType.setManager && (
                <Form.Item name="uids">
                    <Checkbox.Group>
                        <Row>
                            {members.map((item) => {
                                return (
                                    <Col key={item.userId}>
                                        <Checkbox value={item.userId}>
                                            {item.remarkName ?? item.name}
                                        </Checkbox>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
            )}
        </>
    );
};
export default IMManage;
