import React from 'react';
import ReactDOM from 'react-dom';
import Page from './Page';
import { Provider } from 'react-redux';
import store from './store/store';
// import * as serviceWorker from './serviceWorker';
import { AppContainer } from 'react-hot-loader';
import './style/index.less';

ReactDOM.render(
    <AppContainer>
        <Provider store={store}>
            <Page />
        </Provider>
    </AppContainer>,
    document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
// serviceWorker.register();
