import { Button, message } from 'antd';
import React, { useState } from 'react';
import { overdue_payments_item } from '../../../../../service/type';
import styles from './index.module.scss';
import { Input } from 'antd';
import { CheckOutline, CloseCircleOutline } from 'antd-mobile-icons';
import { auditOverdue } from '../../../../../service/requests/AuditRequest';
import { showError } from '../../../../RecommendationComponent/util';
interface OverDueItemParams extends overdue_payments_item {
    refreshCallBack?: Function;
}
const OverdueItem = ({ item }: { item: OverDueItemParams }) => {
    const [showInput, setShowInput] = useState(false);
    const [inputValue, setInputValue] = useState('');
    return (
        <div className={styles.content} key={item.position_id}>
            <div className={styles.item}>
                <div className={styles.subitem1}>{`${item.amount} ${item.currency}`}</div>
                <div className={styles.subitem3}>{item.memo}</div>
            </div>
            {item.position_id.length !== 0 && (
                <>
                    {showInput === false && (
                        <div className={styles.button}>
                            <Button
                                type="primary"
                                style={{ minWidth: '80px' }}
                                onClick={() => {
                                    auditOverdue({
                                        position_id: item.position_id,
                                        date: item.date,
                                        confirm: 1,
                                    }).then(() => {
                                        message.success('审核成功');
                                        item.refreshCallBack!();
                                    });
                                }}
                            >
                                是
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => setShowInput(true)}
                                style={{ minWidth: '80px' }}
                            >
                                否
                            </Button>
                        </div>
                    )}
                    {showInput && (
                        <div className={styles.button}>
                            <Input.Group>
                                <Input
                                    className={styles.input}
                                    onChange={(value) => {
                                        setInputValue(value.target.value);
                                    }}
                                    placeholder=""
                                />
                                <Button
                                    type="primary"
                                    style={{ width: '15%', marginLeft: '10px' }}
                                    onClick={() => {
                                        if (inputValue.length > 0) {
                                            auditOverdue({
                                                memo: inputValue,
                                                position_id: item.position_id,
                                                date: item.date,
                                                confirm: 2,
                                            }).then(() => {
                                                message.success('审核成功');
                                                item.refreshCallBack!();
                                            });
                                        } else {
                                            showError({ msg: '请输入费用类型' });
                                        }
                                    }}
                                    icon={<CheckOutline />}
                                />
                                <Button
                                    type="primary"
                                    style={{ width: '15%' }}
                                    onClick={() => setShowInput(false)}
                                    icon={<CloseCircleOutline />}
                                />
                            </Input.Group>
                            <div style={{ fontSize: '12px' }}>
                                tips:请输入该笔费用的交易内容，若为结构化产品请输入标的名称+产品类型（AQ/DQ/FCN/ELN/SDN），如美团DQ
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
export default OverdueItem;
