import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Button, Modal } from 'antd';
import React, { useMemo, useState } from 'react';
import useFetch from '../../../../utils/Hooks/useFetch';
import ReactPaginate from 'react-paginate';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getEditDetail, getEditListInfo } from '../requests';

const PAGE_SIZE = 15;

export default function EditList() {
    const { account_id } = useParams<{
        uid: string;
        account_id: string;
    }>();
    const history = useHistory();
    const { state } = useLocation();

    const [labelArray, setLabelArray] = useState<[string[]]>([[]]);
    const [curPage, setCurPage] = useState(0);
    const [fetchData, setParams] = useFetch({
        interfaces: getEditListInfo,
        initialParams: { current_page: 1, page_size: PAGE_SIZE, account_id },
    });
    const [visible, setVisible] = useState(false);
    const pageCount = useMemo(() => {
        if (fetchData.data) {
            return fetchData.data?.total_pages;
        }
    }, [fetchData]);

    const colDefs = useMemo<ColDef[]>(() => {
        return [
            {
                headerName: '产品',
                field: 'product_name',
            },
            {
                headerName: '资产类别',
                field: 'asset_type',
                width: 120,
            },
            {
                headerName: '操作项',
                field: 'operation',
            },
            {
                headerName: '编辑时间',
                field: 'edit_time',
                flex: 1,
            },
            {
                headerName: '操作',
                pinned: 'right',
                resizable: false,
                width: 120,
                cellRenderer: (params: ICellRendererParams) => {
                    return (
                        <Button
                            style={{ margin: 'auto' }}
                            type="primary"
                            onClick={() => {
                                getEditDetail({ id: params.data.id }).then((res) => {
                                    if (res.data?.special_fields) {
                                        let resArray = Object.keys(res.data.special_fields).map(
                                            (key) => {
                                                return [key, res.data.special_fields[key]];
                                            }
                                        ) as [string[]];
                                        setLabelArray(resArray);
                                        setVisible(true);
                                    }
                                });
                            }}
                        >
                            查看详情
                        </Button>
                    );
                },
            },
        ];
    }, []);

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    type="primary"
                    style={{ marginRight: '20px' }}
                    onClick={() => {
                        history.push({
                            pathname: '/app/customer/positionEditor',
                            state,
                        });
                    }}
                >
                    返回
                </Button>
                <div>{`${fetchData.data?.summary.customer_id} - ${
                    fetchData.data?.summary.customer_name
                } - ${fetchData.data?.summary.bank_name} - ${
                    fetchData.data?.summary.account_name
                } - ${fetchData.data?.summary.vendor_sub_account_id ?? '空'}`}</div>
            </div>
            <Modal
                title="交易详情"
                destroyOnClose
                footer={null}
                visible={visible}
                onCancel={() => setVisible(false)}
                centered
            >
                {labelArray.map((array) => {
                    return (
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div style={{ flex: '1', textAlign: 'right' }}>{array[0]}</div>
                            <div style={{ flex: '2', textAlign: 'left', marginLeft: '20px' }}>
                                {array[1]}
                            </div>
                        </div>
                    );
                })}
            </Modal>
            <div className="ag-theme-alpine" style={{ marginTop: '16px', width: '100%' }}>
                <AgGridReact
                    animateRows
                    columnDefs={colDefs}
                    defaultColDef={{ resizable: true, width: 160 }}
                    rowData={fetchData.data?.summary.histories ?? []}
                    domLayout="autoHeight"
                    enableCellTextSelection
                    ensureDomOrder
                />
                {pageCount && (
                    <ReactPaginate
                        className="pagination"
                        activeClassName="active"
                        previousLabel={<LeftOutlined style={{ fontSize: 12 }} />}
                        nextLabel={<RightOutlined style={{ fontSize: 12 }} />}
                        forcePage={curPage}
                        pageCount={pageCount}
                        onPageChange={(selectedItem) => {
                            setCurPage(selectedItem.selected);
                            setParams({
                                current_page: selectedItem.selected + 1,
                                page_size: PAGE_SIZE,
                                account_id,
                            });
                        }}
                    />
                )}
            </div>
        </>
    );
}
