import axios from '../tools';

interface OrderReq {
    current_page: number;
    page_size: number;
}

export interface Order {
    customer: {
        id: number;
        name: string;
    };
    account: {
        accountType: string;
        accountName: string;
        vendorSubAccountId: string;
    };
    order_id: number;
    status: number;
    status_text: string;
    updated_at: number;
    product_type: string;
    underlying_name: string;
}

interface OrderList {
    total_count: number;
    total_pages: number;
    current_page: number;
    order_list: Order[];
}

export interface DetailField {
    key: string;
    title: string;
    value: string;
    display_value?: string;
    options?: any;
}

export interface OrderDetail {
    order_id: number;
    detail_fields: DetailField[];
    title: string;
}

export interface OrderDetailRes {
    order_detail: OrderDetail;
}

interface OrderEmailTemplate {
    to: string[];
    cc: string[];
    subject: string;
    body: string;
}

interface OrderEmailTemplateRes {
    template: OrderEmailTemplate;
}

export const getOrderList = (data: OrderReq) => {
    return axios.post<OrderList>('/v3/admin/order/list', {
        data,
    });
};

export const getOrderDetail = (data: { order_id: number }) => {
    return axios.post<OrderDetailRes>('/v3/admin/order/detail', {
        data,
    });
};

export const cancelOrder = (data: { order_id: number }) => {
    return axios.post('/v3/admin/order/cancel', {
        data,
    });
};

export const getOrderTemplate = (data: { order_id: number; type?: 1 | 2 }) => {
    return axios.post<OrderEmailTemplateRes>('/v3/admin/order/mail/template', {
        data,
    });
};

export const updateOrderStatusToPbProcessing = (data: { order_id: number }) => {
    return axios.post('/v3/admin/order/update_status_to_pb_processing', {
        data,
    });
};
