import { Modal, Select } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    RMCustomMobileInfo,
    searchRMCustomerMobile,
} from '../../../../service/requests/CustomerManage';
import { showError } from '../../../RecommendationComponent/util';
const { Option } = Select;
interface AddProp {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    defalutValue: string;
    parentMobile: string;
    confirm: (name: string, mobile: string) => void;
    containerId: string;
    // searchResult: (result: RMCustomMobileInfo['customers'][0]) => void;
}

const AddAssociateModal = ({
    visible,
    confirm,
    defalutValue,
    setVisible,
    parentMobile,
    containerId,
}: AddProp) => {
    const [customerList, setCustomerList] = useState<RMCustomMobileInfo['customers']>([]);
    const options = useMemo(
        () =>
            customerList.map((item, index) => (
                <Option key={item.mobile} value={index}>
                    {item.mobile}
                </Option>
            )),
        [customerList]
    );
    const handleSearch = useCallback(
        (keyword: string) => {
            if (keyword)
                searchRMCustomerMobile({ mobile_key: keyword, parent_mobile: parentMobile }).then(
                    (res) => {
                        setCustomerList(res.data.customers);
                    },
                    showError
                );
        },
        [parentMobile]
    );
    useEffect(() => {
        if (defalutValue?.length > 0) {
            handleSearch(defalutValue);
        }
    }, [defalutValue, handleSearch]);
    const [currenSelect, setCurrenSelect] = useState<RMCustomMobileInfo['customers'][0]>();

    const onChange = useCallback(
        (value) => {
            setCurrenSelect(customerList[value]);
        },
        [customerList]
    );

    const onOk = useCallback(() => {
        if (currenSelect) {
            confirm(currenSelect.name, currenSelect.mobile);
        } else {
            confirm('', '');
        }
    }, [currenSelect, confirm]);
    return (
        <Modal
            visible={visible}
            onOk={() => onOk()}
            title="关联子账号"
            getContainer={document.getElementById(containerId) ?? false}
            onCancel={() => {
                setVisible(false);
            }}
        >
            <div>tips: 关联的子账号会与被添加的主账号，共用一套银行账户信息</div>
            <div style={{ display: 'flex', marginTop: '15px', alignItems: 'center' }}>
                <div>关联子账号：</div>
                <Select
                    style={{ flex: '1' }}
                    allowClear
                    defaultValue={defalutValue ?? ''}
                    onSelect={onChange}
                    showSearch
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearch}
                    notFoundContent={null}
                    placeholder={'搜索需要关联的手机号'}
                >
                    {options}
                </Select>
            </div>
        </Modal>
    );
};
export default AddAssociateModal;
