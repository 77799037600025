/**
 * Created by hao.cheng on 2017/4/28.
 */
import { create, all } from 'mathjs';
import moment from 'moment';
import queryString from 'query-string';
import { createBrowserHistory } from 'history';
/**
 * 获取URL参数
 */
export function parseQuery() {
    return queryString.parseUrl(window.location.href).query;
}
export function getFileName(url: string) {
    var pos = url.lastIndexOf('/');
    return url.substring(pos + 1);
}
export const history = createBrowserHistory();
export const downloadFile = (url: string, filename?: string) => {
    const image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute('crossOrigin', 'anonymous');
    image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext('2d');
        context?.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob((blob) => {
            const url = URL.createObjectURL(blob!);
            const a = document.createElement('a');
            a.download = filename || 'photo';
            a.href = url;
            a.click();
            a.remove();
            URL.revokeObjectURL(url);
        });
    };
    image.src = url;
};
/**
 * 校验是否登录
 * @param permits
 */
export const checkLogin = (permits: any): boolean =>
    (process.env.NODE_ENV === 'production' && !!permits) || process.env.NODE_ENV === 'development';

export const timestampToTime = (cjsj: number) => {
    var date = new Date(cjsj); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = date.getDate() + ' ';
    var h = date.getHours() + ':';
    var m = date.getMinutes() + ':';
    var s = date.getSeconds();
    return Y + M + D + h + m + s;
};

/**
 * 获取指定的URL参数
 */
export function getQueryVariable(variable: string) {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (pair[0] === variable) {
            return pair[1];
        }
    }
    return false;
}

/**
 * 格式化金钱（加千分符）
 */
export function FormatNumber(num: number | string, precision?: number): string {
    if (typeof num === 'string') {
        if (num.indexOf(',') > -1) {
            num = num.replace(/,/g, '');
        }
        num = parseFloat(num);
    }
    if (isNaN(num) || num === undefined || num === null) {
        return '';
    }
    let numStr = num.toString();
    if (numStr.indexOf('.') > -1) {
        let [integerPart, decimalPart] = numStr.split('.');
        const t = integerPart.charAt(0);
        if (t === '-') {
            integerPart = integerPart.slice(1);
        }
        if (precision === undefined) {
            return t === '-'
                ? '-' + FormatNumber(integerPart) + '.' + decimalPart
                : FormatNumber(integerPart) + '.' + decimalPart;
        }
        const formattedDecimalPart =
            decimalPart.slice(0, precision) +
            (decimalPart.length < precision ? '0'.repeat(precision - decimalPart.length) : '');

        return t === '-'
            ? '-' + FormatNumber(integerPart) + '.' + formattedDecimalPart
            : FormatNumber(integerPart) + '.' + formattedDecimalPart;
    } else {
        if (precision === undefined) {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
            return num.toFixed(precision).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    }
}
export function FormatPercentNumber(num: number | string) {
    return FormatNumber(num, 2);
}
export function FormatPriceNumber(num: number | string) {
    return FormatNumber(num, 3);
}
/**
 * 生成用于防抖的uuid
 */
export const MakeUuid = () => {
    var s: any[] = [];
    var hexDigits = '0123456789abcdef';
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-';

    var uuid = s.join('');
    return uuid;
};
//判断字符串是否包含中文字符
export const isChina = (s: string) => {
    var patrn = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/gi;

    if (!patrn.exec(s)) {
        return false;
    } else {
        return true;
    }
};
/**
 * 格式化时间
 * 调用 FormatDate(strDate, "yyyy-MM-dd HH:mm:ss")
 * @param strDate （中国标准时间）时间戳等
 * @param strFormat 返回格式
 * @returns
 */
export const FormatDate = (strDate: any, strFormat?: any) => {
    if (!strDate) return;
    if (!strFormat) strFormat = 'yyyy-MM-dd';
    switch (typeof strDate) {
        case 'string':
            strDate = new Date(strDate.replace(/-/g, '/'));
            break;
        case 'number':
            strDate = new Date(strDate);
            break;
    }
    if (strDate instanceof Date) {
        const dict: any = {
            yyyy: strDate.getFullYear(),
            yy: strDate.getFullYear().toString().slice(2),
            M: strDate.getMonth() + 1,
            d: strDate.getDate(),
            H: strDate.getHours(),
            m: strDate.getMinutes(),
            s: strDate.getSeconds(),
            MM: ('' + (strDate.getMonth() + 101)).substr(1),
            dd: ('' + (strDate.getDate() + 100)).substr(1),
            HH: ('' + (strDate.getHours() + 100)).substr(1),
            mm: ('' + (strDate.getMinutes() + 100)).substr(1),
            ss: ('' + (strDate.getSeconds() + 100)).substr(1),
        };
        return strFormat.replace(/(yyyy|yy?|MM?|dd?|HH?|ss?|mm?)/g, function () {
            return dict[arguments[0]];
        });
    }
};
export const isImageURL = (url: string) => {
    const regex = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;
    return regex.test(url);
};
const config: {} = {
    precision: 10,
};
export const math = create(all, config);

export const getDateAfterTenor = (time: number, tenor: string) => {
    const match = tenor.match(/^(\d+)([DMY])$/);
    if (!match) {
        throw new Error(`Invalid tenor: ${tenor}`);
    }
    const [, amount, unit] = match;
    const date = moment(time);
    switch (unit) {
        case 'D':
            return date.add(Number(amount), 'days').valueOf();
        case 'M':
            return date.add(Number(amount), 'months').valueOf();
        case 'Y':
            return date.add(Number(amount), 'years').valueOf();
        default:
            throw new Error(`Invalid unit: ${unit}`);
    }
};
export const getTopLevelDomain = () => {
    var hostname = window.location.hostname;
    var parts = hostname.split('.');

    // 如果域名的部分数大于1，例如：'subdomain.example.com'
    if (parts.length > 1) {
        // 获取最后两个部分，即主域和顶级域名
        var topLevelDomain = parts[parts.length - 2] + '.' + parts[parts.length - 1];
        return topLevelDomain;
    }

    // 如果域名只有一个部分，例如：'localhost'
    return hostname;
};
