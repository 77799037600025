import React, { useMemo } from 'react';
import styles from './index.module.scss';
import { QuoteEntity, UserAccountInfo } from '../../type';
import { AssetType } from '../../../Transaction/type';
import { configDetailSummary } from '../../util';
export interface AccountSummaryProps {
    info?: UserAccountInfo;
    assetType?: AssetType;
    selectedItem?: QuoteEntity;
}
const AccountSummary = ({ info, assetType, selectedItem }: AccountSummaryProps) => {
    const dataList: { name: string; value: any }[] = useMemo(() => {
        return configDetailSummary({ info, assetType, selectedItem });
    }, [assetType, info, selectedItem]);
    return (
        <div className={styles.container}>
            {dataList.map((item, index) => {
                return (
                    <div className={styles.item} key={index}>
                        <div className={styles.value}>{item.value}</div>
                        <div className={styles.key}>{item.name}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default AccountSummary;
