import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './OrderEmail.module.scss';
import {
    getOrderDetail,
    getOrderTemplate,
    updateOrderStatusToPbProcessing,
} from '../../../service/requests/OrderRequest';
import { Modal, message } from 'antd';
import { showError } from '../../RecommendationComponent/util';
import { EventList, uploadEventTrack } from '../../../service/requests/EventTrack';
import querySearch from 'query-string';
import EmailTemplate from '../../../components/EmailTemplate';
import { useRequest } from 'ahooks';
import DetailTables from '../../../components/DetailTables';

const OrderEmail = () => {
    const history = useHistory();
    const location = useLocation();
    const { order_id } = querySearch.parse(location.search) as {
        order_id: string;
    };
    const { data: orderDetails } = useRequest(getOrderDetail, {
        defaultParams: [
            {
                order_id: parseInt(order_id),
            },
        ],
    });
    const { data: emailTemplate } = useRequest(getOrderTemplate, {
        defaultParams: [
            {
                order_id: parseInt(order_id),
            },
        ],
    });
    const onSentClick = () => {
        uploadEventTrack(EventList.OrderEmailAction, 2, {
            action_type: 'email_sent',
        });
        Modal.confirm({
            title: `确认已发送邮件`,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                updateOrderStatusToPbProcessing({ order_id: parseInt(order_id) }).then((res) => {
                    message.success('更新状态成功！');
                    history.push('/app/trade/order');
                }, showError);
            },
        });
    };

    return (
        <div className={styles.content}>
            <div className={styles.email}>
                {emailTemplate && (
                    <EmailTemplate
                        to={emailTemplate?.data.template.to.toString() || ''}
                        cc={emailTemplate?.data.template.cc.toString() || ''}
                        subject={emailTemplate?.data.template.subject.toString() || ''}
                        body={emailTemplate?.data.template.body || ''}
                        onSentClick={onSentClick}
                    />
                )}
            </div>
            <div className={styles.detail}>
                {orderDetails?.data && (
                    <DetailTables
                        title="下单信息核对"
                        details={[
                            {
                                header: orderDetails.data.order_detail.title,
                                fields: orderDetails.data.order_detail.detail_fields,
                            },
                        ]}
                    />
                )}
            </div>
        </div>
    );
};

export default OrderEmail;
