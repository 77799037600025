import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Modal, Space, Pagination, Input } from 'antd';
import Panel from './Panel';
import './index.less';
import AddUserForm from './components/AddUserForm';
import { getUnreviewAccount_list, getUserList } from '../../../service';
import { UserList } from '../../../service/type';
import { showError } from '../../RecommendationComponent/util';
import { Link, useLocation } from 'react-router-dom';
import { MobileStateContext } from '../../../App';
import { EventList, uploadEventTrack } from '../../../service/requests/EventTrack';
import querySearch from 'query-string';
export interface CollapseItemType {
    name: string;
    sub_sec_cate_id: string;
    parent_sec_cate_id: string;
}

export default function Assets() {
    const { isMobile } = useContext(MobileStateContext);
    const location = useLocation();

    const { customer_id } = querySearch.parse(location.search) as {
        customer_id: string;
    };

    const [visible, setVisible] = useState(false);
    const [userList, setUserList] = useState<UserList>([]);
    const [totalNum, setTotalNum] = useState(0);
    const [unreviewAccountCount, setUnreviewAccountCount] = useState(0);
    const [refreshList, setRefreshList] = useState(0);
    const { state } = useLocation<{ curPage: number }>();
    const [offset, setOffset] = useState(((state?.curPage ?? 1) - 1) * 10);
    const [filterField, setFilterField] = useState<string>('');
    const [currentPage, setCurrentPage] = useState(state?.curPage ?? 1);
    const handleRefreshList = useCallback(() => setRefreshList((num) => num + 1), []);
    const handlePaginationChange = useCallback((page: number) => {
        setOffset((page - 1) * 10);
        setCurrentPage(page);
    }, []);
    const handleFilterChange = useCallback((value: string) => {
        setFilterField(value);
        setCurrentPage(1);
        setOffset(0);
    }, []);
    useEffect(() => {
        getUserList({
            offset,
            customer_id,
            keyword: filterField,
        }).then((data) => {
            setUserList(data.data.users);
            setTotalNum(data.data.total_count);
            setVisible(false);
        }, showError);

        getUnreviewAccount_list().then((data) => setUnreviewAccountCount(data.data.account_count));
    }, [refreshList, offset, customer_id, filterField]);
    return (
        <div id="assects">
            <Modal
                title="添加用户"
                destroyOnClose
                footer={null}
                visible={visible}
                okText="确认"
                cancelText="取消"
                onCancel={() => setVisible(false)}
                centered
            >
                <AddUserForm setRefreshList={handleRefreshList} />
            </Modal>

            <div className="add-customer-wrapper">
                <Button
                    onClick={() => {
                        uploadEventTrack(EventList.PositionEditorAddUser, 2);
                        setVisible(true);
                    }}
                >
                    添加账号
                </Button>
                <Input.Search enterButton onSearch={handleFilterChange} />
                {unreviewAccountCount > 0 && (
                    <Link className="link-to-auditposition" target="_blank" to={'/auditposition'}>
                        审核持仓数据({unreviewAccountCount})
                    </Link>
                )}
            </div>
            <Space
                direction="vertical"
                style={{
                    width: isMobile ? '100%' : '90%',
                }}
            >
                {userList.map((userInfor) => (
                    <Panel
                        userInfor={userInfor}
                        key={userInfor.uid}
                        onlyOne={customer_id?.length > 0}
                        setRefreshList={handleRefreshList}
                        curPage={currentPage}
                    />
                ))}
            </Space>
            <Pagination
                defaultCurrent={1}
                onChange={handlePaginationChange}
                total={totalNum}
                current={currentPage}
                showTotal={(total) => `Total ${total} items`}
                style={{
                    width: isMobile ? '100%' : '90%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 30,
                }}
                pageSize={10}
                showSizeChanger={false}
            />
        </div>
    );
}
