import { Button, Tabs } from 'antd';
import React, { useEffect } from 'react';
import FamilyDetail from './FamilyDetail';
import AssetDetail from './AssetDetail/indes';
import { useDispatch, useSelector } from 'react-redux';
import { setRefresh, setTabIndex } from '../../store/actions/actions';
import { LKRootState } from '../../store/store';
const { TabPane } = Tabs;
const PositionList = () => {
    const dispatch = useDispatch();
    const state = useSelector((state: LKRootState) => state.position.state);
    const tabIndex = useSelector((state: LKRootState) => state.position.tabIndex);
    const [currentIndex, setCurrentIndex] = React.useState('family');
    useEffect(() => {
        console.log(tabIndex);
    }, [tabIndex]);
    return (
        <Tabs
            onChange={(e) => {
                setCurrentIndex(e);
                dispatch(setTabIndex(e));
            }}
            defaultActiveKey={tabIndex}
            tabBarExtraContent={{
                right: (
                    <Button
                        onClick={() => {
                            dispatch(
                                setRefresh({
                                    index: currentIndex,
                                    refresh: state.refresh + 1,
                                })
                            );
                        }}
                    >
                        {'Export Excel'}
                    </Button>
                ),
            }}
        >
            <TabPane key="family" tab="Family">
                <FamilyDetail />
            </TabPane>
            <TabPane key="asset" tab="Asset">
                <AssetDetail />
            </TabPane>
        </Tabs>
    );
};

export default PositionList;
