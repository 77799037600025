import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import AccountItem from './FormItems/AccountItem';
import {
    Asset,
    GinkgoTransFormRefType,
    TransELNExtInfo,
    TransStatus,
    TransactionInfo,
    UpdateTransactionReq,
} from '../../../type';
import DateItem from './FormItems/DateItem';
import { Form } from 'antd';
import LKRadioGroup, { LKRadioGroupProps } from '../../../../../components/LKRadioGroup';
import { useForm } from 'antd/lib/form/Form';
import { GinkgoInput, GinkgoInputNumber } from './FormItems/GinkgoFieldItems';
import BondValidity from './FormItems/BondValidity';
import { useLatest } from 'ahooks';
import SearchUnderlying from './FormItems/SearchUnderlying';
import { math } from '../../../../../utils';
import moment from 'moment';
import BankItem from './FormItems/BankItem';
const ElnForm = forwardRef<
    GinkgoTransFormRefType,
    { transaction?: TransactionInfo; asset?: Asset }
>(({ transaction, asset }, onRef) => {
    const data = useMemo(() => transaction, [transaction]);
    const [form] = useForm();
    const [elnType, setElnType] = useState<number>();
    const [priceType, setPriceType] = useState<number>();
    const [dayOrder, setDayOrder] = useState<number>();
    const latestDayOrder = useLatest(dayOrder);
    const [currentPrice, setCurrentPrice] = useState<Asset>();
    const latestCurrentPrice = useLatest(currentPrice);
    const [timezone, setTimezone] = useState(data?.time_zone || '');

    useImperativeHandle(onRef, () => {
        return {
            getFieldsValues: async (validate) => {
                if (validate === false) {
                    return new Promise((resolve) => {
                        return resolve(configValues(form.getFieldsValue()));
                    });
                } else {
                    return form.validateFields().then(configValues);
                }
            },
        };
    });

    const configValues = (values: any) => {
        let ext_info: TransELNExtInfo = values?.ext_info as TransELNExtInfo;
        if (asset || data === undefined) {
            return {
                ...values,
                ext_info: {
                    ...ext_info,
                    is_day_order: dayOrder,
                    price: ext_info.price?.toString(),
                    fill_price: ext_info.fill_price?.toString(),
                    nominal_amount: ext_info.nominal_amount?.toString(),
                    strike_price_rate: ext_info.strike_price_rate?.toString(),
                    issue_price_rate: ext_info.issue_price_rate?.toString(),
                    underlyings: [latestCurrentPrice.current?.isin],
                    knock_out_price_rate: ext_info.knock_out_price_rate?.toString(),
                    underlying_name: latestCurrentPrice.current?.name,
                    underlying_spec_name: latestCurrentPrice.current?.spec_name,
                    underlying_open_price: latestCurrentPrice.current?.underlying_open_price,
                },
            };
        }
        if (data) {
            return {
                ...values,
                client_id: data.client_id,
                transaction_type: data.type.code,
                id: data.id,
                ext_info: {
                    ...data.ext_info,
                    ...ext_info,
                    is_day_order: dayOrder,
                    price: ext_info.price?.toString(),
                    underlyings: [latestCurrentPrice.current?.isin],
                    underlying_name: latestCurrentPrice.current?.name,
                    underlying_spec_name: latestCurrentPrice.current?.spec_name,
                    underlying_open_price: latestCurrentPrice.current?.underlying_open_price,
                    fill_price: ext_info.fill_price?.toString(),
                    nominal_amount: ext_info.nominal_amount?.toString(),
                    strike_price_rate: ext_info.strike_price_rate?.toString(),
                    issue_price_rate: ext_info.issue_price_rate?.toString(),
                    knock_out_price_rate: ext_info.knock_out_price_rate?.toString(),
                },
                status_code: data.status.code,
            };
        }
    };

    const elnTypeOptions: LKRadioGroupProps['datasource'] = [
        {
            label: 'with Knock out',
            value: 1,
            width: '25%',
        },
        {
            label: 'without Knock out',
            value: 2,
            width: '25%',
        },
    ];
    const priceTypeOptions: LKRadioGroupProps['datasource'] = [
        {
            label: 'LMT',
            value: 1,
            width: '12.5%',
        },
        {
            label: 'MKT',
            value: 2,
            width: '12.5%',
        },
        {
            label: 'Market Open',
            value: 3,
            width: '25%',
        },
        {
            label: 'Market Close',
            value: 4,
            width: '25%',
        },
        {
            label: 'VWAP',
            value: 5,
            width: '12.5%',
        },
    ];
    const obsTypeOptions: LKRadioGroupProps['datasource'] = [
        {
            label: 'Daily',
            value: 1,
            width: '12.5%',
        },
        {
            label: 'Monthly',
            value: 2,
            width: '12.5%',
        },
        {
            label: 'Continuous',
            value: 3,
            width: '25%',
        },
    ];
    const PlaceableReadOnly = useMemo(() => {
        return data !== undefined && data.status.code !== TransStatus.Created;
    }, [data]);
    const TradedReadOnly = useMemo(() => {
        return data !== undefined && data.status.code === TransStatus.Traded;
    }, [data]);
    const PlaceableRequired = useMemo(() => {
        return data === undefined || data.status.code === TransStatus.Created;
    }, [data]);
    const TradedRequired = useMemo(() => {
        return data === undefined || data.status.code === TransStatus.Placeable;
    }, [data]);

    useEffect(() => {
        let finalData = asset ?? data;
        if (!finalData) return;
        setDayOrder((finalData.ext_info as TransELNExtInfo).is_day_order);
        setElnType((finalData.ext_info as TransELNExtInfo).eln_type);
        setPriceType((finalData.ext_info as TransELNExtInfo).price_type);
        form.setFieldsValue({
            ...finalData,
            trade_date:
                typeof finalData.trade_date === 'number'
                    ? finalData.trade_date
                    : moment().valueOf(),
        });
    }, [asset, data, form]);
    const handleFormChange = (
        changedValues: Record<string, any>,
        allValues: UpdateTransactionReq
    ) => {
        let changeKey = Object.keys(changedValues)[0];
        const extInfo = allValues.ext_info as TransELNExtInfo;
        if (changeKey === 'ext_info') {
            changeKey = Object.keys(changedValues[changeKey])[0];
            // 1. 同步计算actual_amount
            if (
                (changeKey === 'issue_price_rate' || changeKey === 'nominal_amount') &&
                extInfo?.issue_price_rate &&
                extInfo?.nominal_amount
            ) {
                extInfo!.actual_amount = math.format(
                    math.evaluate(
                        `(${extInfo.issue_price_rate} / 100 * ${extInfo.nominal_amount})`
                    ),
                    { notation: 'fixed' }
                );
            }
            const curExtInfo = latestCurrentPrice.current?.ext_info as TransELNExtInfo;
            if (curExtInfo?.open_price) {
                if (changeKey === 'issue_price_rate') {
                    extInfo!.issue_price = math.format(
                        math.evaluate(
                            `${extInfo.issue_price_rate} / 100 * ${curExtInfo.open_price}`
                        ),
                        { notation: 'fixed', precision: 3 }
                    );
                }
                if (changeKey === 'strike_price_rate') {
                    extInfo!.strike_price = math.format(
                        math.evaluate(
                            `${extInfo.strike_price_rate} / 100 * ${curExtInfo.open_price}`
                        ),
                        { notation: 'fixed', precision: 3 }
                    );
                }
                if (changeKey === 'knock_out_price_rate') {
                    extInfo!.knock_out_price = math.format(
                        math.evaluate(
                            `${extInfo.knock_out_price_rate} / 100 * ${curExtInfo.open_price}`
                        ),
                        { notation: 'fixed', precision: 3 }
                    );
                }
            }

            // 2. 同步计算maturity_date
            if (
                (changeKey === 'maturity_date' && extInfo.issue_date) ||
                (changeKey === 'issue_date' && extInfo.maturity_date)
            ) {
                let tenor = extInfo.maturity_date! - extInfo.issue_date!;
                if (tenor >= 0) {
                    //tenor 由时间戳换为day
                    const days = Math.floor(tenor / (24 * 60 * 60 * 1000));
                    extInfo.tenor = days;
                }
            }

            if (
                (changeKey === 'issue_price_rate' ||
                    changeKey === 'maturity_date' ||
                    changeKey === 'issue_date') &&
                extInfo.tenor &&
                extInfo.issue_price_rate
            ) {
                let result = `(1 - ${extInfo.issue_price_rate} / 100) / (${extInfo.issue_price_rate} / 100) * 360 / ${extInfo.tenor} * 100`;
                extInfo.earn_rate = math.format(math.evaluate(result), {
                    notation: 'fixed',
                    precision: 2,
                });
            }
            if (
                (changeKey === 'fill_nominal_amount' && extInfo.issue_price_rate) ||
                (changeKey === 'issue_price_rate' && extInfo.fill_nominal_amount)
            ) {
                extInfo.fill_actual_amount = math.format(
                    math.evaluate(
                        `(${extInfo.issue_price_rate} / 100 * ${extInfo.fill_nominal_amount})`
                    ),
                    { notation: 'fixed' }
                );
            }
            form.setFieldsValue({
                ext_info: extInfo,
            });
        }
    };
    const handleChangePrice = (price?: Asset) => {
        let allValues = form.getFieldsValue();
        const extInfo = allValues.ext_info as TransELNExtInfo;
        const curExtInfo = price?.ext_info as TransELNExtInfo;

        if (!extInfo || !curExtInfo || !!data) {
            return;
        }

        extInfo!.issue_price = extInfo.issue_price_rate
            ? math.format(
                  math.evaluate(`${extInfo.issue_price_rate} / 100 * ${curExtInfo.open_price}`),
                  { notation: 'fixed', precision: 3 }
              )
            : undefined;
        extInfo!.strike_price = extInfo.strike_price_rate
            ? math.format(
                  math.evaluate(`${extInfo.strike_price_rate} / 100 * ${curExtInfo.open_price}`),
                  { notation: 'fixed', precision: 3 }
              )
            : undefined;

        extInfo!.knock_out_price = extInfo.knock_out_price_rate
            ? math.format(
                  math.evaluate(`${extInfo.knock_out_price_rate} / 100 * ${curExtInfo.open_price}`),
                  { notation: 'fixed', precision: 3 }
              )
            : undefined;
        extInfo!.underlying_open_price = curExtInfo.open_price;
        form.setFieldsValue({
            ext_info: extInfo,
        });
    };
    const underlying = useMemo(() => {
        if (data) {
            return (data?.ext_info as TransELNExtInfo)?.underlyings
                ? (data?.ext_info as TransELNExtInfo).underlyings![0]
                : undefined;
        }
        if (asset) {
            return (asset?.ext_info as TransELNExtInfo)?.underlyings
                ? (asset?.ext_info as TransELNExtInfo).underlyings![0]
                : undefined;
        }
    }, [asset, data]);
    return (
        <Form
            labelCol={{ span: 6 }}
            labelWrap
            wrapperCol={{ span: 18 }}
            form={form}
            onValuesChange={handleFormChange}
        >
            {data && (
                <AccountItem
                    clientId={data.client_id}
                    name={['ext_info', 'account_id']}
                    form={form}
                    readOnlyValue={data.ext_info.account_id}
                    selectCallback={(bank) => {}}
                    width="calc(62.5% - 10px)"
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                />
            )}

            <SearchUnderlying
                withCurrency={data !== undefined}
                form={form}
                needPrice={data === undefined}
                readOnly={PlaceableReadOnly}
                isin={underlying}
                required={PlaceableRequired}
                width="calc(62.5% - 10px)"
                currentPrice={(price) => {
                    setTimezone(price?.time_zone!);
                    setCurrentPrice(price);
                    handleChangePrice(price);
                }}
            />
            {data === undefined && (
                <Form.Item
                    name={['ext_info', 'bank_id']}
                    rules={[{ required: PlaceableRequired }]}
                    label="Bank"
                >
                    <BankItem readOnly={PlaceableReadOnly} />
                </Form.Item>
            )}
            <Form.Item
                label="ELN Type"
                name={['ext_info', 'eln_type']}
                rules={[{ required: PlaceableRequired, message: 'ELN Type is required' }]}
            >
                <LKRadioGroup
                    datasource={elnTypeOptions}
                    onChange={(value) => {
                        setElnType(value as number);
                    }}
                    readOnly={PlaceableReadOnly}
                />
            </Form.Item>
            <DateItem
                label="Issue Date"
                width="calc(62.5% - 10px)"
                name={['ext_info', 'issue_date']}
                readOnly={PlaceableReadOnly}
                required={PlaceableRequired}
                timezone={timezone}
            />
            <DateItem
                label="Maturity Date"
                width="calc(62.5% - 10px)"
                name={['ext_info', 'maturity_date']}
                readOnly={PlaceableReadOnly}
                required={PlaceableRequired}
                timezone={timezone}
            />
            <Form.Item label="Tenor" name={['ext_info', 'tenor']}>
                <GinkgoInputNumber readOnly addonAfter="days" />
            </Form.Item>
            {elnType === 1 && (
                <Form.Item
                    label="Observation Frequency"
                    name={['ext_info', 'observation_frequency']}
                    rules={[
                        {
                            required: PlaceableRequired,
                            message: 'Observation Frequency is required',
                        },
                    ]}
                >
                    <LKRadioGroup datasource={obsTypeOptions} readOnly={PlaceableReadOnly} />
                </Form.Item>
            )}
            {data === undefined && (
                <Form.Item label="Current Stock Price" name={['ext_info', 'underlying_open_price']}>
                    <GinkgoInputNumber style={{ width: 'calc(62.5% - 10px)' }} readOnly />
                </Form.Item>
            )}
            {elnType === 1 && (
                <Form.Item
                    label="Knock out"
                    name={['ext_info', 'knock_out_price_rate']}
                    rules={[{ required: PlaceableRequired, message: 'Knock out is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        addonAfter="%"
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>
            )}
            {elnType === 1 && data === undefined && (
                <Form.Item label="Knock out$" name={['ext_info', 'knock_out_price']}>
                    <GinkgoInputNumber style={{ width: 'calc(62.5% - 10px)' }} readOnly />
                </Form.Item>
            )}
            <Form.Item
                label="Issue"
                name={['ext_info', 'issue_price_rate']}
                rules={[{ required: PlaceableRequired, message: 'Issue is required' }]}
            >
                <GinkgoInputNumber
                    style={{ width: 'calc(62.5% - 10px)' }}
                    addonAfter="%"
                    readOnly={PlaceableReadOnly}
                />
            </Form.Item>
            {data === undefined && (
                <Form.Item label="Issue$" name={['ext_info', 'issue_price']}>
                    <GinkgoInputNumber style={{ width: 'calc(62.5% - 10px)' }} readOnly />
                </Form.Item>
            )}
            <Form.Item
                label="Strike"
                name={['ext_info', 'strike_price_rate']}
                rules={[{ required: PlaceableRequired, message: 'Strike is required' }]}
            >
                <GinkgoInputNumber
                    style={{ width: 'calc(62.5% - 10px)' }}
                    addonAfter="%"
                    readOnly={PlaceableReadOnly}
                />
            </Form.Item>
            {data === undefined && (
                <Form.Item label="Strike$" name={['ext_info', 'strike_price']}>
                    <GinkgoInputNumber style={{ width: 'calc(62.5% - 10px)' }} readOnly />
                </Form.Item>
            )}

            {data === undefined && (
                <Form.Item label="Annualised Rate of Return" name={['ext_info', 'earn_rate']}>
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        addonAfter="%"
                        readOnly
                    />
                </Form.Item>
            )}

            {data && (
                <>
                    <Form.Item
                        label="Nominal Amount"
                        name={['ext_info', 'nominal_amount']}
                        rules={[
                            { required: PlaceableRequired, message: 'Nominal Amount is required' },
                        ]}
                    >
                        <GinkgoInputNumber
                            style={{ width: 'calc(62.5% - 10px)' }}
                            readOnly={PlaceableReadOnly}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Actual Amount"
                        name={['ext_info', 'actual_amount']}
                        rules={[
                            { required: PlaceableRequired, message: 'Actual Amount is required' },
                        ]}
                    >
                        <GinkgoInputNumber style={{ width: 'calc(62.5% - 10px)' }} readOnly />
                    </Form.Item>
                    <Form.Item
                        label="Price Type"
                        name={['ext_info', 'price_type']}
                        rules={[{ required: PlaceableRequired, message: 'Price Type is required' }]}
                    >
                        <LKRadioGroup
                            datasource={priceTypeOptions}
                            onChange={(value) => {
                                setPriceType(value as number);
                            }}
                            readOnly={PlaceableReadOnly}
                        />
                    </Form.Item>
                    {priceType === 1 && (
                        <Form.Item
                            label="Price"
                            name={['ext_info', 'price']}
                            rules={[{ required: PlaceableRequired, message: 'Price is required' }]}
                        >
                            <GinkgoInputNumber
                                style={{ width: 'calc(62.5% - 10px)' }}
                                readOnly={PlaceableReadOnly}
                            />
                        </Form.Item>
                    )}
                </>
            )}

            {data === undefined && (
                <>
                    <Form.Item
                        label="Issuer"
                        name={['ext_info', 'issuer']}
                        rules={[{ required: TradedRequired, message: 'Issuer is required' }]}
                    >
                        <GinkgoInput
                            style={{ width: 'calc(62.5% - 10px)' }}
                            readOnly={TradedReadOnly}
                        />
                    </Form.Item>
                </>
            )}
            {data && (
                <>
                    <Form.Item
                        name={['ext_info', 'validity']}
                        label="Validity"
                        rules={[
                            {
                                required: PlaceableRequired,
                                message: 'Validity is required',
                                validator(rule, value, callback) {
                                    if (latestDayOrder.current === 1) {
                                        callback();
                                        return;
                                    }
                                    if (!value) {
                                        callback('Validity is required');
                                        return;
                                    }
                                    callback();
                                },
                            },
                        ]}
                    >
                        <BondValidity
                            readOnly={PlaceableReadOnly}
                            isDayOrder={data ? (data.ext_info as any)?.is_day_order : undefined}
                            dayChange={(dayOrder) => {
                                setDayOrder(dayOrder ? 1 : 2);
                                form.validateFields([['ext_info', 'validity']]);
                            }}
                        />
                    </Form.Item>

                    <Form.Item label="Custodian Trade ID" name={['ext_info', 'custodian_trade_id']}>
                        <GinkgoInput
                            style={{ width: 'calc(62.5% - 10px)' }}
                            readOnly={TradedReadOnly}
                        />
                    </Form.Item>
                    <Form.Item label="ISIN" name={['ext_info', 'isin']}>
                        <GinkgoInput
                            style={{ width: 'calc(62.5% - 10px)' }}
                            readOnly={TradedReadOnly}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Upfront(%)"
                        name={['ext_info', 'up_front_rate']}
                        rules={[{ required: TradedRequired, message: 'Upfront(%) is required' }]}
                    >
                        <GinkgoInputNumber
                            style={{ width: 'calc(62.5% - 10px)' }}
                            readOnly={TradedReadOnly}
                            addonAfter="%"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Upfront($)"
                        name={['ext_info', 'up_front_amount']}
                        rules={[{ required: TradedRequired, message: 'Upfront($) is required' }]}
                    >
                        <GinkgoInputNumber
                            style={{ width: 'calc(62.5% - 10px)' }}
                            readOnly={TradedReadOnly}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Face Value"
                        name={['ext_info', 'face_value']}
                        rules={[{ required: TradedRequired, message: 'Face Value is required' }]}
                    >
                        <GinkgoInputNumber
                            style={{ width: 'calc(62.5% - 10px)' }}
                            readOnly={TradedReadOnly}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Fill Nominal Amount"
                        name={['ext_info', 'fill_nominal_amount']}
                        rules={[
                            {
                                required: TradedRequired,
                                message: 'Fill Nominal Amount is required',
                            },
                        ]}
                    >
                        <GinkgoInputNumber
                            style={{ width: 'calc(62.5% - 10px)' }}
                            readOnly={TradedReadOnly}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Fill Actual Amount"
                        name={['ext_info', 'fill_actual_amount']}
                        rules={[
                            { required: TradedRequired, message: 'Fill Actual Amount is required' },
                        ]}
                    >
                        <GinkgoInputNumber style={{ width: 'calc(62.5% - 10px)' }} readOnly />
                    </Form.Item>
                    <Form.Item
                        label="Fill Price"
                        name={['ext_info', 'fill_price']}
                        rules={[{ required: TradedRequired, message: 'Fill Price is required' }]}
                    >
                        <GinkgoInputNumber
                            style={{ width: 'calc(62.5% - 10px)' }}
                            readOnly={TradedReadOnly}
                        />
                    </Form.Item>

                    <DateItem
                        label="Observation Date of Maturity"
                        name={['ext_info', 'observation_date_of_maturity']}
                        width="calc(62.5% - 10px)"
                        readOnly={TradedReadOnly}
                        required={TradedRequired}
                        timezone={timezone}
                    />
                    <DateItem
                        label="Trade Date"
                        name="trade_date"
                        width="calc(62.5% - 10px)"
                        readOnly={TradedReadOnly}
                        required={TradedRequired}
                        timezone={timezone}
                    />
                    <DateItem
                        label="Value Date"
                        name="value_date"
                        width="calc(62.5% - 10px)"
                        readOnly={TradedReadOnly}
                        required={TradedRequired}
                        timezone={timezone}
                    />
                </>
            )}
            {asset?.updated_at && (
                <Form.Item name={['ext_info', 'underlying_open_price']} label="Updated Time">
                    {moment(asset.updated_at).format('YYYY-MM-DD HH:mm:ss')}
                </Form.Item>
            )}
        </Form>
    );
});

export default ElnForm;
