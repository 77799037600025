import React, { useImperativeHandle, forwardRef, useCallback, useMemo, useState } from 'react';
import { SelectProps, Select } from 'antd';
const { Option } = Select;
export interface AllSelectRef {
    clearValue: () => void;
}
interface AllSelectProps {
    selectProp: SelectProps;
    children: { key: string; value: any; name: string }[];
    allText: string;
    onChange: (values: string[]) => void;
}
const SelectWithAll = forwardRef<AllSelectRef, AllSelectProps>(
    ({ selectProp, children, allText, onChange }: AllSelectProps, onRef) => {
        useImperativeHandle(
            onRef,
            () => ({
                clearValue: () => {
                    setValue([]);
                },
            }),
            []
        );
        const [value, setValue] = useState<string[]>([]);
        const options = useMemo(() => {
            let list = children.map((item) => (
                <Option key={item.key} value={item.value}>
                    {item.name}
                </Option>
            ));
            list.unshift(
                <Option key={'all'} value="all">
                    {allText}
                </Option>
            );
            return list;
        }, [children, allText]);

        const allValue = useMemo(() => children.map((e) => e.value), [children]);

        const handleChange = useCallback(
            (values: string[]) => {
                let hasAllOption = values.indexOf('all') > -1;
                if (hasAllOption) {
                    if (values[0] === 'all' && values.length === 2) {
                        setValue([values[1]]);
                        onChange([values[1]]);
                        return;
                    } else {
                        setValue(['all']);
                        onChange([]);
                        return;
                    }
                }
                if (values.length === allValue.length && !hasAllOption) {
                    setValue(['all']);
                    onChange([]);
                    return;
                }
                setValue(values);
                onChange(values);
            },
            [allValue, onChange]
        );

        return (
            <Select
                placeholder={allText}
                value={value}
                mode={selectProp.mode}
                className={selectProp.className}
                onChange={handleChange}
                allowClear={false}
            >
                {options}
            </Select>
        );
    }
);
export default SelectWithAll;
