import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Table, TableColumnType } from 'antd';
import { EditableCell, EditableRow, EditableCellProps, EditableCellType } from '../EditableCell';
import { ProductQuoteDetailType, QuoteEntity, QuoteRelatedTargetAction } from '../../type';
import { AssetType } from '../../../Transaction/type';
import { QouteHook, QuoteSubListMoreColums } from '../../../GKProductPrice/PriceHook';
import { configDefaultValue } from '../../util';
import { DataIndex } from 'rc-table/lib/interface';
interface QuoteAllTableProps {
    data: ProductQuoteDetailType;
    assetType: AssetType;
    handleSelectCaluclate: (selectedList: QuoteEntity[], action?: QuoteRelatedTargetAction) => void;
}
type columnType = (TableColumnType<QuoteEntity> & {
    editable?: boolean;
    type?: EditableCellType;
    options?: { key: string; value: any }[];
    addonAfter?: ReactNode;
})[];
const QuoteSubTable = ({ data, assetType, handleSelectCaluclate }: QuoteAllTableProps) => {
    const [dataSource, setDataSource] = useState<QuoteEntity[]>([]);
    type EditableTableProps = Parameters<typeof Table>[0];
    type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
    useEffect(() => {
        let result = data.selected_quotes?.map((item) => {
            return configDefaultValue(item, assetType, data.minimum_transaction_amount);
        });
        setDataSource(result);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.selected_quotes]);
    const originColumns = QouteHook({
        type: assetType,
        isSub: true,
    }).columns;
    const corColumns: columnType = QuoteSubListMoreColums({
        type: assetType,
        data,
    }).columns;
    const defaultColumns: columnType = [...corColumns, ...originColumns];
    const handleSave = (row: QuoteEntity, dataIndex?: DataIndex, id?: QuoteRelatedTargetAction) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.isin === item.isin);
        const item = newData[index];
        const newItem: any = {
            ...item,
            ...row,
            ext_info: {
                ...item.ext_info,
                ...row.ext_info,
            },
        };
        if (
            dataIndex &&
            (data.asset_type.code === AssetType.AQ || data.asset_type.code === AssetType.DQ) &&
            (JSON.stringify(dataIndex) === JSON.stringify(['ext_info', 'day_volume']) ||
                JSON.stringify(dataIndex) === JSON.stringify(['ext_info', 'total_amount']))
        ) {
            if ((dataIndex as Array<string>)[1] === 'day_volume') {
                newItem.ext_info.total_amount = undefined;
            } else {
                newItem.ext_info.day_volume = undefined;
            }
        }
        newData.splice(index, 1, newItem);
        handleSelectCaluclate(newData, id);
        setDataSource(newData);
    };

    const columns = defaultColumns?.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: EditableCellProps<QuoteEntity>) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                type: col.type,
                options: col.options,
                handleSave: (item: any, id: QuoteRelatedTargetAction) => {
                    handleSave(item, col.dataIndex, id);
                },
            }),
        };
    });
    const resultData = useMemo(() => {
        // let resultData = dataSource?.sort((a, b) => {
        //     return parseFloat(b.coupon as string) - parseFloat(a.coupon as string);
        // });
        // return resultData;
        return dataSource;
    }, [dataSource]);
    return (
        <Table
            components={{
                body: {
                    cell: EditableCell,
                    row: EditableRow,
                },
            }}
            columns={columns as ColumnTypes}
            style={{ marginTop: '16px' }}
            dataSource={resultData}
            size="small"
            onRow={(record) => {
                return {};
            }}
            pagination={false}
            rowKey="isin"
            scroll={{ x: 'max-content' }}
        />
    );
};

export default QuoteSubTable;
