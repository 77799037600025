import React, { useEffect, useMemo, useState } from 'react';
import { VerifiedStatus } from '../../type';
import { AccountManageType } from '../../../Transaction/type';
import styles from './index.module.less';
import LKRadioGroup from '../../../../components/LKRadioGroup';
import { Form, Select } from 'antd';
import LKCheckbox from '../../../../components/LKCheckbox';
import { useForm } from 'antd/lib/form/Form';
import { useRequest } from 'ahooks';
import { searchAccountWithNumber } from '../../../Transaction/request';
const ReconciliationHeader = ({
    onChange,
    initValues,
}: {
    onChange: (
        account_manage_type: number,
        status: number[],
        account_id?: string,
        account_number?: string
    ) => void;
    initValues?: {
        account_id?: string;
        account_manage_type?: number;
        status?: number[];
        account_number?: string;
    };
}) => {
    const accountOptions = Object.keys(AccountManageType)
        .filter((key) => !isNaN(Number(AccountManageType[key as keyof typeof AccountManageType])))
        .map((key) => ({
            label: key,
            value: Number(AccountManageType[key as keyof typeof AccountManageType]),
            width: '12.5%',
        }));
    const statusOptions = Object.keys(VerifiedStatus)
        .filter((key) => !isNaN(Number(VerifiedStatus[key as keyof typeof VerifiedStatus])))
        .map((key) => ({
            label: key,
            value: Number(VerifiedStatus[key as keyof typeof VerifiedStatus]),
            width: '12.5%',
        }));

    const [form] = useForm();
    const [searchKey, setSearchKey] = useState('');
    const { data: accountList, run, cancel } = useRequest(searchAccountWithNumber, {
        manual: true,
        defaultParams: [
            {
                account_number: '',
            },
        ],
    });
    useEffect(() => {
        if (initValues) {
            if (initValues.account_number && initValues.account_number !== '') {
                setSearchKey(initValues.account_number);
            }
            form.setFieldsValue({
                account_id: initValues.account_id,
                account_manage_type: initValues.account_manage_type,
                status: initValues.status,
            });
        } else {
            form.setFieldsValue({
                account_manage_type: 1,
                status: [VerifiedStatus.Unverified],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        // if (searchKey === '') {
        //     return;
        // }
        run({
            account_number: searchKey,
        });
        return () => {
            cancel();
        };
    }, [cancel, run, searchKey]);
    const handleSearch = (searchKey: string) => {
        setSearchKey(searchKey);
    };
    const options = useMemo(() => {
        if ((accountList?.data ?? []).length > 0) {
            return accountList?.data.map((item) => {
                return (
                    <Select.Option value={item.account_id} key={item.account_id}>
                        {item.bank_name +
                            ' - ' +
                            item.vendor_sub_account_id +
                            ' - ' +
                            item.account_name}
                    </Select.Option>
                );
            });
        }
        return undefined;
    }, [accountList]);
    return (
        <Form
            form={form}
            style={{ width: '1000px' }}
            labelAlign="right"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 19 }}
            onValuesChange={() => {
                let values = form.getFieldsValue();
                if (values.account_id === undefined) {
                    setSearchKey('');
                }
                onChange(
                    values.account_manage_type,
                    values.status,
                    values.account_id,
                    accountList?.data?.find((item) => item.account_id === values.account_id)
                        ?.vendor_sub_account_id ?? ''
                );
            }}
        >
            <Form.Item label="Account Number" name="account_id">
                <Select
                    style={{ width: 'calc(87.5% - 10px)' }}
                    showSearch
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={handleSearch}
                    placeholder={'Enter account number'}
                    allowClear
                >
                    {options}
                </Select>
            </Form.Item>
            <Form.Item label="Accounts" name="account_manage_type">
                <LKRadioGroup className={styles.value} datasource={accountOptions} />
            </Form.Item>
            <Form.Item label="Status" name="status">
                <LKCheckbox className={styles.value} datasource={statusOptions} />
            </Form.Item>
        </Form>
    );
};

export default ReconciliationHeader;
