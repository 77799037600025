import React from 'react';
import styles from './index.module.less';

interface TransferAccountTableProps {
    from: {
        accountBank: string;
        accountName: string;
        accountNo: string;
    };
    to: {
        accountBank: string;
        accountName: string;
        accountNo: string;
    };
}
const TransferAccountTable = (props: TransferAccountTableProps) => {
    const { from, to } = props;
    return (
        <>
            <div className={styles.title}>Accounts</div>
            <div className={styles.table}>
                <div className={styles.header}>
                    <div className={`${styles.headerCell} ${styles.key}`} />
                    <div className={styles.headerCell}>From</div>
                    <div className={styles.headerCell}>To</div>
                </div>
                <div className={styles.row}>
                    <div className={`${styles.cell} ${styles.key}`}>Bank</div>
                    <div className={styles.cell}>{from.accountBank}</div>
                    <div className={styles.cell}>{to.accountBank}</div>
                </div>
                <div className={styles.row}>
                    <div className={`${styles.cell} ${styles.key}`}>Account Name</div>
                    <div className={styles.cell}>{from.accountName}</div>
                    <div className={styles.cell}>{to.accountName}</div>
                </div>
                <div className={styles.row}>
                    <div className={`${styles.cell} ${styles.key}`}>Account Number</div>
                    <div className={styles.cell}>{from.accountNo}</div>
                    <div className={styles.cell}>{to.accountNo}</div>
                </div>
            </div>
        </>
    );
};

export default TransferAccountTable;
