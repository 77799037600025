import React, { useEffect, useMemo, useState } from 'react';
import styles from './Trade.module.scss';
import { Col, Row, Select } from 'antd';
import BarChart from '../Components/BarChart';
import { QuestionId, getDashboardData } from '../../../service/requests/Dashboard';
import useFetch from '../../../utils/Hooks/useFetch';
import moment from 'moment';
import { numberTransformer } from '../util';
import classnames from 'classnames';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';

const TradeData = () => {
    const [amountEndDate] = useState(moment().format('yyyy-MM-DD'));
    const [amountPeriod, setAmountPeriod] = useState(7);
    const [monthAccountData, setMonthCountData] = useState<{ keys: string[]; data: number[] }>();
    const [weekTypeData, setWeekTypeData] = useState<{ keys: string[]; data: number[] }>();
    const [monthCountRes] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.TradeMonthCount,
        },
    });
    const [monthAccountRes] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.TradeMonthAccount,
        },
    });
    const [weekCountRes] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.TradeWeekCount,
        },
    });
    const [weekTypeRes] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.TradeWeekType,
        },
    });
    const [amountRes, setAmountParam] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.TradeAmount,
            question_params: {
                start_at: moment(amountEndDate)
                    .subtract(amountPeriod - 1, 'days')
                    .format('yyyy-MM-DD'),
                end_at: moment(amountEndDate).format('yyyy-MM-DD'),
            },
        },
    });
    const [amountChartRes, setAmountChartParam] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.TradeAmountChart,
            question_params: {
                start_at: moment(amountEndDate)
                    .subtract(amountPeriod - 1, 'days')
                    .format('yyyy-MM-DD'),
                end_at: moment(amountEndDate).format('yyyy-MM-DD'),
            },
        },
    });
    useEffect(() => {
        setAmountParam((value) => ({
            ...value,
            question_params: {
                start_at: moment(amountEndDate)
                    .subtract(amountPeriod - 1, 'days')
                    .format('yyyy-MM-DD'),
                end_at: moment(amountEndDate).format('yyyy-MM-DD'),
            },
        }));
        setAmountChartParam((value) => ({
            ...value,
            question_params: {
                start_at: moment(amountEndDate)
                    .subtract(amountPeriod - 1, 'days')
                    .format('yyyy-MM-DD'),
                end_at: moment(amountEndDate).format('yyyy-MM-DD'),
            },
        }));
    }, [amountPeriod, amountEndDate, setAmountParam, setAmountChartParam]);
    useEffect(() => {
        if (monthAccountRes.data) {
            let keys: string[] = [];
            let data: number[] = [];
            monthAccountRes.data.forEach((t: { name: string; total_transaction_num: number }) => {
                keys.push(t.name);
                data.push(t.total_transaction_num);
            });
            setMonthCountData({
                keys,
                data,
            });
        }
    }, [monthAccountRes.data]);
    useEffect(() => {
        if (weekTypeRes.data) {
            let keys: string[] = [];
            let data: number[] = [];
            weekTypeRes.data.forEach((t: { type: string; count: number }) => {
                keys.push(t.type);
                data.push(t.count);
            });
            setWeekTypeData({
                keys,
                data,
            });
        }
    }, [weekTypeRes.data]);
    const option = useMemo(() => {
        if (amountChartRes.data) {
            let keys: string[] = [];
            let data: number[] = [];
            amountChartRes.data.forEach((t: { trade_date: string; amount: number }) => {
                keys.push(t.trade_date);
                data.push(t.amount);
            });
            return {
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                },
                xAxis: {
                    axisTick: {
                        alignWithLabel: true,
                    },
                    boundaryGap: false,
                    type: 'category',
                    data: keys.map((k) => moment(k).format('MM.DD')),
                    axisLabel: {
                        color: '#aaa',
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#ddd',
                        },
                    },
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        lineStyle: {
                            type: 'dashed',
                        },
                    },
                    axisLabel: {
                        color: '#aaa',
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#ddd',
                        },
                    },
                },
                grid: {
                    top: '20px',
                    bottom: '20px',
                    left: '50px',
                },
                series: [
                    {
                        name: '交易总额(USD)',
                        data: data,
                        type: 'line',
                        lineStyle: {
                            color: '#FFC612',
                        },
                        itemStyle: {
                            opacity: 0,
                        },
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: 'rgba(255,198,18,0.15)',
                                    },
                                    {
                                        offset: 1,
                                        color: 'rgba(255,198,18,0.00)',
                                    },
                                ],
                            },
                        },
                    },
                ],
            };
        }
    }, [amountChartRes.data]);
    const selectOptions = [
        { label: '近7天', value: 7 },
        { label: '近30天', value: 30 },
    ];
    const formatValue = (num: number) => {
        return num > 0 ? `+${num}%` : `${num}%`;
    };
    const formatStyle = (num: number) => {
        return num < 0 ? 'fall' : 'rise';
    };
    return (
        <>
            <div className={styles.title}>交易数据</div>
            <Row gutter={16}>
                <Col span={8}>
                    {amountRes.data && amountChartRes.data && (
                        <div className={styles.pannel}>
                            <div className={styles.titleArea}>
                                <div className={styles.pannelTitle}>交易总额</div>
                                <Select
                                    options={selectOptions}
                                    value={amountPeriod}
                                    onChange={(e) => {
                                        setAmountPeriod(e);
                                    }}
                                />
                            </div>
                            <div className={styles.time}>{`${moment(amountEndDate)
                                .subtract(amountPeriod - 1, 'days')
                                .format('yyyy.MM.DD')} - ${moment(amountEndDate).format(
                                'yyyy.MM.DD'
                            )}`}</div>
                            <div>
                                <span className={styles.count}>
                                    {numberTransformer(amountRes.data[0].current_amount).value}
                                </span>
                                <span className={styles.countUnit}>
                                    {numberTransformer(amountRes.data[0].current_amount).unit}
                                </span>
                                <span className={styles.curreny}>{amountRes.data[0].currency}</span>
                            </div>
                            <div className={styles.amount}>{amountRes.data[0].current_amount}</div>
                            <div className={styles.content}>
                                <span>环比同期</span>
                                <span
                                    className={classnames({
                                        [styles[
                                            formatStyle(amountRes.data[0].last_period_rate)
                                        ]]: true,
                                    })}
                                >
                                    {formatValue(amountRes.data[0].last_period_rate)}
                                </span>
                            </div>
                            <div>
                                <ReactEChartsCore
                                    echarts={echarts}
                                    option={option}
                                    style={{ height: '230px' }}
                                />
                            </div>
                        </div>
                    )}
                </Col>
                <Col span={8}>
                    {monthCountRes.data && monthAccountRes.data && monthAccountData && (
                        <BarChart
                            title="近30天交易量"
                            time={`${moment()
                                .subtract(29, 'days')
                                .format('yyyy.MM.DD')} - ${moment().format('yyyy.MM.DD')}`}
                            count={monthCountRes.data[0].total_transaction_num}
                            unit="笔"
                            keys={monthAccountData.keys}
                            data={monthAccountData.data}
                        />
                    )}
                </Col>
                <Col span={8}>
                    {weekCountRes.data && weekTypeRes.data && weekTypeData && (
                        <BarChart
                            title="本周交易类型"
                            time={`${moment()
                                .startOf('week')
                                .format('yyyy.MM.DD')} - ${moment()
                                .endOf('week')
                                .format('yyyy.MM.DD')}`}
                            count={weekCountRes.data[0].total_transaction}
                            unit="个"
                            keys={weekTypeData.keys}
                            data={weekTypeData.data}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default TradeData;
