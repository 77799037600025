import { Col, Divider, Row } from 'antd';
import React from 'react';
import { TransactionInfo } from '../../../type';
import styles from '../index.module.less';
const TransFormHeader = (data: TransactionInfo) => {
    return (
        <>
            <div className={styles.modalHeader}>
                <Row>
                    <Col span={6}>
                        <h1>{data.type.desc}</h1>
                    </Col>
                    <Col span={18}>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <span>{data.status.desc}</span>
                            <span>{data.client.name}</span>
                        </div>
                    </Col>
                </Row>
            </div>
            <Divider />
        </>
    );
};

export default TransFormHeader;
