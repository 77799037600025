import React from 'react';
import styles from './index.module.less';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Asset } from '../../type';
import { FormatNumber } from '../../../../utils';

interface AssetTableProp {
    data: Asset[];
}
const AssetTable = ({ data }: AssetTableProp) => {
    const columns: ColumnsType<Asset> = [
        {
            title: 'Asset Type',
            render: (_, record) => record.asset_type.desc,
        },
        {
            title: 'Asset Name',
            dataIndex: 'name',
        },
        {
            title: 'Amount',
            render: (_, record) =>
                record.amount ? FormatNumber(record.amount, 2) : FormatNumber(record.qty!),
        },
    ];
    return (
        <div className={styles.assetTable}>
            <div className={styles.assetTitle}>Assets</div>
            <Table
                rowKey={(record) => record.isin}
                columns={columns}
                dataSource={data}
                size="small"
                pagination={false}
            />
        </div>
    );
};

export default AssetTable;
