import React from 'react';
import { Button, Form } from 'antd';
import { css } from '@emotion/css';

export const TranscationResultWidget = ({
    style,
    data,
    onFinish,
    onCancel,
}: {
    data: Array<TranscationResultItem>;
    style: {};
    onFinish: () => void;
    onCancel: () => void;
}) => {
    const styles = css`
        display: flex;
        flex-direction: row;
        justify-content: center;
    `;
    return (
        <Form style={style} labelCol={{ span: 5 }} wrapperCol={{ span: 16 }} labelWrap>
            {data.map((item) => (
                <Form.Item key={item.name} label={item.name}>
                    {item.value}
                </Form.Item>
            ))}
            <div className={styles}>
                <Button
                    type="primary"
                    htmlType="button"
                    style={{ marginRight: 20 }}
                    onClick={onFinish}
                >
                    确认无误
                </Button>
                <Button type="text" htmlType="button" onClick={onCancel}>
                    修改内容
                </Button>
            </div>
        </Form>
    );
};

export type TranscationResultItem = {
    name: string;
    value: string;
};
