import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translation_en from './en';
import translation_zh from './zh';

const resources = {
    en: {
        translation: translation_en,
    },
    zh: {
        translation: translation_zh,
    },
};
const lang = window.localStorage.getItem('lk_lang') ?? 'zh';
i18n.use(initReactI18next).init({
    resources,
    lng: lang ?? ((navigator.language ?? 'zh').includes('zh') ? 'zh' : 'en'),
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
