import React, { useEffect, useState } from 'react';
import styles from './ProductData.module.scss';
import { Col, Row } from 'antd';
import BarChart from '../Components/BarChart';
import useFetch from '../../../utils/Hooks/useFetch';
import { QuestionId, getDashboardData } from '../../../service/requests/Dashboard';
import moment from 'moment';

const ProductData = () => {
    const [positionTime, setPositionTime] = useState(moment().format('yyyy-MM-DD'));
    const [positionTypeData, setPositionTypeData] = useState<{ keys: string[]; data: number[] }>();
    const [structuralTime, setStructuralTime] = useState(moment().format('yyyy-MM-DD'));
    const [structuralTypeData, setStructuralTypeData] = useState<{
        keys: string[];
        data: number[];
    }>();
    const [statementEndTime, setStatementEndTime] = useState(
        moment().endOf('week').format('yyyy-MM-DD')
    );
    const [statementTypeData, setStatementTypeData] = useState<{
        keys: string[];
        data: number[];
    }>();
    const [positionCountRes, SetPositionCountParam] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.ProductPositionCount,
            question_params: {
                date: moment(positionTime).format('yyyy-MM-DD'),
            },
        },
    });
    const [positionTypeRes, setPositionTypeParam] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.ProductPositionType,
            question_params: {
                date: moment(positionTime).format('yyyy-MM-DD'),
            },
        },
    });
    const [structuralCountRes, setStructuralCountParam] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.ProductStructuralCount,
            question_params: {
                date: moment(structuralTime).format('yyyy-MM-DD'),
            },
        },
    });
    const [structuralTypeRes, setStructuralTypeParam] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.ProductStructuralType,
            question_params: {
                date: moment(positionTime).format('yyyy-MM-DD'),
            },
        },
    });
    const [statementCountRes, setStatementCountParam] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.ProductStatementCount,
            question_params: {
                start_at: moment(statementEndTime).subtract(6, 'days').format('yyyy-MM-DD'),
                end_at: moment(statementEndTime).format('yyyy-MM-DD'),
            },
        },
    });
    const [statementTypeRes, setStatementTypeParam] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.ProductStatementType,
            question_params: {
                start_at: moment(statementEndTime).subtract(6, 'days').format('yyyy-MM-DD'),
                end_at: moment(statementEndTime).format('yyyy-MM-DD'),
            },
        },
    });
    useEffect(() => {
        if (positionTypeRes.data) {
            let keys: string[] = [];
            let data: number[] = [];
            positionTypeRes.data.forEach((t: { asset_class: string; count: number }) => {
                keys.push(t.asset_class);
                data.push(t.count);
            });
            setPositionTypeData({
                keys,
                data,
            });
        }
    }, [positionTypeRes.data]);
    useEffect(() => {
        if (structuralTypeRes.data) {
            let keys: string[] = [];
            let data: number[] = [];
            structuralTypeRes.data.forEach((t: { struct_product_type: string; count: number }) => {
                keys.push(t.struct_product_type);
                data.push(t.count);
            });
            setStructuralTypeData({
                keys,
                data,
            });
        }
    }, [structuralTypeRes.data]);
    useEffect(() => {
        if (statementTypeRes.data) {
            let keys: string[] = [];
            let data: number[] = [];
            statementTypeRes.data.forEach((t: { asset_class: string; count: number }) => {
                keys.push(t.asset_class);
                data.push(t.count);
            });
            setStatementTypeData({
                keys,
                data,
            });
        }
    }, [statementTypeRes.data]);
    useEffect(() => {
        SetPositionCountParam((value) => {
            return {
                ...value,
                question_params: {
                    date: moment(positionTime).format('yyyy-MM-DD'),
                },
            };
        });
        setPositionTypeParam((value) => {
            return {
                ...value,
                question_params: {
                    date: moment(positionTime).format('yyyy-MM-DD'),
                },
            };
        });
    }, [positionTime, SetPositionCountParam, setPositionTypeParam]);
    useEffect(() => {
        setStructuralCountParam((value) => {
            return {
                ...value,
                question_params: {
                    date: moment(structuralTime).format('yyyy-MM-DD'),
                },
            };
        });
        setStructuralTypeParam((value) => {
            return {
                ...value,
                question_params: {
                    date: moment(structuralTime).format('yyyy-MM-DD'),
                },
            };
        });
    }, [structuralTime, setStructuralCountParam, setStructuralTypeParam]);
    useEffect(() => {
        setStatementCountParam((value) => {
            return {
                ...value,
                question_params: {
                    start_at: moment(statementEndTime).subtract(6, 'days').format('yyyy-MM-DD'),
                    end_at: moment(statementEndTime).format('yyyy-MM-DD'),
                },
            };
        });
        setStatementTypeParam((value) => {
            return {
                ...value,
                question_params: {
                    start_at: moment(statementEndTime).subtract(6, 'days').format('yyyy-MM-DD'),
                    end_at: moment(statementEndTime).format('yyyy-MM-DD'),
                },
            };
        });
    }, [statementEndTime, setStatementCountParam, setStatementTypeParam]);

    const changePositionTime = (num: number) => {
        setPositionTime((value) => {
            return moment(value).add(num, 'days') <= moment()
                ? moment(value).add(num, 'days').format('yyyy-MM-DD')
                : value;
        });
    };
    const changeStructuralTime = (num: number) => {
        setStructuralTime((value) => {
            return moment(value).add(num, 'days') <= moment()
                ? moment(value).add(num, 'days').format('yyyy-MM-DD')
                : value;
        });
    };
    const changeStatementTime = (num: number) => {
        const newNumber = num > 0 ? 7 : -7;
        setStatementEndTime((value) => {
            return moment(value).add(newNumber, 'days').subtract(6, 'days') <= moment()
                ? moment(value).add(newNumber, 'days').format('yyyy-MM-DD')
                : value;
        });
    };
    return (
        <>
            <div className={styles.title}>产品数据</div>
            <Row gutter={16}>
                <Col span={8}>
                    {positionCountRes.data && positionTypeRes.data && positionTypeData && (
                        <BarChart
                            title="持仓产品"
                            time={positionTime}
                            count={positionCountRes.data[0].total_count}
                            unit="个"
                            keys={positionTypeData.keys}
                            data={positionTypeData.data}
                            onTrigger={changePositionTime}
                        />
                    )}
                </Col>
                <Col span={8}>
                    {structuralCountRes.data && structuralTypeRes.data && structuralTypeData && (
                        <BarChart
                            title="存续结构性产品"
                            time={structuralTime}
                            count={structuralCountRes.data[0].total_count}
                            unit="个"
                            keys={structuralTypeData.keys}
                            data={structuralTypeData.data}
                            onTrigger={changeStructuralTime}
                        />
                    )}
                </Col>
                <Col span={8}>
                    {statementCountRes.data && statementTypeRes.data && statementTypeData && (
                        <BarChart
                            title="已结单产品"
                            time={`${moment(statementEndTime).week()}(${moment(statementEndTime)
                                .subtract(6, 'days')
                                .format('MMDD')}-${moment(statementEndTime).format('MMDD')})`}
                            count={statementCountRes.data[0].total_count}
                            unit="个"
                            keys={statementTypeData.keys}
                            data={statementTypeData.data}
                            onTrigger={changeStatementTime}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default ProductData;
