import CustomerList from '../modules/CustomerList';
import CustomerManage from '../modules/CustomerManage';
import StructuralProductsPages from '../modules/StructuralProducts';
import NotifyCenterPages from '../modules/NotifyCenter';
import AuthorityCenterPages from '../modules/AuthorityCenter';
import AssetManagementPages from '../modules/AssetManagement';
import ShareMeetingPages from '../modules/ShareMeeting';
import Activity from '../modules/Activity';
import Trade from '../modules/Trade';
import SignPage from '../modules/Sign';
import Assets from '../modules/AssetManagement/Assets';
import IMManage from '../modules/IMManage';
import Dashboard from '../modules/DashBoard';
import Reconciliation from '../modules/Reconciliation/map';
/**
 * 菜单的路由映射，key为menu.entrance
 */
const menuMapping: Record<string, () => JSX.Element> = {
    '/app/dashboard': Dashboard,
    '/app/customer/list': CustomerList,
    '/app/customer/manage': CustomerManage,
    '/app/customer/positionEditor': Assets,
    '/app/customer/immanage': IMManage,
    '/app/customer/sign': SignPage,
    ...Trade,
    ...ShareMeetingPages,
    ...Activity,
    ...AssetManagementPages,
    ...StructuralProductsPages,
    ...NotifyCenterPages,
    ...AuthorityCenterPages,
    ...Reconciliation,
};

export default menuMapping;
