import EditProduct from '../EditProduct';
import Notice from '../Notice';
import PositionList from '../Position';
import Recommendation from '../RecommendationComponent';
// import BankFee from './BankFee';
import LTV from './LTV';

const AssetManagementPages = {
    '/app/assetManagement/editProduct': EditProduct,
    '/app/assetManagement/list': Recommendation,
    '/app/assetManagement/notice': Notice,
    '/app/assetManagement/position': PositionList,
    '/app/assetManagement/ltv': LTV,
};

export default AssetManagementPages;
