import BankFee from '../AssetManagement/NewBankFee';
import Quotation from '../Quotation';
import TranscationRecord from '../TranscationRecord';
import BondQuote from '../BondQuote';
import Order from '../Order';
import Transaction from '../Transaction';
import GKQuote from '../GKQuote';

export default {
    '/app/trade/quotation': Quotation,
    '/app/trade/order': Order,
    '/app/trade/TranscationRecord/:customer_name': TranscationRecord,
    '/app/trade/bankFee': BankFee,
    '/app/trade/BondQuote': BondQuote,
    '/app/trade/quote': GKQuote,
    '/app/trade/transaction': Transaction,
};
