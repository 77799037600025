import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.less';
import { Divider } from 'antd';
import TransactionBriefDetails from '../Transaction/components/BriefDetails';
import { getPositionDetail, getStaticPositonDetail, getValueTransaction } from './util';
import AccountTabs from './Component/AccountTabs';
import { useParams } from 'react-router-dom';
import { queryPositionDetail } from '../Position/request';
import { PositionAssetDetail } from '../Position/type';
import AssetCommonTable from '../Position/Component/CommonTable';
const PositionDetailPage = () => {
    const { account_id, isin } = useParams<{ account_id: string; isin: string }>();
    const [positionInfo, setPositonInfo] = useState<PositionAssetDetail>();
    useEffect(() => {
        queryPositionDetail({
            account_id,
            isin,
        }).then((res) => {
            setPositonInfo(res.data);
        });
    }, [account_id, isin]);
    const briefDetailData = useMemo(() => {
        if (positionInfo) {
            return getPositionDetail(positionInfo);
        }
    }, [positionInfo]);
    const staticList = useMemo(() => {
        if (!positionInfo) return [];
        return getStaticPositonDetail(positionInfo);
    }, [positionInfo]);
    return (
        <>
            <div className={styles.header}>
                <div className={styles.headerLeft}>
                    {positionInfo && (
                        <div className={styles.transType}>
                            <div>{positionInfo?.position?.asset?.name}</div>
                        </div>
                    )}
                </div>
            </div>
            <Divider />
            <div className={styles.briefDetail}>
                {positionInfo && (
                    <div className={styles.detailHeader}>
                        {positionInfo.position.asset_type.desc}
                    </div>
                )}
                {briefDetailData && <TransactionBriefDetails data={briefDetailData} />}
            </div>

            <div className={styles.briefDetail}>
                <div className={styles.detailHeader}>Statistic</div>

                {staticList.length > 0 && positionInfo?.position_statistics && (
                    <>
                        <div
                            className={styles.subHeader}
                        >{`${positionInfo?.position?.account?.bank_name}-${positionInfo?.position?.account.vendor_sub_account_id}`}</div>
                        <AssetCommonTable
                            columns={staticList}
                            dataSource={[positionInfo?.position_statistics]}
                        />
                    </>
                )}
                {positionInfo?.transaction_list && positionInfo?.transaction_list.length > 0 && (
                    <>
                        <div className={styles.subHeader}>Valued Transactions</div>
                        <AssetCommonTable
                            columns={getValueTransaction(positionInfo)}
                            dataSource={positionInfo?.transaction_list}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default PositionDetailPage;
