import React, { useCallback, useMemo, useState } from 'react';
import styles from './UserDetail.module.scss';
import { getCustomerInfo, updateCustomerInfo } from '../../../../service';
import { Button, Collapse, DatePicker, Form, Input, message, Select } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CustomerInfo } from '../../../../service/type';
import useForceUpdate from '../../../../utils/Hooks/useForceUpdate';
import { showError } from '../../../RecommendationComponent/util';
import { useForm } from 'antd/lib/form/Form';
import { Rule } from 'antd/lib/form';
import moment from 'moment';
import { EventList, uploadEventTrack } from '../../../../service/requests/EventTrack';
import VerifyModal from '../../../../components/VerifyModal';

interface RowCell {
    headerName: string;
    field?: string;
    value?: string;
    editable?: boolean;
    formComponent?: JSX.Element;
    rules?: Rule[];
}

type Row = RowCell | RowCell[];

export default function UserDetail() {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [showVerify, setShowVerify] = useState(false);

    const [form] = useForm();
    const { state } = useLocation();

    const [userInfo, setUserInfo] = useState<CustomerInfo & Record<string, any>>();
    const forceUpdate = useForceUpdate(() => {
        getCustomerInfo(Number(id)).then((res) => {
            setUserInfo(res.data);
            form.resetFields();
        }, showError);
    }, []);

    const infoModel = useMemo<Array<{ title: string; rows: Row[] }>>(
        () => [
            {
                title: '基本信息',
                rows: [
                    [
                        { headerName: 'ID', field: 'id', editable: false },
                        {
                            headerName: '昵称',
                            field: 'name',
                            rules: [{ required: true, message: '不能为空' }],
                        },
                    ],
                    [
                        { headerName: '生日', field: 'birthday', formComponent: <DatePicker /> },
                        { headerName: '性别', field: 'gender' },
                    ],
                    [
                        {
                            headerName: '手机',
                            field: 'mobile',
                            rules: [{ required: true, message: '不能为空' }],
                        },
                        {
                            headerName: '邮箱',
                            field: 'email',
                            rules: [{ required: true, message: '不能为空' }],
                        },
                    ],
                    [
                        {
                            headerName: '状态',
                            field: 'state',
                            rules: [{ required: true, message: '不能为空' }],
                            formComponent: (
                                <Select>
                                    <Select.Option value="目标用户">目标用户</Select.Option>
                                    <Select.Option value="开户中">开户中</Select.Option>
                                    <Select.Option value="入金完成">入金完成</Select.Option>
                                    <Select.Option value="其他(非客户)">其他(非客户)</Select.Option>
                                    <Select.Option value="已注销">已注销</Select.Option>
                                </Select>
                            ),
                        },
                        {
                            headerName: '来源',
                            field: 'source',
                            formComponent: (
                                <Select>
                                    <Select.Option value="朋友介绍">朋友介绍</Select.Option>
                                    <Select.Option value="市场活动">市场活动</Select.Option>
                                    <Select.Option value="自主挖掘">自主挖掘</Select.Option>
                                    <Select.Option value="其他">其他</Select.Option>
                                </Select>
                            ),
                        },
                    ],
                    [
                        {
                            headerName: '居住地址',
                            field: 'address',
                        },
                        {
                            headerName: '邀请人手机号',
                            field: 'inviterMobile',
                            editable: false,
                        },
                    ],
                    {
                        headerName: '所属RM',
                        editable: false,
                        value: userInfo?.rms.map((item) => item.name).join('，'),
                    },
                    {
                        headerName: '新用户roadmap URL',
                        field: 'roadmapUrl',
                    },
                ],
            },
            {
                title: '业务信息',
                rows: [
                    [
                        {
                            headerName: '姓名',
                            field: 'formalName',
                        },
                        { headerName: '国籍', field: 'nationality' },
                    ],
                    { headerName: '护照号码', field: 'passportNumber' },
                    [
                        {
                            headerName: '护照到期日',
                            field: 'passportExpiryDate',
                            formComponent: <DatePicker />,
                        },
                        { headerName: '护照颁发国', field: 'passportIssuingCountry' },
                    ],
                    { headerName: '邮寄地址', field: 'mailingAddress' },
                    [
                        { headerName: '婚姻状况', field: 'maritalStatus' },
                        { headerName: '家庭成员', field: 'familyMembers' },
                    ],
                    [
                        { headerName: '使用的其他机构', field: 'otherProviders' },
                        { headerName: '其他ID', field: 'otherIdents' },
                    ],
                    { headerName: '最高学历', field: 'highestEducation' },
                    {
                        headerName: '工作经历',
                        field: 'workHistory',
                        formComponent: <Input.TextArea />,
                    },
                ],
            },
        ],
        [userInfo]
    );

    const [editing, setEditing] = useState(false);

    const handleFormFinish = useCallback(
        (values) => {
            updateCustomerInfo({
                id: userInfo?.id,
                ...values,
                birthday: values.birthday?.format('YYYY-MM-DD'),
                passportExpiryDate: values.passportExpiryDate?.format('YYYY-MM-DD'),
            }).then(() => {
                message.success('更新成功');
                if (values.state === '已注销') {
                    history.push({ pathname: `/app/customer/list`, state: state });
                } else {
                    forceUpdate();
                    setEditing(false);
                }
            }, showError);
        },
        [forceUpdate, userInfo, history, state]
    );

    return (
        <div id="CustomerListDetail" className={styles.container}>
            <VerifyModal
                visible={showVerify}
                setVisible={setShowVerify}
                containerId="CustomerListDetail"
                pageId="CustomerList"
            />
            {editing ? (
                <div style={{ display: 'flex', columnGap: '8px', marginBottom: '16px' }}>
                    <Button onClick={() => setEditing(false)}>取消</Button>
                    <Button type="primary" onClick={() => form.submit()}>
                        提交
                    </Button>
                </div>
            ) : (
                <>
                    <Button
                        type="primary"
                        style={{ marginBottom: '16px' }}
                        onClick={() => {
                            history.push({ pathname: `/app/customer/list`, state: state });
                        }}
                    >
                        返回
                    </Button>
                    <Button
                        type="primary"
                        style={{ marginBottom: '16px' }}
                        onClick={() => {
                            uploadEventTrack(EventList.CustomerDetailClickEdit, 2);
                            setEditing(true);
                        }}
                    >
                        编辑
                    </Button>
                </>
            )}
            <Form
                form={form}
                initialValues={{
                    ...userInfo,
                    birthday: userInfo?.birthday && moment(userInfo.birthday, 'YYYY-MM-DD'),
                    passportExpiryDate:
                        userInfo?.passportExpiryDate &&
                        moment(userInfo.passportExpiryDate, 'YYYY-MM-DD'),
                }}
                onFinish={handleFormFinish}
            >
                <Collapse
                    defaultActiveKey={[...Array(infoModel.length).keys()].map((item) =>
                        String(item)
                    )}
                >
                    {infoModel.map((model, index) => (
                        <Collapse.Panel header={model.title} key={String(index)}>
                            <table>
                                <tbody>
                                    {model.rows.map((row, index) =>
                                        Array.isArray(row) ? (
                                            <tr key={index}>
                                                {row.map((cell, index) => (
                                                    <React.Fragment key={index}>
                                                        <th>
                                                            {cell.rules && editing && (
                                                                <span
                                                                    style={{
                                                                        color: 'red',
                                                                        marginRight: '10px',
                                                                    }}
                                                                >
                                                                    *
                                                                </span>
                                                            )}
                                                            {cell.headerName}
                                                        </th>
                                                        <td>
                                                            <div
                                                                className={`${
                                                                    styles['cell-wrapper']
                                                                } ${
                                                                    editing
                                                                        ? styles[
                                                                              'cell-wrapper-editing'
                                                                          ]
                                                                        : ''
                                                                }`}
                                                            >
                                                                {editing &&
                                                                cell.editable !== false ? (
                                                                    <Form.Item
                                                                        name={cell.field}
                                                                        rules={cell.rules}
                                                                        className={
                                                                            styles['form-item']
                                                                        }
                                                                    >
                                                                        {cell.formComponent ?? (
                                                                            <Input />
                                                                        )}
                                                                    </Form.Item>
                                                                ) : (
                                                                    <span>
                                                                        {
                                                                            userInfo?.[
                                                                                cell.field ?? ''
                                                                            ]
                                                                        }
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </React.Fragment>
                                                ))}
                                            </tr>
                                        ) : (
                                            <tr key={index}>
                                                <th>
                                                    {row.rules && editing && (
                                                        <span
                                                            style={{
                                                                color: 'red',
                                                                marginRight: '10px',
                                                            }}
                                                        >
                                                            *
                                                        </span>
                                                    )}
                                                    {row.headerName}
                                                </th>
                                                <td colSpan={3}>
                                                    <div
                                                        className={`${styles['cell-wrapper']} ${
                                                            editing
                                                                ? styles['cell-wrapper-editing']
                                                                : ''
                                                        }`}
                                                    >
                                                        {editing && row.editable !== false ? (
                                                            <Form.Item
                                                                name={row.field}
                                                                rules={row.rules}
                                                                className={styles['form-item']}
                                                            >
                                                                {row.formComponent ?? <Input />}
                                                            </Form.Item>
                                                        ) : (
                                                            <span>
                                                                {row.value ??
                                                                    userInfo?.[row.field ?? '']}
                                                            </span>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </Collapse.Panel>
                    ))}
                </Collapse>
            </Form>
        </div>
    );
}
