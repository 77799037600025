import { Button, Form, InputNumber, message } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useCallback } from 'react';
import { updateAssetAllocplanPosition } from '../../../service';
import { showError } from '../../RecommendationComponent/util';

const EditProductionModal = ({
    setRefreshList,
    position_id,
    setEditingProduction,
}: {
    setRefreshList: () => void;
    position_id: string | null;
    setEditingProduction: (production: null) => void;
}) => {
    const onFinish = useCallback(
        (values: { hold_value: number }) => {
            if (position_id) {
                updateAssetAllocplanPosition({
                    position_id,
                    ...values,
                }).then(() => {
                    setEditingProduction(null);
                    message.success('修改成功');
                    setRefreshList();
                }, showError);
            }
        },
        [position_id, setEditingProduction, setRefreshList]
    );
    return (
        <Modal
            title={`编辑产品`}
            destroyOnClose
            footer={null}
            visible={!!position_id}
            okText="确认"
            cancelText="取消"
            onCancel={() => {
                setEditingProduction(null);
            }}
            centered
            width={700}
        >
            <Form
                name="EditProduction"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
            >
                <Form.Item
                    label="市值"
                    name="hold_value"
                    rules={[{ required: true, message: '请输入验证码' }]}
                >
                    <InputNumber
                        style={{
                            width: '100%',
                        }}
                    />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditProductionModal;
