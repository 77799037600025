import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.module.less';
import { Button, Divider, Modal, message } from 'antd';
import { BankFlow, ReconciliationDetail, SystemFlow, VerifiedStatus } from '../type';
import BankAndSystemFlow from '../component/BankAndSystem';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import BankFlowTable from './components/BankFlow';
import {
    getReconciliationDetail,
    saveReconciliationDetail,
    unverifyReconciliation,
    verifyReconciliation,
} from '../request';
import moment from 'moment';
import { FormatNumber } from '../../../utils';
import { showError } from '../../RecommendationComponent/util';

const ReconciliationDetailPage = () => {
    const [verified, setVerified] = useState(false);
    const [detail, setDetail] = useState<ReconciliationDetail>();
    const [bankFlows, setBankFlows] = useState<BankFlow[]>([]);
    const location = useLocation();
    const { id } = qs.parse(location.search) as {
        id: string;
    };
    const summaryData = useMemo(() => {
        if (detail) {
            return [
                {
                    title: 'Account',
                    value: `${detail.account.bank_name}-${detail.account.vendor_sub_account_id}`,
                },
                {
                    title: 'Status',
                    value: detail.status === VerifiedStatus.Verified ? 'Verified' : 'Unverified',
                },
                {
                    title: 'Statement Date',
                    value: moment(detail.statement_date).format('yyyy-MM-DD'),
                },
                {
                    title: 'Prev Date',
                    value: moment(detail.prev_statement_date).format('yyyy-MM-DD'),
                },
                { title: 'Statement Position', value: FormatNumber(detail.bank_position, 2) },
                { title: 'Prev Position', value: FormatNumber(detail.prev_bank_position, 2) },
                {
                    title: 'Bank Position Change',
                    value: FormatNumber(Math.round(detail.bank_position_change * 100) / 100, 2),
                },
                {
                    title: 'Bank Flow Verified Amount',
                    value: FormatNumber(
                        Math.round(
                            bankFlows.reduce((pre, cur) => {
                                return pre + (cur.verified_amount || 0);
                            }, 0) * 100
                        ) / 100,
                        2
                    ),
                },
                {
                    title: 'System Presume Amount',
                    value: FormatNumber(
                        Math.round(
                            bankFlows.reduce((pre, cur) => {
                                return pre + (cur.flow?.presume_amount || 0);
                            }, 0) * 100
                        ) / 100,
                        2
                    ),
                },
            ];
        }
    }, [detail, bankFlows]);
    const isEqual = useMemo(() => {
        if (detail) {
            return (
                Math.round(detail.bank_position_change * 100) / 100 ===
                    Math.round(
                        bankFlows.reduce((pre, cur) => {
                            return pre + (cur.verified_amount || 0);
                        }, 0) * 100
                    ) /
                        100 &&
                Math.round(detail.bank_position_change * 100) / 100 ===
                    Math.round(
                        bankFlows.reduce((pre, cur) => {
                            return pre + (cur.flow?.presume_amount || 0);
                        }, 0) * 100
                    ) /
                        100
            );
        }
    }, [detail, bankFlows]);
    useEffect(() => {
        getReconciliationDetail({ reconciliation_id: parseInt(id) }).then((res) => {
            setDetail(res.data.reconciliation);
        });
    }, [id]);
    useEffect(() => {
        if (detail) {
            setBankFlows(
                detail.reconciliation_flows.map((f) => ({
                    ...f.bank_flow,
                    flow: f.flow,
                }))
            );
            setVerified(detail.status === VerifiedStatus.Verified);
        }
    }, [detail]);

    const getCurDetail = () => {
        const originReconciliationFlowsId = detail!.reconciliation_flows.map((f) => f.bank_flow_id);
        const reconciliationFlows = bankFlows.map((bf) => {
            if (originReconciliationFlowsId.includes(bf.id)) {
                return {
                    ...detail!.reconciliation_flows.find((rf) => rf.bank_flow_id === bf.id),
                    bank_flow: {
                        ...bf,
                        flow: undefined,
                    },
                    flow: bf.flow,
                };
            } else {
                return {
                    bank_flow: {
                        ...bf,
                        flow: undefined,
                    },
                    flow: bf.flow,
                };
            }
        });
        return {
            ...detail,
            reconciliation_flows: reconciliationFlows,
        };
    };
    const onSaveClick = () => {
        const newDetail = getCurDetail();
        saveReconciliationDetail({
            reconciliation: newDetail as ReconciliationDetail,
        }).then(
            (res) => {
                setDetail(res.data.reconciliation);
            },
            (err) => {
                if (err.code === 107004) {
                    message.error('This transaction has been verified, please check again.');
                }
            }
        );
    };
    const onVerifiedClick = () => {
        const verify = () => {
            const newDetail = getCurDetail();
            verifyReconciliation({
                reconciliation: newDetail as ReconciliationDetail,
            }).then(
                (res) => {
                    setDetail(res.data.reconciliation);
                },
                (err) => {
                    const renderBankFlow = (flows: BankFlow[]) => {
                        let ret = '';
                        flows.forEach((f) => {
                            if (f) {
                                ret += '\n';
                                ret += f.statement_date
                                    ? moment(f.statement_date).format('yyyy-MM-DD')
                                    : '-';
                                ret +=
                                    ',' +
                                    (f.presume_amount ? FormatNumber(f.presume_amount, 2) : '-');
                                ret += ',' + f.description;
                            }
                        });
                        return ret;
                    };
                    const renderSystemFlow = (flows: SystemFlow[]) => {
                        let ret = '';
                        flows.forEach((f) => {
                            if (f) {
                                ret += '\n';
                                ret += f.transaction?.value_date
                                    ? moment(f.transaction?.value_date).format('yyyy-MM-DD')
                                    : '-';
                                ret +=
                                    ',' +
                                    (f.presume_amount ? FormatNumber(f.presume_amount, 2) : '-');
                                ret += ',' + (f.transaction?.type?.desc || '-');
                            }
                        });
                        return ret;
                    };
                    if (err.code === 107004) {
                        message.error('This transaction has been verified, please check again.');
                    }
                    if (err.code === 107005) {
                        Modal.info({
                            title: `${err.msg}${renderBankFlow(err.data)}`,
                            icon: false,
                            width: 600,
                            onOk: () => {
                                getReconciliationDetail({ reconciliation_id: parseInt(id) }).then(
                                    (res) => {
                                        setDetail(res.data.reconciliation);
                                    }
                                );
                            },
                        });
                    }
                    if (err.code === 107006) {
                        Modal.info({
                            title: `${err.msg}${renderSystemFlow(err.data)}`,
                            icon: false,
                            width: 600,
                            onOk: () => {
                                getReconciliationDetail({
                                    reconciliation_id: parseInt(id),
                                }).then((res) => {
                                    setDetail(res.data.reconciliation);
                                });
                            },
                        });
                    }
                }
            );
        };
        if (isEqual) {
            verify();
        } else {
            Modal.confirm({
                title:
                    'Attention! The bank statement and the Ginkgo system flow do not fully match. Do you want to force a verification？',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    verify();
                },
            });
        }
    };
    const onUnverifiedClick = () => {
        unverifyReconciliation({ reconciliation_id: parseInt(id) }).then((res) => {
            getReconciliationDetail({ reconciliation_id: parseInt(id) }).then((res) => {
                setDetail(res.data.reconciliation);
            });
        }, showError);
    };
    return (
        <>
            {detail && (
                <>
                    <div className={styles.summary}>
                        <div className={styles.summaryHead}>
                            <div className={styles.asset}>{detail.asset_name}</div>
                            <div className={styles.client}>{detail.account.account_name}</div>
                        </div>
                        <Divider />
                        {summaryData && (
                            <div className={styles.summaryContent}>
                                {summaryData.map((i) => (
                                    <div key={i.title} className={styles.summaryItem}>
                                        <div className={styles.title}>{i.title}</div>
                                        <div className={styles.value}>{i.value}</div>
                                    </div>
                                ))}
                            </div>
                        )}

                        <div>
                            {verified ? (
                                <Button onClick={onUnverifiedClick}>Unverified</Button>
                            ) : (
                                <>
                                    <Button onClick={onVerifiedClick}>Verified</Button>
                                    <Button onClick={onSaveClick}>Save</Button>
                                </>
                            )}
                        </div>
                    </div>
                    <div className={styles.bankFlow}>
                        <div className={styles.title}>Bank Flow</div>
                        <BankFlowTable
                            account={detail.account}
                            bankFlows={bankFlows}
                            setBankFlows={setBankFlows}
                            verified={verified}
                            assetType={detail.asset_type.code}
                            assetName={detail.asset_name}
                            currency={detail.currency}
                            isin={detail.asset?.isin || ''}
                        />
                    </div>
                    <div className={styles.matchFlow}>
                        <div className={styles.title}>Bank Flow & System Flow</div>
                        <BankAndSystemFlow
                            data={detail}
                            bankFlows={bankFlows}
                            setBankFlows={setBankFlows}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default ReconciliationDetailPage;
