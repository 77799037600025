import { Tooltip } from 'antd';
import React from 'react';
import style from './index.module.scss';
type RMAvatarProp = {
    data: {
        name: string;
        faceUrl: string;
    };
    showName?: boolean;
};
const RMAvatar = ({ data, showName = true }: RMAvatarProp) => {
    return (
        <div className={style.content}>
            <Tooltip title={data.name}>
                {data.faceUrl && <img src={data.faceUrl} alt="" className={style.avatar} />}
            </Tooltip>
            {showName && <div className={style.text}>{data.name}</div>}
        </div>
    );
};
export default RMAvatar;
