import React, { useRef } from 'react';
import { Button, Input, message } from 'antd';
import styles from './index.module.scss';
import copy from 'copy-to-clipboard';
import { EventList, uploadEventTrack } from '../../service/requests/EventTrack';
import { TextAreaRef } from 'antd/lib/input/TextArea';

interface EmailTemplateProps {
    to: string;
    cc: string;
    subject: string;
    body: string;
    onSentClick?: () => void;
}
const EmailTemplate = (props: EmailTemplateProps) => {
    const { to, cc, subject, body, onSentClick } = props;
    const bodyRef = useRef<TextAreaRef>(null);
    const copyText = (text: string) => {
        if (
            copy(text, {
                format: 'text/plain',
            })
        ) {
            message.success('复制成功');
        } else {
            message.error('复制失败');
        }
    };
    const onOpenClick = () => {
        uploadEventTrack(EventList.OrderEmailAction, 2, {
            action_type: 'open_client',
        });
        const bodyWithLineFeed = bodyRef.current?.resizableTextArea?.textArea.value.replace(
            /\n/g,
            '%0D%0A'
        );
        window.open(`mailto:${to}?cc=${cc}&subject=${subject}&body=${bodyWithLineFeed}`);
    };
    return (
        <div className={styles.email}>
            <div className={styles.emailContent}>
                <div className={styles.emailField}>
                    <span className={styles.fieldName}>收件人:</span>
                    <span className={styles.fieldValue}>{to}</span>
                    <Button
                        className={styles.fieldBtn}
                        type="primary"
                        onClick={() => {
                            uploadEventTrack(EventList.OrderEmailCopy, 2, {
                                field: 'to',
                            });
                            copyText(to);
                        }}
                    >
                        复制
                    </Button>
                </div>
                <div className={styles.emailField}>
                    <span className={styles.fieldName}>抄送:</span>
                    <span className={styles.fieldValue}>{cc}</span>
                    <Button
                        className={styles.fieldBtn}
                        type="primary"
                        onClick={() => {
                            uploadEventTrack(EventList.OrderEmailCopy, 2, {
                                field: 'cc',
                            });
                            copyText(cc);
                        }}
                    >
                        复制
                    </Button>
                </div>
                <div className={styles.emailField}>
                    <span className={styles.fieldName}>主题:</span>
                    <span className={styles.fieldValue}>{subject}</span>
                    <Button
                        className={styles.fieldBtn}
                        type="primary"
                        onClick={() => {
                            uploadEventTrack(EventList.OrderEmailCopy, 2, {
                                field: 'subject',
                            });
                            copyText(subject);
                        }}
                    >
                        复制
                    </Button>
                </div>
                <div className={styles.emailField}>
                    <Input.TextArea
                        ref={bodyRef}
                        rows={15}
                        className={styles.fieldValue}
                        defaultValue={body}
                    />
                    <Button
                        className={styles.fieldBtn}
                        type="primary"
                        onClick={() => {
                            uploadEventTrack(EventList.OrderEmailCopy, 2, {
                                field: 'body',
                            });
                            copyText(bodyRef.current?.resizableTextArea?.textArea.value || '');
                        }}
                    >
                        复制
                    </Button>
                </div>
            </div>
            <div className={styles.btn}>
                <Button onClick={onOpenClick}>打开邮件客户端</Button>
                {onSentClick && (
                    <Button type="primary" onClick={onSentClick}>
                        完成邮件发送
                    </Button>
                )}
            </div>
        </div>
    );
};

export default EmailTemplate;
