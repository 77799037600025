import React from 'react';
import styles from './index.module.less';

interface TransactionDetailDateInfoProps {
    data: { title: string; value: string }[];
}
const TransactionDetailDateInfo = (props: TransactionDetailDateInfoProps) => {
    const { data } = props;
    return (
        <div className={styles.detailDates}>
            {data.map((i) => (
                <div key={i.title} className={styles.detailDateItems}>
                    <div className={styles.title}>{i.title}</div>
                    <div className={styles.value}>{i.value}</div>
                </div>
            ))}
        </div>
    );
};

export default TransactionDetailDateInfo;
