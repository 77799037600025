import { TableColumnType } from 'antd';
import { BondQuoteEntity, BondType } from '../Quotation/request';
export enum BondQuoteTableColumnType {
    All = 'all',
    Select = 'select',
}
export const getColumns = (
    bondType: BondType,
    type: BondQuoteTableColumnType
): TableColumnType<BondQuoteEntity>[] => {
    if (type === BondQuoteTableColumnType.All) {
        if (bondType === BondType.corporate) {
            return [
                {
                    title: 'ISIN',
                    dataIndex: 'isin',
                    width: 150,
                },
                {
                    title: '信用评级 - S&P',
                    dataIndex: 'credit_rating_sp',
                    width: 150,
                },
                {
                    title: '发行方-英',
                    dataIndex: 'issuer_en',
                    width: 150,
                },
                {
                    title: '发行方-中',
                    dataIndex: 'issuer_cn',
                    width: 150,
                },
                {
                    title: '价格%',
                    dataIndex: 'ask_price',
                    width: 150,
                },
                {
                    title: '含息价格%',
                    dataIndex: 'ask_price_plus_accrued_interest',
                    width: 150,
                },
                {
                    title: '最差收益率%',
                    dataIndex: 'YTW',
                    width: 150,
                },
                {
                    title: '到期收益率%',
                    dataIndex: 'YTM',
                    width: 150,
                },
                {
                    title: '年化票息%',
                    dataIndex: 'coupon',
                    width: 150,
                },
                {
                    title: '久期',
                    dataIndex: 'duration',
                    width: 150,
                },
                {
                    title: '优先级',
                    dataIndex: 'seniority',
                    width: 150,
                },
                {
                    title: '下一赎回日',
                    dataIndex: 'next_call_date',
                    width: 150,
                },
                {
                    title: '到期日',
                    dataIndex: 'maturity_date',
                    width: 150,
                },
                {
                    title: '到期时间',
                    dataIndex: 'residual_maturity',
                    width: 150,
                },
            ];
        } else {
            return [
                {
                    title: 'ISIN',
                    dataIndex: 'isin',
                    width: 150,
                },
                {
                    title: '价格%',
                    dataIndex: 'ask_price',
                    width: 150,
                },
                {
                    title: '含息价格%',
                    dataIndex: 'ask_price_plus_accrued_interest',
                    width: 150,
                },
                {
                    title: '到期收益率%',
                    dataIndex: 'YTM',
                    width: 150,
                },
                {
                    title: '年化票息%',
                    dataIndex: 'coupon',
                    width: 150,
                },
                {
                    title: '久期',
                    dataIndex: 'duration',
                    width: 150,
                },
                {
                    title: '到期日',
                    dataIndex: 'maturity_date',
                    width: 150,
                },
                {
                    title: '到期时间',
                    dataIndex: 'residual_maturity',
                    width: 150,
                },
            ];
        }
    } else {
        if (bondType === BondType.corporate) {
            return [
                {
                    title: '价格%',
                    dataIndex: 'ask_price',
                    width: 150,
                },
                {
                    title: '含息价格%',
                    dataIndex: 'ask_price_plus_accrued_interest',
                    width: 150,
                },
                {
                    title: '最差收益率%',
                    dataIndex: 'YTW',
                    width: 150,
                },
                {
                    title: '到期收益率%',
                    dataIndex: 'YTM',
                    width: 150,
                },
                {
                    title: '年化票息%',
                    dataIndex: 'coupon',
                    width: 150,
                },
                {
                    title: 'ISIN',
                    dataIndex: 'isin',
                    width: 150,
                },
                {
                    title: '信用评级 - S&P',
                    dataIndex: 'credit_rating_sp',
                    width: 150,
                },
                {
                    title: '发行方-英',
                    dataIndex: 'issuer_en',
                    width: 150,
                },
                {
                    title: '发行方-中',
                    dataIndex: 'issuer_cn',
                    width: 150,
                },

                {
                    title: '久期',
                    dataIndex: 'duration',
                    width: 150,
                },
                {
                    title: '优先级',
                    dataIndex: 'seniority',
                    width: 150,
                },
                {
                    title: '下一赎回日',
                    dataIndex: 'next_call_date',
                    width: 150,
                },
                {
                    title: '到期日',
                    dataIndex: 'maturity_date',
                    width: 150,
                },
                {
                    title: '到期时间',
                    dataIndex: 'residual_maturity',
                    width: 150,
                },
            ];
        } else {
            return [
                {
                    title: '价格%',
                    dataIndex: 'ask_price',
                    width: 150,
                },
                {
                    title: '含息价格%',
                    dataIndex: 'ask_price_plus_accrued_interest',
                    width: 150,
                },
                {
                    title: '到期收益率%',
                    dataIndex: 'YTM',
                    width: 150,
                },
                {
                    title: 'ISIN',
                    dataIndex: 'isin',
                    width: 150,
                },
                {
                    title: '年化票息%',
                    dataIndex: 'coupon',
                    width: 150,
                },
                {
                    title: '久期',
                    dataIndex: 'duration',
                    width: 150,
                },
                {
                    title: '到期日',
                    dataIndex: 'maturity_date',
                    width: 150,
                },
                {
                    title: '到期时间',
                    dataIndex: 'residual_maturity',
                    width: 150,
                },
            ];
        }
    }
};
