import React, { useCallback, useEffect, useState } from 'react';
import PanelComponent from './Panel';
import './index.less';
import { queryAssetAllocPlan } from '../../../service';
import { showError } from '../../RecommendationComponent/util';
import { AssetAllocPlan } from '../../../service/type';
import EditProductionModal from './EditProductionModal';

const AssetAllocation: React.FC<{
    uid: string;
    copy_id: number;
}> = ({ copy_id, uid }) => {
    const [refreshList, setRefreshList] = useState(0);
    const [plan, setPlan] = useState<AssetAllocPlan | null>(null);
    const [editingProduction, setEditingProduction] = useState<null | string>(null);
    const handleRefreshList = useCallback(() => setRefreshList((num) => num + 1), []);
    useEffect(() => {
        queryAssetAllocPlan({ uid, copy_id }).then((res) => setPlan(res.data), showError);
    }, [copy_id, refreshList, uid]);
    return (
        <>
            <EditProductionModal
                setRefreshList={handleRefreshList}
                position_id={editingProduction}
                setEditingProduction={setEditingProduction}
            />
            {plan?.packages.map((packageValue) => (
                <PanelComponent
                    setEditingProduction={setEditingProduction}
                    setRefreshList={handleRefreshList}
                    packageValue={packageValue}
                    key={packageValue.pkg_id}
                />
            ))}
        </>
    );
};

export default AssetAllocation;
