export default {
    BondQuote_AddBtn_Title: ['New Order', '新增订单'],
    BondQuote_QuoteTime: ['Quote Time', '报价时间'],
    BondQuote_QuoteUser: ['报价人', '报价人'],
    BondQuote_CustomerId_Name: ['客户id-客户昵称', '客户id-客户昵称'],
    BondQuote_BankAccount: ['银行账号', '银行账号'],
    BondQuote_ProductType: ['产品类型', '产品类型'],
    BondQuote_BondType: ['债券类型', '债券类型'],
    BondQuote_QuoteStatus: ['报价状态', '报价状态'],
    TotalPage: ['总条数', '总条数'],
    AddBond: ['新建报价', '新建报价'],
    National_Debt: ['国债', '国债'],
    Corporate_Bond: ['公司债', '公司债'],
    Confirm: ['确定', '确定'],
    Cancel: ['取消', '取消'],
    BondDetail_AccountInfo: ['账户信息', '账户信息'],
    BondDetail_QuoteDetail: ['报价详情', '报价详情'],
    BondDetail_TipTitle: [
        '您可以调整下方列表选中产品，重新计算组合收益',
        '您可以调整下方列表选中产品，重新计算组合收益',
    ],
    WeightedAverage_AnnualizedCouponRate: ['加权平均年化票率', '加权平均年化票率'],
    WeightedAverage_WorstYield: ['加权平均最差收益率', '加权平均最差收益率'],
    WeightedAverage_WorstYieldToMaturity: ['加权平均到期收益率', '加权平均到期收益率'],
    Total_NominalAmount: ['合计名义金额', '合计名义金额'],
    WeightedAverage_Duration: ['加权平均久期', '加权平均久期'],
    Total_DeliveryAmount: ['合计交割金额', '合计交割金额'],
    BondDetail_BondDetail: ['报价详情', '报价详情'],
    loading: ['加载中...', '加载中...'],
    price_up: ['价格上涨', '价格上涨'],
    BondQuote_Corporate: ['公司债', '公司债'],
    BondQuote_Government: ['国债', '国债'],
    BondQuote_Screening: ['筛选中', '筛选中'],
    BondQuote_Communicating: ['与客户沟通中', '与客户沟通中'],
    BondQuote_Canceled: ['已撤销', '已撤销'],
    BondQuote_Confirmed: ['客户已确认', '客户已确认'],
};
