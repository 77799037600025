import { POSITION_REFRESH, POSITION_TABINDEX } from '../../constants/constants';

export function setRefresh(state: { index: string; refresh: number }) {
    return {
        type: POSITION_REFRESH,
        state: { ...state },
    };
}
export function setTabIndex(tabIndex: string) {
    return {
        type: POSITION_TABINDEX,
        tabIndex,
    };
}
