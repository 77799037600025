import React, { forwardRef, useEffect } from 'react';
import { Asset, AssetAQDQExtInfo, AssetType } from '../../../Transaction/type';
import { Form, FormInstance, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import SearchUnderlying from '../../../Transaction/components/TransForm/Forms/FormItems/SearchUnderlying';
import BankItem from '../../../Transaction/components/TransForm/Forms/FormItems/BankItem';
import {
    fcnCouponFrequencyOptions,
    guaranteedPeriodOptions,
    observationFrequencyOptions,
    tenorOptions,
} from '../data';
import {
    GinkgoInput,
    GinkgoInputNumber,
} from '../../../Transaction/components/TransForm/Forms/FormItems/GinkgoFieldItems';
import { math } from '../../../../utils';
import moment from 'moment';

interface FCNFormProps {
    type: AssetType;
    asset?: Asset;
}

const FCNForm = forwardRef<FormInstance, FCNFormProps>((props, ref) => {
    const { asset } = props;
    const [form] = useForm();
    const formItemStyle: React.CSSProperties = {
        width: 'calc(62.5% - 8px)',
    };

    useEffect(() => {
        if (asset) {
            const extInfo = asset.ext_info as AssetAQDQExtInfo;
            form.setFieldsValue({
                ext_info: extInfo,
            });
        }
    }, [asset, form]);
    const onValuesChange = (changedValues: Record<string, any>, allValues: any) => {
        let changeKey = Object.keys(changedValues)[0];
        let newValues = { ...allValues };
        if (changeKey === 'ext_info') {
            changeKey = Object.keys(changedValues[changeKey])[0];
            if (changeKey === 'knock_out_price_rate') {
                if (
                    changedValues.ext_info.knock_out_price_rate &&
                    allValues.ext_info.underlying_open_price
                ) {
                    newValues.ext_info.knock_out_price = math.format(
                        math.evaluate(
                            `${allValues.ext_info.underlying_open_price} / 100 * ${changedValues.ext_info.knock_out_price_rate}`
                        ),
                        { notation: 'fixed', precision: 3 }
                    );
                } else {
                    newValues.ext_info.knock_out_price = undefined;
                }
            }
            if (changeKey === 'strike_price_rate') {
                if (
                    changedValues.ext_info.strike_price_rate &&
                    allValues.ext_info.underlying_open_price
                ) {
                    newValues.ext_info.strike_price = math.format(
                        math.evaluate(
                            `${allValues.ext_info.underlying_open_price} / 100 * ${changedValues.ext_info.strike_price_rate}`
                        ),
                        { notation: 'fixed', precision: 3 }
                    );
                } else {
                    newValues.ext_info.strike_price = undefined;
                }
            }
        }
        form.setFieldsValue(newValues);
    };
    const onPriceChange = (price: Asset | undefined) => {
        let newValues = {
            ext_info: {
                ...form.getFieldValue('ext_info'),
                underlying_open_price: price?.ext_info.open_price || undefined,
                underlying_name: price?.name || undefined,
                underlying_spec_name: price?.spec_name || undefined,
                underlyings: price?.isin ? [price.isin] : undefined,
            },
        };
        // refresh knock_out_price and strike_price.
        if (newValues.ext_info.knock_out_price_rate) {
            newValues.ext_info.knock_out_price = math.format(
                math.evaluate(
                    `${newValues.ext_info.underlying_open_price} / 100 * ${newValues.ext_info.knock_out_price_rate}`
                ),
                { notation: 'fixed', precision: 3 }
            );
        }
        if (newValues.ext_info.strike_price_rate) {
            newValues.ext_info.strike_price = math.format(
                math.evaluate(
                    `${newValues.ext_info.underlying_open_price} / 100 * ${newValues.ext_info.strike_price_rate}`
                ),
                { notation: 'fixed', precision: 3 }
            );
        }
        form.setFieldsValue(newValues);
    };
    return (
        <>
            <Form
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 17, offset: 1 }}
                ref={ref}
                onValuesChange={onValuesChange}
            >
                <SearchUnderlying
                    withCurrency={false}
                    form={form}
                    needPrice
                    isin={
                        asset?.ext_info.underlyings && asset?.ext_info.underlyings.length > 0
                            ? asset.ext_info.underlyings[0]
                            : undefined
                    }
                    required
                    width="calc(62.5% - 8px)"
                    currentPrice={onPriceChange}
                />
                <Form.Item
                    name={['ext_info', 'bank_id']}
                    rules={[{ required: true, message: 'Bank is required.' }]}
                    label="Bank"
                >
                    <BankItem />
                </Form.Item>
                <Form.Item
                    name={['ext_info', 'annualized_coupon']}
                    rules={[{ required: true, message: 'Annualized Coupon is required.' }]}
                    label="Annualized Coupon"
                >
                    <GinkgoInputNumber style={formItemStyle} addonAfter="%" />
                </Form.Item>
                <Form.Item
                    name={['ext_info', 'guaranteed_period']}
                    rules={[{ required: true, message: 'Guaranteed Period is required.' }]}
                    label="Guaranteed Period"
                >
                    <Select options={guaranteedPeriodOptions} style={formItemStyle} />
                </Form.Item>
                <Form.Item
                    name={['ext_info', 'tenor']}
                    rules={[{ required: true, message: 'Tenor is required.' }]}
                    label="Tenor"
                >
                    <Select
                        options={tenorOptions.concat([{ value: '24M', label: '24M' }])}
                        style={formItemStyle}
                    />
                </Form.Item>
                <Form.Item name={['ext_info', 'underlying_open_price']} label="Current Stock Price">
                    <GinkgoInput readOnly />
                </Form.Item>
                <Form.Item
                    name={['ext_info', 'knock_out_price_rate']}
                    rules={[{ required: true, message: 'Knock out is required.' }]}
                    label="Knock out"
                >
                    <GinkgoInputNumber style={formItemStyle} addonAfter="%" />
                </Form.Item>
                <Form.Item name={['ext_info', 'knock_out_price']} label="Knock out$">
                    <GinkgoInputNumber style={formItemStyle} readOnly />
                </Form.Item>
                <Form.Item
                    name={['ext_info', 'strike_price_rate']}
                    rules={[{ required: true, message: 'Strike is required.' }]}
                    label="Strike"
                >
                    <GinkgoInputNumber style={formItemStyle} addonAfter="%" />
                </Form.Item>
                <Form.Item name={['ext_info', 'strike_price']} label="Strike$">
                    <GinkgoInputNumber style={formItemStyle} readOnly />
                </Form.Item>

                <Form.Item
                    name={['ext_info', 'coupon_frequency']}
                    rules={[{ required: true, message: 'Coupon Frequency is required.' }]}
                    label="Coupon Frequency"
                >
                    <Select options={fcnCouponFrequencyOptions} style={formItemStyle} />
                </Form.Item>

                <Form.Item
                    name={['ext_info', 'issuer']}
                    rules={[{ required: true, message: 'Issuer is required.' }]}
                    label="Issuer"
                >
                    <GinkgoInput style={formItemStyle} />
                </Form.Item>
                <Form.Item
                    name={['ext_info', 'observation_frequency']}
                    rules={[{ required: true, message: 'Observation Frequency is required.' }]}
                    label="Observation Frequency"
                >
                    <Select options={observationFrequencyOptions} style={formItemStyle} />
                </Form.Item>
                {/* hidden item */}
                <Form.Item
                    name={['ext_info', 'underlying_name']}
                    label="underlying_name"
                    hidden
                ></Form.Item>
                <Form.Item
                    name={['ext_info', 'underlying_spec_name']}
                    label="underlying_spec_name"
                    hidden
                ></Form.Item>
                <Form.Item
                    name={['ext_info', 'underlyings']}
                    label="underlyings"
                    hidden
                ></Form.Item>
                {asset?.updated_at && (
                    <Form.Item name={['ext_info', 'underlying_open_price']} label="Updated Time">
                        {moment(asset.updated_at).format('YYYY-MM-DD HH:mm:ss')}
                    </Form.Item>
                )}
            </Form>
        </>
    );
});

export default FCNForm;
