import { Button, Divider, Modal, Pagination, Spin, Table } from 'antd';
import React, { useMemo, useState } from 'react';
import styles from './index.module.scss';
import AddTrans from './components/AddTrans';
import EmailModal from './components/EmailModal';
import {
    AccountManageType,
    TransStatus,
    TransTransferExtInfo,
    TransactionInfo,
    TransactionType,
} from './type';
import { useRequest } from 'ahooks';
import { transactionList } from './request';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import TransForm from './components/TransForm';
import TransactionHeader from './components/TransactionHeader';
import usePageState from '../../utils/Hooks/usePageState';
const Transaction = () => {
    const history = useHistory();
    const { dispacth, selector } = usePageState();
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showAddTrans, setShowAddTrans] = useState(false);
    const [showDetail, setShowDetail] = useState(false);
    const [currentPage, setCurrentPage] = React.useState(selector?.page ?? 1);
    const [detailId, setDetailId] = useState<number>();
    const [pageSize, setPageSize] = React.useState(selector?.page_size ?? 20);
    const { data: transactions, refresh, loading, run, params } = useRequest(transactionList, {
        defaultParams: [
            {
                current_page: currentPage,
                page_size: pageSize,
                account_manage_type: selector?.account_manage_type ?? AccountManageType.Manage,
                account_id: selector?.account_id,
                transaction_status: selector?.transaction_status ?? [
                    TransStatus.Created,
                    TransStatus.Traded,
                    TransStatus.Settled,
                    TransStatus.Verified,
                    TransStatus.Valued,
                    TransStatus.Placeable,
                ],
                transaction_types: (selector?.transaction_type ?? []).concat(
                    selector?.event_type ?? []
                ),
            },
        ],
    });
    const columns = useMemo<ColumnsType<TransactionInfo>>(() => {
        return [
            {
                title: 'Trans. No.',
                dataIndex: 'id',
                width: 175,
            },
            {
                title: 'Trans. Status',
                dataIndex: 'status',
                width: 175,
                render(value) {
                    return value.desc;
                },
            },
            {
                title: 'Client',
                dataIndex: 'client',
                width: 175,
                render(value) {
                    return value.name;
                },
            },
            {
                title: 'Account',
                width: 250,
                render(value, record, index) {
                    if (
                        record.type.code === TransactionType.Transfer &&
                        (record.ext_info as TransTransferExtInfo).transfer_type_code === 3
                    ) {
                        let ext_info = record.ext_info as TransTransferExtInfo;
                        return (
                            <div
                                style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}
                            >{`${ext_info.in_account_bank}-${ext_info.in_account_no},\n${ext_info.out_account_bank}-${ext_info.out_account_no}`}</div>
                        );
                    } else {
                        if (record.account) {
                            return `${record.account?.bank_name}-${record.account?.vendor_sub_account_id}`;
                        } else {
                            return '-';
                        }
                    }
                },
            },
            {
                title: 'Trans. Type',
                dataIndex: 'type',
                width: 175,
                render(value) {
                    return value.desc;
                },
            },
            {
                title: 'Created Type',
                dataIndex: 'create_type',
                width: 200,
                render(value) {
                    return value.desc;
                },
            },
            {
                title: 'Asset Name',
                width: 200,
                render(value, record) {
                    if (record.assets && Array.isArray(record.assets) && record.assets.length > 0) {
                        if (
                            record.type.code === TransactionType.Transfer ||
                            record.type.code === TransactionType['Foreign Exchange']
                        ) {
                            return '-';
                        } else {
                            return record.assets?.[0].spec_name ?? record.assets?.[0].name;
                        }
                    }
                    return '-';
                },
            },
            {
                title: 'Created Date',
                dataIndex: 'created_at',
                width: 200,
                render(value) {
                    return value ? moment(value).format('YYYY-MM-DD') : '-';
                },
            },
            {
                title: 'Traded Date',
                dataIndex: 'trade_date',
                width: 175,
                render(value) {
                    return value ? moment(value).format('YYYY-MM-DD') : '-';
                },
            },
            {
                title: 'Valued Date',
                dataIndex: 'value_date',
                width: 175,
                render(value) {
                    return value ? moment(value).format('YYYY-MM-DD') : '-';
                },
            },
            {
                title: 'Settled Date',
                dataIndex: 'settlement_date',
                width: 175,
                render(value) {
                    return value ? moment(value).format('YYYY-MM-DD') : '-';
                },
            },
            {
                title: 'Verify Date',
                dataIndex: 'verify_date',
                width: 175,
                render(value) {
                    return value ? moment(value).format('YYYY-MM-DD') : '-';
                },
            },
            {
                title: 'Creator',
                dataIndex: ['create_member', 'name'],
                width: 130,
                render(value) {
                    if (value === '系统账号') {
                        return '系统';
                    } else {
                        return value;
                    }
                },
            },
        ];
    }, []);
    return (
        <Spin spinning={loading}>
            <div className={styles.container}>
                <TransactionHeader
                    initValues={{
                        account_id: selector?.account_id,
                        account_manage_type:
                            selector?.account_manage_type ?? AccountManageType.Manage,
                        transaction_status: selector?.transaction_status ?? [
                            TransStatus.Created,
                            TransStatus.Traded,
                            TransStatus.Settled,
                            TransStatus.Verified,
                            TransStatus.Valued,
                            TransStatus.Placeable,
                        ],
                        account_number: selector?.account_name,
                        transaction_type: selector?.transaction_type ?? [],
                        event_type: selector?.event_type ?? [],
                    }}
                    onChange={({
                        account_id,
                        account_manage_type,
                        transaction_type,
                        account_number,
                        transaction_status,
                        event_type,
                    }) => {
                        setCurrentPage(1);
                        dispacth({
                            page: 1,
                            account_manage_type,
                            transaction_status,
                            transaction_type,
                            account_id,
                            account_name: account_number,
                            event_type,
                        });
                        run({
                            ...params[0]!,
                            account_id,
                            account_manage_type: account_manage_type ?? AccountManageType.Manage,
                            current_page: 1,
                            transaction_status: transaction_status ?? [],
                            transaction_types: (transaction_type ?? []).concat(event_type ?? []),
                        });
                    }}
                />
                <Divider />
                <div className={styles.actions}>
                    <Button onClick={() => setShowAddTrans(true)}>New Trade</Button>
                    <Button
                        onClick={() => {
                            setShowEmailModal(true);
                        }}
                    >
                        Send Order Email
                    </Button>
                </div>
                <Table
                    className={styles.table}
                    size="small"
                    columns={columns}
                    dataSource={transactions?.data.transactions}
                    rowKey={(record) => record.id!}
                    pagination={false}
                    onRow={(record) => {
                        return {
                            onClick() {
                                // setDetailId(record.id);
                                // setShowDetail(true);
                                history.push(`/app/trade/transaction/detail?trans_id=${record.id}`);
                            },
                        };
                    }}
                />
                <Pagination
                    className={styles.page}
                    total={transactions?.data.total_count}
                    showTotal={(total, range) => {
                        return `总条数：${total}`;
                    }}
                    pageSize={pageSize}
                    onShowSizeChange={(current, size) => {
                        dispacth({ page_size: size });
                        setPageSize(size);
                    }}
                    onChange={(page, pageSize) => {
                        setCurrentPage(page);
                        dispacth({ page, page_size: pageSize });
                        setPageSize(pageSize || 20);
                        run({
                            ...params[0]!,
                            current_page: page,
                            page_size: pageSize,
                        });
                    }}
                    showSizeChanger
                    current={params[0]?.current_page}
                />
                <div>
                    <EmailModal
                        visible={showEmailModal}
                        onCancel={() => {
                            setShowEmailModal(false);
                        }}
                    />
                    <Modal
                        visible={showAddTrans}
                        title="Choose Trade Type & Account"
                        footer={null}
                        width={1000}
                        destroyOnClose
                        onCancel={() => setShowAddTrans(false)}
                    >
                        <AddTrans
                            onClosed={(id) => {
                                setDetailId(id);
                                setShowAddTrans(false);
                                setShowDetail(true);
                            }}
                        />
                    </Modal>
                    {detailId && (
                        <Modal
                            visible={showDetail}
                            footer={null}
                            destroyOnClose
                            onCancel={() => {
                                refresh();
                                setShowDetail(false);
                            }}
                            width={1000}
                        >
                            <TransForm
                                trans_id={detailId}
                                onClose={() => {
                                    refresh();
                                    setShowDetail(false);
                                }}
                            />
                        </Modal>
                    )}
                </div>
            </div>
        </Spin>
    );
};
export default Transaction;
