import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import {
    Button,
    DatePicker,
    message,
    Modal,
    Pagination,
    Popover,
    Result,
    Spin,
    Switch,
    Tooltip,
} from 'antd';
import moment from 'moment';
import { useLatest } from 'ahooks';
import React, {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import {
    QueryProductType,
    QueryAllAccountList,
    TranscationProductType,
    QueryAllTranscationRecord,
    UploadTermsheetTransaction,
    AllTranscationType,
} from '../../../../service/requests/CustomerManage';
import { EventList, uploadEventTrack } from '../../../../service/requests/EventTrack';
import { AccountSummary } from '../../../../service/type';
import exportXls, { ChangeTranscationToExcelData } from '../../../../utils/Hooks/useExportExcel';
import useFetch from '../../../../utils/Hooks/useFetch';
import { showError as showErrorToast } from '../../../RecommendationComponent/util';
import SelectWithAll, { AllSelectRef } from '../SelectWithAll';
import UploadModal from '../UploadModal';
import style from './index.module.scss';
import { deleteNewReceiptFile } from '../../../StructuralProducts/HistoricalTransactions/requests';
import {
    DeleteOutlined,
    EditOutlined,
    FileImageOutlined,
    FileTextOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import { deleteTransRecord } from '../CustomerDetail/Modal/AddTranscationModal/request';
import MakeTranscationImgModal from '../CustomerDetail/Modal/MakeTransactionImgModal';
import AddTranscationModal from '../CustomerDetail/Modal/AddTranscationModal';
import { MobileStateContext } from '../../../../App';
import { MoreOutline } from 'antd-mobile-icons';
import { CustomerDetailCardProps } from '../CustomerDetail/type';
const { RangePicker } = DatePicker;
export type TranscationRecordRef = {
    exportExcel: () => void;
};
const PAGE_SIZE = 10;
const momentFormat = 'YYYYMMDD';
const IintParams = {
    uid: '',
    // "isin": "",
    offset: 0,
    limit: PAGE_SIZE,
    // "trans_class": "",
    trans_day_begin: moment().subtract(1, 'year').format(momentFormat),
    trans_day_end: moment().format(momentFormat),
    account_ids: [],
    product_types: [],
    trans_types: [],
    is_upload_termsheet: 2,
};
interface TranscationRecordProps extends CustomerDetailCardProps {
    operated_uid: string;
    customerName: string;
}
const TranscationRecord = forwardRef<TranscationRecordRef, TranscationRecordProps>(
    ({ operated_uid, customerName }: TranscationRecordProps, onRef) => {
        const [addVisible, setAddVisible] = useState(false);
        const [viewVisible, setViewVisible] = useState(false);
        const mobileState = useContext(MobileStateContext);
        const [dateStr, setDateStr] = useState(
            `${IintParams.trans_day_begin}/${IintParams.trans_day_end}`
        );
        // const [showError, setShowError] = useState(false);
        const [makeImgVisible, setMakeImgVisible] = useState(false);
        const [editTransVisible, setEditTransVisible] = useState(false);
        const [currentSelect, setCurrentSelect] = useState<
            AllTranscationType['account_asset_trans'][number]
        >();
        // const [loading, setLoading] = useState(false);
        const latestSelect = useLatest(currentSelect);

        /// 请求数据
        const [fetchData, setParams, refreshCall] = useFetch({
            interfaces: QueryAllTranscationRecord,
            initialParams: {
                ...IintParams,
                uid: operated_uid,
            },
        });
        const showError = useMemo(() => {
            return fetchData.isError;
        }, [fetchData.isError]);
        const loading = useMemo(() => {
            return fetchData.isLoading;
        }, [fetchData.isLoading]);
        useImperativeHandle(
            onRef,
            () => ({
                exportExcel: () => {
                    setParams((params) => {
                        QueryAllTranscationRecord({ ...params, limit: 0, offset: 0 }).then(
                            (res) => {
                                if (res) {
                                    exportXls(`${customerName}-${dateStr}-交易记录`, [
                                        ChangeTranscationToExcelData(res.data.account_asset_trans),
                                    ]);
                                }
                            },
                            showErrorToast
                        );
                        return params;
                    });
                },
            }),
            [customerName, dateStr, setParams]
        );
        ///筛选项数据
        const [accountList, setAccountList] = useState<AccountSummary[]>([]);
        const [productList, setProductList] = useState<TranscationProductType[]>([]);

        ///带全选的选择
        const accountRef = useRef<AllSelectRef>(null);

        const productRef = useRef<AllSelectRef>(null);
        const transcationTypeRef = useRef<AllSelectRef>(null);
        ///筛选项
        const accountOptions = useMemo(() => {
            return accountList.map((item) => ({
                key: item.account_id,
                value: item.account_id,
                name: `${item.bank_name}-${item.account_name}`,
            }));
        }, [accountList]);
        const productOptions = useMemo(() => {
            return productList.map((item, index) => ({
                key: item.key,
                value: index,
                name: item.title,
            }));
        }, [productList]);
        const transcationOptions = useMemo(() => {
            let result = productList;
            if (result.length > 0) {
                let type: Array<TranscationProductType['trans_types']> = [];

                type = result.map((item) => item.trans_types);
                let allTypeList = type.reduce((a, b) => a.concat(b));

                //去除相同类型
                let tmpObj: { [key: string]: any } = {};
                let transcationListList: TranscationProductType['trans_types'] = [];
                allTypeList.forEach((item) => {
                    if (tmpObj[item.key] === undefined) {
                        transcationListList.push(item);
                        tmpObj[item.key] = item.title;
                    }
                });

                return transcationListList.map((item) => ({
                    key: item.key,
                    value: item.key,
                    name: item.title,
                }));
            }
            return [];
        }, [productList]);

        ///方法
        const resetSelect = useCallback(() => {
            [productRef, transcationTypeRef, accountRef].forEach((item) => {
                if (item.current) {
                    item.current.clearValue();
                }
            });
        }, [productRef, transcationTypeRef, accountRef]);
        ///初始化数据
        useEffect(() => {
            QueryAllAccountList({ uid: operated_uid }).then((e) => {
                setAccountList(e.data);
            }, showErrorToast);
            QueryProductType({ account_ids: [], uid: operated_uid }).then((e) => {
                setProductList(e.data.types);
            }, showErrorToast);
            setParams({ ...IintParams, uid: operated_uid });
            resetSelect();
        }, [operated_uid, setParams, resetSelect]);

        const handlePaginationChange = useCallback(
            (page: number) => {
                setParams((e) => ({
                    ...e,
                    offset: (page - 1) * PAGE_SIZE,
                }));
            },
            [setParams]
        );

        ///触发回调
        const accountChange = useCallback(
            (account_ids) => {
                uploadEventTrack(EventList.CustomerListTranscationFilter, 2, {
                    key: '账户类型',
                    value: account_ids,
                });

                setParams((e) => ({
                    ...e,
                    account_ids,
                }));
            },
            [setParams]
        );

        const productChange = useCallback(
            (selectProductType: Array<string>) => {
                let product_types = selectProductType.map(
                    (item) => productList[Number.parseInt(item.toString())].key
                );
                uploadEventTrack(EventList.CustomerListTranscationFilter, 2, {
                    key: '产品类型',
                    value: product_types,
                });
                setParams((e) => ({
                    ...e,
                    product_types,
                }));
            },
            [setParams, productList]
        );
        const dateChange = useCallback(
            (e) => {
                let begin = e[0].format(momentFormat);
                let end = e[1].format(momentFormat);
                let a = `${begin}/${end}`;
                setDateStr(a);
                uploadEventTrack(EventList.CustomerListTranscationFilter, 2, {
                    key: '交易时间',
                    value: [begin, end],
                });
                setParams((e) => {
                    return { ...e, trans_day_begin: begin, trans_day_end: end };
                });
            },
            [setParams]
        );
        const transcationChange = useCallback(
            (e) => {
                uploadEventTrack(EventList.CustomerListTranscationFilter, 2, {
                    key: '交易类型',
                    value: e,
                });
                setParams((parmas) => {
                    return {
                        ...parmas,
                        trans_types: e,
                    };
                });
            },
            [setParams]
        );

        const actionsRender = useCallback(
            (params: ICellRendererParams) => {
                if (mobileState.IsMobileMachine) {
                    return (
                        <Popover
                            placement="bottomLeft"
                            trigger="click"
                            content={
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            setCurrentSelect(params.data);
                                            setAddVisible(true);
                                        }}
                                        disabled={params.data.is_upload_termsheet === 2}
                                    >
                                        <UploadOutlined />
                                        上传
                                    </Button>

                                    <Button
                                        type="link"
                                        onClick={() => {
                                            setCurrentSelect(params.data);
                                            setViewVisible(true);
                                        }}
                                        disabled={(params.data.receipts ?? []).length === 0}
                                    >
                                        <FileTextOutlined />
                                        {`查看${
                                            params.data.receipts?.length > 0
                                                ? `${params.data.receipts.length}份`
                                                : ''
                                        }文件`}
                                    </Button>

                                    <Button
                                        type="link"
                                        disabled={params.data.manual !== 1}
                                        onClick={() => {
                                            setCurrentSelect(params.data);
                                            setEditTransVisible(true);
                                        }}
                                    >
                                        <EditOutlined />
                                        修改
                                    </Button>

                                    <Button
                                        type="link"
                                        onClick={() => {
                                            Modal.confirm({
                                                title: '删除确认',
                                                content: '确认删除该条交易记录吗？',
                                                okText: '确认',
                                                cancelText: '取消',
                                                onOk: () => {
                                                    deleteTransRecord({
                                                        account_id: params.data.account_id,
                                                        trans_id: params.data.trans_id,
                                                    }).then((res) => {
                                                        refreshCall();
                                                        message.success('删除成功');
                                                    }, showErrorToast);
                                                },
                                            });
                                        }}
                                        disabled={params.data.manual !== 1}
                                    >
                                        <DeleteOutlined />
                                        删除
                                    </Button>
                                    <Button
                                        type="link"
                                        disabled={
                                            params.data.manual !== 1 &&
                                            params.data.is_upload_termsheet === 2
                                        }
                                        onClick={() => {
                                            setCurrentSelect(params.data);
                                            setMakeImgVisible(true);
                                        }}
                                    >
                                        <FileImageOutlined />
                                        生成图片
                                    </Button>
                                </div>
                            }
                        >
                            <Button>
                                <MoreOutline />
                            </Button>
                        </Popover>
                    );
                } else {
                    return (
                        <>
                            <Tooltip title="上传">
                                <Button
                                    type="link"
                                    onClick={() => {
                                        setCurrentSelect(params.data);
                                        setAddVisible(true);
                                    }}
                                    disabled={params.data.is_upload_termsheet === 2}
                                >
                                    <UploadOutlined />
                                </Button>
                            </Tooltip>
                            <Tooltip
                                title={`查看${
                                    params.data.receipts?.length > 0
                                        ? `${params.data.receipts.length}份`
                                        : ''
                                }文件`}
                            >
                                <Button
                                    type="link"
                                    onClick={() => {
                                        setCurrentSelect(params.data);
                                        setViewVisible(true);
                                    }}
                                    disabled={(params.data.receipts ?? []).length === 0}
                                >
                                    <FileTextOutlined />
                                </Button>
                            </Tooltip>

                            <Tooltip title="修改">
                                <Button
                                    type="link"
                                    disabled={params.data.manual !== 1}
                                    onClick={() => {
                                        setCurrentSelect(params.data);
                                        setEditTransVisible(true);
                                    }}
                                >
                                    <EditOutlined />
                                </Button>
                            </Tooltip>
                            <Tooltip title="删除">
                                <Button
                                    type="link"
                                    onClick={() => {
                                        Modal.confirm({
                                            title: '删除确认',
                                            content: '确认删除该条交易记录吗？',
                                            okText: '确认',
                                            cancelText: '取消',
                                            onOk: () => {
                                                deleteTransRecord({
                                                    account_id: params.data.account_id,
                                                    trans_id: params.data.trans_id,
                                                }).then((res) => {
                                                    refreshCall();
                                                    message.success('删除成功');
                                                }, showErrorToast);
                                            },
                                        });
                                    }}
                                    disabled={params.data.manual !== 1}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </Tooltip>
                            <Tooltip title="生成图片">
                                <Button
                                    type="link"
                                    disabled={
                                        params.data.manual !== 1 &&
                                        params.data.is_upload_termsheet === 2
                                    }
                                    onClick={() => {
                                        setCurrentSelect(params.data);
                                        setMakeImgVisible(true);
                                    }}
                                >
                                    <FileImageOutlined />
                                </Button>
                            </Tooltip>
                        </>
                    );
                }
            },
            [mobileState.IsMobileMachine, refreshCall]
        );
        const colDefs = useMemo<ColDef[]>(
            () => [
                {
                    field: 'trade_date',
                    headerName: '交易日期',
                    width: 120,
                    cellRenderer: (params: ICellRendererParams) => <div>{params.value}</div>,
                },
                { field: 'account_name', headerName: '银行账号', width: 120 },
                { field: 'product_type_text', headerName: '产品种类', width: 120 },

                { field: 'trans_text', headerName: '交易类型', width: 120 },
                { field: 'trans_content', headerName: '交易内容', width: 240 },
                { field: 'c_matched_qty', headerName: '成交数量', width: 120 },
                { field: 'c_matched_price', headerName: '成交价格', width: 120 },
                {
                    headerName: '操作',
                    pinned: 'right',
                    width: mobileState.IsMobileMachine ? 40 : 200,
                    cellRenderer: (params: ICellRendererParams) => actionsRender(params),
                },
            ],
            [actionsRender, mobileState.IsMobileMachine]
        );
        const uploadReq = useCallback(
            (item: { file_encode: string }) => {
                return UploadTermsheetTransaction({
                    account_id: latestSelect.current!.account_id,
                    trans_id: latestSelect.current!.trans_id,
                    file_url: item.file_encode,
                });
            },
            [latestSelect]
        );

        return (
            <>
                {showError && (
                    <Result
                        status="error"
                        title="加载失败，请点击重试"
                        extra={
                            <Button
                                type="primary"
                                onClick={() => {
                                    refreshCall();
                                }}
                            >
                                重试
                            </Button>
                        }
                    />
                )}
                {!showError && (
                    <Spin spinning={loading}>
                        <div className={style.content}>
                            {latestSelect.current && (
                                <MakeTranscationImgModal
                                    visibleState={[makeImgVisible, setMakeImgVisible]}
                                    params={{
                                        account_id: latestSelect.current!.account_id,
                                        trans_id: latestSelect.current!.trans_id,
                                    }}
                                />
                            )}
                            {latestSelect.current && (
                                <AddTranscationModal
                                    productInfo={{
                                        account_id: latestSelect.current.account_id,
                                        action: latestSelect.current.action,
                                        product_type: latestSelect.current.product_type,
                                        trans_id: latestSelect.current.trans_id,
                                        trans_type: latestSelect.current.trans_type,
                                    }}
                                    visibleState={[editTransVisible, setEditTransVisible]}
                                />
                            )}
                            <UploadModal
                                setAddvisible={setAddVisible}
                                addVisible={addVisible}
                                viewVisible={viewVisible}
                                setViewvisible={setViewVisible}
                                refresh={refreshCall}
                                termsheets={latestSelect.current?.receipts ?? []}
                                uploadRequest={uploadReq}
                                deleteApi={(item) => {
                                    return deleteNewReceiptFile({ id: item.id });
                                }}
                            />

                            <div className={style.tool}>
                                <RangePicker
                                    className={style.date}
                                    onChange={dateChange}
                                    defaultValue={[moment().subtract(1, 'year'), moment()]}
                                    format={'YYYY/MM/DD'}
                                    disabledDate={(curMoment) => {
                                        if (curMoment.isBefore(moment())) {
                                            return false;
                                        }
                                        return true;
                                    }}
                                />
                                <SelectWithAll
                                    ref={accountRef}
                                    selectProp={{ mode: 'multiple', className: style.select }}
                                    children={accountOptions}
                                    allText="全部银行账户"
                                    onChange={accountChange}
                                />
                                <SelectWithAll
                                    ref={productRef}
                                    selectProp={{ mode: 'multiple', className: style.select }}
                                    children={productOptions}
                                    allText="全部产品类型"
                                    onChange={productChange}
                                />
                                <SelectWithAll
                                    ref={transcationTypeRef}
                                    selectProp={{ mode: 'multiple', className: style.select }}
                                    children={transcationOptions}
                                    allText="全部交易类型"
                                    onChange={transcationChange}
                                />
                            </div>
                            <div className={style.switch}>
                                <Switch
                                    onChange={(checked) => {
                                        setParams((e) => ({
                                            ...e,
                                            is_upload_termsheet: checked ? 1 : 2,
                                        }));
                                    }}
                                />
                                <div className={style.switchText}>只看可上传termsheet交易记录</div>
                            </div>
                            <div
                                className={`ag-theme-alpine ${style.CustomerManage_Transcation__TableContainer}`}
                            >
                                <AgGridReact
                                    animateRows
                                    columnDefs={colDefs}
                                    defaultColDef={{ resizable: true, width: 100 }}
                                    rowData={fetchData.data?.account_asset_trans ?? []}
                                    domLayout="autoHeight"
                                    enableCellTextSelection
                                    ensureDomOrder
                                />
                            </div>
                            {fetchData.data && fetchData.data?.total_count > 1 ? (
                                <Pagination
                                    onChange={handlePaginationChange}
                                    className={style.page}
                                    total={fetchData.data?.total_count}
                                    // style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}
                                    pageSize={PAGE_SIZE}
                                    showSizeChanger={false}
                                />
                            ) : null}
                        </div>
                    </Spin>
                )}
            </>
        );
    }
);
export default TranscationRecord;

// QueryAllAccountList . QueryAllTranscationRecord / QueryProductType
