export const numberTransformer = (num: number) => {
    let ret = {} as any,
        i;
    const k = 10000,
        sizes = ['', '万', '亿', '万亿'];
    num = Math.abs(num);
    if (num < k) {
        ret.value = num;
        ret.unit = '';
    } else {
        i = Math.floor(Math.log(num) / Math.log(k));
        ret.value = (num / Math.pow(k, i)).toFixed(2);
        ret.unit = sizes[i];
    }
    return ret;
};
