import axios from '../tools';

export interface IAccount {
    accountId: string;
    uid: string;
    accountType: string;
    accountClass: number;
    accountName: string;
    vendorSubAccountId: string;
    tag: number;
    displayName: string;
    status: number;
    boundAt: number;
}

export interface IClient {
    id: number;
    name: string;
    mobile: string;
    email: string;
    address: string;
    state: string;
    gender: string;
    birthday: string;
    source: string;
    roadmap_url: string;
    creator_id: number;
    huoban_id: number;
    kyc_url: string;
    remark: string;
    is_real: number;
    cts: string;
    uts: string;
    banks: IBank[];
}

export interface IBank {
    id: string;
    locale: string;
    name: string;
    logo: string;
    short_name: string;
}
export interface ILtv {
    id: number;
    client_id: number;
    account_id: string;
    remaining_loanable_amount: number;
    person_in_charge: number;
    logic_status: string;
    created_at: number;
    updated_at: number;
    account: IAccount;
    client: IClient;
    person_in_charge_ic: IPerson_in_charge_ic;
}

export interface IPerson_in_charge_ic {
    id: number;
    name: string;
    mobile: string;
    valid: boolean;
    huobanId: number;
    feishuId: string;
    feishuOpenId: string;
    jiraId: string;
    feishuAvatar: string;
}

export interface IHisotryProps {
    account_id: string;
}

export interface ILtvData {
    data: {
        ltvs: ILtv[];
    };
}

export interface ILtvHisData {
    data: {
        ltv_histories: ILtv[];
    };
}

export interface ILtvUpdate {
    ltv: ILtv;
}

export interface IResLtv {
    reqId: string;
    code: number;
    msg: string;
    data: {};
}

export interface ICharge {
    client_id: number;
    role_type: number;
}
export interface IMember {
    data: {
        members: {
            id: number;
            name: string;
            mobile: string;
            valid: boolean;
            huobanId: number;
            feishuId: string;
            feishuOpenId: string;
            jiraId: string;
            feishuAvatar: string;
        }[];
    };
}

export interface IDetail {
    account_id: string;
}

export interface ILtvDetailData {
    data: {
        ltv: ILtv;
    };
}

export const queryAllLtv = async (data: {}) => {
    const res = await axios.post<{}, ILtvData>('/gk//v1/ltv/query', { data });
    return res.data;
};

export const queryHistoryLtv = async (data: IHisotryProps) => {
    const res = await axios.post<IHisotryProps, ILtvHisData>('/gk/v1/ltv/history', { data });
    return res.data;
};

export const queryUpdateLtv = async (data: ILtvUpdate) => {
    const res = await axios.post<ILtvUpdate, IResLtv>('/gk/v1/ltv/update', { data });
    return res.data;
};

export const queryChargeMember = async (data: ICharge) => {
    const res = await axios.post<ICharge, IMember>('/gk/v1/client/query_member_list', { data });
    return res.data;
};

export const queryLtvDetail = async (data: IDetail) => {
    const res = await axios.post<IDetail, ILtvDetailData>('/gk/v1/ltv/detail', {
        data,
    });
    return res.data;
};
