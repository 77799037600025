import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import AccountItem from './FormItems/AccountItem';
import { GinkgoTransFormRefType, TransStatus, TransactionInfo } from '../../../type';
import DateItem from './FormItems/DateItem';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { GinkgoInput, GinkgoInputNumber } from './FormItems/GinkgoFieldItems';
import BondValidity from './FormItems/BondValidity';
import BondPriceType from './FormItems/BondPriceType';
import SearchTickerItem from './FormItems/SearchTickerItem';
import { useLatest } from 'ahooks';

const BondForm = forwardRef<GinkgoTransFormRefType, TransactionInfo>(
    (data: TransactionInfo, onRef) => {
        const [form] = useForm();
        const [priceType, setPriceType] = useState<number>();
        const [dayOrder, setDayOrder] = useState<number>();
        const [timezone, setTimezone] = useState(data.time_zone || '');
        const latestDayOrder = useLatest(dayOrder);
        useImperativeHandle(onRef, () => {
            return {
                getFieldsValues: async (validate) => {
                    if (validate === false) {
                        return new Promise((resolve) => {
                            return resolve(configValues(form.getFieldsValue()));
                        });
                    } else {
                        return form.validateFields().then(configValues);
                    }
                },
            };
        });

        const configValues = (values: any) => {
            return {
                ...values,
                client_id: data.client_id,
                transaction_type: data.type.code,
                id: data.id,
                ext_info: {
                    ...data.ext_info,
                    ...values.ext_info,
                    is_day_order: dayOrder,
                    price: values.ext_info?.price?.toString(),
                    fill_price: values.ext_info?.fill_price?.toString(),
                },
                status_code: data.status.code,
            };
        };
        const PlaceableReadOnly = useMemo(() => {
            return data.status.code !== TransStatus.Created;
        }, [data]);
        const TradedReadOnly = useMemo(() => {
            return data.status.code === TransStatus.Traded;
        }, [data]);
        const PlaceableRequired = useMemo(() => {
            return data.status.code === TransStatus.Created;
        }, [data]);
        const TradedRequired = useMemo(() => {
            return data.status.code === TransStatus.Placeable;
        }, [data]);

        useEffect(() => {
            setDayOrder((data.ext_info as any).is_day_order);
            setPriceType((data.ext_info as any).price_type);
            form.setFieldsValue({
                ...data,
            });
        }, [data, form]);

        return (
            <Form labelCol={{ span: 6 }} labelWrap wrapperCol={{ span: 18 }} form={form}>
                <AccountItem
                    clientId={data.client_id}
                    name={['ext_info', 'account_id']}
                    form={form}
                    readOnlyValue={data.ext_info.account_id}
                    selectCallback={(bank) => {}}
                    width="calc(75% - 10px)"
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                />
                <SearchTickerItem
                    form={form}
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                    width="calc(75% - 10px)"
                    onTickerSelect={(asset) => {
                        setTimezone(asset?.time_zone || '');
                    }}
                />
                <Form.Item
                    label="Quantity"
                    name={['ext_info', 'qty']}
                    rules={[{ required: PlaceableRequired, message: 'Quantity is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(75% - 10px)' }}
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>
                <BondPriceType
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                    onChange={(value) => setPriceType(value)}
                />
                {priceType === 6 && (
                    <Form.Item
                        label={'Other Price Type'}
                        name={['ext_info', 'custom_price_type']}
                        rules={[{ required: PlaceableRequired, message: 'Price type is required' }]}
                    >
                        <GinkgoInput
                            style={{ width: 'calc(75% - 10px)' }}
                            readOnly={PlaceableReadOnly}
                        />
                    </Form.Item>
                )}
                {priceType === 1 && (
                    <Form.Item
                        label="Price"
                        name={['ext_info', 'price']}
                        rules={[{ required: PlaceableRequired, message: 'Price is required' }]}
                    >
                        <GinkgoInputNumber
                            style={{ width: 'calc(75% - 10px)' }}
                            readOnly={PlaceableReadOnly}
                        />
                    </Form.Item>
                )}
                <Form.Item
                    name={['ext_info', 'validity']}
                    label="Validity"
                    rules={[
                        {
                            required: PlaceableRequired,
                            message: 'Validity is required',
                            validator(rule, value, callback) {
                                if (latestDayOrder.current === 1) {
                                    callback();
                                    return;
                                }
                                if (!value) {
                                    callback('Validity is required');
                                    return;
                                }
                                callback();
                            },
                        },
                    ]}
                >
                    <BondValidity
                        readOnly={PlaceableReadOnly}
                        isDayOrder={(data.ext_info as any).is_day_order}
                        dayChange={(dayOrder) => {
                            setDayOrder(dayOrder ? 1 : 2);
                            form.validateFields([['ext_info', 'validity']]);
                        }}
                    />
                </Form.Item>

                <Form.Item label="Custodian Trade ID" name={['ext_info', 'custodian_trade_id']}>
                    <GinkgoInput style={{ width: 'calc(75% - 10px)' }} readOnly={TradedReadOnly} />
                </Form.Item>

                <Form.Item
                    label="Filled Quantity"
                    name={['ext_info', 'fill_qty']}
                    rules={[{ required: TradedRequired, message: 'Filled Quantity is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(75% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Filled Price"
                    name={['ext_info', 'fill_price']}
                    rules={[{ required: TradedRequired, message: 'Filled Price is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(75% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Settlement Amount"
                    name={['ext_info', 'settlement_amount']}
                    rules={[{ required: TradedRequired, message: 'Sttlement Amount is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(75% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>

                <DateItem
                    label="Trade Date"
                    name="trade_date"
                    width="calc(75% - 10px)"
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                    timezone={timezone}
                />
            </Form>
        );
    }
);

export default BondForm;
