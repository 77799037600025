import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router';
import { queryEditEntry } from '../../../../service';
import styles from './index.module.scss';
import { Tabs } from 'antd';
import { showError } from '../../../RecommendationComponent/util';
import { queryEditEntryType } from '../../../../service/type';
import ViewModal from './components/viewModal';
import { EventList, uploadEventTrack } from '../../../../service/requests/EventTrack';

const { TabPane } = Tabs;

export default function CustomerProductQuotation() {
    const { id: customer_id } = useParams<{ id: string }>();
    const [customerInfo, setCustomerInfo] = useState<queryEditEntryType | null>(null);
    const [currentProduct, setCurrentProduct] = useState<queryEditEntryType['entries'][0] | null>(
        null
    );
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        queryEditEntry({
            customer_id: Number(customer_id),
        }).then((data) => {
            setCustomerInfo(data.data);
            setCurrentProduct(data.data.entries[0]);
        }, showError);
    }, [customer_id]);
    const onChange = useCallback(
        (key: string) => {
            const target = customerInfo?.entries.find((item) => item.product_type === key);
            if (target) {
                uploadEventTrack(EventList.SingleQuoteSwitchType, 2, {
                    product_type: target.product_type,
                });
                setCurrentProduct(target);
            }
        },
        [customerInfo]
    );
    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>客户id：{customerInfo?.customer.id}</div>
            <div className={styles['customer-info']}>
                <div className={styles['customer-info-item']}>
                    姓名：{customerInfo?.customer.name}
                </div>
                <div className={styles['customer-info-item']}>
                    联系方式：{customerInfo?.customer.mobile}
                </div>
            </div>
            <button
                className={styles.button}
                onClick={() => {
                    uploadEventTrack(EventList.SingleQuoteGenPreview, 2);
                    setVisible(true);
                }}
            >
                生成预览
            </button>
            <ViewModal
                visible={visible}
                setVisible={setVisible}
                key={currentProduct?.product_type}
                product={currentProduct}
            />
            <Tabs defaultActiveKey="1" onChange={onChange}>
                {customerInfo?.entries.map((item) => {
                    return (
                        <TabPane tab={item.product_type} key={item.product_type}>
                            <iframe
                                src={item.edit_entry_url}
                                title={item.product_type}
                                style={{ width: '100%', height: 800, border: 'unset' }}
                            />
                        </TabPane>
                    );
                })}
            </Tabs>
        </div>
    );
}
