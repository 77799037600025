import axios from '../tools';
import { AccountSummary } from '../type';
export interface PositionModel {
    account_id: string;
    accrued_interest_amount: null;
    accrued_interest_amount_currency: null;
    aggr_balance: string;
    avail_balance: string;
    book_value: string;
    book_value_currency: string;
    c_accrued_interest_amount: null;
    c_aggr_balance: string;
    c_avail_balance: string;
    c_book_value: string;
    c_cost_price: string;
    c_cur_price: string;
    c_earn: string;
    c_earn_daily: string;
    c_eligible_collateral_value: string;
    c_hold_value: string;
    c_settle_hold_value: string;
    cost_price: string;
    cost_price_currency: string;
    coupon: string;
    cur_price: string;
    cur_price_currency: string;
    delivery: number;
    div_yld: null;
    earn: string;
    earn_daily: string;
    earn_rate: string;
    earn_rate_daily: string;
    eligible_collateral_value: string;
    eligible_collateral_value_currency: string;
    ex_country: string;
    ext_info: {
        ytm: string;
        coupon: string;
        expiry_date: string;
        lock_up_num: string;
        tradable_num: string;
    };
    fx: string;
    hold_value: string;
    hold_value_currency: string;
    hold_value_exchange: ExchangeModel[];
    isin: string;
    market_value_usd: string;
    market_value_usd_complete: string;
    name: string;
    position_info_display: null;
    security_type: string;
    show_alert_icon: number;
    sub_asset_class: string;
    sub_positions: null;
    symbol: string;
    ticker: string;
    total_asset_percentage: string;
    type: string;
    sub_position_details: {
        details: BankInfo[];
        summary: {}[];
    }[];
    ytm: string;
    termsheets: any[];
}
export interface ExchangeModel {
    c_to_amount: string;
    exchange_rate: string;
    fromName: string;
    from_currency: string;
    fx_updated_at: number;
    to_amount: string;
    to_currency: string;
    to_name: string;
}
export interface CustomerActivityData {
    customer_dynamics: {
        app_ver: '1.1.579';
        deal_volume_in_week: number;
        online_days_in_month: number;
        latest_login_time: string;
        latest_read_docs?: {
            read_at: number;
            doc_id: '25881';
            doc_title: string;
        }[];
        doc_id: '25881';
        doc_title: '4个关于FCN的知识点，你了解过几个？｜鹿可6number秒小课堂';
        read_at: number;
        online_days_in_week: 1;
        order_volume_in_week: number;
        otc_valid_position_stat: {
            [key: string]: number;
        };
    };
}
export interface CustomerPositionData {
    account_position_number_infos: {
        key: string;
        number: number;
        title: string;
    }[];
    sec_cates: {
        c_hold_value: string;
        desc: string;
        hold_value: string;
        hold_value_currency: string;
        hold_value_diff: string;
        hold_value_exchange: ExchangeModel[];
        name: string;
        percentage: number;
        positions: PositionModel[];
        sec_cate_id: string;
        termsheets: any[];
        account_id: string;
        isin: string;
    }[];
}
export interface RMICInfo {
    id: number;
    name: string;
    feishuAvatar: string;
    mobile: string;
    jiraId: null;
    roleIds: number;
    valid: boolean;
    tradeBankAccountIds: string[];
}
export interface CustomerManageData {
    totalHits: number;
    hitsPerPage: number;
    totalPages: number;
    currentPage: number;

    pageData: {
        id: number;
        [key: string]: any;
        relativeMobile: string;
        parentMobile: string;
        bank_names: string[];
        name: string;
        nickName: string; //app昵称
        remarkName: string; //app 备注
        gender: string;
        mobile: string;
        parentCustomerId: number;
        roadmapUrl: string;
        isChildUser: number;
        email: string;
        address: string;
        birthday: string;
        source: string;
        state: string;
        relativeCustomerId: number;
        relativeName: string;
        portalUid: string;
        kycUrl: string;
        remark: string;
        last_login_in_time_info: string;
        bankAccountIdsStr: string;
        bankAccounts: {
            accountClass: number;
            accountId: string;
            bankName: string;
            traders: RMICInfo[];
            vendorAccountId: string;
        }[];
        ics: RMICInfo[];
        rms: RMICInfo[];
        signedFiles: {
            create_at: number;
            file_name: string;
            file_size: number;
            file_url: string;
            id: number;
            uid: string;
        }[];
    }[];
}
export interface BankInfo {
    bank_id: string;
    bank_logo: string;
    bank_name: string;
    total_asset: string;
    percent: string;
    c_total: string;
    currency: string;
    total: string;
    vendor_sub_account_id: string;
    cashType: string;
    account_assets: AccountInfoType[];
}
export interface AccountInfoType {
    account_name: string;
    vendor_sub_account_id: string;
    account_type: string;
    account_display_name: string;
    display_name: string;
    account_tag: number;
    tag: number;

    c_net_asset: string;
    account_id: string;
    account_class: number;
}
export interface CustomerAssetModel {
    bi_review_url: string;
    customer_asset_summary: {
        concentration: string; //集中度
        asset?: {
            balanceAmount: string;
            balanceAmountDiffPercent: string;
            currency: string;
            date: string;
            c_debit_amount: string;
            c_total_asset: string;
            debitAmountDiffPercent: string;
            holdValue: string;
            holdValueDiffPercent: string;
            manuallyUpdatedAt: string;
            c_net_asset: string;
            netAssetDiffPercent: string;
            net_margin_avail: string;
            positionUpdatedAt: string;
            risk_rate: string;
            totalAsset: string;
            totalAssetDiffPercent: string;
            updatedAt: string;
            c_net_margin_avail: string;
        };
        asset_distribution?: {
            avg_cost_price: null;
            c_total_market_value: string;
            c_total_qty: null;
            currency: null;
            isin: null;
            position_info_by_bank: null;
            position_percent_by_bank: BankInfo[];
            total_market_value: string;
            total_qty: null;
        };
        asset_earning?: {
            c_earn: 'number.numbernumber';
            earn: 'number.numbernumber';
            earn_rate: 'number.numbernumber%';
            time_duration: 2;
        };
    };
}
export interface CustomerManageReq {
    startIndex?: number;
    currentPage: number;
    pageSize: number;
    search_key?: string;
}
export interface TranscationProductType {
    key: string;
    title: string;
    trans_types: {
        key: string;
        title: string;
    }[];
    trans_detail_type_fields?: {
        action: number;
        title: string;
        transType: number;
    }[];
}
export interface QueryTranscationReq {
    uid: string;
    // "isin": "",
    offset: number;
    limit: number;
    // "trans_class": "",
    trans_day_begin: string;
    trans_day_end: string;
    account_ids: string[];
    product_types: string[];
    trans_types: string[];
    is_upload_termsheet: number;
}
export interface AllTranscationType {
    total_count: number;
    account_asset_trans: {
        trade_date: string;
        account_id: string;
        trans_id: string;
        trans_detail_type: string;
        trans_type_text: string;
        account_name: string;
        c_matched_qty: string;
        trans_content: string;
        product_type_text: string;
        c_matched_price: string;
        product_type: string;
        trans_type: number;
        action: number;

        manual: number;
        receipts: {
            id: number;
            account_id: string;
            trans_id: string;
            matched_order_id: number;
            order_type: number;
            file_url: string;
            file_name: string;
            created_at: number;
        }[];
    }[];
}
type RoleReqParma = {
    role?: number; //交易员 8
};
export const getCustomerManageData = (data: CustomerManageReq) => {
    return axios.post<CustomerManageData>('/v1/admin/crm/customer/list', { data });
};

export type RMList = {
    id: number;
    name: string;
}[];

export const getRMList = (data?: RoleReqParma) => {
    return axios.post<RMList>('/v1/admin/auth/member/list', { data });
};

export const getICList = () => {
    return axios.post<RMList>('/v1/admin/auth/member/ic_list');
};

export const setCustomerRMs = (data: {
    customerId: number;
    memberIds: number[];
    icIds: number[];
    traders: { [key: string]: number[] };
}) => {
    return axios.post('/v1/admin/crm/customer/actor/set', { data });
};
export type EarningType = {
    earn: string;
    time_duration: number;
    end_date: string;
    start_date: string;
    earn_rate: string;
    c_earn: string;
};
export type RMCustomMobileInfo = {
    customers: {
        mobile: string;
        name: string;
    }[];
};
export const searchRMCustomerMobile = (data: { mobile_key: string; parent_mobile: string }) => {
    return axios.post<RMCustomMobileInfo>('/v1/admin/customer/mobiles', { data });
};
export const associateCustomerMobile = (data: {
    parent_mobile: string;
    child_mobile: string | undefined;
}) => {
    return axios.post<RMCustomMobileInfo>('/v1/admin/user/associate', { data });
};
//  /v1/admin/user/associate
// v2/admin/customer/base_info
// v2/admin/customer/asset
// v2/admin/customer/position
// /v2/admin/customer/dynamic

export const QuertPosition = (data: { customer_id: number }) => {
    return axios.post<CustomerPositionData>('/v2/admin/customer/position', { data });
};
export const QuertBaseInfo = (data: { customer_id: number }) => {
    return axios.post<{ customer_detail: CustomerManageData['pageData'][number] }>(
        '/v2/admin/customer/base_info',
        {
            data,
        }
    );
};
export const QueryAsset = (data: { customer_id: number }) => {
    return axios.post<CustomerAssetModel>('/v2/admin/customer/asset', { data });
};
export const QueryActivity = (data: { customer_id: number }) => {
    return axios.post<CustomerActivityData>('/v2/admin/customer/dynamic', { data });
};

export const QueryProductType = (data: { uid: string; account_ids: Array<string> }) => {
    return axios.post<{ types: TranscationProductType[] }>(
        '/v1/admin/asset/transaction_product_type',
        {
            data,
        }
    );
};
export const QueryAllTranscationRecord = (data: QueryTranscationReq) => {
    return axios.post<AllTranscationType>('/v1/admin/asset/transaction', {
        data,
    });
};
export const QueryAllAccountList = (data: { uid: string }) => {
    return axios.post<AccountSummary[]>('/v1/admin/account/list', { data });
};
export const QueryEarning = (data: { customer_id: number; begin_at?: string; end_at?: string }) => {
    return axios.post<EarningType>('/v1/admin/earning/customer/query', { data });
};

export const UploadTermsheetTransaction = (data: {
    account_id: string;
    trans_id: string;
    file_url: string;
}) => {
    return axios.post('/v1/admin/upload_termsheet_transaction', { data });
};
