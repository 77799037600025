import React, { useEffect, useMemo } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import QuoteAllTable from '../component/QuoteAllTable';
import QuoteSubTable from '../component/QuoteSubTable';
import AccountSummary from '../component/AccountSummary';
import BondCalculate from '../component/BondCalculate';
import BondQuoteDetailActions from '../component/BondQuoteDetailActions';
import DetailSection from '../component/DetailSection';
import { Empty, InputNumber, Modal, Spin, message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import GKProductsPrice from '../../GKProductPrice';
import {
    calculateQuotePrice,
    qouteConfirm,
    queryQouteRecord,
    queryCashPositionSummary,
    revokeQuote,
    saveRecord,
    syncMsg,
} from '../request';
import { useLatest, useRequest } from 'ahooks';
import {
    ProductQuoteDetailType,
    QuoteEntity,
    QuoteRelatedTargetAction,
    UserAccountInfo,
} from '../type';
import querySearch from 'query-string';
import { showError } from '../../RecommendationComponent/util';
import { AssetType, TransELNExtInfo } from '../../Transaction/type';
import { Asset } from '../../Transaction/type';
import { configDefaultValue } from '../util';
const QuoteDetail = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const { id } = querySearch.parse(location.search) as {
        id: string;
    };

    const [price, setPrice] = React.useState<number>(0.5);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [detailData, setdetailData] = React.useState<ProductQuoteDetailType>();
    const detailLatest = useLatest(detailData);
    const assetType = useMemo(() => {
        return detailData?.asset_type.code;
    }, [detailData]);
    const { loading: requestLoading } = useRequest(queryQouteRecord, {
        defaultParams: [
            {
                asset_quote_id: Number.parseInt(id),
            },
        ],
        onSuccess: (data) => {
            setdetailData(data.data.asset_quote_detail);
        },
    });
    const { loading: calculateLoading, run: calculateRun } = useRequest(calculateQuotePrice, {
        manual: true,
        onSuccess: (data) => {
            setdetailData(changeQuotePoolData(data.data.asset_quote_detail));
        },
    });

    const [accountSummary, setAccountSummary] = React.useState<UserAccountInfo>();
    const changeQuotePoolData = (data: ProductQuoteDetailType) => {
        data.quote_pool = data.quote_pool?.map((poolItem) => {
            return (
                data.selected_quotes?.find((selectItem) => {
                    return selectItem.isin === poolItem.isin;
                }) ?? poolItem
            );
        });
        return data;
    };
    //获取账户相关信息
    useEffect(() => {
        if (id && !accountSummary) {
            queryCashPositionSummary({
                asset_quote_id: id,
            }).then((res) => {
                setAccountSummary(res.data);
            });
        }
    }, [accountSummary, id]);

    useEffect(() => {
        setPrice(detailData?.price_increased_percentage || 0.5);
    }, [detailData]);

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        calculateRun({
            asset_quote_detail: {
                ...detailData!,
                price_increased_percentage: Number.parseFloat(e.target.value),
            },
        });
    };
    const handleAllTableSelectWithAction = async (
        list: QuoteEntity[],
        action?: QuoteRelatedTargetAction
    ) => {
        let finalData: QuoteEntity[] = list?.map((item) => {
            return configDefaultValue(item, assetType!, detailData?.minimum_transaction_amount);
        });
        setLoading(true);
        try {
            let result = await calculateQuotePrice({
                asset_quote_detail: {
                    ...detailData!,
                    selected_quotes: finalData,
                },
            });
            setdetailData(changeQuotePoolData(result.data.asset_quote_detail));
        } catch (error) {
            message.error({ msg: '报价计算失败' });
        } finally {
            setLoading(false);
        }
        switch (action) {
            case QuoteRelatedTargetAction.Cancel:
                handleCancel();
                break;
            case QuoteRelatedTargetAction.Confirm:
                handleConfirm();
                break;
            case QuoteRelatedTargetAction.Delete:
                handleRemove();
                break;
            case QuoteRelatedTargetAction.Save:
                handleSave();
                break;
            case QuoteRelatedTargetAction.Sync:
                handleSyncApp();
                break;
            default:
                return;
        }
    };
    const handleRecommandSelect = (list: Asset[]) => {
        let finalData: QuoteEntity[] = [];
        detailData?.selected_quotes?.forEach((item) => {
            list.forEach((findItem) => {
                if (findItem.isin === item.isin) {
                    finalData.push({
                        ...item,
                    });
                }
            });
        });

        calculateRun({
            asset_quote_detail: {
                ...detailData!,
                quote_pool: list?.map((item) => ({
                    asset: {
                        ...item,
                    },
                    ...((item as any) as QuoteEntity),
                    isin: item.isin,
                    ext_info: item.ext_info as TransELNExtInfo,
                })),
                // selected_quotes: finalData ?? [],
            },
        });
    };
    const handleConfirm = () => {
        setLoading(true);
        saveRecord({ asset_quote_detail: detailLatest.current! })
            .then(() => {
                qouteConfirm({ asset_quote_id: id })
                    .then(() => {
                        message.success('您已确认报价信息');
                    })
                    .catch(showError)
                    .finally(() => setLoading(false));
            })
            .catch((err) => {
                setLoading(false);
                showError({ msg: err.message });
            });
    };
    const handleSave = () => {
        setLoading(true);
        saveRecord({ asset_quote_detail: detailLatest.current! })
            .then(() => {
                message.success('保存成功');
            })
            .catch(() => {
                message.error('保存失败');
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleCancel = () => {
        Modal.confirm({
            title: '取消后，回到上次保存的数据，状态不变',
            onOk: () => {
                //reload当前页面
                window.location.reload();
            },
        });
    };
    const handleSyncApp = () => {
        saveRecord({ asset_quote_detail: detailLatest.current! });
        Modal.confirm({
            title: `点击确认后将生成【${
                detailData!.asset_type.desc
            }报价】卡片，可以前往App查看或分享给客户。位置：首页 - 右上角消息中心(🔔) - 产品报价`,
            onOk: () => {
                setLoading(true);
                syncMsg({ asset_quote_id: id })
                    .then(() => {
                        message.success('同步成功');
                    })
                    .catch(showError)
                    .finally(() => {
                        setLoading(false);
                    });
            },
        });
    };
    const handleRemove = () => {
        Modal.confirm({
            title: '是否确定撤销该报价',
            onOk: () => {
                setLoading(true);
                revokeQuote({ asset_quote_id: id })
                    .then(() => {
                        message.success('撤销成功');
                        history.goBack();
                    })
                    .finally(() => setLoading(false));
            },
        });
    };
    const handleSendEmail = () => {
        history.push('/app/trade/BondQuote/email?id=' + id);
    };
    return (
        <Spin spinning={requestLoading || calculateLoading || loading}>
            {detailData && (
                <div className={styles.container}>
                    <DetailSection title={t('BondDetail_AccountInfo')}>
                        <AccountSummary
                            info={accountSummary}
                            assetType={assetType}
                            selectedItem={
                                detailData.selected_quotes?.length > 0
                                    ? detailData.selected_quotes[0]
                                    : undefined
                            }
                        />
                    </DetailSection>

                    {detailData.status.code < 3 && assetType && accountSummary && (
                        <DetailSection title={'选择产品'}>
                            <GKProductsPrice
                                type={assetType}
                                defaultSelectedKeys={detailData.quote_pool?.map(
                                    (item) => item.isin
                                )}
                                onSelectionChange={handleRecommandSelect}
                                tableHeight={400}
                                accountId={accountSummary.account.accountId}
                            />
                        </DetailSection>
                    )}
                    <DetailSection title={t('BondDetail_QuoteDetail')}>
                        {(assetType === AssetType.CorporateBond ||
                            assetType === AssetType.GovernmentBond) && (
                            <InputNumber
                                value={price}
                                disabled={detailData?.status!.code! > 2}
                                onChange={(value) => setPrice(value as number)}
                                onBlur={handlePriceChange}
                                addonBefore={<div>{t('price_up')}</div>}
                                addonAfter={'%'}
                                style={{ maxWidth: '220px', marginBottom: '12px' }}
                            />
                        )}
                        {(detailData?.cal_fields?.length ?? 0) > 0 && (
                            <BondCalculate data={detailData} onOk={handleConfirm} />
                        )}
                        <QuoteSubTable
                            data={detailData}
                            assetType={detailData.asset_type.code}
                            handleSelectCaluclate={(list, action) => {
                                handleAllTableSelectWithAction(list, action);
                            }}
                        />
                        <QuoteAllTable
                            radioType={
                                assetType === AssetType.CorporateBond ||
                                assetType === AssetType.GovernmentBond
                                    ? 'checkbox'
                                    : 'radio'
                            }
                            data={detailData}
                            selectedList={handleAllTableSelectWithAction}
                        />
                    </DetailSection>
                    <BondQuoteDetailActions
                        save={handleSave}
                        cancel={handleCancel}
                        sendEmail={handleSendEmail}
                        revoke={handleRemove}
                        asyncApp={handleSyncApp}
                        status={detailData.status.code}
                    />
                </div>
            )}
            {!detailData && <Empty />}
        </Spin>
    );
};

export default QuoteDetail;
