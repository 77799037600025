import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Button, DatePicker, Form, Input, message, Modal, Radio } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Search from 'antd/lib/input/Search';
import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { MobileStateContext } from '../../App';
import { addSecurityWithISIN, newSecurity, searchSecurity } from '../../service';
import { EventList, uploadEventTrack } from '../../service/requests/EventTrack';
import { assetList } from '../../service/type';
import { showError } from '../RecommendationComponent/util';
import SearchProductFormItem from '../widget/GeneralForm/SearchProductFormItem';
import SpecName from './SpecName';

const EditProduct = () => {
    const [form] = useForm();
    const [dataList, setDataList] = useState<assetList>([]);
    const [currentAsset, setCurrentAsset] = useState<assetList[0] | null>(null);
    const [keyword, setKeyword] = useState('');
    const [refreshList, setRefreshList] = useState(0);
    const [visible, setVisible] = useState(false);
    const [date, setDate] = useState<Moment>(moment());
    const [addType, setAddType] = useState(1);
    const [addProductType, setAddProductType] = useState('bond');
    const [buttonEnble, setButtonEnble] = useState(true);
    const [curPostion, setCurPostion] = useState<assetList[number]>();
    const formParams = useMemo(() => {
        let params: { [key: string]: any } = {
            addType: 1,
            sub_asset_class: 'bond',
            ...currentAsset,
        };
        if (currentAsset?.sub_asset_class === 'reits' || currentAsset?.sub_asset_class === 'bond') {
            params.price = currentAsset.cur_price;
        }
        if (currentAsset?.sub_asset_class === 'bond') {
            params.ext_fields.expiry_date = moment(currentAsset.ext_fields.expiry_date);
        }
        return params;
    }, [currentAsset]);

    const handleRefreshList = useCallback(() => setRefreshList((num) => num + 1), []);
    const onSearch = useCallback((keyword?: string) => {
        uploadEventTrack(EventList.EditProductSearch, 2, {
            value: keyword ?? '',
        });
        keyword ? setKeyword(keyword) : setKeyword('');
    }, []);
    const { IsMobileMachine } = useContext(MobileStateContext);
    const resetData = useCallback(() => {
        setDate(moment());
        setAddType(1);
        setAddProductType('bond');
        form.resetFields();
    }, [form]);
    useEffect(() => {
        keyword && searchSecurity({ keyword }).then((res) => setDataList(res.data), showError);
    }, [keyword, refreshList]);
    useEffect(() => {
        setTimeout(() => {
            form?.resetFields();
        }, 0);
    }, [formParams, form]);
    const editClick = useCallback(
        (params) => {
            resetData();

            setCurrentAsset(params.data);
            setAddProductType(params.data.sub_asset_class);
            setAddType(2);
            setTimeout(() => {
                setVisible(true);
            }, 0);
        },
        [resetData]
    );
    const customFields = useMemo(() => {
        return Object.keys(currentAsset?.custom_fields ?? []).map((key) => {
            return {
                key: key,
                value: currentAsset?.custom_fields[key],
            };
        });
    }, [currentAsset]);

    const colDefs = useMemo<ColDef[]>(
        () => [
            {
                headerName: '产品名称',
                field: 'name',
                width: 200,
            },
            {
                headerName: '别名',
                width: 200,
                cellRenderer: ({ data: props }: ICellRendererParams) => (
                    <SpecName
                        isin={props.isin}
                        spec_name={props.spec_name}
                        handleRefreshList={handleRefreshList}
                    />
                ),
            },
            {
                headerName: 'ISIN',
                field: 'isin',
                width: 150,
            },
            {
                headerName: '展示分类',
                field: 'f_sec_type_text',
                flex: 1,
                minWidth: 100,
            },
            {
                headerName: '操作',
                pinned: 'right',
                width: 100,
                cellRenderer: (params: ICellRendererParams) =>
                    ['pfund', 'bond', 'reits'].findIndex(
                        (item) => params.data.sub_asset_class === item
                    ) !== -1 && (
                        <Button
                            type="primary"
                            onClick={() => {
                                editClick(params);
                            }}
                        >
                            编辑
                        </Button>
                    ),
            },
        ],
        [handleRefreshList, editClick]
    );
    const formItem = useMemo(() => {
        let bondItems = [
            { label: '票面利率', name: 'coupon' },
            { label: '到期收益率', name: 'ytm' },
            { label: '到期日', name: 'expiry_date' },
        ];
        let pfundItems = [
            { label: '起投金额', name: 'net_return_since_inception' },
            { label: '近一年收益率', name: 'yearly_return' },
            { label: '成立至今收益率', name: 'net_total_return' },
            { label: '近一年波动率', name: 'yearly_volatility' },
        ];
        let reitsItems = [
            { label: '分红收益率', name: 'div_yld' },
            { label: '单位净值', name: 'nav' },
            { label: '资产面积', name: 'lease_flr_area' },
            { label: '入住率', name: 'occupy_rate' },
        ];
        // 成交价格、敲出价、执行价、保证期（/天）、杠杆（无、双倍）、每日买入 / 卖出股数、买入天数、生效日期
        let aqItems = [
            { label: '挂钩标的', name: '' },
            { label: '敲出价', name: 'strike_price' },
            { label: '执行价', name: 'ko_price' },
            { label: '保证期（/天）', name: 'guaranteed_period_days' },
            { label: '杠杆（无、双倍）', name: 'leverage' },
            { label: '每日买入 / 卖出股数', name: 'day_volume' },
            { label: '买入天数', name: 'fixing_days' },
            { label: '生效日期', name: 'effective_date' },
        ];
        let a;
        switch (addProductType) {
            case 'bond':
                a = bondItems;
                break;
            case 'pfund':
                a = pfundItems;
                break;
            case 'reits':
                a = reitsItems;
                break;
            default:
                a = aqItems;
                break;
        }
        return a.map((item) => {
            if (item.label === '挂钩标的') {
                return (
                    <SearchProductFormItem
                        formName="标的股票"
                        searchType={['stock']}
                        key="stock"
                        assetListCallback={(originAsset) => setCurPostion(originAsset)}
                    />
                );
            }
            if (item.label === '生效日期') {
                return (
                    <Form.Item
                        label={item.label}
                        name={item.name}
                        key={item.name}
                        rules={[{ required: true, message: '请选择生效日期' }]}
                    >
                        <DatePicker
                            inputReadOnly={IsMobileMachine}
                            format="YYYY-MM-DD"
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                );
            }
            if (item.label.indexOf('杠杆') !== -1) {
                return (
                    <Form.Item
                        label={item.label}
                        name={['ext_fields', item.name]}
                        key={item.name}
                        rules={[{ required: true }]}
                    >
                        <Radio.Group>
                            <Radio value={'1'}>无</Radio>
                            <Radio value={'2'}>有</Radio>
                        </Radio.Group>
                    </Form.Item>
                );
            }
            return item.label === '到期日' ? (
                <Form.Item
                    label="到期日"
                    key={item.name}
                    name={['ext_fields', item.name]}
                    rules={[{ required: true, message: '请选择交易日期' }]}
                >
                    <DatePicker
                        defaultPickerValue={date!}
                        inputReadOnly={IsMobileMachine}
                        onChange={(e) => {
                            setDate((oe) => {
                                return e ?? oe;
                            });
                        }}
                        format="YYYY-MM-DD"
                        style={{ width: '100%' }}
                    />
                </Form.Item>
            ) : (
                <Form.Item
                    label={item.label}
                    name={['ext_fields', item.name]}
                    rules={[{ required: true, message: '请输入' + item.label }]}
                >
                    <Input
                        style={{ width: '100%' }}
                        addonAfter={item.label.indexOf('率') !== -1 ? '%' : ''}
                        type="number"
                    />
                </Form.Item>
            );
        });
    }, [addProductType, IsMobileMachine, date]);

    const onFinish = useCallback(
        (res) => {
            if (buttonEnble === false) {
                return;
            }
            let map: { [key: string]: string } = {};
            let reqParams = res.custom_fields;
            if (reqParams) {
                reqParams.forEach((element: any) => {
                    map[element.key] = element.value;
                });
            }

            setButtonEnble(false);
            if (res.addType === 1) {
                newSecurity({ isin: res.isin })
                    .then((res) => {
                        if (res?.data) {
                            message.success('添加成功');
                            resetData();
                            setVisible(false);
                            handleRefreshList();
                        }
                    }, showError)
                    .finally(() => {
                        setButtonEnble(true);
                    });
            } else {
                let params = {
                    ...res,
                    expiry_date: date?.format('YYYY-MM-DD'),
                };
                params.ext_fields = {
                    ...params.ext_fields,
                    effective_date: res.effective_date?.format('YYYY-MM-DD'),
                };

                if (currentAsset) {
                    params.isin = currentAsset.isin;
                    params.sub_asset_class = currentAsset.sub_asset_class;
                }

                if (curPostion) {
                    params.ext_fields = {
                        ...params.ext_fields,
                        underlying: curPostion.isin,
                    };
                }
                params.custom_fields = map;
                addSecurityWithISIN(params)
                    .then(() => {
                        message.success(currentAsset === null ? '添加成功' : '更新成功');
                        resetData();
                        setVisible(false);
                        handleRefreshList();
                    }, showError)
                    .finally(() => {
                        setButtonEnble(true);
                    });
            }
        },
        [date, handleRefreshList, resetData, currentAsset, buttonEnble, curPostion]
    );

    return (
        <>
            <Modal
                visible={visible}
                forceRender
                closable={false}
                title={currentAsset === null ? '添加产品' : '更新产品'}
                footer={null}
                maskClosable={false}
            >
                <Form
                    form={form}
                    labelWrap
                    labelAlign="left"
                    initialValues={{
                        ...formParams,
                        custom_fields: customFields,
                        ext_fields: { leverage: '1', ...(currentAsset?.ext_fields ?? {}) },
                    }}
                    onFinish={onFinish}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                >
                    {!currentAsset && (
                        <Form.Item label="添加方式" name="addType" rules={[{ required: true }]}>
                            <Radio.Group
                                onChange={(value) => {
                                    setAddType(value.target.value);
                                }}
                            >
                                <Radio value={1}>ISIN</Radio>
                                <Radio value={2}>手动录入字段</Radio>
                            </Radio.Group>
                        </Form.Item>
                    )}
                    {addType === 1 && (
                        <Form.Item
                            label="ISIN"
                            name="isin"
                            rules={[{ required: true, message: '请输入资产ISIN' }]}
                        >
                            <Input style={{ width: '100%' }} />
                        </Form.Item>
                    )}
                    {addType === 2 && (
                        <>
                            {!currentAsset && (
                                <Form.Item
                                    label="产品类型"
                                    name="sub_asset_class"
                                    rules={[{ required: true }]}
                                >
                                    <Radio.Group
                                        onChange={(value) => {
                                            setAddProductType(value.target.value);
                                        }}
                                    >
                                        <Radio value={'bond'}>债券</Radio>
                                        <Radio value={'reits'}>REITs</Radio>
                                        <Radio value={'pfund'}>基金</Radio>
                                        <Radio value={'AQ'}>AQ</Radio>
                                        <Radio value={'DQ'}>DQ</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            )}
                            <Form.Item
                                label="产品名称"
                                name="name"
                                rules={[{ required: true, message: '请输入产品名称' }]}
                            >
                                <Input style={{ width: '100%' }} />
                            </Form.Item>
                            {(addProductType === 'bond' || addProductType === 'reits') && (
                                <Form.Item
                                    label="产品价格"
                                    name="price"
                                    rules={[{ required: true, message: '请输入产品价格' }]}
                                >
                                    <Input type="number" style={{ width: '100%' }} />
                                </Form.Item>
                            )}
                            {formItem}
                            {!(addProductType === 'AQ' || addProductType === 'DQ') && (
                                <Form.List name="custom_fields">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <Form.Item
                                                    label={`自定义字段${index + 1}`}
                                                    required
                                                    key={field.key}
                                                    style={{
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <Form.Item
                                                        noStyle
                                                        key={`key${field.key}`}
                                                        name={[field.name, 'key']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '请输入自定义字段名',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            style={{
                                                                display: 'inline-block',
                                                                width: '40%',
                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...field}
                                                        noStyle
                                                        name={[field.name, 'value']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '请输入自定义字段值',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            style={{
                                                                display: 'inline-block',
                                                                width: '40%',
                                                                marginLeft: '10px',
                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        style={{
                                                            display: 'inline-block',
                                                            width: '10%',
                                                            marginLeft: '10px',
                                                        }}
                                                    >
                                                        <MinusCircleOutlined
                                                            style={{
                                                                fontSize: '20px',
                                                                color: 'red',
                                                            }}
                                                            onClick={() => remove(field.name)}
                                                        />
                                                    </Form.Item>
                                                </Form.Item>
                                            ))}

                                            <Button
                                                type="dashed"
                                                style={{ width: '60%', marginLeft: '20%' }}
                                                onClick={() => add()}
                                                block
                                                icon={<PlusOutlined />}
                                            >
                                                增加自定义字段
                                            </Button>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    textAlign: 'center',
                                                    fontSize: '12px',
                                                    color: '#666',
                                                    margin: '10px 0px 20px',
                                                }}
                                            >
                                                自定义字段展示规则：输入什么展示什么，必要时记得注明单位或符号哦
                                            </div>
                                        </>
                                    )}
                                </Form.List>
                            )}
                        </>
                    )}

                    <Form.Item style={{ marginTop: '8px' }} wrapperCol={{ offset: 6 }}>
                        <Button type="primary" htmlType="submit" loading={!buttonEnble}>
                            确定
                        </Button>
                        <Button
                            onClick={() => {
                                setVisible(false);
                            }}
                            style={{ marginLeft: '30%' }}
                        >
                            取消
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: '40px',
                    marginTop: '27px',
                }}
            >
                <Search
                    placeholder="请输入产品名，别名，ISIN"
                    onSearch={onSearch}
                    enterButton
                    style={{ width: 317 }}
                />
                <Button
                    type="primary"
                    onClick={() => {
                        uploadEventTrack(EventList.EditProductAdd, 2);
                        setCurrentAsset(null);
                        resetData();
                        setVisible(true);
                    }}
                >
                    添加产品
                </Button>
            </div>
            <div style={{ margin: '24px 0', fontSize: 16 }}>搜索结果：</div>
            <div className="ag-theme-alpine CustomerManage__TableContainer">
                <AgGridReact
                    animateRows
                    columnDefs={colDefs}
                    defaultColDef={{ resizable: true, minWidth: 68, suppressMenu: true }}
                    rowData={dataList}
                    domLayout="autoHeight"
                    enableCellTextSelection
                    ensureDomOrder
                />
            </div>
        </>
    );
};

export default EditProduct;
