import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getUserAllAccount } from '../../../service/requests/HistoricalTransactions';
import { Form, Select } from 'antd';
import { UserAccountDetailInfo } from '../../../service/requests/HistoricalTransactions';
import { css } from '@emotion/css';
const { Option } = Select;

const SelectAccountFormItem = ({
    width,
    customer_id,
    selectCallback,
    secondName,
    secondLabel,
}: {
    width?: string;
    customer_id: number;
    selectCallback: (bankList: UserAccountDetailInfo, index?: number) => void;
    secondName?: string;
    secondLabel?: string;
}) => {
    const [bankList, setBankList] = useState<UserAccountDetailInfo[]>([]);
    const selecIndex = useRef<number>(0);
    useEffect(() => {
        if (customer_id !== 0) {
            getUserAllAccount({ customer_id: customer_id.toString() }).then((res) => {
                setBankList(res.data);
                selectCallback(res.data[selecIndex.current], selecIndex.current);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer_id]);
    const onChange = useCallback(
        (value) => {
            selecIndex.current = value;
            selectCallback(bankList[value], value);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [bankList, selectCallback]
    );

    const styles = css`
        border-bottom: 1px solid #e8e8e8;
    `;
    return (
        <Form.Item
            label={secondLabel ?? '交易账户'}
            name={secondName ?? 'account_index'}
            style={{ width: width ?? '100%' }}
            rules={[{ required: true, message: '请选择银行' }]}
        >
            <Select onSelect={onChange}>
                {bankList.map((item, index) => (
                    <Option className={styles} value={index} key={item.account_id}>
                        {item.vendor_sub_account_id ?? ''}
                        <br />
                        {item.bank_name + ' - ' + item.account_name}
                    </Option>
                ))}
            </Select>
        </Form.Item>
    );
};
export default SelectAccountFormItem;
