import { orange } from '@ant-design/colors';
import { WarningFilled } from '@ant-design/icons';
import { Alert } from 'antd';
import React from 'react';
import { AuthorityTreeData } from './requests';

interface DissociatedOperationListProps {
    operations: AuthorityTreeData['dissociatedOperations'];
}

export default function DissociatedOperationList(props: DissociatedOperationListProps) {
    const { operations } = props;
    return (
        <Alert
            type="warning"
            message={
                <>
                    <WarningFilled
                        style={{ color: orange[5], marginRight: '8px', fontSize: '20px' }}
                    />
                    <span>警告：存在尚未绑定权限的API，请考虑为这些API设置相关权限</span>
                </>
            }
            description={
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        maxHeight: '30vh',
                        overflow: 'scroll',
                    }}
                >
                    {operations.map((item) => (
                        <div
                            style={{ display: 'flex', width: '50%', minWidth: '480px' }}
                            key={item.uri}
                        >
                            <span style={{ width: '240px' }}>{item.name}</span>
                            <span
                                style={{
                                    flex: 1,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}
                            >
                                {item.uri}
                            </span>
                        </div>
                    ))}
                </div>
            }
        />
    );
}
