import { ModalProps, ModalFuncProps } from 'antd/lib/modal';
import { ReactNode } from 'react';
import { Modal } from 'antd';
type combine = ModalProps & ModalFuncProps;
type IConfig = Pick<ModalProps, 'title' | 'width' | 'onOk' | 'footer'>;
export type errorModalType = (errorText: ReactNode, config?: IConfig) => any;
export const error: errorModalType = (errorText, configs) => {
    const { title, width, onOk, footer } = configs || {};
    const config: combine = {
        title: title || `错误信息`,
        okText: `关闭`,
        className: 'modal-small-error-wrap',
        content: errorText,
    };

    if (width) {
        config.width = width;
    }
    if (onOk) {
        config.onOk = onOk;
    }
    if (footer) {
        config.footer = footer;
    }
    Modal.error(config);
};
