import axios from '../../service/tools';
import {
    PositionAssetDetail,
    PositionAssetList,
    PositionAssetSummary,
    PositionFamilyAsset,
} from './type';
export const mergeTables = () => {};
export const queryManageFamilyList = () => {
    return axios.post<{
        family_positions: PositionFamilyAsset[];
        total_family_position: PositionFamilyAsset;
    }>('/gk/v1/position/managed_family/list');
};
export const queryAssetSummary = (data?: { account_id?: string; client_id?: string }) => {
    return axios.post<PositionAssetSummary>('/gk/v1/position/managed_asset/summary', { data });
};
export const queryAssetList = (data?: { account_id?: string; client_id?: string }) => {
    return axios.post<PositionAssetList[]>('/gk/v1/position/managed_asset/list', { data });
};
export const queryPositionDetail = (data?: { account_id?: string; isin?: string }) => {
    return axios.post<PositionAssetDetail>('/gk/v1/position/detail', { data });
};
