import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Button, Pagination, Tooltip } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TooltipCell } from '../../Activity/DailyConfig';
import { getAlterNativeSingInfo } from '../../../service/requests/Sing';
import { showError } from '../../RecommendationComponent/util';
import { SignScene } from '../request';
const NEWSPAGESIZE = 15;
const AlterNativeSign = () => {
    const [offset, setOffset] = useState(0);
    const [totalNum, setTotalNum] = useState(0);
    const [infoList, setInfoList] = useState<any[]>([]);
    const handlePaginationChange = useCallback((page: number) => {
        setOffset((page - 1) * NEWSPAGESIZE);
    }, []);
    const columnDefs = useMemo<ColDef[]>(
        () => [
            {
                headerName: '文件链接',
                field: 'sign_url',
                width: 240,
                cellRenderer: (params: ICellRendererParams) => (
                    <Tooltip title={params.value}>
                        <Button
                            type="link"
                            style={{ paddingLeft: '0', color: 'blue', textDecoration: 'underline' }}
                            onClick={() => window.open(params.value)}
                        >
                            {params.value}
                        </Button>
                    </Tooltip>
                ),
            },
            {
                headerName: '签署模板',
                field: 'template_title',
                width: 100,
                cellRenderer: (params: ICellRendererParams) => <TooltipCell text={params.value} />,
            },
            {
                headerName: '签署人',
                field: 'customer_id',
                width: 200,
                cellRenderer: (params: ICellRendererParams) => (
                    <TooltipCell text={`${params.data.name}-${params.value}`} />
                ),
            },

            {
                headerName: '创建时间',
                width: 200,
                field: 'created_at',
                cellRenderer: (params: ICellRendererParams) => {
                    if (!params.value) return <div />;
                    let timeStr = moment(params.value).format('YYYY-MM-DD HH:mm:ss');
                    return <div>{timeStr}</div>;
                },
            },
            {
                headerName: '签署状态',
                field: 'signed_status',
                width: 100,
                cellRenderer: (params: ICellRendererParams) => (
                    <div style={{ color: params.value === 1 ? 'greenyellow' : '' }}>
                        {params.value === 1 ? '已签署' : '待签署'}
                    </div>
                ),
            },
            {
                headerName: '签署时间',
                minWidth: 200,
                flex: 1,
                field: 'signed_at',
                cellRenderer: (params: ICellRendererParams) => {
                    if (!params.value) return <div />;
                    let timeStr = moment(params.value).format('YYYY-MM-DD HH:mm:ss');
                    return <div>{timeStr}</div>;
                },
            },
        ],
        []
    );
    useEffect(() => {
        getAlterNativeSingInfo({
            limit: NEWSPAGESIZE,
            offset,
            scene: SignScene.OtherInvestment,
        }).then((res) => {
            setTotalNum(res.data.total_count);
            setInfoList(res.data.document_list);
        }, showError);
    }, [offset]);
    return (
        <>
            <div className="ag-theme-alpine">
                <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={{ resizable: true, width: 96, minWidth: 68, suppressMenu: true }}
                    rowData={infoList}
                    domLayout="autoHeight"
                    className="CustomerList__Table"
                />
            </div>
            {totalNum > 0 && infoList.length > 0 ? (
                <Pagination
                    onChange={handlePaginationChange}
                    total={totalNum}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 30,
                    }}
                    pageSize={NEWSPAGESIZE}
                    defaultCurrent={1}
                    showSizeChanger={false}
                />
            ) : null}
        </>
    );
};

export default AlterNativeSign;
