import axios from '../../../service/tools';

export interface AuthorityOperation {
    id: number;
    group: string;
    name: string;
    uri: string;
    localOnly: boolean;
    whiteList: boolean;
    loginOnly: boolean;
    parmClass: string;
    parmName: string;
    parmType: string;
    dataLinkId: number;
    authorityId: number;
}

export interface Authority {
    id: number;
    name: string;
    menu: boolean;
    weight: number;
    entrance: string;
    parentId: number;
    operations: Array<AuthorityOperation>;
    children?: Authority[];
}

export interface AuthorityTreeData {
    authorities: Array<Authority>;
    dissociatedOperations: Array<AuthorityOperation>;
}

export const getAuthorityTree = () => {
    return axios.post<AuthorityTreeData>('/v1/admin/auth/authority/tree');
};

export const createMenuAuthority = (data: { name: string; entrance: string; parentId: number }) => {
    return axios.post('/v1/admin/auth/authority/create', { data });
};

export const editMenuAuthority = (data: {
    id: number;
    name: string;
    entrance: string;
    parentId: number;
}) => {
    return axios.post('/v1/admin/auth/authority/update', { data });
};

export const deleteMenuAuthority = (data: { id: number }) => {
    return axios.post('/v1/admin/auth/authority/delete', { data });
};

export const setMenuOrder = (data: { parentId: number; childIds: number[] }) => {
    return axios.post('/v1/admin/auth/authority/sort', { data });
};

export const setMenuOperations = (data: { id: number; operationIds: number[] }) => {
    return axios.post('/v1/admin/auth/authority/operation/reset', { data });
};
