import React, { useMemo, useState } from 'react';
import { Button, Modal } from 'antd';
import AddRealAccount from './components/AddRealAccount';
import { stopPropagation } from '../../RecommendationComponent/util';
import { useCallback } from 'react';
import { UserList } from '../../../service/type';
import { useHistory } from 'react-router-dom';
import { EventList, uploadEventTrack } from '../../../service/requests/EventTrack';

const PanelHeader: React.FC<{
    userInfor: UserList[0];
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    setRefreshList: () => void;
    curPage: number;
}> = ({ userInfor, setRefreshList, curPage }) => {
    const [addVisible, setAddVisible] = useState(false);
    const handleAddClick: React.MouseEventHandler<HTMLElement> = useCallback((event) => {
        event.stopPropagation();
        uploadEventTrack(EventList.PositionEditorAddAcc, 2);
        setAddVisible(true);
    }, []);

    const history = useHistory();
    const title = useMemo(() => {
        if (userInfor.relative_customer_name) {
            return `${userInfor.customer_id} - ${userInfor.name} && ${userInfor.relative_customer_id} - ${userInfor.relative_customer_name}`;
        } else {
            return `${userInfor.customer_id} - ${userInfor.name}`;
        }
    }, [userInfor]);
    return (
        <div className="header-wrapper">
            <div onClick={stopPropagation}>
                <Modal
                    title={`添加账户: ${userInfor.mobile_number}`}
                    destroyOnClose
                    footer={null}
                    visible={addVisible}
                    okText="确认"
                    cancelText="取消"
                    onCancel={() => setAddVisible(false)}
                    centered
                    width={560}
                >
                    <AddRealAccount
                        setRefreshList={setRefreshList}
                        userUid={userInfor.uid}
                        close={() => setAddVisible(false)}
                        copy_id={0}
                    />
                </Modal>
            </div>
            <span className="title">{title}</span>
            <Button
                className="header-button"
                onClick={() => {
                    uploadEventTrack(EventList.PositionEditorAdjustAsset, 2);
                    history.push({
                        pathname: `/app/customer/positionEditor/adjustAsset/${userInfor.uid}/${userInfor.customer_id}/${userInfor.name}`,
                        state: { curPage },
                    });
                }}
            >
                持仓调整
            </Button>
            <Button className="header-button" onClick={handleAddClick}>
                添加账户
            </Button>
        </div>
    );
};

export default PanelHeader;
