import axios from '../../service/tools';
import { AccountManageType } from '../Transaction/type';
import {
    BankFlow,
    ReconciliationDetail,
    ReconciliationRes,
    SystemFlow,
    VerifiedStatus,
} from './type';
export const requestList = (data: {
    account_manage_type: AccountManageType;
    account_id?: string;
    status: VerifiedStatus[];
    page_size: number;
    current_page: number;
}) => {
    return axios.post<ReconciliationRes>('gk/v1/reconciliation/list', { data });
};

export const getUnmatchSystemFlow = (data: {
    account_id: string;
    asset_name: string;
    asset_type: number;
    isin: string;
    currency: string;
}) => {
    return axios.post<{ system_flows: SystemFlow[] }>('gk/v1/flow/system/unmatched', {
        data,
    });
};
export const getReconciliationDetail = (data: { reconciliation_id: number }) => {
    return axios.post<{ reconciliation: ReconciliationDetail }>('gk/v1/reconciliation/detail', {
        data,
    });
};

export const getUnconfirmedBankFlow = (data: {
    account_id: string;
    asset_name: string;
    asset_type: number;
    isin: string;
    currency: string;
}) => {
    return axios.post<{ bank_flows: BankFlow[] }>('gk/v1/flow/bank/unconfirmed', {
        data,
    });
};
export const saveReconciliationDetail = (data: { reconciliation: ReconciliationDetail }) => {
    return axios.post<{ reconciliation: ReconciliationDetail }>('gk/v1/reconciliation/save', {
        data,
    });
};

export const verifyReconciliation = (data: { reconciliation: ReconciliationDetail }) => {
    return axios.post<{ reconciliation: ReconciliationDetail }>('gk/v1/reconciliation/verify', {
        data,
    });
};

export const unverifyReconciliation = (data: { reconciliation_id: number }) => {
    return axios.post<{ reconciliation: ReconciliationDetail }>(
        'gk/v1/reconciliation/status/update',
        {
            data: {
                ...data,
                status: VerifiedStatus.Unverified,
            },
        }
    );
};
