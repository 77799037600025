import React, { useEffect } from 'react';
import styles from './index.module.scss';
import { Button, Modal, Pagination, Tooltip, message } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { BannerListType, bannerList, updateOnlineStatus } from './type';
import moment from 'moment-timezone';
import { useLatest, useRequest } from 'ahooks';
import usePageState from '@src/utils/Hooks/usePageState';
import { useHistory } from 'react-router-dom';
import { ArrowDownOutlined, ArrowUpOutlined, BellOutlined, EditOutlined } from '@ant-design/icons';
import SyncArticlePush from './SyncArticlePush';
import { SyncPushStatusType } from '../SyncArticle/type';
const BannerConfig = () => {
    const { dispacth, selector } = usePageState();
    const [currentPage, setCurrentPage] = React.useState(selector?.page ?? 1);
    const [pageSize, setPageSize] = React.useState(selector?.page_size ?? 20);
    const latestPageSize = useLatest(pageSize);
    const latestCurrentPage = useLatest(currentPage);
    const [pushModalVisible, setPushModalVisible] = React.useState(false);
    const [currentArticle, setCurrentArticle] = React.useState<BannerListType | null>(null);
    const [updateOnlineLoading, setUpdateOnlineLoading] = React.useState(false);
    const latestCurrentArticle = useLatest(currentArticle);
    const histort = useHistory();
    const { data: acticleList, run, params, refresh } = useRequest(bannerList, {
        defaultParams: [
            {
                current_page: currentPage,
                page_size: pageSize,
            },
        ],
    });
    //定时3秒同步一次列表接口
    useEffect(() => {
        const timer = setInterval(() => {
            run({
                ...params[0]!,
                current_page: latestCurrentPage.current,
                page_size: latestPageSize.current,
            });
        }, 5000);
        return () => {
            clearInterval(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const changeOnlineStatus = (record: BannerListType) => {
        setUpdateOnlineLoading(true);
        updateOnlineStatus({
            banner_id: record.id,
            online_status: record.online === 1 ? 2 : 1,
        })
            .then((res) => {
                refresh();
            })
            .catch((err) => {
                message.error(err.msg);
            })
            .finally(() => {
                setUpdateOnlineLoading(false);
            });
    };
    const columns: ColumnsType<BannerListType> = [
        {
            title: '上线',
            dataIndex: 'online',
            width: '50px',
            render(value: any, record: any) {
                return (
                    <span
                        style={{
                            color: value === 1 ? 'green' : 'gray',
                        }}
                    >
                        {value === 1 ? '是' : '否'}
                    </span>
                );
            },
        },
        {
            title: '同步时间',
            dataIndex: 'updated_at',
            width: '200px',
            render(value: any, record: any) {
                return moment(value).format('YYYY-MM-DD HH:mm:ss');
            },
        },
        {
            title: '标题',
            dataIndex: 'title',
            width: '250px',
        },
        {
            title: 'Push状态',
            dataIndex: 'push_task_status',
            width: '80px',
            render(value) {
                return value?.desc || '-';
            },
        },

        {
            title: 'id',
            dataIndex: 'id',
            width: '60px',
        },
        {
            title: '操作人',
            dataIndex: ['updater', 'name'],
        },
        {
            fixed: 'right',
            title: '操作',
            key: 'action',
            width: '200px',
            render(record: BannerListType) {
                return (
                    <>
                        <Tooltip title="编辑">
                            <Button icon={<EditOutlined />} />
                        </Tooltip>

                        <Tooltip title={record.online === 1 ? '下线' : '上线'}>
                            <Button
                                loading={updateOnlineLoading}
                                icon={
                                    record.online === 1 ? (
                                        <ArrowDownOutlined />
                                    ) : (
                                        <ArrowUpOutlined />
                                    )
                                }
                                onClick={(e) => {
                                    e.stopPropagation();
                                    Modal.confirm({
                                        title: `是否确认${
                                            record.online === 1 ? '下线' : '上线'
                                        }该Banner？`,
                                        content:
                                            record.online === 1
                                                ? '下线后此Banner将在列表中保留，可再次上线'
                                                : '上线后此Banner将展示在App首页Banner位',
                                        okText: '确认',
                                        cancelText: '取消',
                                        onOk() {
                                            changeOnlineStatus(record);
                                        },
                                    });
                                }}
                            />
                        </Tooltip>

                        <Tooltip title="App Push">
                            <Button
                                disabled={
                                    record.push_task_status?.code === SyncPushStatusType.推送中 ||
                                    record.push_task_status?.code === SyncPushStatusType.等待中
                                }
                                icon={<BellOutlined />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setPushModalVisible(true);
                                    setCurrentArticle(record);
                                }}
                            />
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <div className={styles.container}>
            <Modal
                visible={pushModalVisible && latestCurrentArticle.current !== null}
                title="App Push"
                width={800}
                destroyOnClose
                className={styles.pushModal}
                footer={null}
                onCancel={() => {
                    setPushModalVisible(false);
                    setCurrentArticle(null);
                }}
            >
                <SyncArticlePush
                    setVisible={setPushModalVisible}
                    article={latestCurrentArticle.current!}
                />
            </Modal>
            <div className={styles.sync}>
                <Button
                    type="primary"
                    onClick={() => {
                        histort.push('/app/activity/banner/detail');
                    }}
                >
                    新建Banner
                </Button>
            </div>

            <div className={styles.table}>
                <Table
                    columns={columns}
                    key={'id'}
                    size="small"
                    pagination={false}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                histort.push('/app/activity/banner/detail?id=' + record.id);
                            },
                        };
                    }}
                    dataSource={acticleList?.data.banners}
                    scroll={{ x: 1000, y: 'calc(100vh - 260px)' }}
                />
                {/* <div className={styles.titleTipIcon}>
                    <InfoCircleFilled />
                    同步预计等待时间3到5分钟 | {`上次列表更新时间:${currentTime}`}
                </div> */}
            </div>

            <Pagination
                className={styles.page}
                total={acticleList?.data.total_count}
                showTotal={(total, range) => {
                    return `总条数：${total}`;
                }}
                pageSize={pageSize}
                onShowSizeChange={(current, size) => {
                    dispacth({ page_size: size });
                    setPageSize(size);
                }}
                onChange={(page, pageSize) => {
                    setCurrentPage(page);
                    dispacth({ page, page_size: pageSize });
                    setPageSize(pageSize || 20);
                    run({
                        ...params[0]!,
                        current_page: page,
                        page_size: pageSize,
                    });
                }}
                showSizeChanger
                current={currentPage}
            />
        </div>
    );
};

export default BannerConfig;
