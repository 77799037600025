import { AuthorityTableRow, DefaultModalProps } from '.';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, message, Modal, Tooltip, Transfer } from 'antd';
import { AuthorityOperation, setMenuOperations } from './requests';
import { TransferItem } from 'antd/lib/transfer';
import { showError } from '../../RecommendationComponent/util';

interface EditAPIModalProps extends DefaultModalProps {
    menu?: AuthorityTableRow;
    unlinkedOperations?: AuthorityOperation[];
}

export default function EditAPIModal({
    menu,
    setVisible,
    refreshData,
    unlinkedOperations,
    ...others
}: EditAPIModalProps) {
    const transferOptions = useMemo<TransferItem[]>(() => {
        const options: TransferItem[] = [];
        menu?.operations?.forEach((operation) =>
            options.push({
                key: operation.id.toString(),
                title: operation.name,
                description: operation.uri,
            })
        );
        unlinkedOperations?.forEach((operation) =>
            options.push({
                key: operation.id.toString(),
                title: operation.name,
                description: operation.uri,
            })
        );
        return options;
    }, [menu, unlinkedOperations]);

    const [targetKeys, setTargetKeys] = useState<string[]>([]);

    useEffect(() => {
        if (menu) {
            setTargetKeys(menu.operations.map((item) => item.id.toString()));
        }
    }, [menu]);

    const handleSubmit = useCallback(() => {
        const operationIds = targetKeys.map((item) => Number(item));
        setMenuOperations({ id: menu?.id ?? 0, operationIds }).then(() => {
            message.success('设置成功');
            refreshData();
            setVisible(false);
        }, showError);
    }, [targetKeys, menu, refreshData, setVisible]);

    return (
        <Modal
            {...others}
            centered
            destroyOnClose
            title="编辑权限API"
            footer={null}
            width={600}
            onCancel={() => setVisible(false)}
        >
            <Transfer
                titles={['可选', '已选']}
                dataSource={transferOptions}
                targetKeys={targetKeys}
                onChange={(targetKeys) => setTargetKeys(targetKeys)}
                render={(option) => (
                    <Tooltip title={option.description} placement="right">
                        <span style={{ display: 'inline-block', width: '100%' }}>
                            {option.title}
                        </span>
                    </Tooltip>
                )}
                style={{ justifyContent: 'center' }}
                listStyle={{ width: 240, height: 400 }}
            />
            <div
                style={{
                    marginTop: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                    columnGap: '64px',
                }}
            >
                <Button onClick={() => setVisible(false)}>取消</Button>
                <Button type="primary" onClick={handleSubmit}>
                    提交
                </Button>
            </div>
        </Modal>
    );
}
