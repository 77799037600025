import React, { useMemo, useRef } from 'react';
import styles from './index.module.less';
import {
    TransFormProps,
    GinkgoTransFormRefType,
    TransactionType,
    TransStatus,
    TransactionInfo,
    TransactionOrderType,
} from '../../type';
import TransFormFooter from './TransFormFooter';
import DepositForm from './Forms/DepositForm';
import TransFormHeader from './TransFormHeader';
import TransferForm from './Forms/TransferForm';
import { useRequest } from 'ahooks';
import { changeStatus, deleteTrans, queryTransDetail, updateTrans } from '../../request';
import { Modal, Spin } from 'antd';
import { showError } from '../../../RecommendationComponent/util';
import LoanForm from './Forms/LoanForm';
import BondForm from './Forms/BondForm';
import ExchangeForm from './Forms/ExchangeForm';
import EquitiesForm from './Forms/EquitiesForm';
import ElnForm from './Forms/ElnForm';
import AQForm from './Forms/AQForm';
import FCNForm from './Forms/FCNForm';
import OptionForm from './Forms/OptionsForm';
import AdjustmentForm from './Forms/AdjustmentForm';
import FundForm from './Forms/FundForm';
import FundRedeemForm from './Forms/FundRedeemForm';
import FundContributeForm from './Forms/FundContributeForm';
import FundDistributeForm from './Forms/FundDistributeForm';
const TransForm = (data: TransFormProps & { onClose: () => void }) => {
    const formRef = useRef<GinkgoTransFormRefType>(null);
    const [loading, setLoading] = React.useState(false);
    const { data: transInfo, refresh } = useRequest(queryTransDetail, {
        defaultParams: [
            {
                trans_id: data.trans_id,
            },
        ],
        onError(e: any, params) {
            showError({ msg: e.msg });
        },
        onBefore() {
            setLoading(true);
        },
        onFinally() {
            setLoading(false);
        },
    });
    const configData: TransactionInfo | undefined = useMemo(() => {
        if (!transInfo?.data.transaction) return undefined;
        if (transInfo?.data.transaction.type.code === TransactionType.Transfer) {
            let { assets, positions } = transInfo?.data.transaction;
            if (assets && positions) {
                assets = assets.map((item: any) => {
                    let finder = positions.find((position: any) => position.isin === item.isin);
                    return {
                        ...item,
                        asset_name: finder?.asset.name,
                        totalAmount: finder?.amount ?? finder?.qty,
                    };
                });
                return {
                    ...transInfo?.data.transaction,
                    assets,
                };
            } else {
                return transInfo.data.transaction;
            }
        }
        return transInfo?.data.transaction;
    }, [transInfo]);
    const content = () => {
        if (!configData) return null;
        switch (configData.type.code) {
            case TransactionOrderType['Place Deposit']:
                return <DepositForm {...configData} ref={formRef} />;
            case TransactionOrderType.Transfer:
                return <TransferForm {...configData} ref={formRef} />;
            case TransactionOrderType['Take Loan']:
                return <LoanForm {...configData} ref={formRef} />;
            case TransactionOrderType['Sell Bond']:
            case TransactionOrderType['Buy Bond']:
                return <BondForm {...configData} ref={formRef} />;
            case TransactionOrderType['Foreign Exchange']:
                return <ExchangeForm {...configData} ref={formRef} />;
            case TransactionOrderType['Buy Equities']:
            case TransactionOrderType['Sell Equities']:
                return <EquitiesForm {...configData} ref={formRef} />;
            case TransactionOrderType['Buy ELN']:
                return <ElnForm transaction={configData} ref={formRef} />;
            case TransactionOrderType['Buy FCN']:
                return <FCNForm {...configData} ref={formRef} />;
            case TransactionOrderType['Buy AQ']:
            case TransactionOrderType['Buy DQ']:
                return <AQForm {...configData} ref={formRef} />;
            case TransactionOrderType['Buy Option']:
            case TransactionOrderType['Sell Option']:
                return <OptionForm {...configData} ref={formRef} />;
            case TransactionOrderType['Create Adjustment']:
                return <AdjustmentForm {...configData} ref={formRef} />;
            case TransactionOrderType['Subscribe Fund']:
                return <FundForm {...configData} ref={formRef} />;
            case TransactionOrderType['Fund Redeem']:
                return <FundRedeemForm {...configData} ref={formRef} />;
            case TransactionOrderType['Fund Contribute']:
                return <FundContributeForm {...configData} ref={formRef} />;
            case TransactionOrderType['Fund Distribute']:
                return <FundDistributeForm {...configData} ref={formRef} />;
            default:
                break;
        }
    };
    return (
        <div className={styles.container}>
            <Spin
                spinning={loading}
                style={{
                    maxHeight: 'inherit',
                }}
            >
                {transInfo?.data.transaction && (
                    <>
                        <TransFormHeader {...transInfo?.data.transaction} />
                        {content()}
                        <TransFormFooter
                            {...transInfo?.data.transaction}
                            onSave={() => {
                                if (formRef.current?.getFieldsValues) {
                                    formRef.current.getFieldsValues(false).then((res) => {
                                        setLoading(true);
                                        updateTrans(res)
                                            .then(() => {
                                                refresh();
                                            })
                                            .catch((err) => {
                                                showError({ msg: err.msg });
                                                setLoading(false);
                                            });
                                    });
                                }
                            }}
                            onPlaceable={() => {
                                if (formRef.current?.getFieldsValues) {
                                    formRef.current.getFieldsValues().then((res) => {
                                        setLoading(true);
                                        updateTrans({
                                            ...res,
                                            status_code: TransStatus.Placeable,
                                        })
                                            .then(() => {
                                                refresh();
                                            })
                                            .catch((err) => {
                                                showError({ msg: err.msg });
                                                setLoading(false);
                                            });
                                    });
                                }
                            }}
                            onBackCreated={() => {
                                setLoading(true);
                                changeStatus({
                                    status: TransStatus.Created,
                                    trans_id: transInfo.data.transaction.id,
                                })
                                    .then(() => {
                                        refresh();
                                    })
                                    .catch((err) => {
                                        showError({ msg: err.msg });
                                        setLoading(false);
                                    });
                            }}
                            onBackPlaceable={() => {
                                setLoading(true);
                                changeStatus({
                                    status: TransStatus.Placeable,
                                    trans_id: transInfo.data.transaction.id,
                                })
                                    .then(() => {
                                        refresh();
                                    })
                                    .catch((err) => {
                                        showError({ msg: err.msg });
                                        setLoading(false);
                                    });
                            }}
                            onTraded={() => {
                                if (formRef.current?.getFieldsValues) {
                                    formRef.current.getFieldsValues().then((res) => {
                                        setLoading(true);
                                        updateTrans({
                                            ...res,
                                            status_code: TransStatus.Traded,
                                        })
                                            .then(() => {
                                                refresh();
                                            })
                                            .catch((err) => {
                                                showError({ msg: err.msg });
                                                setLoading(false);
                                            });
                                    });
                                }
                            }}
                            onDelete={() => {
                                Modal.confirm({
                                    title: 'Are you sure to delete this record?',
                                    onOk: () => {
                                        deleteTrans({
                                            trans_id: transInfo.data.transaction.id,
                                        })
                                            .catch(showError)
                                            .finally(() => data.onClose());
                                    },
                                });
                            }}
                        />
                    </>
                )}
            </Spin>
        </div>
    );
};

export default TransForm;
