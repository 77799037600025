import axios from '../../service/tools';
export type SyncArtclePushHistory = {
    push_histories: {
        id: number;
        doc_id: number;
        created_at: number;
        creator: {
            id: number;
            name: string;
            mobile: string;
            valid: boolean;
            huobanId: number;
            feishuId: string;
            feishuOpenId: string;
            jiraId: string;
            feishuAvatar: string;
        };
    }[];
};
export enum ArticleType {
    Featured = 1,
    HouseKeeper = 2,
}
export type SyncArticleListType = {
    updated_at: number;
    doc_title: string;
    release_status: SyncArticleStatusType;
    push_status: {
        code: number;
        desc: string;
    };
    doc_url: string;
    online: 1 | 2; //是否上线
    doc_id: number;
    id: number;
    title: string;
    release_channel: number[];
    //是否精选
    feature_status?: 1 | 2;
    creator: string;
    doc_share_link: string;
};
export enum showLocationType {
    '鹿可App' = 2,
    '鹿可百科小程序' = 1,
    '鹿可百科官网' = 3,
    '链接' = 4,
}
export enum SyncArticleStatusType {
    '同步中' = 3,
    '同步完成' = 1,
    '同步失败' = 2,
}
export enum SyncPushStatusType {
    /**
     * WAITING(1, "等待中"),
PUSHING(2, "推送中"),
PUSHED(3, "已推送"),
ALL_FAILED(4, "推送失败"),
PARTIAL_FAILED(5, "部分失败"),
CANCELLED(6, "推送取消"),
    */
    '等待中' = 1,
    '推送中' = 2,
    '已推送' = 3,
    '推送失败' = 4,
    '部分失败' = 5,
    '推送取消' = 6,
}
export interface ActiclePublishReq {
    doc_url: string;
    doc_title: string;
    tags: number[];
    poster_url: string;
    type: ArticleType;
    release_channel: number[];
    featured: 1 | 2;
}
//文章精选状态, 1-置为精选, 2-移除精选
export const selectActicle = (data: { doc_id: number; feature_status: 1 | 2 }) => {
    return axios.post('/v1/admin/doc_release/update_feature_status', { data });
};
//查询文章标题
export const searchActicleTitle = (data: { doc_url: string }) => {
    return axios.post<{ doc_title: string }>('/v1/admin/doc_release/title', { data });
};

export const acticleTagsSearch = (data: { keyword: string }) => {
    return axios.post<{
        doc_tag_infos: { id: number; name: string }[];
    }>('/v1/admin/doc_release/tag/fuzzy_search', {
        data,
    });
};
export const addArticleTag = (data: { tag_name: string }) => {
    return axios.post('/v1/admin/doc_release/tag/add', { data });
};
export const acticlePublishAndSave = (data: ActiclePublishReq) => {
    return axios.post('/v1/admin/doc_release/save', { data });
};
export const acticleList = (data: {
    current_page: number;
    page_size: number;
    type: ArticleType;
}) => {
    return axios.post<{
        total_pages: number;
        current_page: number;
        total_count: number;
        doc_releases: SyncArticleListType[];
    }>('/v1/admin/doc_release/page', { data });
};

//更新文章上线状态
export const updateOnlineStatus = (data: { doc_id: number; online_status: number }) => {
    return axios.post('/v1/admin/doc_release/update_online_status', { data });
};

export const selectActiclePushHistory = (data: { doc_id: number }) => {
    return axios.post<SyncArtclePushHistory>('/v1/admin/doc_release/app_push/history', { data });
};
/**
 *     "doc_id": 0,
    "push_title": "",
    "push_body": "",
    "all_user_or_only_myself": 0,
    "push_at": 0,
    "by_user_id": "",
    "is_persistent": 0,
    "message_type": 0
*/

export const createPush = (data: {
    doc_id: number;
    title: string;
    body: string;
    is_all_users: 1 | 2; // 1-所有用户, 2-仅自己
    push_at: number;
    by_user_id: string;
    is_persistent: 1 | 2; // 通知是否存入消息中心, 1-是, 2-否, 默认1
}) => {
    return axios.post('/v1/admin/doc_release/app_push/add', { data });
};
export const getActicleDetail = (data: { doc_release_id: number }) => {
    return axios.post<{
        doc_release: {
            doc_title: string;
            doc_url: string;
            release_channel: number[];
            tags: { name: string; id: number }[];
            poster_url: string;
            featured: 1 | 2;
            tag_info_v2s: {
                id: number;
                name: string;
            }[];
        };
    }>('/v1/admin/doc_release/detail', { data });
};
export type SyncPosterType = {
    poster_path: string;
    thumbnail_path: string;
    name: string;
    created_at: number;
};
export const getPosterList = () => {
    return axios.post<{
        posters: SyncPosterType[];
    }>('/v1/admin/doc_release/default_posters');
};
