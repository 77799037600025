import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Input, Radio } from 'antd';
import styles from './index.module.scss';

enum ResidualMaturityEnum {
    all = 'all',
    custom = 'custom',
}
interface ResidualMaturityProp {
    onChange: (obj: { residual_maturity_from?: string; residual_maturity_to?: string }) => void;
}
const ResidualMaturity = ({ onChange }: ResidualMaturityProp) => {
    const [showRange, setShowRange] = useState(false);
    const [showError, setShowError] = useState(false);
    const [residualMaturityFrom, setResidualMaturityFrom] = useState('');
    const [residualMaturityTo, setResidualMaturityTo] = useState('');
    const [residualMaturity, setResidualMaturity] = useState<{
        residual_maturity_from?: string;
        residual_maturity_to?: string;
    }>();
    useEffect(() => {
        if (
            residualMaturity &&
            !residualMaturity.residual_maturity_from &&
            !residualMaturity.residual_maturity_to
        ) {
            setShowError(false);
            onChange({
                residual_maturity_from: '',
                residual_maturity_to: '',
            });
        }
        if (residualMaturity?.residual_maturity_from && residualMaturity.residual_maturity_to) {
            const { residual_maturity_from, residual_maturity_to } = residualMaturity;
            if (parseFloat(residual_maturity_to) > parseFloat(residual_maturity_from)) {
                setShowError(false);
                onChange({
                    residual_maturity_from,
                    residual_maturity_to,
                });
            } else {
                setShowError(true);
            }
        }
    }, [residualMaturity, onChange]);
    const onResidualMarurityBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement>, key: string) => {
            e.persist();
            setResidualMaturity((value) => {
                return value
                    ? {
                          ...value,
                          [key]: e.target.value,
                      }
                    : { [key]: e.target.value };
            });
        },
        []
    );
    const onResidualMaturityInputChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        setValue: Dispatch<SetStateAction<string>>
    ) => {
        e.persist();
        const v = e.target.value
            .replace(/[^\d.]/g, '')
            .replace(/\.{2,}/g, '.')
            .replace(/^\./, '')
            .replace('.', '$#$')
            .replace(/\./, '')
            .replace('$#$', '.');
        setValue(v);
    };
    return (
        <div style={{ lineHeight: '40px' }}>
            <span style={{ fontSize: '14px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.85)' }}>
                Tenor (year)：
            </span>
            <Radio.Group
                defaultValue={'all'}
                onChange={(e) => {
                    setShowRange(e.target.value === ResidualMaturityEnum.custom);
                    if (e.target.value === ResidualMaturityEnum.all) {
                        setShowError(false);
                        setResidualMaturity(undefined);
                        setResidualMaturityFrom('');
                        setResidualMaturityTo('');
                        onChange({
                            residual_maturity_from: '',
                            residual_maturity_to: '',
                        });
                    }
                }}
            >
                <Radio value={ResidualMaturityEnum.all}>全部</Radio>
                <Radio value={ResidualMaturityEnum.custom}>自定义</Radio>
            </Radio.Group>
            {showRange && (
                <span>
                    <Input
                        className={styles.customInput}
                        value={residualMaturityFrom}
                        placeholder="最短时间"
                        onBlur={(e) => {
                            onResidualMarurityBlur(e, 'residual_maturity_from');
                        }}
                        onChange={(e) => {
                            onResidualMaturityInputChange(e, setResidualMaturityFrom);
                        }}
                    />
                    至
                    <Input
                        className={styles.customInput}
                        value={residualMaturityTo}
                        placeholder="最长时间"
                        onBlur={(e) => {
                            onResidualMarurityBlur(e, 'residual_maturity_to');
                        }}
                        onChange={(e) => {
                            onResidualMaturityInputChange(e, setResidualMaturityTo);
                        }}
                    />
                    {showError && <span style={{ color: 'red' }}>请修改筛选条件</span>}
                </span>
            )}
        </div>
    );
};

export default ResidualMaturity;
