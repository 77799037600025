import md5 from 'js-md5';
import pbAxios from '../tools';

const SALT = '12138';

export function VerifyMember(code: string) {
    const hashedCode = md5(code + SALT).slice(0, 16);
    return pbAxios.post('/v1/admin/member_verify', { data: { verify_code: hashedCode } });
}

export function UpdateVerifyCode(oldCode: string, newCode: string) {
    const oldHashed = md5(oldCode + SALT).slice(0, 16);
    const newHashed = md5(newCode + SALT).slice(0, 16);
    return pbAxios.post('/v1/admin/member_verify/update', {
        data: { verify_code: newHashed, old_verify_code: oldHashed },
    });
}
