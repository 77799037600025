import { Form, Input, Button, Select, message, Radio, InputNumber } from 'antd';
import React, { useCallback, useState } from 'react';
import { addAssetAllocplanPosition, magicNewSecurity, searchSecurity } from '../../../service';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { assetList } from '../../../service/type';
import { showError } from '../../RecommendationComponent/util';

const { Option } = Select;
const AddAsset = ({
    setVisible,
    setActive,
    handleRefreshList,
    pkg_id,
}: {
    pkg_id: string;
    setActive: () => void;
    setVisible: (boolean: boolean) => void;
    handleRefreshList: () => void;
}) => {
    const [showAddProduction, setShowAddProduction] = useState(false);
    const [value, setValue] = useState('');
    const [productLibrary, setProductLibrary] = useState(1);
    const addNewSecurity = useCallback(() => {
        magicNewSecurity({ name: value, is_std: productLibrary }).then(() => {
            message.success('添加成功');
            setValue('');

            setProductLibrary(1);
            setShowAddProduction(false);
        }, showError);
    }, [productLibrary, value]);
    const onFinish = useCallback(
        (values: { hold_value: number; isin: string }) => {
            addAssetAllocplanPosition({ pkg_id, ...values }).then((res) => {
                setVisible(false);
                setActive();
                handleRefreshList();
            }, showError);
        },
        [handleRefreshList, pkg_id, setActive, setVisible]
    );

    const [assetList, setAssetList] = useState<assetList>([]);
    const handleSearch = useCallback((keyword: string) => {
        if (keyword)
            searchSecurity({ keyword }).then((res) => {
                setAssetList(res.data);
                if (res.data.length) {
                    setShowAddProduction(false);
                } else {
                    setShowAddProduction(true);
                }
            }, showError);
    }, []);
    const options = assetList.map((item) => (
        <Option key={item.isin} value={item.isin}>
            {item.name}
        </Option>
    ));

    return (
        <Form name="addasset" labelCol={{ span: 5 }} wrapperCol={{ span: 16 }} onFinish={onFinish}>
            <Form.Item
                label="资产名"
                name="isin"
                rules={[{ required: true, message: '请输入资产名' }]}
            >
                <Select
                    showSearch
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearch}
                    notFoundContent={null}
                    placeholder={'输入搜索'}
                >
                    {options}
                </Select>
            </Form.Item>
            {showAddProduction && (
                <div style={{ marginLeft: 67, marginBottom: 30 }}>
                    <div style={{ color: '#FF0000' }}>
                        未搜索到相应资产，请在下方添加至相应产品库
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                        <Radio.Group
                            onChange={(e) => setProductLibrary(e.target.value)}
                            value={productLibrary}
                        >
                            <Radio value={1}>标准产品库</Radio>
                            <Radio value={2}>非标产品库</Radio>
                        </Radio.Group>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>资产ISIN：</span>{' '}
                        <Input
                            style={{ width: 333, marginRight: 35 }}
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                        />
                        <Button onClick={addNewSecurity}>添加</Button>
                    </div>
                </div>
            )}
            <Form.Item
                label="资产市值"
                name="hold_value"
                rules={[{ required: true, message: '请输入资产市值' }]}
            >
                <InputNumber style={{ width: '100%' }} min={0} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    提交
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddAsset;
