type PseudoEnum = Record<string | number, string | number>;
export enum aqdqObservationFrequency {
    Daily = 1,
    Biweekly = 2,
    Monthly = 3,
    Maturity = 4,
}
export enum fcnCouponFrequency {
    Biweekly = 1,
    Monthly = 2,
    Bimonthly = 3,
    Quarterly = 4,
    'Semi-annually' = 5,
    Annually = 6,
}

export enum OptionSide {
    Buy = 1,
    Sell = 2,
}

export enum OptionType {
    'European Call' = 1,
    'European Put' = 2,
    'American Call' = 3,
    'American Put' = 4,
}

export const leverageOptions = [
    {
        label: 'leveraged',
        value: 2,
    },
    {
        label: 'non-leveraged',
        value: 1,
    },
];
export const guaranteedPeriodOptions = [
    {
        label: 'No Guarantee',
        value: 'No Guarantee',
    },
    {
        label: '14D',
        value: '14D',
    },
    {
        label: '1M',
        value: '1M',
    },
    {
        label: '2M',
        value: '2M',
    },
    {
        label: '3M',
        value: '3M',
    },
];
export const tenorOptions = Array.from(Array(12), (v, k) => ({
    label: `${k + 1}M`,
    value: `${k + 1}M`,
}));

const numberEnumToOptions = <Enum extends PseudoEnum>(_enum: Enum) => {
    return Object.keys(_enum)
        .filter((v) => isNaN(Number(v)))
        .map((key) => ({
            label: key,
            value: _enum[key as keyof typeof _enum],
        }));
};
export const observationFrequencyOptions = numberEnumToOptions(aqdqObservationFrequency);
export const fcnCouponFrequencyOptions = numberEnumToOptions(fcnCouponFrequency);
export const optionSideOptions = numberEnumToOptions(OptionSide);
export const optionTypeOptions = numberEnumToOptions(OptionType);
