import { HostType } from '../../../service/config';
import { multiHostAxios } from '../../../service/tools';

const axios = multiHostAxios(HostType.NOTIFY);

export const getNotifyModelList = () => {
    return axios.post<NotifyModel[]>('/v1/admin/notify/EventNode/list');
};

export const addEventNode = (data: {
    delay: number;
    eventTypeId: number;
    notifyNodeId: number;
}) => {
    return axios.post('/v1/admin/notify/EventNode/create', { data });
};

export const updateEventNode = (data: {
    id: number;
    delay: number;
    index: number;
    eventTypeId: number;
    notifyNodeId: number;
}) => {
    return axios.post('/v1/admin/notify/EventNode/update', { data });
};

export const deleteEventNode = (data: { id: number }) => {
    return axios.post('/v1/admin/notify/EventNode/delete', { data });
};

export const sortEventNodes = (data: { eventTypeId: number; eventNodeIds: number[] }) => {
    return axios.post('/v1/admin/notify/EventNode/sort', { data });
};
