import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Button, Modal, Pagination, Tag } from 'antd';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DailyConfigProp, NEWSPAGESIZE, TooltipCell } from '..';
import { MobileStateContext } from '../../../../App';
import {
    QueryDailyNews,
    DailyNewsInfo,
    DeleteDailyNews,
    DailyTag,
} from '../../../../service/requests/DailyNewsRequest';

const { confirm } = Modal;

const AllDailyNews = ({ refresh, setVisible, setCurrentSelect }: DailyConfigProp) => {
    const { isMobile } = useContext(MobileStateContext);
    const [offset, setOffset] = useState(0);
    const [totalNum, setTotalNum] = useState(0);
    const [refreshList, setRefreshList] = useState(0);

    const handleRefreshList = useCallback(() => setRefreshList((num) => num + 1), []);

    useEffect(() => {
        refresh(() => {
            handleRefreshList();
        }, 3);
    }, [handleRefreshList, refresh]);
    const [infoList, setInfoList] = useState<DailyNewsInfo['daily_news_list']>([]);
    useEffect(() => {
        QueryDailyNews({ limit: NEWSPAGESIZE, offset, type: 0 }).then((res) => {
            setInfoList(res.data.daily_news_list);
            setTotalNum(res.data.total_count);
        });
    }, [offset, refreshList]);
    const handlePaginationChange = useCallback((page: number) => {
        setOffset((page - 1) * NEWSPAGESIZE);
    }, []);

    const columnDefs = useMemo<ColDef[]>(
        () => [
            {
                headerName: '类型',
                field: 'type',
                width: 40,
                cellRenderer: (params: ICellRendererParams) => {
                    const a = ['大事', '账户', '问答'];
                    return <div>{a[params.value - 1]}</div>;
                },
            },
            {
                headerName: '标题',
                field: 'title',
                width: 180,
                cellRenderer: (params: ICellRendererParams) => <TooltipCell text={params.value} />,
            },
            {
                headerName: '副标题',
                field: 'content',
                width: 200,
                cellRenderer: (params: ICellRendererParams) => <TooltipCell text={params.value} />,
            },
            {
                headerName: '链接',
                field: 'doc_feishu_url',
                width: 200,
                cellRenderer: (params: ICellRendererParams) => <TooltipCell text={params.value} />,
            },

            {
                headerName: '发布时间',
                width: 200,
                field: 'public_at',
                cellRenderer: (params: ICellRendererParams) => {
                    let timeStr = moment(params.value).format('YYYY-MM-DD HH:mm:ss');
                    return <div>{timeStr}</div>;
                },
            },
            {
                headerName: '修改时间',
                width: 200,
                field: 'updated_at',
                cellRenderer: (params: ICellRendererParams) => {
                    let timeStr = moment(params.value).format('YYYY-MM-DD HH:mm:ss');
                    return <div>{timeStr}</div>;
                },
            },
            {
                headerName: '标签',
                field: 'look_tags',
                width: 250,
                cellRenderer: (params: ICellRendererParams) => {
                    return params.value.map((item: any) => {
                        return <Tag>{(item as DailyTag).tag_name}</Tag>;
                    });
                },
            },
            {
                headerName: '状态',
                field: 'state',
                flex: 1,
                cellRenderer: (params: ICellRendererParams) => (
                    <div style={{ color: params.value === '已下线' ? 'gray' : '' }}>
                        {params.value}
                    </div>
                ),
            },
            {
                headerName: '操作',
                pinned: 'right',
                width: 180,
                cellRenderer: (params: ICellRendererParams) => (
                    <>
                        <Button
                            disabled={params.data.state_code > 2}
                            onClick={() => {
                                setCurrentSelect(params.data);
                                setVisible(true);
                            }}
                        >
                            修改
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                confirm({
                                    title: '确认下线?',
                                    icon: <ExclamationCircleOutlined />,
                                    onOk() {
                                        DeleteDailyNews({ id: params.data.id }).then(() => {
                                            handleRefreshList();
                                        });
                                    },
                                });
                            }}
                            disabled={params.data.state_code > 2}
                        >
                            下线
                        </Button>
                    </>
                ),
            },
        ],
        [handleRefreshList, setCurrentSelect, setVisible]
    );

    return (
        <>
            <div className="ag-theme-alpine">
                <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={{ resizable: true, width: 96, minWidth: 68, suppressMenu: true }}
                    rowData={infoList}
                    domLayout="autoHeight"
                    className="CustomerList__Table"
                />
            </div>
            {totalNum > 0 && infoList.length > 0 ? (
                <Pagination
                    onChange={handlePaginationChange}
                    total={totalNum}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 30,
                        width: isMobile ? '100%' : '90%',
                    }}
                    pageSize={NEWSPAGESIZE}
                    defaultCurrent={1}
                    showSizeChanger={false}
                />
            ) : null}
        </>
    );
};
export default AllDailyNews;
