import axios from '../../service/tools';
// 获取用户
const queryUsers = () => {
    return axios.post<Group['members']>('/v1/admin/im/user/query');
};
// 获取群组用户
const queryGroupUsers = (data: { groupId: string }) => {
    return axios.post('/v1/admin/im/group/member/list', { data });
};
// 设置用户为群主
const setGroupOwner = (data: { groupId: string; uid: string }) => {
    return axios.post('/v1/admin/im/group/owner/set', { data });
};
// 设置用户为管理员
const setGroupManager = (data: { groupId: string; uids: string[] }) => {
    return axios.post('/v1/admin/im/group/manager/set', { data });
};
const removeUserFromGroup = (data: { groupId: string; uids: string[] }) => {
    return axios.post('/v1/admin/im/group/member/remove', { data });
};

const addUserToGroup = (data: { groupId: string; uids: string[] }) => {
    return axios.post('/v1/admin/im/group/member/add', { data });
};
const queryAllGroups = (data: { limit: number; offset: number }) => {
    return axios.post<GroupList>('/v1/admin/im/group/list', { data });
};
export enum RoleLevel {
    Member = 1,
    Owner = 2,
    Manager = 3,
}
export interface Group {
    basic: {
        groupId: string;
        name: string;
        faceUrl: string;
        status: number;
        createTime: string;
    };
    remark: {
        remarkedGroupId: string;
        remarkName: string;
    };
    members: {
        userId: string;
        roleLevel: RoleLevel; // 1普通成员, 2群主, 3管理员
        userGroupFaceUrl: string;
        customerId: string;
        faceUrl: string;
        name: string;
        remarkName: string;
    }[];
}

export interface GroupList {
    groups: Group[];
    totalCount: number;
}
export {
    queryUsers,
    queryGroupUsers,
    setGroupOwner,
    setGroupManager,
    removeUserFromGroup,
    addUserToGroup,
    queryAllGroups,
};
