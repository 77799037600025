import React, { useMemo } from 'react';
import styles from './index.module.scss';
import { Button } from 'antd';
import { ProductQuoteBondDetailType, QouteType } from '../../type';
import alertImg from '../../../../static/alert-circle.png';
import successImg from '../../../../static/check-circle.png';
const BondCalculate = ({ data, onOk }: { data?: ProductQuoteBondDetailType; onOk: () => void }) => {
    const finalData = useMemo(() => {
        //数据中 0、1 为一竖列,2、3为一竖列，4、5为一竖列
        return (data?.fields ?? []).reduce((pre, cur, index) => {
            if (index % 2 === 0) {
                pre.push([cur]);
            } else {
                pre[pre.length - 1].push(cur);
            }
            return pre;
        }, [] as { title: string; value?: string; unit?: string; is_pass_check?: boolean; failed_check_message?: string }[][]);
    }, [data]);
    const btnTitle = () => {
        switch (data?.status) {
            case QouteType.Communicating:
                return '确认报价';
            case QouteType.Confirmed:
                return '您已确认报价信息';
            case QouteType.Canceled:
                return '该报价已撤销';
            case QouteType.Screening:
                return '筛选中';
        }
        return '';
    };
    return (
        <div className={styles.container}>
            <div className={styles.result}>
                {finalData.map((section, index) => (
                    <div className={styles.section} key={index}>
                        {section.map((item) => (
                            <div className={styles.item} key={item.title}>
                                <div className={styles.value}>
                                    {item.value ?? '--'} {item.unit}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className={styles.title}>{item.title}</div>
                                    {typeof item.is_pass_check !== 'undefined' && (
                                        <img
                                            style={{
                                                width: '16px',
                                                height: '16px',
                                                marginLeft: '8px',
                                            }}
                                            alt=""
                                            src={item.is_pass_check! ? successImg : alertImg}
                                        />
                                    )}
                                    {item.failed_check_message && (
                                        <div style={{ color: '#FF0000', marginLeft: '8px' }}>
                                            {item.failed_check_message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            {false && (
                <>
                    <div className={styles.spline} />
                    <Button
                        disabled={
                            data?.selected_quotes?.length === 0 ||
                            data?.status !== QouteType.Communicating
                        }
                        className={styles.button}
                        onClick={onOk}
                    >
                        {btnTitle()}
                    </Button>
                </>
            )}
        </div>
    );
};

export default BondCalculate;
