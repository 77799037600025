import React, { forwardRef, useEffect, useState } from 'react';
import { Asset, AssetType } from '../../../Transaction/type';
import { Form, FormInstance, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import SearchUnderlying from '../../../Transaction/components/TransForm/Forms/FormItems/SearchUnderlying';
import BankItem from '../../../Transaction/components/TransForm/Forms/FormItems/BankItem';
import {
    tenorOptions,
    optionSideOptions,
    optionTypeOptions,
    OptionSide,
    OptionType,
} from '../data';
import {
    GinkgoInput,
    GinkgoInputNumber,
} from '../../../Transaction/components/TransForm/Forms/FormItems/GinkgoFieldItems';
import { math } from '../../../../utils';
import moment from 'moment';

interface OptionFormProps {
    type: AssetType;
    asset?: Asset;
}

const OptionForm = forwardRef<FormInstance, OptionFormProps>((props, ref) => {
    const { asset } = props;
    const [form] = useForm();
    const [showAnnualisedRate, setShowAnnualisedRate] = useState(false);
    const formItemStyle: React.CSSProperties = {
        width: 'calc(62.5% - 8px)',
    };

    useEffect(() => {
        if (asset) {
            const extInfo = asset.ext_info as any;
            form.setFieldsValue({
                ext_info: extInfo,
            });
            setShowAnnualisedRate(extInfo.option_side === OptionSide.Sell);
        }
    }, [asset, form]);
    const calcEarnRate = (optionFeeRate: number, tenor: number) => {
        return math.format(math.evaluate(`${optionFeeRate} * 365 / (${tenor} * 30)`), {
            notation: 'fixed',
            precision: 2,
        });
    };
    const calcBreakevenPrice = (
        currentStockPrice: number,
        optionFeeRate: number,
        strikePriceRate: number,
        type: number
    ) => {
        return math.format(
            math.evaluate(
                `${currentStockPrice} * (${strikePriceRate}/100 ${
                    type === OptionType['American Call'] || type === OptionType['European Call']
                        ? '+'
                        : '-'
                } ${optionFeeRate}/100 )`
            ),
            {
                notation: 'fixed',
                precision: 3,
            }
        );
    };
    const onValuesChange = (changedValues: Record<string, any>, allValues: any) => {
        let changeKey = Object.keys(changedValues)[0];
        let newValues = { ...allValues };
        if (changeKey === 'ext_info') {
            changeKey = Object.keys(changedValues[changeKey])[0];
            if (changeKey === 'option_fee_rate') {
                // option_fee
                if (
                    changedValues.ext_info.option_fee_rate &&
                    allValues.ext_info.underlying_open_price
                ) {
                    // option_fee
                    newValues.ext_info.option_fee = math.format(
                        math.evaluate(
                            `${allValues.ext_info.underlying_open_price} / 100 * ${changedValues.ext_info.option_fee_rate}`
                        ),
                        { notation: 'fixed', precision: 3 }
                    );

                    // breakeven_price
                    if (allValues.ext_info.option_type && allValues.ext_info.strike_price_rate) {
                        newValues.ext_info.break_even_price = calcBreakevenPrice(
                            allValues.ext_info.underlying_open_price,
                            changedValues.ext_info.option_fee_rate,
                            allValues.ext_info.strike_price_rate,
                            allValues.ext_info.option_type
                        );
                    }
                } else {
                    newValues.ext_info.option_fee = undefined;
                }
                // annualised_rate_of_return
                if (changedValues.ext_info.option_fee_rate && allValues.ext_info.tenor) {
                    const result = calcEarnRate(
                        changedValues.ext_info.option_fee_rate,
                        parseInt(allValues.ext_info.tenor)
                    );
                    newValues.ext_info.annualised_rate_of_return = result;
                } else {
                    newValues.ext_info.annualised_rate_of_return = undefined;
                }
            }
            if (changeKey === 'strike_price_rate') {
                if (
                    changedValues.ext_info.strike_price_rate &&
                    allValues.ext_info.underlying_open_price
                ) {
                    // strike_price
                    newValues.ext_info.strike_price = math.format(
                        math.evaluate(
                            `${allValues.ext_info.underlying_open_price} / 100 * ${changedValues.ext_info.strike_price_rate}`
                        ),
                        { notation: 'fixed', precision: 3 }
                    );
                    // breakeven_price
                    if (allValues.ext_info.option_type && allValues.ext_info.option_fee_rate) {
                        newValues.ext_info.break_even_price = calcBreakevenPrice(
                            allValues.ext_info.underlying_open_price,
                            allValues.ext_info.option_fee_rate,
                            changedValues.ext_info.strike_price_rate,
                            allValues.ext_info.option_type
                        );
                    }
                } else {
                    newValues.ext_info.strike_price = undefined;
                }
            }
            if (changeKey === 'option_side') {
                if (changedValues.ext_info.option_side === OptionSide.Sell) {
                    setShowAnnualisedRate(true);
                    if (allValues.ext_info.option_fee_rate && allValues.ext_info.tenor) {
                        const result = calcEarnRate(
                            allValues.ext_info.option_fee_rate,
                            parseInt(allValues.ext_info.tenor)
                        );
                        newValues.ext_info.annualised_rate_of_return = result;
                    }
                } else {
                    setShowAnnualisedRate(false);
                    newValues.ext_info.annualised_rate_of_return = undefined;
                }
            }
            if (changeKey === 'tenor') {
                if (changedValues.ext_info.tenor && allValues.ext_info.option_fee_rate) {
                    const result = calcEarnRate(
                        allValues.ext_info.option_fee_rate,
                        parseInt(changedValues.ext_info.tenor)
                    );
                    newValues.ext_info.annualised_rate_of_return = result;
                } else {
                    newValues.ext_info.annualised_rate_of_return = undefined;
                }
            }
            // breakeven_price
            if (changeKey === 'option_type') {
                if (
                    changedValues.ext_info.option_type &&
                    allValues.ext_info.underlying_open_price &&
                    allValues.ext_info.strike_price_rate &&
                    allValues.ext_info.option_fee_rate
                ) {
                    newValues.ext_info.break_even_price = calcBreakevenPrice(
                        allValues.ext_info.underlying_open_price,
                        allValues.ext_info.option_fee_rate,
                        allValues.ext_info.strike_price_rate,
                        changedValues.ext_info.option_type
                    );
                }
            }
        }
        form.setFieldsValue(newValues);
    };
    const onPriceChange = (price: Asset | undefined) => {
        let newValues = {
            ext_info: {
                ...form.getFieldValue('ext_info'),
                underlying_open_price: price?.ext_info.open_price || undefined,
                underlying_name: price?.name || undefined,
                underlying_spec_name: price?.spec_name || undefined,
                underlyings: price?.isin ? [price.isin] : undefined,
            },
        };
        // refresh option_fee and strike_price.
        if (newValues.ext_info.strike_price_rate) {
            newValues.ext_info.strike_price = math.format(
                math.evaluate(
                    `${newValues.ext_info.underlying_open_price} / 100 * ${newValues.ext_info.strike_price_rate}`
                ),
                { notation: 'fixed', precision: 3 }
            );
        }
        if (newValues.ext_info.option_fee_rate) {
            newValues.ext_info.option_fee = math.format(
                math.evaluate(
                    `${newValues.ext_info.underlying_open_price} / 100 * ${newValues.ext_info.option_fee_rate}`
                ),
                { notation: 'fixed', precision: 3 }
            );
        }
        if (
            newValues.ext_info.option_fee_rate &&
            newValues.ext_info.strike_price_rate &&
            newValues.ext_info.option_type
        ) {
            newValues.ext_info.break_even_price = calcBreakevenPrice(
                newValues.ext_info.underlying_open_price,
                newValues.ext_info.option_fee_rate,
                newValues.ext_info.strike_price_rate,
                newValues.ext_info.option_type
            );
        }
        form.setFieldsValue(newValues);
    };
    return (
        <>
            <Form
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 17, offset: 1 }}
                ref={ref}
                onValuesChange={onValuesChange}
            >
                <SearchUnderlying
                    withCurrency={false}
                    form={form}
                    needPrice
                    isin={
                        asset?.ext_info.underlyings && asset?.ext_info.underlyings.length > 0
                            ? asset.ext_info.underlyings[0]
                            : undefined
                    }
                    required
                    width="calc(62.5% - 8px)"
                    currentPrice={onPriceChange}
                />
                <Form.Item
                    name={['ext_info', 'bank_id']}
                    rules={[{ required: true, message: 'Bank is required.' }]}
                    label="Bank"
                >
                    <BankItem />
                </Form.Item>
                <Form.Item
                    name={['ext_info', 'option_side']}
                    rules={[{ required: true, message: 'Side is required.' }]}
                    label="Side"
                >
                    <Select options={optionSideOptions} style={formItemStyle} />
                </Form.Item>
                <Form.Item
                    name={['ext_info', 'option_type']}
                    rules={[{ required: true, message: 'Option Type is required.' }]}
                    label="Option Type"
                >
                    <Select options={optionTypeOptions} style={formItemStyle} />
                </Form.Item>
                <Form.Item
                    name={['ext_info', 'tenor']}
                    rules={[{ required: true, message: 'Tenor is required.' }]}
                    label="Tenor"
                >
                    <Select options={tenorOptions} style={formItemStyle} />
                </Form.Item>
                <Form.Item name={['ext_info', 'underlying_open_price']} label="Current Stock Price">
                    <GinkgoInput readOnly />
                </Form.Item>
                <Form.Item
                    name={['ext_info', 'strike_price_rate']}
                    rules={[{ required: true, message: 'Strike is required.' }]}
                    label="Strike"
                >
                    <GinkgoInputNumber style={formItemStyle} addonAfter="%" />
                </Form.Item>
                <Form.Item name={['ext_info', 'strike_price']} label="Strike$">
                    <GinkgoInputNumber style={formItemStyle} readOnly />
                </Form.Item>
                <Form.Item
                    name={['ext_info', 'option_fee_rate']}
                    rules={[{ required: true, message: 'Option Fee(%) is required.' }]}
                    label="Option Fee(%)"
                >
                    <GinkgoInputNumber style={formItemStyle} addonAfter="%" />
                </Form.Item>
                <Form.Item name={['ext_info', 'option_fee']} label="Option Fee($)">
                    <GinkgoInputNumber style={formItemStyle} readOnly />
                </Form.Item>
                <Form.Item
                    name={['ext_info', 'annualised_rate_of_return']}
                    label="Annualised Rate of Return"
                    hidden={!showAnnualisedRate}
                >
                    <GinkgoInputNumber style={formItemStyle} readOnly addonAfter="%" />
                </Form.Item>
                <Form.Item name={['ext_info', 'break_even_price']} label="Breakeven Price">
                    <GinkgoInput style={formItemStyle} readOnly />
                </Form.Item>
                <Form.Item
                    name={['ext_info', 'issuer']}
                    rules={[{ required: true, message: 'Issuer is required.' }]}
                    label="Issuer"
                >
                    <GinkgoInput style={formItemStyle} />
                </Form.Item>
                {/* hidden item */}
                <Form.Item
                    name={['ext_info', 'underlying_name']}
                    label="underlying_name"
                    hidden
                ></Form.Item>
                <Form.Item
                    name={['ext_info', 'underlying_spec_name']}
                    label="underlying_spec_name"
                    hidden
                ></Form.Item>
                <Form.Item
                    name={['ext_info', 'underlyings']}
                    label="underlyings"
                    hidden
                ></Form.Item>
                {asset?.updated_at && (
                    <Form.Item name={['ext_info', 'underlying_open_price']} label="Updated Time">
                        {moment(asset.updated_at).format('YYYY-MM-DD HH:mm:ss')}
                    </Form.Item>
                )}
            </Form>
        </>
    );
});

export default OptionForm;
