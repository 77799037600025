import axios from '../../service/tools';
import {
    AccountManageType,
    TransStatus,
    UpdateTransactionReq,
    TransactionResponse,
    TransactionInfo,
    ClientRes,
    EmailAddress,
    MailContent,
    SendEmailReq,
    QueryAssetType,
    LetterInfo,
    Asset,
    AssetType,
    Account,
    TransactionOrderType,
} from './type';
const transactionList = (data: {
    account_manage_type: AccountManageType;
    transaction_status: TransStatus[];
    transaction_types: TransactionOrderType[];
    account_id?: string;
    page_size: number;
    current_page: number;
}) => {
    return axios.post<TransactionResponse>('gk/v1/transaction/list', { data });
};
const updateTrans = (data: UpdateTransactionReq) => {
    return axios.post<TransactionInfo>('gk/v1/transaction/save', { data });
};
const queryTransDetail = (data: { trans_id: number }) => {
    return axios.post<{ transaction: TransactionInfo }>('gk/v1/transaction/detail', { data });
};
const deleteTrans = (data: { trans_id: number }) => {
    return axios.post('gk/v1/transaction/delete', { data });
};

const changeStatus = (data: { status: TransStatus; trans_id: number }) => {
    return axios.post('gk/v1/transaction/status/update', { data });
};
const getTransactionDetail = (data: { trans_id: number }) => {
    return axios.post<{ transaction: TransactionInfo }>('gk/v1/transaction/detail', { data });
};
const getManagedClients = (data: { is_need_bank?: 1 | 2 }) => {
    return axios.post<ClientRes>('gk/v1/client/managed/list', { data });
};

const searchPosition = (data: { account_id: string; asset_name: string }) => {
    return axios.post<QueryAssetType[]>('gk/v1/position/list', { data });
};
const getTransactionsByclientbank = (data: { client_id: number; bank_id: string }) => {
    return axios.post<{ transactions: TransactionInfo[] }>('gk/v1/transaction/placeable/list', {
        data,
    });
};

const getTransactionsByIds = (data: { trans_ids: number[] }) => {
    return axios.post<{ transactions: TransactionInfo[] }>('gk/v1/transaction/batch_details', {
        data,
    });
};

const getEmailsByBankId = (data: { bank_id: string; mail_type: number }) => {
    return axios.post<{ mail_addresses: EmailAddress[] }>('gk/v1/mail/recipient/list', {
        data,
    });
};
const queryCash = (data: { account_id: string; currency: string }) => {
    return axios.post<{ balance: number }>('gk/v1/position/cash', { data });
};

const getEmailContent = (data: { trans_ids: number[]; mail_type: number }) => {
    return axios.post<{ mail_content: MailContent }>('gk/v1/mail/content', {
        data,
    });
};

const sendEmail = (data: SendEmailReq) => {
    return axios.post<{ mail_content: MailContent }>('gk/v1/mail/send', {
        data,
    });
};

const getTransactionLetterInfo = (data: { trans_id: number }) => {
    return axios.post<{ letter_info: LetterInfo; transaction: TransactionInfo }>(
        'gk/v1/transaction/get_letter',
        {
            data,
        }
    );
};

const saveTransactionLetterInfo = (data: { trans_id: number; letter_info: LetterInfo }) => {
    return axios.post<{ letter_info: LetterInfo; transaction: TransactionInfo }>(
        'gk/v1/transaction/save_letter',
        {
            data,
        }
    );
};

const searchProduct = (data: {
    asset_types: AssetType[];
    isin?: string;
    ticker?: string;
    is_need_price?: number;
}) => {
    return axios.post<{ assets: Asset[] }>('gk/v1/asset/search', { data });
};
const searchAccountWithNumber = (data: { account_number: string }) => {
    return axios.post<Account[]>('v2/admin/customer/suggestions', { data });
};
const downloadPdfByHtml = (data: { html_base64: string; file_name: string }) => {
    return axios.post(
        'gk/v1/file/pdf/html_pdf',
        {
            data,
        },
        {
            responseType: 'blob',
        }
    );
};
const uploadTermsheet = (data: { trans_id: number; data_url: string; file_name: string }) => {
    return axios.post('gk/v1/transaction/termsheet/parse', { data });
};
const deleteTermsheet = (data: { trans_id: number }) => {
    return axios.post('gk/v1/transaction/termsheet/delete', {
        data,
    });
};
const getTermsheetDetail = (data: { trans_id: number }) => {
    return axios.post('gk/v1/transaction/termsheet/parsing_detail', {
        data,
    });
};
const saveTermsheetDetail = (data: { termsheet_parsing_info: any; trans_id: number }) => {
    return axios.post('gk/v1/transaction/termsheet/confirm', {
        data,
    });
};

export {
    transactionList,
    searchAccountWithNumber,
    queryCash,
    updateTrans,
    queryTransDetail,
    deleteTrans,
    getTransactionDetail,
    getManagedClients,
    getTransactionsByclientbank,
    getTransactionsByIds,
    getEmailsByBankId,
    getEmailContent,
    changeStatus,
    sendEmail,
    searchPosition,
    getTransactionLetterInfo,
    saveTransactionLetterInfo,
    searchProduct,
    downloadPdfByHtml,
    uploadTermsheet,
    deleteTermsheet,
    getTermsheetDetail,
    saveTermsheetDetail,
};
