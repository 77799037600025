import React, { useEffect, useRef } from 'react';

interface ClickOutsideProps {
    children?: React.ReactNode;
    onClickOutside?: Function;
    className?: string;
}

export default function ClickOutside(props: ClickOutsideProps) {
    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(this: Document, ev: MouseEvent) {
            if (wrapperRef.current && !wrapperRef.current.contains(ev.target as Node)) {
                props.onClickOutside?.();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef, props.onClickOutside]);

    return (
        <div ref={wrapperRef} className={props.className}>
            {props.children}
        </div>
    );
}
