import React, { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { searchProduct } from '../../../../request';
import { Asset, AssetType } from '../../../../type';
import { Form, FormInstance, Select } from 'antd';
import { GinkgoInput, GinkgoSelect } from './GinkgoFieldItems';
const { Option } = Select;
const SearchIsinAdjustment = ({
    readOnly,
    form,
    width,
    required,
    canNull,
}: {
    readOnly?: boolean;
    required?: boolean;
    canNull?: boolean;
    form?: FormInstance;
    width?: string;
}) => {
    const [currentProduct, setCurrentProduct] = useState<Asset>();
    const [searchKey, setSearchKey] = useState<string>('');
    const { data: productList, run, cancel } = useRequest(searchProduct, {
        manual: true,
    });
    useEffect(() => {
        searchKey &&
            run({
                asset_types: canNull ? [] : [AssetType.CorporateBond, AssetType.GovernmentBond],
                isin: searchKey,
            });
        return () => {
            cancel();
        };
    }, [canNull, cancel, run, searchKey]);
    useEffect(() => {
        if (form && currentProduct) {
            form.setFieldsValue({
                ext_info: {
                    ...form.getFieldValue('ext_info'),
                    asset_name: currentProduct.spec_name ?? currentProduct.name,
                },
            });
        }
    }, [form, currentProduct]);
    useEffect(() => {
        if (productList?.data?.assets?.length === 0) {
            if (form && searchKey && canNull) {
                form.setFieldsValue({
                    ext_info: {
                        ...form.getFieldValue('ext_info'),
                        isin: searchKey,
                    },
                });
            }
        }
    }, [form, productList, searchKey, canNull]);
    return (
        <>
            <Form.Item
                label="ISIN"
                name={['ext_info', 'isin']}
                rules={[{ required: required, message: 'ISIN is required' }]}
            >
                <GinkgoSelect
                    showSearch
                    readOnly={readOnly}
                    defaultActiveFirstOption={false}
                    autoClearSearchValue={false}
                    showArrow={false}
                    notFoundContent={null}
                    placeholder={'Enter to search'}
                    style={{ width: width }}
                    onSearch={(value) => setSearchKey(value)}
                    onSelect={(value: string) => {
                        setCurrentProduct(
                            productList?.data?.assets?.find((item) => item.isin === value)
                        );
                    }}
                >
                    {(productList?.data?.assets ?? []).map((asset) => {
                        return (
                            <Option key={asset.isin} value={asset.isin}>
                                {asset.isin}
                            </Option>
                        );
                    })}
                </GinkgoSelect>
            </Form.Item>
            <Form.Item
                name={['ext_info', 'asset_name']}
                label="Asset Name"
                rules={[{ required: required, message: 'Asset Name is required' }]}
            >
                <GinkgoInput
                    style={{ width: width }}
                    readOnly={(canNull ? productList?.data?.assets.length !== 0 : true) || readOnly}
                />
            </Form.Item>
        </>
    );
};

export default SearchIsinAdjustment;
