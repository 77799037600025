/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Form,
    Input,
    message,
    Modal,
    Radio,
    Row,
    Tabs,
} from 'antd';
import Calendar from './Calendar';
import AccountNotice from './AccountNotice';
import HotQA from './HotQA';
import styles from '../../StructuralProducts/components/TranscationOptionsForm.module.scss';
import moment from 'moment';
import {
    AddDailyNews,
    DailyNewsInfo,
    DailyTag,
    queryDailyTags,
    UpdateDailyNews,
} from '../../../service/requests/DailyNewsRequest';
import { showError } from '../../RecommendationComponent/util';
import { Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import AllDailyNews from './All/all';
export const TooltipCell = ({ text }: { text: string }) => (
    <Tooltip title={text} placement="topLeft">
        <span>{text}</span>
    </Tooltip>
);
const { TabPane } = Tabs;
type voidClick = () => void;
export const NEWSPAGESIZE = 15;
export interface DailyConfigProp {
    refresh: (refresh: voidClick, index: number) => void;
    setVisible: (boolean: boolean) => void;
    setCurrentSelect: (data: DailyNewsInfo['daily_news_list'][number]) => void;
}
const DailyConfig = () => {
    const [index, setIndex] = useState(1);
    const [sendType, setSendType] = useState(1);
    const [tagType, setTagType] = useState(1);
    const [activetyKey, setActivetyKey] = useState('1');
    const [form] = useForm(undefined);
    let childRefresh = new Map<number, () => void>();
    const [currentSelectTags, setCurrentSelectTags] = useState<number[]>([]);
    const [tagList, setTagList] = useState<DailyTag[]>([]);
    const callback = (refresh: voidClick, index: number) => {
        childRefresh.set(index, refresh);
    };
    const [currentSelect, setCurrentSelect] = useState<
        DailyNewsInfo['daily_news_list'][number] | null
    >();
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        if (currentSelect) {
            setIndex(currentSelect.type);
            setTagType(currentSelect.is_all_user);
            setCurrentSelectTags(currentSelect.look_tags.map((item) => item.id));
        } else {
            setIndex(Number.parseInt(activetyKey === '4' ? '1' : activetyKey));
            setTagType(1);
            setCurrentSelectTags([]);
        }
    }, [currentSelect, activetyKey]);
    useEffect(() => {
        form?.resetFields();
    }, [currentSelectTags, form, index]);
    const onFinish = useCallback(
        (res) => {
            (currentSelect ? UpdateDailyNews : AddDailyNews)({
                ...res,
                public_at: (res.public_at ?? moment()).valueOf(),
                id: currentSelect?.id,
            }).then((res) => {
                if (res) {
                    message.success(currentSelect ? '修改成功' : '增加成功');
                    setVisible(false);
                    setCurrentSelect(null);
                    setSendType(1);
                    if (childRefresh.get(index)) {
                        childRefresh.get(index)!();
                    }
                    setActivetyKey(index.toString());
                }
            }, showError);
        },
        [childRefresh, currentSelect, index]
    );
    useEffect(() => {
        queryDailyTags().then((res) => {
            if (res) {
                setTagList(res.data);
            }
        }, showError);
    }, []);

    const range = (start: number, end: number) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

    const disabledTime = (date: moment.Moment) => {
        const hour = moment().hours(); // 获取当前的小时
        const min = moment().minutes(); // 获取当前的分钟
        const second = moment().seconds(); // 获取当前秒
        if (index === 1) {
            return {};
        }
        if (date?.format('YYYY-MM-DD').valueOf() === moment().format('YYYY-MM-DD').valueOf()) {
            if (date?.format('HH').valueOf() === moment().format('HH').valueOf()) {
                if (date?.format('mm').valueOf() === moment().format('mm').valueOf()) {
                    return {
                        disabledHours: () => range(0, 24).splice(0, hour),
                        disabledMinutes: () => range(0, 60).splice(0, min),
                        disabledSeconds: () => range(0, 60).splice(0, second),
                    };
                } else {
                    return {
                        disabledHours: () => range(0, 24).splice(0, hour),
                        disabledMinutes: () => range(0, 60).splice(0, min),
                    };
                }
            } else {
                return {
                    disabledHours: () => range(0, 24).splice(0, hour),
                };
            }
        }
    };
    const childProp = {
        refresh: callback,
        setCurrentSelect: setCurrentSelect,
        setVisible: setVisible,
    };
    return (
        <>
            <Modal
                destroyOnClose
                footer={null}
                visible={visible}
                okText="确认"
                cancelText="取消"
                forceRender
                onCancel={() => {
                    setVisible(false);
                }}
                centered
            >
                <Form
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={onFinish}
                    form={form}
                    initialValues={{
                        type: index,
                        sendtype: 1,
                        is_all_user: 1,
                        ...currentSelect,
                        tag_ids: currentSelectTags,
                        public_at: moment(),
                    }}
                >
                    <Form.Item label="事件类型" name="type">
                        <Radio.Group
                            onChange={(e) => {
                                setIndex(e.target.value);
                            }}
                        >
                            <Radio value={1}>大事日历</Radio>
                            <Radio value={2}>账户提醒</Radio>
                            <Radio value={3}>热门问答</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label={'标题'}
                        rules={[{ required: true, message: '请输入标题' }]}
                        name="title"
                    >
                        <Input placeholder="0~40个字" />
                    </Form.Item>
                    <Form.Item label={'副标题'} name="content">
                        <Input placeholder="0~50个字" />
                    </Form.Item>
                    <Form.Item label="链接" name="doc_feishu_url">
                        <Input />
                    </Form.Item>
                    <Form.Item label={'发布时间'} name="sendtype">
                        <Radio.Group
                            onChange={(e) => {
                                setSendType(e.target.value);
                            }}
                        >
                            <Radio value={1}>{'立即发布'}</Radio>
                            <Radio value={2}>{'定时发布'}</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {sendType === 2 && (
                        <Form.Item label={index === 1 ? '事件时间' : '发布时间'} name="public_at">
                            <DatePicker
                                showNow={false}
                                defaultPickerValue={moment()}
                                format="YYYY-MM-DD HH:mm:ss"
                                showTime={{
                                    defaultValue: moment(
                                        currentSelect?.public_at ?? moment().valueOf()
                                    ),
                                }}
                            />
                        </Form.Item>
                    )}

                    <Form.Item label={'发布方式'} name="is_all_user">
                        <Radio.Group
                            onChange={(e) => {
                                setTagType(e.target.value);
                            }}
                        >
                            <Radio value={1}>全量发布</Radio>
                            <Radio value={2}>部分发布</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {tagType === 2 && (
                        <Form.Item label="标签" name="tag_ids">
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row>
                                    {tagList.map((rm) => (
                                        <Col span={12} key={rm.id}>
                                            <Checkbox value={rm.id} style={{ lineHeight: '32px' }}>
                                                {rm.tag_name}
                                            </Checkbox>
                                        </Col>
                                    ))}
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    )}
                    <div className={styles.bottom}>
                        <Button block type="primary" htmlType="submit" style={{ marginRight: 20 }}>
                            完成
                        </Button>
                        <Button
                            block
                            type="text"
                            htmlType="button"
                            onClick={() => {
                                if (currentSelect) {
                                    setCurrentSelect(null);
                                }
                                setSendType(1);
                                setVisible(false);
                            }}
                        >
                            取消
                        </Button>
                    </div>
                </Form>
            </Modal>
            <Tabs
                activeKey={activetyKey}
                onChange={(e) => {
                    setIndex(Number.parseInt(e));
                    setActivetyKey(e);
                }}
                tabBarExtraContent={{
                    left: (
                        <Button
                            type="primary"
                            style={{ marginRight: '40px' }}
                            onClick={() => {
                                setCurrentSelect(null);
                                setVisible(true);
                            }}
                        >
                            新增
                        </Button>
                    ),
                }}
            >
                <TabPane key="1" tab="大事日历">
                    <Calendar {...childProp} />
                </TabPane>
                <TabPane key="2" tab="账户提醒">
                    <AccountNotice {...childProp} />
                </TabPane>
                <TabPane key="3" tab="热门问答">
                    <HotQA {...childProp} />
                </TabPane>
                <TabPane key="4" tab="全部">
                    <AllDailyNews {...childProp} />
                </TabPane>
            </Tabs>
        </>
    );
};
export default DailyConfig;
