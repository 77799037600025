import { Form } from 'antd';
import React from 'react';
import LKRadioGroup from '../../../../../../components/LKRadioGroup';
import { GinkgoCurrencyType } from '../../../../type';

const CurrencyItem = ({
    readOnly,
    required,
    onChange,
}: {
    readOnly?: boolean;
    required?: boolean;
    onChange: (value: string) => void;
}) => {
    const currencyList = Object.keys(GinkgoCurrencyType).map((key) => ({
        label: key,
        value: key,
    }));
    return (
        <Form.Item
            label="Currency"
            name={['ext_info', 'currency']}
            rules={[{ required: required, message: 'currency is required' }]}
        >
            <LKRadioGroup
                datasource={currencyList}
                itemWidth="12.5%"
                readOnly={readOnly}
                onChange={(value) => onChange(value as string)}
            />
        </Form.Item>
    );
};

export default CurrencyItem;
