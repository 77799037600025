import { PORTAL_LOGIN, REMOVE_LOGIN, SET_LOGIN } from '../../constants/constants';

export function setLogin(data) {
    return {
        type: SET_LOGIN,
        newLoginData: data,
    };
}

export function removeLogin() {
    return {
        type: REMOVE_LOGIN,
        newLoginData: null,
    };
}

export function setPortalLogin(data) {
    return {
        type: PORTAL_LOGIN,
        portalLogin: data,
    };
}
