import React from 'react';
import styles from './index.module.scss';
interface DetailSectionProps {
    title: string;
    children: React.ReactNode;
}
const DetailSection = (props: DetailSectionProps) => {
    return (
        <>
            <div className={styles.title}>{props.title}</div>
            <div className={styles.card}>{props.children}</div>
        </>
    );
};

export default DetailSection;
