import { Form } from 'antd';
import React from 'react';
import LKDatePicker from '../../../LKDatePicker';
export interface DateItemProps {
    label: string;
    name: any;
    required?: boolean;
    readOnly?: boolean;
    addonBefore?: React.ReactNode;
    width?: string;
    timezone?: string;
}
const DateItem = (data: DateItemProps) => {
    return (
        <Form.Item
            name={data.name}
            label={data.label}
            rules={[{ required: data.required, message: data.label + ' is required' }]}
        >
            <LKDatePicker
                style={{ width: data.width ?? 'calc(50% - 10px)' }}
                format={'YYYY-MM-DD'}
                readOnly={data.readOnly}
                addonBefore={data.addonBefore}
                timezone={data.timezone}
            />
        </Form.Item>
    );
};
export default DateItem;
