/**
 * Created by hao.cheng on 2017/4/16.
 */
import { HostType, LOGIN } from './config';
import { CustomerManageData, CustomerManageReq } from './requests/CustomerManage';
import axios, { multiHostAxios } from './tools';
// import './mockService';
import {
    BankList,
    QueryAsset,
    QuerySummaryAsset,
    UserList,
    assetList,
    WhileList,
    queryPlanPkgType,
    AssetAllocUserList,
    AssetAllocPlan,
    RecommendationList,
    RecomProductList,
    AllowedMenuList,
    UnreviewAccount_list,
    CustomerInfo,
    CustomList,
    OptionQuoteuList,
    assetQueryPendingType,
    queryEditEntryType,
    quotePreview,
    quoteOrderList,
    AdvisorTwrUserType,
    orderTextTemplate,
} from './type';
interface LoginType {
    pwd: string;
    account_id: string;
}
export interface BaseReqParam {
    limit: number;
    offset: number;
}
export interface BaseReqParamPageSize {
    total_count: number;
    total_pages: number;
    current_page: number;
    page_size: number;
}
export const login = (data: LoginType) => {
    return axios.post(LOGIN, {
        data,
    });
};
export const loginWithCode = (data: { code: string }) => {
    return axios.post('/v1/admin/auth/login', {
        data,
    });
};
export const logout = () => {
    return axios.post('/v1/admin/logout', {
        data: {},
    });
};

export const getMenuList = () => {
    return axios.post<{
        sec_cates: Array<{
            name: string;
            sec_cate_id: string;
            sub_sec_cates: Array<{
                name: string;
                sub_sec_cate_id: string;
                parent_sec_cate_id: string;
            }>;
        }>;
    }>('v1/admin/category/list', {
        data: {},
    });
};
export const getAllowedMenuList = () => {
    return axios.post<AllowedMenuList>('/v1/admin/auth/menus', {
        data: {},
    });
};

export interface SecurityListType {
    security_summaries: Array<{
        name: string;
        sec_id: string;
        sec_cate_id: string;
        sub_sec_cate_id: string;
        display_order: number;
    }>;
}
export const getSecurityList = (data: {
    parent_sec_cate_id: string;
    sub_sec_cate_id: string;
    offset?: number;
    limit?: number;
}) => {
    return axios.post<SecurityListType>('v1/admin/security/list', {
        data: {
            sec_cate_id: data.parent_sec_cate_id,
            sub_sec_cate_id: data.sub_sec_cate_id,
            offset: data.offset || 0,
            limit: data.limit || 0,
        },
    });
};

export const addSecurity = (data: Record<string, any>) => {
    return axios.post('v1/admin/security/add', {
        data,
    });
};
export const updateSecurity = (data: Record<string, any>) => {
    return axios.post('v1/admin/security/update', {
        data,
    });
};
export const deleteSecurity = (data: { sec_id: string }) => {
    return axios.post('v1/admin/security/delete', {
        data,
    });
};
export const querySecurity = (data: { sec_id: string }) => {
    return axios.post('v1/admin/security/query', {
        data,
    });
};

export const addUser = (data: { name: string; mobile_number: string; check_code: string }) => {
    return axios.post('v1/admin/user/add', {
        data,
    });
};
export const deleteUser = (data: { uid: string }) => {
    return axios.post('v1/admin/user/delete', {
        data,
    });
};
export const updateUser = (data: { uid: string; name: string; check_code: string }) => {
    return axios.post('v1/admin/user/update', {
        data,
    });
};
export const getUserList = (data: {
    offset?: number;
    limit?: number;
    customer_id?: string;
    keyword?: string;
}) => {
    return axios.post<{ users: UserList; total_count: number }>('v1/admin/user/list', {
        data,
    });
};
//获取银行列表
export const getBankList = () => {
    return axios.post<BankList>('/v2/misc/dict_entry', {
        data: {
            entry_id: 'account_type',
        },
    });
};

export const queryAsset = (data: { uid: string; account_id?: string }) => {
    return axios.post<QueryAsset>('/v1/admin/asset/query', {
        data,
    });
};
export const querySummaryAsset = (data: { uid: string; account_id?: string }) => {
    return axios.post<QuerySummaryAsset>('v1/admin/asset/query_summary', {
        data,
    });
};

export const addAccount = (data: {
    uid: string;
    account_type: string;
    account_name: string;
    copy_id: number;
    vendor_account_id?: string;
    vendor_sub_account_id?: string;
}) => {
    return axios.post('/v1/admin/account/add', {
        data,
    });
};

export const addPosition = (data: {
    uid: string;
    isin: string;
    qty: number;
    account_id: string;
    cost_price: number;
}) => {
    return axios.post('v1/admin/position/add', {
        data,
    });
};
export const deletePosition = (data: { uid: string; isin: string; account_id: string }) => {
    return axios.post('/v1/admin/position/delete', {
        data,
    });
};

export const deleteAccount = (data: { uid: string; account_id: string; status: number }) => {
    return axios.post('v1/admin/account/delete', {
        data,
    });
};

export const getWhiteList = (data: { offset?: number; limit?: number }) => {
    return axios.post<{ total_count: number; account_list: WhileList }>(
        'v1/admin/user/white_list/list',
        {
            data,
        }
    );
};

export const addWhiteList = (data: {
    username: string;
    moblie_number: string;
    opening_status: number;
    account_bank?: string;
}) => {
    return axios.post('v1/admin/user/white_list/add', {
        data,
    });
};
export const updateWhiteList = (data: {
    moblie_number: string;
    opening_status: number;
    account_bank?: string;
}) => {
    return axios.post('/v1/admin/user/white_list/update', {
        data,
    });
};
export const deleteWhiteList = (data: { moblie_number: string }) => {
    return axios.post('/v1/admin/user/white_list/delete', {
        data,
    });
};
export const addBalance = (data: {
    uid: string;
    isin: string;
    type: string;
    amount: string;
    account_id: string;
    [key: string]: string;
    // trade_date: string;
    // end_date: string;
    // start_date: string;
}) => {
    return axios.post('/v1/admin/balance/add', {
        data,
    });
};
export const deleteBalance = (data: {
    uid: string;
    isin: string;
    type: string;
    account_id: string;
}) => {
    return axios.post('/v1/admin/balance/delete', {
        data,
    });
};
//后台管理金融产品相关的接口

// 后台管理资产配置相关的接口
export const addPlanPkg = (data: { name: string; plan_id: string }) => {
    return axios.post('/v1/admin/asset_alloc/plan_pkg/add', {
        data,
    });
};
export const addPlanPosition = (data: { isin: string; pkg_id: string; hold_value: number }) => {
    return axios.post('/v1/admin/asset_alloc/plan_position/add', {
        data,
    });
};

export const updatePlan = (data: { plan_name: string; plan_id: string }) => {
    return axios.post('/v1/admin/asset_alloc/plan/update', {
        data,
    });
};

export const queryPlanPkg = (data: { account_id: string }) => {
    return axios.post<queryPlanPkgType>('/v1/admin/asset_alloc/plan/query', {
        data,
    });
};
export const deletePlanPkg = (data: { pkg_id: string }) => {
    return axios.post('/v1/admin/asset_alloc/plan_pkg/delete', {
        data,
    });
};
export const updatePlanPkg = (data: { pkg_id: string; name?: string; debit_amount?: number }) => {
    return axios.post('/v1/admin/asset_alloc/plan_pkg/update', {
        data,
    });
};

// 后台金融产品相关接口
export const searchSecurity = (data: { keyword: string; sub_asset_classes?: Array<string> }) => {
    return axios.post<assetList>('v1/admin/security/search', {
        data,
    });
};
export const newSecurity = (data: { isin: string }) => {
    return axios.post('v1/admin/security/new', {
        data,
    });
};
export const magicNewSecurity = (data: { name: string; is_std: number }) => {
    return axios.post('/v1/admin/security/magic_new', {
        data,
    });
};

//金融产品添加(无ISIN)-更新(有ISIN)
export const addSecurityWithISIN = (data: { [key: string]: any }) => {
    return axios.post('/v1/admin/security/manual_new', {
        data,
    });
};
//用户拷贝

export const addUserCopy = (data: { uid: string; copy_id: number }) => {
    return axios.post('/v1/admin/user_copy/add', {
        data,
    });
};
export const updateUserCopy = (data: { uid: string; copy_id: number; account_name: string }) => {
    return axios.post('/v1/admin/user_copy/update', {
        data,
    });
};
export const deleteUserCopy = (data: { uid: string; copy_id: number }) => {
    return axios.post('/v1/admin/user_copy/delete', {
        data,
    });
};

// 资产配置相关
export const getAssetAllocUserList = (data: { offset?: number; limit?: number }) => {
    return axios.post<AssetAllocUserList>('/v1/admin/asset_alloc/user/list', {
        data,
    });
};
export const addAssetAllocPlan = (data: { uid: string }) => {
    return axios.post('/v1/admin/asset_alloc/plan/add', {
        data,
    });
};
export const updateAssetAllocPlan = (data: { plan_id: string; plan_name: string }) => {
    return axios.post('/v1/admin/asset_alloc/plan/update', {
        data,
    });
};
export const deleteAssetAllocPlan = (data: { uid: string; copy_id: number }) => {
    return axios.post('/v1/admin/asset_alloc/plan/delete', {
        data,
    });
};
export const queryAssetAllocPlan = (data: { uid: string; copy_id: number }) => {
    return axios.post<AssetAllocPlan>('/v1/admin/asset_alloc/plan/query', {
        data,
    });
};
export const updateAssetAllocPlanPkg = (data: {
    pkg_id: string;
    programme_note?: string;
    name?: string;
}) => {
    return axios.post<AssetAllocPlan>('/v1/admin/asset_alloc/plan_pkg/update', {
        data,
    });
};
export const addAssetAllocplanPosition = (data: {
    isin: string;
    pkg_id: string;
    hold_value: number;
}) => {
    return axios.post<AssetAllocPlan>('/v1/admin/asset_alloc/plan_position/add', {
        data,
    });
};
export const updateAssetAllocplanPosition = (data: { position_id: string; hold_value: number }) => {
    return axios.post<AssetAllocPlan>('/v1/admin/asset_alloc/plan_position/update', {
        data,
    });
};
export const deleteAssetAllocplanPosition = (data: { position_id: string }) => {
    return axios.post<AssetAllocPlan>('/v1/admin/asset_alloc/plan_position/delete', {
        data,
    });
};
export const addAssetAllocBalance = (data: {
    pkg_id: string;
    isin: string;
    type: string;
    amount: string;
}) => {
    return axios.post('/v1/admin/asset_alloc/balance/add', {
        data,
    });
};

/*
//推荐产品相关接口
*/
//获取推荐产品列表
export const getRecommendationList = () => {
    return axios.post<RecommendationList>('/v2/misc/dict_entry', {
        data: {
            entry_id: 'f_sec_type',
        },
    });
};
export const addRecomTag = (data: { name: string; brief: string; f_sec_type: string }) => {
    return axios.post('/v2/admin/recom/tag_add', {
        data,
    });
};
export const deleteRecomTag = (data: { tag_id: string }) => {
    return axios.post('/v2/admin/recom/tag_delete', {
        data,
    });
};
export const updateRecomTag = (data: { tag_id: string; name: string; brief: string }) => {
    return axios.post('/v2/admin/recom/tag_update', {
        data,
    });
};
export const getRecomProductList = (data: { f_sec_type: string }) => {
    return axios.post<RecomProductList>('/v2/admin/recom/product_list', {
        data,
    });
};
export const deleteRecomProduct = (data: { isin: string; tag_id: string }) => {
    return axios.post('/v2/admin/recom/product_delete', {
        data,
    });
};
export const addRecomProduct = (data: { isin: string; tag_id: string }) => {
    return axios.post('/v2/admin/recom/product_add', {
        data,
    });
};

// 审核持仓

export const getUnreviewAccount_list = () => {
    return axios.post<UnreviewAccount_list>('/v1/admin/asset/account_list_unreview', {
        data: {},
    });
};
export const assetReview = (data: { account_id: string; update_date: any }) => {
    return axios.post('/v1/admin/asset/review', {
        data,
    });
};
// 查询持仓
export const assetQueryPending = (data: { account_id: string; update_date: any }) => {
    return axios.post<assetQueryPendingType>('/v1/admin/asset/query_pending', {
        data,
    });
};

//客户列表

//获取RM|IC客户列表
export const getCustomerList = (data: CustomerManageReq) => {
    return axios.post<CustomerManageData>('/v1/admin/crm/customer/getMyCustomList', {
        data,
    });
};
//更新客户信息
export const updateCustomerInfo = (data: {
    id: number;
    name: string;
    mobile?: string;
    email?: string;
    address?: string;
}) => {
    return axios.post('/v1/admin/crm/customer/update', {
        data,
    });
};
export const getCustomerInfo = (data: number) => {
    return axios.post<CustomerInfo>('/v1/admin/crm/customer/getCustomInfo', {
        data,
    });
};
export const createCustomer = (data: {
    name: string;
    mobile: string;
    email?: string;
    address?: string;
}) => {
    return axios.post('/v1/admin/crm/customer/create', {
        data,
    });
};

// 正式用户管理
/**
 * @deprecated
 */
export const getCustomerLists = (data: { searchKey: string }) => {
    return axios.post<CustomList>('/v1/admin/customer/list', {
        data,
    });
};
/**
 * @see getCustomerList
 *
 * @deprecated [老方法，不支持ic搜索]
 */
export const getAllCustomerLists = (data: { searchKey: string }) => {
    return axios.post<CustomList>('/v1/admin/allCustomer/list', {
        data,
    });
};
export const addCustomer = (data: { customer_id: number }) => {
    return axios.post('/v1/admin/user/add', {
        data,
    });
};
export const addRealAccount = (data: {
    uid: string;
    account_type: string;
    account_name: string;
    vendor_account_id: string;
    account_class: number;
    copy_id?: number;
    attr_class?: object;
    asset_class_attr?: object;
}) => {
    return axios.post('/v1/admin/account/add', {
        data,
    });
};

// 结构性产品

export const optionQuoteuUpload = (data: {
    customer_id: number;
    quote_url?: string;
    offset?: number;
    limit?: number;
}) => {
    return axios.post('/v1/admin/optionQuote/upload', {
        data,
    });
};

export const getOptionQuoteuList = (data: {
    customer_id: number;
    quote_url?: string;
    offset?: number;
    limit?: number;
}) => {
    return axios.post<OptionQuoteuList>('/v1/admin/optionQuote/list', {
        data,
    });
};
export const getOptionQuoteListById = (data: { option_quote_id_list: Array<number> }) => {
    return axios.post<OptionQuoteuList>('/v1/admin/optionQuote/listById', {
        data,
    });
};

// 早报助手相关
export const getMorningPostEditorUrl = () => {
    return multiHostAxios(HostType.BAIKE).post('/v1/admin/pedia/daily/information/source/save');
};

export const getMorningPostPreview = () => {
    return multiHostAxios(HostType.BAIKE).post('/v1/admin/pedia/daily/preview/doc');
};

export const publishMorningPost = (data: { docId: string }) => {
    return multiHostAxios(HostType.BAIKE).post('/v1/admin/pedia/daily/publish/daily/report', {
        data,
    });
};

// 权限中心相关
export const getAuthorityMemberList = () => {
    return axios.post('/v1/admin/auth/role/member/list');
};

export const getAuthoritiesWithRoles = () => {
    return axios.post('/v1/admin/auth/role/authority/list');
};

export const addAuthorityRole = (data: { name: string; comment?: string }) => {
    return axios.post('/v1/admin/auth/role/create', { data });
};
export const deleteAuthorityRole = (data: { id: number }) => {
    return axios.post('/v1/admin/auth/role/delete', { data });
};

export const editAuthorityRole = (data: { name: string; id: number }) => {
    return axios.post('/v1/admin/auth/role/update', { data });
};

export const updateRoleForMember = (data: { memberId: number; roleId: number }) => {
    return axios.post('/v1/admin/auth/member/role/reset', { data });
};

export const updateAuthorityForRole = (data: { roleId: number; authorityId: number }) => {
    return axios.post('/v1/admin/auth/role/authority/reset', { data });
};
export const updateAuthorityItemForRole = (data: { roleId: number; operationId: number }) => {
    return axios.post('/v1/admin/auth/role/operation/reset', { data });
};

//客户报价平台

export const queryEditEntry = (data: { customer_id: number }) => {
    return axios.post<queryEditEntryType>('/v2/product/quote/query_edit_entry', {
        data,
    });
};
export const getQuotePreview = (data: { edit_entry_url: string; product_type: string }) => {
    return axios.post<quotePreview>('/v2/product/quote/preview', {
        data,
    });
};
export const getProductQuoteUrl = (data: {
    customer_id: string;
    edit_entry_url: string;
    product_type: string;
}) => {
    return axios.post<{
        product_quote_sheet_id: number;
    }>('/v2/product/quote/get_product_quote_url', {
        data,
    });
};
export const getQuoteOrderList = (data: {
    offset: number;
    limit: number;
    product_type: string;
}) => {
    let url = 'v2/product/quote/get_order_list';
    if (data.product_type === 'fx') {
        url = 'v2/admin/order/matched_fx_orders';
    } else if (data.product_type === 'cash') {
        url = 'v2/admin/order/matched_currency_orders';
    } else if (data.product_type === 'loan') {
        url = 'v2/admin/order/matched_loan_orders';
    } else if (data.product_type === 'deposit') {
        url = 'v2/admin/order/matched_orders_deposit_and_withdraw';
    }
    return axios.post<quoteOrderList>(url, {
        data,
    });
};
export const getQuoteMatchOrderList = (data: {
    offset: number;
    limit: number;
    asset_class: string;
}) => {
    return axios.post<quoteOrderList>('v2/admin/order/matched_orders', {
        data,
    });
};

export const getQuoteOrderTextTemplate = (data: { order_id: number; product_type: string }) => {
    return axios.post<orderTextTemplate>('v2/product/quote/get_order_text_template', {
        data,
    });
};

//rm模拟交易
//买入结构化产品
export const addTradeStruct = (data: {
    uid?: string;
    type: string;
    underlyings: Array<string>;
    account_id: string;
    strike_price: string;
    ko_price: string;
    expiry_date: string;
    day_volume?: string;
    total_price?: string;
    annual_interest?: string;
}) => {
    return axios.post('/v1/admin/trade/struct/add', {
        data,
    });
};

//卖出持仓产品
export const sellTradePosition = (data: {
    uid: string;
    isin: string;
    qty: string;
    account_id: string;
    deal_price: string;
}) => {
    return axios.post('/v1/admin/trade/position/sell', {
        data,
    });
};
//买入期权产品
export const addTradeOption = (data: {
    uid: string;
    underlying: string;
    qty: string;
    account_id: string;
    option_type: string;
    expiry_date: string;
    strike_price: string;
    option_premium: string;
}) => {
    return axios.post('/v1/admin/trade/option/add', {
        data,
    });
};
//买入交易所产品
export const addTradeExchange = (data: {
    uid: string;
    isin: string;
    qty: string;
    account_id: string;
    deal_price: string;
}) => {
    return axios.post('/v1/admin/trade/exchange/add', {
        data,
    });
};

//查询持仓
export const queryPosition = (data: { uid: string; isin: string; account_id: string }) => {
    return axios.post('/v1/admin/position/query', {
        data,
    });
};
//待买入产品实时信息查询
export const availQueryTradeProduct = (data: {
    uid: string;
    isin: string;
    qty: string;
    account_id: string;
    deal_price: string;
}) => {
    return axios.post('/v1/admin/trade/product/avail_query', {
        data,
    });
};

export const queryExchangeRate = (data: { currency: string }) => {
    return axios.post('/v2/misc/query_fx', {
        data,
    });
};

export const getAdvisorTwrUser = (data: any) => {
    return axios.post<AdvisorTwrUserType>('/v1/admin/advisor/twr/user', {
        data,
    });
};

export const getCategorizedPositions = (data: { uid: string }) => {
    return axios.post('/v1/admin/user/categorized_positions', { data });
};

export const updateAccountPosition = (data: {
    isin: string;
    type: string;
    account_id: string;
    qty_delta: string;
}) => {
    return axios.post('/v1/admin/position/update', { data });
};
export const updatePushSetting = (data: { push_open: number }) => {
    return axios.post('/v1/admin/push_setting', { data });
};

export const getPushSettingInfo = () => {
    return axios.post<{ status: number }>('/v1/admin/push_setting/query', {});
};
