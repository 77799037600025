import { AccountInfoType } from '../../../service/requests/CustomerManage';
import axios from '../../../service/tools';

interface UpdateAccountParams {
    account_id: string;
    account_type?: string;
    account_name?: string;
    vendor_account_id?: string;
    attr_class?: object;
    asset_class_attr?: object;
    effective_at?: number;
    tag: number;
    display_name?: string;
}

export const updateAccount = (data: UpdateAccountParams) => {
    console.log(data);
    return axios.post('/v1/admin/account/update', {
        data,
    });
};
export interface EditListInfo {
    total_count: number;
    total_pages: number;
    current_page: number;
    page_size: number;
    summary: {
        customer_id: number;
        customer_name: string;
        bank_name: string;
        account_name: string;
        vendor_sub_account_id: string;
        histories: {
            id: number;
            product_name: string;
            asset_type: string;
            operation: string;
            edit_time: string;
        }[];
    };
}
export interface AccountAttr {
    account_name: string;
    attr_class?: {
        custodian_fee?: string;
    };
    asset_class_attr?: {
        custodian_fee?: {
            [key: string]: string;
        };
    };
    effective_at?: number;
}

export const getAccountAttr = (data: { account_id: string }) => {
    return axios.post<{ attr: AccountAttr; account: AccountInfoType }>('/v1/admin/account/query', {
        data,
    });
};

export interface AssetItem {
    text: string;
    value: string;
}

export const getAssetClasses = () => {
    return axios.post<{ items: AssetItem[] }>('/v2/misc/dict_entry', {
        data: {
            entry_id: 'attr_asset_class',
        },
    });
};
//查看初始持仓编辑记录摘要
export const getEditListInfo = (data: {
    account_id: string;
    page_size: number;
    current_page: number;
}) => {
    return axios.post<EditListInfo>('/v1/admin/position_edit_history/summary', {
        data,
    });
};

//查看初始持仓编辑记录详情
export const getEditDetail = (data: { id: number }) => {
    return axios.post<{ special_fields: { [key: string]: any } }>(
        '/v1/admin/position_edit_history/detail',
        {
            data,
        }
    );
};
