import React, { useEffect } from 'react';
import styles from './index.module.scss';
import { Button, Modal, Pagination, Tabs, Tooltip, message } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import {
    ArticleType,
    SyncArticleListType,
    SyncArticleStatusType,
    SyncPushStatusType,
    acticleList as getActicleList,
    selectActicle,
    showLocationType,
    updateOnlineStatus,
} from './type';
import moment from 'moment-timezone';
import { useLatest, useRequest } from 'ahooks';
import usePageState from '@src/utils/Hooks/usePageState';
import { useHistory } from 'react-router-dom';
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    BellOutlined,
    CopyOutlined,
    EditOutlined,
    InfoCircleFilled,
    StarFilled,
    StarOutlined,
} from '@ant-design/icons';
import SyncArticlePush from './SyncArticlePush';
import copy from 'copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { LKRootState } from '@src/store/store';
import { setSyncPageIndex } from '@src/store/actions/actions';
const { TabPane } = Tabs;
export const SyncIndex = () => {
    const syncIndex = useSelector((state: LKRootState) => state.config.syncPageIndex);
    const dispatch = useDispatch();
    return (
        <Tabs
            defaultActiveKey={syncIndex ?? '1'}
            onChange={(index) => {
                dispatch(setSyncPageIndex(index));
            }}
        >
            <TabPane key={'1'} tab="App资讯同步">
                <SyncArticle type={ArticleType.Featured} />
            </TabPane>
            <TabPane key={'2'} tab="管家服务同步">
                <SyncArticle type={ArticleType.HouseKeeper} />
            </TabPane>
        </Tabs>
    );
};
const SyncArticle = ({ type }: { type: ArticleType }) => {
    const { dispacth, selector } = usePageState();
    const [currentPage, setCurrentPage] = React.useState(
        (type === ArticleType.Featured ? selector?.page : selector?.second_page) ?? 1
    );
    const [pageSize, setPageSize] = React.useState(
        (type === ArticleType.Featured ? selector?.page_size : selector?.second_page_size) ?? 20
    );
    const latestPageSize = useLatest(pageSize);
    const latestCurrentPage = useLatest(currentPage);
    const [pushModalVisible, setPushModalVisible] = React.useState(false);
    const [currentArticle, setCurrentArticle] = React.useState<SyncArticleListType | null>(null);
    const [updateOnlineLoading, setUpdateOnlineLoading] = React.useState(false);
    const [, setFeatureStatusLoading] = React.useState(false);
    const latestCurrentArticle = useLatest(currentArticle);
    const [currentTime, setCurrentTime] = React.useState(moment().format('YYYY-MM-DD HH:mm:ss'));
    const histort = useHistory();
    const { data: acticleList, run, params, refresh } = useRequest(getActicleList, {
        defaultParams: [
            {
                current_page: currentPage,
                page_size: pageSize,
                type: type,
            },
        ],
        onFinally: () => {
            setCurrentTime(moment().format('YYYY-MM-DD HH:mm:ss'));
        },
    });
    //定时3秒同步一次列表接口
    useEffect(() => {
        const timer = setInterval(() => {
            run({
                ...params[0]!,
                type,
                current_page: latestCurrentPage.current,
                page_size: latestPageSize.current,
            });
        }, 5000);
        return () => {
            clearInterval(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const changeOnlineStatus = (record: SyncArticleListType) => {
        setUpdateOnlineLoading(true);
        updateOnlineStatus({
            doc_id: record.doc_id,
            online_status: record.online === 1 ? 2 : 1,
        })
            .then((res) => {
                refresh();
            })
            .catch((err) => {
                message.error(err.msg);
            })
            .finally(() => {
                setUpdateOnlineLoading(false);
            });
    };
    const columns: ColumnsType<SyncArticleListType> = [
        {
            title: '上线',
            dataIndex: 'online',
            width: '50px',
            render(value: any, record: any) {
                return (
                    <span
                        style={{
                            color: value === 1 ? 'green' : 'gray',
                        }}
                    >
                        {value === 1 ? '是' : '否'}
                    </span>
                );
            },
        },
        {
            title: '精选',
            dataIndex: 'feature_status',
            width: '50px',
            render(value: any, record: any) {
                return value === 1 ? '是' : '否';
            },
        },
        {
            title: '同步时间',
            dataIndex: 'updated_at',
            width: '170px',
            render(value: any, record: any) {
                return moment(value).format('YYYY-MM-DD HH:mm:ss');
            },
        },
        {
            title: '同步状态',
            dataIndex: 'release_status',
            width: '80px',
            render(value, record) {
                if (record.online === 2) {
                    return '-';
                }
                return SyncArticleStatusType[value] || '-';
            },
        },
        {
            title: 'Push状态',
            dataIndex: 'push_task_status',
            width: '80px',
            render(value) {
                return value?.desc || '-';
            },
        },
        {
            title: '文章标题',
            dataIndex: 'doc_title',
            render(value, record) {
                return (
                    <span
                        style={{
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            window.open(record.doc_url);
                        }}
                    >
                        {value}
                    </span>
                );
            },
        },
        {
            title: 'id',
            dataIndex: 'doc_id',
            width: '60px',
        },
        {
            title: '展示位置',
            dataIndex: 'release_channel',
            width: '200px',
            render(value) {
                return value
                    .map((item: showLocationType) => {
                        return showLocationType[item];
                    })
                    .join('、');
            },
        },
        {
            title: '操作人',
            dataIndex: ['updater', 'name'],
            width: '120px',
        },
        {
            fixed: 'right',
            title: '操作',
            key: 'action',
            width: '240px',
            render(record: SyncArticleListType) {
                return (
                    <>
                        <Tooltip title="编辑">
                            <Button
                                icon={<EditOutlined />}
                                disabled={!record.doc_id || record.release_status === 3}
                                onClick={(e) => {
                                    // e.stopPropagation();
                                    // histort.push(
                                    //     '/app/activity/articleSync/detail?id=' + record.id
                                    // );
                                }}
                            />
                        </Tooltip>
                        <Tooltip title={record.online === 1 ? '下线' : '上线'}>
                            <Button
                                loading={updateOnlineLoading}
                                icon={
                                    record.online === 1 ? (
                                        <ArrowDownOutlined />
                                    ) : (
                                        <ArrowUpOutlined />
                                    )
                                }
                                disabled={!record.doc_id || record.release_status !== 1}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    Modal.confirm({
                                        title: `是否确认${
                                            record.online === 1 ? '下线' : '上线'
                                        }该文章？`,
                                        content:
                                            record.online === 1
                                                ? '下线后此文章将在列表中保留，可再次同步'
                                                : '上线后此文章将在所选位置展示',
                                        okText: '确认',
                                        cancelText: '取消',
                                        onOk() {
                                            changeOnlineStatus(record);
                                        },
                                    });
                                }}
                            />
                        </Tooltip>
                        {record.online === 1 && (
                            <>
                                <Tooltip title="App Push">
                                    <Button
                                        disabled={
                                            record.release_channel.findIndex(
                                                (item) => item === showLocationType['鹿可App']
                                            ) === -1 ||
                                            !record.doc_id ||
                                            record.release_status !== 1
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setPushModalVisible(true);
                                            setCurrentArticle(record);
                                        }}
                                        icon={<BellOutlined />}
                                    />
                                </Tooltip>

                                <Tooltip title="复制链接">
                                    <Button
                                        icon={<CopyOutlined />}
                                        disabled={!record.doc_id || record.release_status !== 1}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (copy(encodeURI(record.doc_share_link))) {
                                                message.success('复制成功');
                                            } else {
                                                message.error('复制失败');
                                            }
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip
                                    title={record.feature_status === 1 ? '取消精选' : '设为精选'}
                                >
                                    <Button
                                        disabled={!record.doc_id || record.release_status !== 1}
                                        type="text"
                                        icon={
                                            record.feature_status === 1 ? (
                                                <StarFilled style={{ color: '#ffc612' }} />
                                            ) : (
                                                <StarOutlined />
                                            )
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            let title = '';
                                            let content = '';
                                            if (record.feature_status === 1) {
                                                title = '是否取消该文章精选？';
                                                content =
                                                    '取消后此文章将不再作为精选展示 可在列表中将文章重新设为精选';
                                            } else {
                                                title = '是否将该文章设为精选？';
                                                content = '设置后此文章将作为精选展示';
                                            }
                                            setFeatureStatusLoading(true);
                                            Modal.confirm({
                                                title,
                                                content,
                                                okText: '确认',
                                                cancelText: '取消',
                                                onOk() {
                                                    selectActicle({
                                                        doc_id: record.doc_id,
                                                        feature_status:
                                                            record.feature_status === 1 ? 2 : 1,
                                                    })
                                                        .catch((err) => {
                                                            console.log(err.msg);
                                                        })
                                                        .finally(() => {
                                                            setFeatureStatusLoading(false);
                                                            refresh();
                                                        });
                                                },
                                            });
                                        }}
                                    />
                                </Tooltip>
                            </>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <div className={styles.container}>
            <Modal
                visible={pushModalVisible && latestCurrentArticle.current !== null}
                title="App Push"
                width={800}
                destroyOnClose
                className={styles.pushModal}
                footer={null}
                onCancel={() => {
                    setPushModalVisible(false);
                    setCurrentArticle(null);
                }}
            >
                <SyncArticlePush
                    setVisible={setPushModalVisible}
                    article={latestCurrentArticle.current!}
                />
            </Modal>
            <div className={styles.sync}>
                <Button
                    type="primary"
                    onClick={() => {
                        histort.push('/app/activity/articleSync/detail?type=' + type);
                    }}
                >
                    同步新文章
                </Button>
            </div>

            <div className={styles.table}>
                <Table
                    columns={columns}
                    key={'id'}
                    size="small"
                    pagination={false}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                if (record.release_status !== 1) return;
                                histort.push(
                                    '/app/activity/articleSync/detail?id=' +
                                        record.id +
                                        '&type=' +
                                        type
                                );
                            },
                        };
                    }}
                    dataSource={acticleList?.data.doc_releases}
                    scroll={{ x: 1700, y: 'calc(100vh - 350px)' }}
                />
                <div className={styles.titleTipIcon}>
                    <InfoCircleFilled />
                    同步预计等待时间3到5分钟 | {`上次列表更新时间:${currentTime}`}
                </div>
            </div>

            <Pagination
                className={styles.page}
                total={acticleList?.data.total_count}
                showTotal={(total, range) => {
                    return `总条数：${total}`;
                }}
                pageSize={pageSize}
                onShowSizeChange={(current, size) => {
                    if (type === ArticleType.Featured) {
                        dispacth({ page_size: size });
                    } else {
                        dispacth({ second_page_size: size });
                    }
                    setPageSize(size);
                }}
                onChange={(page, pageSize) => {
                    setCurrentPage(page);
                    if (type === ArticleType.Featured) {
                        dispacth({ page: page });
                    } else {
                        dispacth({ second_page: page });
                    }
                    setPageSize(pageSize || 20);
                    run({
                        ...params[0]!,
                        type,
                        current_page: page,
                        page_size: pageSize,
                    });
                }}
                showSizeChanger
                current={currentPage}
            />
        </div>
    );
};

export default SyncArticle;
