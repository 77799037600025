import { BaseReqParam } from '..';
import axios from '../tools';
export interface QueryDailyNewsReq extends BaseReqParam {
    type: number;
}
export type DailyNewsInfo = {
    daily_news_list: {
        id: number;
        title: string;
        content: string;
        is_all_user: number;
        type: number; // #1大事日历，2账户提醒，3热门问答
        doc_feishu_url: string;
        look_tags: DailyTag[]; // #不选择或者选全部标签都传null，其它情况传标签数组
        public_at: number;
        state_code: number;
        state: string; // "待发布(未到发布时间)","已发布(已过发布时间)", "已下线(手动下线)", "已过期(已过有效时间)",
    }[];
    total_count: 50;
};
export type DailyNewsReq = {
    id?: string;
    title?: string;
    content?: string;
    is_all_user: number;
    type: number; // #1大事日历，2账户提醒，3热门问答
    doc_feishu_url?: string;
    tag_ids?: string[]; // #不选择或者选全部标签都传null，其它情况传标签数组
    public_at: number;
};

export type DailyTag = { tag_name: string; id: number };
export const QueryDailyNews = (data: QueryDailyNewsReq) => {
    return axios.post<DailyNewsInfo>('/v1/admin/daily_news/list', { data });
};
export const AddDailyNews = (data: DailyNewsReq) => {
    return axios.post('/v1/admin/daily_news/add', { data });
};
export const UpdateDailyNews = (data: DailyNewsReq) => {
    return axios.post('/v1/admin/daily_news/update', { data });
};
export const DeleteDailyNews = (data: { id: number }) => {
    return axios.post('/v1/admin/daily_news/delete', { data });
};
export const queryDailyTags = () => {
    return axios.post<DailyTag[]>('/v1/admin/daily_news/look_tags', {});
};
