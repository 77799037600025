import React, { useEffect, useState } from 'react';
import { AssetType } from '../../Transaction/type';
import { LKMultipleCheckbox } from '../../../components/LKMultipleCheckbox';
import ResidualMaturity from '../../Quotation/Component/ResidualMaturity';
import { getBondCreditRatings } from '../request';

interface BondFilterProps {
    type: AssetType;
    onFilterChange: (value: any) => void;
}
const BondFilter = (props: BondFilterProps) => {
    const { type, onFilterChange } = props;
    const [ratings, setRatings] = useState([]);
    useEffect(() => {
        getBondCreditRatings().then((res) => {
            setRatings(res.data);
        });
    }, []);
    return (
        <div>
            {type === AssetType.CorporateBond && (
                <LKMultipleCheckbox
                    plainOptions={ratings.map((item) => {
                        return {
                            value: item,
                            label: item,
                        };
                    })}
                    valueChange={(item) => {
                        let obj: { [key: string]: string[] } = {};
                        obj['credit_ratings'] = item;
                        onFilterChange(obj);
                    }}
                    title="S&P"
                />
            )}
            <ResidualMaturity onChange={onFilterChange} />
        </div>
    );
};

export default BondFilter;
