import axios from '../../service/tools';
import {
    BondQuotesDetails,
    ProductQuoteDetailType,
    ProductQuoteResponseType,
    UserAccountInfo,
} from './type';
import { AssetType } from '../Transaction/type';
const queryQouteList = (data: { current_page: number; page_size: number }) => {
    return axios.post<ProductQuoteResponseType>('gk/v1/asset/quote/list', { data });
};
const addQouteRecord = (data: {
    client_id: number;
    account_id: string;
    asset_type_code: AssetType;
}) => {
    return axios.post<{ asset_quote: { id: number } }>('gk/v1/asset/quote/add', { data });
};
const copyQouteRecord = (data: {
    asset_quote_id: number;
    client_id: number;
    account_id: string;
    asset_type_code: AssetType;
}) => {
    return axios.post<{ asset_quote: { id: number } }>('gk/v1/asset/quote/copy', { data });
};

const queryQouteRecord = (data: { asset_quote_id: number }) => {
    return axios.post<{ asset_quote_detail: ProductQuoteDetailType }>('gk/v1/asset/quote/detail', {
        data,
    });
};
const calculateQuotePrice = (data: { asset_quote_detail: ProductQuoteDetailType }) => {
    return axios.post<{ asset_quote_detail: ProductQuoteDetailType }>(
        'gk/v1/asset/quote/calculate_and_check',
        { data }
    );
};
// 同步到消息中心
const syncMsg = (data: { asset_quote_id: string }) => {
    return axios.post('gk/v1/asset/quote/sync_to_msg_center', { data });
};
//查询客户下某个账户的现金持仓摘要及安全垫
const queryCashPositionSummary = (data: { asset_quote_id: string }) => {
    return axios.post<UserAccountInfo>('gk/v1/position/cash/summary', { data });
};
//保存产品报价
const saveRecord = (data: { asset_quote_detail: ProductQuoteDetailType }) => {
    return axios.post('gk/v1/asset/quote/save', { data });
};
//撤销报价
const revokeQuote = (data: { asset_quote_id: string }) => {
    return axios.post('gk/v1/asset/quote/cancel', { data });
};
//确认报价并发送Lark提醒
const qouteConfirm = (data: { asset_quote_id: string }) => {
    return axios.post('gk/v1/asset/quote/confirm', { data });
};
// 发送email页面 获取详细信息
const getBondQuotesDetails = (data: { asset_quote_id: string }) => {
    return axios.post<BondQuotesDetails>('v5/admin/product/quote/info', { data });
};

export {
    queryQouteList,
    qouteConfirm,
    revokeQuote,
    addQouteRecord,
    queryQouteRecord,
    calculateQuotePrice,
    syncMsg,
    queryCashPositionSummary,
    saveRecord,
    copyQouteRecord,
    getBondQuotesDetails,
};
