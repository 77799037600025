/**
 * Created by 叶子 on 2017/8/13.
 */
import React, { useMemo } from 'react';
import DocumentTitle from 'react-document-title';
import { Route, Redirect, Switch } from 'react-router-dom';
import EmptyRouterPage from '../modules/SpecialPages/EmptyRouterPage';
import NoAccessPage from '../modules/SpecialPages/NoAccessPage';
import { AllowedMenuList } from '../service/type';
import menuMapping from './menu.mapping';
import regularRoutes from './regular-routes.config';

interface ContentSwitchProps {
    allowedMenuList?: AllowedMenuList;
}

const ContentSwitch = ({ allowedMenuList }: ContentSwitchProps) => {
    const menus = useMemo(() => {
        const res: AllowedMenuList = [];
        allowedMenuList?.forEach((menu) => {
            res.push(menu);
            if (Array.isArray(menu.children)) {
                res.push(...menu.children);
            }
        });
        return res;
    }, [allowedMenuList]);

    if (!allowedMenuList) {
        return null;
    }

    return (
        <>
            <Switch>
                {menus.map((menu) => (
                    <Route
                        exact
                        key={menu.id}
                        path={menu.entrance}
                        render={() => {
                            const Component = menuMapping[menu.entrance ?? ''] ?? EmptyRouterPage;
                            return (
                                <DocumentTitle title={menu.name}>
                                    <Component />
                                </DocumentTitle>
                            );
                        }}
                    />
                ))}
                {menus.length ? (
                    <Route
                        exact
                        path="/app"
                        render={() => (
                            <Redirect
                                to={
                                    menus[0].children
                                        ? menus[0].children[0].entrance
                                        : menus[0].entrance
                                }
                                push
                            />
                        )}
                    />
                ) : (
                    <Route path="/" render={() => <NoAccessPage />} />
                )}
                {regularRoutes.map((route) => (
                    <Route
                        exact
                        key={route.path}
                        path={route.path}
                        render={() => (
                            <DocumentTitle title={route.title}>
                                <route.component />
                            </DocumentTitle>
                        )}
                    />
                ))}
                <Route render={() => <EmptyRouterPage />} />
            </Switch>
        </>
    );
};

export default ContentSwitch;
