import React, { useEffect, useState } from 'react';
import { AssetType } from '../../Transaction/type';
import { showError } from '../../RecommendationComponent/util';
import QuotationMenu from '../../Quotation/Component/Menu';
import { FilterCriteria, queryAccountFilterCriteria, queryFilterCriteria } from '../request';

const FilterHeader = ({
    type,
    onChange,
    isQuote,
    accountId,
    isDetail,
}: {
    type: AssetType;
    isQuote: boolean;
    accountId?: string;
    isDetail?: boolean;
    onChange?: (value: {
        underlyings?: string[];
        bank_ids?: string[];
        update_time_type_code?: number;
    }) => void;
}) => {
    const [headData, setHeadData] = useState<FilterCriteria>();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [value, setValue] = useState<{
        underlyings: string[];
        bank_ids: string[];
        update_time_type: number;
    }>();
    useEffect(() => {
        isQuote
            ? queryAccountFilterCriteria({
                  asset_type_code: type,
                  account_id: accountId || '',
              }).then((e) => {
                  setHeadData(e.data);
              }, showError)
            : queryFilterCriteria({ asset_type_code: type }).then((e) => {
                  setHeadData(e.data);
              }, showError);
    }, [type, accountId, isQuote]);

    return (
        <QuotationMenu<{ value: any; label: string }>
            dataList={{
                underlyings: (headData?.assets ?? []).map((item) => {
                    return {
                        label: item.spec_name ?? item.name,
                        value: item.isin,
                    };
                }),
                bank_ids: (headData?.banks ?? []).map((item) => {
                    return {
                        label: item.short_name,
                        value: item.id,
                    };
                }),
                ...(isDetail
                    ? {}
                    : {
                          update_times: (headData?.update_time_types ?? []).map((item) => {
                              return {
                                  label: item.desc,
                                  value: item.code,
                              };
                          }),
                      }),
            }}
            cellValue={(res) => {
                return res.value;
            }}
            cellLable={(res) => {
                return res.label;
            }}
            onChange={(item) => {
                setValue((old) => {
                    let newItem = {
                        ...old,
                        ...(item as any),
                    };
                    if (Object.keys(item).findIndex((item) => item === 'update_time') > -1) {
                        newItem.update_time_type = item.update_time;
                    }
                    delete newItem.update_time;
                    if (isDetail) {
                        delete newItem.update_time_type;
                    }
                    onChange && onChange(newItem);
                    return newItem;
                });
            }}
        />
    );
};

export default FilterHeader;
