import moment from 'moment-timezone';
import React from 'react';
import {
    BankType,
    BaseExtInfo,
    CustodyType,
    ELNType,
    FundStruct,
    TransAQExtInfo,
    TransAdjustmentAssetType,
    TransAdjustmentExtInfo,
    TransBondExtInfo,
    TransDepositExtInfo,
    TransELNExtInfo,
    TransEquitiesExtInfo,
    TransExchangeExtInfo,
    TransFcnExtInfo,
    TransFundExtInfo,
    TransLoanExtInfo,
    TransOptionExtInfo,
    TransTransferExtInfo,
    TransactionInfo,
    TransactionType,
    UnitType,
} from '../type';
import { FormatNumber } from '../../../utils';
import { OptionSide, OptionType, fcnCouponFrequency } from '../../GKProductPrice/Form/data';
import { isUndefined } from 'mathjs';
import { ReactNode } from 'react';
import { createHashHistory } from 'history';
export const formatDate = (ts: number, timezone?: string) => {
    let ret = '';
    if (ts) {
        ret = moment.tz(ts, timezone || moment.tz.guess()).format('yyyy-MM-DD');
    }
    return ret;
};
const getEquitiesPriceType = (extInfo: TransEquitiesExtInfo) => {
    let ret = '';
    switch (extInfo.price_type) {
        case 1:
            ret = 'LMT';
            break;
        case 2:
            ret = 'MKT';
            break;
        case 3:
            ret = 'MKT Close';
            break;
        case 4:
            ret = 'MKT Open';
            break;
        case 5:
            ret = 'VWAP';
            break;
        case 6:
            ret = extInfo?.custom_price_type || '';
            break;
    }
    return ret;
};

const getStructPriceType = (priceType?: number) => {
    let ret = '';
    switch (priceType) {
        case 1:
            ret = 'LMT';
            break;
        case 2:
            ret = 'MKT';
            break;
        case 3:
            ret = 'MKT Close';
            break;
        case 4:
            ret = 'MKT Open';
            break;
        case 5:
            ret = 'VWAP';
            break;
        default:
            break;
    }
    return ret;
};

const getCouponFrequency = (fre: number) => {
    return (
        Object.keys(fcnCouponFrequency).find(
            (key) => fcnCouponFrequency[key as keyof typeof fcnCouponFrequency] === fre
        ) || ''
    );
};

const getLeverage = (leverage: number) => {
    return leverage === 1 ? 'non-leveraged' : 'leveraged';
};

export const getOptionType = (type: number) => {
    return (
        Object.keys(OptionType).find(
            (key) => OptionType[key as keyof typeof OptionType] === type
        ) || ''
    );
};

export const getOptionSide = (side: number) => {
    return (
        Object.keys(OptionSide).find(
            (key) => OptionSide[key as keyof typeof OptionSide] === side
        ) || ''
    );
};

const isNotUndefined = (x: unknown) => {
    return !isUndefined(x);
};

export const getTransBirefDetails = (item: TransactionInfo) => {
    const history = createHashHistory();
    let ret = [] as { title: string; value: string; width?: string; node?: ReactNode }[];
    let accoutItem = [
        {
            title: 'Account Number',
            value: item.account
                ? `${item.account?.bank_name}-${item.account?.vendor_sub_account_id || ''}`
                : '',
        },
    ];
    let currencyItem = [
        {
            title: 'Currency',
            value: item.ext_info?.currency || '',
        },
    ];
    let extInfo;
    switch (item.type.code) {
        case TransactionType['Place Deposit']:
            extInfo = item.ext_info as TransDepositExtInfo;
            ret = [
                ...accoutItem,
                { title: 'Value Date', value: formatDate(item.value_date) },
                { title: 'Tenor', value: extInfo?.tenor || '' },
                {
                    title: 'Maturity Date',
                    value: isNotUndefined(extInfo?.maturity_date)
                        ? formatDate(extInfo?.maturity_date)
                        : '',
                },
                { title: 'Roll Type', value: extInfo?.roll_type || '' },
                { title: 'Currency', value: extInfo?.currency || '' },
                {
                    title: 'Dealer Rate',
                    value: isNotUndefined(extInfo?.dealer_rate)
                        ? `${(FormatNumber(parseFloat(extInfo.dealer_rate) / 100), 2)}%`
                        : '',
                },
                {
                    title: 'Spread',
                    value: isNotUndefined(extInfo?.spread)
                        ? `${FormatNumber(parseFloat(extInfo.spread) / 100, 2)}% ${
                              extInfo.spread
                          }bps`
                        : '',
                },
                {
                    title: 'Client Rate',
                    value: isNotUndefined(extInfo?.client_rate)
                        ? `${FormatNumber(parseFloat(extInfo.client_rate) / 100, 2)}%`
                        : '',
                },
                {
                    title: 'Principal Amount',
                    value: isNotUndefined(extInfo?.principal_amount)
                        ? FormatNumber(extInfo.principal_amount!, 2)
                        : '',
                },
                {
                    title: 'Interest Amount',
                    value: isNotUndefined(extInfo?.interest_amount)
                        ? FormatNumber(extInfo.interest_amount!, 2)
                        : '',
                },
                {
                    title: 'Total Amount',
                    value: isNotUndefined(extInfo?.total_amount)
                        ? FormatNumber(extInfo.total_amount!, 2)
                        : '',
                },
            ];
            break;
        case TransactionType['Deposit Maturity']:
            extInfo = item.ext_info as TransDepositExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'Asset Type',
                    value: item.assets.length > 0 ? item.assets?.[0].asset_type.desc : '',
                },
                {
                    title: 'Currency',
                    value: extInfo.currency || '',
                },
                {
                    title: 'Maturity Date',
                    value: isNotUndefined(extInfo?.principal_amount)
                        ? formatDate(extInfo.maturity_date)
                        : '',
                },
                {
                    title: 'Principal Amount',
                    value: isNotUndefined(extInfo?.principal_amount)
                        ? FormatNumber(extInfo.principal_amount, 2)
                        : '',
                },
                {
                    title: '',
                    value: '',
                },
            ];
            break;
        case TransactionType['Deposit Interest']:
            extInfo = item.ext_info as TransDepositExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'Asset Type',
                    value: item.assets.length > 0 ? item.assets?.[0].asset_type.desc : '',
                },
                {
                    title: 'Currency',
                    value: extInfo.currency || '',
                },
                {
                    title: 'Maturity Date',
                    value: isNotUndefined(extInfo?.principal_amount)
                        ? formatDate(extInfo.maturity_date)
                        : '',
                },
                {
                    title: 'Interest Amount',
                    value: isNotUndefined(extInfo?.interest_amount)
                        ? FormatNumber(extInfo.interest_amount, 2)
                        : '',
                },
                {
                    title: '',
                    value: '',
                },
            ];
            break;
        case TransactionType['Take Loan']:
            extInfo = item.ext_info as TransLoanExtInfo;
            ret = [
                ...accoutItem,
                { title: 'Value Date', value: formatDate(item.value_date) },
                { title: 'Tenor', value: extInfo ? extInfo.tenor : '' },
                {
                    title: 'Maturity Date',
                    value: isNotUndefined(extInfo.maturity_date)
                        ? formatDate(extInfo.maturity_date)
                        : '',
                },
                { title: 'Currency', value: extInfo.currency || '' },
                {
                    title: 'Dealer Rate',
                    value: isNotUndefined(extInfo?.dealer_rate)
                        ? `${FormatNumber(parseFloat(extInfo.dealer_rate) / 100)}%`
                        : '',
                },
                {
                    title: 'Spread',
                    value: isNotUndefined(extInfo?.spread)
                        ? `${FormatNumber(parseFloat(extInfo.spread) / 100)}% ${extInfo.spread}bps`
                        : '',
                },
                {
                    title: 'Client Rate',
                    value: isNotUndefined(extInfo?.client_rate)
                        ? `${FormatNumber(parseFloat(extInfo.client_rate) / 100)}%`
                        : '',
                },
                {
                    title: 'Principal Amount',
                    value: isNotUndefined(extInfo?.principal_amount)
                        ? FormatNumber(extInfo.principal_amount, 2)
                        : '',
                },
                {
                    title: 'Interest Amount',
                    value: isNotUndefined(extInfo?.interest_amount)
                        ? FormatNumber(extInfo.interest_amount, 2)
                        : '',
                },
                {
                    title: 'Total Amount',
                    value: isNotUndefined(extInfo?.total_amount)
                        ? FormatNumber(extInfo.total_amount, 2)
                        : '',
                },
                {
                    title: '',
                    value: '',
                },
            ];
            break;
        case TransactionType['Loan Maturity']:
            extInfo = item.ext_info as TransLoanExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'Asset Type',
                    value: item.assets.length > 0 ? item.assets?.[0].asset_type.desc : '',
                },
                { title: 'Currency', value: extInfo.currency || '' },
                {
                    title: 'Maturity Date',
                    value: isNotUndefined(extInfo.maturity_date)
                        ? formatDate(extInfo.maturity_date)
                        : '',
                },

                {
                    title: 'Principal Amount',
                    value: isNotUndefined(extInfo?.principal_amount)
                        ? FormatNumber(extInfo.principal_amount, 2)
                        : '',
                },
                {
                    title: '',
                    value: '',
                },
            ];
            break;
        case TransactionType['Loan Interest']:
            extInfo = item.ext_info as TransLoanExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'Asset Type',
                    value: item.assets.length > 0 ? item.assets?.[0].asset_type.desc : '',
                },
                { title: 'Currency', value: extInfo.currency || '' },
                {
                    title: 'Maturity Date',
                    value: isNotUndefined(extInfo.maturity_date)
                        ? formatDate(extInfo.maturity_date)
                        : '',
                },

                {
                    title: 'Interest Amount',
                    value: isNotUndefined(extInfo?.interest_amount)
                        ? FormatNumber(extInfo.interest_amount, 2)
                        : '',
                },
                {
                    title: '',
                    value: '',
                },
            ];
            break;
        case TransactionType['Transfer']:
            extInfo = item.ext_info as TransTransferExtInfo;
            ret = [
                {
                    title: 'Transfer Type',
                    value: (extInfo?.transfer_type as any)?.mailContent || '',
                },
                {
                    title: extInfo?.transfer_scope?.desc ? 'Scope' : '',
                    value: extInfo?.transfer_scope?.desc || '',
                },
                {
                    title: '',
                    value: '',
                },
                {
                    title: '',
                    value: '',
                },

                {
                    title: '',
                    value: '',
                },
                {
                    title: '',
                    value: '',
                },
            ];
            break;
        case TransactionType['Foreign Exchange']:
            extInfo = item.ext_info as TransExchangeExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'Buy',
                    value: extInfo?.buy_currency || '',
                },
                {
                    title: 'Buy Amount',
                    value: isNotUndefined(extInfo?.buy_amount)
                        ? FormatNumber(extInfo?.buy_amount, 2)
                        : '',
                },
                {
                    title: 'Sell',
                    value: extInfo?.sell_currency || '',
                },
                {
                    title: 'Sell Amount',
                    value: isNotUndefined(extInfo?.sell_amount)
                        ? FormatNumber(extInfo?.sell_amount, 2)
                        : '',
                },
                {
                    title: 'Client Rate',
                    value: extInfo?.rate || '',
                },
            ];
            break;
        case TransactionType['Buy Bond']:
        case TransactionType['Sell Bond']:
            extInfo = item.ext_info as TransBondExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'ISIN',
                    value: extInfo?.isin || '',
                    node: (
                        <span
                            className="lk_link"
                            onClick={() => {
                                extInfo = item.ext_info as BaseExtInfo;
                                history.push(
                                    `/app/assetManagement/position/detail/${
                                        item.account.account_id
                                    }/${extInfo!.isin}`
                                );
                            }}
                        >
                            {extInfo.isin || ''}
                        </span>
                    ),
                },
                {
                    title: 'Name',
                    value: extInfo?.name || '',
                    width: '33.332%',
                    node: (
                        <span
                            className="lk_link"
                            onClick={() => {
                                extInfo = item.ext_info as BaseExtInfo;
                                history.push(
                                    `/app/assetManagement/position/detail/${
                                        item.account.account_id
                                    }/${extInfo!.isin}`
                                );
                            }}
                        >
                            {extInfo?.name || ''}
                        </span>
                    ),
                },
                {
                    title: 'Currency',
                    value: extInfo?.currency || '',
                },
                {
                    title: 'Quantity',
                    value: isNotUndefined(extInfo?.qty) ? FormatNumber(extInfo?.qty) : '',
                },
                {
                    title: 'Validity',
                    value:
                        extInfo?.is_day_order === 1
                            ? 'Day Order'
                            : moment(extInfo?.validity).format('yyyy-MM-DD'),
                },
                {
                    title: 'Price Type',
                    value: extInfo?.price_type ? (extInfo?.price_type === 1 ? 'LMT' : 'MKT') : '',
                },
                {
                    title: 'Filled Quantity',
                    value: isNotUndefined(extInfo?.fill_qty) ? FormatNumber(extInfo?.fill_qty) : '',
                },
                {
                    title: 'Filled Price',
                    value: isNotUndefined(extInfo?.fill_price)
                        ? FormatNumber(extInfo?.fill_price, 2)
                        : '',
                },
                {
                    title: 'Spread',
                    value: isNotUndefined(extInfo?.spread)
                        ? `${FormatNumber(extInfo.spread / 100)}% ${extInfo.spread}bps`
                        : '',
                },
                {
                    title: 'Customer Price',
                    value: isNotUndefined(extInfo?.customer_price)
                        ? FormatNumber(extInfo?.customer_price, 2)
                        : '',
                },
                {
                    title: 'Accrued Interest',
                    value: isNotUndefined(extInfo?.accrued_interest)
                        ? FormatNumber(extInfo?.accrued_interest, 2)
                        : '',
                },
                {
                    title: 'Settlement Amount',
                    value: isNotUndefined(extInfo?.settlement_amount)
                        ? FormatNumber(extInfo?.settlement_amount, 2)
                        : '',
                },
                {
                    title: '',
                    value: '',
                },
                {
                    title: '',
                    value: '',
                },
                {
                    title: '',
                    value: '',
                },
                {
                    title: '',
                    value: '',
                },
            ];
            break;
        case TransactionType['Buy Equities']:
        case TransactionType['Sell Equities']:
            extInfo = item.ext_info as TransEquitiesExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'Ticker',
                    value: extInfo?.ticker || '',
                },
                {
                    title: 'ISIN',
                    value: extInfo?.isin || '',
                    node: (
                        <span
                            className="lk_link"
                            onClick={() => {
                                extInfo = item.ext_info as BaseExtInfo;
                                history.push(
                                    `/app/assetManagement/position/detail/${
                                        item.account.account_id
                                    }/${extInfo!.isin}`
                                );
                            }}
                        >
                            {extInfo.isin || ''}
                        </span>
                    ),
                },
                {
                    title: 'Name',
                    value: extInfo?.name || '',
                    width: '33.332%',
                    node: (
                        <span
                            className="lk_link"
                            onClick={() => {
                                extInfo = item.ext_info as BaseExtInfo;
                                history.push(
                                    `/app/assetManagement/position/detail/${
                                        item.account.account_id
                                    }/${extInfo!.isin}`
                                );
                            }}
                        >
                            {extInfo?.name || ''}
                        </span>
                    ),
                },
                {
                    title: 'Currency',
                    value: extInfo?.currency || '',
                },
                {
                    title: 'Quantity',
                    value: isNotUndefined(extInfo?.qty) ? FormatNumber(extInfo?.qty) : '',
                },
                {
                    title: 'Price Type',
                    value: getEquitiesPriceType(extInfo),
                },
                {
                    title: 'Price',
                    value: isNotUndefined(extInfo?.price) ? FormatNumber(extInfo.price, 2) : '',
                },
                {
                    title: 'Validity',
                    value:
                        extInfo?.is_day_order === 1
                            ? 'Day Order'
                            : moment(extInfo?.validity).format('yyyy-MM-DD'),
                },
                {
                    title: 'Filled Quantity',
                    value: isNotUndefined(extInfo?.fill_qty) ? FormatNumber(extInfo?.fill_qty) : '',
                },
                {
                    title: 'Filled Price',
                    value: isNotUndefined(extInfo?.fill_price)
                        ? FormatNumber(extInfo?.fill_price, 2)
                        : '',
                },
                {
                    title: 'Settlement Amount',
                    value: isNotUndefined(extInfo?.settlement_amount)
                        ? FormatNumber(extInfo?.settlement_amount, 2)
                        : '',
                    width: '100%',
                },
            ];
            break;
        case TransactionType['Buy ELN']:
            extInfo = item.ext_info as TransELNExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'ISIN',
                    value: extInfo?.isin || '',
                    node: (
                        <span
                            className="lk_link"
                            onClick={() => {
                                extInfo = item.ext_info as BaseExtInfo;
                                history.push(
                                    `/app/assetManagement/position/detail/${
                                        item.account.account_id
                                    }/${extInfo!.isin}`
                                );
                            }}
                        >
                            {extInfo.isin || ''}
                        </span>
                    ),
                },
                {
                    title: 'Price Type',
                    value: extInfo?.price_type ? (extInfo?.price_type === 1 ? 'LMT' : 'MKT') : '',
                },
                {
                    title: 'Price',
                    value: isNotUndefined(extInfo?.price) ? FormatNumber(extInfo.price!, 2) : '',
                },
                {
                    title: 'Fill Price',
                    value: isNotUndefined(extInfo?.fill_price)
                        ? FormatNumber(extInfo.fill_price!, 2)
                        : '',
                },
                {
                    title: 'Nominal Amount',
                    value: isNotUndefined(extInfo?.nominal_amount)
                        ? FormatNumber(extInfo.nominal_amount!, 2)
                        : '',
                },
                {
                    title: 'Fill Nominal Amount',
                    value: isNotUndefined(extInfo?.fill_nominal_amount)
                        ? FormatNumber(extInfo.fill_nominal_amount!, 2)
                        : '',
                },
                {
                    title: 'Actual Amount',
                    value: isNotUndefined(extInfo?.actual_amount)
                        ? FormatNumber(extInfo.actual_amount!, 2)
                        : '',
                },
                {
                    title: 'Fill Actual Amount',
                    value: isNotUndefined(extInfo?.fill_actual_amount)
                        ? FormatNumber(extInfo.fill_actual_amount!, 2)
                        : '',
                },
                {
                    title: 'Upfront(%)',
                    value: isNotUndefined(extInfo?.up_front_rate)
                        ? FormatNumber(extInfo.up_front_rate!, 2)
                        : '',
                },
                {
                    title: 'Upfront($)',
                    value: isNotUndefined(extInfo?.up_front_amount)
                        ? FormatNumber(extInfo.up_front_amount!, 3)
                        : '',
                },
                {
                    title: 'ELN Type',
                    value: extInfo?.eln_type
                        ? extInfo?.eln_type === ELNType.ELN
                            ? 'ELN'
                            : '敲出ELN'
                        : '',
                },
                {
                    title: 'Underlying',
                    value: `${extInfo.underlying_spec_name || extInfo.underlying_name} | ${
                        extInfo.ticker || ''
                    }`,
                },
                {
                    title: 'Currency',
                    value: extInfo?.currency || '',
                },
                {
                    title: 'Issue Date',
                    value: extInfo?.issue_date
                        ? formatDate(extInfo?.issue_date, item.time_zone)
                        : '',
                },
                {
                    title: 'Maturity Date',
                    value: extInfo?.maturity_date
                        ? formatDate(extInfo?.maturity_date, item.time_zone)
                        : '',
                },
                {
                    title: 'Strike',
                    value: isNotUndefined(extInfo?.strike_price_rate)
                        ? `${extInfo.strike_price_rate}%`
                        : '',
                },
                {
                    title: 'Knock out',
                    value: isNotUndefined(extInfo?.knock_out_price_rate)
                        ? `${extInfo.knock_out_price_rate}%`
                        : '',
                },
                {
                    title: 'Issue',
                    value: isNotUndefined(extInfo?.issue_price_rate)
                        ? `${extInfo.issue_price_rate}%`
                        : '',
                    width: '100%',
                },
            ];
            break;
        case TransactionType['ELN Knock Out']:
            extInfo = item.ext_info as TransELNExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'Asset Type',
                    value: item.assets?.[0].asset_type.desc,
                },
                {
                    title: 'Currency',
                    value: extInfo?.currency || '',
                },
                {
                    title: 'Knock Out Date',
                    value: formatDate(
                        (item.assets?.[0].ext_info as TransELNExtInfo).knock_out_price_rate!
                    ),
                },
                {
                    title: '',
                    value: '',
                },
                {
                    title: '',
                    value: '',
                },
            ];
            break;
        case TransactionType['ELN Maturity']:
            extInfo = item.ext_info as TransELNExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'Asset Type',
                    value: item.assets?.[0].asset_type.desc,
                },
                {
                    title: 'Currency',
                    value: extInfo?.currency || '',
                },
                {
                    title: 'Maturity Date',
                    value: formatDate(extInfo?.maturity_date!),
                },
                {
                    title: '',
                    value: '',
                },
                {
                    title: '',
                    value: '',
                },
            ];
            break;
        case TransactionType['ELN Take Over Shares']:
            extInfo = item.ext_info as TransELNExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'Take over Shares Date',
                    value: formatDate(extInfo?.take_over_shares_date!),
                },
                {
                    title: 'Underlying',
                    value: `${extInfo.underlying_spec_name || extInfo.underlying_name} | ${
                        extInfo.ticker || ''
                    }`,
                },
                {
                    title: 'No. of Shares',
                    value: extInfo?.shares_number!.toString() || '',
                },
                {
                    title: 'Currency',
                    value: extInfo?.currency || '',
                },
                {
                    title: 'Return Amount',
                    value: isNotUndefined(extInfo.return_amount)
                        ? FormatNumber(extInfo.return_amount!, 2)
                        : '',
                },
            ];
            break;
        case TransactionType['Buy AQ']:
        case TransactionType['Buy DQ']:
            extInfo = item.ext_info as TransAQExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'ISIN',
                    value: extInfo?.isin || '',
                    node: (
                        <span
                            className="lk_link"
                            onClick={() => {
                                extInfo = item.ext_info as BaseExtInfo;
                                history.push(
                                    `/app/assetManagement/position/detail/${
                                        item.account.account_id
                                    }/${extInfo!.isin}`
                                );
                            }}
                        >
                            {extInfo.isin || ''}
                        </span>
                    ),
                },
                {
                    title: 'Name',
                    value: item.assets ? item.assets?.[0].name : '',
                    width: '33.332%',
                    node: (
                        <span
                            className="lk_link"
                            onClick={() => {
                                extInfo = item.ext_info as BaseExtInfo;
                                history.push(
                                    `/app/assetManagement/position/detail/${
                                        item.account.account_id
                                    }/${extInfo!.isin}`
                                );
                            }}
                        >
                            {item.assets ? item.assets?.[0].name : ''}
                        </span>
                    ),
                },
                {
                    title: 'Price Type',
                    value: getStructPriceType(extInfo.price_type),
                },
                {
                    title: 'Price',
                    value: extInfo?.price ? FormatNumber(extInfo.price, 3) : '',
                },
                {
                    title: 'Fill Price',
                    value: extInfo?.fill_price ? FormatNumber(extInfo.fill_price, 3) : '',
                },
                {
                    title: 'Upfront(%)',
                    value: extInfo?.up_front_rate ? FormatNumber(extInfo.up_front_rate, 2) : '',
                },
                {
                    title: 'Upfront($)',
                    value: extInfo?.up_front_amount ? FormatNumber(extInfo.up_front_amount, 3) : '',
                },
                ...(item.account?.account_type === BankType.UBS
                    ? [
                          {
                              title: 'Max Notional Amount',
                              value: extInfo?.maximum_notional_amount
                                  ? FormatNumber(extInfo.maximum_notional_amount, 3)
                                  : '',
                          },
                      ]
                    : []),
                {
                    title: 'Underlying',
                    value: `${extInfo.underlying_spec_name || extInfo.underlying_name} | ${
                        extInfo.ticker || ''
                    }`,
                },
                ...currencyItem,
                {
                    title: 'Daily Trade No.',
                    value: extInfo?.day_volume?.toString() || '',
                },
                {
                    title: 'Leveraged Daily Trade No.',
                    value: extInfo?.boosted_day_volume?.toString() || '',
                },
                {
                    title: 'Guaranteed Period',
                    value: extInfo?.guaranteed_period || '',
                },
                {
                    title: 'Tenor',
                    value: extInfo?.tenor || '',
                },
                {
                    title: 'Leverage',
                    value: extInfo?.leverage ? getLeverage(extInfo.leverage) : '',
                },
                {
                    title: 'Strike',
                    value: extInfo?.strike_price_rate ? `${extInfo.strike_price_rate}%` : '',
                },
                {
                    title: 'Knock out',
                    value: extInfo?.knock_out_price_rate ? `${extInfo.knock_out_price_rate}%` : '',
                    width: item.account?.account_type === BankType.UBS ? '100%' : undefined,
                },
            ];
            break;
        case TransactionType['AQ Settlement']:
        case TransactionType['DQ Settlement']:
            extInfo = item.ext_info as TransAQExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'Asset Type',
                    value: item.assets ? item.assets?.[0].asset_type.desc : '',
                },
                {
                    title: 'Currency',
                    value: extInfo?.currency || '',
                },
                {
                    title: 'Accrual Period Start Date',
                    value: isNotUndefined(extInfo?.current_period?.start_date)
                        ? formatDate(extInfo.current_period!.start_date!)
                        : '',
                },
                {
                    title: 'Accrual Period End Date',
                    value: isNotUndefined(extInfo?.current_period?.end_date)
                        ? formatDate(extInfo.current_period!.end_date!)
                        : '',
                },
                {
                    title: 'Valuation Date',
                    value: isNotUndefined(extInfo?.current_period?.end_date)
                        ? formatDate(extInfo.current_period!.end_date!)
                        : '',
                },
                {
                    title: 'Number of Traded Days',
                    value: isNotUndefined(extInfo?.current_period?.observation_days)
                        ? extInfo!.current_period!.observation_days!.toString()
                        : '',
                },
                {
                    title: 'Traded Number of Shares',
                    value: isNotUndefined(extInfo?.current_period?.trade_numbers)
                        ? extInfo!.current_period!.trade_numbers!.toString()
                        : '',
                    width: '83.33%',
                },
            ];
            break;
        case TransactionType['AQ Knock Out']:
        case TransactionType['DQ Knock Out']:
            extInfo = item.ext_info as TransAQExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'Asset Type',
                    value: item.assets ? item.assets?.[0].asset_type.desc : '',
                },
                ...currencyItem,
                {
                    title: 'Accrual Period Start Date',
                    value: isNotUndefined(extInfo?.current_period?.start_date)
                        ? formatDate(extInfo.current_period!.start_date!)
                        : '',
                },
                {
                    title: 'Accrual Period End Date',
                    value: isNotUndefined(extInfo?.current_period?.end_date)
                        ? formatDate(extInfo.current_period!.end_date!)
                        : '',
                },
                {
                    title: 'Knock Out Date',
                    value: extInfo?.knock_out_date ? formatDate(extInfo.knock_out_date) : '',
                },
                {
                    title: 'Number of Traded Days',
                    value: isNotUndefined(extInfo?.current_period?.observation_days)
                        ? extInfo!.current_period!.observation_days!.toString()
                        : '',
                },
                {
                    title: 'Traded Number of Shares',
                    value: isNotUndefined(extInfo?.current_period?.trade_numbers)
                        ? extInfo!.current_period!.trade_numbers!.toString()
                        : '',
                    width: '83.33%',
                },
            ];
            break;
        case TransactionType['AQ Maturity']:
        case TransactionType['DQ Maturity']:
            extInfo = item.ext_info as TransAQExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'Asset Type',
                    value: item.assets ? item.assets?.[0].asset_type.desc : '',
                },
                ...currencyItem,
                {
                    title: 'Accrual Period Start Date',
                    value: isNotUndefined(extInfo?.current_period?.start_date)
                        ? formatDate(extInfo.current_period!.start_date!)
                        : '',
                },
                {
                    title: 'Accrual Period End Date',
                    value: isNotUndefined(extInfo?.current_period?.end_date)
                        ? formatDate(extInfo.current_period!.end_date!)
                        : '',
                },
                {
                    title: 'Maturity Date',
                    value: extInfo?.maturity_date ? formatDate(extInfo.maturity_date) : '',
                },
                {
                    title: 'Number of Traded Days',
                    value: isNotUndefined(extInfo?.current_period?.observation_days)
                        ? extInfo!.current_period!.observation_days!.toString()
                        : '',
                },
                {
                    title: 'Traded Number of Shares',
                    value: isNotUndefined(extInfo?.current_period?.trade_numbers)
                        ? extInfo!.current_period!.trade_numbers!.toString()
                        : '',
                    width: '83.33%',
                },
            ];
            break;
        case TransactionType['Buy FCN']:
            extInfo = item.ext_info as TransFcnExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'ISIN',
                    value: extInfo?.isin || '',
                    node: (
                        <span
                            className="lk_link"
                            onClick={() => {
                                extInfo = item.ext_info as BaseExtInfo;
                                history.push(
                                    `/app/assetManagement/position/detail/${
                                        item.account.account_id
                                    }/${extInfo!.isin}`
                                );
                            }}
                        >
                            {extInfo.isin || ''}
                        </span>
                    ),
                },
                {
                    title: 'Price Type',
                    value: getStructPriceType(extInfo.price_type),
                },
                {
                    title: 'Price',
                    value: extInfo?.price ? FormatNumber(extInfo.price, 3) : '',
                },
                {
                    title: 'Fill Price',
                    value: extInfo?.fill_price ? FormatNumber(extInfo.fill_price, 3) : '',
                },
                {
                    title: 'Amount',
                    value: extInfo?.amount ? FormatNumber(extInfo.amount, 3) : '',
                },
                {
                    title: 'Fill Amount',
                    value: extInfo?.fill_amount ? FormatNumber(extInfo.fill_amount, 3) : '',
                },
                {
                    title: 'Upfront(%)',
                    value: extInfo?.up_front_rate ? FormatNumber(extInfo.up_front_rate, 2) : '',
                },
                {
                    title: 'Upfront($)',
                    value: extInfo?.up_front_amount ? FormatNumber(extInfo.up_front_amount, 3) : '',
                },
                {
                    title: 'Underlying',
                    value: `${extInfo.underlying_spec_name || extInfo.underlying_name} | ${
                        extInfo.ticker || ''
                    }`,
                },
                ...currencyItem,
                {
                    title: 'Guaranteed Period',
                    value: extInfo?.guaranteed_period || '',
                },
                {
                    title: 'Tenor',
                    value: extInfo?.tenor || '',
                },
                {
                    title: 'Annualized Coupon',
                    value: extInfo?.annualized_coupon ? `${extInfo.annualized_coupon}%` : '',
                },
                {
                    title: 'Coupon Frequency',
                    value: extInfo?.coupon_frequency
                        ? getCouponFrequency(extInfo.coupon_frequency)
                        : '',
                },

                {
                    title: 'Strike',
                    value: extInfo?.strike_price_rate ? `${extInfo.strike_price_rate}%` : '',
                },
                {
                    title: 'Knock out',
                    value: extInfo?.knock_out_price_rate ? `${extInfo.knock_out_price_rate}%` : '',
                    width: '33.32%',
                },
            ];
            break;
        case TransactionType['FCN Interest']:
            extInfo = item.ext_info as TransFcnExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'Asset Type',
                    value: item.assets.length > 0 ? item.assets?.[0].asset_type.desc : '',
                },
                ...currencyItem,
                {
                    title: 'Interest Amount',
                    value: isNotUndefined(extInfo?.interest_amount)
                        ? FormatNumber(extInfo.interest_amount!, 3)
                        : '',
                },
                {
                    title: 'Interest Payment Date',
                    value: extInfo?.interest_payment_date
                        ? formatDate(extInfo.interest_payment_date)
                        : '',
                },
                {
                    title: '',
                    value: '',
                },
            ];
            break;
        case TransactionType['FCN Knock Out']:
            extInfo = item.ext_info as TransFcnExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'Asset Type',
                    value: item.assets.length > 0 ? item.assets?.[0].asset_type.desc : '',
                },
                ...currencyItem,
                {
                    title: 'Interest Amount',
                    value: isNotUndefined(extInfo?.interest_amount)
                        ? FormatNumber(extInfo.interest_amount!, 3)
                        : '',
                },
                {
                    title: 'Early Redemption Amount',
                    value: isNotUndefined(extInfo?.early_redemption_amount)
                        ? FormatNumber(extInfo.early_redemption_amount!, 3)
                        : '',
                },
                {
                    title: 'Knock Out Date',
                    value: extInfo?.knock_out_date ? formatDate(extInfo.knock_out_date) : '',
                },
            ];
            break;
        case TransactionType['FCN Maturity']:
            extInfo = item.ext_info as TransFcnExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'Asset Type',
                    value: item.assets.length > 0 ? item.assets?.[0].asset_type.desc : '',
                },
                ...currencyItem,
                {
                    title: 'Interest Amount',
                    value: isNotUndefined(extInfo?.interest_amount)
                        ? FormatNumber(extInfo.interest_amount!, 3)
                        : '',
                },
                {
                    title: 'Redemption Amount',
                    value: isNotUndefined(extInfo?.redemption_amount)
                        ? FormatNumber(extInfo.redemption_amount!, 3)
                        : '',
                },
                {
                    title: 'Maturity Date',
                    value: extInfo?.maturity_date ? formatDate(extInfo.maturity_date) : '',
                },
            ];
            break;
        case TransactionType['FCN Take Over Shares']:
            extInfo = item.ext_info as TransFcnExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'Asset Type',
                    value: item.assets.length > 0 ? item.assets?.[0].asset_type.desc : '',
                },
                {
                    title: 'Take over Shares Date',
                    value: extInfo?.take_over_shares_date
                        ? formatDate(extInfo.take_over_shares_date)
                        : '',
                },
                {
                    title: 'Underlying',
                    value: `${extInfo.underlying_spec_name || extInfo.underlying_name} | ${
                        extInfo.ticker || ''
                    }`,
                },
                {
                    title: 'No. of Shares',
                    value: extInfo?.shares_number?.toString() || '',
                },
                ...currencyItem,
                {
                    title: 'Return Amount',
                    value: extInfo?.return_amount ? FormatNumber(extInfo.return_amount, 3) : '',
                    width: '100%',
                },
            ];
            break;
        case TransactionType['Buy Option']:
        case TransactionType['Sell Option']:
            extInfo = item.ext_info as TransOptionExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'ISIN',
                    value: extInfo?.isin || '',
                    node: (
                        <span
                            className="lk_link"
                            onClick={() => {
                                extInfo = item.ext_info as BaseExtInfo;
                                history.push(
                                    `/app/assetManagement/position/detail/${
                                        item.account.account_id
                                    }/${extInfo!.isin}`
                                );
                            }}
                        >
                            {extInfo.isin || ''}
                        </span>
                    ),
                },
                {
                    title: 'Price Type',
                    value: getStructPriceType(extInfo.price_type),
                },
                {
                    title: 'Price',
                    value: extInfo?.price ? FormatNumber(extInfo.price, 3) : '',
                },
                {
                    title: 'Fill Price',
                    value: extInfo?.fill_price ? FormatNumber(extInfo.fill_price, 3) : '',
                },
                {
                    title: 'No. of Shares',
                    value: extInfo?.number_of_shares?.toString() || '',
                },
                {
                    title: 'Fill No. of Shares',
                    value: extInfo?.fill_number_of_shares?.toString() || '',
                },
                {
                    title: 'Fill Option Fee(%)',
                    value: extInfo?.fill_option_fee_rate
                        ? ` ${FormatNumber(extInfo.fill_option_fee_rate, 2)}%`
                        : '',
                },
                {
                    title: 'Option Fee',
                    value: extInfo?.option_fee ? FormatNumber(extInfo.option_fee, 3) : '',
                },
                {
                    title: 'Upfront(%)',
                    value: extInfo?.up_front_rate ? FormatNumber(extInfo.up_front_rate, 2) : '',
                },
                {
                    title: 'Upfront($)',
                    value: extInfo?.up_front_amount ? FormatNumber(extInfo.up_front_amount, 3) : '',
                },
                {
                    title: 'Underlying',
                    value: `${extInfo.underlying_spec_name || extInfo.underlying_name} | ${
                        extInfo.ticker || ''
                    }`,
                },
                ...currencyItem,
                {
                    title: 'Option Type',
                    value: extInfo?.option_type ? getOptionType(extInfo.option_type) : '',
                },

                {
                    title: 'Strike',
                    value: extInfo?.strike_price_rate ? `${extInfo.strike_price_rate}%` : '',
                },
                {
                    title: 'Tenor',
                    value: extInfo?.tenor || '',
                    width: '50%',
                },
            ];
            break;
        case TransactionType['Option Exercise']:
            extInfo = item.ext_info as TransOptionExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'Asset Type',
                    value:
                        extInfo.option_side && extInfo.option_type
                            ? `${getOptionSide(extInfo.option_side)} ${getOptionType(
                                  extInfo.option_type
                              )}`
                            : '',
                },
                ...currencyItem,
                {
                    title: 'Exercise Stock Price',
                    value: isNotUndefined(extInfo?.exercise_stock_price)
                        ? FormatNumber(extInfo.exercise_stock_price!, 3)
                        : '',
                },
                {
                    title: 'Exercise Quantity',
                    value: isNotUndefined(extInfo?.exercise_qty)
                        ? extInfo.exercise_qty!.toString()
                        : '',
                },
                {
                    title: 'Exercise Amount',
                    value: isNotUndefined(extInfo?.exercise_amount)
                        ? FormatNumber(extInfo.exercise_amount!, 3)
                        : '',
                },
                {
                    title: 'Exercise Date',
                    value: extInfo.exercise_date ? formatDate(extInfo.exercise_date) : '',
                    width: '100%',
                },
            ];
            break;
        case TransactionType['Option Expiration']:
            extInfo = item.ext_info as TransOptionExtInfo;
            ret = [
                ...accoutItem,
                {
                    title: 'Asset Type',
                    value:
                        extInfo.option_side && extInfo.option_type
                            ? `${getOptionSide(extInfo.option_side)} ${getOptionType(
                                  extInfo.option_type
                              )}`
                            : '',
                },
                ...currencyItem,
                {
                    title: 'Maturity Date',
                    value: extInfo.maturity_date ? formatDate(extInfo.maturity_date) : '',
                    width: '50%',
                },
            ];
            break;
        case TransactionType['Create Adjustment']:
            extInfo = item.ext_info as TransAdjustmentExtInfo;
            ret = [...accoutItem];
            switch (extInfo.asset_type) {
                case TransAdjustmentAssetType.Cash:
                    ret = ret.concat([
                        {
                            title: 'Asset',
                            value: `${extInfo.currency || ''} Cash`,
                        },
                        {
                            title: 'Side',
                            value: extInfo.side
                                ? extInfo.side === 1
                                    ? 'Increase'
                                    : 'Decrease'
                                : '',
                        },
                        {
                            title: 'Amount',
                            value: isNotUndefined(extInfo.amount)
                                ? FormatNumber(extInfo.amount!, 3)
                                : '',
                        },
                        {
                            title: 'Description',
                            value: extInfo.description || '',
                            width: '33.32%',
                        },
                    ]);
                    break;
                case TransAdjustmentAssetType.Bond:
                    ret = ret.concat([
                        {
                            title: 'ISIN',
                            value: extInfo?.isin || '',
                            node: (
                                <span
                                    className="lk_link"
                                    onClick={() => {
                                        extInfo = item.ext_info as BaseExtInfo;
                                        history.push(
                                            `/app/assetManagement/position/detail/${
                                                item.account.account_id
                                            }/${extInfo!.isin}`
                                        );
                                    }}
                                >
                                    {extInfo.isin || ''}
                                </span>
                            ),
                        },
                        {
                            title: 'Asset Name',
                            value: item.assets ? item.assets?.[0].name : '',
                            width: '33.32%',
                            node: (
                                <span
                                    className="lk_link"
                                    onClick={() => {
                                        if (!item.account || !item.assets) return;
                                        history.push(
                                            `/app/assetManagement/position/detail/${item.account.account_id}/${item.assets[0].isin}`
                                        );
                                    }}
                                >
                                    {item.assets ? item.assets?.[0].name : ''}
                                </span>
                            ),
                        },
                        {
                            title: 'Side',
                            value: extInfo.side
                                ? extInfo.side === 1
                                    ? 'Increase'
                                    : 'Decrease'
                                : '',
                        },
                        {
                            title: 'Amount',
                            value: isNotUndefined(extInfo.amount)
                                ? FormatNumber(extInfo.amount!, 3)
                                : '',
                        },
                        {
                            title: 'Description',
                            value: extInfo.description || '',
                            width: '100%',
                        },
                    ]);
                    break;
                case TransAdjustmentAssetType.Equity:
                    ret = ret.concat([
                        {
                            title: 'Ticker',
                            value: extInfo.ticker || '',
                        },
                        {
                            title: 'Asset Name',
                            value: item.assets ? item.assets?.[0].name : '',
                            width: '33.32%',
                            node: (
                                <span
                                    className="lk_link"
                                    onClick={() => {
                                        if (!item.account || !item.assets) return;
                                        history.push(
                                            `/app/assetManagement/position/detail/${item.account.account_id}/${item.assets[0].isin}`
                                        );
                                    }}
                                >
                                    {item.assets ? item.assets?.[0].name : ''}
                                </span>
                            ),
                        },
                        {
                            title: 'ISIN',
                            value: extInfo?.isin || '',
                            node: (
                                <span
                                    className="lk_link"
                                    onClick={() => {
                                        extInfo = item.ext_info as BaseExtInfo;
                                        history.push(
                                            `/app/assetManagement/position/detail/${
                                                item.account.account_id
                                            }/${extInfo!.isin}`
                                        );
                                    }}
                                >
                                    {extInfo.isin || ''}
                                </span>
                            ),
                        },
                        {
                            title: 'Side',
                            value: extInfo.side
                                ? extInfo.side === 1
                                    ? 'Increase'
                                    : 'Decrease'
                                : '',
                        },
                        {
                            title: 'Amount',
                            value: isNotUndefined(extInfo.amount)
                                ? FormatNumber(extInfo.amount!, 3)
                                : '',
                        },
                        {
                            title: 'Description',
                            value: extInfo.description || '',
                            width: '83.33%',
                        },
                    ]);
                    break;
                case TransAdjustmentAssetType.Other:
                    ret = ret.concat([
                        {
                            title: 'Asset Name',
                            value: extInfo.asset_name || '',
                            width: '33.32%',
                            node: (
                                <span
                                    className="lk_link"
                                    onClick={() => {
                                        if (!item.account || !item.assets) return;
                                        history.push(
                                            `/app/assetManagement/position/detail/${item.account.account_id}/${item.assets[0].isin}`
                                        );
                                    }}
                                >
                                    {item.assets ? item.assets?.[0].name : ''}
                                </span>
                            ),
                        },
                        {
                            title: 'Side',
                            value: extInfo.side
                                ? extInfo.side === 1
                                    ? 'Increase'
                                    : 'Decrease'
                                : '',
                        },
                        {
                            title: 'Amount',
                            value: isNotUndefined(extInfo.amount)
                                ? FormatNumber(extInfo.amount!, 3)
                                : '',
                        },
                        {
                            title: 'Description',
                            value: extInfo.description || '',
                        },
                    ]);
                    break;
                default:
                    break;
            }
            break;
        case TransactionType['Subscribe Fund']:
            extInfo = item.ext_info as TransFundExtInfo;
            let resultList: { title: string; value: string; width?: string }[] = [];
            if (extInfo.unit_type === 1) {
                resultList = [
                    {
                        title: 'Investment Amount',
                        value: extInfo.invest_amount ?? '',
                    },
                    {
                        title: 'Units',
                        value: extInfo.units ?? '',
                    },
                    {
                        title: 'NAV',
                        value: extInfo.nav ?? '',
                    },
                    {
                        title: 'Fees',
                        value: extInfo.fees ?? '',
                    },
                    {
                        title: 'Payment Amount',
                        value: extInfo.payment_amount ?? '',
                        width: '66.6%',
                    },
                ];
            } else {
                resultList = [
                    {
                        title: 'Committed Amount',
                        value: extInfo.committed_capital ?? '',
                    },
                    {
                        title: 'Immediate Capital Call',
                        value: extInfo.immediate_capital_call ?? '',
                    },
                    {
                        title: 'Fees',
                        value: extInfo.fees ?? '',
                    },
                    {
                        title: 'Immediate Payment',
                        value: extInfo.immediate_payment ?? '',
                        width: '83.33%',
                    },
                ];
            }
            ret = [
                {
                    title: '保管类型',
                    value: CustodyType[extInfo?.bank_custody_type || 1],
                },
                ...accoutItem,
                {
                    title: 'Fund Name',
                    value: extInfo.asset_name || '',
                    width: '33.32%',
                },
                {
                    title: 'Fund Manager',
                    value: extInfo.fund_manager || '',
                    width: '33.32%',
                },
                {
                    title: 'Currency',
                    value: extInfo.currency || '',
                },
                {
                    title: 'ISIN',
                    value: extInfo.isin || '',
                    node: (
                        <span
                            className="lk_link"
                            onClick={() => {
                                extInfo = item.ext_info as BaseExtInfo;
                                history.push(
                                    `/app/assetManagement/position/detail/${
                                        item.account.account_id
                                    }/${extInfo!.isin}`
                                );
                            }}
                        >
                            {extInfo.isin || ''}
                        </span>
                    ),
                },
                {
                    title: 'Fund Struct',
                    value: FundStruct[extInfo.fund_struct_type || 1],
                },
                {
                    title: 'Unit Type',
                    value: UnitType[extInfo.unit_type || 1],
                },
                ...resultList,
            ];

            break;
        case TransactionType['Fund Redeem']:
            extInfo = item.ext_info as TransFundExtInfo;

            ret = [
                {
                    title: '保管类型',
                    value: CustodyType[extInfo?.bank_custody_type || 1],
                },
                ...accoutItem,
                {
                    title: 'Fund Name',
                    value: extInfo.asset_name || '',
                    width: '33.32%',
                },
                {
                    title: 'Fund Manager',
                    value: extInfo.fund_manager || '',
                    width: '33.32%',
                },
                {
                    title: 'Currency',
                    value: extInfo.currency || '',
                },
                {
                    title: 'ISIN',
                    value: extInfo.isin || '',
                    node: (
                        <span
                            className="lk_link"
                            onClick={() => {
                                extInfo = item.ext_info as BaseExtInfo;
                                history.push(
                                    `/app/assetManagement/position/detail/${
                                        item.account.account_id
                                    }/${extInfo!.isin}`
                                );
                            }}
                        >
                            {extInfo.isin || ''}
                        </span>
                    ),
                },
                {
                    title: 'Fund Struct',
                    value: FundStruct[extInfo.fund_struct_type || 1],
                },
                {
                    title: 'Unit Type',
                    value: UnitType[extInfo.unit_type || 1],
                },
                {
                    title: 'Redeem Amount',
                    value: extInfo.redeem_amount ?? '',
                },
                {
                    title: 'Units',
                    value: extInfo.units ?? '',
                },
                {
                    title: 'NAV',
                    value: extInfo.nav ?? '',
                },
                {
                    title: 'Fees',
                    value: extInfo.fees ?? '',
                },
                {
                    title: 'Settlement Amount',
                    value: extInfo.settlement_amount ?? '',
                    width: '66.6%',
                },
            ];
            break;
        case TransactionType['Fund Contribute']:
            extInfo = item.ext_info as TransFundExtInfo;

            ret = [
                {
                    title: '保管类型',
                    value: CustodyType[extInfo?.bank_custody_type || 1],
                },
                ...accoutItem,
                {
                    title: 'Fund Name',
                    value: extInfo.asset_name || '',
                    width: '33.32%',
                },
                {
                    title: 'Fund Manager',
                    value: extInfo.fund_manager || '',
                    width: '33.32%',
                },
                {
                    title: 'Currency',
                    value: extInfo.currency || '',
                },
                {
                    title: 'ISIN',
                    value: extInfo.isin || '-',
                    node: (
                        <span
                            className="lk_link"
                            onClick={() => {
                                extInfo = item.ext_info as BaseExtInfo;
                                history.push(
                                    `/app/assetManagement/position/detail/${
                                        item.account.account_id
                                    }/${extInfo!.isin}`
                                );
                            }}
                        >
                            {extInfo.isin || ''}
                        </span>
                    ),
                },
                {
                    title: 'Fund Struct',
                    value: FundStruct[extInfo.fund_struct_type || 1],
                },
                {
                    title: 'Unit Type',
                    value: UnitType[extInfo.unit_type || 1],
                },
                {
                    title: 'Capital Call',
                    value: extInfo.capital_call ?? '',
                },
                {
                    title: 'Fees',
                    value: extInfo.fees ?? '',
                },
                {
                    title: 'Payment Amount',
                    value: extInfo.payment_amount ?? '',
                    width: '100%',
                },
            ];
            break;
        case TransactionType['Fund Distribute']:
            extInfo = item.ext_info as TransFundExtInfo;
            let disResult: { title: string; value: string; width?: string }[] = [];
            if (extInfo.unit_type === 1) {
                disResult = [
                    {
                        title: 'Distribute Amount',
                        value: extInfo.distribute_amount ?? '',
                    },
                    {
                        title: 'Fees',
                        value: extInfo.fees ?? '',
                    },
                    {
                        title: 'Settlement Amount',
                        value: extInfo.settlement_amount ?? '',
                        width: '100%',
                    },
                ];
            } else {
                disResult = [
                    {
                        title: 'Capital Return',
                        value: extInfo.capital_return ?? '',
                    },
                    {
                        title: 'Distribute Amount',
                        value: extInfo.distribute_amount ?? '',
                    },
                    {
                        title: 'Fees',
                        value: extInfo.fees ?? '',
                    },
                    {
                        title: 'Settlement Amount',
                        value: extInfo.settlement_amount ?? '',
                        width: '83.33%',
                    },
                ];
            }
            ret = [
                {
                    title: '保管类型',
                    value: CustodyType[extInfo?.bank_custody_type || 1],
                },
                ...accoutItem,
                {
                    title: 'Fund Name',
                    value: extInfo.asset_name || '',
                    width: '33.32%',
                },
                {
                    title: 'Fund Manager',
                    value: extInfo.fund_manager || '',
                    width: '33.32%',
                },
                {
                    title: 'Currency',
                    value: extInfo.currency || '',
                },
                {
                    title: 'ISIN',
                    value: extInfo.isin || '-',
                    node: (
                        <span
                            className="lk_link"
                            onClick={() => {
                                extInfo = item.ext_info as BaseExtInfo;
                                history.push(
                                    `/app/assetManagement/position/detail/${
                                        item.account.account_id
                                    }/${extInfo!.isin}`
                                );
                            }}
                        >
                            {extInfo.isin || ''}
                        </span>
                    ),
                },
                {
                    title: 'Fund Struct',
                    value: FundStruct[extInfo.fund_struct_type || 1],
                },
                {
                    title: 'Unit Type',
                    value: UnitType[extInfo.unit_type || 1],
                },
                ...disResult,
            ];

            break;
    }
    return ret;
};
