import { Modal, Form, Input, Button, Select, message } from 'antd';
import React, { useCallback, useState } from 'react';
import { addRecomProduct, newSecurity, searchSecurity } from '../../service';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { assetList } from '../../service/type';
import { showError } from './util';

const { Option } = Select;
const AddAssetModal = ({
    visible,
    setVisible,
    tag_id,
    handleRefreshList,
    setActive,
}: {
    visible: boolean;
    setVisible: (boolean: boolean) => void;
    handleRefreshList: () => void;
    setActive: () => void;
    tag_id: string;
}) => {
    const [showAddProduction, setShowAddProduction] = useState(false);
    const [value, setValue] = useState('');
    const addNewSecurity = useCallback(() => {
        newSecurity({ isin: value }).then(() => {
            message.success('添加成功');
            setValue('');
            setShowAddProduction(false);
        }, showError);
    }, [value]);
    const onFinish = useCallback(
        (values: { isin: string }) => {
            addRecomProduct({ tag_id, ...values }).then((res) => {
                setVisible(false);
                handleRefreshList();
                setActive();
            }, showError);
        },
        [tag_id, setVisible, handleRefreshList, setActive]
    );

    const [assetList, setAssetList] = useState<assetList>([]);
    const handleSearch = useCallback((keyword: string) => {
        if (keyword)
            searchSecurity({ keyword }).then((res) => {
                setAssetList(res.data);
                if (res.data.length) {
                    setShowAddProduction(false);
                } else {
                    setShowAddProduction(true);
                }
            }, showError);
    }, []);
    const options = assetList.map((item) => (
        <Option key={item.isin} value={item.isin}>
            {item.name}
        </Option>
    ));

    return (
        <Modal
            title="添加资产"
            destroyOnClose
            footer={null}
            visible={visible}
            okText="确认"
            cancelText="取消"
            onCancel={() => {
                setVisible(false);
            }}
            width={700}
            centered
        >
            <Form
                name="addasset"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
            >
                <Form.Item
                    label="资产名"
                    name="isin"
                    rules={[{ required: true, message: '请输入资产名' }]}
                >
                    <Select
                        showSearch
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleSearch}
                        notFoundContent={null}
                        placeholder={'输入搜索'}
                    >
                        {options}
                    </Select>
                </Form.Item>
                {showAddProduction && (
                    <div style={{ marginLeft: 67, marginBottom: 30 }}>
                        <div style={{ color: '#FF0000' }}>未搜索到相应资产，请输入ISIN添加资产</div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>资产ISIN：</span>{' '}
                            <Input
                                style={{ width: 333, marginRight: 40 }}
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                            />
                            <Button onClick={addNewSecurity}>添加</Button>
                        </div>
                    </div>
                )}
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddAssetModal;
