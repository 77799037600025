import React from 'react';
import { Checkbox } from 'antd';
import { CheckboxGroupProps, CheckboxValueType } from 'antd/lib/checkbox/Group';
import styles from './index.module.less';
import { v4 as uuid } from 'uuid';

type LKCheckboxProps = CheckboxGroupProps & {
    datasource: Array<{
        label: string;
        value: string | number | boolean;
        disabled?: boolean;
        width?: string;
    }>;
    itemWidth?: string; // '25%';
    readOnly?: boolean;
    withReset?: boolean;
    ResetBtnWidth?: string;
};
const LKCheckbox = (props: LKCheckboxProps) => {
    const { datasource, onChange, itemWidth, ...rest } = props;
    const onValueChange = (checkedValue: Array<CheckboxValueType>) => {
        if (checkedValue.includes('')) {
            onChange && onChange([]);
            return;
        }
        onChange && onChange(checkedValue);
    };
    return props.readOnly ? (
        <div>{(props.value ?? []).map((item) => item.toString()).join('')}</div>
    ) : (
        <div className={styles.lkcheckbox}>
            <Checkbox.Group {...rest} onChange={onValueChange}>
                {props.withReset && (
                    <div
                        key={uuid()}
                        className={styles.item}
                        style={{
                            width:
                                props.ResetBtnWidth || itemWidth || datasource[0].width || '12.5%',
                        }}
                    >
                        <Checkbox id="reset" value={''}>
                            Reset
                        </Checkbox>
                    </div>
                )}
                {datasource.map((i) => (
                    <div
                        key={uuid()}
                        className={styles.item}
                        style={{ width: i.width || itemWidth || '' }}
                    >
                        <Checkbox id={i.label} value={i.value}>
                            {i.label}
                        </Checkbox>
                    </div>
                ))}
            </Checkbox.Group>
        </div>
    );
};

export default LKCheckbox;
