import { Button, Divider, Modal, Table } from 'antd';
import React, { useMemo, useState } from 'react';
import { BankFlow, InsertType } from '../../../type';
import EditableCell from '../EditableCell';
import { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined } from '@ant-design/icons';
import { Account } from '../../../../Transaction/type';
import { FormatNumber } from '../../../../../utils';
import moment from 'moment';
import { useRequest } from 'ahooks';
import { getUnconfirmedBankFlow } from '../../../request';

interface BankFlowTableProps {
    account: Account;
    bankFlows: BankFlow[];
    verified: boolean;
    assetType: number;
    assetName: string;
    currency: string;
    isin: string;
    setBankFlows: React.Dispatch<React.SetStateAction<BankFlow[]>>;
}
const BankFlowTable = (props: BankFlowTableProps) => {
    const {
        account,
        bankFlows,
        verified,
        assetType,
        assetName,
        currency,
        isin,
        setBankFlows,
    } = props;
    const [showModal, setShowModal] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const { data: apiFlowData, run, loading } = useRequest(getUnconfirmedBankFlow, {
        manual: true,
    });
    const columns: (ColumnsType<BankFlow>[number] & {
        editable?: boolean;
        dataIndex: string;
        type?: 'text' | 'date' | 'number';
    })[] = [
        {
            title: 'Statement Date',
            dataIndex: 'statement_date',
            width: 100,
            align: 'center',
            editable: true,
            type: 'date',
            render: (value) => (value ? moment(value).format('yyyy-MM-DD') : '-'),
        },
        {
            title: 'Trade Date',
            dataIndex: 'trade_date',
            width: 100,
            align: 'center',
            editable: true,
            type: 'date',
            render: (value) => (value ? moment(value).format('yyyy-MM-DD') : '-'),
        },
        {
            title: 'Value Date',
            dataIndex: 'value_date',
            width: 100,
            align: 'center',
            editable: true,
            type: 'date',
            render: (value) => (value ? moment(value).format('yyyy-MM-DD') : '-'),
        },
        {
            title: 'Api Amount',
            dataIndex: 'presume_amount',
            width: 100,
            align: 'center',
            editable: true,
            type: 'number',
            render: (value, record) =>
                record.insert_type === InsertType.Manual ? '-' : FormatNumber(value, 2),
        },
        {
            title: 'Verified Amount',
            dataIndex: 'verified_amount',
            width: 100,
            align: 'center',
            editable: true,
            type: 'number',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            width: 300,
            align: 'center',
            editable: true,
        },
        {
            title: 'action',
            dataIndex: 'action',
            width: 50,
            align: 'center',
            render: (_, record, idx) => {
                return record.insert_type === InsertType.Manual && !verified ? (
                    <Button
                        onClick={() => {
                            Modal.confirm({
                                content: 'confirm to delete mannual bank flow?',
                                onOk: () => {
                                    onDeleteClick(idx);
                                },
                            });
                        }}
                    >
                        <DeleteOutlined />
                    </Button>
                ) : (
                    '-'
                );
            },
        },
    ];
    const editableColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: BankFlow, idx: number) => ({
                record,
                editing:
                    !verified &&
                    ((record.insert_type === InsertType.Manual &&
                        col.dataIndex !== 'presume_amount') ||
                        col.dataIndex === 'verified_amount'),
                dataIndex: col.dataIndex,
                type: col.type,
                index: idx,
                handleBlur: (record: BankFlow, idx: number) => {
                    const temp = [...bankFlows];
                    temp.splice(idx, 1, record);
                    setBankFlows(temp);
                },
            }),
        };
    });
    const apiFlows = useMemo(() => {
        if (apiFlowData) {
            return apiFlowData.data.bank_flows;
        } else {
            return undefined;
        }
    }, [apiFlowData]);
    const onApiFlowsOkClick = () => {
        const newFlows = apiFlows!
            .filter((f) => selectedRowKeys.includes(f.id))
            .filter((f) => !(bankFlows || []).map((bf) => bf.id).includes(f.id));
        setBankFlows((flows) => {
            const manualFlows = (flows || []).filter((f) => f.insert_type === InsertType.Manual);
            const leftFlows = (flows || []).filter((f) => selectedRowKeys.includes(f.id));
            let temp = leftFlows.concat(newFlows);
            temp.sort((a, b) => a.id - b.id);
            temp = temp.concat(manualFlows);
            return temp;
        });
        setShowModal(false);
    };
    const onAddManualClick = () => {
        setBankFlows((flows) => {
            return (flows || []).concat([
                {
                    account_id: account.account_id,
                    isin: isin,
                    bank_id: account.account_type,
                    asset_type: assetType,
                    currency: currency,
                    trade_date: undefined,
                    value_date: undefined,
                    statement_date: undefined,
                    presume_amount: undefined,
                    verified_amount: undefined,
                    description: '',
                    created_at: undefined,
                    updated_at: undefined,
                    insert_type: InsertType.Manual,
                } as BankFlow,
            ]);
        });
        setShowModal(false);
    };
    const onSelectChange = (keys: React.Key[]) => {
        setSelectedRowKeys(keys);
    };
    const onDeleteClick = (index: number) => {
        setBankFlows((flows) => {
            const temp = [...flows!];
            temp?.splice(index, 1);
            return temp;
        });
    };

    return (
        <>
            <Table
                size="small"
                columns={editableColumns as ColumnsType<BankFlow>}
                dataSource={bankFlows}
                pagination={false}
                bordered
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                rowKey={(record) => record.id}
            />
            {!verified && (
                <div style={{ marginTop: '10px' }}>
                    <Button
                        onClick={() => {
                            run({
                                account_id: account.account_id,
                                asset_name: assetName,
                                asset_type: assetType,
                                isin,
                                currency,
                            });
                            setSelectedRowKeys(
                                (
                                    bankFlows?.filter((f) => f.insert_type !== InsertType.Manual) ||
                                    []
                                ).map((f) => f.id)
                            );
                            setShowModal(true);
                        }}
                    >
                        Choose Custodian API Flows
                    </Button>
                </div>
            )}

            <div>
                <Modal
                    title="Choose Custodian API Flows"
                    visible={showModal}
                    destroyOnClose
                    maskClosable
                    footer={null}
                    width={1000}
                    onCancel={() => {
                        setShowModal(false);
                    }}
                >
                    <Table
                        columns={columns.filter(
                            (col) => col.title !== 'Verified Amount' && col.dataIndex !== 'action'
                        )}
                        size="small"
                        dataSource={apiFlows!}
                        pagination={false}
                        rowKey={(record) => record.id}
                        rowSelection={{
                            selectedRowKeys,
                            onChange: onSelectChange,
                        }}
                        scroll={{ y: 400 }}
                        loading={loading}
                    />

                    {apiFlows &&
                        `${selectedRowKeys.length} selected Amount ${FormatNumber(
                            apiFlows
                                .filter((f) => selectedRowKeys.includes(f.id))
                                .reduce((pre, cur) => {
                                    return (
                                        Math.round((pre + (cur.presume_amount || 0)) * 100) / 100
                                    );
                                }, 0),
                            2
                        )}`}
                    <Divider />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={onAddManualClick}>Add Manual Data</Button>
                        <Button onClick={onApiFlowsOkClick} type="primary">
                            Done
                        </Button>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default BankFlowTable;
