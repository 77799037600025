import { Modal, Button, message, Input as AntInput } from 'antd';
import { Formik } from 'formik';
import { Form, Select, InputNumber } from 'formik-antd';
import React, { useCallback, useState } from 'react';
import { addPosition, newSecurity, searchSecurity } from '../../../../../service';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { assetList } from '../../../../../service/type';
import { showError } from '../../../../RecommendationComponent/util';

const { Option } = Select;

interface ImportAssetModalProps {
    visible: boolean;
    setVisible: (boolean: boolean) => void;
    handleRefreshList: () => void;
    uid: string;
    account_id: string;
}

const ImportAssetModal = ({
    visible,
    setVisible,
    uid,
    account_id,
    handleRefreshList,
}: ImportAssetModalProps) => {
    const [showAddProduction, setShowAddProduction] = useState(false);
    const [value, setValue] = useState('');
    const [showRule, setShowRule] = useState(true);
    const addNewSecurity = useCallback(() => {
        newSecurity({ isin: value }).then(() => {
            message.success('添加成功');
            setValue('');
            setShowAddProduction(false);
        }, showError);
    }, [value]);

    const onFinish = useCallback(
        (values: { cost_price: number | undefined; qty: number | undefined; isin: string }) => {
            addPosition({
                uid,
                account_id,
                cost_price: showRule ? 1 : values.cost_price ?? 0,
                qty: showRule ? 1 : values.qty ?? 0,
                isin: values.isin,
            }).then(() => {
                message.success('转入成功');
                setVisible(false);
                handleRefreshList();
            }, showError);
        },
        [uid, account_id, setVisible, handleRefreshList, showRule]
    );

    const [assetList, setAssetList] = useState<assetList>([]);
    const handleSearch = useCallback((keyword: string) => {
        if (keyword)
            searchSecurity({ keyword }).then((res) => {
                setAssetList(res.data);
                if (res.data.length) {
                    setShowAddProduction(false);
                } else {
                    setShowAddProduction(true);
                }
            }, showError);
    }, []);

    const options = assetList.map((item) => (
        <Option
            key={item.isin}
            value={item.isin}
            label={item.sub_asset_class}
            className={item.asset_class}
        >
            {item.name}
        </Option>
    ));
    const [shouldForceInt, setShouldForceInt] = useState(true);
    const onSelectOption = useCallback(
        (option: { children: string; label: string; className: string }) => {
            const { children, label } = option;
            setShowRule(label === 'struct');
            if (
                label === 'reits' ||
                (label === 'struct' && children?.includes('FCN')) ||
                label === 'pfund' ||
                label === 'fund'
            ) {
                setShouldForceInt(false);
            } else {
                setShouldForceInt(true);
            }
        },
        []
    );

    return (
        <Modal
            title="添加资产"
            destroyOnClose
            footer={null}
            visible={visible}
            okText="确认"
            cancelText="取消"
            onCancel={() => {
                setVisible(false);
            }}
            width={700}
            centered
        >
            <Formik
                initialValues={{
                    isin: '',
                    cost_price: undefined,
                    qty: undefined,
                }}
                validate={(values) => {
                    let res: { [key: string]: string } = {};
                    if (showRule) {
                        return res;
                    }
                    if (!values.cost_price) {
                        res['cost_price'] = '请输入成本价格';
                    }
                    if (!values.isin) {
                        res['isin'] = '请选择一个资产';
                    }
                    if (!values.qty) {
                        res['qty'] = '请输入转入数量';
                    }
                    return res;
                }}
                onSubmit={onFinish}
            >
                {({ submitForm }) => (
                    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 16 }}>
                        <Form.Item label="资产名" name="isin">
                            <Select
                                name="isin"
                                showSearch
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={handleSearch}
                                onSelect={(_: string, option: any) => onSelectOption(option)}
                                notFoundContent={null}
                                placeholder={'输入搜索'}
                                style={{ width: 393, maxWidth: '100%' }}
                            >
                                {options}
                            </Select>
                        </Form.Item>
                        {showAddProduction && (
                            <div style={{ marginLeft: 67, marginBottom: 30 }}>
                                <div style={{ color: '#FF0000' }}>
                                    未搜索到相应资产，请输入ISIN添加资产
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span>资产ISIN：</span>{' '}
                                    <AntInput
                                        style={{ width: 333, marginRight: 40 }}
                                        value={value}
                                        onChange={(e) => setValue(e.target.value)}
                                    />
                                    <Button onClick={addNewSecurity}>添加</Button>
                                </div>
                            </div>
                        )}
                        <Form.Item label="成本价格" name="cost_price">
                            <InputNumber
                                min={0}
                                name="cost_price"
                                style={{ width: 393, maxWidth: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item label="资产数量" name="qty">
                            <InputNumber
                                name="qty"
                                min={0}
                                style={{ width: 393, maxWidth: '100%' }}
                                precision={shouldForceInt ? 0 : 3}
                                parser={(value) => {
                                    if (value) {
                                        return shouldForceInt ? parseInt(value) : parseFloat(value);
                                    } else {
                                        return 0;
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item name="submit" wrapperCol={{ offset: 5, span: 16 }}>
                            <Button type="primary" onClick={submitForm}>
                                提交
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default ImportAssetModal;
