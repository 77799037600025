import { Formik } from 'formik';
import { AssetModelProps } from '../EditAssets';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, message, Modal, Typography } from 'antd';
import { Form, Select } from 'formik-antd';
import { formatNum, FormInputNumber, FormSelect } from './FormComponents';
import { QueryAsset } from '../../../../../service/type';
import { addBalance, queryExchangeRate } from '../../../../../service';

interface RepayMoneyModalProps extends AssetModelProps {
    type: string;
    cashPositions: QueryAsset['sec_cates'][number]['positions'] | undefined;
}

type CurrencyTypes = 'CHF' | 'CNH' | 'HKD' | 'SGD' | 'USD';
type CurrencyExRates = {
    [currency in CurrencyTypes]: {
        [currency in CurrencyTypes]: number;
    };
};

export default function WithdrawRepayModal(props: RepayMoneyModalProps) {
    const { visible, setVisible, cashPositions, handleRefreshList, uid, accountId, type } = props;
    const [curCurrency, setCurCurrency] = useState<CurrencyTypes>();
    const [exRates, setExRates] = useState<CurrencyExRates>();

    const typeName = type === 'repay' ? '还贷' : '出金';

    useEffect(() => {
        if (visible) {
            queryExchangeRate({ currency: '' })
                .then((res) => {
                    setExRates(res.data.fx as CurrencyExRates);
                })
                .catch(() => {
                    message.error('未能获取货币兑换率，请退出重试');
                });
        }
    }, [visible]);

    const Option = Select.Option;

    const currencyChoices = useMemo(() => {
        if (cashPositions) {
            if (type === 'repay') {
                return cashPositions
                    .find((item) => item.isin === 'debit')
                    ?.sub_positions.map((position) => ({
                        isin: position.isin,
                        symbol: position.symbol,
                    }));
            } else {
                return cashPositions
                    .find((item) => item.isin === 'balance')
                    ?.sub_positions.map((position) => ({
                        isin: position.isin,
                        symbol: position.symbol,
                    }));
            }
        }
    }, [type, cashPositions]);

    const cashInUSD = useMemo(() => {
        if (cashPositions && exRates) {
            let res = 0;
            cashPositions
                .find((item) => item.isin === 'balance')
                ?.sub_positions.forEach((position) => {
                    let money = parseFloat(position.c_book_value.replaceAll(',', ''));
                    res += (exRates[position.isin as CurrencyTypes].USD ?? 1) * money;
                });
            return res;
        }
    }, [cashPositions, exRates]);

    const limit = useMemo(() => {
        if (cashPositions && curCurrency && exRates && cashInUSD) {
            if (type === 'repay') {
                let debit = cashPositions
                    .find((item) => item.isin === 'debit')
                    ?.sub_positions?.find((item) => item.isin === curCurrency)?.c_book_value;
                let debitMoney = -parseFloat(debit!.replaceAll(',', ''));
                let cash = (cashInUSD * (exRates.USD[curCurrency] ?? 1)).toString();
                cash = cash.match(/\d*(\.\d{0,2})?/)![0];
                return Math.min(parseFloat(cash), debitMoney);
            } else {
                let cash = cashPositions
                    .find((item) => item.isin === 'balance')
                    ?.sub_positions.find((item) => item.isin === curCurrency)?.c_book_value;
                return parseFloat(cash!.replaceAll(',', ''));
            }
        }
    }, [cashPositions, curCurrency, exRates, cashInUSD, type]);

    return (
        <Formik
            initialValues={{
                isin: null,
                amount: 0,
            }}
            onSubmit={(values, formikBag) => {
                addBalance({
                    uid,
                    account_id: accountId,
                    isin: curCurrency!,
                    amount: values.amount.toString(),
                    type,
                })
                    .then(() => {
                        handleRefreshList();
                        message.success(`${typeName}成功`);
                        formikBag.resetForm();
                        setCurCurrency(undefined);
                        setVisible(false);
                    })
                    .catch(() => {
                        message.error(`${typeName}失败`);
                    });
            }}
            validate={(values) => {
                let errors: { [key: string]: string } = {};
                if (!values.isin) {
                    errors.isin = '请选择货币';
                }
                if (values.amount <= 0) {
                    errors.amount = '请输入一个正值';
                }
                if (limit && values.amount > limit) {
                    errors.amount = `${typeName}量不能超过最大值`;
                }
                return errors;
            }}
        >
            {({ submitForm, resetForm }) => (
                <Modal
                    visible={visible}
                    footer={null}
                    onCancel={() => {
                        resetForm();
                        setCurCurrency(undefined);
                        setVisible(false);
                    }}
                    centered
                    width={600}
                    title={`账户${typeName}`}
                >
                    <Form labelCol={{ span: 5 }} wrapperCol={{ span: 19 }}>
                        <FormSelect
                            name="isin"
                            label="币种"
                            onChange={(value) => setCurCurrency(value)}
                        >
                            {currencyChoices?.map((currency) => (
                                <Option value={currency.isin} key={currency.isin}>
                                    {currency.symbol}
                                </Option>
                            ))}
                        </FormSelect>
                        <FormInputNumber
                            name="amount"
                            label="数量"
                            min={0}
                            formatter={(value) => formatNum(value as number)}
                            parser={(value) => parseFloat(value?.replaceAll(',', '') ?? '')}
                        >
                            <Typography.Text style={{ display: 'block' }}>
                                最大：{formatNum(limit)}
                            </Typography.Text>
                        </FormInputNumber>
                    </Form>
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                        <Button type="primary" onClick={() => submitForm()}>
                            完成
                        </Button>
                    </div>
                </Modal>
            )}
        </Formik>
    );
}
