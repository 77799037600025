import { Input, InputNumber, InputProps, InputNumberProps, SelectProps, Select } from 'antd';
import React from 'react';
import { FormatNumber } from '../../../../../../utils';
interface GinkgoInputProps {
    returntype?: 'string' | 'number';
    ref?: React.Ref<HTMLInputElement>;
}
const GinkgoInput = (data: InputProps) => {
    return data.readOnly ? (
        <div className={data.className} style={data.style}>
            {data.value}
        </div>
    ) : (
        <Input {...data} />
    );
};
const GinkgoInputNumber = (data: InputNumberProps & GinkgoInputProps) => {
    return data.readOnly ? (
        <div className={data.className} style={data.style}>
            {`${FormatNumber(data.value ?? '')} ${
                data.value && data.addonAfter ? data.addonAfter : ''
            }`}
        </div>
    ) : (
        <InputNumber
            {...data}
            ref={data.ref}
            formatter={
                data.formatter
                    ? data.formatter
                    : (value, { userTyping }) => {
                          debugger;
                          if (value === undefined || value === '') return ''; // Handle undefined case
                          let stringValue = value.toString();
                          if (!userTyping && data.precision !== undefined) {
                              stringValue = parseFloat(stringValue).toFixed(data.precision);
                          }
                          const parts = stringValue.split('.');
                          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                          if (parts.length === 2) {
                              parts[1] = parts[1].slice(0, 10); // Limit to at most 10 decimal places
                          }
                          return parts.join('.');
                      }
            }
            parser={
                data.parser
                    ? data.parser
                    : (value) => {
                          if (!value) return NaN; // Handle empty value
                          const numericValue = value.replace(/\\￥\s?|(,*)/g, '');
                          if (data.returntype === 'string') {
                              return numericValue;
                          }
                          return parseFloat(numericValue);
                      }
            }
        />
    );
};
const GinkgoSelect = (data: SelectProps & { readOnly?: boolean; readonlyvalue?: string }) => {
    return data.readOnly ? (
        <div className={data.className} style={data.style}>
            {data.readonlyvalue ?? data.value}
        </div>
    ) : (
        <Select {...data} />
    );
};

export { GinkgoInputNumber, GinkgoInput, GinkgoSelect };
