import React, { useRef, useState } from 'react';
import { Button, Tabs } from 'antd';
import AlterNativeSign from './AlterNative';
import SignAddModal from './Component/SignAddModal';
import CustomerSign from './CustomerSign';
const { TabPane } = Tabs;
const SignPage = () => {
    const addModalState = useState(false);
    const refreshCustomerSign = useRef<() => void>();
    return (
        <>
            <SignAddModal
                visibleState={addModalState}
                finish={() => {
                    refreshCustomerSign.current?.();
                }}
            />
            <Tabs
                defaultActiveKey="2"
                tabBarExtraContent={{
                    right: (
                        <Button
                            type="primary"
                            onClick={() => {
                                addModalState[1](true);
                            }}
                        >
                            新增
                        </Button>
                    ),
                }}
            >
                <TabPane key={'1'} tab="另类投资">
                    <AlterNativeSign />
                </TabPane>
                <TabPane key={'2'} tab="服务协议">
                    <CustomerSign
                        refreshCall={(call) => {
                            refreshCustomerSign.current = call;
                        }}
                    />
                </TabPane>
            </Tabs>
        </>
    );
};

export default SignPage;
