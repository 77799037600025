import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
export const exportExcel = (tableIds: string[], fileName: string) => {
    var xlsxParam = {
        raw: true,
    }; //转换成excel时，使用原始的格式，这样导出的时候数字过长不会变成科学计数法
    let workbook = XLSX.utils.book_new();

    let lastJson: any[] = [];
    tableIds.forEach((tableId) => {
        let elment = document.getElementById(tableId);
        if (!elment) {
            return;
        }
        let ws = XLSX.utils.table_to_sheet(elment, xlsxParam);
        let json = XLSX.utils.sheet_to_json(ws, { header: 1 });
        if (lastJson.length > 0) {
            lastJson = lastJson.concat(['', '']);
        }
        lastJson = lastJson.concat(json);
    });
    let worksheet = XLSX.utils.json_to_sheet(lastJson, { skipHeader: true });
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
    let wbout = XLSX.write(workbook, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array',
    });
    try {
        FileSaver.saveAs(
            new Blob([wbout], {
                type: 'application/octet-stream;charset=utf-8"',
            }),
            `${fileName}.xlsx`
        );
    } catch (e) {
        if (typeof console !== 'undefined') console.log(e, wbout);
    }
};
