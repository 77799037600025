import { Modal, Form, InputNumber, Button, Select, DatePicker, Radio } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MobileStateContext } from '../../../../../App';
import { addBalance } from '../../../../../service';
import { Moment } from 'moment';

import {
    CurrencyItem,
    queryCanFxCashCurrency,
} from '../../../../../service/requests/HistoricalTransactions';
import { showError } from '../../../../RecommendationComponent/util';

const { Option } = Select;
const UpdateAssetAssetModal = ({
    visible,
    setVisible,
    uid,
    account_id,
    handleRefreshList,
    updateAssetsType,
}: {
    visible: boolean;
    setVisible: (boolean: boolean) => void;
    handleRefreshList: () => void;
    uid: string;
    account_id: string;
    updateAssetsType: string;
}) => {
    const [canFxCurrency, setCanFxCurrency] = useState<CurrencyItem[]>([]);
    const { IsMobileMachine } = useContext(MobileStateContext);
    const onFinish = useCallback(
        (values: { amount: string; isin: string; [key: string]: any }) => {
            addBalance({
                type: updateAssetsType,
                uid,
                account_id,
                ...values,
                start_date: (values.start_date as Moment)?.format('YYYY-MM-DD'),
                trade_date: values.trade_date?.format('YYYY-MM-DD'),
                end_date: values.end_date?.format('YYYY-MM-DD'),
            }).then((res) => {
                setVisible(false);
                handleRefreshList();
            }, showError);
        },
        [updateAssetsType, uid, account_id, setVisible, handleRefreshList]
    );
    const toOptions = useMemo(
        () =>
            canFxCurrency.map((item, index) => (
                <Option key={item.currency} value={item.currency}>
                    {item.currency_text}
                </Option>
            )),
        [canFxCurrency]
    );
    useEffect(() => {
        queryCanFxCashCurrency().then((item) => {
            setCanFxCurrency(item.data);
        });
    }, []);
    return (
        <Modal
            title={`账户${returnTitle(updateAssetsType)}`}
            destroyOnClose
            footer={null}
            visible={visible}
            okText="确认"
            cancelText="取消"
            onCancel={() => {
                setVisible(false);
            }}
            width={700}
            centered
        >
            <Form
                name="updateasset"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
            >
                <Form.Item
                    label="币种"
                    name="isin"
                    rules={[{ required: true, message: '请选择币种' }]}
                >
                    <Select showArrow={false} filterOption={false} notFoundContent={null}>
                        {toOptions}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="数量"
                    name="amount"
                    rules={[{ required: true, message: '请输入数量' }]}
                >
                    <InputNumber min={0} style={{ width: '100%' }} />
                </Form.Item>
                {updateAssetsType !== 'balance' && (
                    <>
                        <Form.Item
                            label="利率"
                            name="interest"
                            rules={[{ required: true, message: '请输入数量' }]}
                        >
                            <InputNumber min={0} style={{ width: '100%' }} addonAfter="%" />
                        </Form.Item>
                        {updateAssetsType === 'deposit' && (
                            <>
                                <Form.Item
                                    label={'存款类型'}
                                    name="deposit_type"
                                    rules={[{ required: true }]}
                                >
                                    <Radio.Group>
                                        <Radio value={1}>定期</Radio>
                                        <Radio value={2}>活期</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </>
                        )}
                        <Form.Item
                            label="起始日期"
                            name="start_date"
                            rules={[{ required: true, message: '请输入成交日期' }]}
                        >
                            <DatePicker
                                inputReadOnly={IsMobileMachine}
                                // onChange={setDate}
                                format="YYYY-MM-DD"
                            />
                        </Form.Item>
                        <Form.Item
                            label="结束日期"
                            name="end_date"
                            rules={[{ required: true, message: '请输入成交日期' }]}
                        >
                            <DatePicker
                                inputReadOnly={IsMobileMachine}
                                // onChange={setDate}
                                format="YYYY-MM-DD"
                            />
                        </Form.Item>
                        <Form.Item
                            label="交易日期"
                            name="trade_date"
                            rules={[{ required: true, message: '请输入成交日期' }]}
                        >
                            <DatePicker
                                inputReadOnly={IsMobileMachine}
                                // onChange={setDate}
                                format="YYYY-MM-DD"
                            />
                        </Form.Item>
                    </>
                )}
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default UpdateAssetAssetModal;

const returnTitle = (updateAssetsType: string) =>
    updateAssetsType === 'balance' ? '入金' : updateAssetsType === 'deposit' ? '存款' : '贷款';
