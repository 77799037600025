import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import AccountItem from './FormItems/AccountItem';
import {
    Asset,
    GinkgoTransFormRefType,
    TransFundExtInfo,
    TransStatus,
    TransactionInfo,
    UpdateTransactionReq,
} from '../../../type';
import { math } from '../../../../../utils';

import DateItem from './FormItems/DateItem';
import { Form } from 'antd';
import LKRadioGroup, { LKRadioGroupProps } from '../../../../../components/LKRadioGroup';
import { useForm } from 'antd/lib/form/Form';
import { GinkgoInput, GinkgoInputNumber } from './FormItems/GinkgoFieldItems';
import moment from 'moment';
import SearchNewFund from './FormItems/SearchNewFund';
import { isNumber } from 'mathjs';
const FundForm = forwardRef<GinkgoTransFormRefType, TransactionInfo>(
    (data: TransactionInfo, onRef) => {
        const [form] = useForm();
        const [fundStructType, setFundStructType] = useState<number>();
        const [currentAsset, setCurrentAsset] = useState<Asset>();
        const [timezone, setTimezone] = useState(data.time_zone || '');
        useImperativeHandle(onRef, () => {
            return {
                getFieldsValues: async (validate) => {
                    if (validate === false) {
                        return new Promise((resolve) => {
                            return resolve(configValues(form.getFieldsValue()));
                        });
                    } else {
                        return form.validateFields().then(configValues);
                    }
                },
            };
        });

        const configValues = (values: any) => {
            return {
                ...values,
                client_id: data.client_id,
                transaction_type: data.type.code,
                id: data.id,
                ext_info: {
                    ...data.ext_info,
                    ...values.ext_info,
                },
                status_code: data.status.code,
            };
        };

        const fundStructOptions: LKRadioGroupProps['datasource'] = [
            {
                label: 'Open End',
                value: 1,
                width: '25%',
            },
            {
                label: 'Close End',
                value: 2,
                width: '25%',
            },
        ];

        const unitTypeOptions: LKRadioGroupProps['datasource'] = [
            {
                label: 'Unit',
                value: 1,
                width: '12.5%',
            },
            {
                label: 'Capital',
                value: 2,
                width: '12.5%',
            },
        ];

        const custodyTypeOptions: LKRadioGroupProps['datasource'] = [
            {
                label: '私行保管',
                value: 1,
                width: '12.5%',
            },
            {
                label: '非私行保管',
                value: 2,
                width: '12.5%',
            },
        ];

        const PlaceableReadOnly = useMemo(() => {
            return data.status.code !== TransStatus.Created;
        }, [data]);
        const TradedReadOnly = useMemo(() => {
            return data.status.code === TransStatus.Traded;
        }, [data]);
        const PlaceableRequired = useMemo(() => {
            return data.status.code === TransStatus.Created;
        }, [data]);
        const TradedRequired = useMemo(() => {
            return data.status.code === TransStatus.Placeable;
        }, [data]);
        const handleFormChange = (
            changedValues: Record<string, any>,
            allValues: UpdateTransactionReq
        ) => {
            let changeKey = Object.keys(changedValues)[0];
            const extInfo = allValues.ext_info as TransFundExtInfo;
            if (changeKey === 'ext_info') {
                changeKey = Object.keys(changedValues[changeKey])[0];
                // 1. 同步计算actual_amount
                if (
                    (changeKey === 'invest_amount' || changeKey === 'units') &&
                    isNumber(extInfo.invest_amount) &&
                    isNumber(extInfo.units)
                ) {
                    extInfo.nav = math.format(
                        math.evaluate(`${extInfo.invest_amount} / ${extInfo.units}`),
                        { notation: 'fixed', precision: 3 }
                    );
                }
                if (
                    isNumber(extInfo.invest_amount) &&
                    isNumber(extInfo.fees) &&
                    (changeKey === 'invest_amount' || changeKey === 'fees')
                ) {
                    extInfo.payment_amount = math.format(
                        math.evaluate(`${extInfo.invest_amount} + ${extInfo.fees}`),
                        { notation: 'fixed', precision: 3 }
                    );
                }
                if (
                    isNumber(extInfo.immediate_capital_call) &&
                    isNumber(extInfo.fees) &&
                    (changeKey === 'immediate_capital_call' || changeKey === 'fees')
                ) {
                    extInfo.immediate_payment = math.format(
                        math.evaluate(`${extInfo.immediate_capital_call} + ${extInfo.fees}`),
                        { notation: 'fixed', precision: 3 }
                    );
                }
                form.setFieldsValue({
                    ext_info: extInfo,
                });
            }
        };
        useEffect(() => {
            let ext_info = data.ext_info as TransFundExtInfo;
            setFundStructType(ext_info.fund_struct_type);
            form.setFieldsValue({
                ...data,
                trade_date:
                    typeof data.trade_date === 'number' ? data.trade_date : moment().valueOf(),
            });
        }, [data, form]);

        return (
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                form={form}
                labelWrap
                onValuesChange={handleFormChange}
            >
                <Form.Item
                    label="保管类型"
                    name={['ext_info', 'bank_custody_type']}
                    rules={[{ required: PlaceableRequired, message: '保管类型 is required' }]}
                >
                    <LKRadioGroup
                        datasource={custodyTypeOptions}
                        onChange={(value) => {}}
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>

                <AccountItem
                    clientId={data.client_id}
                    name={['ext_info', 'account_id']}
                    form={form}
                    readOnlyValue={data.ext_info.account_id}
                    selectCallback={(bank) => {
                        setTimezone(bank.time_zone);
                    }}
                    width="calc(62.5% - 10px)"
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                />
                <SearchNewFund
                    readOnly={PlaceableReadOnly}
                    onChange={(asset) => {
                        setCurrentAsset(asset);
                        let values = form.getFieldsValue();
                        setFundStructType((asset?.ext_info as TransFundExtInfo)?.fund_struct_type);
                        form.setFieldsValue({
                            ...values,
                            ext_info: {
                                ...form.getFieldValue('ext_info'),
                                currency: asset?.currency,
                                isin: asset?.isin,
                                ...asset?.ext_info,
                                asset_name: asset?.name,
                                unit_type:
                                    (asset?.ext_info as TransFundExtInfo)?.fund_struct_type === 1
                                        ? 1
                                        : 2,
                            },
                        });
                    }}
                />

                <Form.Item label="Fund Manager" name={['ext_info', 'fund_manager']}>
                    <GinkgoInput style={{ width: 'calc(62.5% - 10px)' }} readOnly />
                </Form.Item>

                <Form.Item label="Currency" name={['ext_info', 'currency']}>
                    <GinkgoInput style={{ width: 'calc(62.5% - 10px)' }} readOnly />
                </Form.Item>

                <Form.Item label="ISIN" name={['ext_info', 'isin']}>
                    <GinkgoInput style={{ width: 'calc(62.5% - 10px)' }} readOnly />
                </Form.Item>

                <Form.Item label="Fund Struct" name={['ext_info', 'fund_struct_type']}>
                    <LKRadioGroup datasource={fundStructOptions} readOnly />
                </Form.Item>

                <Form.Item label="Unit Type" name={['ext_info', 'unit_type']}>
                    <LKRadioGroup datasource={unitTypeOptions} readOnly />
                </Form.Item>

                {fundStructType === 1 && (
                    <>
                        <Form.Item
                            label="Invest Amount"
                            name={['ext_info', 'invest_amount']}
                            rules={[
                                { required: TradedRequired, message: 'Invest Amount is required' },
                            ]}
                        >
                            <GinkgoInputNumber
                                style={{ width: 'calc(62.5% - 10px)' }}
                                readOnly={TradedReadOnly}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Units"
                            name={['ext_info', 'units']}
                            rules={[{ required: TradedRequired, message: 'Units is required' }]}
                        >
                            <GinkgoInputNumber
                                style={{ width: 'calc(62.5% - 10px)' }}
                                readOnly={TradedReadOnly}
                            />
                        </Form.Item>

                        <Form.Item label="Nav" name={['ext_info', 'nav']}>
                            <GinkgoInputNumber style={{ width: 'calc(62.5% - 10px)' }} readOnly />
                        </Form.Item>

                        <Form.Item
                            label="Fees"
                            name={['ext_info', 'fees']}
                            rules={[{ required: TradedRequired, message: 'Fees is required' }]}
                        >
                            <GinkgoInputNumber
                                style={{ width: 'calc(62.5% - 10px)' }}
                                readOnly={TradedReadOnly}
                            />
                        </Form.Item>
                        <Form.Item label="Payment Amount" name={['ext_info', 'payment_amount']}>
                            <GinkgoInputNumber style={{ width: 'calc(62.5% - 10px)' }} readOnly />
                        </Form.Item>
                    </>
                )}
                {fundStructType === 2 && (
                    <>
                        <Form.Item
                            label="Committed Capital"
                            name={['ext_info', 'committed_capital']}
                            rules={[
                                {
                                    required: TradedRequired,
                                    message: 'Committed Capital is required',
                                },
                            ]}
                        >
                            <GinkgoInputNumber
                                style={{ width: 'calc(62.5% - 10px)' }}
                                readOnly={TradedReadOnly}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Immediate Capital Call"
                            name={['ext_info', 'immediate_capital_call']}
                            rules={[
                                {
                                    required: TradedRequired,
                                    message: 'Immediate Capital Call is required',
                                },
                            ]}
                        >
                            <GinkgoInputNumber
                                style={{ width: 'calc(62.5% - 10px)' }}
                                readOnly={TradedReadOnly}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Fees"
                            name={['ext_info', 'fees']}
                            rules={[{ required: TradedRequired, message: 'Fees is required' }]}
                        >
                            <GinkgoInputNumber
                                style={{ width: 'calc(62.5% - 10px)' }}
                                readOnly={TradedReadOnly}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Immediate Payment"
                            name={['ext_info', 'immediate_payment']}
                            rules={[
                                {
                                    required: TradedRequired,
                                    message: 'Immediate Payment is required',
                                },
                            ]}
                        >
                            <GinkgoInputNumber style={{ width: 'calc(62.5% - 10px)' }} readOnly />
                        </Form.Item>
                    </>
                )}

                <DateItem
                    label="Trade Date"
                    name="trade_date"
                    width="calc(62.5% - 10px)"
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                    timezone={timezone}
                />
                <DateItem
                    label="Value Date"
                    name="value_date"
                    width="calc(62.5% - 10px)"
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                    timezone={timezone}
                />
            </Form>
        );
    }
);

export default FundForm;
