import { Result } from 'antd';
import React from 'react';
import DocumentTitle from 'react-document-title';

export default function EmptyRouterPage() {
    return (
        <DocumentTitle title="警告 - 路由错误">
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Result
                    status="warning"
                    title={
                        <p>找不到该页面对应的路由组件，请检查相关的设置或联系对应的开发人员。</p>
                    }
                />
            </div>
        </DocumentTitle>
    );
}
