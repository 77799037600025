import {
    TRACK_RECORD_TAGS,
    TRACK_RECORD_LIST,
    ALL_TRACK_RECORD_LIST,
    SAVE_PAGE_STATUS,
    SET_ASSET_MODEL,
    SET_ACTIVITY_MODEL,
    SET_CUSTOMER_MODEL,
    SET_POSITION_MODEL,
    CUSTOMER_DETAIL_CARD_REFRESHKEY,
} from '../../constants/constants';
import { getTrackRecordList } from '../../../modules/CustomerList/components/Record/request';
import { showError } from '../../../modules/RecommendationComponent/util';
import { CustomerDetailCardType } from '../../../modules/CustomerList/components/CustomerDetail/type';
import { Dispatch } from 'redux';
import { LKRootState } from '../../store';
import { CustomerDetailCardRefreshKeyType } from '../../type';
export function setRecordTags(data: any) {
    return {
        type: TRACK_RECORD_TAGS,
        trackRecordTags: data,
    };
}
export function setRecordList(data: any) {
    return {
        type: TRACK_RECORD_LIST,
        trackRecordList: data,
    };
}
export function setAllRecordList(data: any) {
    return {
        type: ALL_TRACK_RECORD_LIST,
        allTrackRecordList: data,
    };
}
export function setPageStatus(data: any) {
    return {
        type: SAVE_PAGE_STATUS,
        pageStatus: data,
    };
}
export function setAssetModel(data: any) {
    return {
        type: SET_ASSET_MODEL,
        assetModel: data,
    };
}
export function setActivityModel(data: any) {
    return {
        type: SET_ACTIVITY_MODEL,
        activityModel: data,
    };
}
export function setCustomerModel(data: any) {
    return {
        type: SET_CUSTOMER_MODEL,
        customerModel: data,
    };
}
export function setPositionModel(data: any) {
    return {
        type: SET_POSITION_MODEL,
        positionModel: data,
    };
}
function setCustomerDetailCardRefreshKey(data: CustomerDetailCardRefreshKeyType) {
    return {
        type: CUSTOMER_DETAIL_CARD_REFRESHKEY,
        card_refresh_key: data,
    };
}

export const refreshDetailCard = (type: CustomerDetailCardType) => {
    return (dispatch: Dispatch, getState: () => LKRootState) => {
        const data = getState().customer.card_refresh_key;
        let newData = { ...data, [type]: data[type] + 1 };
        dispatch(setCustomerDetailCardRefreshKey(newData));
    };
};
export function getRecordList(customer_id?: any, all?: any, tags?: any) {
    tags = tags ?? [];
    tags = tags[0] === 'all' ? [] : tags;
    return (dispatch: Dispatch) => {
        getTrackRecordList({
            customer_id: customer_id,
            current_page: 1,
            page_size: all ? 100 : 3,
            tags: tags ?? [],
        }).then(
            (res) => dispatch(all ? setAllRecordList(res.data) : setRecordList(res.data)),
            showError
        );
    };
}
