import React, { useMemo } from 'react';
import { PositionAssetList } from '../type';
import { Table } from 'antd';
import { getColumnsFromAssetType } from '../columns';
import { useHistory } from 'react-router-dom';
import { AssetType } from '../../Transaction/type';

const AssetCommonTable = ({
    data,
    id,
    withClick,
}: {
    data?: PositionAssetList;
    id?: string;
    withClick: boolean;
}) => {
    const columns = useMemo(() => {
        if (data) {
            return getColumnsFromAssetType(data.asset_types[0].code, withClick);
        } else {
            return [];
        }
    }, [data, withClick]);
    const history = useHistory();
    return (
        <Table
            size="small"
            columns={columns}
            pagination={false}
            dataSource={data?.positions}
            bordered
            id={id}
            rowKey="id"
            onRow={(record) => {
                return {
                    onClick: () => {
                        if (
                            record.asset_type.code === AssetType.Cash ||
                            record.asset_type.code === AssetType.RCN ||
                            record.asset_type.code === AssetType.NCD ||
                            record.asset_type.code === AssetType.Other
                        ) {
                            return;
                        }
                        history.push(
                            `/app/assetManagement/position/detail/${record.account.account_id}/${record.isin}`
                        );
                    },
                };
            }}
            scroll={{
                x: 'max-content',
            }}
        />
    );
};

export default AssetCommonTable;
