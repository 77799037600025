import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Button, Pagination, Modal } from 'antd';
import { MobileStateContext } from '../../../App';

import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { showError } from '../../RecommendationComponent/util';
import { NoticeAddReqType, noticeDelete, noticeList } from '../../../service/requests/Notice';
import { FormatDate } from '../../../utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import NoticeAdd from '../NoticeAdd';

const { confirm } = Modal;
const NoticeList = ({ currentIndex }: { currentIndex: number }) => {
    const { isMobile } = useContext(MobileStateContext);
    const [offset, setOffset] = useState(0);
    const [totalNum, setTotalNum] = useState(0);
    const [refreshList, setRefreshList] = useState(0);
    const [currentSelect, setCurrentSelect] = useState<NoticeAddReqType>();
    const handleRefreshList = useCallback(() => setRefreshList((num) => num + 1), []);
    const [visible, setVisible] = useState(false);
    const [userList, setUserList] = useState<Array<NoticeAddReqType>>([]);
    useEffect(() => {
        if (currentIndex === 2) {
            noticeList({
                offset,
            }).then((data) => {
                setUserList(data.data.notices);
                setTotalNum(data.data.total_count);
            }, showError);
        }
    }, [offset, currentIndex, refreshList]);
    const handlePaginationChange = useCallback((page: number) => {
        setOffset((page - 1) * 10);
    }, []);

    const columnDefs = useMemo<ColDef[]>(
        () => [
            {
                headerName: '资产名',
                field: 'name',
                width: 180,
            },
            {
                headerName: 'isin',
                field: 'isin',
                width: 150,
            },
            {
                headerName: 'ticker',
                field: 'ticker',
                width: 100,
            },
            {
                headerName: '时效',
                width: 70,
                cellRenderer: (params: ICellRendererParams) => {
                    let timeStr =
                        params.data.duration_value +
                        (params.data.duration_type === 1 ? '天' : '小时');
                    return <div>{timeStr}</div>;
                },
            },
            {
                headerName: '失效时间',
                width: 170,
                cellRenderer: (params: ICellRendererParams) => {
                    let timeStr = FormatDate(params.data.expired_at, 'yyyy-MM-dd HH:mm:ss');
                    return <div>{timeStr}</div>;
                },
            },
            {
                headerName: '状态',
                field: 'status',
                cellRenderer: (params: ICellRendererParams) => {
                    return (
                        <div>
                            {params.data.status === 1
                                ? '生效中'
                                : params.data.status === 2
                                ? '手动关闭'
                                : '已失效'}
                        </div>
                    );
                },
            },
            {
                headerName: '公告文案',
                field: 'notice',
                width: 300,
            },
            {
                headerName: '操作',
                pinned: 'right',
                width: 180,
                cellRenderer: (params: ICellRendererParams) => (
                    <>
                        <Button
                            disabled={params.data.status !== 1}
                            onClick={() => {
                                setCurrentSelect(params.data);
                                setVisible(true);
                            }}
                        >
                            修改
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                confirm({
                                    title: '确认关闭?',
                                    icon: <ExclamationCircleOutlined />,
                                    onOk() {
                                        noticeDelete({ id: params.data.id }).then(() => {
                                            handleRefreshList();
                                        });
                                    },
                                });
                            }}
                            disabled={params.data.status !== 1}
                        >
                            关闭
                        </Button>
                    </>
                ),
            },
        ],
        [handleRefreshList]
    );

    return (
        <>
            <Modal
                destroyOnClose
                footer={null}
                visible={visible}
                okText="确认"
                cancelText="取消"
                onCancel={() => setVisible(false)}
                centered
            >
                <NoticeAdd
                    data={currentSelect}
                    complete={() => {
                        setVisible(false);
                        handleRefreshList();
                    }}
                />
            </Modal>
            <div className="ag-theme-alpine">
                <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={{ resizable: true, width: 96, minWidth: 68, suppressMenu: true }}
                    rowData={userList}
                    domLayout="autoHeight"
                    className="CustomerList__Table"
                />
            </div>
            {totalNum > 0 && userList.length > 0 ? (
                <Pagination
                    onChange={handlePaginationChange}
                    total={totalNum}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 30,
                        width: isMobile ? '100%' : '90%',
                    }}
                    pageSize={10}
                    defaultCurrent={1}
                    showSizeChanger={false}
                />
            ) : null}
        </>
    );
};
export default NoticeList;
