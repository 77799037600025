import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import styles from './index.module.less';
import moment from 'moment-timezone';

interface DateEditableCellProps {
    record: any;
    index: number;
    dataIndex: string;
    timezone?: string;
    editing: boolean;
    children: React.ReactNode;
    handleChange: (record: any, idx: number) => void;
}
const DateEditableCell = (props: DateEditableCellProps) => {
    const {
        record,
        index,
        dataIndex,
        timezone,
        editing,
        children,
        handleChange,
        ...restProps
    } = props;
    const [value, setValue] = useState<number>();
    let childNode = children;

    useEffect(() => {
        if (record) {
            const temp = record[dataIndex];
            if (temp) {
                setValue(moment(moment.tz(temp, timezone!).format('yyyy-MM-DD')).valueOf());
            }
        }
    }, [record, dataIndex]);

    const renderForm = () => {
        return (
            <DatePicker
                value={value ? moment(value) : undefined}
                onChange={(value, dateStr) => {
                    if (value) {
                        setValue(moment(dateStr).valueOf());
                        if (handleChange) {
                            const temp = {
                                ...record,
                                [dataIndex]: moment
                                    .tz(dateStr, timezone || moment.tz.guess())
                                    .valueOf(),
                            };
                            handleChange(temp, index);
                        }
                    } else {
                        setValue(undefined);
                        if (handleChange) {
                            const temp = {
                                ...record,
                                [dataIndex]: null,
                            };
                            handleChange(temp, index);
                        }
                    }
                }}
                suffixIcon={null}
            />
        );
    };
    return editing ? (
        <td className={styles.cell}>{renderForm()}</td>
    ) : (
        <td {...restProps}>{childNode}</td>
    );
};

export default DateEditableCell;
