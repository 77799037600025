import React, { useMemo, useState, useEffect } from 'react';
import {
    queryTranscationLoan,
    queryTranscationOrder,
    queryTranscationWithdraw,
} from '../../../service/requests/HistoricalTransactions';
import { quoteOrderList } from '../../../service/type';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import { FormatDate } from '../../../utils';
import moment from 'moment';

const TranscationOptionSelectOrder = ({
    userid,
    account_id,
    type,
    selectData,
}: {
    userid?: number;
    account_id?: string;
    type: 'options' | 'accum' | 'decum' | 'fcn' | 'loan' | 'withdraw';
    selectData: (data: quoteOrderList['order_info_list'][number]) => void;
}) => {
    let columnDefs: ColDef[] = [
        { headerName: '产品', width: 125, checkboxSelection: true, field: 'product' },
        { headerName: '股票', width: 125, field: 'share' },
        { headerName: '期限', width: 125, field: 'tenor' },
        {
            headerName: '下单时间',
            width: 125,
            field: 'created_at',
            valueFormatter: (params: ValueFormatterParams) =>
                FormatDate(params.value, 'dd/MM/yyyy.HH:mm'),
        },
        { headerName: '期权类型', width: 125, field: 'option_type' },
    ];
    switch (type) {
        case 'accum':
            columnDefs.splice(4, 1);
            break;
        case 'decum':
            columnDefs.splice(4, 1);
            break;
        case 'fcn':
            columnDefs = [
                { headerName: '产品', width: 125, checkboxSelection: true, field: 'product' },
                { headerName: '期限', width: 125, field: 'tenor' },
                { headerName: '票息%', width: 125, field: 'coupon' },
                {
                    headerName: '下单时间',
                    width: 125,
                    field: 'created_at',
                    valueFormatter: (params: ValueFormatterParams) =>
                        FormatDate(params.value, 'dd/MM/yyyy.HH:mm'),
                },
            ];
            break;
        case 'loan':
            columnDefs = [
                { headerName: '贷款货币', width: 125, checkboxSelection: true, field: 'currency' },
                { headerName: '贷款数量', width: 125, field: 'occur_amount' },
                { headerName: '贷款利率', width: 125, field: 'loan_rate' },
                {
                    field: '',
                    headerName: '起始日期',
                    width: 120,
                    cellRenderer: (params: ICellRendererParams) =>
                        moment(params.data.begin_at).format('YYYY-MM-DD'),
                },
                {
                    field: '',
                    headerName: '截止日期',
                    width: 120,
                    cellRenderer: (params: ICellRendererParams) =>
                        moment(params.data.end_at).format('YYYY-MM-DD'),
                },
            ];
            break;
        case 'withdraw':
            columnDefs = [
                { headerName: '存款货币', width: 125, checkboxSelection: true, field: 'currency' },
                {
                    headerName: '存款数量',
                    width: 125,
                    cellRenderer: (params: ICellRendererParams) => {
                        let a = params.data.occur_balance?.replaceAll(',', '');
                        let b = params.data.fill_balance?.replaceAll(',', '');

                        return (Number.parseFloat(a) + Number.parseFloat(b) ?? 0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    },
                },
                {
                    headerName: '存款利率',
                    width: 125,
                    cellRenderer: (params: ICellRendererParams) => params.data.interest + '%',
                },
                {
                    headerName: '类型',
                    width: 125,
                    cellRenderer: (params: ICellRendererParams) =>
                        params.data.deposit_type === 1 ? '定期' : '活期',
                },
                {
                    field: '',
                    headerName: '起始日期',
                    width: 120,
                    cellRenderer: (params: ICellRendererParams) =>
                        moment(params.data.begin_at).format('YYYY-MM-DD'),
                },
                {
                    field: '',
                    headerName: '截止日期',
                    width: 120,
                    cellRenderer: (params: ICellRendererParams) =>
                        moment(params.data.end_at).format('YYYY-MM-DD'),
                },
            ];
            break;
        default:
            break;
    }
    const [historyData, setHistoryData] = useState<quoteOrderList>();

    const defaultColDef = useMemo<ColDef>(
        () => ({
            width: 100,
            sortable: true,
            resizable: true,
        }),
        []
    );
    useEffect(() => {
        if (type === 'loan') {
            if (account_id && account_id.length > 0) {
                queryTranscationLoan({ account_id: account_id, delivered: 1 }).then((res) => {
                    setHistoryData(res.data);
                });
            }
        } else if (type === 'withdraw') {
            if (account_id && account_id.length > 0) {
                queryTranscationWithdraw({ account_id: account_id, delivered: 1 }).then((res) => {
                    setHistoryData(res.data);
                });
            }
        } else {
            if (userid && userid > 0) {
                queryTranscationOrder({ customer_id: userid, product_type: type }).then((res) => {
                    setHistoryData(res.data);
                });
            }
        }
    }, [userid, type, account_id]);
    return (
        <>
            <div className="ag-theme-alpine" style={{ width: '100%', marginBottom: '20px' }}>
                <AgGridReact
                    animateRows
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowData={historyData?.order_info_list ?? historyData?.orders ?? []}
                    domLayout="autoHeight"
                    enableCellTextSelection
                    ensureDomOrder
                    suppressCellFocus
                    rowSelection="single"
                    onRowSelected={(value) => {
                        if (value.node.isSelected()) {
                            selectData(value.data);
                        }
                    }}
                />
            </div>
        </>
    );
};
export default TranscationOptionSelectOrder;
