import { Button, Empty } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getRecordList, setRecordTags } from '../../../../../store/actions/actions';
import { LKRootState } from '../../../../../store/store';
import RecordListModal, { RecordListModalType } from '../component/Modal';
import RecordItem from '../component/RecordItem';
import RecordTags from '../component/RecordTags';
import { getTrackRecordTags } from '../request';
import './index.less';
const TrackRecordList = () => {
    const { id, tag } = useParams<{ id: string; tag: string }>();
    const disptch = useDispatch();
    useEffect(() => {
        disptch(getRecordList(id, true));
    }, [id, disptch]);
    const track_list = useSelector((state: LKRootState) => state.customer.all_Track_list);
    const tags = useSelector((state: LKRootState) => state.customer.tags);
    useEffect(() => {
        if ((tags ?? []).length === 0) {
            getTrackRecordTags().then((res) => {
                disptch(setRecordTags(res.data.customer_track_tags));
            });
        }
    }, [disptch, tags]);
    const radios = useMemo(() => {
        return ['全部', ...(tags ?? []).map((item) => item.name)];
    }, [tags]);
    const recordModalState = useState(false);

    return (
        <>
            <div className="all_trackrecord">
                {recordModalState[0] && (
                    <RecordListModal
                        isList
                        customer_id={Number.parseInt(id)}
                        visibleState={recordModalState}
                        modalType={RecordListModalType.Add}
                    />
                )}

                <div className="headers">
                    <RecordTags
                        tags={radios}
                        touchBack={(tag) => {
                            disptch(getRecordList(id, true, tag === '全部' ? [] : [tag]));
                        }}
                    />
                    <Button
                        type="text"
                        onClick={() => {
                            recordModalState[1](true);
                        }}
                    >
                        + 新增记录
                    </Button>
                </div>
                {(track_list?.customer_track_logs ?? []).map((item) => (
                    <RecordItem
                        key={item.id}
                        data={item}
                        customer_id={Number.parseInt(id)}
                        tags={[tag]}
                        isList
                    />
                ))}
                {(track_list?.customer_track_logs ?? []).length === 0 && (
                    <Empty description="暂无数据" style={{ marginTop: '50px' }} />
                )}
            </div>
        </>
    );
};

export default TrackRecordList;
