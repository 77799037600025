import {
    Button,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Radio,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AccountInfoType } from '../../../../../service/requests/CustomerManage';
import { showError } from '../../../../RecommendationComponent/util';
import {
    AccountAttr,
    AssetItem,
    getAccountAttr,
    getAssetClasses,
    updateAccount,
} from '../../requests';

interface EditAccountModalProps {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    account_id: string;
}

export default function EditAccountModal(props: EditAccountModalProps) {
    const { visible, setVisible, account_id } = props;
    const [form] = useForm();

    const [accountAttr, setAccountAttr] = useState<AccountAttr>();
    const [assetClasses, setAssetClasses] = useState<AssetItem[]>([]);
    const [accountInfo, setAccountInfo] = useState<AccountInfoType>();
    useEffect(() => {
        if (visible) {
            getAccountAttr({ account_id }).then((res) => {
                setAccountInfo(res.data.account);
                setAccountAttr(res.data.attr);
            }, showError);
            getAssetClasses().then((res) => setAssetClasses(res.data.items), showError);
        }
    }, [account_id, visible]);

    const initialValues = useMemo(
        () => ({
            account_name: accountAttr?.account_name,
            custodian_fee: accountAttr?.attr_class?.custodian_fee,
            asset_class: Object.keys(accountAttr?.asset_class_attr?.custodian_fee ?? {}),
            effective_at: accountAttr?.effective_at ? moment(accountAttr.effective_at) : undefined,
            tag: accountInfo?.tag,
            display_name: accountInfo?.display_name,
            vendor_sub_account_id: accountInfo?.vendor_sub_account_id,
        }),
        [accountAttr, accountInfo]
    );

    useEffect(() => {
        form.resetFields();
    }, [form, initialValues]);

    const onFormFinish = useCallback(
        (values: typeof initialValues) => {
            updateAccount({
                account_id,
                account_name: values.account_name,
                attr_class: {
                    custodian_fee: values.custodian_fee,
                },
                asset_class_attr: {
                    custodian_fee: Object.fromEntries(
                        values.asset_class.map((item) => [item, values.custodian_fee])
                    ),
                },
                vendor_account_id: values.vendor_sub_account_id,
                tag: values.tag!,
                display_name: values.display_name,
                effective_at: values.effective_at?.valueOf(),
            })
                .then(() => {
                    message.success('编辑成功');
                    setVisible(false);
                })
                .catch(showError);
        },
        [account_id, initialValues, setVisible]
    );

    return (
        <Modal
            visible={visible}
            onCancel={() => setVisible(false)}
            title="编辑账户"
            footer={null}
            width={560}
        >
            <Form
                labelCol={{ span: 6 }}
                labelWrap
                wrapperCol={{ offset: 1, span: 16 }}
                form={form}
                initialValues={initialValues}
                onFinish={onFormFinish}
            >
                <Form.Item
                    label="开户名"
                    name="account_name"
                    rules={[{ required: true, message: '请输入开户名' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    initialValue={1}
                    label="账户标签"
                    name="tag"
                    rules={[{ required: true }]}
                >
                    <Radio.Group>
                        <Radio value={1}>常规</Radio>
                        <Radio value={2}>专户</Radio>
                        <Radio value={3}>全委</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="显示名称" name="display_name">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="账户号"
                    name="vendor_sub_account_id"
                    rules={[{ required: false, message: '请输入账户号' }]}
                >
                    <Input />
                </Form.Item>
                <Divider style={{ marginTop: '32px' }} />
                <p style={{ textAlign: 'center' }}>账户管理费</p>
                <Form.Item
                    label="托管费率"
                    name="custodian_fee"
                    rules={[{ required: true, message: '请输入托管费率' }]}
                >
                    <InputNumber addonAfter="%" min={0} />
                </Form.Item>
                <Form.Item label="Fee-waiving asset class" name="asset_class">
                    <Checkbox.Group>
                        <Row>
                            {assetClasses.map((assetClass) => (
                                <Col span={12} key={assetClass.value}>
                                    <Checkbox
                                        value={assetClass.value}
                                        style={{ lineHeight: '28px' }}
                                    >
                                        {assetClass.text}
                                    </Checkbox>
                                </Col>
                            ))}
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
                <Form.Item label="生效时间" name="effective_at">
                    <DatePicker showTime />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </div>
            </Form>
        </Modal>
    );
}
