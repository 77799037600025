import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Form, Select, message } from 'antd';
import React, { useCallback, useState } from 'react';
import { addCustomer, getCustomerLists } from '../../../../service';
import { CustomList } from '../../../../service/type';
import { showError } from '../../../RecommendationComponent/util';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const AddExhibitionUserForm = ({ setRefreshList }: { setRefreshList: () => void }) => {
    const history = useHistory();

    const [customerList, setCustomerList] = useState<CustomList['customer_list']>([]);
    const onFinish = useCallback(
        (values: { customerIndex: number }) => {
            const target = customerList[values.customerIndex];
            addCustomer({
                customer_id: target.id,
            }).then(() => {
                message.success('添加成功');
                setRefreshList();
            }, showError);
        },
        [setRefreshList, customerList]
    );

    const handleSearch = useCallback((searchKey: string) => {
        getCustomerLists({
            searchKey,
        }).then((res) => {
            setCustomerList(res.data.customer_list);
        }, showError);
    }, []);
    const options = customerList.map((item, index) => (
        <Option key={item.id} value={index}>
            {item.name}
        </Option>
    ));
    return (
        <Form
            name="AddExhibitionUserForm"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 16 }}
            onFinish={onFinish}
        >
            <Form.Item
                label="选择客户"
                name="customerIndex"
                rules={[{ required: true, message: '请输入ID或姓名' }]}
            >
                <Select
                    showSearch
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={handleSearch}
                    notFoundContent={null}
                    placeholder={'输入搜索'}
                >
                    {options}
                </Select>
            </Form.Item>
            <div style={{ marginLeft: 18, marginBottom: 18 }}>
                <InfoCircleOutlined style={{ marginRight: 10 }} />
                可前往
                <span
                    style={{ color: 'blue' }}
                    onClick={() => {
                        history.push('/app/customer/list');
                    }}
                >
                    客户列表
                </span>
                创建一位新客户
            </div>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    提交
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddExhibitionUserForm;
