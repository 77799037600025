import { BankType } from '../../modules/Transaction/type';
import axios from '../tools';
import { quoteOrder, quoteOrderList } from '../type';

export interface UpdateQuoteOrderData {
    order_id: number;
    product_type: string;
    product?: string;
    strike_price_ratio: number;
    qty: number;
    status: number;
    option_premium_ratio?: number;
    limit_order_price?: number;
    limit_order_share?: string;
    knock_out?: number;
    knock_out_type?: string;
    sold_out_days?: string;
    trade_amount?: number;
}
export interface UserAccountDetailInfo {
    uid: string;
    account_id: string;
    account_type: BankType;
    account_name: string;
    bank_name: string;
    account_class: number;
    vendor_account_id?: string;
    vendor_sub_account_id?: string;
    time_zone: string;
}
export interface ConfirmTranscationReqParam {
    termination_fee_currency?: string;
    order_id?: string;
    from_or_to_account_name?: string;
    from_or_to_account_id?: string;
    account_type?: number;
    deposit_matched_order_id?: number;
    target_account_id?: string;
    from_account_name?: string;
    amount?: string;
    loan_rate?: string;
    deposit_type?: number;
    occur_balance?: string;
    fill_balance?: string;
    interest?: string;
    loan_order_id?: number;
    isin?: string;
    termination_fee?: string;
    end_at?: string;
    currency?: string;
    begin_at?: string;
    fee_currency?: string;
    fee?: string;
    client_token: string;
    to_account_id?: string;
    occur_amount?: string;
    account_id: string;
    from_currency?: string;
    from_amount?: string;
    to_currency?: string;
    to_amount?: string;
    fx?: string;
    matched_at?: string;
    matched_qty?: string;
    matched_price?: string;
    ext_fields?: any;
    action?: number;
}
export interface CashItem {
    currency: string;
    balance: string;
    balanceNumber: number;
    account_id: string;
}
export interface CurrencyItem {
    currency: string;
    currency_text: string;
}
/**
 * 更新定单
 * @param data
 * @returns
 */
export const updateQuoteOrder = (data: UpdateQuoteOrderData) => {
    return axios.post('/v2/product/quote/edit_order_fields', {
        data,
    });
};
/**
 * 获取当前选择的账户下所有银行账户
 * @param data
 * @returns
 */
export const getUserAllAccount = (data: { customer_id: string }) => {
    return axios.post<UserAccountDetailInfo[]>('/v2/admin/customer/query_bank_accounts', {
        data,
    });
};
/**
 * 二次确认
 * @param data
 * @returns
 */
export const confirmTranscationAgain = (data: ConfirmTranscationReqParam) => {
    return axios.post('/v2/admin/order/match', {
        data,
    });
};

/**
 * 换汇成交接口
 * @param data
 * @returns
 */
export const cashFxTranscation = (data: ConfirmTranscationReqParam) => {
    return axios.post('/v2/admin/order/match_fx', {
        data,
    });
};
/**
 * 现金成交接口
 * @param data
 * @returns
 */
export const cashTranscation = (data: ConfirmTranscationReqParam) => {
    return axios.post('/v2/admin/order/match_currency', {
        data,
    });
};

/**
 * 贷款成交接口
 * @param data
 * @returns
 */
export const loanTranscation = (data: ConfirmTranscationReqParam) => {
    return axios.post('/v2/admin/order/match_loan', {
        data,
    });
};
/**
 * 存款成交接口
 * @param data
 * @returns
 */
export const depositTranscation = (data: ConfirmTranscationReqParam) => {
    return axios.post('/v2/admin/order/match_deposit', {
        data,
    });
};
/**
 * 存款成交接口
 * @param data
 * @returns
 */
export const withdrawTranscation = (data: ConfirmTranscationReqParam) => {
    return axios.post('/v2/admin/order/match_withdraw', {
        data,
    });
};
/**
 * 查询当前账户下的可建仓订单
 */
export const queryTranscationOrder = (data: {
    customer_id: number;
    product_type: 'options' | 'accum' | 'decum' | 'fcn';
}) => {
    return axios.post<quoteOrderList>('/v2/admin/order/preorders', {
        data,
    });
};
/**
 * 查询当前账户下的可还贷款订单
 */
export const queryTranscationLoan = (data: { account_id: string; delivered: number }) => {
    return axios.post<quoteOrderList>('/v2/admin/order/matched_loan_orders_by_account', {
        data,
    });
};
/**
 * 查询当前账户下的可取款订单
 */
export const queryTranscationWithdraw = (data: { account_id: string; delivered: number }) => {
    return axios.post<quoteOrderList>('/v2/admin/order/matched_orders_deposit', {
        data,
    });
};
/**
 * 查询当前账户下的可平仓订单
 */
export const queryCloseTranscationOrder = (data: {
    account_id: string;
    product_type: 'options' | 'accum' | 'decum' | 'fcn';
}) => {
    return axios.post<Array<quoteOrder>>('/v2/admin/order/avail_positions', {
        data,
    });
};
/**
 * 查询当前账户下的可平仓订单
 */
export const queryAccountCash = (data: { account_id: string }) => {
    return axios.post<Array<CashItem>>('/v1/admin/account/disposable_balance', {
        data,
    });
};
/**
 * 查询当前账户下的可转换货币类型
 */
export const queryCanFxCashCurrency = () => {
    return axios.post<Array<CurrencyItem>>('/v1/admin/support_currencies', {});
};

/**
 * 查询当前账户下的可交易股票
 */
export const queryDisposablePosition = (data: { isin: string; account_id: string }) => {
    return axios.post('/v1/admin/account/disposable_position', { data });
};
