import { Form, FormInstance, Input, Select } from 'antd';
import React, { ReactNode, useContext, useEffect, useRef } from 'react';
import { BondQuoteEntity } from '../../../Quotation/request';
export enum EditableCellType {
    Input = 'Input',
    Select = 'Select',
}

export interface EditableCellProps<Item> {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: keyof Item;
    record: Item;
    addonAfter?: ReactNode;
    required?: boolean;
    handleSave: (record: Item) => void;
    options?: { key: string; value: string }[];
    type?: EditableCellType;
}
const EditableContext = React.createContext<FormInstance<any> | null>(null);

const EditableRow = ({ index, ...props }: { index: number }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    addonAfter,
    required,
    handleSave,
    type,
    options,
    ...restProps
}: EditableCellProps<BondQuoteEntity>) => {
    const inputRef = useRef<any>(null);
    const form = useContext(EditableContext)!;
    const toggleEdit = () => {
        // setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };
    useEffect(() => {
        // form.setFieldsValue(record);
        if (form && record) {
            form.setFieldsValue(record);
        }
    }, [record, form]);
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    const renderContent = () => {
        let item: ReactNode;

        switch (type) {
            case EditableCellType.Input:
                item =
                    record.order_type === 1 && dataIndex === 'limit_price' ? (
                        <div style={{ textAlign: 'center' }}>--</div>
                    ) : (
                        <Input
                            ref={inputRef}
                            onPressEnter={save}
                            onBlur={save}
                            key={typeof type}
                            required={required}
                            addonAfter={addonAfter}
                        />
                    );
                break;
            case EditableCellType.Select:
                item = (
                    <Select onSelect={save} key={typeof type}>
                        {options?.map((item) => (
                            <Select.Option key={item.key} value={item.value}>
                                {item.key}
                            </Select.Option>
                        ))}
                    </Select>
                );
        }

        return (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                {item}
            </Form.Item>
        );
    };
    let childNode = children;
    if (editable) {
        childNode = editable ? (
            renderContent()
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 24 }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};
export { EditableCell, EditableRow };
