import AuthorityMemberCenter from './AuthorityMemberCenter';
import AuthorityRoleSettings from './AuthorityRoleSettings';
import AuthorityTree from './AuthorityTree';

const AuthorityCenterPages = {
    '/app/authorityCenter/members': AuthorityMemberCenter,
    '/app/authorityCenter/roleSettings': AuthorityRoleSettings,
    '/app/authorityCenter/authorityTree': AuthorityTree,
};

export default AuthorityCenterPages;
