import React, { useState } from 'react';
import { Button, message, Modal } from 'antd';
import { showError, stopPropagation } from '../../RecommendationComponent/util';
import { useCallback } from 'react';
import { AssetAllocPlan } from '../../../service/type';
import { updateAssetAllocPlanPkg } from '../../../service';
import AddAsset from './AddAsset';
import UpdateAssetModal from './UpdateAssetModal';
import { Input } from 'antd';

const { TextArea } = Input;

const PanelHeader: React.FC<{
    packageValue: AssetAllocPlan['packages'][0];
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    setRefreshList: () => void;
    setActive: () => void;
}> = ({ packageValue, setRefreshList, setActive }) => {
    const [addVisible, setAddVisible] = useState(false);
    const [value, setValue] = useState(packageValue.programme_note);
    const [updateAssetsVisible, setUpdateAssetsVisible] = useState(false);

    const handleSubmit = useCallback(() => {
        if (value !== packageValue.programme_note) {
            updateAssetAllocPlanPkg({
                pkg_id: packageValue.pkg_id,
                programme_note: value,
            }).then(() => {
                message.success('修改成功');
                setRefreshList();
            }, showError);
        }
    }, [packageValue.pkg_id, packageValue.programme_note, setRefreshList, value]);
    const handleAddClick: React.MouseEventHandler<HTMLElement> = useCallback((event) => {
        event.stopPropagation();
        setAddVisible(true);
    }, []);
    const handleUpdateAsset: React.MouseEventHandler<HTMLElement> = useCallback((event) => {
        event.stopPropagation();
        setUpdateAssetsVisible(true);
    }, []);

    return (
        <div
            style={{ width: '100%' }}
            onKeyPress={stopPropagation}
            onKeyUp={stopPropagation}
            onKeyDown={stopPropagation}
        >
            <div className="header-wrapper">
                <div onClick={stopPropagation}>
                    <UpdateAssetModal
                        pkg_id={packageValue.pkg_id}
                        visible={updateAssetsVisible}
                        setVisible={setUpdateAssetsVisible}
                        handleRefreshList={setRefreshList}
                    />
                    <Modal
                        title={`添加产品`}
                        destroyOnClose
                        footer={null}
                        visible={addVisible}
                        okText="确认"
                        cancelText="取消"
                        onCancel={() => setAddVisible(false)}
                        centered
                        width={700}
                    >
                        <AddAsset
                            pkg_id={packageValue.pkg_id}
                            setActive={setActive}
                            handleRefreshList={setRefreshList}
                            setVisible={setAddVisible}
                        />
                    </Modal>
                </div>
                <span className="title">
                    <span
                        style={{
                            display: 'inline-block',
                            color: '#FFD330',
                            width: 250,
                        }}
                    >
                        {packageValue.name}
                    </span>
                    <span className="subtitle">净值：{packageValue.net_asset}</span>
                    <span className="subtitle">总值：{packageValue.total_asset}</span>
                    <span className="subtitle">负债：{packageValue.debit_amount}</span>
                </span>
                <Button className="header-button" onClick={handleUpdateAsset}>
                    资金变动
                </Button>
                <Button className="header-button" onClick={handleAddClick}>
                    添加产品
                </Button>
            </div>
            <TextArea
                onClick={stopPropagation}
                autoSize
                value={value}
                onChange={(target) => setValue(target.target.value)}
                onBlur={handleSubmit}
                placeholder="点击输入钱包描述"
                style={{
                    backgroundColor: '#E6E6E6',
                    borderRadius: '12px',
                    marginTop: 20,
                    minHeight: 50,
                }}
            />
        </div>
    );
};

export default PanelHeader;
