import React, { useEffect, useState } from 'react';
import { Form, Modal, Select, Input, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useForm } from 'antd/lib/form/Form';
import {
    queryHistoryLtv,
    ILtv,
    queryUpdateLtv,
    queryChargeMember,
    queryLtvDetail,
} from '@requests/LtvRequest';
import moment from 'moment';

export enum ModalType {
    Edit = 'edit',
    HISTORY = 'history',
}

const columns: ColumnsType<ILtv> = [
    {
        title: 'Available Loan Amount ($Million)',
        dataIndex: 'remaining_loanable_amount',
        width: 254,
        render(value: number) {
            return isNaN(+Number(value).toFixed(3)) ? '0.000' : Number(value).toFixed(3);
        },
    },
    {
        title: 'Update Date',
        dataIndex: 'updated_at',
        width: 127,
        render(value) {
            return moment(value).format('YYYY.MM.DD');
        },
    },
];

interface IProps {
    asset: ILtv;
    show?: boolean;
    type: string;
    onCancel: () => void;
    onConfirm: () => void;
}

function NormalText(props: any) {
    const { value } = props;
    return <div>{value || ''}</div>;
}

function LvtForm(props: IProps) {
    const { asset, show = true, type, onCancel, onConfirm } = props;
    const [tableData, setTableData] = useState<ILtv[]>([]);
    const [chargeOptions, setChargeOptions] = useState([{ label: '', value: NaN }]);
    const [loading, setLoading] = useState(false);

    const isEdit = type === 'edit';
    const [form] = useForm();

    useEffect(() => {
        if (asset && show) {
            queryLtvDetail({ account_id: asset.account_id }).then((res) => {
                form.setFieldsValue({
                    ...res.ltv,
                    remaining_loanable_amount: Number(res.ltv.remaining_loanable_amount).toFixed(3),
                });
            });
            if (isEdit) {
                queryChargeMember({
                    role_type: 4,
                    client_id: asset.client_id,
                }).then((res) => {
                    if (res.members) {
                        setChargeOptions(
                            res.members?.map((item) => ({
                                label: item.name,
                                value: item.id,
                            }))
                        );
                    }
                });
            }
        }
    }, [asset, form, isEdit, show]);

    useEffect(() => {
        if (show && asset && !isEdit) {
            queryHistoryLtv({ account_id: asset.account_id }).then((res) => {
                setTableData(res.ltv_histories);
            });
        }
    }, [asset, isEdit, show]);

    function onOk() {
        setLoading(true);
        if (!loading) {
            form.validateFields()
                .then((res) => {
                    const remaining_loanable_amount = Number(res.remaining_loanable_amount);
                    const person_in_charge = Number(res.person_in_charge);

                    if (
                        remaining_loanable_amount !== asset?.remaining_loanable_amount ||
                        person_in_charge !== asset?.person_in_charge
                    ) {
                        const params = {
                            ...asset,
                            remaining_loanable_amount,
                            person_in_charge,
                        };
                        queryUpdateLtv({ ltv: params })
                            .then(() => {
                                setLoading(false);
                                onConfirm();
                            })
                            .catch((err) => {
                                setLoading(false);
                                onConfirm();
                            });
                    } else {
                        setLoading(false);
                        onConfirm();
                    }
                })
                .catch((err) => {
                    setLoading(false);
                });
        }
    }

    function onClose() {
        setTableData([]);
        setChargeOptions([{ label: '', value: NaN }]);
        onCancel();
    }

    return (
        <div className="ltv-modal-container">
            <Modal
                // forceRender
                visible={show}
                width={660}
                title={type === ModalType.Edit ? 'Edit' : 'History'}
                cancelText="Cancel"
                okText="Save"
                onCancel={onClose}
                onOk={onOk}
                className={`ltv-custom-modal ${
                    type === ModalType.HISTORY && 'ltv-custom-hide-footer'
                } `}
                wrapClassName="ltv-modal"
                confirmLoading={loading}
            >
                <Form
                    form={form}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 12 }}
                    // initialValues={initialData}
                    onFinish={(values) => {}}
                    onValuesChange={(c, a) => {}}
                    className="ltv-form"
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            // width: '200px',
                            margin: 'auto',
                        }}
                    >
                        <Form.Item name={['client', 'name']} label="Client">
                            <NormalText />
                        </Form.Item>
                        <Form.Item name={['account', 'bank_name']} label="Bank">
                            <NormalText />
                        </Form.Item>
                        <Form.Item name={['account', 'vendor_sub_account_id']} label="Account">
                            <NormalText />
                        </Form.Item>
                    </div>
                    {isEdit ? (
                        <>
                            <Form.Item
                                name="remaining_loanable_amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Available Loan Amount is required.',
                                    },
                                ]}
                                label="Available Loan Amount ($Million)"
                            >
                                <Input type="number" style={{ borderRadius: '8px' }} />
                            </Form.Item>
                            <Form.Item
                                name="person_in_charge"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Person in charge is required.',
                                    },
                                ]}
                                label="Person In Charge"
                            >
                                <Select
                                    className="ltv-form-select"
                                    style={{ borderRadius: '8px' }}
                                    options={chargeOptions}
                                />
                            </Form.Item>
                        </>
                    ) : (
                        <>
                            <div
                                className="ltv-history-table-container"
                                style={{ width: '382px', margin: 'auto' }}
                            >
                                <Table
                                    bordered={false}
                                    size="small"
                                    pagination={false}
                                    dataSource={tableData}
                                    columns={columns}
                                    className="ltv-history-table"
                                    rowClassName="ltv-history-table-row"
                                    rowKey={(record) => record.id}
                                />
                            </div>
                        </>
                    )}
                </Form>
            </Modal>
        </div>
    );
}

export default LvtForm;
