import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Modal, Upload } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { UploadChangeParam } from 'antd/lib/upload';
import { showError } from '../../../RecommendationComponent/util';
import { CommonUploadFile, UploadFileType } from '../../../AssetManagement/BankFee/requests';
type UploadModalProp = {
    refresh: () => void;
    termsheets: any[];
    addVisible: boolean;
    setAddvisible: (addVisible: boolean) => void;
    viewVisible: boolean;
    setViewvisible: (addVisible: boolean) => void;
    uploadRequest: (item: { file_encode: string }) => Promise<any>;
    deleteApi: (item: any) => Promise<any>;
};
const UploadModal = ({
    refresh,
    setAddvisible,
    addVisible,
    uploadRequest,
    viewVisible,
    setViewvisible,
    termsheets,
    deleteApi,
}: UploadModalProp) => {
    const [pdfStr, setPdfStr] = useState<{ url: string; name: string }[]>([]);
    const [sheetsData, setDheetsData] = useState(termsheets);
    const [pdfUploading, setPdfUploading] = useState(false);
    const parsePDF = useCallback((props: UploadRequestOption) => {
        const reader = new FileReader();
        reader.onload = () => {
            const data = reader.result as string;
            props.onSuccess?.(data);
        };
        reader.onerror = () => {
            props.onError?.(new Error('读取文件失败'));
        };
        if (typeof props.file !== 'string') {
            reader.readAsDataURL(props.file);
        }
    }, []);
    useEffect(() => {
        setDheetsData(termsheets);
    }, [termsheets]);
    const uploadChange = useCallback((info: UploadChangeParam) => {
        let array = info.fileList.map((item) => {
            return { name: item.name ?? '', url: item.response };
        });
        setPdfStr(array);
    }, []);
    const deleteReceipt = useCallback(
        (item) => {
            Modal.confirm({
                title: `确认将${item.file_name}删除?`,
                onOk: () => {
                    deleteApi(item).then(() => {
                        setDheetsData((data) => {
                            return data.filter((sheetItem) => item.id !== sheetItem.id);
                        });
                        refresh();
                        message.success('删除成功');
                    }, showError);
                },
            });
        },
        [deleteApi, refresh]
    );
    const handleUploadPdf = useCallback(() => {
        if (pdfStr.length > 0) {
            let fileList: string[] = [];
            setPdfUploading(true);
            let promiseArray = pdfStr.map((item) =>
                CommonUploadFile({
                    data_url: item.url,
                    target_type: UploadFileType.Receipt,
                    file_name: item.name,
                }).then((res) => {
                    fileList.push(res.data.url);
                })
            );
            let uploadPromiseArray: Promise<any>[];
            Promise.all(promiseArray)
                .then(() => {
                    uploadPromiseArray = fileList.map((item) =>
                        uploadRequest({ file_encode: item })
                    );
                    Promise.all(uploadPromiseArray)
                        .then(() => {
                            message.success('上传成功');
                            setAddvisible(false);
                            setPdfStr([]);
                            refresh();
                        }, showError)
                        .finally(() => setPdfUploading(false));
                })
                .catch((err) => {
                    message.error(err);
                    setPdfUploading(false);
                });
        } else {
            message.error('请先选择文件');
        }
    }, [pdfStr, refresh, setAddvisible, uploadRequest]);
    return (
        <>
            <Modal
                title="上传银行回执单"
                destroyOnClose
                visible={addVisible}
                centered
                onCancel={() => {
                    setAddvisible(false);
                    setPdfStr([]);
                }}
                onOk={handleUploadPdf}
                confirmLoading={pdfUploading}
                cancelText="取消"
                okText="上传"
            >
                <Upload
                    // accept=".pdf"
                    onChange={uploadChange}
                    multiple
                    maxCount={10}
                    customRequest={parsePDF}
                >
                    <Button icon={<UploadOutlined />}>点击上传</Button>
                </Upload>
            </Modal>
            <Modal
                title="查看文件"
                visible={viewVisible}
                footer={null}
                onCancel={() => {
                    setViewvisible(false);
                }}
            >
                {sheetsData.map((item, index) => (
                    <div style={{ display: 'flex', marginTop: '20px' }} key={index}>
                        <Button
                            type="link"
                            style={{ textAlign: 'left', flex: '1', color: 'blue' }}
                            onClick={() => {
                                window.open(item.file_url);
                            }}
                        >
                            {item.file_name}
                        </Button>
                        <Button
                            type="primary"
                            danger
                            onClick={() => {
                                deleteReceipt(item);
                            }}
                        >
                            删除
                        </Button>
                    </div>
                ))}
            </Modal>
        </>
    );
};

export default UploadModal;
