import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { message, Tabs } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { MobileStateContext } from '../../App';
import {
    getAuthoritiesWithRoles,
    updateAuthorityForRole,
    updateAuthorityItemForRole,
} from '../../service';
import useForceUpdate from '../../utils/Hooks/useForceUpdate';
import AuthorityAddRoleControl from './AuthorityAddRole';
import { AuthorityTableRow } from './AuthorityTree';
import { Authority, AuthorityOperation } from './AuthorityTree/requests';
import './AuthorityMemberCenter.less';
import { StrToNumObj } from './AuthorityMemberControl';
interface AuthorityData {
    roles: {
        id: number;
        name: string;
        authoritiyIds: number[] | null;
        operationIds: number[] | null;
    }[];
    authorities: Authority[];
}

export default function AuthorityRoleSettings() {
    const { isMobile } = useContext(MobileStateContext);
    const [tabKey, setTabKey] = useState(0);
    const [authorityData, setAuthorityData] = useState<AuthorityData>();
    const [rowData, setRowData] = useState<AuthorityTableRow[]>([]);
    useEffect(() => {
        if (authorityData) {
            const newData: AuthorityTableRow[] = [];
            for (let authority of authorityData.authorities) {
                newData.push({
                    id: authority.id,
                    name: authority.name,
                    entrance: authority.entrance,
                    parentId: authority.parentId,
                    children: authority.children ?? [],
                    hierarchy: [authority.name],
                    operations: authority.operations,
                });
                for (let authChild of authority.children ?? []) {
                    newData.push({
                        id: authChild.id,
                        name: authChild.name,
                        entrance: authChild.entrance,
                        parentId: authChild.parentId,
                        children: authority.children ?? [],
                        hierarchy: [authority.name, authChild.name],
                        operations: authChild.operations,
                    });
                }
            }

            setRowData(newData);
        }
    }, [authorityData]);
    const forceUpdate = useForceUpdate(() => {
        getAuthoritiesWithRoles()
            .then((res) => {
                let data = res.data as AuthorityData;
                if (data) {
                    setAuthorityData(data);
                } else {
                    message.error('获取权限列表失败');
                }
            })
            .catch(() => {
                message.error('获取权限列表失败');
            });
    });

    const curRole = useMemo(() => {
        if (authorityData) {
            return authorityData.roles[tabKey];
        }
    }, [authorityData, tabKey]);
    const handleAuthorityItemChange = useCallback(
        (operationId: number) => {
            if (curRole) {
                updateAuthorityItemForRole({ roleId: curRole.id, operationId: operationId })
                    .then(() => {
                        forceUpdate();
                        message.destroy();
                        message.success('权限设置成功');
                    })
                    .catch(() => {
                        message.error('权限设置失败');
                    });
            }
        },
        [curRole, forceUpdate]
    );
    const handleAuthorityChange = useCallback(
        (authorityId: number) => {
            if (curRole) {
                updateAuthorityForRole({
                    roleId: curRole.id,
                    authorityId,
                })
                    .then(() => {
                        forceUpdate();
                        message.destroy();
                        message.success('权限设置成功');
                    })
                    .catch(() => {
                        message.error('权限设置失败');
                    });
            }
        },
        [curRole, forceUpdate]
    );
    const columnDefs = useMemo<ColDef[]>(
        () => [
            {
                flex: 1,
                headerName: '操作项权限',
                autoHeight: true,
                field: 'operations',
                cellRenderer: (params: ICellRendererParams) => {
                    const value = params.value as AuthorityOperation[];
                    if (value.length > 0) {
                        return value.map((item, index) => {
                            return (
                                <Checkbox
                                    key={index}
                                    checked={curRole?.operationIds?.includes(item.id)}
                                    onChange={() => handleAuthorityItemChange(item.id)}
                                    style={{ marginLeft: 0, marginRight: '8px' }}
                                >
                                    {item.name}
                                </Checkbox>
                            );
                        });
                    }
                    return null;
                },
            },
        ],
        [curRole, handleAuthorityItemChange]
    );

    const autoGroupColumnDef = useMemo<ColDef>(
        () => ({
            headerName: '菜单权限',
            width: 320,
            cellRendererParams: {
                innerRenderer: (params: ICellRendererParams) => {
                    const value = params.data as AuthorityTableRow;
                    if (value) {
                        if (value.children.length === 0 || value.name === value.hierarchy[1]) {
                            return (
                                <Checkbox
                                    checked={curRole?.authoritiyIds?.includes(value.id)}
                                    onChange={() => handleAuthorityChange(value.id)}
                                    style={{ marginLeft: 0, marginRight: '8px' }}
                                >
                                    {value.name}
                                </Checkbox>
                            );
                        } else {
                            return value.name;
                        }
                    }
                    return null;
                },
            },
        }),
        [curRole, handleAuthorityChange]
    );
    const roles = useMemo(() => {
        let roles: StrToNumObj = {};
        authorityData?.roles.forEach((item) => {
            roles[item.name] = item.id;
        });
        return roles;
    }, [authorityData]);
    return (
        <div>
            <Tabs
                defaultActiveKey={tabKey.toString()}
                onChange={(key) => {
                    setTabKey(parseInt(key));
                }}
                style={isMobile ? {} : { display: 'inline-flex', marginRight: '20px' }}
            >
                {authorityData?.roles?.map((role, index) => (
                    <Tabs.TabPane tab={role.name} key={index} />
                ))}
            </Tabs>
            <AuthorityAddRoleControl onSuccess={() => forceUpdate()} roles={roles} />

            <div
                className="ag-theme-alpine"
                style={{
                    padding: isMobile ? '20px 0' : 0,
                    width: '100%',
                }}
            >
                <AgGridReact
                    reactUi
                    animateRows
                    treeData
                    rowData={rowData}
                    columnDefs={columnDefs}
                    domLayout="autoHeight"
                    autoGroupColumnDef={autoGroupColumnDef}
                    getDataPath={(data: AuthorityTableRow) => data.hierarchy}
                />
            </div>
        </div>
    );
}
