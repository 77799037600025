import axios from '../tools';
export type RecordReqType = {
    current_page: number;
    page_size: number;
    product_type: string;
    customer_name?: string;
};

export type TransactionRecord = {
    total_count: number;
    total_pages: number;
    current_page: number;
    page_size: number;
    transaction_details: {
        trade_date: string;
        customer_id: number;
        customer_name: string;
        account_name: string;
        type: string;
        content: string;
        quantity: string;
        cost: string;
        currency: string;
        trans_id: string;
        receipts: {
            id: 0;
            account_id: '';
            trans_id: '';
            matched_order_id: 0;
            order_type: 0;
            file_url: '';
            file_name: '';
            created_at: 0;
        }[];
    }[];
};
export type RecordTypes = {
    product_types: {
        key: string;
        title: string;
        trans_types: [
            {
                key: string;
                value: {};
                title: string;
            }
        ];
    }[];
};
/**
 * 查询交易记录
 * @param data
 * @returns
 */
export const getTransactionRecord = (data: RecordReqType) => {
    return axios.post<TransactionRecord>('/v2/admin/order/transaction', {
        data,
    });
};

export const getRecordType = () => {
    return axios.post<RecordTypes>('/v2/admin/order/all_product_type', {});
};
