import { CustomerDetailCardType } from '../../../modules/CustomerList/components/CustomerDetail/type';
import {
    ALL_TRACK_RECORD_LIST,
    TRACK_RECORD_LIST,
    TRACK_RECORD_TAGS,
    SAVE_PAGE_STATUS,
    SET_ASSET_MODEL,
    SET_ACTIVITY_MODEL,
    SET_CUSTOMER_MODEL,
    SET_POSITION_MODEL,
    CUSTOMER_DETAIL_CARD_REFRESHKEY,
} from '../../constants/constants';
const initCardType = {
    [CustomerDetailCardType.Info]: 0,
    [CustomerDetailCardType.Position]: 0,
    [CustomerDetailCardType.Asset]: 0,
    [CustomerDetailCardType.Activity]: 0,
    [CustomerDetailCardType.Record]: 0,
    [CustomerDetailCardType.TranscationRecord]: 0,
};
function customer(state = { lang: 'zh-CN', card_refresh_key: initCardType }, action: any) {
    const { type } = action;

    if (type === TRACK_RECORD_TAGS) {
        return {
            ...state,
            tags: action.trackRecordTags,
        };
    } else if (type === TRACK_RECORD_LIST) {
        return {
            ...state,
            track_list: action.trackRecordList,
        };
    } else if (type === SAVE_PAGE_STATUS) {
        return {
            ...state,
            pageStatus: action.pageStatus,
        };
    } else if (type === ALL_TRACK_RECORD_LIST) {
        return {
            ...state,
            all_Track_list: action.allTrackRecordList,
        };
    } else if (type === SET_ASSET_MODEL) {
        return {
            ...state,
            assetModel: action.assetModel,
        };
    } else if (type === SET_ACTIVITY_MODEL) {
        return {
            ...state,
            activityModel: action.activityModel,
        };
    } else if (type === SET_POSITION_MODEL) {
        return {
            ...state,
            positionModel: action.positionModel,
        };
    } else if (type === SET_CUSTOMER_MODEL) {
        return {
            ...state,
            customerModel: action.customerModel,
        };
    } else if (type === CUSTOMER_DETAIL_CARD_REFRESHKEY) {
        return {
            ...state,
            card_refresh_key: { ...action.card_refresh_key },
        };
    } else {
        return state;
    }
}

export default customer;
