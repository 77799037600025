import { CopyOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { CellStyle, ColDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { message, Tooltip } from 'antd';
import copy from 'copy-to-clipboard';
import React, { useCallback, useMemo, useState } from 'react';
import './index.less';
import pinyin from 'tiny-pinyin';
import { CustomerManageData } from '../../service/requests/CustomerManage';
import { stopPropagation } from '../RecommendationComponent/util';
import { CustomerData } from './components/CustomerDetail/type';
const colorMap: {
    [key: string]: string;
} = {
    开户中: '#EFA800',
    开户完成: '#EFA800',
    入金完成: '#EFA800',
    活跃用户: '#EFA800',
    销售线索: '#0C6DD9',
    目标用户: '#0C6DD9',
    初步接触: '#0C6DD9',
    持续跟进: '#0C6DD9',
    '其他(非客户)': '#AAAAAA',
    已注销: '#AAAAAA',
};
const dangerMap: {
    [key: string]: string;
} = {
    中风险: '#EFA800',
    高风险: '#ED3B00',
    低风险: '#AAAAAA',
};
let style: CellStyle = {
    paddingLeft: '0',
    paddingRight: '0',
};
let style2: CellStyle = {
    height: '48px',
    lineHeight: '48px',
    width: '100%',
    boxSizing: 'border-box',
    paddingLeft: '10px',
    borderBottom: 'solid 1px #ddd',
};
const HidableCell = (props: { value: string; hideIndexStart: number }) => {
    const [visible, setVisible] = useState(false);
    const valueShown = useMemo(() => {
        if (visible) {
            return props.value;
        } else {
            return props.value?.replace(
                props.value.slice(props.hideIndexStart, props.hideIndexStart + 4),
                '****'
            );
        }
    }, [props, visible]);
    const handleCopy = useCallback((value: string, event: any) => {
        stopPropagation(event);
        if (copy(value)) {
            message.success('复制成功');
        } else {
            message.error('复制失败');
        }
    }, []);

    return props.value ? (
        <div className="cell-hidable">
            <span className="content-value">{valueShown}</span>
            {visible ? (
                <EyeOutlined
                    onClick={(event: any) => {
                        stopPropagation(event);
                        setVisible(false);
                    }}
                />
            ) : (
                <EyeInvisibleOutlined
                    onClick={(event: any) => {
                        stopPropagation(event);
                        setVisible(true);
                    }}
                />
            )}
            <CopyOutlined onClick={(event: any) => handleCopy(props.value, event)} />
        </div>
    ) : null;
};
export { HidableCell };
const defs = (prop: { actions: ColDef }) => {
    const columnDefs: ColDef[] = [
        { headerName: '客户ID', pinned: 'left', field: 'id', width: 80 },
        {
            headerName: '昵称',
            pinned: 'left',
            field: 'name',
            width: 80,
            cellRenderer: (params: ICellRendererParams) => {
                if (/[a-zA-Z]/.test(params.value[0])) {
                    return params.value.length > 3 ? params.value.slice(0, 3) + '*' : params.value;
                } else if (pinyin.isSupported()) {
                    const pinyinStr = params.value
                        .slice(1, params.value.length)
                        .split('')
                        .map((char: string) => pinyin.convertToPinyin(char, '', true))
                        .join('');

                    return '*' + pinyinStr;
                } else {
                    return params.value;
                }
            },
        },
        {
            headerName: '状态',
            field: 'state',
            cellRenderer: (params: ICellRendererParams) => (
                <div style={{ color: `${colorMap[params.value]}` }}>{params.value}</div>
            ),
        },
        {
            headerName: 'App昵称',
            field: 'nickName',

            width: 130,
        },
        {
            headerName: 'App备注',
            field: 'remarkName',
            width: 130,
        },

        {
            headerName: '手机号',
            field: 'mobile',
            width: 210,
            cellRenderer: (params: ICellRendererParams) => (
                <HidableCell value={params.value} hideIndexStart={6} />
            ),
        },
        {
            headerName: '账号角色',
            width: 90,
            valueGetter: (params: ValueGetterParams) => {
                let data = params.data as CustomerManageData['pageData'][number];
                if (data.relativeCustomerId) {
                    return '主账号';
                }
                if (data.parentCustomerId) {
                    return '副账号';
                }
                return '';
            },
        },
        {
            headerName: '关联账号',
            valueGetter: (params: ValueGetterParams) => {
                let data = params.data as CustomerManageData['pageData'][number];
                if (data.relativeCustomerId) {
                    return data.relativeMobile;
                }
                if (data.parentCustomerId) {
                    return data.parentMobile;
                }
                return '';
            },
            width: 160,
        },
        {
            headerName: 'RM',
            valueGetter: (params: ValueGetterParams) => {
                let data = params.data as CustomerManageData['pageData'][number];
                let array = data.rms.map((rm) => rm.name);
                return Array.from(array).join(',');
            },
            cellRenderer: (params: ICellRendererParams) => (
                <Tooltip title={params.value}>
                    <span>{params.value}</span>
                </Tooltip>
            ),
        },
        {
            headerName: 'IC',
            valueGetter: (params: ValueGetterParams) => {
                let data = params.data as CustomerManageData['pageData'][number];
                let icarray = data.ics.map((ic) => ic.name);
                return Array.from(icarray).join(',');
            },
            cellRenderer: (params: ICellRendererParams) => (
                <Tooltip title={params.value}>
                    <span>{params.value}</span>
                </Tooltip>
            ),
        },
        {
            headerName: '银行账户',
            width: 150,
            cellStyle: () => style,
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <div style={{ height: '100%' }}>
                        {params.data.bankAccounts?.map((item: any, index: number) => (
                            <div style={style2} key={index}>
                                {item.bankName +
                                    (item.vendorAccountId ? `-${item.vendorAccountId}` : '')}
                            </div>
                        ))}
                    </div>
                );
            },
        },
        {
            headerName: '交易员',
            width: 100,
            cellStyle: () => style,
            cellRenderer: (params: ICellRendererParams) => {
                return (
                    <div style={{ height: '100%' }}>
                        {params.data.bankAccounts?.map((item: any) => (
                            <div style={style2}>
                                <Tooltip
                                    title={item.traders
                                        ?.map(
                                            (
                                                trader: CustomerData['bankAccounts'][0]['traders'][0]
                                            ) => {
                                                return trader.name;
                                            }
                                        )
                                        .join(',')}
                                >
                                    <span>
                                        {item.traders
                                            ?.map(
                                                (
                                                    trader: CustomerData['bankAccounts'][0]['traders'][0]
                                                ) => {
                                                    return trader.name;
                                                }
                                            )
                                            .join(',')}
                                    </span>
                                </Tooltip>
                            </div>
                        ))}
                    </div>
                );
            },
        },

        {
            headerName: '工作经历',
            field: 'work_history',
            minWidth: 80,
            cellRenderer: (params: ICellRendererParams) => (
                <Tooltip title={params.value}>
                    <span>{params.value}</span>
                </Tooltip>
            ),
        },

        {
            headerName: '安全垫',
            field: 'risk_rate',
            cellRenderer: (params: ICellRendererParams) => (
                <div
                    style={{
                        color: `${Number.parseInt(params.value) ?? 0 > 15 ? '' : '#ED3B00'}`,
                    }}
                >
                    {params.value}
                </div>
            ),
        },
        {
            headerName: '集中度',
            field: 'risk_concentration',
            cellRenderer: (params: ICellRendererParams) => (
                <div style={{ color: `${dangerMap[params.value]}` }}>{params.value}</div>
            ),
        },
        { headerName: '最近app登录时间', field: 'last_login_in_time', width: 185 },
        {
            headerName: '邮箱',
            field: 'email',
            width: 228,
            cellRenderer: (params: ICellRendererParams) => (
                <HidableCell value={params.value} hideIndexStart={3} />
            ),
        },

        { headerName: '来源', field: 'source', width: 96 },
        { headerName: '生日', field: 'birthday', width: 120 },
        {
            headerName: '地址',
            field: 'address',
            width: 144,
            cellRenderer: (params: ICellRendererParams) => (
                <Tooltip title={params.value}>
                    <span>{params.value}</span>
                </Tooltip>
            ),
        },
        { headerName: '性别', field: 'gender' },
        prop.actions,
    ];
    return columnDefs;
};

export default defs;
