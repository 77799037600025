import { useState, useEffect, useReducer, ReactNode, useCallback } from 'react';
import { AxiosPromise } from 'axios';
import { message } from 'antd';
import { error, errorModalType } from '../logger';
import usePrevious from './useProvider';
import { MessageApi } from 'antd/lib/message';

type initFuncType = <T>(value: T) => T;
type IParamsObj = Record<string, any>;
type IFetchParams = any;

type InterfaceFuncType = (params: IFetchParams) => AxiosPromise;
interface IRequest<T extends InterfaceFuncType> {
    interfaces: T;
    initialParams: returnParams<T>;
    initialData?: AxiosReturnType<T>;
    notFetch?: (params: returnParams<T>) => boolean;
}

type returnIRequestType<T extends InterfaceFuncType> = AxiosReturnType<T>;

export type AxiosReturnType<T> = T extends (...args: any[]) => AxiosPromise<infer R> ? R : any;

export type returnParams<T> = T extends (p: infer M) => any ? M : never;
// type xxxx<U, T> = t extends InterfaceFuncType
//   ? AxiosReturnType<t>
//   ? [AxiosReturnType<t>, AxiosReturnType<u>]
//   : number;
interface IOptions<T extends InterfaceFuncType> {
    controlFresh?: boolean;
    interceptParam?: (params: returnParams<T>) => returnParams<T>;
    onSuccess?: (data: returnIRequestType<T>) => any;
    dataProcessFunc?: (data: returnIRequestType<T>) => any;
    onError?: (msg: ReactNode) => any;
    onRequestStart?: () => any;
    onRequestFinish?: () => any;
    setLoading?: (loading: boolean) => any;
}

interface IFetch<T extends InterfaceFuncType> {
    data?: returnIRequestType<T>;
    isLoading?: boolean;
    isError?: boolean;
}
type useFetchFuncType = <T extends InterfaceFuncType>(
    requestConfig: IRequest<T>,
    options?: IOptions<T>
) => [IFetch<T>, React.Dispatch<React.SetStateAction<returnParams<T>>>, (value?: any) => any];

type errorMessageType = MessageApi['error'];

type errorUnionType = errorModalType | errorMessageType;

type reducerType = <T extends InterfaceFuncType>(
    preState: IFetch<T>,
    currentState: IFetch<T>
) => IFetch<T>;

const mainReducer: reducerType = (preState, currentState) => ({ ...preState, ...currentState });
message.config({
    top: 100,
    duration: 1.5,
});
const initFunc: initFuncType = (_) => _;
const useFetch: useFetchFuncType = (
    { interfaces, initialParams, initialData, notFetch = () => {} },
    options = {}
) => {
    const [params, setParams] = useState(initialParams);
    const [fresh, setFresh] = useState(false);
    const [fetchState, setFetchState] = useReducer(mainReducer, {
        data: initialData,
        isLoading: false,
        isError: false,
    });
    const assignOptions = Object.assign(
        {},
        {
            controlFresh: false,
            dataProcessFunc: initFunc,
            interceptParam: initFunc,
            onSuccess: initFunc,
            onError: initFunc,
            onRequestFinish: initFunc,
            onRequestStart: initFunc,
            setLoading: initFunc,
            errorType: 'message',
        },
        options
    );
    const setFreshData = useCallback(() => {
        setFresh((fresh) => !fresh);
    }, []);
    const {
        onSuccess,
        controlFresh,
        interceptParam,
        onRequestFinish,
        onRequestStart,
        dataProcessFunc,
        setLoading,
        onError,
        errorType,
    } = assignOptions;
    const errFunc: errorUnionType = errorType === 'modal' ? error : message.error;
    useEffect(() => {
        const fetchData = async () => {
            onRequestStart();
            setLoading(true);
            setFetchState({ isError: false, isLoading: true });
            try {
                const completeParam = interceptParam(params);
                const result = Array.isArray(interfaces)
                    ? await Promise.all(
                          interfaces.map((interfaceItem) => interfaceItem(completeParam))
                      )
                    : await interfaces(completeParam);
                if (Array.isArray(result)) {
                    if (result.every((ele) => ele.data.status === 0 || ele.data.code === 0)) {
                        const data = result.map((ele) => ele.data.data);
                        setFetchState({ data: dataProcessFunc(data) });
                        onSuccess(data);
                    } else {
                        const messages = result.map((ele) => ele.data.message);
                        messages.forEach((ele) => {
                            if (ele) {
                                const errMsg = ele;

                                errFunc(errMsg);
                            }
                        });
                        onError(messages);
                    }
                } else {
                    const { data, code, msg } = result as any;
                    if (code === 0) {
                        setFetchState({ data: dataProcessFunc(data) });
                        onSuccess(result.data);
                    } else {
                        errFunc(msg);
                        console.log('err', msg);
                        onError(msg);
                    }
                }
            } catch (error) {
                const { msg } = error as any;
                errFunc(msg);
                onError(msg);
                setFetchState({ isError: true });
            } finally {
                onRequestFinish();
                setLoading(false);
                setFetchState({ isLoading: false });
            }
        };
        if (fresh !== previousFresh) {
            !notFetch(params) && fetchData();
            return;
        }
        if (controlFresh) {
            if (JSON.stringify(previousParams) !== JSON.stringify(params)) {
                !notFetch(params) && fetchData();
            }
        } else {
            !notFetch(params) && fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, fresh]);
    const previousParams = usePrevious(params, {} as any);
    const previousFresh = usePrevious(fresh, false);
    return [fetchState, setParams, setFreshData];
};
export default useFetch;
