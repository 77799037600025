import { Divider, Pagination, Spin, Table } from 'antd';
import React, { useMemo } from 'react';
import styles from './index.module.scss';
import { useRequest } from 'ahooks';
import { requestList } from './request';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import ReconciliationHeader from './component/ReconciliationHeader';
import { VerifiedStatus, ReconciliationRecord } from './type';
import { AccountManageType } from '../Transaction/type';
import usePageState from '../../utils/Hooks/usePageState';
import { FormatNumber } from '../../utils';

const Reconciliation = () => {
    const history = useHistory();
    const { dispacth, selector } = usePageState();
    const [currentPage, setCurrentPage] = React.useState(selector?.page ?? 1);
    const [pageSize, setPageSize] = React.useState(selector?.page_size ?? 20);

    const { data, loading, run, params } = useRequest(requestList, {
        defaultParams: [
            {
                current_page: currentPage,
                page_size: pageSize,
                account_id: selector?.account_id,
                account_manage_type: selector?.account_manage_type ?? AccountManageType.Manage,
                status: selector?.verify_status ?? [VerifiedStatus.Unverified],
            },
        ],
    });
    const columns = useMemo<ColumnsType<ReconciliationRecord>>(() => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                width: 80,
            },
            {
                title: 'Account',
                dataIndex: 'account',
                width: 145,
                render(value, record, index) {
                    return `${record.account.bank_name}-${record.account.vendor_sub_account_id}`;
                },
            },
            {
                title: 'Asset Type',
                width: 145,
                render(value, record, index) {
                    return record.asset_type?.desc ?? '';
                },
            },
            {
                title: 'Asset',
                dataIndex: 'asset_name',
                width: 235,
            },
            {
                title: 'Statement Date',
                dataIndex: 'statement_date',
                width: 175,
                render(value) {
                    return moment(Number.parseInt(value)).format('YYYY-MM-DD');
                },
            },
            {
                title: 'Bank Position Change',
                dataIndex: 'bank_position_change',
                width: 160,
                render(value, record) {
                    let isCheck =
                        record.bank_position_change === record.bank_verify_amount &&
                        record.bank_position_change === record.system_verify_amount;
                    return (
                        <div
                            style={{
                                color: isCheck ? 'rgba(0,0,0,0.85)' : 'red',
                            }}
                        >
                            {FormatNumber(value, 2)}
                        </div>
                    );
                },
            },
            {
                title: 'Bank Flow Verify Amount',
                dataIndex: 'bank_verify_amount',
                width: 160,
                render(value, record) {
                    let isCheck =
                        record.bank_position_change === record.bank_verify_amount &&
                        record.bank_position_change === record.system_verify_amount;
                    return (
                        <div
                            style={{
                                color: isCheck ? 'rgba(0,0,0,0.85)' : 'red',
                            }}
                        >
                            {FormatNumber(value, 2)}
                        </div>
                    );
                },
            },
            {
                title: 'System Verified Amount',
                dataIndex: 'system_verify_amount',
                width: 160,
                render(value, record) {
                    let isCheck =
                        record.bank_position_change === record.bank_verify_amount &&
                        record.bank_position_change === record.system_verify_amount;
                    return (
                        <div
                            style={{
                                color: isCheck ? 'rgba(0,0,0,0.85)' : 'red',
                            }}
                        >
                            {FormatNumber(value, 2)}
                        </div>
                    );
                },
            },
            {
                title: 'status',
                dataIndex: 'status',
                width: 80,
                render(value) {
                    return value === 2 ? 'Verified' : 'Unverified';
                },
            },
        ];
    }, []);
    return (
        <Spin spinning={loading}>
            <div className={styles.container}>
                <ReconciliationHeader
                    initValues={{
                        account_id: selector?.account_id,
                        account_manage_type:
                            selector?.account_manage_type ?? AccountManageType.Manage,
                        status: selector?.verify_status ?? [VerifiedStatus.Unverified],
                        account_number: selector?.account_name,
                    }}
                    onChange={(account_manage_type, status, account_id, account_name) => {
                        setCurrentPage(1);
                        dispacth({
                            page: 1,
                            account_manage_type,
                            verify_status: status,
                            account_id,
                            account_name,
                        });
                        run({
                            ...params[0]!,
                            account_id: account_id,
                            current_page: 1,
                            account_manage_type,
                            status,
                        });
                    }}
                />
                <Divider />
                <Table
                    className={styles.table}
                    size="small"
                    id="reconciliation-table"
                    columns={columns}
                    dataSource={data?.data.reconciliations}
                    rowKey={(record) => record.id!}
                    pagination={false}
                    onRow={(record) => {
                        return {
                            onClick() {
                                history.push(`/app/reconciliation/list/detail?id=${record.id}`);
                            },
                        };
                    }}
                />
                <Pagination
                    className={styles.page}
                    total={data?.data.total_count}
                    showTotal={(total, range) => {
                        return `总条数：${total}`;
                    }}
                    pageSize={pageSize}
                    onShowSizeChange={(current, size) => {
                        setPageSize(size);
                        dispacth({ page_size: size });
                    }}
                    onChange={(page, pageSize) => {
                        setCurrentPage(page);
                        dispacth({ page, page_size: pageSize });
                        setPageSize(pageSize || 20);
                        run({
                            ...params[0]!,
                            current_page: page,
                            page_size: pageSize,
                        });
                    }}
                    showSizeChanger
                    current={params[0]?.current_page}
                />
            </div>
        </Spin>
    );
};
export default Reconciliation;
