import SingleCustomerQuote from './CustomerQuotationPlatform';
import MultiCustomerQuote from './MultiCustomerQuote';
import HistoricalTransactions from './HistoricalTransactions';

const StructuralProductsPages = {
    '/app/structuralProducts/customerQuotationPlatform': SingleCustomerQuote,
    '/app/structuralProducts/multiCustomerQuote': MultiCustomerQuote,
    '/app/structuralProducts/historicalTransactions/:productType': HistoricalTransactions,
};

export default StructuralProductsPages;
