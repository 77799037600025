import React, { useState, useEffect, useCallback } from 'react';
import { message, Modal, Table } from 'antd';
import { queryEditEntryType } from '../../../../../../service/type';
import { getProductQuoteUrl, getQuotePreview } from '../../../../../../service';
import { showError } from '../../../../../RecommendationComponent/util';
import styles from './index.module.scss';
import { EventList, uploadEventTrack } from '../../../../../../service/requests/EventTrack';

const ViewModal: React.FC<{
    setVisible: (boolean: boolean) => void;
    visible: boolean;
    product: queryEditEntryType['entries'][0] | null;
}> = ({ setVisible, visible, product }) => {
    const [quotesTable, setQuotesTable] = useState<null | {
        columns: Array<{
            title: string;
            dataIndex: string;
        }>;
        dataBase: Array<Record<any, string>>;
    }>(null);
    useEffect(() => {
        setQuotesTable({
            columns: [],
            dataBase: [],
        });
        if (product?.product_type && product.edit_entry_url && visible) {
            getQuotePreview({
                edit_entry_url: product.edit_entry_url,
                product_type: product?.product_type,
            }).then((data) => {
                const quotes = data?.data?.quotes;
                if (quotes) {
                    const columns: Array<{
                        title: string;
                        dataIndex: string;
                    }> = [];
                    const dataBase: Array<Record<any, string>> = [];
                    quotes.titles.forEach((item, index) => {
                        columns.push({
                            title: item,
                            dataIndex: index.toString(),
                        });
                    });
                    quotes.cells.forEach((laneCell, columnIndex) => {
                        dataBase[columnIndex] = {};
                        dataBase[columnIndex]['key'] = columnIndex.toString();
                        laneCell.forEach((cell, laneIndex) => {
                            dataBase[columnIndex][laneIndex] = cell.value;
                        });
                    });
                    setQuotesTable({
                        columns,
                        dataBase,
                    });
                }
            }, showError);
        }
    }, [product, visible]);
    const handleCreate = useCallback(() => {
        if (!quotesTable || quotesTable.dataBase.length === 0) {
            return message.error('请先选中报价');
        }
        if (product) {
            uploadEventTrack(EventList.SingleQuoteGenQuoteUrl, 2);
            getProductQuoteUrl({
                customer_id: product.customer_id.toString(),
                edit_entry_url: product.edit_entry_url,
                product_type: product.product_type,
            }).then((data) => {
                window.open(
                    `${url[process.env.REACT_ADMIN_ENV ?? 'production']}/${product.customer_id}/${
                        data.data.product_quote_sheet_id
                    }`
                );
                setVisible(false);
            }, showError);
        }
    }, [product, quotesTable, setVisible]);
    return (
        <>
            <Modal
                title={`报价列表预览`}
                destroyOnClose
                footer={null}
                visible={visible}
                okText="确认"
                cancelText="取消"
                onCancel={() => setVisible(false)}
                centered
                width="calc(100% - 20px)"
                style={{
                    maxWidth: 1400,
                }}
            >
                {quotesTable ? (
                    <>
                        <div className={styles['table-wrapper']}>
                            <Table
                                columns={quotesTable.columns}
                                dataSource={quotesTable.dataBase}
                                pagination={false}
                                rowKey="key"
                                scroll={{ x: true }}
                            />
                        </div>
                        <div className={styles.options}>
                            <button
                                className={styles.return}
                                onClick={() => {
                                    setVisible(false);
                                }}
                            >
                                返回编辑
                            </button>
                            <button className={styles.create} onClick={handleCreate}>
                                生成报价页
                            </button>
                        </div>
                    </>
                ) : null}
            </Modal>
        </>
    );
};

export default ViewModal;

const url = {
    dev: 'https://portal-test.ilookcap.com/order/options',
    stage: 'https://portal-stage.ilookcap.com/order/options',
    production: 'https://portal.ilookcap.com/order/options',
};
