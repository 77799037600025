import { createStore, applyMiddleware } from 'redux';
import reducer from './reducers/reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { RecordListRes, TrackTagsType } from '../modules/CustomerList/components/Record/request';
import { UserPersonalInfo } from '../service/requests/PersonalRequest';
import { CustomerDetailCardRefreshKeyType, RoutePageState } from './type';
import {
    CustomerActivityData,
    CustomerAssetModel,
    CustomerPositionData,
} from '../service/requests/CustomerManage';
import { CustomerData } from '../modules/CustomerList/components/CustomerDetail/type';
export type CustomerState = {
    curIndex: number | undefined;
    showDetail: boolean;
    curPage: number;
    filterField: string;
};
export interface LKRootState {
    customer: {
        tags: TrackTagsType;
        track_list: RecordListRes;
        all_Track_list: RecordListRes;
        pageStatus: CustomerState;
        assetModel: CustomerAssetModel;
        activityModel: CustomerActivityData['customer_dynamics'];
        positionModel: CustomerPositionData;
        customerModel: CustomerData;
        card_refresh_key: CustomerDetailCardRefreshKeyType;
    };
    login: UserPersonalInfo;
    pageState: RoutePageState;
    config: {
        syncPageIndex: string;
    };
    position: {
        state: {
            index: string;
            refresh: number;
        };
        tabIndex: string;
    };
}

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
