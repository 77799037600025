import { HostType } from '../../../service/config';
import { multiHostAxios } from '../../../service/tools';

const axios = multiHostAxios(HostType.NOTIFY);

export const getMsgTemplateList = () => {
    return axios.post<MsgTemplate[]>('/v1/admin/notify/Template/list');
};

export const createMsgTemplate = (data: {
    channel: string;
    content: string;
    title?: string;
    eventTypeId: number;
}) => {
    return axios.post('/v1/admin/notify/Template/create', { data });
};

export const updateMsgTemplate = (data: {
    id: number;
    channel: string;
    content: string;
    title?: string;
    eventTypeId: number;
}) => {
    return axios.post('/v1/admin/notify/Template/update', { data });
};
