import style from './index.module.scss';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { CustomerData, CustomerDetailCardProps } from '../CustomerDetail/type';
import {
    baseKeyLeftArray,
    baseKeyRightArray,
    businessKeyLeftArray,
    businessKeyRightArray,
} from '../ConstValue';
import { Button, message, Modal, Result, Upload } from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import {
    deleteSignedFile,
    singedUploadPdf,
} from '../../../StructuralProducts/HistoricalTransactions/requests';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { QuertBaseInfo } from '../../../../service/requests/CustomerManage';
import { showError as showErrorToast } from '../../../RecommendationComponent/util';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerModel } from '../../../../store/actions/actions';
import { LKRootState } from '../../../../store/store';
export interface CustomerInfoRef {
    showUploadModal: () => void;
}
interface CusomterInfoCardProp extends CustomerDetailCardProps {
    searchChild: (id: number) => void;
    banksInfo: CustomerData['bankAccounts'];
}

const CustomerInfo = forwardRef<CustomerInfoRef, CusomterInfoCardProp>(
    ({ customer_id, searchChild, banksInfo }: CusomterInfoCardProp, onRef) => {
        useImperativeHandle(
            onRef,
            () => ({
                showUploadModal: () => {
                    setAddVisible(true);
                },
            }),
            []
        );
        const detailCardRefresh = useSelector(
            (state: LKRootState) => state.customer.card_refresh_key.info
        );
        const [customerData, setCusotmerData] = useState<CustomerData>();
        const [addVisible, setAddVisible] = useState(false);
        const [showError, setShowError] = useState(false);
        const [pdfStr, setPdfStr] = useState<{ url: string; name: string }[]>([]);
        const [pdfUploading, setPdfUploading] = useState(false);
        const dispatch = useDispatch();
        const parsePDF = useCallback((props: UploadRequestOption) => {
            const reader = new FileReader();
            reader.onload = () => {
                const data = reader.result as string;
                props.onSuccess?.(data);
            };
            reader.onerror = () => {
                props.onError?.(new Error('读取文件失败'));
            };
            if (typeof props.file !== 'string') {
                reader.readAsDataURL(props.file);
            }
        }, []);

        const uploadChange = useCallback((info: UploadChangeParam) => {
            let array = info.fileList.map((item) => {
                return { name: item.name ?? '', url: item.response };
            });
            setPdfStr(array);
        }, []);
        const request = useCallback(() => {
            QuertBaseInfo({ customer_id: customer_id ?? 0 })
                .then((e) => {
                    setCusotmerData(e.data.customer_detail);
                    dispatch(setCustomerModel(e.data.customer_detail));
                    setShowError(false);
                })
                .catch((e) => {
                    setShowError(true);
                });
        }, [customer_id, dispatch]);
        useEffect(() => {
            if (detailCardRefresh > 0) {
                request();
            }
        }, [detailCardRefresh, request]);
        useEffect(() => {
            request();
        }, [customer_id, request]);
        const deleteReceipt = useCallback(
            (item) => {
                Modal.confirm({
                    title: `确认将${item.file_name}删除?`,
                    onOk: () => {
                        deleteSignedFile({ id: item.id }).then((res) => {
                            request();
                            message.success('删除成功');
                        }, showErrorToast);
                    },
                });
            },
            [request]
        );
        const handleUploadPdf = useCallback(() => {
            if (customerData && pdfStr.length > 0) {
                setPdfUploading(true);
                let promiseArray = pdfStr.map((item) =>
                    singedUploadPdf({
                        file_encode: item.url,
                        uid: customerData!.portalUid,
                        file_name: item.name,
                    })
                );
                Promise.all(promiseArray)
                    .then(() => {
                        message.success('上传成功');
                        setAddVisible(false);
                        setPdfStr([]);
                        request();
                    }, showErrorToast)
                    .finally(() => setPdfUploading(false));
            } else {
                message.error('请先选择文件');
            }
        }, [customerData, pdfStr, request]);

        const makeItem = (item: any) => {
            if (!customerData) return <div>无</div>;
            if (item.key === 'parentCustomerState' && !customerData['parentCustomerState']) {
                return <></>;
            }
            if (item.valueGetter) {
                if (item.key === 'relativeMobile') {
                    return item.valueGetter(customerData, (relativeId: number) => {
                        searchChild(relativeId);
                    });
                }
                return item.valueGetter(customerData);
            }
            if (item.speicalGetter) {
                return item.speicalGetter(banksInfo);
            }
            if (item.isArray) {
                let result = Array.from(
                    (customerData[item.key] ?? []).map((ic: { name: string }) => ic.name)
                ).join(',');
                return <div key={item.key}>{result.length > 0 ? result : '无'}</div>;
            }

            return <div key={item.key}>{customerData[item.key] ?? '无'}</div>;
        };

        return (
            <>
                {showError && (
                    <Result
                        status="error"
                        title="加载失败，请点击重试"
                        extra={
                            <Button
                                type="primary"
                                onClick={() => {
                                    request();
                                }}
                            >
                                重试
                            </Button>
                        }
                    />
                )}

                {!showError && (
                    <div className={style.content}>
                        <Modal
                            title="上传客户文件"
                            destroyOnClose
                            visible={addVisible}
                            centered
                            onCancel={() => {
                                setAddVisible(false);
                                setPdfStr([]);
                            }}
                            onOk={handleUploadPdf}
                            confirmLoading={pdfUploading}
                            cancelText="取消"
                            okText="上传"
                        >
                            <Upload
                                // accept=".pdf"
                                onChange={uploadChange}
                                multiple
                                maxCount={10}
                                customRequest={parsePDF}
                            >
                                <Button icon={<UploadOutlined />}>点击上传</Button>
                            </Upload>
                        </Modal>
                        {customerData !== undefined && customerData !== null && (
                            <>
                                {/* <div className={style.info}> */}
                                <div className={style.sectionTitle}>基本信息</div>
                                <div className={style.baseInfo}>
                                    <div className={style.column}>
                                        <div className={style.key}>
                                            {baseKeyLeftArray.map((item) => {
                                                return <div key={item.title}>{item.title}</div>;
                                            })}
                                        </div>
                                        <div className={style.value}>
                                            {baseKeyLeftArray.map((item) => {
                                                return (
                                                    <div key={item.key}>
                                                        {customerData[item.key] ?? '无'}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className={style.newColumn}>
                                        {baseKeyRightArray.map((item) => (
                                            <div className={style.item} key={item.title}>
                                                <div className={style.key}>
                                                    {item.key === 'parentCustomerState' &&
                                                    !customerData['parentCustomerState'] ? (
                                                        <div />
                                                    ) : (
                                                        <div key={item.title}>
                                                            {item.titleGetter
                                                                ? item.titleGetter(customerData)
                                                                : item.title}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className={style.value}>{makeItem(item)}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className={style.baseInfo}>
                                    <div className={style.column}>
                                        <div className={style.key}>
                                            <div>新用户Roadmap：</div>
                                            <div>客户画像：</div>
                                        </div>
                                        <div className={style.value}>
                                            <div>
                                                <a
                                                    className={`${style.doc}`}
                                                    href={customerData['roadmapUrl']}
                                                    target={'_blank'}
                                                >
                                                    {customerData['roadmapUrl'] ?? '无'}
                                                </a>
                                            </div>
                                            <div>{customerData['remark'] ?? '无'}</div>
                                        </div>
                                    </div>
                                    <div className={style.column}>
                                        <div className={style.key}>KYC链接：</div>
                                        <div className={style.value}>
                                            <a
                                                className={`${style.doc}`}
                                                href={customerData['kycUrl']}
                                                target={'_blank'}
                                            >
                                                {customerData['kycUrl'] ?? '无'}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className={style.sectionTitle}>业务信息</div>
                                <div className={style.baseInfo}>
                                    <div className={style.column}>
                                        <div className={style.key}>
                                            {businessKeyLeftArray.map((item) => {
                                                return <div key={item.title}>{item.title}</div>;
                                            })}
                                        </div>
                                        <div className={style.value}>
                                            {businessKeyLeftArray.map((item) => {
                                                return (
                                                    <div key={item.key}>
                                                        {customerData[item.key] ?? '无'}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className={style.column}>
                                        <div className={style.key}>
                                            {businessKeyRightArray.map((item) => {
                                                return <div key={item.title}>{item.title}</div>;
                                            })}
                                        </div>
                                        <div className={style.value}>
                                            {businessKeyRightArray.map((item) => {
                                                return (
                                                    <div key={item.key}>
                                                        {customerData[item.key] ?? '无'}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className={style.baseInfo}>
                                    <div className={style.file}>
                                        <div className={style.key}>已上传文件：</div>
                                        <div className={style.list}>
                                            {(customerData.signedFiles ?? []).map((item) => (
                                                <div className={style.item} key={item.id}>
                                                    <div
                                                        className={style.fileName}
                                                        onClick={() => {
                                                            window.open(item.file_url, 'target');
                                                        }}
                                                    >
                                                        {item.file_name}
                                                    </div>
                                                    <Button
                                                        className={style.delete}
                                                        type="link"
                                                        onClick={() => deleteReceipt(item)}
                                                    >
                                                        <DeleteOutlined />
                                                    </Button>
                                                </div>
                                            ))}
                                            {(customerData.signedFiles ?? []).length === 0 && '无'}
                                        </div>
                                    </div>
                                </div>
                                {/* </div> */}
                            </>
                        )}
                    </div>
                )}
            </>
        );
    }
);
export default CustomerInfo;
