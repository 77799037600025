export interface TransFormProps {
    trans_id: number;
}
export enum TransStatus {
    Created = 1,
    Placeable = 2,
    Traded = 3,
    Valued = 4,
    Settled = 5,
    Verified = 6,
    Deleted = 7,
}
export enum GinkgoCurrencyType {
    USD = 'C_USD',
    HKD = 'C_HKD',
    SGD = 'C_SGD',
    CHF = 'C_CHF',
    CNH = 'C_CNH',
    GBP = 'C_GBP',
    EUR = 'C_EUR',
}
export enum AssetType {
    Cash = 101001,
    FixedDeposit = 101002,
    NCD = 101003,
    Equities = 102001,
    AQ = 102002,
    DQ = 102003,
    Option = 102004,
    CorporateBond = 103001,
    GovernmentBond = 103002,
    Fund = 104001,
    PE = 104002,
    ETF = 104003,
    XAU = 105001,
    REITs = 106001,
    FCN = 107001,
    ELN = 107002,
    SDN = 107003,
    RCN = 107004,
    Loan = 201001,
    Other = 901001,
}
export enum FundStruct {
    'Open End' = 1,
    'Close End' = 2,
}
export enum RedemptionFrequency {
    Monthly = 1,
    Quarterly = 2,
    Annually = 3,
}
export enum CapitalCallFrequency {
    Quarterly = 1,
    Annually = 2,
}
export const isCashIsin = (value: string): boolean => {
    if (!value) return false;
    return Object.values(GinkgoCurrencyType).includes(value as GinkgoCurrencyType);
};
export enum TransCreateType {
    Order = 1,
    Event = 2,
}
export interface GinkgoTransFormRefType {
    onSave?: () => void;
    onDelete?: () => void;
    onTraded?: () => void;
    onPlaceable?: () => void;
    onBackCreated?: () => void;
    onBackPlaceable?: () => void;
    getFieldsValues?: (validate?: boolean) => Promise<UpdateTransactionReq>;
}
export enum TransferType {
    In = 1,
    Out = 2,
    BetweenSubAccounts = 3,
}
export enum ELNType {
    敲出ELN = 1,
    ELN = 2,
}
export interface Asset {
    isin: string;
    asset_type: { code: number; desc: string };
    asset_type_code: number;
    ticker: string;
    name: string;
    spec_name: string;
    is_selected?: boolean;
    pinyin: string;
    currency: string;
    value_date: number;
    trade_date: number;
    asset_name?: string;
    ext_info:
        | TransELNExtInfo
        | AssetBondExtInfo
        | AssetAQDQExtInfo
        | AssetOptionExtInfo
        | TransFundExtInfo;
    totalAmount?: number;
    underlying_open_price?: string;
    special_fields: Record<string, any>;
    created_at: number;
    qty?: number;
    amount?: number;
    asset: {
        name: string;
    };
    updated_at?: number;
    time_zone: string;
}

export interface QueryAssetType {
    id: number;
    account_id: string;
    isin: string;
    amount: number;
    qty: number;
    logic_status: string;
    created_at: number;
    asset_name: string;
    name: string;
    asset: {
        name?: string;
    };
}

export interface Account {
    account_id: string;
    uid: string;
    account_type: BankType;
    account_class: number;
    account_name: string;
    bank_name: string;
    vendor_sub_account_id: string;
    tag: number;
    display_name: string;
    status: number;
    bound_at: number;
    time_zone: string;
}

export interface Flow {
    id: number;
    account_id: string;
    trans_id: number;
    position_type: string;
    isin: string;
    presume_amount: number;
    verified_amount: number;
    create_member_id: number;
    create_date: number;
    logic_status: string;
    update_at: number;
    account: Account;
    asset: Asset;
    reconciliation_id?: number;
}
export enum BankType {
    UBS = 'ubs',
    LO = 'lo',
    BoS = 'bos',
    GS = 'gs',
    SC = 'sc',
    JPM = 'jpm',
    IBKR = 'ibkr',
    JB = 'jb',
}
export enum TransAdjustmentAssetType {
    Cash = AssetType.Cash,
    Bond = AssetType.CorporateBond,
    Equity = AssetType.Equities,
    Other = AssetType.Other,
}

export interface TransactionInfo {
    id: number;
    client_id: number;
    client: Client;
    type: {
        code: number;
        desc: string;
    };
    status: {
        code: number;
        desc: string;
    };
    create_type: {
        code: number;
        desc: string;
    };
    create_member_id: number;
    ext_info:
        | TransDepositExtInfo
        | TransLoanExtInfo
        | TransTransferExtInfo
        | TransExchangeExtInfo
        | TransAdjustmentExtInfo;
    created_at: number;
    trade_date: number;
    value_date: number;
    settlement_date: number;
    verify_date: number;
    logic_status: string;
    update_at: number;
    flows: Flow[];
    account: Account;
    assets: Asset[];
    positions: Asset[];
    email_send_date: number;
    placeable_date: number;
    time_zone: string;
}
export interface Client {
    id: number;
    client_id: number;
    name: string;
    mobile: string;
    email: string;
    address: string;
    state: string;
    gender: string;
    birthday: string;
    source: string;
    roadmap_url: string;
    creator_id: number;
    huoban_id: number;
    kyc_url: string;
    remark: string;
    is_real: number;
    cts: string;
    uts: string;
    token: string;
}
export interface TransactionResponse {
    transactions: TransactionInfo[];
    total_count: number;
    total_pages: number;
    current_page: number;
    page_size: number;
}
export enum AccountManageType {
    Manage = 1,
    Informed = 2,
}

export enum TransactionOrderType {
    'Place Deposit' = 1,
    'Take Loan' = 4,
    'Transfer' = 7,
    'Foreign Exchange' = 8,
    'Buy Equities' = 9,
    'Sell Equities' = 10,
    'Buy Bond' = 11,
    'Sell Bond' = 12,
    'Buy AQ' = 20,
    'Buy DQ' = 24,
    'Buy FCN' = 28,
    'Buy ELN' = 16,
    'Buy Option' = 33,
    'Sell Option' = 34,
    'Subscribe Fund' = 38,
    'Fund Redeem' = 39,
    'Fund Contribute' = 40,
    'Fund Distribute' = 41,
    'Create Adjustment' = 37,
}
export enum TransactionType {
    'Place Deposit' = 1,
    'Deposit Maturity' = 2,
    'Deposit Interest' = 3,
    'Take Loan' = 4,
    'Loan Maturity' = 5,
    'Loan Interest' = 6,
    'Transfer' = 7,
    'Foreign Exchange' = 8,
    'Buy Equities' = 9,
    'Sell Equities' = 10,
    'Buy Bond' = 11,
    'Sell Bond' = 12,
    'Bond Maturity' = 13,
    'Bond Coupon' = 14,
    'Bond Redeem' = 15,
    'Buy ELN' = 16,
    'ELN Knock Out' = 17,
    'ELN Maturity' = 18,
    'ELN Take Over Shares' = 19,
    'Buy AQ' = 20,
    'AQ Settlement' = 21,
    'AQ Knock Out' = 22,
    'AQ Maturity' = 23,
    'Buy DQ' = 24,
    'DQ Settlement' = 25,
    'DQ Knock Out' = 26,
    'DQ Maturity' = 27,
    'Buy FCN' = 28,
    'FCN Interest' = 29,
    'FCN Knock Out' = 30,
    'FCN Maturity' = 31,
    'FCN Take Over Shares' = 32,
    'Buy Option' = 33,
    'Sell Option' = 34,
    'Option Exercise' = 35,
    'Option Expiration' = 36,
    'Create Adjustment' = 37,
    'Subscribe Fund' = 38,
    'Fund Redeem' = 39,
    'Fund Contribute' = 40,
    'Fund Distribute' = 41,
}
export interface UpdateTransactionReq {
    id?: number;
    client_id: number;
    transaction_type?: TransactionOrderType;
    transfer_type?: TransferType;
    account_id?: string;
    currency?: string;
    status_code?: TransStatus;
    type?: string;
    value_date?: number;
    trade_date?: number;
    ext_info?:
        | TransDepositExtInfo
        | TransLoanExtInfo
        | TransTransferExtInfo
        | TransExchangeExtInfo
        | TransBondExtInfo
        | TransEquitiesExtInfo
        | TransELNExtInfo
        | TransAQExtInfo;
    assets?: Asset[];
}
/**
 * 代表货币兑换交易。
 */
export interface BaseExtInfo {
    account_id: string;
    currency: string;
    isin?: string;
    underlying_open_price: string;
    open_price: string;
    limit_price: number;
    underlying_name: string;
    underlyings?: string[];
    underlying_spec_name?: string;
    is_day_order?: number;
}
export interface TransOptionExtInfo extends BaseExtInfo {
    isin?: string;
    ticker?: string;
    price_type?: number;
    price?: string;
    fill_price?: number;
    number_of_shares?: number;
    fill_number_of_shares?: number;
    fill_option_fee_rate?: number;
    up_front_rate?: number;
    up_front_amount?: number;
    option_type?: number;
    strike_price_rate?: number;
    tenor?: string;
    option_fee?: number;
    exercise_stock_price?: number;
    exercise_qty?: number;
    exercise_amount?: number;
    exercise_date?: number;
    maturity_date?: number;
    option_side?: number;
}

export interface TransFcnExtInfo extends BaseExtInfo {
    isin?: string;
    price_type?: number;
    price?: string;
    fill_price?: number;
    amount?: number;
    fill_amount?: number;
    up_front_rate?: number;
    up_front_amount?: number;
    interest_amount?: number;
    interest_payment_date?: number;
    early_redemption_amount?: number;
    redemption_amount?: number;
    knock_out_date?: number;
    maturity_date?: number;
    take_over_shares_date?: number;
    shares_number?: number;
    return_amount?: number;
    ticker?: string;
    guaranteed_period?: string;
    tenor?: string;
    annualized_coupon?: number;
    strike_price_rate?: number;
    knock_out_price_rate?: number;
    coupon_frequency?: number;
}
export interface TransAdjustmentExtInfo extends BaseExtInfo {
    amount?: string;
    asset_name?: string;
    asset_type?: number;
    side?: number;
    description?: string;
    isin?: string;
    ticker?: string;
}
export interface TransAQExtInfo extends BaseExtInfo {
    guaranteed_period?: string;
    leverage?: number;
    boosted_day_volume?: number;
    day_volume?: number;
    isin?: string;
    price_type?: number;
    price?: string;
    fill_price?: string;
    up_front_rate?: string;
    up_front_amount?: string;
    maximum_notional_amount?: string;
    knock_out_date?: number;
    maturity_date?: number;
    strike_price_rate?: number;
    knock_out_price_rate?: number;
    tenor?: string;
    ticker?: string;
    current_period?: {
        end_date?: number;
        observation_days?: number;
        start_date?: number;
        trade_numbers?: number;
    };
}
export enum CustodyType {
    私行保管 = 1,
    非私行保管 = 2,
}
export enum UnitType {
    Unit = 1,
    Capital = 2,
}
export interface TransFundExtInfo extends BaseExtInfo {
    fund_struct_type?: number;
    bank_custody_type?: number;
    ticker?: string;
    payment_amount?: string;
    committed_capital?: string;
    immediate_capital_call?: string;
    immediate_payment?: string;
    redeem_amount?: string;
    settlement_amount?: string;
    capital_call?: string;
    unit_type?: number;
    invest_amount?: string;
    units?: string;
    asset_name?: string;
    fund_manager?: string;
    nav?: string;
    fees?: string;
    capital_return?: string;
    distribute_amount?: string;
}
export interface TransELNExtInfo extends BaseExtInfo {
    bank_id?: string;
    eln_type?: number;
    issue_date?: number;
    maturity_date?: number;
    tenor?: number;
    issue_price_rate?: number;
    strike_price_rate?: number;
    knock_out_price_rate?: number;
    issue_price?: string;
    strike_price?: string;
    knock_out_price?: string;
    earn_rate?: string;
    observation_frequency?: number;
    nominal_amount?: string;
    actual_amount?: string;
    price_type?: number;
    price?: string;
    validity?: string;
    custodian_trade_id?: string;
    up_front_rate?: number;
    up_front_amount?: number;
    fill_nominal_amount?: number;
    fill_actual_amount?: string;
    fill_price?: number;
    isin?: string;
    take_over_shares_date?: number;
    shares_number?: number;
    return_amount?: string;

    ticker?: string;
}

export interface TransExchangeExtInfo extends BaseExtInfo {
    /** 唯一账户标识符 */
    exchange_type_code: number;
    /** 代表'settle_od' */
    settle_od: number;

    /** 兑换前的货币 */
    buy_currency: string;

    /** 兑换前的货币数量 */
    buy_amount: string;

    /** 兑换后的货币 */
    sell_currency: string;

    /** 兑换后的货币数量 */
    sell_amount: string;

    /** 买入货币和卖出货币之间的汇率 */
    rate: string;

    /** 托管机构提供的交易的唯一标识符 */
    custodian_trade_id: string;
}
export interface TransDepositExtInfo extends BaseExtInfo {
    /** 期限 */
    tenor: string;
    /** 到期日时间戳 */
    maturity_date: number;
    /** 滚动类型 */
    roll_type: string;
    /** 利差 */
    principal_type_code: number;
    spread: string;
    /** 存款范围 */
    deposit_range: string;
    /** 本金 */
    principal_amount: string;
    /** 托管机构交易Id */
    custodian_trade_id: string;
    /** 银行标准利率 */
    dealer_rate: string;
    /** 客户利率 */
    client_rate: string;
    /** 利息金额 */
    interest_amount: string;
    /** 总金额 */
    total_amount: string;
    currency: string;
}
export interface TransLoanExtInfo extends BaseExtInfo {
    /** 期限 */
    tenor: string;
    /** 到期日时间戳 */
    maturity_date: number;
    /** 滚动类型 */
    roll_type: string;
    /** 利差 */
    spread: string;
    /** 存款范围 */
    deposit_range: string;
    /** 本金 */
    principal_amount: string;
    /** 托管机构交易Id */
    custodian_trade_id: string;
    /** 银行标准利率 */
    dealer_rate: string;
    /** 客户利率 */
    client_rate: string;
    /** 利息金额 */
    interest_amount: string;
    /** 总金额 */
    total_amount: string;
    currency: string;
}
export interface TransBondExtInfo extends BaseExtInfo {
    isin: string;
    name: string;
    qty: number;
    price_type: number;
    price: number;
    validity: number;
    custodian_trade_id: string;
    spread: number;
    fill_qty: string;
    fill_price: string;
    customer_price: string;
    accrued_interest: string;
    settlement_amount: string;
}
export interface TransEquitiesExtInfo extends BaseExtInfo {
    ticker: string;
    isin: string;
    name: string;
    qty: number;
    price_type: number;
    custom_price_type: string;
    price: string;
    validity: number;
    custodian_trade_id: string;
    spread: number;
    fill_qty: number;
    fill_price: string;
    customer_price: string;
    accrued_interest: string;
    settlement_amount: string;
}
export interface AccountInfo {
    account_id?: string;
    account_no?: string;
    accout_name?: string;
    account_bank_name?: string;
}
export interface TransTransferExtInfo extends BaseExtInfo {
    /** 转账类型 */
    transfer_type?:
        | TransferType
        | {
              code: number;
              desc: string;
              mailContent: string;
          };
    /** 账户名 */
    out_account_name?: string;
    /** 账户id */
    out_account_id?: string;
    /** 账户银行 */
    out_account_bank?: string;

    transfer_type_code?: number;
    transfer_scope?: { code: number; desc: string };
    transfer_scope_code?: number;
    out_account_no?: string;
    /** 账户名 */
    in_account_name?: string;
    /** 账户id */
    in_account_id?: string;
    /** 账户银行 */
    in_account_bank?: string;
    in_account_no?: string;
}

interface AssetBaseExtInfo {
    open_price?: string;
    ticker?: string;
    underlyings?: string[];
    underlying_name?: string;
    underlying_spec_name?: string;
    underlying_open_price?: string;
}
export interface AssetBondExtInfo extends AssetBaseExtInfo {
    ytm: string;
    ytw: string;
    coupon: string;
    currency: string;
    duration: string;
    ask_price: string;
    bond_type: string;
    issuer_cn: string;
    issuer_en: string;
    seniority: string;
    maturity_date: number;
    next_call_date: number;
    accrued_interest: string;
    credit_rating_sp: string;
    expiry_date: number;
    next_coupon_date: number;
    price_type: string;
    price: string;
    nominal_amount: string;
    delivery_amount: string;
    ask_price_plus_accrued_interest: string;
    residual_maturity: string;
}

export interface AssetOptionExtInfo extends AssetBaseExtInfo {
    option_side: number;
}
export interface AssetAQDQExtInfo extends AssetBaseExtInfo {
    bank_id?: string;
    leverage?: number;
    guaranteed_period?: string;
    tenor?: string;
    knock_out_price_rate?: string;
    knock_out_price?: string;
    strike_price_rate?: string;
    strike_price?: string;
    trade_days?: number;
    issuer?: string;
    observation_frequency?: number;
}
export interface ClientRes {
    clients: Client[];
}

export interface Client {
    id: number;
    name: string;
    banks?: Bank[];
}

export interface Bank {
    id: string;
    locale: string;
    name: string;
    logo: string;
    short_name: string;
}

export interface EmailAddress {
    id: number;
    bank_id: string;
    address: string;
    description: string;
}

export interface MailContent {
    from: string;
    to: string[];
    cc: string[];
    subject: string;
    body: string;
}

export interface SendEmailReq {
    mail_type: number;
    from: string;
    to: string[];
    cc?: string[];
    bcc?: string[];
    subject: string;
    body: string;
    body_type: 'TEXT' | 'HTML';
    trans_ids: number[];
}

export interface LetterInfo {
    attention?: string;
    contact_number?: string;
    contact_person?: string;
    date?: undefined;
    email_address?: string;
    group_email?: string;
    to_account_bank_id_code?: string;
    to_account_bank_name?: string;
    from_account_bank_name?: string;
    purpose?: string;
}

export interface AqDqObervationPeriod {
    end_date: number;
    observation_days: number;
    settle_date: number;
    start_date: number;
}
