import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, message, Tag } from 'antd';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { updateSecurity } from '../../service';
import { showError } from '../RecommendationComponent/util';

interface SpecNameType {
    isin: string;
    spec_name: string | null;
    handleRefreshList: () => void;
}

const SpecName: React.FC<SpecNameType> = (props) => {
    const [inputVisible, setInputVisible] = useState(false);

    const handleInputConfirm = useCallback(
        (e) => {
            if (e.target.value !== props.spec_name) {
                updateSecurity({
                    isin: props.isin,
                    spec_name: e.target.value,
                }).then(() => {
                    message.success('修改成功');
                    props.handleRefreshList();
                }, showError);
            }
            setInputVisible(false);
        },
        [props]
    );
    useEffect(() => {
        if (props.spec_name) setInputVisible(false);
    }, [props.spec_name]);
    return (
        <>
            {inputVisible && (
                <Input
                    autoFocus
                    type="text"
                    size="small"
                    className="tag-input"
                    defaultValue={props.spec_name || ''}
                    style={{ width: 93, height: 23 }}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                />
            )}
            {!props.spec_name && !inputVisible && (
                <Tag onClick={() => setInputVisible(true)}>
                    <PlusOutlined /> 添加别名
                </Tag>
            )}
            {props.spec_name && !inputVisible && (
                <Tag onClick={() => setInputVisible(true)}>
                    {props.spec_name}
                    <EditOutlined style={{ marginLeft: 9, color: '#1890FF' }} />
                </Tag>
            )}
        </>
    );
};

export default SpecName;
