import { ColDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Button, message, Modal, Tooltip, Typography } from 'antd';
import { Form, Input, Select } from 'formik-antd';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { showError } from '../../RecommendationComponent/util';
import { createEventType, getEventTypeList, updateEventType } from './requests';
import useForceUpdate from '../../../utils/Hooks/useForceUpdate';

interface EditableFields {
    name?: string;
    domain?: string;
    action?: string;
    cancelEventTypeId?: number;
    cancelPath?: string;
    demoData?: string;
}

export default function NotifyType() {
    const [rowData, setRowData] = useState<EventType[]>();
    const [curEditRow, setCurEditRow] = useState<EventType>();
    const [showModal, setShowModal] = useState(false);

    const modalTitle = useMemo(() => (curEditRow ? '编辑事件类型' : '添加事件类型'), [curEditRow]);

    const forceUpdate = useForceUpdate(() => {
        getEventTypeList()
            .then((res) => setRowData(res.data))
            .catch(showError);
    }, []);

    useEffect(() => {
        if (!showModal) {
            setCurEditRow(undefined);
        }
    }, [showModal]);

    const columns = useMemo<ColDef[]>(
        () => [
            { field: 'name', headerName: '名称' },
            { field: 'domain', headerName: '对象' },
            { field: 'action', headerName: '行为' },
            {
                headerName: '撤回事件',
                valueGetter: (params: ValueGetterParams) => {
                    let data = params.data as EventType;
                    return rowData?.find((item) => item.id === data.cancelEventTypeId)?.name ?? '';
                },
            },
            { field: 'cancelPath', headerName: '撤回条件' },
            {
                field: 'demoData',
                headerName: '数据示例',
                flex: 1,
                cellRenderer: (params: ICellRendererParams) => (
                    <Tooltip title={params.value} placement="bottomLeft">
                        <span>{params.value}</span>
                    </Tooltip>
                ),
            },
            {
                headerName: '操作',
                pinned: 'right',
                cellRenderer: (params: ICellRendererParams) => (
                    <Button
                        type="primary"
                        onClick={() => {
                            setCurEditRow(params.data);
                            setShowModal(true);
                        }}
                    >
                        编辑
                    </Button>
                ),
            },
        ],
        [rowData]
    );

    const handleFormValidate = useCallback((values: any) => {
        const errors: { [key: string]: string } = {};
        if (values.cancelEventTypeId && !values.cancelPath) {
            errors.cancelPath = '请输入内容';
        }
        for (let key of ['name', 'domain', 'action', 'demoData']) {
            if (!values[key]) {
                errors[key] = '请输入内容';
            }
        }
        return errors;
    }, []);

    const handleFormFinish = useCallback(
        (values: Partial<EventType>) => {
            if (!(Number(values.cancelEventTypeId) > 0)) {
                values.cancelEventTypeId = 0;
                values.cancelPath = '';
            }
            if (curEditRow) {
                values.id = curEditRow.id;
                updateEventType(values as EventType)
                    .then(() => {
                        message.success('更新成功');
                        forceUpdate();
                    })
                    .catch(showError)
                    .finally(() => setShowModal(false));
            } else {
                createEventType(values as EventType)
                    .then(() => {
                        message.success('创建成功');
                        forceUpdate();
                    })
                    .catch(showError)
                    .finally(() => setShowModal(false));
            }
        },
        [curEditRow, forceUpdate]
    );

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                <Typography.Title level={4} style={{ margin: 0 }}>
                    事件类型
                </Typography.Title>
                <Button
                    type="primary"
                    style={{ marginLeft: 16 }}
                    onClick={() => setShowModal(true)}
                >
                    添加
                </Button>
            </div>
            <div className="ag-theme-alpine" style={{ marginTop: 16 }}>
                <AgGridReact
                    columnDefs={columns}
                    defaultColDef={{ minWidth: 200 }}
                    rowData={rowData}
                    domLayout="autoHeight"
                    enableCellTextSelection
                />
            </div>
            <Modal
                title={modalTitle}
                footer={null}
                visible={showModal}
                onCancel={() => setShowModal(false)}
                destroyOnClose
            >
                <Formik
                    initialValues={curEditRow ?? {}}
                    validate={handleFormValidate}
                    onSubmit={handleFormFinish}
                >
                    {({ values }: { values: EditableFields }) => (
                        <Form labelCol={{ span: 4 }}>
                            <Form.Item label="名称" name="name" required>
                                <Input name="name" />
                            </Form.Item>
                            <Form.Item label="对象" name="domain" required>
                                <Input name="domain" />
                            </Form.Item>
                            <Form.Item label="行为" name="action" required>
                                <Input name="action" />
                            </Form.Item>
                            <Form.Item label="撤回事件" name="cancelEventTypeId">
                                <Select name="cancelEventTypeId" allowClear>
                                    {rowData?.map(
                                        (row) =>
                                            (!curEditRow || curEditRow.id !== row.id) && (
                                                <Select.Option value={row.id} key={row.id}>
                                                    {row.name}
                                                </Select.Option>
                                            )
                                    )}
                                </Select>
                            </Form.Item>
                            {Number(values.cancelEventTypeId) > 0 && (
                                <Form.Item label="撤回条件" name="cancelPath" required>
                                    <Input name="cancelPath" />
                                </Form.Item>
                            )}
                            <Form.Item label="数据示例" name="demoData" required>
                                <Input.TextArea name="demoData" />
                            </Form.Item>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin: '8px 0',
                                }}
                            >
                                <Button onClick={() => setShowModal(false)}>取消</Button>
                                <Button type="primary" htmlType="submit" style={{ marginLeft: 64 }}>
                                    提交
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
}
