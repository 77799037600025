import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import AccountItem from './FormItems/AccountItem';
import {
    Asset,
    BankType,
    GinkgoTransFormRefType,
    TransAQExtInfo,
    TransStatus,
    TransactionInfo,
    UpdateTransactionReq,
} from '../../../type';
import DateItem from './FormItems/DateItem';
import { Form } from 'antd';
import LKRadioGroup, { LKRadioGroupProps } from '../../../../../components/LKRadioGroup';
import { useForm } from 'antd/lib/form/Form';
import { GinkgoInput, GinkgoInputNumber } from './FormItems/GinkgoFieldItems';
import BondValidity from './FormItems/BondValidity';
import { useLatest } from 'ahooks';
import SearchUnderlying from './FormItems/SearchUnderlying';
import TenorItem from './FormItems/TenorItem';
import moment from 'moment';
import { UserAccountDetailInfo } from '../../../../../service/requests/HistoricalTransactions';
const AQForm = forwardRef<GinkgoTransFormRefType, TransactionInfo>(
    (data: TransactionInfo, onRef) => {
        const [form] = useForm();
        const [priceType, setPriceType] = useState<number>();
        const [guaranteeType, setGuaranteeType] = useState<string>();
        const [leverageType, setLeverageType] = useState<number>();
        const [dayOrder, setDayOrder] = useState<number>();
        const latestDayOrder = useLatest(dayOrder);
        const [currentPrice, setCurrentPrice] = useState<Asset>();
        const latestCurrentPrice = useLatest(currentPrice);
        const [currentBank, setCurrentBank] = useState<UserAccountDetailInfo>();
        const [timezone, setTimezone] = useState(data.time_zone || '');
        useImperativeHandle(onRef, () => {
            return {
                getFieldsValues: async (validate) => {
                    if (validate === false) {
                        return new Promise((resolve) => {
                            return resolve(configValues(form.getFieldsValue()));
                        });
                    } else {
                        return form.validateFields().then(configValues);
                    }
                },
            };
        });

        const configValues = (values: any) => {
            return {
                ...values,
                client_id: data.client_id,
                transaction_type: data.type.code,
                id: data.id,
                ext_info: {
                    ...data.ext_info,
                    ...values.ext_info,
                    is_day_order: dayOrder,
                    underlyings: [latestCurrentPrice.current?.isin],
                    underlying_name: latestCurrentPrice.current?.name,
                    underlying_spec_name: latestCurrentPrice.current?.spec_name,
                    underlying_open_price: latestCurrentPrice.current?.underlying_open_price,
                    price: values.ext_info?.price?.toString(),
                },
                status_code: data.status.code,
            };
        };

        const priceTypeOptions: LKRadioGroupProps['datasource'] = [
            {
                label: 'LMT',
                value: 1,
                width: '12.5%',
            },
            {
                label: 'MKT',
                value: 2,
                width: '12.5%',
            },

            {
                label: 'Market Close',
                value: 3,
                width: '25%',
            },
            {
                label: 'Market Open',
                value: 4,
                width: '25%',
            },
            {
                label: 'VWAP',
                value: 5,
                width: '12.5%',
            },
        ];
        const leverageTypeOptions: LKRadioGroupProps['datasource'] = [
            {
                label: 'leveraged',
                value: 2,
                width: '25%',
            },
            {
                label: 'non-leveraged',
                value: 1,
                width: '25%',
            },
        ];

        const guaranteeTypeOptions: LKRadioGroupProps['datasource'] = [
            //No Guarantee，14D，1M，2M，3M
            {
                label: 'No Guarantee',
                value: 'No Guarantee',
                width: '25%',
            },
            {
                label: '7D',
                value: '7D',
                width: '12.5%',
            },
            {
                label: '14D',
                value: '14D',
                width: '12.5%',
            },
            {
                label: '1M',
                value: '1M',
                width: '12.5%',
            },
            {
                label: '2M',
                value: '2M',
                width: '12.5%',
            },
            {
                label: '3M',
                value: '3M',
                width: '12.5%',
            },
        ];
        const gptbknOptions: LKRadioGroupProps['datasource'] = [
            {
                label: 'Normal and Leveraged',
                value: 1,
                width: '25%',
            },
            {
                label: 'only Normal',
                value: 2,
                width: '25%',
            },
        ];
        const settlementTypeOptions: LKRadioGroupProps['datasource'] = [
            {
                label: '14D',
                value: '14D',
                width: '12.5%',
            },
            {
                label: '1M',
                value: '1M',
                width: '12.5%',
            },
            {
                label: '2M',
                value: '2M',
                width: '12.5%',
            },
            {
                label: '3M',
                value: '3M',
                width: '12.5%',
            },
        ];
        const obsTypeOptions: LKRadioGroupProps['datasource'] = [
            {
                label: 'Daily',
                value: 1,
                width: '12.5%',
            },
            {
                label: 'Biweekly',
                value: 2,
                width: '12.5%',
            },

            {
                label: 'Monthly',
                value: 3,
                width: '12.5%',
            },
            {
                label: 'Maturity',
                value: 4,
                width: '25%',
            },
        ];
        const PlaceableReadOnly = useMemo(() => {
            return data.status.code !== TransStatus.Created;
        }, [data]);
        const TradedReadOnly = useMemo(() => {
            return data.status.code === TransStatus.Traded;
        }, [data]);
        const PlaceableRequired = useMemo(() => {
            return data.status.code === TransStatus.Created;
        }, [data]);
        const TradedRequired = useMemo(() => {
            return data.status.code === TransStatus.Placeable;
        }, [data]);
        const handleFormChange = (
            changedValues: Record<string, any>,
            allValues: UpdateTransactionReq
        ) => {
            let changeKey = Object.keys(changedValues)[0];
            const extInfo = allValues.ext_info as TransAQExtInfo;
            if (changeKey === 'ext_info') {
                changeKey = Object.keys(changedValues[changeKey])[0];
                // 1. 同步计算actual_amount
                if (
                    (changeKey === 'leverage' || changeKey === 'day_volume') &&
                    extInfo?.day_volume &&
                    extInfo.leverage
                ) {
                    extInfo!.boosted_day_volume = extInfo.day_volume * extInfo.leverage;
                }
                form.setFieldsValue({
                    ext_info: extInfo,
                });
            }
        };
        useEffect(() => {
            let ext_info = data.ext_info as TransAQExtInfo;
            setGuaranteeType(ext_info.guaranteed_period);
            setLeverageType(ext_info.leverage);
            setDayOrder(ext_info.is_day_order);
            setPriceType((data.ext_info as any).price_type);
            form.setFieldsValue({
                ...data,
                trade_date:
                    typeof data.trade_date === 'number' ? data.trade_date : moment().valueOf(),
            });
        }, [data, form]);
        const underlying = useMemo(() => {
            if (data) {
                return (data?.ext_info as TransAQExtInfo)?.underlyings
                    ? (data?.ext_info as TransAQExtInfo).underlyings![0]
                    : undefined;
            }
        }, [data]);
        return (
            <Form
                labelCol={{ span: 6 }}
                labelWrap
                wrapperCol={{ span: 18 }}
                form={form}
                onValuesChange={handleFormChange}
            >
                <AccountItem
                    clientId={data.client_id}
                    name={['ext_info', 'account_id']}
                    form={form}
                    readOnlyValue={data.ext_info.account_id}
                    selectCallback={(bank) => {
                        setCurrentBank(bank);
                    }}
                    width="calc(62.5% - 10px)"
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                />
                <SearchUnderlying
                    withCurrency={data !== undefined}
                    form={form}
                    needPrice={data === undefined}
                    readOnly={PlaceableReadOnly}
                    isin={underlying}
                    required={PlaceableRequired}
                    width="calc(62.5% - 10px)"
                    currentPrice={(price) => {
                        setCurrentPrice(price);
                        setTimezone(price?.time_zone!);
                        // handleChangePrice(price);
                    }}
                />
                <TenorItem
                    type="onlyMonth"
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                />
                <Form.Item
                    label="Leverage"
                    name={['ext_info', 'leverage']}
                    rules={[{ required: PlaceableRequired, message: 'Leverage is required' }]}
                >
                    <LKRadioGroup
                        datasource={leverageTypeOptions}
                        onChange={(value) => {
                            setLeverageType(value as number);
                        }}
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>

                <Form.Item
                    label="Guaranteed Period"
                    name={['ext_info', 'guaranteed_period']}
                    rules={[
                        { required: PlaceableRequired, message: 'Guaranteed Period is required' },
                    ]}
                >
                    <LKRadioGroup
                        datasource={guaranteeTypeOptions}
                        onChange={(value) => {
                            setGuaranteeType(value as string);
                        }}
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>
                {leverageType === 2 && guaranteeType && guaranteeType !== 'No Guarantee' && (
                    <Form.Item
                        label="Guarantee Period Trade before Knock out"
                        name={['ext_info', 'is_doubled_within_guaranteed_period']}
                        rules={[
                            {
                                required: PlaceableRequired,
                                message: 'Guarantee Period Trade before Knock out is required',
                            },
                        ]}
                    >
                        <LKRadioGroup datasource={gptbknOptions} readOnly={PlaceableReadOnly} />
                    </Form.Item>
                )}
                <Form.Item
                    label="Settlement Frequency"
                    name={['ext_info', 'settlement_frequency']}
                    rules={[
                        {
                            required: PlaceableRequired,
                            message: 'Settlement Frequency is required',
                        },
                    ]}
                >
                    <LKRadioGroup datasource={settlementTypeOptions} readOnly={PlaceableReadOnly} />
                </Form.Item>
                <Form.Item
                    label="Strike"
                    name={['ext_info', 'strike_price_rate']}
                    rules={[{ required: PlaceableRequired, message: 'Strike is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        addonAfter="%"
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Knock out"
                    name={['ext_info', 'knock_out_price_rate']}
                    rules={[{ required: PlaceableRequired, message: 'Knock out is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        addonAfter="%"
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Observation Frequency"
                    name={['ext_info', 'observation_frequency']}
                    rules={[
                        {
                            required: PlaceableRequired,
                            message: 'Observation Frequency is required',
                        },
                    ]}
                >
                    <LKRadioGroup datasource={obsTypeOptions} readOnly={PlaceableReadOnly} />
                </Form.Item>
                <Form.Item
                    label="Daily Trade Number of Shares"
                    name={['ext_info', 'day_volume']}
                    rules={[
                        {
                            required: PlaceableRequired,
                            message: 'Daily Trade Number of Shares is required',
                        },
                    ]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>
                {leverageType === 2 && (
                    <Form.Item
                        label="Leveraged Daily Trade Number of Shares"
                        name={['ext_info', 'boosted_day_volume']}
                        rules={[
                            {
                                required: PlaceableRequired,
                                message: 'Leveraged Daily Trade Number of Shares is required',
                            },
                        ]}
                    >
                        <GinkgoInputNumber
                            style={{ width: 'calc(62.5% - 10px)' }}
                            readOnly={PlaceableReadOnly}
                        />
                    </Form.Item>
                )}
                <Form.Item
                    label="Price Type"
                    name={['ext_info', 'price_type']}
                    rules={[{ required: PlaceableRequired, message: 'Price Type is required' }]}
                >
                    <LKRadioGroup
                        datasource={priceTypeOptions}
                        onChange={(value) => {
                            setPriceType(value as number);
                        }}
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>
                {priceType === 1 && (
                    <Form.Item
                        label="Price"
                        name={['ext_info', 'price']}
                        rules={[{ required: PlaceableRequired, message: 'Price is required' }]}
                    >
                        <GinkgoInputNumber
                            style={{ width: 'calc(62.5% - 10px)' }}
                            readOnly={PlaceableReadOnly}
                        />
                    </Form.Item>
                )}
                <Form.Item
                    name={['ext_info', 'validity']}
                    label="Validity"
                    rules={[
                        {
                            required: PlaceableRequired,
                            message: 'Validity is required',
                            validator(rule, value, callback) {
                                if (latestDayOrder.current === 1) {
                                    callback();
                                    return;
                                }
                                if (!value) {
                                    callback('Validity is required');
                                    return;
                                }
                                callback();
                            },
                        },
                    ]}
                >
                    <BondValidity
                        readOnly={PlaceableReadOnly}
                        isDayOrder={(data.ext_info as any).is_day_order}
                        dayChange={(dayOrder) => {
                            setDayOrder(dayOrder ? 1 : 2);
                            form.validateFields([['ext_info', 'validity']]);
                        }}
                    />
                </Form.Item>

                <Form.Item label="Custodian Trade ID" name={['ext_info', 'custodian_trade_id']}>
                    <GinkgoInput
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>

                <Form.Item label="ISIN" name={['ext_info', 'isin']}>
                    <GinkgoInput
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Upfront(%)"
                    name={['ext_info', 'up_front_rate']}
                    rules={[{ required: TradedRequired, message: 'Upfront(%) is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                        addonAfter="%"
                    />
                </Form.Item>
                <Form.Item
                    label="Upfront($)"
                    name={['ext_info', 'up_front_amount']}
                    rules={[{ required: TradedRequired, message: 'Upfront($) is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>

                <Form.Item
                    label="Fill Price"
                    name={['ext_info', 'fill_price']}
                    rules={[{ required: TradedRequired, message: 'Fill Price is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                {currentBank?.account_type === BankType.UBS && (
                    <Form.Item
                        label="Maximum Notional Amount"
                        name={['ext_info', 'maximum_notional_amount']}
                        rules={[
                            {
                                required: TradedRequired,
                                message: 'Maximum Notional Amount is required',
                            },
                        ]}
                    >
                        <GinkgoInputNumber
                            style={{ width: 'calc(62.5% - 10px)' }}
                            readOnly={TradedReadOnly}
                        />
                    </Form.Item>
                )}

                <DateItem
                    label="Trade Date"
                    name="trade_date"
                    width="calc(62.5% - 10px)"
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                    timezone={timezone}
                />
                <DateItem
                    label="Value Date"
                    name="value_date"
                    width="calc(62.5% - 10px)"
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                    timezone={timezone}
                />
                <Form.Item
                    label="Trade Days"
                    name={['ext_info', 'trade_days']}
                    rules={[{ required: TradedRequired, message: 'Trade Days is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                        precision={0}
                    />
                </Form.Item>
            </Form>
        );
    }
);

export default AQForm;
