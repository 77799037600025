import React, { useState } from 'react';
import { Button, message } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Radio } from 'formik-antd';
import SearchProductFormItem from '../../widget/GeneralForm/SearchProductFormItem';
import { assetList } from '../../../service/type';
import { noticeAdd, NoticeAddReqType, noticeUpdate } from '../../../service/requests/Notice';
import { showError } from '../../RecommendationComponent/util';

const NoticeAdd = ({ data, complete }: { data?: NoticeAddReqType; complete?: () => void }) => {
    const [curPostion, setCurPostion] = useState<assetList[number]>();

    return (
        <>
            <div>
                <Formik
                    initialValues={{
                        assetIndex: data === undefined ? '' : 0,
                        notice: data === undefined ? '' : data.notice,
                        duration_value: data === undefined ? 3 : data.duration_value,
                    }}
                    validate={(values) => {
                        let res: { [key: string]: string } = {};
                        if (typeof values.assetIndex !== 'number') {
                            res.assetIndex = '请输入资产名';
                        }
                        if (!values.notice) {
                            res.notice = '请输入通知内容';
                        }

                        return res;
                    }}
                    onSubmit={(values, formikHelpers) => {
                        if (data !== undefined) {
                            let isin = curPostion === undefined ? data.isin : curPostion.isin;
                            noticeUpdate({
                                isin,
                                id: data.id,
                                ...values,
                                duration_type: 1,
                            }).then(() => {
                                message.success('更新成功');
                                complete!();
                                formikHelpers.resetForm();
                            }, showError);
                        } else {
                            noticeAdd({
                                isin: curPostion!.isin,
                                ...values,
                                duration_type: 1,
                            }).then(() => {
                                message.success('创建成功');
                                formikHelpers.resetForm();
                            }, showError);
                        }
                    }}
                >
                    {({ submitForm }) => (
                        <Form>
                            <SearchProductFormItem
                                formName=""
                                searchType={[]}
                                isFormik
                                defaultSearchIsin={data === undefined ? undefined : data.isin}
                                assetListCallback={(originAsset) => setCurPostion(originAsset)}
                            >
                                <div className="form-item-label">资产名</div>
                            </SearchProductFormItem>
                            <Form.Item name="notice">
                                <div className="form-item-label">通知内容</div>
                                <Input.TextArea
                                    rows={4}
                                    name="notice"
                                    showCount
                                    maxLength={300}
                                    placeholder="请输入通知内容"
                                />
                            </Form.Item>
                            <Form.Item name="is_all_users" className="form-omit-explain">
                                <div className="form-item-label">展示时效</div>
                                <div style={{ marginBottom: '10px' }}>
                                    *从保存起立刻展示在app，在【时效天数*24】小时后取消展示
                                </div>
                                <Radio.Group name="duration_value">
                                    <Radio name="duration_value" value={3}>
                                        3天
                                    </Radio>
                                    <Radio name="duration_value" value={5}>
                                        5天
                                    </Radio>
                                    <Radio name="duration_value" value={7}>
                                        7天
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Button
                                onClick={submitForm}
                                style={{ display: 'block', marginTop: '16px' }}
                                type="primary"
                            >
                                保存
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </>
    );
};
export default NoticeAdd;
