import { HostType } from '../config';
import { multiHostAxios } from '../tools';

const axios = multiHostAxios(HostType.BAIKE);
export type identModel = {
    id: number;
    name: string;
    ident: string;
    type: '文本' | '平铺单休';
    options: {
        id: number;
        label: string;
    }[];
};
export type ApplyInfoModel = {
    favouriteTopicOptions: {
        id: number;
        label: string;
    }[];
    fields: identModel[];
    guests: SubscribedList;
    title: string;
    values: Map<number, identModel>;
};
export type SubscribedList = {
    id: number;
    uid: number;
    name: string;
    mobile: string;
    cts: string;
    uts: string;
    state: string;
    avatar: string;
    boundMobile: string;
    ident?: {
        id: number;
        ident: string;
        label: string;
        index: number;
        fieldId: number;
        activityId: number;
    };
    favouriteTopics?: number[];
    values?: {}[];
    otherFavouriteTopics: string;
    activityId: number;
    identId: number;
}[];
export type ShareMeetingLinksModel = {
    page_data: {
        id: number;
        createdAt: number;
        channelName: string;
        docId: number;
    }[];
    total_count: number;
    total_pages: number;
};
export function getSubscribeInfo(data: { activityId: number }) {
    return axios.post<Array<ApplyInfoModel>>('/v1/admin/activity/guest/list', { data });
}
//新增小程序渠道文章
export function addMiniDocumentLink(data: { doc_id: number; channel: string }) {
    return axios.post('/v1/admin/activity/publicity/doc/add', { data });
}
//查询小程序渠道文章列表
export function getShareLinkInfo(data: { current_page: number; page_size: number }) {
    return axios.post<ShareMeetingLinksModel>('/v1/admin/activity/publicity/doc/list', { data });
}
