import { Button, Form, Input, message, Modal, Select } from 'antd';
import React, { useCallback } from 'react';
import { AuthorityTableRow, DefaultModalProps } from '.';
import { showError } from '../../RecommendationComponent/util';
import { editMenuAuthority } from './requests';

interface EditMenuModalProps extends DefaultModalProps {
    menu?: AuthorityTableRow;
    topLevelMenus?: AuthorityTableRow[];
}

export default function EditMenuModal(props: EditMenuModalProps) {
    const { menu, topLevelMenus, setVisible, refreshData } = props;

    const handleFormFinish = useCallback(
        (values) => {
            editMenuAuthority({ ...values, id: menu?.id ?? 0 }).then(() => {
                message.success('编辑成功');
                refreshData();
                setVisible(false);
            }, showError);
        },
        [menu, refreshData, setVisible]
    );

    return (
        <Modal
            {...props}
            centered
            destroyOnClose
            title="编辑菜单"
            footer={null}
            onCancel={() => setVisible(false)}
        >
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ offset: 1, span: 16 }}
                initialValues={menu}
                onFinish={handleFormFinish}
            >
                <Form.Item
                    label="父级菜单"
                    required
                    name="parentId"
                    rules={[{ required: true, message: '请选择一项' }]}
                >
                    <Select>
                        <Select.Option value={0}>无</Select.Option>
                        {topLevelMenus?.map((menu) => (
                            <Select.Option key={menu.id} value={menu.id}>
                                {menu.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="菜单名称"
                    required
                    name="name"
                    rules={[{ required: true, message: '请输入内容' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="菜单入口"
                    required
                    name="entrance"
                    rules={[{ required: true, message: '请输入内容' }]}
                >
                    <Input />
                </Form.Item>
                <div
                    style={{
                        paddingTop: '16px',
                        display: 'flex',
                        justifyContent: 'center',
                        columnGap: '64px',
                    }}
                >
                    <Button onClick={() => setVisible(false)}>取消</Button>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </div>
            </Form>
        </Modal>
    );
}
