import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Button, Form, Modal, Radio, Spin, Table, TableColumnType, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from 'antd/lib/form/Form';
import { CustomerManageData } from '../../service/requests/CustomerManage';
import SearchCustomUserFormItem from '../widget/GeneralForm/SearchCustomUserFormItem';
import { UserAccountDetailInfo } from '../../service/requests/HistoricalTransactions';
import { useRequest } from 'ahooks';
import { addBondRecord, queryQouteList } from './request';
import { ProductQuoteResponseType, QouteType } from './type';
import { BondType } from '../Quotation/request';
import moment from 'moment';
import { EventList, uploadEventTrack } from '../../service/requests/EventTrack';
import { CopyOutlined } from '@ant-design/icons';
import AccountItem from '../Transaction/components/TransForm/Forms/FormItems/AccountItem';

//根据bondsQuoteListData类型构建一个 mockData
const BondQuote = () => {
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [curBank, setCurBank] = useState<UserAccountDetailInfo>();
    const history = useHistory();
    const [customUser, setCustomUser] = useState<CustomerManageData['pageData'][0]>();
    const [form] = useForm();
    const [visible, setVisible] = useState(false);
    const [currentModalType, setCurrentModalType] = useState<'Add' | 'Copy'>('Add');
    const [addLoading, setAddLoading] = useState(false);
    const { data: bondsQuoteListData, loading, run: refresh } = useRequest(queryQouteList, {
        defaultParams: [
            {
                current_page: currentPage,
                page_size: pageSize,
            },
        ],
    });
    const columns: TableColumnType<ProductQuoteResponseType['product_quotes'][number]>[] = [
        {
            title: t('BondQuote_QuoteTime'),
            dataIndex: 'created_at',
            render: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
            width: 300,
        },
        {
            title: t('BondQuote_QuoteUser'),
            dataIndex: 'quoteUser',
            width: 200,
            render(value, record, index) {
                return record.member.name;
            },
        },
        {
            title: t('BondQuote_CustomerId_Name'),
            dataIndex: 'customerId',
            width: 200,
            render(value, record, index) {
                return `${record.customer.id}-${record.customer.name}`;
            },
        },
        {
            title: t('BondQuote_BankAccount'),
            dataIndex: 'bankAccount',
            width: 150,
            render(value, record, index) {
                return `${record.account.bank_name}${
                    record.account.vendor_sub_account_id
                        ? '-' + record.account.vendor_sub_account_id
                        : ''
                }`;
            },
        },
        {
            title: t('BondQuote_ProductType'),
            dataIndex: 'productType',
            width: 200,
            render(value, record, index) {
                return record.product_type === BondType.corporate
                    ? t('BondQuote_Corporate')
                    : t('BondQuote_Government');
            },
        },
        {
            title: t('BondQuote_QuoteStatus'),
            dataIndex: 'quoteStatus',
            width: 200,
            render(value, record, index) {
                switch (record.status) {
                    case QouteType.Screening:
                        return t('BondQuote_Screening');
                    case QouteType.Communicating:
                        return t('BondQuote_Communicating');
                    case QouteType.Canceled:
                        return t('BondQuote_Canceled');
                    case QouteType.Confirmed:
                        return t('BondQuote_Confirmed');
                }
            },
        },
        {
            title: '',
            fixed: 'right',
            width: 80,
            render(value, record, index) {
                return (
                    <Button
                        className={styles.btn}
                        onClick={(e) => {
                            e.stopPropagation();
                            setCurrentModalType('Copy');
                            setVisible(true);
                            setCustomUser({
                                ...record.customer,
                            });
                            form.setFieldsValue({
                                customer_id: record.customer.name,
                                account_id: record.account_id,
                                product_type: record.product_type,
                            });
                        }}
                    >
                        <Tooltip title="编辑">
                            <CopyOutlined />
                        </Tooltip>
                    </Button>
                );
            },
        },
    ];
    const [tableHeight, setTableHeight] = React.useState(0);
    useEffect(() => {
        //获取屏幕高度;
        const element = document.getElementById('BondQuote_Container');
        if (element) {
            setTableHeight(element.clientHeight - 180);
        }
    }, []);
    const onFinish = (values: { product_type: BondType }) => {
        if (customUser && curBank) {
            setAddLoading(true);
            addBondRecord({
                customer_id: customUser.id,
                account_id: curBank.account_id,
                product_type: values.product_type,
            })
                .then((res) => {
                    setVisible(false);
                    history.push(
                        `${history.location.pathname}/detail?id=${res.data.product_quote.id}&type=edit`
                    );
                })
                .finally(() => setAddLoading(false));
        }
    };
    return (
        <div className={styles.container} id="BondQuote_Container">
            <Button
                type="primary"
                className={styles.add}
                onClick={() => {
                    uploadEventTrack(EventList.BondQouteAddClick, 2);
                    setCurrentModalType('Add');
                    form.resetFields();
                    setVisible(true);
                }}
            >
                {t('BondQuote_AddBtn_Title')}
            </Button>
            <Spin spinning={loading} tip={t('loading')}>
                <Table
                    columns={columns}
                    className={styles.table}
                    dataSource={bondsQuoteListData?.data.product_quotes}
                    pagination={{
                        pageSize: pageSize,
                        total: bondsQuoteListData?.data.total_count,
                        showSizeChanger: true,
                        showTotal: (total) => `${t('TotalPage')} ${total} `,
                        current: currentPage,
                        onChange(page, pageSize) {
                            setPageSize(pageSize || 10);
                            setCurrentPage(page);
                            refresh({
                                current_page: page,
                                page_size: pageSize,
                            });
                        },
                    }}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                history.push(
                                    `${history.location.pathname}/detail?id=${record.id}&type=edit`
                                );
                            },
                        };
                    }}
                    rowKey="id"
                    size="small"
                    scroll={{ x: true, y: tableHeight }}
                />
            </Spin>
            <Modal
                title={currentModalType === 'Add' ? '新增报价' : '复制报价'}
                onOk={() => form.submit()}
                closable={false}
                maskClosable={false}
                visible={visible}
                confirmLoading={addLoading}
                onCancel={() => setVisible(false)}
                okText={t('Confirm')}
                cancelText={t('Cancel')}
                destroyOnClose
            >
                <Form
                    onFinish={onFinish}
                    form={form}
                    initialValues={{ type: 0 }}
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                >
                    <SearchCustomUserFormItem
                        userCallback={(user) => {
                            setCustomUser(user);
                            form.setFieldsValue({ account_id: undefined });
                        }}
                    />
                    <AccountItem
                        name={'account_id'}
                        selectCallback={(originAccount, index) => {
                            setCurBank(originAccount);
                        }}
                        required
                        clientId={customUser?.id ?? 0}
                    />
                    <Form.Item
                        label="交易产品类型"
                        name="product_type"
                        rules={[{ required: true, message: '请选择交易产品类型' }]}
                    >
                        <Radio.Group>
                            <Radio value={BondType.corporate} key={0}>
                                公司债
                            </Radio>
                            <Radio value={BondType.government} key={1}>
                                国债
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default BondQuote;
