import Modal from 'antd/lib/modal/Modal';
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Formik } from 'formik';
import { Form, Select } from 'formik-antd';
import { Button, message, Input } from 'antd';
import {
    addTradeOption,
    addTradeStruct,
    availQueryTradeProduct,
    queryPosition,
    searchSecurity,
    addTradeExchange,
    newSecurity,
} from '../../../../../service';
import { showError } from '../../../../RecommendationComponent/util';
import { assetList } from '../../../../../service/type';
import { FormRadio, FormInput, FormDatePicker, FormInputNumber } from './FormComponents';

const { Option } = Select;
interface BuyAssetModalProps {
    visible: boolean;
    setVisible: (newState: boolean) => void;
    uid: string;
    account_id: string;
    handleRefreshList: () => void;
}

function validate(value: any) {
    let error;
    if (!value) {
        error = '不可为空';
    }
    return error;
}

interface BuyAvailQueryResult {
    c_debit: string | null;
    c_max_avail_qty: string;
    c_total_price: string | null;
    currency: string;
    debit: string | null;
    isin: string;
    max_avail_qty: string;
    total_price: string | null;
}

export default function BuyAssetModal(props: BuyAssetModalProps) {
    const { visible, setVisible } = props;

    const [showAddProduction, setShowAddProduction] = useState(false);
    const [value, setValue] = useState('');
    const addNewSecurity = useCallback(() => {
        newSecurity({ isin: value }).then(() => {
            message.success('添加成功');
            setValue('');
            setShowAddProduction(false);
        }, showError);
    }, [value]);
    const [assetListData, setAssetListData] = useState<assetList>([]);
    const handleSearch = useCallback((keyword: string) => {
        if (keyword)
            searchSecurity({ keyword }).then((res) => {
                setAssetListData(res.data);
                if (res.data.length) {
                    setShowAddProduction(false);
                } else {
                    setShowAddProduction(true);
                }
            }, showError);
    }, []);
    useEffect(() => {
        handleSearch('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const options = useMemo(
        () =>
            assetListData
                .filter(
                    (item) => item.sub_asset_class !== 'option' && item.sub_asset_class !== 'struct'
                )
                .map((item) => (
                    <Option key={item.isin} value={item.isin}>
                        {item.name}
                    </Option>
                )),
        [assetListData]
    );

    const validateDq = useCallback(
        (isin: string) => {
            if (!isin) {
                return '不能为空';
            }
            // });
            return queryPosition({
                uid: props.uid,
                account_id: props.account_id,
                isin,
            }).then((data: any) => {
                if (!data.data) {
                    return '未持有此资产';
                }
            }, showError);
        },
        [props.account_id, props.uid]
    );

    const [totalPrice, setTotalPrice] = useState<BuyAvailQueryResult>();

    const validateFormik = useCallback(
        async (values: Record<string, any>) => {
            let errors: { [key: string]: string } = {};
            if (values.type === 'stocks') {
                if (values.stockName && values.stockPrice) {
                    try {
                        let queryResult = await availQueryTradeProduct({
                            uid: props.uid,
                            isin: values.stockName,
                            qty: values.stockAmount?.toString() || '',
                            account_id: props.account_id,
                            deal_price: values.stockPrice.toString(),
                        });
                        setTotalPrice(queryResult.data);
                        if (
                            values.stockAmount &&
                            queryResult.data?.c_max_avail_qty &&
                            values.stockAmount > queryResult.data.c_max_avail_qty
                        ) {
                            errors.stockAmount = '数量不能超过最大值';
                        }
                    } catch (err) {
                        setTotalPrice(undefined);
                        showError(err as any);
                    }
                }
            }
            return errors;
        },
        [props.account_id, props.uid]
    );
    const handleSubmit = useCallback(
        (values: Record<string, any>) => {
            if (values.type === 'stocks') {
                addTradeExchange({
                    uid: props.uid,
                    isin: values.stockName,
                    qty: values.stockAmount,
                    account_id: props.account_id,
                    deal_price: values.stockPrice,
                }).then(() => {
                    message.success('成功');
                    props.handleRefreshList();
                    setVisible(false);
                }, showError);
            } else if (values.type === 'option') {
                addTradeOption({
                    uid: props.uid,
                    underlying: values.optionName,
                    qty: values.optionAmount,
                    account_id: props.account_id,
                    option_type: values.optionType,
                    expiry_date: new Date(values.optionDate).getTime().toString(),
                    strike_price: values.optionPrice,
                    option_premium: values.optionExpense,
                }).then(() => {
                    message.success('成功');
                    props.handleRefreshList();
                    setVisible(false);
                }, showError);
            } else if (values.type === 'structure') {
                addTradeStruct({
                    uid: props.uid,
                    type: values.structType,
                    underlyings: [values.structName],
                    account_id: props.account_id,
                    strike_price: values.structStrike,
                    ko_price: values.structKnock,
                    expiry_date: new Date(values.structDate).getTime().toString(),
                    day_volume: values.structAmount,
                    total_price: values.structMoney,
                    annual_interest: values.structInterest,
                }).then(() => {
                    message.success('成功');
                    props.handleRefreshList();
                    setVisible(false);
                }, showError);
            }
        },
        [props, setVisible]
    );
    return (
        <Modal
            visible={visible}
            footer={null}
            onCancel={() => setVisible(false)}
            centered
            width={800}
            style={{ maxWidth: 800 }}
            destroyOnClose
            title="买入产品"
        >
            <Formik
                initialValues={{
                    type: 'stocks',
                    stockName: '',
                    stockPrice: '',
                    stockAmount: '',
                    stockTotal: '',
                    optionType: 'BUY_CALL',
                    optionName: '',
                    optionDate: '',
                    optionPrice: '',
                    optionExpense: '',
                    optionAmount: '',
                    optionTotal: '',
                    structType: 'AQ',
                    structName: '',
                    structStrike: '',
                    structKnock: '',
                    structDate: '',
                    structAmount: '',
                    structMoney: '',
                    structInterest: '',
                }}
                onSubmit={handleSubmit}
                validate={validateFormik}
            >
                {({ values, submitForm, validateForm }) => (
                    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                        {/* <FormRadio
                            name="type"
                            label="产品类型"
                            values={[
                                ['stocks', '股票、债券、基金'],
                                ['option', '期权'],
                                ['structure', '结构性产品'],
                            ]}
                            onChange={() => setShowAddProduction(false)}
                        /> */}
                        {values.type === 'stocks' ? (
                            <>
                                <Form.Item
                                    name={'stockName'}
                                    label={'产品名称'}
                                    validate={validate}
                                >
                                    <Select
                                        allowClear
                                        style={{ width: 354 }}
                                        name={'stockName'}
                                        showSearch
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={handleSearch}
                                        notFoundContent={null}
                                        placeholder={'输入名称或ISIN'}
                                    >
                                        {options}
                                    </Select>
                                </Form.Item>
                                {showAddProduction && (
                                    <div style={{ marginLeft: 119, marginBottom: 30 }}>
                                        <div style={{ color: '#FF0000' }}>
                                            未搜索到相应资产，请输入ISIN添加资产
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span>资产ISIN：</span>{' '}
                                            <Input
                                                style={{ width: 356, marginRight: 40 }}
                                                value={value}
                                                onChange={(e) => setValue(e.target.value)}
                                            />
                                            <Button onClick={addNewSecurity}>添加</Button>
                                        </div>
                                    </div>
                                )}
                                <FormInputNumber
                                    name="stockPrice"
                                    label="买入价"
                                    validate={validate}
                                    placeholder={'输入单位买入价格'}
                                />
                                <div
                                    style={{
                                        display: 'flex',
                                        marginLeft: 81,
                                    }}
                                >
                                    <FormInputNumber
                                        name="stockAmount"
                                        label="买入数量"
                                        validate={validate}
                                        placeholder={'单位买入价格'}
                                    >
                                        <div>
                                            最大：
                                            {totalPrice?.c_max_avail_qty.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ','
                                            )}
                                        </div>
                                    </FormInputNumber>
                                </div>
                                {totalPrice && values.stockAmount ? (
                                    <div style={{ marginLeft: 144 }}>
                                        <div>
                                            总价：
                                            {totalPrice.c_total_price?.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ','
                                            )}{' '}
                                            {totalPrice.currency}
                                        </div>
                                        <div>
                                            产生贷款：
                                            {totalPrice.c_debit?.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ','
                                            )}{' '}
                                            {totalPrice.currency}
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        ) : values.type === 'option' ? (
                            <>
                                <FormRadio
                                    name="optionType"
                                    label="期权类型"
                                    values={[
                                        ['BUY_CALL', '买CALL'],
                                        ['SELL_CALL', '卖CALL'],
                                        ['BUY_PUT', '买PUT'],
                                        ['SELL_PUT', '卖PUT'],
                                    ]}
                                    validate={validate}
                                />
                                <Form.Item
                                    name={'optionName'}
                                    label={'标的名称'}
                                    validate={validate}
                                >
                                    <Select
                                        style={{ width: 354 }}
                                        name={'optionName'}
                                        showSearch
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={handleSearch}
                                        notFoundContent={null}
                                        placeholder={'输入名称或ISIN'}
                                    >
                                        {options}
                                    </Select>
                                </Form.Item>
                                {showAddProduction && (
                                    <div style={{ marginLeft: 119, marginBottom: 30 }}>
                                        <div style={{ color: '#FF0000' }}>
                                            未搜索到相应资产，请输入ISIN添加资产
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span>资产ISIN：</span>{' '}
                                            <Input
                                                style={{ width: 356, marginRight: 40 }}
                                                value={value}
                                                onChange={(e) => setValue(e.target.value)}
                                            />
                                            <Button onClick={addNewSecurity}>添加</Button>
                                        </div>
                                    </div>
                                )}
                                <FormDatePicker
                                    name="optionDate"
                                    label="到期日"
                                    validate={validate}
                                />
                                <FormInputNumber
                                    name="optionPrice"
                                    label="行权价"
                                    validate={validate}
                                    placeholder={'输入行权价'}
                                />
                                <FormInputNumber
                                    name="optionExpense"
                                    label="期权费"
                                    validate={validate}
                                    placeholder={'每股期权费'}
                                />
                                <FormInputNumber
                                    name="optionAmount"
                                    label="数量"
                                    validate={validate}
                                    placeholder={'输入期权数量'}
                                />
                                <div style={{ marginLeft: 144, display: 'flex' }}>
                                    总价：
                                    {values.optionExpense && values.optionAmount ? (
                                        <>
                                            {(
                                                Number(values.optionExpense) *
                                                Number(values.optionAmount)
                                            )
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            {values.optionName
                                                ? assetListData.find(
                                                      (assetItem) =>
                                                          assetItem.isin === values.optionName
                                                  )?.currency
                                                : ''}
                                        </>
                                    ) : null}
                                </div>
                            </>
                        ) : (
                            <>
                                <FormRadio
                                    name="structType"
                                    label="结构化产品类型"
                                    values={[
                                        ['AQ', 'AQ'],
                                        ['DQ', 'DQ'],
                                        ['FCN', 'FCN'],
                                    ]}
                                />
                                <Form.Item
                                    name={'structName'}
                                    label={'标的名称'}
                                    validate={values.structType === 'DQ' ? validateDq : validate}
                                >
                                    <Select
                                        style={{ width: 354 }}
                                        name={'structName'}
                                        showSearch
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={handleSearch}
                                        notFoundContent={null}
                                        placeholder={'输入名称或ISIN'}
                                    >
                                        {options}
                                    </Select>
                                </Form.Item>
                                {showAddProduction && (
                                    <div style={{ marginLeft: 119, marginBottom: 30 }}>
                                        <div style={{ color: '#FF0000' }}>
                                            未搜索到相应资产，请输入ISIN添加资产
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <span>资产ISIN：</span>{' '}
                                            <Input
                                                style={{ width: 356, marginRight: 40 }}
                                                value={value}
                                                onChange={(e) => setValue(e.target.value)}
                                            />
                                            <Button onClick={addNewSecurity}>添加</Button>
                                        </div>
                                    </div>
                                )}
                                <FormInput
                                    name="structStrike"
                                    label="执行价"
                                    validate={validate}
                                    placeholder={'输入执行价'}
                                />
                                <FormInput
                                    name="structKnock"
                                    label="敲出价"
                                    validate={validate}
                                    placeholder={'输入敲出价'}
                                />
                                {values.structType === 'FCN' ? (
                                    <>
                                        <FormInput
                                            name="structMoney"
                                            label="金额"
                                            validate={validate}
                                            placeholder={'输入产品的总金额'}
                                        />
                                        <FormInput
                                            name="structInterest"
                                            label="年利"
                                            validate={validate}
                                            placeholder={'输入年利率'}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <FormInput
                                            name="structAmount"
                                            label="数量"
                                            validate={validate}
                                            placeholder={'输入每日卖出数量'}
                                        />
                                    </>
                                )}
                                <FormDatePicker
                                    name="structDate"
                                    label="到期日"
                                    validate={validate}
                                />
                            </>
                        )}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                paddingTop: '10px',
                            }}
                        >
                            <Button type="primary" onClick={() => validateForm().then(submitForm)}>
                                完成
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}
