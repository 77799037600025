import React, { useEffect, useMemo, useState } from 'react';
import LKRadioGroup from '../../../../components/LKRadioGroup';
import { Client } from '../../type';
import pinyin from 'tiny-pinyin';

interface InitialClientSelectionProps {
    clients: Client[];
    onChange: (client?: Client) => void;
}
const InitialClientSelection = (props: InitialClientSelectionProps) => {
    const { clients, onChange } = props;
    const [initial, setInitial] = useState('');
    const [value, setValue] = useState<number>();
    const initialData = [
        { label: 'ABCD', value: 'abcd' },
        { label: 'EFG', value: 'efg' },
        { label: 'HIJ', value: 'hij' },
        { label: 'KLM', value: 'klm' },
        { label: 'NOP', value: 'nop' },
        { label: 'QRS', value: 'qrs' },
        { label: 'TUV', value: 'tuv' },
        { label: 'WXYZ', value: 'wxyz' },
        { label: '0-9', value: '0123456789' },
    ];
    const filteredClients = useMemo(() => {
        let ret = clients.map((c) => ({
            label: c.name,
            value: c.id,
        }));
        if (initial) {
            ret = ret.filter((i) => {
                if (/[a-zA-Z0-9]/.test(i.label.charAt(0))) {
                    return initial.indexOf(i.label.charAt(0).toLowerCase()) > -1;
                } else if (pinyin.isSupported()) {
                    return (
                        initial.indexOf(
                            pinyin.convertToPinyin(i.label.charAt(0), '', true).charAt(0)
                        ) > -1
                    );
                } else {
                    return false;
                }
            });
        }
        if (!ret.find((i) => i.value === value)) {
            setValue(undefined);
        }
        return ret;
    }, [clients, value, initial]);
    useEffect(() => {
        onChange(clients.find((c) => c.id === value));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);
    const onInitialChange = (value: any) => {
        setInitial(value as string);
    };
    const onClientChange = (value: any) => {
        setValue(value);
    };

    return (
        <div>
            <LKRadioGroup datasource={initialData} onChange={onInitialChange} itemWidth="6.25%" />
            <LKRadioGroup
                datasource={filteredClients}
                value={value}
                onChange={onClientChange}
                itemWidth="25%"
            />
        </div>
    );
};

export default InitialClientSelection;
