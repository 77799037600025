import { Button, Form, message, Modal, Select, Upload } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import './index.less';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { CustomModalProps } from '../../../CustomerDetail/Modal/MakeTransactionImgModal';
import TextArea from 'antd/lib/input/TextArea';
import { FileAddFilled } from '@ant-design/icons';
import { CommonUploadFile, UploadFileType } from '../../../../../AssetManagement/BankFee/requests';
import { showError } from '../../../../../RecommendationComponent/util';
import moment from 'moment';
import { AutoSaveType, saveValues, getValues } from '../../../../../../utils/AutoSave';
import FileItem from '../FileItem';
import store from '../../../../../../store/store';
import {
    addTrackRecord,
    AddTrackRecordReqProps,
    editTrackRecord,
    TrackRecordItem,
    TrackTagsType,
} from '../../request';
import { useDispatch } from 'react-redux';
import { getRecordList } from '../../../../../../store/actions/actions';
interface RecordListModal extends CustomModalProps {
    customer_id: number;
    changeType?: (type: RecordListModalType) => void;
    modalType: RecordListModalType;
    data?: TrackRecordItem;
    isList: boolean;
    refreshTags?: string[];
}
export enum RecordListModalType {
    Edit = 'edit',
    Add = 'add',
    View = 'view',
}
export interface TrackRecordFileItem {
    id: number;
    name: string;
    url: string;
    creatTime: string;
    createrName?: string;
    loading?: boolean;
}
const RecordListModal = ({
    visibleState,
    modalType,
    changeType,
    customer_id,
    data,
    isList,
    refreshTags,
}: RecordListModal) => {
    const [visible, setVisible] = visibleState;
    const [form] = Form.useForm();
    const { tags } = store.getState().customer as { tags: TrackTagsType };
    const [filesMap, setFilesMap] = useState<Map<string, TrackRecordFileItem>>(new Map());
    const [updateTime, setUpdateTime] = useState<undefined | number>(undefined);

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                content: data.content,
                tags: data.tags,
            });
            setFilesMap(
                new Map(
                    (data.attachments ?? []).map((item) => [
                        item.url,
                        {
                            id: item.id,
                            name: item.url,
                            url: item.url,
                            creatTime: moment(item.upload_at).format('YYYY-MM-DD HH:mm:ss'),
                            createrName: item.uploader_member.name,
                        },
                    ])
                )
            );
        }
    }, [data, form]);
    useEffect(() => {
        //定时保存数据到本地
        const timer = setInterval(() => {
            const values = form.getFieldsValue();
            if (modalType === RecordListModalType.View) return;
            // if (!values.content && !values.tags) return;
            //增加filesMap
            values.filesMap = Array.from(filesMap.values()).filter((item) => !item.loading);
            setUpdateTime(Date.now());
            saveValues(
                AutoSaveType.TrackRecord,
                values,
                modalType === RecordListModalType.Edit ? data?.id : 'all'
            );
        }, 5000);
        return () => {
            clearInterval(timer);
        };
    }, [data, filesMap, form, modalType]);
    //获取本地数据
    useEffect(() => {
        if (modalType === RecordListModalType.View) return;
        const values = getValues<{
            content: string;
            tags: string[];
            filesMap: Array<TrackRecordFileItem>;
            updateTime: number;
        }>(AutoSaveType.TrackRecord, modalType === RecordListModalType.Edit ? data?.id : 'all');
        if (!values || Object.keys(values).length === 0) return;
        form.setFieldsValue({
            content: values.content,
            tags: values.tags,
        });
        setUpdateTime(values.updateTime);
        setFilesMap(new Map(values.filesMap.map((item) => [item.url, item])));
    }, [data, form, modalType]);

    const onOk = () => {
        switch (modalType) {
            case RecordListModalType.View:
                changeType && changeType(RecordListModalType.Edit);
                break;
            case RecordListModalType.Edit:
                form.submit();
                break;
            default:
                form.submit();
                break;
        }
    };
    const dispatch = useDispatch();

    const onFinish = (values: AddTrackRecordReqProps) => {
        let req: AddTrackRecordReqProps = {
            ...values,
            customer_id: customer_id,
            attachment_ids: Array.from(filesMap.values()).map((item) => item.id),
        };
        if (modalType === RecordListModalType.Edit) {
            req = {
                ...req,
                id: data?.id,
            };
            editTrackRecord(req).then((res) => {
                if (res.data) {
                    if (isList) {
                        dispatch(getRecordList(customer_id, true, refreshTags));
                    } else {
                        dispatch(getRecordList(customer_id));
                    }
                    //清除保存在本地的数据
                    saveValues(AutoSaveType.TrackRecord, {}, data?.id);
                    changeType && changeType(RecordListModalType.View);
                    message.success('编辑成功');
                    setVisible(false);
                }
            }, showError);
        } else {
            addTrackRecord(req).then((res) => {
                if (res.data) {
                    if (isList) {
                        dispatch(getRecordList(customer_id, true, refreshTags));
                    } else {
                        dispatch(getRecordList(customer_id));
                    } //清除保存在本地的数据
                    saveValues(AutoSaveType.TrackRecord, {}, 'all');
                    message.success('增加成功');
                    setVisible(false);
                }
            }, showError);
        }
    };

    const parsePDF = useCallback(
        (props: UploadRequestOption) => {
            const reader = new FileReader();
            const fileName = (props.file as any).name as string;
            //判断文件名是否重复
            if (filesMap.has(fileName)) {
                message.error('文件名重复');
                return;
            }
            setFilesMap((prev) => {
                const newMap = new Map(prev);
                newMap.set(fileName, {
                    loading: true,
                    name: fileName,
                    id: 0,
                    url: '',
                    creatTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                });
                return newMap;
            });
            reader.onload = () => {
                const data = reader.result as string;
                CommonUploadFile({
                    data_url: data,
                    file_name: fileName,
                    target_type: UploadFileType.track,
                }).then((res) => {
                    setFilesMap((prev) => {
                        const newMap = new Map(prev);
                        newMap.set(fileName, {
                            name: fileName,
                            id: res.data.id!,
                            url: res.data.url,
                            creatTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                            loading: false,
                        });
                        return newMap;
                    });
                }, showError);

                props.onSuccess?.(data);
            };
            reader.onerror = () => {
                props.onError?.(new Error('读取文件失败'));
            };
            if (typeof props.file !== 'string') {
                reader.readAsDataURL(props.file);
            }
        },
        [filesMap]
    );
    return (
        <Modal
            visible={visible}
            onCancel={() => {
                if (modalType === RecordListModalType.Edit) {
                    changeType && changeType(RecordListModalType.View);
                }
                setVisible(false);
            }}
            maskClosable={false}
            destroyOnClose={false}
            width={800}
            cancelButtonProps={{
                style: {
                    display: modalType === RecordListModalType.View ? 'none' : 'inline-block',
                },
            }}
            className="record_modal_container"
            cancelText="取消"
            onOk={onOk}
            okText={modalType === RecordListModalType.View ? '编辑' : '确定'}
        >
            <div className="content">
                {modalType === RecordListModalType.Edit && data && (
                    <div className="header">
                        <div className="header_info">{`创建时间：${moment(data.created_at).format(
                            'YYYY-MM-DD HH:mm:ss'
                        )}`}</div>
                        <div className="header_info">{`创建人：${data.creator.name}`}</div>
                    </div>
                )}
                <Form
                    style={{ marginTop: '20px' }}
                    className="record_form"
                    onFinish={onFinish}
                    form={form}
                    labelWrap
                >
                    <Form.Item
                        name="tags"
                        wrapperCol={{ span: 40 }}
                        rules={[{ required: true, message: '请选择标签' }]}
                    >
                        <Select
                            allowClear
                            mode="multiple"
                            placeholder="请选择标签"
                            disabled={modalType === RecordListModalType.View}
                        >
                            {(tags ?? []).map((item) => (
                                <Select.Option key={item.name} value={item.name}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="content"
                        className="form_desc"
                        wrapperCol={{ span: 40 }}
                        rules={[{ required: true, message: '请输入详细内容' }]}
                    >
                        <TextArea disabled={modalType === RecordListModalType.View} showCount />
                    </Form.Item>
                </Form>
                <div>附件</div>
                {Array.from(filesMap.values()).map((item) => {
                    return (
                        <FileItem
                            data={item}
                            key={item.name}
                            type={modalType}
                            loading={item.loading}
                            deleteClick={() => {
                                setFilesMap((prev) => {
                                    const newMap = new Map(prev);
                                    newMap.delete(item.name);
                                    return newMap;
                                });
                            }}
                        />
                    );
                })}
                <Upload multiple maxCount={10} customRequest={parsePDF} itemRender={() => <></>}>
                    <Button>
                        添加附件
                        <FileAddFilled />
                    </Button>
                </Upload>
                <div className="bottom_text">
                    {modalType === RecordListModalType.View
                        ? `最后修改：@${data?.updater.name} ${moment(data?.updated_at).format(
                              'YYYY-MM-DD HH:mm:ss'
                          )}`
                        : updateTime
                        ? `自动保存于：${moment(updateTime).format('YYYY-MM-DD HH:mm:ss')}`
                        : ''}
                </div>
            </div>
        </Modal>
    );
};

export default RecordListModal;
