import axios from '../../service/tools';

export enum SignScene {
    CustomerAgreement = '服务协议',
    OtherInvestment = '另类投资',
}

export const addSign = (data: { scene: SignScene; customer_id: number; file_ids: number[] }) => {
    return axios.post('/v1/admin/esign/add_sign', { data });
};

export const updateSign = (data: { file_ids: number[]; newly_signed_id: number }) => {
    return axios.post('/v1/admin/esign/update_sign', { data });
};
