import BannerConfig from '../BannerConfig';
import MorningPostHelper from '../MorningPostHelper';
import CreatePush from '../PushCenter/CreatePush';
import PushHistory from '../PushCenter/PushHistory';
import ShareLink from '../ShareMeeting/ShareLink';
import { SyncIndex } from '../SyncArticle';
import DailyConfig from './DailyConfig';

const ActivityRoutes = {
    '/app/activity/dailyConfig': DailyConfig,
    '/app/activity/morningPostHelper': MorningPostHelper,
    '/app/activity/sharelink': ShareLink,
    '/app/activity/createPush': CreatePush,
    '/app/activity/pushHistory': PushHistory,
    '/app/activity/articleSync': SyncIndex,
    '/app/activity/banner': BannerConfig,
};

export default ActivityRoutes;
