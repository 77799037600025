import axios from '../../../../service/tools';
export type TrackTagsType = { name: string }[];
export type AddTrackRecordReqProps = {
    id?: number;
    customer_id: number;
    tags: string[];
    content: string;
    attachment_ids: number[];
};
export interface GetRecordListReq {
    current_page: number;
    page_size: number;
    tags: string[];
    customer_id: number;
}
export interface PersonInfo {
    id: number;
    name: string;
    mobile: string;
    valid: boolean;
    huobanId: number;
    feishuId: string;
    feishuOpenId: string;
    jiraId: string;
    feishuAvatar: string;
}

export interface TrackRecordItem {
    id: number;
    customer_id: number;
    content: string;
    attachment_ids: [];
    creator_id: number;
    created_at: number;
    updated_at: number;
    rights: [];
    tags: string[];
    attachments: {
        id: number;
        type: string;
        url: string;
        uploader: string;
        class: string;
        upload_at: number;
        uploader_member: PersonInfo;
    }[];
    creator: PersonInfo;
    updater: PersonInfo;
}
export interface RecordListRes {
    total_count: number;
    total_pages: number;
    current_page: number;
    page_size: number;
    customer_track_logs: Array<TrackRecordItem>;
}
/**
 * 获取tags
 * @param
 * @returns
 */
export const getTrackRecordTags = () => {
    return axios.post<{ customer_track_tags: TrackTagsType }>(
        '/v1/admin/customer_track/tag/list',
        {}
    );
};
/**
 * 增加record
 * @param
 * @returns
 */
export const addTrackRecord = (data: AddTrackRecordReqProps) => {
    return axios.post('/v1/admin/customer_track/log/add', { data });
};
/**
 * 获取记录列表
 * @param
 * @returns
 */
export const getTrackRecordList = (data: GetRecordListReq) => {
    return axios.post('/v1/admin/customer_track/log/list', { data });
};
/**
 * 修改record
 * @param
 * @returns
 */
export const editTrackRecord = (data: AddTrackRecordReqProps) => {
    return axios.post('/v1/admin/customer_track/log/update', { data });
};
