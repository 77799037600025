import { Menu } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LookButton from '../../components/LookButton';
import { EventList, uploadEventTrack } from '../../service/requests/EventTrack';
import { AllowedMenuList } from '../../service/type';
import './index.less';
import backimg from '../../static/back.png';
import activityimg from '../../static/activity.png';
import customerimg from '../../static/customer.png';
import positionimg from '../../static/position.png';
import trans from '../../static/trans.png';
import dashboardimg from '../../static/dashboard.png';
import reconciliationimg from '../../static/reconciliation.png';

import AddTranscationModal from '../CustomerList/components/CustomerDetail/Modal/AddTranscationModal';
import { PlusOutlined } from '@ant-design/icons';
const imgMap: { [key: string]: any } = {
    设置: backimg,
    运营: activityimg,
    客户: customerimg,
    资产: positionimg,
    交易: trans,
    Dashboard: dashboardimg,
    Reconciliation: reconciliationimg,
};
interface MenuContainerProps {
    allowedMenus?: AllowedMenuList;
    onSelect: () => void;
    collapsedState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}

export default function MenuContainer(props: MenuContainerProps) {
    const { allowedMenus, onSelect } = props;
    const [collapsed] = props.collapsedState;
    const [openedKeys, setOpenedKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const history = useHistory();
    const [hasReload, setHasReload] = useState(false);
    useEffect(() => {
        // if (
        //     navigator.userAgent.match(/Mobi/i) ||
        //     navigator.userAgent.match(/Android/i) ||
        //     navigator.userAgent.match(/iPhone/i)
        // ) {
        //     setIsMobile(true);
        // }
        let allKeys: string[] = [];
        allowedMenus?.forEach((menu) => {
            if (menu.entrance === history.location.pathname) {
                setSelectedKeys([String(menu.id)]);
            }
            allKeys.push(menu.id.toString());
            if (Array.isArray(menu.children)) {
                for (const childMenu of menu.children) {
                    if (childMenu.entrance === history.location.pathname) {
                        setSelectedKeys([String(childMenu.id)]);
                    }
                }
            }
        });
        if (!hasReload && allKeys.length > 0) {
            setOpenedKeys(allKeys);
            setHasReload(true);
        }

        // setdefalutOpens(allKeys);
    }, [allowedMenus, history.location.pathname, hasReload]);

    const handleSelect = useCallback(
        (menu: AllowedMenuList[number]) => {
            if (menu.parentId === 0) {
                // setOpenedKeys([]);
            }
            if (history.location.pathname !== menu.entrance && menu.children === null) {
                uploadEventTrack(EventList.ClickMenuTab, 2, {
                    page_name: menu.name,
                });
            }
            history.push(menu.entrance);
            onSelect();
        },
        [history, onSelect]
    );

    // const dispatch = useDispatch();
    // const handleLogOut = useCallback(() => {
    //     logout().then(() => {
    //         dispatch(setLogin({}));
    //         dispatch(setHasToken(true));
    //         localStorage.removeItem('login');
    //         history.push('/login');
    //     });
    // }, [dispatch, history]);

    const [showTransactionModal, setShowTransactionModal] = useState(false);
    // const [transactionModalTitle, setTransactionModalTitle] = useState('交易信息补充');

    const renderMenu = useCallback(
        (menu: AllowedMenuList[number]) => {
            if (menu.entrance.startsWith('/app/noDisplay/')) {
                return null;
            } else {
                if (menu.name === 'Dashboard') {
                    return (
                        <Menu.Item
                            key={menu.id}
                            className="MenuContainer__MenuItem"
                            onClick={() => handleSelect(menu)}
                            icon={
                                <img
                                    src={imgMap[menu.name]}
                                    style={{ width: '24px', height: '24px' }}
                                    alt=""
                                />
                            }
                        >
                            {menu.name}
                        </Menu.Item>
                        // <Menu.SubMenu
                        //     key={menu.id}
                        //     title={menu.name}
                        //     icon={
                        //         <img
                        //             src={imgMap[menu.name]}
                        //             style={{ width: '24px', height: '24px' }}
                        //             alt=""
                        //         />
                        //     }
                        // />
                    );
                }
                return (
                    <Menu.Item
                        key={menu.id}
                        className="MenuContainer__MenuItem"
                        onClick={() => handleSelect(menu)}
                    >
                        {menu.name}
                    </Menu.Item>
                );
            }
        },
        [handleSelect]
    );

    return (
        <div className="MenuContainer bg--primary">
            <LookButton
                variant="filled"
                color={'#FFC612'}
                style={{
                    margin: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                onClick={() => {
                    setShowTransactionModal(true);
                }}
            >
                {collapsed ? <PlusOutlined /> : '新增交易'}
            </LookButton>
            <AddTranscationModal visibleState={[showTransactionModal, setShowTransactionModal]} />
            <Menu
                className="MenuContainer__Wrapper"
                mode="inline"
                // inlineCollapsed={collapsed}
                selectedKeys={selectedKeys}
                openKeys={openedKeys}
                onOpenChange={(keys) => {
                    setOpenedKeys(keys);
                }}
            >
                {allowedMenus?.map((menu) =>
                    menu.children ? (
                        <Menu.SubMenu
                            key={menu.id}
                            title={menu.name}
                            icon={
                                <img
                                    src={imgMap[menu.name]}
                                    style={{ width: '24px', height: '24px' }}
                                    alt=""
                                />
                            }
                        >
                            {menu.children.map((childMenu) => renderMenu(childMenu))}
                        </Menu.SubMenu>
                    ) : (
                        renderMenu(menu)
                    )
                )}
            </Menu>
        </div>
    );
}
