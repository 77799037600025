import { Button, Form, DatePicker, Radio, message, InputNumber } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';
import { showError } from '../../RecommendationComponent/util';
import { quoteOrderList, assetList } from '../../../service/type';
import styles from './TranscationOptionsForm.module.scss';
import { UserAccountDetailInfo } from '../../../service/requests/HistoricalTransactions';
import {
    confirmTranscationAgain,
    ConfirmTranscationReqParam,
} from '../../../service/requests/HistoricalTransactions';
import SearchProductFormItem from '../../widget/GeneralForm/SearchProductFormItem';
import {
    TranscationResultItem,
    TranscationResultWidget,
} from '../../widget/TranscationResultWidget';
import SelectAccountFormItem from '../../widget/GeneralForm/SelectAccountFormItem';
import SearchCustomUserFormItem from '../../widget/GeneralForm/SearchCustomUserFormItem';
import moment, { Moment } from 'moment';
import { MakeUuid } from '../../../utils';
import TranscationOptionSelectOrder from './TranscationOptionSelectOrder';
import { useForm } from 'antd/lib/form/Form';
import SelectPositionFormItem from '../../widget/GeneralForm/SelectPositionFormItem';
import { uploadEventTrack, EventList } from '../../../service/requests/EventTrack';
import { CustomerManageData } from '../../../service/requests/CustomerManage';

const TranscationOptionsForm = ({
    setVisible,
    originOrderData,
    setTitle,
    refreshData,
    checkHiddenTab,
}: {
    setVisible: (boolean: boolean) => void;
    setTitle: (string: string) => void;
    refreshData: () => void;
    originOrderData?: quoteOrderList['order_info_list'][number];
    checkHiddenTab?: () => void;
}) => {
    const [date, setDate] = useState<Moment | null>(moment());
    const showSelect = originOrderData == null;
    const [transcationType, setTranscationType] = useState(1);
    const [showAgain, setShowAgain] = useState(false);
    const [buttonEnble, setButtonEnble] = useState(true);
    const [closeOrderData, setCloseOrderData] = useState<
        quoteOrderList['order_info_list'][number] | null
    >(null);
    const [openOrderData, setOpenOrderData] = useState<
        quoteOrderList['order_info_list'][number] | null
    >(originOrderData ?? null);

    const orderData = useMemo<quoteOrderList['order_info_list'][number] | null>(
        () => (transcationType === 1 ? openOrderData : closeOrderData),
        [transcationType, closeOrderData, openOrderData]
    );

    const [form] = useForm();
    const [customUser, setCustomUser] = useState<CustomerManageData['pageData'][0]>();
    const [curBank, setCurBank] = useState<UserAccountDetailInfo>();
    const [curPostion, setCurPostion] = useState<assetList[number]>();

    const [params, setParams] = useState({
        action: 1,
        matched_qty: '',
        account_index: 0,
        option_premium_ratio: '',
        assetIndex: 0,
        strike_price_ratio: '',
        matched_price: '',
    });
    const [reqUUID, setReqUUID] = useState(MakeUuid());
    const onFinish = useCallback(
        (valus) => {
            setParams(valus);
            setTitle('交易信息确认');
            setShowAgain(true);
            checkHiddenTab?.();
        },
        [setTitle, checkHiddenTab]
    );
    const onPost = useCallback(() => {
        if (buttonEnble === false) {
            return;
        }
        let data: null | ConfirmTranscationReqParam = null;

        if (transcationType === 2) {
            data = {
                action: params.action ?? 1,
                matched_qty: params.matched_qty,
                client_token: reqUUID,
                isin: orderData?.isin ?? '',
                account_id: curBank!.account_id,
                matched_price: params.matched_price,
                matched_at: date!.format('YYYY-MM-DD'),
            };
        } else {
            data = {
                action: params.action ?? 1,
                order_id: orderData?.order_id.toString(),
                matched_qty: params.matched_qty,
                client_token: reqUUID,
                account_id: curBank!.account_id,
                matched_at: date!.format('YYYY-MM-DD'),
                matched_price: (
                    (Number.parseFloat(params.matched_price) *
                        Number.parseFloat(params.option_premium_ratio)) /
                    100.0
                ).toFixed(3),
                ext_fields: {
                    underlying: curPostion?.isin,
                    strike_price: (
                        (Number.parseFloat(params.matched_price) *
                            Number.parseFloat(params.strike_price_ratio)) /
                        100.0
                    ).toFixed(3),
                    underlying_matched_price: params.matched_price.toString(),
                },
            };
        }
        setButtonEnble(false);
        confirmTranscationAgain(data)
            .then(() => {
                uploadEventTrack(EventList.TransactionFormMakeDeal, 2, {
                    product_type: '期权',
                    transaction_type: transcationType === 1 ? '建仓' : '平仓',
                });
                message.success('确认成功');
                refreshData();
                setReqUUID(MakeUuid());
                setVisible(false);
            }, showError)
            .finally(() => {
                setButtonEnble(true);
            });
    }, [
        params,
        reqUUID,
        curPostion,
        buttonEnble,
        curBank,
        orderData,
        transcationType,
        date,
        refreshData,
        setVisible,
    ]);

    let openPositioonReult = [
        { name: '标的成交价', value: `${params.matched_price}  ${orderData?.currency}` },
        {
            name: '行权价',
            value:
                (
                    (Number.parseFloat(params.matched_price) *
                        Number.parseFloat(params.strike_price_ratio)) /
                    100.0
                ).toFixed(3) + ` ${orderData?.currency} (${params.strike_price_ratio}%)`,
        },
        {
            name: '每股期权费',
            value:
                (
                    (Number.parseFloat(params.matched_price) *
                        Number.parseFloat(params.option_premium_ratio)) /
                    100.0
                ).toFixed(3) + ` ${orderData?.currency} (${params.option_premium_ratio}%)`,
        },
        { name: '期限', value: orderData?.tenor ?? '' },
    ];
    let closePositionReult = [
        {
            name: '成交价',
            value:
                Number.parseFloat(params.matched_price).toFixed(3) +
                ` (${orderData?.exchangeCountry})`,
        },
    ];
    const resultArray = useMemo<Array<TranscationResultItem>>(
        () => [
            {
                name: '产品',
                value:
                    transcationType === 2
                        ? orderData?.name!
                        : `${orderData?.product}(${curPostion?.name})`,
            },
            {
                name: '交易行为',
                value: transcationType === 1 ? '建仓' : '平仓',
            },
            {
                name: '交易账户',
                value:
                    (curBank?.vendor_sub_account_id ? curBank.vendor_sub_account_id + '\n' : '') +
                    `(${curBank?.account_name})`,
            },
            ...(params.action === 1 || showSelect === false
                ? openPositioonReult
                : closePositionReult),
            {
                name: '数量',
                value: params.matched_qty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','),
            },
            {
                name: '成交日期',
                value: date!.format('YYYY-MM-DD'),
            },
        ],
        [
            params,
            orderData,
            transcationType,
            showSelect,
            closePositionReult,
            curBank,
            curPostion,
            date,
            openPositioonReult,
        ]
    );

    return (
        <>
            <TranscationResultWidget
                style={{ display: `${!showAgain ? 'none' : 'block'}` }}
                onFinish={onPost}
                onCancel={() => {
                    setShowAgain(false);
                    checkHiddenTab?.();
                }}
                data={resultArray}
            />
            <Form
                form={form}
                style={{ display: `${showAgain ? 'none' : 'block'}` }}
                name="TranscationOptionsForm"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                initialValues={{
                    ...orderData,
                    matched_qty: orderData?.qty,
                    action: 1,
                    create_at: date,
                }}
            >
                {showSelect === false && (
                    <Form.Item label="产品">
                        <div>{`${orderData?.product}(${orderData?.share})`}</div>
                    </Form.Item>
                )}
                {showSelect && (
                    <>
                        {' '}
                        <Form.Item label="交易行为" name="action">
                            <Radio.Group
                                onChange={(value) => {
                                    setTranscationType(value.target.value!);
                                }}
                            >
                                <Radio value={1}>建仓</Radio>
                                <Radio value={2}>平仓</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <SearchCustomUserFormItem
                            userCallback={(user) => {
                                setCustomUser(user);
                            }}
                        />
                    </>
                )}
                <SelectAccountFormItem
                    selectCallback={(originAccount) => setCurBank(originAccount)}
                    customer_id={(showSelect ? customUser?.id : orderData?.customer_id) ?? 0}
                />
                {transcationType === 2 && (
                    <SelectPositionFormItem
                        account_id={curBank?.account_id ?? ''}
                        type="options"
                        selectData={(data) => {
                            setCloseOrderData(data!);
                        }}
                    />
                )}
                {showSelect && transcationType === 1 && (
                    <>
                        <Form.Item label="选择订单"></Form.Item>
                        <TranscationOptionSelectOrder
                            userid={customUser?.id ?? 0}
                            type="options"
                            selectData={(data) => {
                                setOpenOrderData(data);
                                form.resetFields([
                                    'option_premium_ratio',
                                    'strike_price_ratio',
                                    'matched_qty',
                                ]);
                            }}
                        />
                    </>
                )}
                {transcationType === 1 && (
                    <>
                        <SearchProductFormItem
                            formName="标的股票"
                            searchType={['stock']}
                            assetListCallback={(originAsset) => setCurPostion(originAsset)}
                        />
                        <Form.Item
                            label="标的成交价"
                            name="matched_price"
                            rules={[{ required: true, message: '请输入价格' }]}
                        >
                            <InputNumber
                                min={0}
                                style={{ width: '100%' }}
                                placeholder="请输入价格"
                            />
                        </Form.Item>
                        <Form.Item label="行权价" name="strike_price_ratio">
                            <InputNumber
                                min={0}
                                style={{ width: '100%' }}
                                addonAfter="%"
                                placeholder="请输入行权价"
                                // defaultValue={orderData.strike_price_ratio}
                            />
                        </Form.Item>
                        <Form.Item label="每股期权费" name="option_premium_ratio">
                            <InputNumber
                                min={0}
                                addonAfter="%"
                                style={{ width: '100%' }}
                                placeholder="请输入期权费"
                            />
                        </Form.Item>
                    </>
                )}
                {transcationType === 2 && (
                    <Form.Item
                        label="成交价"
                        name="matched_price"
                        rules={[{ required: true, message: '请输入价格' }]}
                    >
                        <InputNumber min={0} style={{ width: '100%' }} placeholder="请输入价格" />
                    </Form.Item>
                )}
                <Form.Item label="数量" name="matched_qty">
                    <InputNumber min={0} style={{ width: '100%' }} placeholder="请输入数量" />
                </Form.Item>
                <Form.Item label="日期" name="create_at">
                    <DatePicker onChange={setDate} format="YYYY-MM-DD" />
                </Form.Item>
                <div className={styles.bottom}>
                    <Button block type="primary" htmlType="submit" style={{ marginRight: 20 }}>
                        完成
                    </Button>

                    <Button block type="text" htmlType="button" onClick={() => setVisible(false)}>
                        取消
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default TranscationOptionsForm;
