import React, { useMemo, useState } from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './index.module.scss';
import tipImg from '../../../../static/quote_bond_tip.png';
import { BondQuoteEntity } from '../../../Quotation/request';
import { BondQuoteTableColumnType, getColumns } from '../../constValue';
import { ProductQuoteBondDetailType } from '../../type';
interface QuoteAllTableProps {
    data: ProductQuoteBondDetailType;
    selectedList: (selectedList: BondQuoteEntity[]) => void;
}
const QuoteAllTable = ({ data, selectedList }: QuoteAllTableProps) => {
    const { t } = useTranslation();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(() => {
        return (
            data.quote_pool?.filter((item) => item.is_selected).map((item) => item.bond_price_id) ??
            []
        );
    });
    const columns = getColumns(data.product_type, BondQuoteTableColumnType.All);
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: BondQuoteEntity[]) => {
            setSelectedRowKeys(selectedRowKeys ?? []);
            let finalData = selectedRows.map((item) => {
                return (
                    data.selected_quotes?.find(
                        (finditem) => finditem.bond_price_id === item.bond_price_id
                    ) ?? item
                );
            });
            selectedList(finalData);
        },
        getCheckboxProps: (record: BondQuoteEntity) => ({
            disabled: data.status > 2,
            name: record.bond_price_id?.toString(),
        }),
    };
    const resultData = useMemo(() => {
        let resultData = data.quote_pool?.sort((a, b) => {
            return parseFloat(b.coupon as string) - parseFloat(a.coupon as string);
        });
        return resultData;
    }, [data.quote_pool]);
    return (
        <div className={styles.tableContainer}>
            <Table
                style={{ marginTop: '16px' }}
                columns={columns}
                dataSource={resultData}
                size="small"
                rowSelection={{
                    type: 'checkbox',
                    fixed: true,
                    ...rowSelection,
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            if (data.status > 2) return;
                            if (selectedRowKeys?.includes(record.bond_price_id)) {
                                rowSelection.onChange(
                                    selectedRowKeys?.filter((k) => k !== record.bond_price_id),
                                    selectedRowKeys
                                        ?.filter((k) => k !== record.bond_price_id)
                                        .map(
                                            (item) =>
                                                resultData?.find(
                                                    (findItem) => findItem.bond_price_id === item
                                                )!
                                        )
                                );
                            } else {
                                rowSelection.onChange(
                                    [...selectedRowKeys, record.bond_price_id],
                                    [...selectedRowKeys, record.bond_price_id].map(
                                        (item) =>
                                            resultData?.find(
                                                (findItem) => findItem.bond_price_id === item
                                            )!
                                    )
                                );
                            }
                        },
                    };
                }}
                sticky
                summary={() => (
                    <Table.Summary fixed="top">
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={columns.length + 1}>
                                <div className={styles.tips}>
                                    <img src={tipImg} alt="" />
                                    <div>{t('BondDetail_TipTitle')}</div>
                                </div>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
                pagination={false}
                rowKey="bond_price_id"
                scroll={{ x: '100%' }}
            />
        </div>
    );
};

export default QuoteAllTable;
