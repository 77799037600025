import React, { useState } from 'react';
import { Tabs } from 'antd';
import NoticeList from './NoticeList';
import NoticeAdd from './NoticeAdd';
const { TabPane } = Tabs;
const Notice = () => {
    const [index, setIndex] = useState(1);
    return (
        <Tabs
            onChange={(e) => {
                setIndex(Number.parseInt(e));
            }}
        >
            <TabPane key="1" tab="新增">
                <NoticeAdd />
            </TabPane>
            <TabPane key="2" tab="公告列表">
                <NoticeList currentIndex={index} />
            </TabPane>
        </Tabs>
    );
};
export default Notice;
