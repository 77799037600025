import { IN_APP, SET_LANG, SyncPageIndex } from '../../constants/constants';

function config(state = { lang: 'zh-CN' }, action: any) {
    const { type, lang, inApp, syncPageIndex } = action;

    if (type === SET_LANG) {
        return {
            ...state,
            lang,
        };
    } else if (type === IN_APP) {
        return {
            ...state,
            inApp,
        };
    } else if (type === SyncPageIndex) {
        return {
            ...state,
            syncPageIndex,
        };
    } else {
        return state;
    }
}

export default config;
