import { CheckOutline, RightOutline } from 'antd-mobile-icons';
import { Popup } from 'antd-mobile';
import React, { useCallback, useState } from 'react';
import './index.less';

interface MobileSelectorProps {
    options: Array<{ title: string; value: string }>;
    placeholder?: string;
    value?: string;
    defaultValue?: string;
    onChange?: (value: string) => void;
}

export default function MobileSelector(props: MobileSelectorProps) {
    const { options, defaultValue, onChange, placeholder } = props;

    const [curValue, setCurValue] = useState(defaultValue ?? undefined);
    const [visible, setVisible] = React.useState(false);

    const handleClose = useCallback(() => {
        setVisible(false);
    }, []);
    const handleSelect = useCallback(
        (value: string) => {
            setCurValue(value);
            onChange?.(value);
            setVisible(false);
        },
        [onChange]
    );

    return (
        <>
            <div onClick={() => setVisible(true)} className="MobileSelector">
                <span className="MobileSelector-label">
                    {curValue ? (
                        <span>{options.find((item) => item.value === curValue)?.title}</span>
                    ) : (
                        <span className="MobileSelector-label-placeholder">
                            {placeholder ?? '请选择一项'}
                        </span>
                    )}
                </span>
                <RightOutline className="MobileSelector-arrow-right" />
            </div>
            <Popup visible={visible} onMaskClick={handleClose} bodyClassName="MobileSelector-popup">
                <div className="MobileSelector-popup-content">
                    {options.map((option) => (
                        <div
                            className={`MobileSelector-popup-option ${
                                option.value === curValue
                                    ? 'MobileSelector-popup-option-selected'
                                    : ''
                            }`}
                            key={option.value}
                            onClick={() => handleSelect(option.value)}
                        >
                            <span>{option.title}</span>
                            {option.value === curValue && <CheckOutline />}
                        </div>
                    ))}
                </div>
            </Popup>
        </>
    );
}
