import React, { useMemo } from 'react';
import { css } from '@emotion/css';
import './index.less';

interface LookButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'outlined' | 'filled';
    color?: string;
}

function shadeColor(color: string, percent: number) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = Math.trunc(R * (1 + percent));
    G = Math.trunc(G * (1 + percent));
    B = Math.trunc(B * (1 + percent));

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    let RR = R.toString(16);
    let GG = G.toString(16);
    let BB = B.toString(16);

    RR.length === 1 && (RR = `0${RR}`);
    GG.length === 1 && (GG = `0${GG}`);
    BB.length === 1 && (BB = `0${BB}`);

    return '#' + RR + GG + BB;
}

export default function LookButton(props: LookButtonProps) {
    let { variant, color, disabled, className, children, onClick, ...otherProps } = props;
    if (typeof children === 'string' && children.length === 2) {
        if (
            encodeURIComponent(children[0]) !== children[0] &&
            encodeURIComponent(children[1]) !== children[1]
        ) {
            children = `${children[0]} ${children[1]}`;
        }
    }
    color = color ?? '#313653';
    let lighterColor = shadeColor(color, 0.1);
    let darkerColor = shadeColor(color, -0.1);

    const styles = useMemo(
        () =>
            disabled
                ? css`
                      color: rgba(0, 0, 0, 0.25);
                      border-color: #d9d9d9;
                      background-color: #f5f5f5;
                  `
                : variant === 'filled'
                ? css`
                      color: white;
                      background-color: ${color};
                      &:hover {
                          background-color: ${lighterColor};
                      }
                      &:active {
                          background-color: ${darkerColor};
                      }
                  `
                : css`
                      color: black;
                      border-color: #d9d9d9;
                      background-color: white;
                      &:hover {
                          color: ${lighterColor};
                          border-color: ${lighterColor};
                      }
                      &:active {
                          color: ${darkerColor};
                          border-color: ${darkerColor};
                      }
                  `,
        [color, darkerColor, disabled, lighterColor, variant]
    );

    return (
        <button
            disabled={disabled}
            className={`${className ?? ''} ${
                disabled ? 'LookButton--disabled' : ''
            } LookButton ${styles}`}
            onClick={onClick}
            {...otherProps}
        >
            {children}
        </button>
    );
}
