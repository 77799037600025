import React, { useCallback, useMemo } from 'react';
import { deleteAccount } from '../../../service';
import { Button, Collapse, Space, Table, message } from 'antd';
import PanelHeader from './PanelHeader';
import { useHistory } from 'react-router-dom';
import { AccountSummary, UserList } from '../../../service/type';
import { EventList, uploadEventTrack } from '../../../service/requests/EventTrack';
const { Panel } = Collapse;

const PanelComponent: React.FC<{
    setRefreshList: () => void;
    userInfor: UserList[0];
    onlyOne: boolean;
    curPage: number;
}> = ({ setRefreshList, userInfor, onlyOne, curPage }) => {
    const history = useHistory();
    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
    }, []);
    const columns = useMemo(() => {
        return [
            {
                title: '账户类型',
                dataIndex: 'account_class',
                render: (account_class: number) => (
                    <span>{account_class === 1 ? '人工录入' : '银行同步'}</span>
                ),
                width: 100,
            },
            {
                title: '银行名',
                dataIndex: 'bank_name',
                render: (text: string) => <span>{text}</span>,
                width: 100,
            },
            {
                title: '开户名',
                dataIndex: 'account_name',
            },
            {
                title: '账户号',
                dataIndex: 'vendor_account_id',
            },
            {
                title: '操作',
                key: 'action',
                filters: [
                    {
                        text: '隐藏',
                        value: 2,
                    },
                    {
                        text: '展示',
                        value: 1,
                    },
                ],
                onFilter: (value: any, record: any) => {
                    return (
                        record.status === value ||
                        (record.account_class === 2 && record.status === value)
                    );
                },
                defaultFilteredValue: ['1'],
                filtered: true,
                filterResetToDefaultFilteredValue: true,
                width: '25%',
                render: ({ uid, account_id, account_class, status }: AccountSummary) => (
                    <Space size="middle">
                        <Button
                            type="primary"
                            onClick={() => {
                                uploadEventTrack(EventList.PositionEditorAccAction, 2, {
                                    action_type: account_class === 1 ? 'edit' : 'read',
                                });
                                history.push({
                                    pathname: `${history.location.pathname}/${
                                        account_class === 1 ? 'edit' : 'read'
                                    }/${uid}/${account_id}`,
                                    state: { curPage },
                                });
                            }}
                        >
                            {account_class === 1 ? '编辑' : '查看'}
                        </Button>
                        {account_class === 1 && (
                            <>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        uploadEventTrack(EventList.PositionEditorAccAction, 2, {
                                            action_type: 'edit_record',
                                        });
                                        history.push({
                                            pathname: `${history.location.pathname}/editList/${uid}/${account_id}`,
                                            state: { curPage },
                                        });
                                    }}
                                >
                                    编辑记录
                                </Button>
                                <Button
                                    type="primary"
                                    danger
                                    onClick={() => {
                                        uploadEventTrack(EventList.PositionEditorAccAction, 2, {
                                            action_type: 'delete',
                                        });

                                        deleteAccount({
                                            uid,
                                            account_id,
                                            status: status === 1 ? 2 : 1,
                                        }).then(() => {
                                            setRefreshList();
                                            message.success(status === 1 ? '隐藏成功' : '展示成功');
                                        });
                                    }}
                                >
                                    {status === 1 ? '隐藏' : '展示'}
                                </Button>
                            </>
                        )}
                    </Space>
                ),
            },
        ];
    }, [curPage, history, setRefreshList]);

    return (
        <>
            <Collapse defaultActiveKey={onlyOne ? [userInfor.uid] : []}>
                <Panel
                    header={
                        <PanelHeader
                            onClick={handleClick}
                            userInfor={userInfor}
                            setRefreshList={setRefreshList}
                            curPage={curPage}
                        />
                    }
                    key={userInfor.uid}
                >
                    <Table
                        columns={columns}
                        dataSource={userInfor.account_summaries}
                        pagination={false}
                        rowKey="account_id"
                        scroll={{ x: true }}
                    />
                </Panel>
            </Collapse>
            {/* 显示拷贝账号，先隐藏掉 */}
            {/* {userInfor.copy_list.map((list) => (
                <CopyList userInfor={userInfor} listValue={list} setRefreshList={setRefreshList} />
            ))} */}
        </>
    );
};

export default PanelComponent;
