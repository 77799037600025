import { DatePicker, Form, Modal, Radio } from 'antd';
import moment from 'moment';
import React, { useCallback } from 'react';
import { showError } from '../../../RecommendationComponent/util';
import { CustomModalProps } from '../CustomerDetail/Modal/MakeTransactionImgModal';

import { accountReview } from './request';
const NAMELIST = [
    '全部',
    'stock',
    'cash',
    'bond',
    'reits',
    'option',
    'mutual fund',
    'private fund',
];
const VALUELIST = ['all', 'stock', 'cash', 'bond', 'reits', 'option', 'fund', 'pfund'];
const ConfigReviewModal = ({ visibleState, id }: CustomModalProps & { id: number }) => {
    const [visible, setVisible] = visibleState;
    const [form] = Form.useForm();
    const onfinish = useCallback(
        (values) => {
            let { start_time, end_time } = values;
            if (start_time) {
                start_time = start_time.format('YYYY-MM-DD');
            }
            if (end_time) {
                end_time = end_time.format('YYYY-MM-DD');
            }

            accountReview({
                customer_id: id,
                asset_type: values.asset_type,
                start_time,
                end_time,
            }).then((res) => {
                setVisible(false);
                form.resetFields();
                window.open(res.data.bi_review_url);
            }, showError);
        },
        [form, id, setVisible]
    );

    return (
        <Modal
            visible={visible}
            destroyOnClose={false}
            title="生成review链接"
            onOk={() => {
                form.submit();
            }}
            onCancel={() => {
                setVisible(false);
                form.resetFields();
            }}
        >
            <Form
                initialValues={{
                    asset_type: 'all',
                    start_time: moment().subtract(3, 'months'),
                    end_time: moment(),
                }}
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 16 }}
                labelWrap
                form={form}
                onFinish={onfinish}
            >
                <Form.Item
                    name="asset_type"
                    label="资产类别"
                    rules={[{ required: true, message: '请选择资产类别' }]}
                >
                    <Radio.Group>
                        {NAMELIST.map((item, index) => (
                            <Radio key={index} value={VALUELIST[index]}>
                                {item}
                            </Radio>
                        ))}
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="start_time"
                    label="开始时间"
                    rules={[{ required: true, message: '请选择开始时间' }]}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    name="end_time"
                    label="结束时间"
                    rules={[{ required: true, message: '请选择结束时间' }]}
                >
                    <DatePicker />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ConfigReviewModal;
