import axios from '../tools';
export type UserPersonalInfo = {
    name: string;
    avatar_url: string;
    app_login_info: { uid: string; token: string; login_at: number };
    im_token: {
        expiredTs: number;
        token: string;
    };
};
export const requestPersonalInfo = () => {
    return axios.post<UserPersonalInfo>('/v1/admin/info', {});
};
