import { Button, message, Modal, Typography } from 'antd';
import { Formik } from 'formik';
import { Form, Select } from 'formik-antd';
import React, { useState } from 'react';
import { addPosition, sellTradePosition } from '../../../../../service';
import { AssetForSell, AssetModelProps } from '../EditAssets';
import { formatNum, FormInputNumber, FormSelect } from './FormComponents';

interface DeleteSellAssetModalProps extends AssetModelProps {
    assetChoices: AssetForSell[];
    type: string;
}

export default function DeleteSellAssetModal(props: DeleteSellAssetModalProps) {
    const { visible, setVisible, uid, accountId, handleRefreshList, assetChoices, type } = props;

    const [curAsset, setCurAsset] = useState<AssetForSell>();
    const handleSelectAsset = (name: any) => {
        setCurAsset(assetChoices.find((asset) => asset.name === name));
    };

    const typeName = type === 'delete' ? '转出' : '卖出';

    return (
        <Formik
            initialValues={{
                name: null,
                price: 0,
                amount: 0,
            }}
            onSubmit={(values, formikBag) => {
                if (curAsset) {
                    if (type === 'sell') {
                        sellTradePosition({
                            uid,
                            isin: curAsset.isin,
                            qty: values.amount.toString(),
                            account_id: accountId,
                            deal_price: values.price.toString(),
                        })
                            .then(() => {
                                message.success('卖出成功');
                                handleRefreshList();

                                formikBag.resetForm();
                                setCurAsset(undefined);
                                setVisible(false);
                            })
                            .catch(() => {
                                message.error('卖出失败');
                            });
                    } else {
                        addPosition({
                            uid,
                            account_id: accountId,
                            isin: curAsset.isin,
                            qty: curAsset.sub_asset_class === 'struct' ? -1 : -values.amount,
                            // cost_price: curAsset.costPrice,
                            cost_price: curAsset.c_cost_price,
                        })
                            .then(() => {
                                message.success('转出成功');
                                handleRefreshList();
                                formikBag.resetForm();
                                setCurAsset(undefined);
                                setVisible(false);
                            })
                            .catch(() => {
                                message.error('转出失败');
                            });
                    }
                }
            }}
            validate={(values) => {
                const errors: { [key: string]: string } = {};

                if (!values.name) {
                    errors['name'] = '请输入产品名称';
                }
                if (curAsset?.sub_asset_class === 'struct') {
                    return;
                }
                if (values.amount <= 0) {
                    errors['amount'] = '请输入一个正值';
                }
                if (type === 'sell' && values.price <= 0) {
                    errors['price'] = '请输入一个正值';
                }
                if (values.amount && curAsset && values.amount! > curAsset.quantity) {
                    errors['amount'] = '数量不能超过最大值';
                }
                return errors;
            }}
        >
            {({ submitForm, resetForm }) => (
                <Modal
                    visible={visible}
                    footer={null}
                    onCancel={() => {
                        resetForm();
                        setCurAsset(undefined);
                        setVisible(false);
                    }}
                    centered
                    width={600}
                    title={`${typeName}产品`}
                >
                    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
                        <FormSelect
                            name="name"
                            label="产品名称"
                            defaultActiveFirstOption={false}
                            onSelect={handleSelectAsset}
                        >
                            {assetChoices.map((asset) => (
                                <Select.Option value={asset.name} key={asset.isin}>
                                    {asset.name}
                                </Select.Option>
                            ))}
                        </FormSelect>

                        {type === 'sell' ? (
                            <FormInputNumber
                                name="price"
                                label="卖出价"
                                placeholder="输入单位卖出价"
                                min={0}
                            />
                        ) : null}

                        <FormInputNumber
                            name="amount"
                            label={`${typeName}数量`}
                            placeholder="输入数量"
                            min={0}
                            style={{
                                display: 'inline-flex',
                                width: 354,
                            }}
                            formatter={(value) => formatNum(Math.floor(value as number))}
                            parser={(value) => parseFloat(value?.replaceAll(',', '') ?? '')}
                        >
                            <Typography.Text style={{ display: 'block' }}>
                                最大：{formatNum(curAsset?.quantity)}
                            </Typography.Text>
                        </FormInputNumber>
                    </Form>
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                        <Button type="primary" onClick={() => submitForm()}>
                            完成
                        </Button>
                    </div>
                </Modal>
            )}
        </Formik>
    );
}
