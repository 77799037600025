import { isNumber } from 'mathjs';
import { AssetType } from '../Transaction/type';
import { QuoteEntity } from './type';
import { AccountSummaryProps } from './component/AccountSummary';

export const configDefaultValue = (
    item: QuoteEntity,
    assetType: AssetType,
    minimum_transaction_amount?: string
): QuoteEntity => {
    let ext_info = item.ext_info ?? {};
    switch (assetType) {
        case AssetType.AQ:
        case AssetType.DQ:
        case AssetType.Option:
        case AssetType.ELN:
            ext_info = {
                ...ext_info,
                price_type: isNumber(ext_info.price_type) ? item.ext_info.price_type : 1,
            };
            break;
        case AssetType.FCN:
        case AssetType.CorporateBond:
        case AssetType.GovernmentBond:
            ext_info = {
                ...ext_info,
                nominal_amount:
                    ext_info.nominal_amount ?? minimum_transaction_amount?.replace(/,/g, ''),
                price_type: isNumber(ext_info.price_type) ? ext_info.price_type : 1,
            };
            break;
        default:
            break;
    }
    return {
        ...item,
        ext_info: {
            ...ext_info,
            nominal_amount:
                typeof ext_info.nominal_amount === 'string'
                    ? ext_info.nominal_amount?.replace(/,/g, '')
                    : ext_info.nominal_amount,
        },
    };
};
export const configDetailSummary = ({ info, assetType, selectedItem }: AccountSummaryProps) => {
    if (!info) return [];
    switch (assetType) {
        case AssetType.CorporateBond:
        case AssetType.GovernmentBond:
            return [
                {
                    name: '用户',
                    value: info.user.name,
                },
                {
                    name: '交易账户',
                    value: `${info.account.accountType}${
                        info.account.vendorSubAccountId ? '-' + info.account.vendorSubAccountId : ''
                    }`,
                },
                {
                    name: '产品类型',
                    value: AssetType[assetType!],
                },
                {
                    name: '现金余额',
                    value: `${info.balance_amount}${info.currency ?? ''}`,
                },
                {
                    name: '安全垫',
                    value: `${info.risk_rate}`,
                },
            ];

        case AssetType.ELN:
            return [
                {
                    name: '用户',
                    value: info.user.name,
                },
                {
                    name: '交易账户',
                    value: `${info.account.accountType}${
                        info.account.vendorSubAccountId ? '-' + info.account.vendorSubAccountId : ''
                    }`,
                },
                {
                    name: '产品类型',
                    value: AssetType[assetType!],
                },
                {
                    name: '安全垫',
                    value: `${info.risk_rate}`,
                },
            ];
        case AssetType.FCN:
        case AssetType.AQ:
            return [
                {
                    name: '用户',
                    value: info.user.name,
                },
                {
                    name: '交易账户',
                    value: `${info.account.accountType}${
                        info.account.vendorSubAccountId ? '-' + info.account.vendorSubAccountId : ''
                    }`,
                },
                {
                    name: '产品类型',
                    value: AssetType[assetType!],
                },
                {
                    name: '安全垫',
                    value: `${info.risk_rate}`,
                },
            ];
        case AssetType.DQ:
            return [
                {
                    name: '用户',
                    value: info.user.name,
                },
                {
                    name: '交易账户',
                    value: `${info.account.accountType}${
                        info.account.vendorSubAccountId ? '-' + info.account.vendorSubAccountId : ''
                    }`,
                },
                {
                    name: '产品类型',
                    value: AssetType[assetType!],
                },
            ];
        case AssetType.Option:
            let defaultValue = [
                {
                    name: '用户',
                    value: info.user.name,
                },
                {
                    name: '交易账户',
                    value: `${info.account.accountType}${
                        info.account.vendorSubAccountId ? '-' + info.account.vendorSubAccountId : ''
                    }`,
                },
                {
                    name: '产品类型',
                    value: AssetType[assetType!],
                },
            ];
            if (selectedItem?.ext_info) {
                let ext_info = (selectedItem?.ext_info as any) as {
                    option_side: number;
                    option_type: number;
                };
                return ext_info.option_side === 2 &&
                    (ext_info.option_type === 1 || ext_info.option_type === 3)
                    ? defaultValue
                    : [
                          {
                              name: '用户',
                              value: info.user.name,
                          },
                          {
                              name: '交易账户',
                              value: `${info.account.accountType}${
                                  info.account.vendorSubAccountId
                                      ? '-' + info.account.vendorSubAccountId
                                      : ''
                              }`,
                          },
                          {
                              name: '产品类型',
                              value: AssetType[assetType!],
                          },
                          {
                              name: '安全垫',
                              value: `${info.risk_rate}`,
                          },
                      ];
            } else {
                return defaultValue;
            }
        default:
            break;
    }
    return [];
};
