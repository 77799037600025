import React from 'react';
import styles from './LineChart.module.scss';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';

interface BarChartProps {
    title: string;
    time: string;
    data: any;
}
const LineChart = (props: BarChartProps) => {
    const { title, time, data } = props;
    const option = {
        tooltip: {
            trigger: 'axis',
        },
        xAxis: {
            type: 'category',
            data: data.cell_values[1],
            boundaryGap: false,
            axisLabel: {
                color: '#aaa',
            },
            axisLine: {
                lineStyle: {
                    color: '#ddd',
                },
            },
        },
        yAxis: {
            name: '单位:人',
            type: 'value',
            axisLabel: {
                color: '#aaa',
            },
            axisLine: {
                lineStyle: {
                    color: '#ddd',
                },
            },
            splitLine: {
                lineStyle: {
                    type: 'dashed',
                },
            },
        },
        legend: {
            data: ['全部', '潜客', '开户中', '入金完成'],
            bottom: 5,
            textStyle: {
                color: '#aaa',
            },
        },
        grid: {
            top: '40px',
            bottom: '60x',
            left: '40px',
            right: '40px',
        },
        series: [
            {
                name: '全部',
                type: 'line',
                data: data.cell_values[5],
                itemStyle: {
                    opacity: 0,
                    color: '#F66B40',
                },
            },
            {
                name: '潜客',
                type: 'line',
                data: data.cell_values[4],
                itemStyle: {
                    opacity: 0,
                    color: '#FFC612',
                },
            },
            {
                name: '开户中',
                type: 'line',
                data: data.cell_values[3],
                itemStyle: {
                    opacity: 0,
                    color: '#4CAF50',
                },
            },
            {
                name: '入金完成',
                type: 'line',
                data: data.cell_values[2],
                itemStyle: {
                    opacity: 0,
                    color: '#409AE9',
                },
            },
        ],
    };
    return (
        <div className={styles.pannel}>
            <div className={styles.pannelTitle}>{title}</div>
            <div className={styles.time}>{time}</div>
            <div>
                <ReactEChartsCore echarts={echarts} option={option} style={{ height: '260px' }} />
            </div>
        </div>
    );
};

export default LineChart;
