/**
 * Created by hao.cheng on 2017/4/16.
 */
import React from 'react';
import LtvTable from './components/LtvTable/index';
import './index.scss';

function LTV() {
    return (
        <div className="ltv-contain">
            <LtvTable />
        </div>
    );
}

export default LTV;
