import { SET_PAGE_STATE } from '../../constants/constants';
import { PageState, RoutePageState, SetPageStateAction } from '../../type';

const initialState: RoutePageState = {
    appcustomerimmanage: {
        page: 0,
        searchKeyword: '',
        // 其他页面状态
    },
};

function pageState(state = initialState, action: SetPageStateAction<keyof PageState>) {
    switch (action.type) {
        case SET_PAGE_STATE:
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key],
                    ...action.payload.value,
                },
            };
        default:
            return state;
    }
}

export default pageState;
