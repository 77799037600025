import React, { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { searchProduct } from '../../../../request';
import { Asset, AssetType } from '../../../../type';
import { Form, FormInstance, Select } from 'antd';
import { GinkgoInput, GinkgoSelect } from './GinkgoFieldItems';
const { Option } = Select;
const SearchTickerItem = ({
    readOnly,
    form,
    width,
    required,
    onTickerSelect,
}: {
    readOnly?: boolean;
    required?: boolean;
    form?: FormInstance;
    width?: string;
    onTickerSelect?: (asset: Asset | undefined) => void;
}) => {
    const [currentProduct, setCurrentProduct] = useState<Asset>();
    const [searchKey, setSearchKey] = useState<string>('');
    const { data: productList, run, cancel } = useRequest(searchProduct, {
        manual: true,
    });
    useEffect(() => {
        searchKey &&
            run({
                asset_types: [AssetType.Equities],
                ticker: searchKey,
            });
        return () => {
            cancel();
        };
    }, [cancel, run, searchKey]);
    useEffect(() => {
        if (form && currentProduct) {
            form.setFieldsValue({
                ext_info: {
                    ...form.getFieldValue('ext_info'),
                    isin: currentProduct.isin,
                    name: currentProduct.name,
                    currency: currentProduct.currency,
                },
            });
        }
    }, [form, currentProduct]);
    return (
        <>
            <Form.Item
                label="Ticker"
                name={['ext_info', 'ticker']}
                rules={[{ required: required, message: 'Ticker is required' }]}
            >
                <GinkgoSelect
                    showSearch
                    readOnly={readOnly}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    notFoundContent={null}
                    placeholder={'Enter to search'}
                    style={{ width: width }}
                    onSearch={(value) => setSearchKey(value)}
                    onSelect={(value: string) => {
                        const product = productList?.data?.assets?.find(
                            (item) => item.ticker === value
                        );
                        setCurrentProduct(product);
                        onTickerSelect && onTickerSelect(product);
                    }}
                >
                    {(productList?.data?.assets ?? []).map((asset) => {
                        return (
                            <Option key={asset.isin} value={asset.ticker}>
                                {asset.ticker}
                            </Option>
                        );
                    })}
                </GinkgoSelect>
            </Form.Item>
            <Form.Item
                name={['ext_info', 'isin']}
                label="ISIN"
                rules={[{ required: required, message: 'ISIN is required' }]}
            >
                <GinkgoInput style={{ width: width }} readOnly />
            </Form.Item>
            <Form.Item
                name={['ext_info', 'name']}
                label="Name"
                rules={[{ required: required, message: 'Name is required' }]}
            >
                <GinkgoInput style={{ width: width }} readOnly />
            </Form.Item>
            <Form.Item
                name={['ext_info', 'currency']}
                label="Currency"
                rules={[{ required: required, message: 'Currency is required' }]}
            >
                <GinkgoInput readOnly style={{ width: width }} />
            </Form.Item>
        </>
    );
};

export default SearchTickerItem;
