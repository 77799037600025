import { useSearchParams } from '@src/utils/Hooks/useSearchParams';
import styles from './index.module.scss';
import React, { useEffect } from 'react';
import { Button, Form, Modal, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { bannerConfigSave, bannerDetail } from './type';
import { GinkgoInput } from '../Transaction/components/TransForm/Forms/FormItems/GinkgoFieldItems';
import ImagePickerModal from './ImagePickerModal';
const BannerDetail = () => {
    const params = useSearchParams();

    const [form] = Form.useForm();

    useEffect(() => {
        let id = params.get('id');
        if (id) {
            bannerDetail({
                banner_id: parseInt(id),
            })
                .then((value) => {
                    let obj = value.data.banner;
                    form.setFieldsValue(obj);
                })
                .catch((err) => {
                    message.error(err.msg);
                });
        }
        //eslint-disable-next-line
    }, [params]);
    const history = useHistory();

    const onFinsh = (values: any) => {
        let id = params.get('id');
        bannerConfigSave({
            ...values,
            id,
        })
            .then((res) => {
                message.success('更新成功');
                history.goBack();
            })
            .catch((err) => {
                message.error(err.msg);
            });
    };
    return (
        <div className={styles.detailContainer}>
            <Form
                form={form}
                onFinish={onFinsh}
                requiredMark={false}
                labelAlign="right"
                labelCol={{
                    style: {
                        width: '100px',
                    },
                }}
            >
                <Form.Item
                    label="Banner标题"
                    name="title"
                    rules={[{ required: true, message: '标题是必填的' }]}
                >
                    <GinkgoInput className={styles.formItem} />
                </Form.Item>
                <Form.Item
                    label="Banner链接"
                    name="doc_url"
                    rules={[
                        {
                            required: true,
                            validator(rule, value, callback) {
                                if (value && !/^(http|https):\/\/[^ "]+$/.test(value)) {
                                    return Promise.reject('请输入正确的链接');
                                }
                                if (value.length === 0) {
                                    return Promise.reject('链接是必填的');
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <GinkgoInput className={styles.formItem} />
                </Form.Item>
                <Form.Item
                    label="文章头图"
                    name="poster_url"
                    rules={[
                        {
                            required: true,
                            message: '请上传图片',
                        },
                    ]}
                >
                    <ImagePickerModal />
                </Form.Item>

                {params.get('id') !== null && (
                    <Button
                        onClick={() => {
                            Modal.confirm({
                                title: '是否取消编辑该Banner？所做更改将不会保存',
                                okText: '确认',
                                cancelText: '取消',
                                onOk: () => {
                                    history.goBack();
                                },
                            });
                        }}
                    >
                        取消编辑
                    </Button>
                )}
                <Button type="primary" htmlType="submit">
                    设置banner
                </Button>
            </Form>
        </div>
    );
};

export default BannerDetail;
