import React, { useMemo } from 'react';
import styles from './DataAudit.module.scss';
import { assetQueryPendingType, UnreviewAccount_list } from '../../../../../service/type';
import { assetQueryPending } from '../../../../../service';
import Detail from '../Detail';
import useFetch from '../../../../../utils/Hooks/useFetch';
import OverdueItem from '../OverdueItem';

const DataAudit: React.FC<{
    account: UnreviewAccount_list['account_summaries'][0];
    setFreshData: () => void;
}> = ({ account, setFreshData }) => {
    const [fetchState, , setFreshAudit] = useFetch({
        interfaces: assetQueryPending,
        initialParams: {
            account_id: account.account_id,
            update_date: account.update_date,
        },
    });

    const [pendingPositionList, curPositionList] = useMemo(() => {
        if (fetchState.data) {
            let pendingBalanceArray = changeBalanceListToPosition(
                fetchState.data.pending_asset.balances
            );
            let curBalanceArray = changeBalanceListToPosition(fetchState.data.cur_asset.balances);
            var pendingArray: assetQueryPendingType['pending_asset']['positions'] = [];
            var curArray: assetQueryPendingType['pending_asset']['positions'] = [];

            //由于增加了未交割数据所以生成自定义isin用来diff
            fetchState.data.pending_asset.positions.forEach((element) => {
                var newitem = element;
                newitem.isin = element.isin + element.delivery;
                pendingArray.push(newitem);
            });
            fetchState.data.cur_asset.positions.forEach((element) => {
                var newitem = element;
                newitem.isin = element.isin + element.delivery;
                curArray.push(newitem);
            });
            let result = [
                diffAssetList(
                    pendingBalanceArray.concat(pendingArray),
                    curBalanceArray.concat(curArray),
                    true
                ),
                diffAssetList(
                    curBalanceArray.concat(curArray),
                    pendingBalanceArray.concat(pendingArray),
                    false
                ),
            ];
            return result;
        }
        return [[], []];
    }, [fetchState.data]);

    return (
        <>
            <div className={styles.container}>
                {account && fetchState.data && (
                    <>
                        <Detail
                            pureData={fetchState.data.pending_asset}
                            type={'pending'}
                            positionList={pendingPositionList}
                            account={account}
                            setFreshData={setFreshData}
                            haveOverdue={(fetchState.data.overdue_payments ?? []).length > 0}
                        />
                        <div className={styles.division} />
                        <Detail
                            pureData={fetchState.data.cur_asset}
                            type={'current'}
                            positionList={curPositionList}
                            account={account}
                            setFreshData={setFreshData}
                        />
                    </>
                )}
            </div>
            {account && (fetchState.data?.overdue_payments ?? []).length > 0 && (
                <div className={styles.overdue}>
                    <div
                        style={{
                            fontSize: '30px',
                            lineHeight: '40px',
                            height: '40px',
                            marginBottom: '20px',
                        }}
                    >
                        请您确认以下资金是否是滞纳金
                    </div>
                    <OverdueItem
                        item={{
                            memo: '银行说明',
                            date: '日期',
                            position_id: '',
                            amount: '数量',
                            currency: '/ 货币',
                        }}
                    />
                    {(fetchState.data?.overdue_payments ?? []).map((item) => (
                        <OverdueItem
                            key={item.position_id}
                            item={{
                                ...item,
                                refreshCallBack: () => {
                                    console.log('clicksuccess');
                                    setFreshAudit();
                                },
                            }}
                        />
                    ))}
                </div>
            )}
        </>
    );
};

export default DataAudit;

type positiionItem = assetQueryPendingType['pending_asset']['positions'][0];
export interface diffedPositionsItem extends positiionItem {
    diffType: string;
    quantityChange?: number;
    sortValue?: number;
}

const diffAssetList = (
    listA: assetQueryPendingType['pending_asset']['positions'],
    listB: assetQueryPendingType['pending_asset']['positions'],
    isPending: boolean
) => {
    // 克隆 两个数组中共有的项会被删除，最后留下新增项
    const surplusItemList = listA.slice();
    //删除相同项
    const remove = createRemove(surplusItemList);
    //最后返回的数组
    const returnList: Array<diffedPositionsItem> = [];
    listB.forEach((listBItem) => {
        const listAItem = listA.find((listAItem) => listAItem.isin === listBItem.isin);
        if (listAItem) {
            //共有项
            returnList.push({
                ...listAItem,
                quantityChange: parseFloat(
                    (listAItem.c_aggr_balance - listBItem.c_aggr_balance).toFixed(2)
                ),
                diffType: 'equal',
            });
            //剔除所有公共项最后剩下新增项

            remove(listAItem.isin);
        } else {
            //删除项
            returnList.push({
                ...listBItem,
                //删除项只显示变化
                diffType: 'delete',
                c_aggr_balance: isPending ? 0 : listBItem.c_aggr_balance,
                quantityChange: -listBItem.c_aggr_balance,
            });
        }
    });
    // 新增项
    surplusItemList.forEach((surplusItem) => {
        returnList.push({
            ...surplusItem,
            diffType: 'surplus',
            quantityChange: isPending ? surplusItem.c_aggr_balance : 0,
        });
    });
    let result = returnList.sort((left, right) => {
        if (left.security_type !== right.security_type) {
            return sortMap[right.security_type] - sortMap[left.security_type];
        }
        if (left.security_type === 'cash') {
            return right.sortValue! - left.sortValue!;
        }
        if (left.isin !== right.isin) {
            return left.isin > right.isin ? 1 : -1;
        }
        return 0;
    });
    return result;
};
///合并所有现金item
const changeBalanceListToPosition = (balanceList: assetQueryPendingType['balances']) => {
    let tempPendingMap: Record<string, any> = {};
    let pendingArray: Array<positiionItem> = [];
    balanceList.forEach((subItem) => {
        //生成自定义isin 用来检索
        let customIsin = subItem.mark + subItem.currency + subItem.delivery.toString();
        if (customIsin in tempPendingMap) {
            tempPendingMap[customIsin] += subItem.balance;
        } else {
            tempPendingMap[customIsin] = subItem.balance;
        }
    });
    ///转化成positionitem来渲染
    for (const [key, val] of Object.entries(tempPendingMap)) {
        let value = parseFloat((val as number).toFixed(2));
        let obj: { isin: string; c_aggr_balance: number; security_type: string } = {
            isin: key,
            c_aggr_balance: value,
            ...resolveCustomIsin(key),
            security_type: 'cash',
        };
        pendingArray.push((obj as unknown) as diffedPositionsItem);
    }
    return pendingArray;
};
const createRemove = (list: assetQueryPendingType['pending_asset']['positions']) => {
    return (isin: string) => {
        var index = list.findIndex((item) => item.isin === isin);
        if (index > -1) {
            list.splice(index, 1);
        }
    };
};
///从自定义生成的isin解析diff时候需要的字段
const resolveCustomIsin = (origin: string) => {
    var isdelivery = origin.substr(4, 1) === '1';

    var string =
        nammMap[origin.substr(1, origin.length - 2)] +
        nammMap[origin.substr(0, 1) + origin.substr(origin.length - 1, 1)];
    var endstring =
        nammMap[origin.substr(0, 1) + origin.substr(origin.length - 1, 1)] +
        nammMap[origin.substr(1, origin.length - 2)];

    return {
        sortValue: sortValue(origin),
        name: isdelivery ? string : endstring,
    };
};
///获取排序优先级
const sortValue = (isin: string) => {
    const currency = isin.substr(1, 3);
    const mark = isin.substr(0, 1);
    const delivery = isin.substr(4, 1);
    return sortValueMap[currency] * 100 + sortValueMap[mark] * 10 + sortValueMap[delivery];
};
const sortValueMap: Record<string, number> = {
    HKD: 1,
    USD: 2,
    SGD: 3,
    CNH: 4,
    P: 2,
    N: 1,
    '2': 0,
    '1': 1,
    CHF: 5,
    EUR: 6,
    GBP: 7,
    JPY: 8,
    CNY: 9,
    AUD: 10,
    KRW: 11,
    CAD: 12,
    RUB: 13,
    NZD: 14,
};
const nammMap: Record<string, string> = {
    HKD: '港币',
    USD: '美元',
    SGD: '新币',
    CNH: '人民币',
    P1: '现金',
    N1: '贷款',
    P2: '在途',
    N2: '待支付',
    CHF: '瑞士法郎',
    EUR: '欧元',
    GBP: '英镑',
    JPY: '日元',
    CNY: '人民币',
    AUD: '澳元',
    KRW: '韩元',
    CAD: '加元',
    RUB: '卢布',
    NZD: '新西兰元',
};
const sortMap = {
    cash: 8,
    debit: 7,
    stock: 6,
    struct: 5,
    fund: 4,
    bond: 3,
    reits: 2,
    others: 1,
};
