import { getTopLevelDomain } from '../utils';

// 授权
export const LOGIN = 'v1/admin/login';
export const REPORT_URL = '/v1/misc/event_report';

interface HostUrlInterface {
    dev: string;
    stage: string;
    production: string;
}

export enum HostType {
    PB = 'PB',
    BAIKE = 'BAIKE',
    QUOTE = 'QUOTE',
    PUSH = 'PUSH',
    NOTIFY = 'NOTIFY',
    PORTAL = 'PORTAL',
    WEBIM = 'WEBIM',
    MINI = 'MINI',
}

const HostUrls: { [key in HostType]: HostUrlInterface } = {
    PB: {
        dev: 'https://wp-test.ilookcap.com/api',
        stage: 'https://pb-stage.ilookcap.com/api',
        production: 'https://pb.ilookcap.com/api',
    },
    BAIKE: {
        dev: 'https://baike-test.ilookcap.com/api',
        stage: 'https://baike-stage.ilookcap.com/api',
        production: 'https://baike.ilookcap.com/api',
    },
    PORTAL: {
        dev: 'https://portal-test.ilookcap.com/api',
        stage: 'https://portal-stage.ilookcap.com/api',
        production: 'https://portal.ilookcap.com/api',
    },
    QUOTE: {
        dev: 'https://portal-test.ilookcap.com/quote/api',
        stage: 'https://quote-stage.ilookcap.com/api',
        production: 'https://pb.ilookcap.com/api',
    },
    PUSH: {
        dev: 'https://push-test.ilookcap.com/api',
        stage: 'https://push-stage.ilookcap.com/api',
        production: 'https://push.ilookcap.com/api',
    },
    NOTIFY: {
        dev: 'https://notify-test.ilookcap.com/api',
        stage: 'https://notify-stage.ilookcap.com/api',
        production: 'https://notify.ilookcap.com/api',
    },
    WEBIM: {
        dev: 'https://imweb-test.' + getTopLevelDomain(),
        stage: 'https://imweb-stage.' + getTopLevelDomain(),
        production: 'https://imweb.' + getTopLevelDomain(),
    },
    MINI: {
        dev: 'https://uniparser-test.ilookcap.com/api',
        stage: 'https://uniparser-test.ilookcap.com/api',
        production: 'https://uniparser.ilookcap.com/api',
    },
};
export const buildWebPrefix = (hostType: HostType) => {
    let url = HostUrls[hostType][process.env.REACT_ADMIN_ENV ?? 'production'];
    return url.replace('/api', '');
};
export const WEBIMHost = HostUrls['WEBIM'][process.env.REACT_ADMIN_ENV ?? 'production'];
export const buildAxiosSetting = (hostType: HostType) => {
    let urls = HostUrls[hostType];
    return {
        host: urls[process.env.REACT_ADMIN_ENV ?? 'production'],
        /** "int, 客户端类型, 1-小程序, 2-H5，3-App, 5-web", */
        client_type: 99,
        /** token过期时间 */
        // 永久
        ttl: 1000 * 60 * 24 * 24 * 24 * 1000 * 9999999999999,
    };
};

export const md5key = '%K&_ZjXU#$NM';
