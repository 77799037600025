import { Space, Table, TableColumnType } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { LKRootState } from '../../../store/store';
import { exportExcel as downloadExcel } from '../util';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { queryManageFamilyList } from '../request';
import { PositionFamilyAsset } from '../type';
const FamilyDetail = () => {
    const exportExcel = useSelector((state: LKRootState) => state.position.state);
    const [totalList, setTotalList] = useState<PositionFamilyAsset>();
    const [contentList, setContentList] = useState<PositionFamilyAsset[]>([]);
    const history = useHistory();
    useEffect(() => {
        if (exportExcel.index === 'family' && exportExcel.refresh > 0) {
            handleExport();
        }
    }, [exportExcel]);
    const handleExport = () => {
        let elements = document.querySelectorAll('.ant-table-measure-row');
        elements.forEach((element) => {
            element.remove();
        });
        downloadExcel(
            ['Position_Family_Total_Table', 'Position_Family_Content_Table'],
            `Position-Family-${moment().format('YYYYMMDD HHmmss')}`
        );
    };
    const contentCloums: TableColumnType<PositionFamilyAsset>[] = [
        {
            title: 'Family',
            width: '16.6%',
            dataIndex: 'client_id',
            render(value, record) {
                return `${record.client.name}-${record.client.id}`;
            },
        },
        {
            title: 'Asset Value',
            width: '16.6%',
            dataIndex: 'asset_value',
            render(value, record, index) {
                return `${value} ${record.currency}`;
            },
        },
        {
            title: 'Liability Value',
            width: '16.6%',
            dataIndex: 'liability_value',
            render(value, record, index) {
                return `${value} ${record.currency}`;
            },
        },
        {
            title: 'Net Asset Value',
            width: '16.6%',
            dataIndex: 'net_asset_value',
            render(value, record, index) {
                return `${value} ${record.currency}`;
            },
        },
        {
            title: 'Net Collateral Value',
            width: '16.6%',
            dataIndex: 'net_collateral_value',
            render(value, record, index) {
                return `${value} ${record.currency}`;
            },
        },
    ];

    const totalCloums: TableColumnType<any>[] = [
        {
            title: () => (
                <div
                    style={{
                        color: 'transparent',
                    }}
                >
                    -
                </div>
            ),
            width: '16.6%',
            render(value, record, index) {
                return 'Total';
            },
        },
        {
            title: 'Asset Value',
            width: '16.6%',
            dataIndex: 'asset_value',
            render(value, record, index) {
                return `${value} ${record?.currency}`;
            },
        },
        {
            title: 'Liability Value',
            width: '16.6%',
            dataIndex: 'liability_value',
            render(value, record, index) {
                return `${value} ${record?.currency}`;
            },
        },
        {
            title: 'Net Asset Value',
            width: '16.6%',
            dataIndex: 'net_asset_value',
            render(value, record, index) {
                return `${value} ${record?.currency}`;
            },
        },
        {
            title: 'Net Collateral Value',
            width: '16.6%',
            dataIndex: 'net_collateral_value',
            render(value, record, index) {
                return `${value} ${record?.currency}`;
            },
        },
    ];
    useEffect(() => {
        queryManageFamilyList().then((value) => {
            setTotalList(value.data.total_family_position);
            setContentList(value.data.family_positions);
        });
    }, []);

    return (
        <Space direction="vertical" size="small" style={{ width: '100%' }}>
            <Table
                bordered
                dataSource={[totalList]}
                id="Position_Family_Total_Table"
                size="small"
                scroll={{ x: 1000 }}
                pagination={false}
                columns={totalCloums}
            />
            <Table
                bordered
                dataSource={contentList}
                id="Position_Family_Content_Table"
                size="small"
                pagination={false}
                scroll={{ x: 1000 }}
                columns={contentCloums}
                onRow={(record, index) => {
                    return {
                        onClick: () => {
                            history.push(
                                `/app/assetManagement/position/subAccount/all/${record.client_id}/${record.client.name}`
                            );
                        },
                    };
                }}
            />
        </Space>
    );
};

export default FamilyDetail;
