/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tabs, Form } from 'antd';
import useFetch from '../../utils/Hooks/useFetch';
import Paginate from '../../components/Paginate';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import {
    getRecordType,
    getTransactionRecord,
    RecordTypes,
    TransactionRecord,
} from '../../service/requests/RecordRequest';
import { useParams } from 'react-router-dom';
import SearchCustomUserFormItem from '../widget/GeneralForm/SearchCustomUserFormItem';
import { useForm } from 'antd/lib/form/Form';
import { EventList, uploadEventTrack } from '../../service/requests/EventTrack';

const PAGE_SIZE = 50;
const TranscationRecord = () => {
    const { customer_name } = useParams<{ customer_name: string }>();
    const [filterValue, setFilterValue] = useState(
        customer_name.includes(':') ? '' : customer_name
    );

    const [form] = useForm();
    const [activetyKey, setActivetyKey] = useState('all');
    const [recordTypes, setRecordTypes] = useState<RecordTypes['product_types']>([]);
    const [curPage, setCurPage] = useState(1);
    const [currentSelect, setCurrentSelect] = useState<
        TransactionRecord['transaction_details'][number]
    >();
    const [fetchState, setParams] = useFetch({
        interfaces: getTransactionRecord,
        initialParams: {
            current_page: curPage,
            page_size: PAGE_SIZE,
            product_type: 'all',
            customer_name: filterValue,
        },
    });
    useEffect(() => {
        if (currentSelect && fetchState.data) {
            setCurrentSelect(
                fetchState.data.transaction_details.filter(
                    (item) => item.trans_id === currentSelect.trans_id
                )[0]
            );
        }
    }, [currentSelect, fetchState.data]);
    useEffect(() => {
        getRecordType().then((res) => {
            setRecordTypes(res.data.product_types);
        });
    }, []);
    const handlePageChange = useCallback(
        (selectedItem: { selected: number }) => {
            setCurPage(selectedItem.selected + 1);
            setParams({
                current_page: selectedItem.selected + 1,
                page_size: PAGE_SIZE,
                product_type: 'all',
                customer_name: filterValue,
            });
        },
        [filterValue, setParams]
    );
    const handleFilter = useCallback(
        (name: string) => {
            setCurPage(1);
            setParams({
                current_page: 1,
                page_size: PAGE_SIZE,
                customer_name: name,
                product_type: activetyKey,
            });
        },
        [setParams, activetyKey]
    );
    const onTabChange = useCallback(
        (tabKey: string) => {
            setActivetyKey(tabKey);
            uploadEventTrack(EventList.AppRecordProductFilter, 2, {
                type: tabKey,
            });
            setParams({
                current_page: 1,
                page_size: PAGE_SIZE,
                customer_name: filterValue,
                product_type: tabKey,
            });
        },
        [filterValue, setParams]
    );
    const colDefs = useMemo<ColDef[]>(
        () => [
            {
                field: 'trade_date',
                headerName: '交易日期',
                width: 140,
                cellRenderer: (params: ICellRendererParams) => <div>{params.value}</div>,
            },

            { field: 'customer_id', headerName: '客户ID', width: 128 },
            { field: 'customer_name', headerName: '客户名', width: 128 },
            { field: 'account_name', headerName: '银行账号', width: 128 },
            { field: 'type', headerName: '交易类型', width: 128 },
            { field: 'content', headerName: '交易内容', width: 250 },
            { field: 'quantity', headerName: '成交数量', width: 128 },
            { field: 'cost', headerName: '成交价格', flex: 1, minWidth: 160 },
        ],
        []
    );

    const clearInput = useCallback(() => {
        form.resetFields();
    }, [form]);

    return (
        <>
            <Form name="AddRealAccount" form={form} requiredMark={false}>
                <SearchCustomUserFormItem
                    clear={clearInput}
                    labelStr="客户名"
                    width="70%"
                    defaultSearchValue={customer_name.includes(':') ? '' : customer_name}
                    userCallback={(user) => {
                        uploadEventTrack(EventList.AppRecordProductFilter, 2, {
                            searchValue: user?.name ?? '',
                        });
                        if (user) {
                            setFilterValue(user.name);
                            handleFilter(user.name);
                        } else {
                            setFilterValue('');
                            handleFilter('');
                        }
                    }}
                />
            </Form>
            <Tabs onChange={onTabChange} activeKey={activetyKey}>
                {recordTypes.map((item) => (
                    <Tabs.TabPane tab={item.title} key={item.key} />
                ))}
            </Tabs>
            <div className="ag-theme-alpine CustomerManage__TableContainer">
                <AgGridReact
                    animateRows
                    columnDefs={colDefs}
                    defaultColDef={{ resizable: true, minWidth: 68, suppressMenu: true }}
                    rowData={fetchState.data?.transaction_details}
                    domLayout="autoHeight"
                    enableCellTextSelection
                    ensureDomOrder
                />
                <Paginate
                    forcePage={curPage - 1}
                    pageCount={fetchState.data?.total_pages ?? 0}
                    onPageChange={handlePageChange}
                />
            </div>
        </>
    );
};

export default TranscationRecord;
