import { message } from 'antd';

export enum AutoSaveType {
    TrackRecord = 1,
}
const saveKey = {
    [AutoSaveType.TrackRecord]: 'trackRecord',
};
/**
 * @param type 保存类型
 * @param key 保存的key
 * @returns 保存的值
 */

const getValues = <T extends any>(type: AutoSaveType, key?: any) => {
    if (!localStorage.getItem(saveKey[type])) {
        return null;
    }
    if (key) {
        return JSON.parse(localStorage.getItem(saveKey[type]) as string)[key] as T;
    }
};
/**
 * @param type 保存类型
 * @param key 保存的key
 * @param values 保存的值 必须为json
 */
const saveValues = (type: AutoSaveType, values: any, key?: any) => {
    //判断values是否为json
    if (typeof values !== 'object') {
        message.error('保存的值必须为json');
        return;
    }
    if (!localStorage.getItem(saveKey[type])) {
        localStorage.setItem(saveKey[type], JSON.stringify({}));
    }
    if (key) {
        const data = JSON.parse(localStorage.getItem(saveKey[type]) as string);
        //如果values存在值则在values中记录修改时间，否则清除修改时间
        if (Object.keys(values).length > 0) {
            values[`updateTime`] = Date.now();
        } else {
            delete values[`updateTime`];
        }
        data[key] = values;
        localStorage.setItem(saveKey[type], JSON.stringify(data));
    } else {
        localStorage.setItem(saveKey[type], JSON.stringify(values));
    }
};

export { getValues, saveValues };
