import React, { useEffect } from 'react';
import styles from './index.module.scss';
import { useTranslation } from 'react-i18next';
import QuoteAllTable from '../component/QuoteAllTable';
import QuoteSubTable from '../component/QuoteSubTable';
import AccountSummary from '../component/AccountSummary';
import BondCalculate from '../component/BondCalculate';
import BondQuoteDetailActions from '../component/BondQuoteDetailActions';
import DetailSection from '../component/DetailSection';
import BondsQuotation from '../../Quotation/BondsQuotation';
import { BondQuoteEntity, BondType } from '../../Quotation/request';
import { InputNumber, Modal, Spin, message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import {
    calculateBondPrice,
    qouteConfirm,
    queryBondRecord,
    queryCashPositionSummary,
    revokeBondRecord,
    saveBondRecord,
    syncMsg,
} from '../request';
import { useRequest } from 'ahooks';
import { BondQuoteDetailPageType, ProductQuoteBondDetailType, UserAccountInfo } from '../type';
import querySearch from 'query-string';
import { showError } from '../../RecommendationComponent/util';
const BondQuoteDetail = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const { id } = querySearch.parse(location.search) as {
        id: string;
        type: BondQuoteDetailPageType;
    };
    const [price, setPrice] = React.useState<number>(0.5);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [bondDetailData, setBondDetailData] = React.useState<ProductQuoteBondDetailType>();
    const { loading: requestLoading } = useRequest(queryBondRecord, {
        defaultParams: [
            {
                product_quote_id: Number.parseInt(id),
            },
        ],
        onSuccess: (data) => {
            setBondDetailData(data.data.product_quote_detail);
        },
    });
    const { loading: calculateLoading, run: calculateRun } = useRequest(calculateBondPrice, {
        manual: true,
        onSuccess: (data) => {
            setBondDetailData(data.data.product_quote_detail);
        },
    });

    const [accountSummary, setAccountSummary] = React.useState<UserAccountInfo>();

    //获取账户相关信息
    useEffect(() => {
        if (id && !accountSummary) {
            queryCashPositionSummary({
                product_quote_id: id,
            }).then((res) => {
                setAccountSummary(res.data);
            });
        }
    }, [accountSummary, id]);

    useEffect(() => {
        setPrice(bondDetailData?.price_increased_percentage || 0.5);
    }, [bondDetailData]);

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        calculateRun({
            product_quote_detail: {
                ...bondDetailData!,
                price_increased_percentage: Number.parseFloat(e.target.value),
            },
        });
    };
    const handleAllTableSelect = (list: BondQuoteEntity[]) => {
        let finalData = list.map((item) => {
            return {
                ...item,
                nominal_amount: item.nominal_amount ?? bondDetailData!.minimum_transaction_amount,
                order_type: typeof item.order_type === 'number' ? item.order_type : 2,
                limit_price: item.limit_price ?? 100,
            };
        });
        calculateRun({
            product_quote_detail: {
                ...bondDetailData!,
                selected_quotes: finalData,
            },
        });
    };
    const handleRecommandSelect = (list: BondQuoteEntity[]) => {
        let finalData: BondQuoteEntity[] = [];
        bondDetailData?.selected_quotes?.forEach((item) => {
            list.forEach((findItem) => {
                if (findItem.id === item.bond_price_id) {
                    finalData.push(item);
                }
            });
        });

        calculateRun({
            product_quote_detail: {
                ...bondDetailData!,
                quote_pool: list?.map((item) => ({
                    ...item,
                    bond_price_id: item.id ?? 0,
                    id: undefined,
                })),
                selected_quotes: finalData ?? [],
            },
        });
    };
    const handleConfirm = () => {
        setLoading(true);
        saveBondRecord({ product_quote_detail: bondDetailData! })
            .then(() => {
                qouteConfirm({ product_quote_id: id })
                    .then(() => {
                        message.success('您已确认报价信息');
                    })
                    .catch(showError)
                    .finally(() => setLoading(false));
            })
            .catch((err) => {
                setLoading(false);
                showError({ msg: err.message });
            });
    };
    const handleSave = () => {
        setLoading(true);
        saveBondRecord({ product_quote_detail: bondDetailData! })
            .then(() => {
                message.success('保存成功');
            })
            .catch(() => {
                message.error('保存失败');
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleCancel = () => {
        Modal.confirm({
            title: '取消后，回到上次保存的数据，状态不变',
            onOk: () => {
                //reload当前页面
                window.location.reload();
            },
        });
    };
    const handleSyncApp = () => {
        saveBondRecord({ product_quote_detail: bondDetailData! });
        Modal.confirm({
            title: `点击确认后将生成【${
                bondDetailData!.product_type === BondType.corporate ? '公司债' : '国债'
            }报价】卡片，可以前往App查看或分享给客户。位置：首页 - 右上角消息中心(🔔) - 产品报价`,
            onOk: () => {
                setLoading(true);
                syncMsg({ product_quote_id: id })
                    .then(() => {
                        message.success('同步成功');
                    })
                    .catch(showError)
                    .finally(() => {
                        setLoading(false);
                    });
            },
        });
    };
    const handleRemove = () => {
        Modal.confirm({
            title: '是否确定撤销该报价',
            onOk: () => {
                setLoading(true);
                revokeBondRecord({ product_quote_id: id })
                    .then(() => {
                        message.success('撤销成功');
                        history.goBack();
                    })
                    .finally(() => setLoading(false));
            },
        });
    };
    const handleSendEmail = () => {
        history.push('/app/trade/BondQuote/email?id=' + id);
    };
    return (
        <Spin spinning={requestLoading || calculateLoading || loading}>
            <div className={styles.container}>
                {bondDetailData && (
                    <>
                        <DetailSection title={t('BondDetail_AccountInfo')}>
                            <AccountSummary
                                info={accountSummary}
                                bondType={bondDetailData.product_type}
                            />
                        </DetailSection>

                        {bondDetailData.status < 3 && (
                            <DetailSection title={'选择产品'}>
                                <BondsQuotation
                                    type={bondDetailData.product_type}
                                    defaultSelectedKeys={bondDetailData.quote_pool?.map(
                                        (item) => item.bond_price_id
                                    )}
                                    onSelectionChange={handleRecommandSelect}
                                    tableHeight={400}
                                />
                            </DetailSection>
                        )}
                    </>
                )}
                <DetailSection title={t('BondDetail_QuoteDetail')}>
                    <InputNumber
                        value={price}
                        disabled={bondDetailData?.status! > 2}
                        onChange={(value) => setPrice(value as number)}
                        onBlur={handlePriceChange}
                        addonBefore={<div>{t('price_up')}</div>}
                        addonAfter={'%'}
                        style={{ maxWidth: '220px', marginBottom: '12px' }}
                    />
                    {(bondDetailData?.fields?.length ?? 0) > 0 && (
                        <BondCalculate data={bondDetailData} onOk={handleConfirm} />
                    )}
                    {bondDetailData && (
                        <>
                            <QuoteSubTable
                                data={bondDetailData}
                                bondType={bondDetailData.product_type}
                                handleSelectCaluclate={handleAllTableSelect}
                            />
                            <QuoteAllTable
                                data={bondDetailData}
                                selectedList={handleAllTableSelect}
                            />
                        </>
                    )}
                </DetailSection>
                {bondDetailData && (
                    <BondQuoteDetailActions
                        save={handleSave}
                        cancel={handleCancel}
                        sendEmail={handleSendEmail}
                        revoke={handleRemove}
                        asyncApp={handleSyncApp}
                        status={bondDetailData.status}
                    />
                )}
            </div>
        </Spin>
    );
};

export default BondQuoteDetail;
