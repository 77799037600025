import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';

export const formatter = (res: any, sub_sec_cate_id: string | null) => {
    return {
        sec_id: res.id,
        sub_sec_cate_id,
        detail: {
            comps: [
                {
                    type: 'single_row_table',
                    title: res.name,
                    brief: res.brief,
                    id: 'basic_info',
                    data: res.necessaryData,
                },
            ].concat(
                res.optionalCard?.map((item: any) => {
                    if (item.type === 'notice_list') {
                        item.data = item.data.map((target: any) => target.value);
                    }
                    return item;
                }) || []
            ),
        },
    };
};

export const unFormatter = (security_info: { sec_id: string; detail: { comps: any[] } }) => {
    const basic_info =
        security_info.detail.comps.find((item) => item.type === 'single_row_table') || {};
    const optionalCardInfo = security_info.detail.comps
        .filter((item) => item.type !== 'single_row_table')
        .map((item) => {
            if (item.type === 'notice_list') {
                item.data = item.data.map((target: any) => ({
                    value: target,
                }));
            }
            return item;
        });
    return {
        id: security_info.sec_id,
        name: basic_info.title || '',
        brief: basic_info.brief || '',
        necessaryData: basic_info.data || [],
        optionalCard: optionalCardInfo,
    };
};

export const getBase64 = (file: RcFile) => {
    return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult: any = '';
        reader.readAsDataURL(file);
        reader.onload = function () {
            imgResult = reader.result;
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.onloadend = function () {
            resolve(imgResult);
        };
    });
};
export const stopPropagation: (event: any) => void = (event) => {
    if (event.stopPropagation) {
        event.stopPropagation();
    }
};
export const showError = ({ msg }: { msg: string }) => {
    message.error(msg);
};

export const mapCurrencySymbols = (currency: string | null | undefined) => {
    if (!currency) return '/';
    switch (currency) {
        case 'CNH':
            return '¥';
        case 'USD':
            return '$';
        case 'HKD':
            return 'HK$';
        case 'SGD':
            return 'S$';
    }
    return currency;
};
export const currenyToChinese = (currency: string | null | undefined) => {
    if (!currency) return '/';
    switch (currency) {
        case 'CNH':
            return '人民币';
        case 'USD':
            return '美元';
        case 'HKD':
            return '港币';
        case 'SGD':
            return '新币';
        case 'CHF':
            return '瑞士法郎';
    }
    return currency;
};
