import { assetList as assetListType } from '../../../service/type';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Select, message, Input } from 'antd';
import { Input as FormikInput, Form as FormikForm, Select as FormikSelect } from 'formik-antd';
import { showError } from '../../RecommendationComponent/util';

import { searchSecurity, newSecurity } from '../../../service';

const { Option } = Select;

const SearchProductFormItem = (prop: {
    assetListCallback: (assetList: assetListType[number]) => void;
    searchType: Array<string>;
    formName: string;
    children?: JSX.Element;
    isFormik?: boolean;
    defaultSearchIsin?: string;
}) => {
    const [value, setValue] = useState('');
    const [showAddProduction, setShowAddProduction] = useState(false);
    const [assetList, setAssetList] = useState<assetListType>([]);

    const handleSearch = useCallback(
        (keyword: string) => {
            if (keyword)
                searchSecurity({ keyword, sub_asset_classes: prop.searchType }).then((res) => {
                    setAssetList(res.data);
                    if (res.data.length) {
                        setShowAddProduction(false);
                    } else {
                        setShowAddProduction(true);
                    }
                }, showError);
        },
        [prop]
    );
    const options = assetList.map((item, index) => (
        <Option key={item.isin} value={index}>
            {item.name}
        </Option>
    ));
    const addNewSecurity = useCallback(() => {
        newSecurity({ isin: value }).then(() => {
            message.success('添加成功');
            setValue('');
            setShowAddProduction(false);
        }, showError);
    }, [value]);
    useEffect(() => {
        if (prop.defaultSearchIsin !== undefined) {
            handleSearch(prop.defaultSearchIsin);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prop.defaultSearchIsin]);
    const onChange = useCallback(
        (value) => {
            prop.assetListCallback(assetList[value]);
        },
        [assetList, prop]
    );

    return (
        <>
            {prop.children ?? <></>}
            {prop.isFormik === undefined ? (
                <>
                    <Form.Item
                        label={prop.formName}
                        name="assetIndex"
                        rules={[{ required: true, message: '请输入股票名称' }]}
                    >
                        <Select
                            onSelect={onChange}
                            showSearch
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={handleSearch}
                            notFoundContent={null}
                            placeholder={'搜索名称或ISIN'}
                        >
                            {options}
                        </Select>
                    </Form.Item>
                    {showAddProduction && (
                        <div style={{ marginLeft: 119, marginBottom: 30 }}>
                            <div style={{ color: '#FF0000' }}>
                                未搜索到相应资产，请输入ISIN添加资产
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span>资产ISIN：</span>{' '}
                                <Input
                                    style={{ width: 356, marginRight: 40 }}
                                    value={value}
                                    name=""
                                    onChange={(e) => setValue(e.target.value)}
                                />
                                <Button onClick={addNewSecurity}>添加</Button>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <FormikForm.Item
                        label={prop.formName}
                        name="assetIndex"
                        rules={[{ required: true, message: '请输入股票名称' }]}
                    >
                        <FormikSelect
                            name="assetIndex"
                            onSelect={onChange}
                            showSearch
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            filterOption={false}
                            onSearch={handleSearch}
                            notFoundContent={null}
                            placeholder={'搜索名称或ISIN'}
                        >
                            {options}
                        </FormikSelect>
                    </FormikForm.Item>
                    {showAddProduction && (
                        <div style={{ marginLeft: 119, marginBottom: 30 }}>
                            <div style={{ color: '#FF0000' }}>
                                未搜索到相应资产，请输入ISIN添加资产
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span>资产ISIN：</span>{' '}
                                <FormikForm>
                                    <FormikInput
                                        style={{ width: 356, marginRight: 40 }}
                                        value={value}
                                        onChange={(e) => setValue(e.target.value)}
                                        name={'aaa'}
                                    />
                                </FormikForm>
                                <Button onClick={addNewSecurity}>添加</Button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};
export default SearchProductFormItem;
