import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import AccountItem from './FormItems/AccountItem';
import { GinkgoTransFormRefType, TransStatus, TransactionInfo } from '../../../type';
import DateItem from './FormItems/DateItem';
import { Form } from 'antd';
import LKRadioGroup, { LKRadioGroupProps } from '../../../../../components/LKRadioGroup';
import RateInputItem, { RateInputType } from './FormItems/RateInputItem';
import { useForm } from 'antd/lib/form/Form';
import { GinkgoInput, GinkgoInputNumber } from './FormItems/GinkgoFieldItems';
import SearchIsinItem from './FormItems/SearchIsinItem';
import BondValidity from './FormItems/BondValidity';
import { useLatest } from 'ahooks';

const BondForm = forwardRef<GinkgoTransFormRefType, TransactionInfo>(
    (data: TransactionInfo, onRef) => {
        const [form] = useForm();
        const [priceType, setPriceType] = useState<number>();
        const [dayOrder, setDayOrder] = useState<number>();
        const [timezone, setTimezone] = useState(data.time_zone || '');
        const latestDayOrder = useLatest(dayOrder);
        useImperativeHandle(onRef, () => {
            return {
                getFieldsValues: async (validate) => {
                    if (validate === false) {
                        return new Promise((resolve) => {
                            return resolve(configValues(form.getFieldsValue()));
                        });
                    } else {
                        return form.validateFields().then(configValues);
                    }
                },
            };
        });

        const configValues = (values: any) => {
            return {
                ...values,
                client_id: data.client_id,
                transaction_type: data.type.code,
                id: data.id,
                ext_info: {
                    ...data.ext_info,
                    ...values.ext_info,
                    is_day_order: dayOrder,
                    price: values.ext_info?.price?.toString(),
                },
                status_code: data.status.code,
            };
        };

        const priceTypeOptions: LKRadioGroupProps['datasource'] = [
            {
                label: 'LMT',
                value: 1,
                width: '12.5%',
            },
            {
                label: 'MKT',
                value: 2,
                width: '12.5%',
            },
        ];
        const PlaceableReadOnly = useMemo(() => {
            return data.status.code !== TransStatus.Created;
        }, [data]);
        const TradedReadOnly = useMemo(() => {
            return data.status.code === TransStatus.Traded;
        }, [data]);
        const PlaceableRequired = useMemo(() => {
            return data.status.code === TransStatus.Created;
        }, [data]);
        const TradedRequired = useMemo(() => {
            return data.status.code === TransStatus.Placeable;
        }, [data]);

        useEffect(() => {
            setDayOrder((data.ext_info as any).is_day_order);
            setPriceType((data.ext_info as any).price_type);
            form.setFieldsValue({
                ...data,
            });
        }, [data, form]);

        return (
            <Form labelCol={{ span: 6 }} labelWrap wrapperCol={{ span: 18 }} form={form}>
                <AccountItem
                    clientId={data.client_id}
                    name={['ext_info', 'account_id']}
                    form={form}
                    readOnlyValue={data.ext_info.account_id}
                    selectCallback={(bank) => {
                        setTimezone(bank.time_zone);
                    }}
                    width="calc(62.5% - 10px)"
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                />
                <SearchIsinItem
                    form={form}
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                    width="calc(62.5% - 10px)"
                />
                <Form.Item
                    label="Quantity"
                    name={['ext_info', 'qty']}
                    rules={[{ required: PlaceableRequired, message: 'Quantity is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>

                <Form.Item
                    label="Price Type"
                    name={['ext_info', 'price_type']}
                    rules={[{ required: PlaceableRequired, message: 'Price Type is required' }]}
                >
                    <LKRadioGroup
                        datasource={priceTypeOptions}
                        onChange={(value) => {
                            setPriceType(value as number);
                        }}
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>
                {priceType === 1 && (
                    <Form.Item
                        label="Price"
                        name={['ext_info', 'price']}
                        rules={[{ required: PlaceableRequired, message: 'Price is required' }]}
                    >
                        <GinkgoInputNumber
                            style={{ width: 'calc(62.5% - 10px)' }}
                            readOnly={PlaceableReadOnly}
                        />
                    </Form.Item>
                )}
                <Form.Item
                    name={['ext_info', 'validity']}
                    label="Validity"
                    rules={[
                        {
                            required: PlaceableRequired,
                            message: 'Validity is required',
                            validator(rule, value, callback) {
                                if (latestDayOrder.current === 1) {
                                    callback();
                                    return;
                                }
                                if (!value) {
                                    callback('Validity is required');
                                    return;
                                }
                                callback();
                            },
                        },
                    ]}
                >
                    <BondValidity
                        readOnly={PlaceableReadOnly}
                        isDayOrder={(data.ext_info as any).is_day_order}
                        dayChange={(dayOrder) => {
                            setDayOrder(dayOrder ? 1 : 2);
                            form.validateFields([['ext_info', 'validity']]);
                        }}
                    />
                </Form.Item>

                <Form.Item label="Custodian Trade ID" name={['ext_info', 'custodian_trade_id']}>
                    <GinkgoInput
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <RateInputItem
                    type={RateInputType.Bps}
                    label="Spread"
                    name={['ext_info', 'spread']}
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                />

                <Form.Item
                    label="Fill Qty"
                    name={['ext_info', 'fill_qty']}
                    rules={[{ required: TradedRequired, message: 'Fill Qty is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Fill Price"
                    name={['ext_info', 'fill_price']}
                    rules={[{ required: TradedRequired, message: 'Fill Price is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Customer Price"
                    name={['ext_info', 'customer_price']}
                    rules={[{ required: TradedRequired, message: 'Customer Price is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Accrued Interest"
                    name={['ext_info', 'accrued_interest']}
                    rules={[{ required: TradedRequired, message: 'Accrued Interest is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Settlement Amount"
                    name={['ext_info', 'settlement_amount']}
                    rules={[{ required: TradedRequired, message: 'Settlement Amount is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>

                <DateItem
                    label="Trade Date"
                    name="trade_date"
                    width="calc(62.5% - 10px)"
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                    timezone={timezone}
                />
                <DateItem
                    label="Value Date"
                    name="value_date"
                    width="calc(62.5% - 10px)"
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                    timezone={timezone}
                />
            </Form>
        );
    }
);

export default BondForm;
