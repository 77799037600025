import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Typography, Button, Collapse, Popconfirm, message, Modal } from 'antd';
import { Formik } from 'formik';
import { Form, InputNumber, Select } from 'formik-antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useForceUpdate from '../../../utils/Hooks/useForceUpdate';
import { showError } from '../../RecommendationComponent/util';
import { getNotifyNodeList } from '../NotifyNode/requests';
import {
    addEventNode,
    deleteEventNode,
    getNotifyModelList,
    sortEventNodes,
    updateEventNode,
} from './requests';

export default function NotifyModel() {
    const [notifyNodes, setNotifyNodes] = useState<NotifyNode[]>();
    const [notifyModels, setNotifyModels] = useState<NotifyModel[]>();
    const [curEventId, setCurEventId] = useState<number>(0);
    const [curEditNode, setCurEditNode] = useState<NotifyModel['eventNodes'][number]>();

    useEffect(() => {
        getNotifyNodeList().then((res) => setNotifyNodes(res.data), showError);
    }, []);

    const forceUpdate = useForceUpdate(() => {
        getNotifyModelList().then((res) => setNotifyModels(res.data), showError);
    }, []);

    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (!showModal) {
            setCurEventId(0);
            setCurEditNode(undefined);
        }
    }, [showModal]);

    const columns = useMemo<ColDef[]>(
        () => [
            { headerName: '通知节点', field: 'notifyNode.name', rowDrag: true },
            { headerName: '顺延时间（秒）', field: 'delay' },
            {
                headerName: '操作',
                cellRenderer: (params: ICellRendererParams) => (
                    <>
                        <Button
                            type="primary"
                            onClick={() => {
                                setCurEventId(params.data.eventTypeId);
                                setCurEditNode(params.data);
                                setShowModal(true);
                            }}
                        >
                            编辑
                        </Button>
                        <Popconfirm
                            title="确定删除该通知节点吗？"
                            okText="确定"
                            cancelText="取消"
                            onConfirm={() =>
                                deleteEventNode({ id: params.data.id }).then(() => {
                                    message.success('删除成功');
                                    forceUpdate();
                                }, showError)
                            }
                        >
                            <Button type="primary" danger>
                                删除
                            </Button>
                        </Popconfirm>
                    </>
                ),
            },
        ],
        [forceUpdate]
    );

    const handleFormFinish = useCallback(
        (values) => {
            if (curEditNode) {
                updateEventNode(values)
                    .then(() => {
                        message.success('更新成功');
                        forceUpdate();
                    }, showError)
                    .finally(() => setShowModal(false));
            } else {
                addEventNode({
                    eventTypeId: curEventId,
                    ...values,
                })
                    .then(() => {
                        message.success('添加成功');
                        forceUpdate();
                    }, showError)
                    .finally(() => setShowModal(false));
            }
        },
        [curEditNode, curEventId, forceUpdate]
    );

    return (
        <>
            <Typography.Title level={4} style={{ margin: '16px 0 0' }}>
                事件通知模型
            </Typography.Title>
            <Collapse defaultActiveKey={['0']} style={{ marginTop: 16 }}>
                {notifyModels?.map((notifyModel, index) => (
                    <Collapse.Panel
                        key={String(index)}
                        header={
                            <div className="Notify_PanelHeader">
                                <span>{notifyModel.name}</span>
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCurEventId(notifyModel.id);
                                        setShowModal(true);
                                    }}
                                >
                                    添加事件通知节点
                                </Button>
                            </div>
                        }
                    >
                        <div className="ag-theme-alpine">
                            <AgGridReact
                                columnDefs={columns}
                                rowData={notifyModel.eventNodes}
                                domLayout="autoHeight"
                                rowDragManaged
                                animateRows
                                enableCellTextSelection
                                onRowDragEnd={({ api }) => {
                                    const oldNodeIds = notifyModel.eventNodes.map(
                                        (node) => node.id
                                    );
                                    const newNodeIds: number[] = [];
                                    api.forEachNode((node) => {
                                        newNodeIds.push(node.data.id);
                                    });
                                    let shouldUpdate = false;
                                    for (let i = 0; i < oldNodeIds.length; i++) {
                                        if (oldNodeIds[i] !== newNodeIds[i]) {
                                            shouldUpdate = true;
                                            break;
                                        }
                                    }
                                    if (shouldUpdate) {
                                        api.setSuppressRowDrag(true);
                                        api.showLoadingOverlay();
                                        sortEventNodes({
                                            eventTypeId: notifyModel.id,
                                            eventNodeIds: newNodeIds,
                                        })
                                            .then(() => {
                                                message.success('排序成功');
                                                forceUpdate();
                                            }, showError)
                                            .finally(() => {
                                                api.setSuppressRowDrag(false);
                                                api.hideOverlay();
                                            });
                                    }
                                }}
                            />
                        </div>
                    </Collapse.Panel>
                ))}
            </Collapse>
            <Modal
                title="创建事件通知节点"
                footer={null}
                destroyOnClose
                visible={showModal}
                onCancel={() => setShowModal(false)}
            >
                <Formik initialValues={curEditNode ?? { delay: 0 }} onSubmit={handleFormFinish}>
                    <Form labelCol={{ span: 6 }}>
                        <Form.Item wrapperCol={{ span: 6 }} name="event" label="事件类型">
                            <span>
                                {notifyModels?.find((model) => model.id === curEventId)?.name}
                            </span>
                        </Form.Item>
                        <Form.Item label="通知节点" name="notifyNodeId">
                            <Select name="notifyNodeId">
                                {notifyNodes?.map((notifyNode) => (
                                    <Select.Option value={notifyNode.id} key={notifyNode.id}>
                                        {notifyNode.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="顺延时间" name="delay">
                            <InputNumber name="delay" addonAfter="秒" />
                        </Form.Item>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin: '8px 0',
                            }}
                        >
                            <Button onClick={() => setShowModal(false)}>取消</Button>
                            <Button type="primary" htmlType="submit" style={{ marginLeft: 64 }}>
                                提交
                            </Button>
                        </div>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
}
