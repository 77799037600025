import { combineReducers } from 'redux';

import config from './module/config';
import login from './module/login';
import token from './module/token';
import customer from './module/customer';
import pageState from './module/page_data';
import position from './module/position';
const rootReducer = combineReducers({
    config,
    login,
    token,
    customer,
    pageState,
    position,
});

export default rootReducer;
