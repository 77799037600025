import { Button, Form, DatePicker, message, InputNumber } from 'antd';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { showError } from '../../RecommendationComponent/util';
import { quoteOrderList, assetList } from '../../../service/type';
import styles from './TranscationOptionsForm.module.scss';
import { UserAccountDetailInfo } from '../../../service/requests/HistoricalTransactions';
import {
    confirmTranscationAgain,
    ConfirmTranscationReqParam,
} from '../../../service/requests/HistoricalTransactions';
import SearchProductFormItem from '../../widget/GeneralForm/SearchProductFormItem';
import {
    TranscationResultItem,
    TranscationResultWidget,
} from '../../widget/TranscationResultWidget';
import SelectAccountFormItem from '../../widget/GeneralForm/SelectAccountFormItem';
import SearchCustomUserFormItem from '../../widget/GeneralForm/SearchCustomUserFormItem';
import moment, { Moment } from 'moment';
import { MakeUuid } from '../../../utils';
import TranscationOptionSelectOrder from './TranscationOptionSelectOrder';
import { useForm } from 'antd/lib/form/Form';
import { MobileStateContext } from '../../../App';
import { uploadEventTrack, EventList } from '../../../service/requests/EventTrack';
import { CustomerManageData } from '../../../service/requests/CustomerManage';

const TranscationFCNForm = ({
    setVisible,
    originOrderData,
    setTitle,
    refreshData,
    checkHiddenTab,
}: {
    setVisible: (boolean: boolean) => void;
    setTitle: (string: string) => void;
    refreshData: () => void;
    originOrderData?: quoteOrderList['order_info_list'][number];
    checkHiddenTab?: () => void;
}) => {
    const [date, setDate] = useState<Moment | null>(moment());
    const showSelect = originOrderData == null;
    const [showAgain, setShowAgain] = useState(false);
    const [buttonEnble, setButtonEnble] = useState(true);
    const [openOrderData, setOpenOrderData] = useState<
        quoteOrderList['order_info_list'][number] | null
    >(originOrderData ?? null);
    const orderData = useMemo<quoteOrderList['order_info_list'][number] | null>(
        () => openOrderData,
        [openOrderData]
    );
    const { IsMobileMachine } = useContext(MobileStateContext);
    const [form] = useForm();
    const [customUser, setCustomUser] = useState<CustomerManageData['pageData'][0]>();
    const [curBank, setCurBank] = useState<UserAccountDetailInfo>();
    const [curPostion, setCurPostion] = useState<assetList[number]>();

    const [params, setParams] = useState({
        qty: '',
        knock_out: '',
        account_index: 0,
        trade_amount: '',
        sold_out_days: '',
        option_premium_ratio: '',
        assetIndex: 0,
        strike_price_ratio: '',
        matched_price: '',
    });
    const [reqUUID, setReqUUID] = useState(MakeUuid());
    const onFinish = useCallback(
        (valus) => {
            setParams(valus);
            setTitle('交易信息确认');
            setShowAgain(true);
            checkHiddenTab?.();
        },
        [setTitle, checkHiddenTab]
    );
    const onPost = useCallback(() => {
        if (buttonEnble === false) {
            return;
        }
        let data: null | ConfirmTranscationReqParam = null;

        data = {
            order_id: orderData?.order_id.toString(),
            matched_qty: '0',
            client_token: reqUUID,
            occur_amount: params.trade_amount.toString(),
            account_id: curBank!.account_id,
            matched_at: date!.format('YYYY-MM-DD'),
            matched_price: '1',
            ext_fields: {
                underlyings: [curPostion?.isin],
                ko_price: (
                    (Number.parseFloat(params.matched_price) *
                        Number.parseFloat(params.knock_out)) /
                    100.0
                ).toFixed(3),
                strike_price: (
                    (Number.parseFloat(params.matched_price) *
                        Number.parseFloat(params.strike_price_ratio)) /
                    100.0
                ).toFixed(3),
            },
        };

        setButtonEnble(false);
        confirmTranscationAgain(data)
            .then(() => {
                uploadEventTrack(EventList.TransactionFormMakeDeal, 2, {
                    product_type: 'FCN',
                });
                message.success('确认成功');
                refreshData();
                setReqUUID(MakeUuid());
                setVisible(false);
            }, showError)
            .finally(() => {
                setButtonEnble(true);
            });
    }, [
        params,
        reqUUID,
        curPostion,
        buttonEnble,
        curBank,
        date,
        refreshData,
        setVisible,
        orderData,
    ]);
    const resultArray = useMemo<Array<TranscationResultItem>>(
        () => [
            {
                name: '交易产品',
                value: `${orderData?.product}(${curPostion?.name})`,
            },
            {
                name: '交易账户',
                value:
                    (curBank?.vendor_sub_account_id ? curBank.vendor_sub_account_id + '\n' : '') +
                    `(${curBank?.account_name})`,
            },
            { name: '期限', value: orderData?.tenor ?? '' },
            { name: '保证期', value: orderData?.guaranteed_period ?? '' },
            { name: '票息', value: orderData?.coupon ?? '' },
            { name: '标的成交价', value: `${params.matched_price}  ${orderData?.currency}` },
            {
                name: '敲出价',
                value:
                    (
                        (Number.parseFloat(params.matched_price) *
                            Number.parseFloat(params.knock_out)) /
                        100.0
                    ).toFixed(3) + ` ${orderData?.currency} (${params.knock_out}%)`,
            },
            {
                name: '执行价',
                value:
                    (
                        (Number.parseFloat(params.matched_price) *
                            Number.parseFloat(params.strike_price_ratio)) /
                        100.0
                    ).toFixed(3) + ` ${orderData?.currency} (${params.strike_price_ratio}%)`,
            },
            {
                name: '投资金额',
                value: params.trade_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? '',
            },
            {
                name: '成交日期',
                value: date!.format('YYYY-MM-DD'),
            },
        ],
        [params, orderData, curBank, curPostion, date]
    );

    return (
        <>
            <TranscationResultWidget
                style={{ display: `${!showAgain ? 'none' : 'block'}` }}
                onFinish={onPost}
                onCancel={() => {
                    setShowAgain(false);
                    checkHiddenTab?.();
                }}
                data={resultArray}
            />
            <Form
                form={form}
                style={{ display: `${showAgain ? 'none' : 'block'}` }}
                name="TranscationOptionsForm"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                initialValues={{
                    ...orderData,
                    matched_qty: orderData?.qty,
                    create_at: date,
                }}
            >
                {showSelect === false && (
                    <Form.Item label="产品">
                        <div>
                            {`${orderData?.product}` +
                                (orderData?.share == null ? '' : `(${orderData?.share})`)}
                        </div>
                    </Form.Item>
                )}
                {showSelect && (
                    <>
                        <SearchCustomUserFormItem
                            userCallback={(user) => {
                                setCustomUser(user);
                            }}
                        />
                    </>
                )}
                <SelectAccountFormItem
                    selectCallback={(originAccount) => setCurBank(originAccount)}
                    customer_id={(showSelect ? customUser?.id : orderData?.customer_id) ?? 0}
                />
                {showSelect && (
                    <>
                        <Form.Item label="选择订单"></Form.Item>
                        <TranscationOptionSelectOrder
                            userid={customUser?.id ?? 0}
                            type="fcn"
                            selectData={(data) => {
                                setOpenOrderData(data);
                                form.resetFields([
                                    'qty',
                                    'trade_amount',
                                    'strike_price_ratio',
                                    'knock_out',
                                ]);
                            }}
                        />
                    </>
                )}

                <SearchProductFormItem
                    formName="标的股票"
                    searchType={['stock']}
                    assetListCallback={(originAsset) => setCurPostion(originAsset)}
                />
                <Form.Item
                    label="标的成交价"
                    name="matched_price"
                    rules={[{ required: true, message: '请输入标的成交价' }]}
                >
                    <InputNumber
                        min={0}
                        style={{ width: '100%' }}
                        placeholder={
                            (orderData?.order_type ?? '') === '市价单'
                                ? '请输入成交价'
                                : orderData?.limit_order_price ?? ''
                        }
                    />
                </Form.Item>
                <Form.Item
                    label="敲出价"
                    name="knock_out"
                    rules={[{ required: true, message: '请输入敲出价' }]}
                >
                    <InputNumber
                        min={0}
                        style={{ width: '100%' }}
                        addonAfter="%"
                        placeholder="请输入敲出价"
                        // defaultValue={orderData.strike_price_ratio}
                    />
                </Form.Item>
                <Form.Item
                    label="执行价"
                    name="strike_price_ratio"
                    rules={[{ required: true, message: '请输入执行价' }]}
                >
                    <InputNumber
                        min={0}
                        style={{ width: '100%' }}
                        addonAfter="%"
                        placeholder="请输入执行价"
                        // defaultValue={orderData.strike_price_ratio}
                    />
                </Form.Item>

                <Form.Item
                    label={`投资金额`}
                    name="trade_amount"
                    rules={[{ required: true, message: `投资金额` }]}
                >
                    <InputNumber min={0} style={{ width: '100%' }} placeholder={`投资金额`} />
                </Form.Item>
                <Form.Item
                    label="成交日期"
                    name="create_at"
                    rules={[{ required: true, message: '请输入成交日期' }]}
                >
                    <DatePicker
                        inputReadOnly={IsMobileMachine}
                        onChange={setDate}
                        format="YYYY-MM-DD"
                    />
                </Form.Item>
                <div className={styles.bottom}>
                    <Button block type="primary" htmlType="submit" style={{ marginRight: 20 }}>
                        完成
                    </Button>

                    <Button block type="text" htmlType="button" onClick={() => setVisible(false)}>
                        取消
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default TranscationFCNForm;
