import { Button, Checkbox, Form, message, Modal, Popover } from 'antd';
import Cookies from 'js-cookie';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { requestPersonalInfo, UserPersonalInfo } from '../../service/requests/PersonalRequest';
import headlogo from '../../static/pb_head_logo.png';
import headmsg from '../../static/pb_head_msg.png';
import style from './LKHeader.module.scss';
import exitIcon from '../../static/head_exit.png';
import { useHistory } from 'react-router-dom';
import { getPushSettingInfo, logout, updatePushSetting } from '../../service';
import { useDispatch } from 'react-redux';
import { setHasToken, setLogin, setPortalLogin } from '../../store/actions/actions';
import { WEBIMHost } from '../../service/config';
import { EventList, uploadEventTrack } from '../../service/requests/EventTrack';
import { showError } from '../RecommendationComponent/util';
import { useForm } from 'antd/lib/form/Form';
import { CustomModalProps } from '../CustomerList/components/CustomerDetail/Modal/MakeTransactionImgModal';
import { CloseCircleOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { MobileStateContext } from '../../App';
import { getTopLevelDomain } from '../../utils';

const LKHeader = ({ visibleState }: CustomModalProps) => {
    const [userInfo, setUserInfo] = useState<UserPersonalInfo>();
    const [showModal, setShowModal] = useState(false);
    const mobileState = useContext(MobileStateContext);

    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        requestPersonalInfo().then((e) => {
            setUserInfo(e.data);
            dispatch(setPortalLogin(e.data));
            setCookie(e.data);
        });
    }, [dispatch]);
    const setCookie = (userInfo: UserPersonalInfo) => {
        let preix = process.env.REACT_ADMIN_ENV.toUpperCase();
        const domin = getTopLevelDomain();
        Cookies.set(`${preix}-AppLoginUid`, userInfo.app_login_info?.uid ?? 'testuid', {
            expires: 360,
            domain: domin,
        });
        Cookies.set(`${preix}-ImToken`, userInfo.im_token?.token ?? 'testtoken', {
            expires: 360,
            domain: domin,
        });
        Cookies.set(`AppLoginUid`, userInfo.app_login_info?.uid ?? 'testuid', {
            expires: 360,
            domain: 'localhost',
        });
        Cookies.set(`ImToken`, userInfo.im_token?.token ?? 'testtoken', {
            expires: 360,
            domain: 'localhost',
        });

        if (userInfo.app_login_info) {
            Cookies.set(`AppLoginInfo`, JSON.stringify(userInfo.app_login_info), {
                expires: 360,
                domain: 'localhost',
            });
            Cookies.set(`${preix}-AppLoginInfo`, JSON.stringify(userInfo.app_login_info), {
                expires: 360,
                domain: domin,
            });
            localStorage.setItem('AppLoginInfo', JSON.stringify(userInfo.app_login_info));
        }
    };
    const handleLogOut = useCallback(() => {
        logout().then(() => {
            dispatch(setLogin({}));
            dispatch(setHasToken(true));
            localStorage.removeItem('login');
            history.push('/login');
        });
    }, [dispatch, history]);
    const userDetail = (
        <div
            style={{
                width: '160px',
                height: '140px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                background: '#fbfbfb',
            }}
        >
            <img
                src={userInfo?.avatar_url}
                alt=""
                style={{ width: '48px', height: '48px', borderRadius: '48px', marginTop: '12px' }}
            />
            <div style={{ marginTop: '6px', fontSize: '14px', lineHeight: '20px', flex: '1' }}>
                {userInfo?.name}
            </div>

            <div
                style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    getPushSettingInfo()
                        .then((e) => {
                            form.setFieldsValue({
                                push_open: e.data.status === 1 ? ['wechat'] : [],
                            });
                        }, showError)
                        .finally(() => {
                            setShowModal(true);
                        });
                }}
            >
                账号设置
            </div>
            <Button
                type="text"
                onClick={handleLogOut}
                style={{
                    width: '100%',
                    height: '40px',
                    background: 'white',
                    border: '0px',
                    display: 'flex',
                    alignItems: 'center',
                    lineHeight: '40px',
                    justifyContent: 'center',
                }}
            >
                <img
                    src={exitIcon}
                    alt=""
                    style={{ width: '24px', height: '24px', marginRight: '4px' }}
                />
                退出登录
            </Button>
        </div>
    );
    const [form] = useForm();
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 20px',
                height: '64px',
                background: '#fff',
                boxShadow: '0px 4px 6px 0px rgba(51,51,51,0.04)',
            }}
        >
            <Modal
                visible={showModal}
                title={'账号设置'}
                okText="确定"
                cancelText="取消"
                onCancel={() => setShowModal(false)}
                onOk={() => form.submit()}
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={(values) => {
                        updatePushSetting({
                            push_open: values.push_open.includes('wechat') ? 1 : 2,
                        }).then(() => {
                            message.success('更新成功');
                            setShowModal(false);
                        }, showError);
                    }}
                >
                    <Form.Item name={'push_open'} label="设置辅助消息推送方式">
                        <Checkbox.Group>
                            <Checkbox value="wechat">通过微信公众号进行推送</Checkbox>
                        </Checkbox.Group>
                    </Form.Item>
                    <div style={{ fontSize: '12px' }}>
                        请关注“鹿可家族办公室”公众号，将由该公众号进行提醒，使用tips：
                        <span
                            onClick={() =>
                                window.open(
                                    'https://lookcapital.sg.larksuite.com/wiki/wiklgPEt3RmBmnnYu0MavMcNBHg'
                                )
                            }
                            style={{
                                color: 'blue',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                        >
                            设置微信公众号辅助推送
                        </span>
                    </div>
                </Form>
            </Modal>
            <img src={headlogo} alt="" style={{ height: '36px' }} />
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    flex: '1',
                }}
            >
                <Button
                    type="text"
                    onClick={() => {
                        if (userInfo) {
                            setCookie(userInfo);
                            window.open(WEBIMHost);
                            uploadEventTrack(EventList.MenuWebIMClick, 2);
                        } else {
                            message.error('请先刷新此页面再点击跳转');
                        }
                    }}
                    style={{ border: '0px', height: '40px' }}
                >
                    <img
                        src={headmsg}
                        alt=""
                        style={{ height: '32px', width: '32px', marginRight: '5px' }}
                    />
                </Button>
                <Popover
                    trigger="hover"
                    overlayClassName={style.popover}
                    placement="bottom"
                    content={userDetail}
                    style={{ backgroundColor: '#fbfbfb' }}
                >
                    <img
                        src={userInfo?.avatar_url}
                        alt=""
                        style={{ width: '32px', height: '32px', borderRadius: '32px' }}
                    />
                </Popover>
                {mobileState.IsMobileMachine && (
                    <Button
                        style={{ marginLeft: '20px' }}
                        icon={visibleState[0] ? <CloseCircleOutlined /> : <MenuFoldOutlined />}
                        onClick={() => visibleState[1](!visibleState[0])}
                    />
                )}
            </div>
        </div>
    );
};

export default LKHeader;
