import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import AccountItem from './FormItems/AccountItem';
import {
    Asset,
    GinkgoTransFormRefType,
    TransAQExtInfo,
    TransStatus,
    TransactionInfo,
    UpdateTransactionReq,
} from '../../../type';
import DateItem from './FormItems/DateItem';
import { Form } from 'antd';
import LKRadioGroup, { LKRadioGroupProps } from '../../../../../components/LKRadioGroup';
import { useForm } from 'antd/lib/form/Form';
import { GinkgoInput, GinkgoInputNumber } from './FormItems/GinkgoFieldItems';
import BondValidity from './FormItems/BondValidity';
import { useLatest } from 'ahooks';
import SearchUnderlying from './FormItems/SearchUnderlying';
import TenorItem from './FormItems/TenorItem';
import moment from 'moment';
const AQForm = forwardRef<GinkgoTransFormRefType, TransactionInfo>(
    (data: TransactionInfo, onRef) => {
        const [form] = useForm();
        const [priceType, setPriceType] = useState<number>();
        const [dayOrder, setDayOrder] = useState<number>();
        const latestDayOrder = useLatest(dayOrder);
        const [currentPrice, setCurrentPrice] = useState<Asset>();
        const latestCurrentPrice = useLatest(currentPrice);
        const [timezone, setTimezone] = useState(data.time_zone || '');
        useImperativeHandle(onRef, () => {
            return {
                getFieldsValues: async (validate) => {
                    if (validate === false) {
                        return new Promise((resolve) => {
                            return resolve(configValues(form.getFieldsValue()));
                        });
                    } else {
                        return form.validateFields().then(configValues);
                    }
                },
            };
        });

        const configValues = (values: any) => {
            return {
                ...values,
                client_id: data.client_id,
                transaction_type: data.type.code,
                id: data.id,
                ext_info: {
                    ...data.ext_info,
                    ...values.ext_info,
                    is_day_order: dayOrder,
                    underlyings: latestCurrentPrice.current
                        ? [latestCurrentPrice.current.isin]
                        : data.ext_info.underlyings,
                    price: values.ext_info?.price?.toString(),
                    underlying_name: latestCurrentPrice.current
                        ? latestCurrentPrice.current.name
                        : data.ext_info.underlying_name,
                    underlying_spec_name: latestCurrentPrice.current
                        ? latestCurrentPrice.current.spec_name
                        : data.ext_info.underlying_spec_name,
                    underlying_open_price: latestCurrentPrice.current
                        ? latestCurrentPrice.current.underlying_open_price
                        : data.ext_info.underlying_open_price,
                },
                status_code: data.status.code,
            };
        };

        const priceTypeOptions: LKRadioGroupProps['datasource'] = [
            {
                label: 'LMT',
                value: 1,
                width: '12.5%',
            },
            {
                label: 'MKT',
                value: 2,
                width: '12.5%',
            },
            {
                label: 'Market Close',
                value: 3,
                width: '25%',
            },
            {
                label: 'Market Open',
                value: 4,
                width: '25%',
            },
            {
                label: 'VWAP',
                value: 5,
                width: '12.5%',
            },
        ];

        const guaranteeTypeOptions: LKRadioGroupProps['datasource'] = [
            //No Guarantee，14D，1M，2M，3M
            {
                label: 'No Guarantee',
                value: 'No Guarantee',
                width: '25%',
            },
            {
                label: '7D',
                value: '7D',
                width: '12.5%',
            },
            {
                label: '14D',
                value: '14D',
                width: '12.5%',
            },
            {
                label: '1M',
                value: '1M',
                width: '12.5%',
            },
            {
                label: '2M',
                value: '2M',
                width: '12.5%',
            },
            {
                label: '3M',

                value: '3M',
                width: '12.5%',
            },
        ];

        const obsTypeOptions: LKRadioGroupProps['datasource'] = [
            {
                label: 'Daily',
                value: 1,
                width: '12.5%',
            },
            {
                label: 'Biweekly',
                value: 2,
                width: '12.5%',
            },

            {
                label: 'Monthly',
                value: 3,
                width: '12.5%',
            },
            {
                label: 'Maturity',
                value: 4,
                width: '25%',
            },
        ];
        const cfTypeOptions: LKRadioGroupProps['datasource'] = [
            {
                label: 'Biweekly',
                value: 1,
                width: '12.5%',
            },
            {
                label: 'Monthly',
                value: 2,
                width: '12.5%',
            },
            {
                label: 'Bimonthly',
                value: 3,
                width: '12.5%',
            },
            {
                label: 'Quarterly',
                value: 4,
                width: '12.5%',
            },

            {
                label: 'Semi-annually',
                value: 5,
                width: '25%',
            },
            {
                label: 'Annually',
                value: 6,
                width: '25%',
            },
        ];
        const PlaceableReadOnly = useMemo(() => {
            return data.status.code !== TransStatus.Created;
        }, [data]);
        const TradedReadOnly = useMemo(() => {
            return data.status.code === TransStatus.Traded;
        }, [data]);
        const PlaceableRequired = useMemo(() => {
            return data.status.code === TransStatus.Created;
        }, [data]);
        const TradedRequired = useMemo(() => {
            return data.status.code === TransStatus.Placeable;
        }, [data]);
        const handleFormChange = (
            changedValues: Record<string, any>,
            allValues: UpdateTransactionReq
        ) => {
            let changeKey = Object.keys(changedValues)[0];
            const extInfo = allValues.ext_info as TransAQExtInfo;
            if (changeKey === 'ext_info') {
                changeKey = Object.keys(changedValues[changeKey])[0];
                // 1. 同步计算actual_amount
                if (
                    (changeKey === 'leverage' || changeKey === 'day_volume') &&
                    extInfo?.day_volume &&
                    extInfo.leverage
                ) {
                    extInfo!.boosted_day_volume = extInfo.day_volume * extInfo.leverage;
                }
                form.setFieldsValue({
                    ext_info: extInfo,
                });
            }
        };
        useEffect(() => {
            let ext_info = data.ext_info as TransAQExtInfo;
            setDayOrder(ext_info.is_day_order);
            setPriceType((data.ext_info as any).price_type);
            form.setFieldsValue({
                ...data,
                trade_date:
                    typeof data.trade_date === 'number' ? data.trade_date : moment().valueOf(),
            });
        }, [data, form]);
        const underlying = useMemo(() => {
            if (data) {
                return (data?.ext_info as TransAQExtInfo)?.underlyings
                    ? (data?.ext_info as TransAQExtInfo).underlyings![0]
                    : undefined;
            }
        }, [data]);
        return (
            <Form
                labelCol={{ span: 6 }}
                labelWrap
                wrapperCol={{ span: 18 }}
                form={form}
                onValuesChange={handleFormChange}
            >
                <AccountItem
                    clientId={data.client_id}
                    name={['ext_info', 'account_id']}
                    form={form}
                    readOnlyValue={data.ext_info.account_id}
                    selectCallback={(bank) => {}}
                    width="calc(62.5% - 10px)"
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                />
                <SearchUnderlying
                    withCurrency={data !== undefined}
                    form={form}
                    needPrice={data === undefined}
                    ticker={(data.ext_info as any).ticker}
                    readOnly={PlaceableReadOnly}
                    isin={underlying}
                    required={PlaceableRequired}
                    width="calc(62.5% - 10px)"
                    currentPrice={(price) => {
                        setTimezone(price?.time_zone!);
                        setCurrentPrice(price);
                        // handleChangePrice(price);
                    }}
                />
                <TenorItem
                    type="onlyMonth"
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                />
                <Form.Item
                    label="Guaranteed Period"
                    name={['ext_info', 'guaranteed_period']}
                    rules={[
                        { required: PlaceableRequired, message: 'Guaranteed Period is required' },
                    ]}
                >
                    <LKRadioGroup datasource={guaranteeTypeOptions} readOnly={PlaceableReadOnly} />
                </Form.Item>
                <Form.Item
                    label="Coupon Frequency"
                    name={['ext_info', 'coupon_frequency']}
                    rules={[
                        {
                            required: PlaceableRequired,
                            message: 'Coupon Frequency is required',
                        },
                    ]}
                >
                    <LKRadioGroup datasource={cfTypeOptions} readOnly={PlaceableReadOnly} />
                </Form.Item>
                <Form.Item
                    label="Annualized Coupon"
                    name={['ext_info', 'annualized_coupon']}
                    rules={[
                        { required: PlaceableRequired, message: 'Annualized Coupon is required' },
                    ]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        addonAfter="%"
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Strike"
                    name={['ext_info', 'strike_price_rate']}
                    rules={[{ required: PlaceableRequired, message: 'Strike is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        addonAfter="%"
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Knock out"
                    name={['ext_info', 'knock_out_price_rate']}
                    rules={[{ required: PlaceableRequired, message: 'Knock out is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        addonAfter="%"
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Observation Frequency"
                    name={['ext_info', 'observation_frequency']}
                    rules={[
                        {
                            required: PlaceableRequired,
                            message: 'Observation Frequency is required',
                        },
                    ]}
                >
                    <LKRadioGroup datasource={obsTypeOptions} readOnly={PlaceableReadOnly} />
                </Form.Item>
                <Form.Item
                    label="Amount"
                    name={['ext_info', 'amount']}
                    rules={[{ required: PlaceableRequired, message: 'Amount is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Price Type"
                    name={['ext_info', 'price_type']}
                    rules={[{ required: PlaceableRequired, message: 'Price Type is required' }]}
                >
                    <LKRadioGroup
                        datasource={priceTypeOptions}
                        onChange={(value) => {
                            setPriceType(value as number);
                        }}
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>
                {priceType === 1 && (
                    <Form.Item
                        label="Price"
                        name={['ext_info', 'price']}
                        rules={[{ required: PlaceableRequired, message: 'Price is required' }]}
                    >
                        <GinkgoInputNumber
                            style={{ width: 'calc(62.5% - 10px)' }}
                            readOnly={PlaceableReadOnly}
                        />
                    </Form.Item>
                )}
                <Form.Item
                    name={['ext_info', 'validity']}
                    label="Validity"
                    rules={[
                        {
                            required: PlaceableRequired,
                            message: 'Validity is required',
                            validator(rule, value, callback) {
                                if (latestDayOrder.current === 1) {
                                    callback();
                                    return;
                                }
                                if (!value) {
                                    callback('Validity is required');
                                    return;
                                }
                                callback();
                            },
                        },
                    ]}
                >
                    <BondValidity
                        readOnly={PlaceableReadOnly}
                        isDayOrder={(data.ext_info as any).is_day_order}
                        dayChange={(dayOrder) => {
                            setDayOrder(dayOrder ? 1 : 2);
                            form.validateFields([['ext_info', 'validity']]);
                        }}
                    />
                </Form.Item>

                <Form.Item label="Custodian Trade ID" name={['ext_info', 'custodian_trade_id']}>
                    <GinkgoInput
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>

                <Form.Item label="ISIN" name={['ext_info', 'isin']}>
                    <GinkgoInput
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Upfront(%)"
                    name={['ext_info', 'up_front_rate']}
                    rules={[{ required: TradedRequired, message: 'Upfront(%) is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                        addonAfter="%"
                    />
                </Form.Item>
                <Form.Item
                    label="Upfront($)"
                    name={['ext_info', 'up_front_amount']}
                    rules={[{ required: TradedRequired, message: 'Upfront($) is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>

                {/* <Form.Item
                    label="Face Value"
                    name={['ext_info', 'face_value']}
                    rules={[{ required: TradedRequired, message: 'Face Value is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item> */}
                <Form.Item
                    label="Fill Amount"
                    name={['ext_info', 'fill_amount']}
                    rules={[{ required: TradedRequired, message: 'Fill Amount is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Fill Price"
                    name={['ext_info', 'fill_price']}
                    rules={[{ required: TradedRequired, message: 'Fill Price is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <DateItem
                    label="Trade Date"
                    name="trade_date"
                    width="calc(62.5% - 10px)"
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                    timezone={timezone}
                />
                <DateItem
                    label="Value Date"
                    name="value_date"
                    width="calc(62.5% - 10px)"
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                    timezone={timezone}
                />
            </Form>
        );
    }
);

export default AQForm;
