import { ColumnsType } from 'antd/lib/table';
import {
    AssetType,
    ELNType,
    FundStruct,
    TransactionInfo,
    TransactionType,
} from '../Transaction/type';
import { PositionAssetDetail } from '../Position/type';
import moment from 'moment-timezone';
import { FormatNumber } from '../../utils';
import { isUndefined } from 'mathjs';
import { fcnCouponFrequency } from '../GKProductPrice/Form/data';
import { getOptionSide, getOptionType } from '../Transaction/Detail/utils';
export const formatDate = (ts: number, timezone?: string) => {
    let ret = '';
    if (ts) {
        ret = moment.tz(ts, timezone || moment.tz.guess()).format('yyyy-MM-DD');
    }
    return ret;
};
const getCouponFrequency = (fre: number) => {
    return (
        Object.keys(fcnCouponFrequency).find(
            (key) => fcnCouponFrequency[key as keyof typeof fcnCouponFrequency] === fre
        ) || ''
    );
};
const getObservationFrequency = (fre: number) => {
    switch (fre) {
        case 1:
            return '每天';
        case 2:
            return '每两周';
        case 3:
            return '每月';
        case 4:
            return '到期';
        default:
            return '--';
    }
};
export const getPositionDetail = (item: PositionAssetDetail) => {
    let ret = [] as { title: string; value: string; width?: string }[];
    let extInfo = item.position.ext_info;
    switch (item.position.asset_type.code) {
        case AssetType.FCN:
            ret = [
                //Portfolio Type、Currency、Tenor、Guaranteed Period、Strike、Knock out、 Coupon Frequency、Annualized Coupon
                // {
                //     title: 'Portfolio Type',
                //     value: item.position.ext_info['portfolio_type'],
                // },
                {
                    title: 'ISIN',
                    value: item.position.isin,
                },
                {
                    title: 'Underlying',
                    value: `${extInfo.underlying_spec_name || extInfo.underlying_name} | ${
                        extInfo.ticker || ''
                    }`,
                },
                {
                    title: 'Currency',
                    value: item.position.currency,
                },
                {
                    title: 'Tenor',
                    value: item.position.ext_info['tenor'],
                },
                {
                    title: 'Guaranteed Period',
                    value: item.position.ext_info['guaranteed_period'],
                },
                {
                    title: 'Strike',
                    value: extInfo?.strike_price_rate ? `${extInfo.strike_price_rate}%` : '',
                },
                {
                    title: 'Knock out',
                    value: extInfo?.knock_out_price_rate ? `${extInfo.knock_out_price_rate}%` : '',
                },
                {
                    title: 'Coupon Frequency',
                    value: extInfo?.coupon_frequency
                        ? getCouponFrequency(extInfo.coupon_frequency)
                        : '',
                },
                {
                    title: 'Annualized Coupon',
                    value: extInfo?.annualized_coupon ? `${extInfo.annualized_coupon}%` : '',
                },
            ];
            break;
        case AssetType.FixedDeposit: {
            ret = [
                {
                    title: 'Value Date',
                    value: isNotUndefined(extInfo?.value_date)
                        ? formatDate(extInfo?.value_date)
                        : '',
                },
                { title: 'Tenor', value: extInfo?.tenor || '' },
                {
                    title: 'Maturity Date',
                    value: isNotUndefined(extInfo?.maturity_date)
                        ? formatDate(extInfo?.maturity_date)
                        : '',
                },
                { title: 'Roll Type', value: extInfo?.roll_type || '' },
                { title: 'Currency', value: extInfo?.currency || '' },
                {
                    title: 'Dealer Rate',
                    value: isNotUndefined(extInfo?.dealer_rate)
                        ? `${(FormatNumber(parseFloat(extInfo.dealer_rate) / 100), 2)}%`
                        : '',
                },
                {
                    title: 'Spread',
                    value: isNotUndefined(extInfo?.spread)
                        ? `${FormatNumber(parseFloat(extInfo.spread) / 100, 2)}% ${
                              extInfo.spread
                          }bps`
                        : '',
                },
                {
                    title: 'Client Rate',
                    value: isNotUndefined(extInfo?.client_rate)
                        ? `${FormatNumber(parseFloat(extInfo.client_rate) / 100, 2)}%`
                        : '',
                },
                {
                    title: 'Principal Amount',
                    value: isNotUndefined(extInfo?.principal_amount)
                        ? FormatNumber(extInfo.principal_amount!, 2)
                        : '',
                },
                {
                    title: 'Interest Amount',
                    value: isNotUndefined(extInfo?.interest_amount)
                        ? FormatNumber(extInfo.interest_amount!, 2)
                        : '',
                },
                {
                    title: 'Total Amount',
                    value: isNotUndefined(extInfo?.total_amount)
                        ? FormatNumber(extInfo.total_amount!, 2)
                        : '',
                    width: '50%',
                },
            ];
            break;
        }
        case AssetType.Loan: {
            ret = [
                {
                    title: 'Value Date',
                    value: isNotUndefined(extInfo?.value_date)
                        ? formatDate(extInfo?.value_date)
                        : '',
                },
                { title: 'Tenor', value: extInfo ? extInfo.tenor : '' },
                {
                    title: 'Maturity Date',
                    value: isNotUndefined(extInfo.maturity_date)
                        ? formatDate(extInfo.maturity_date)
                        : '',
                },
                { title: 'Currency', value: extInfo.currency || '' },
                {
                    title: 'Dealer Rate',
                    value: isNotUndefined(extInfo?.dealer_rate)
                        ? `${FormatNumber(parseFloat(extInfo.dealer_rate) / 100)}%`
                        : '',
                },
                {
                    title: 'Spread',
                    value: isNotUndefined(extInfo?.spread)
                        ? `${FormatNumber(parseFloat(extInfo.spread) / 100)}% ${extInfo.spread}bps`
                        : '',
                },
                {
                    title: 'Client Rate',
                    value: isNotUndefined(extInfo?.client_rate)
                        ? `${FormatNumber(parseFloat(extInfo.client_rate) / 100)}%`
                        : '',
                },
                {
                    title: 'Principal Amount',
                    value: isNotUndefined(extInfo?.principal_amount)
                        ? FormatNumber(extInfo.principal_amount, 2)
                        : '',
                },
                {
                    title: 'Interest Amount',
                    value: isNotUndefined(extInfo?.interest_amount)
                        ? FormatNumber(extInfo.interest_amount, 2)
                        : '',
                },
                {
                    title: 'Total Amount',
                    value: isNotUndefined(extInfo?.total_amount)
                        ? FormatNumber(extInfo.total_amount, 2)
                        : '',
                },
            ];
            break;
        }
        case AssetType.Option: {
            ret = [
                {
                    title: 'ISIN',
                    value: item.position.isin || '',
                },
                {
                    title: 'Underlying',
                    value: `${extInfo.underlying_spec_name || extInfo.underlying_name} | ${
                        extInfo.ticker || ''
                    }`,
                },
                {
                    title: 'Currency',
                    value: extInfo?.currency || '',
                },
                {
                    title: 'Side',
                    value: extInfo.option_side ? `${getOptionSide(extInfo.option_side)}` : '',
                },
                {
                    title: 'Option Type',
                    value: extInfo?.option_type ? getOptionType(extInfo.option_type) : '',
                },
                {
                    title: 'Fill Option Fee(%)',
                    value: extInfo?.fill_option_fee_rate
                        ? ` ${FormatNumber(extInfo.fill_option_fee_rate, 2)}%`
                        : '',
                },
                {
                    title: 'Tenor',
                    value: extInfo?.tenor || '',
                },
                {
                    title: 'Strike',
                    value: extInfo?.strike_price_rate ? `${extInfo.strike_price_rate}%` : '',
                },
                {
                    title: 'Fill No. of Shares',
                    value: extInfo?.fill_number_of_shares?.toString() || '',
                },
            ];
            break;
        }
        case AssetType.CorporateBond:
        case AssetType.GovernmentBond: {
            ret = [
                {
                    title: 'ISIN',
                    value: item.position?.isin || '',
                },
                {
                    title: 'Issuer',
                    value: extInfo?.issuer_en || extInfo.issuer_cn || '',
                },
                {
                    title: 'Issuer CN',
                    value: extInfo?.issuer_cn || '',
                },
                {
                    title: 'Currency',
                    value: item.position.currency || '',
                },
                {
                    title: 'Coupon Rate',
                    value: extInfo?.coupon ? `${extInfo.coupon}%` : '',
                },

                {
                    title: 'Maturity Date',
                    value: isNotUndefined(extInfo?.maturity_date)
                        ? formatDate(extInfo?.maturity_date)
                        : '',
                },
                {
                    title: 'S&P',
                    value: extInfo.credit_rating_sp || '',
                },
                {
                    title: 'Seniority',
                    value: extInfo?.seniority || '',
                },
                {
                    title: 'Coupon Frequency',
                    value: extInfo?.coupon_freq || '',
                },
                {
                    title: 'Next Coupon Date',
                    value: isNotUndefined(extInfo?.next_coupon_date)
                        ? formatDate(extInfo?.next_coupon_date)
                        : '',
                },
                {
                    title: 'Prev Coupon Date',
                    value: isNotUndefined(extInfo?.prev_coupon_date)
                        ? formatDate(extInfo?.prev_coupon_date)
                        : '',
                },
                {
                    title: 'Notional',
                    value: FormatNumber(extInfo.face_value, 3) || '',
                },
                {
                    title: 'Denomination',
                    value: FormatNumber(extInfo.denomination_min, 3) || '',
                },
                {
                    title: 'Next Call Date',
                    value: isNotUndefined(extInfo?.next_call_date)
                        ? formatDate(extInfo?.next_call_date)
                        : '',
                },
                {
                    title: 'Issue Date',
                    value: isNotUndefined(extInfo?.issue_date)
                        ? formatDate(extInfo?.issue_date)
                        : '',
                },
            ];
            break;
        }
        case AssetType.ELN: {
            //Underlying、Currency、ELN Type、Issue Date、Maturity Date、Tenor、Issue、Knock out、Observation Frequency、Fill Nominal Amount、Fill Actual Amount
            ret = [
                {
                    title: 'ISIN',
                    value: item.position.isin || '',
                },
                {
                    title: 'Underlying',
                    value: `${extInfo.underlying_spec_name || extInfo.underlying_name} | ${
                        extInfo.ticker || ''
                    }`,
                },
                {
                    title: 'Currency',
                    value: extInfo?.currency || '',
                },
                {
                    title: 'ELN Type',
                    value: extInfo?.eln_type
                        ? extInfo?.eln_type === ELNType.ELN
                            ? 'ELN'
                            : '敲出ELN'
                        : '',
                },
                {
                    title: 'Issue Date',
                    value: isNotUndefined(extInfo?.issue_date)
                        ? formatDate(extInfo?.issue_date)
                        : '',
                },
                {
                    title: 'Maturity Date',
                    value: isNotUndefined(extInfo?.maturity_date)
                        ? formatDate(extInfo?.maturity_date)
                        : '',
                },
                {
                    title: 'Tenor',
                    value: extInfo?.tenor ? extInfo.tenor + 'days' : '',
                },
                {
                    title: 'Issue',
                    value: FormatNumber(extInfo?.issue_price_rate, 3)
                        ? FormatNumber(extInfo?.issue_price_rate, 3) + '%'
                        : '',
                },
                {
                    title: 'Knock out',
                    value: FormatNumber(extInfo?.knock_out_price, 3) || '',
                },
                {
                    title: 'Observation Frequency',
                    value: getObservationFrequency(extInfo?.observation_frequency),
                },
                {
                    title: 'Fill Nominal Amount',
                    value: FormatNumber(extInfo?.fill_nominal_amount, 3) || '',
                },
                {
                    title: 'Fill Actual Amount',
                    value: FormatNumber(extInfo?.fill_actual_amount, 3) || '',
                },
            ];
            break;
        }
        case AssetType.Equities: {
            ret = [
                //ticker,isin,name,currency,market price
                {
                    title: 'Ticker',
                    value: extInfo?.ticker || '',
                },
                {
                    title: 'ISIN',
                    value: item.position?.isin || '',
                },
                {
                    title: 'Name',
                    value: extInfo?.asset_name || '',
                },
                {
                    title: 'Currency',
                    value: item.position.currency || '',
                },
                {
                    title: 'Market Price',
                    value: FormatNumber(extInfo?.market_price, 3) || '',
                },
            ];
            break;
        }
        case AssetType.Fund: {
            ret = [
                //打款类型，Fund name，Fund manager，currency
                {
                    title: 'Fund Struct',
                    value: FundStruct[extInfo.fund_struct_type as keyof typeof FundStruct],
                },
                {
                    title: 'Fund name',
                    width: '50%',
                    value: extInfo?.asset_name || '',
                },
                {
                    title: 'Fund manager',
                    value: extInfo?.fund_manager || '',
                },
                {
                    title: 'Currency',
                    value: item.position.currency || '',
                },
            ];
            break;
        }
        case AssetType.AQ:
        case AssetType.DQ: {
            //isin、underlying、Currency、Tenor、Leverage、Guaranteed Period、Strike、Knock out、 Daily Number of Shares、Leveraged Number of Shares
            ret = [
                {
                    title: 'ISIN',
                    value: item.position?.isin || '',
                },
                {
                    title: 'Underlying',
                    value: `${extInfo.underlying_spec_name || extInfo.underlying_name} | ${
                        extInfo.ticker || ''
                    }`,
                },
                {
                    title: 'Currency',
                    value: item.position.currency || '',
                },
                {
                    title: 'Tenor',
                    value: extInfo?.tenor || '',
                },
                {
                    title: 'Leverage',
                    value: extInfo?.leverage === 2 ? 'leveraged' : 'non-leveraged',
                },
                {
                    title: 'Guaranteed Period',
                    value: extInfo?.guaranteed_period || '',
                },
                {
                    title: 'Strike',
                    value: extInfo?.strike_price_rate ? `${extInfo.strike_price_rate}%` : '',
                },
                {
                    title: 'Knock out',
                    value: extInfo?.knock_out_price_rate ? `${extInfo.knock_out_price_rate}%` : '',
                },
                {
                    title: 'Daily Number of Shares',
                    width: '33.3%',
                    value: extInfo?.day_volume || '',
                },
                {
                    title: 'Leveraged Number of Shares',
                    width: '33.3%',
                    value: extInfo?.boosted_day_volume || '',
                },
            ];
        }
    }
    return ret;
};
const isNotUndefined = (x: unknown) => {
    return !isUndefined(x);
};
export const getValueTransaction = (item: PositionAssetDetail) => {
    let ret = [] as ColumnsType<TransactionInfo>;
    switch (item.position.asset_type.code) {
        //赵杰雄和赵刘赟没有达成一致
        case AssetType.FCN:
        case AssetType.ELN:
            ret = [
                {
                    title: 'Transaction Type',
                    dataIndex: ['type', 'desc'],
                    width: '20%',
                },
                {
                    title: 'Value Date/Trade Date',
                    dataIndex: 'trade_date',
                    width: '20%',
                    render(value, record, index) {
                        return `${moment
                            .tz(record.value_date, record.time_zone)
                            .format('yyyy-MM-DD')}/${moment
                            .tz(record.trade_date, record.time_zone)
                            .format('yyyy-MM-DD')}`;
                    },
                },
                {
                    title: 'Amount',
                    dataIndex: ['ext_info', 'holding_amount'],
                },
            ];
            break;
        case AssetType.Option:
            ret = [
                {
                    title: 'Transaction Type',
                    dataIndex: ['type', 'desc'],
                    width: '20%',
                },
                {
                    title: 'Value Date/Trade Date',
                    dataIndex: 'trade_date',
                    width: '20%',
                    render(value, record, index) {
                        return `${moment
                            .tz(record.value_date, record.time_zone)
                            .format('yyyy-MM-DD')}/${moment
                            .tz(record.trade_date, record.time_zone)
                            .format('yyyy-MM-DD')}`;
                    },
                },
                {
                    title: 'Amount',
                    dataIndex: ['ext_info', 'cash_amount'],
                },
            ];
            break;
        case AssetType.CorporateBond:
        case AssetType.GovernmentBond:
        case AssetType.FixedDeposit:
        case AssetType.Loan:
        case AssetType.Equities:
            ret = [
                {
                    title: 'Transaction Type',
                    dataIndex: ['type', 'desc'],
                    width: '20%',
                },
                {
                    title: 'Value Date/Trade Date',
                    dataIndex: 'trade_date',
                    width: '20%',
                    render(value, record, index) {
                        return `${moment
                            .tz(record.value_date, record.time_zone)
                            .format('yyyy-MM-DD')}/${moment
                            .tz(record.trade_date, record.time_zone)
                            .format('yyyy-MM-DD')}`;
                    },
                },
                {
                    title: 'Holding',
                    dataIndex: ['ext_info', 'holding_amount'],
                    render(value) {
                        return FormatNumber(value, 2);
                    },
                },
                {
                    title: 'Cash',
                    dataIndex: ['ext_info', 'cash_amount'],
                    render(value) {
                        return FormatNumber(value, 2);
                    },
                },
            ];
            break;
        case AssetType.Fund: {
            if (item.position.ext_info.fund_struct_type === FundStruct['Open End']) {
                ret = [
                    {
                        title: 'Transaction Type',
                        dataIndex: ['type', 'desc'],
                        width: '20%',
                    },
                    {
                        title: 'Value Date/Trade Date',
                        dataIndex: 'trade_date',
                        width: '20%',
                        render(value, record, index) {
                            return `${moment
                                .tz(record.value_date, record.time_zone)
                                .format('yyyy-MM-DD')}/${moment
                                .tz(record.trade_date, record.time_zone)
                                .format('yyyy-MM-DD')}`;
                        },
                    },
                    {
                        title: 'Units',
                        dataIndex: ['ext_info', 'units'],
                        render(value) {
                            return FormatNumber(value, 2);
                        },
                    },
                    {
                        title: 'Fees',
                        dataIndex: ['ext_info', 'fees'],
                        render(value) {
                            return FormatNumber(value, 2);
                        },
                    },

                    {
                        title: 'Cash',
                        dataIndex: ['ext_info', 'cash_amount'],
                        render(value) {
                            return FormatNumber(value, 2);
                        },
                    },
                ];
            } else {
                ret = [
                    {
                        title: 'Transaction Type',
                        dataIndex: ['type', 'desc'],
                        width: '20%',
                    },
                    {
                        title: 'Value Date/Trade Date',
                        dataIndex: 'trade_date',
                        width: '20%',
                        render(value, record, index) {
                            return `${moment
                                .tz(record.value_date, record.time_zone)
                                .format('yyyy-MM-DD')}/${moment
                                .tz(record.trade_date, record.time_zone)
                                .format('yyyy-MM-DD')}`;
                        },
                    },

                    {
                        title: 'Paid In',
                        dataIndex: ['ext_info', 'paid_in_amount'],
                        render(value) {
                            return FormatNumber(value, 2);
                        },
                    },
                    {
                        title: 'Fees',
                        dataIndex: ['ext_info', 'fees'],
                        render(value) {
                            return FormatNumber(value, 2);
                        },
                    },
                    {
                        title: 'Cash',
                        dataIndex: ['ext_info', 'cash_amount'],
                        render(value) {
                            return FormatNumber(value, 2);
                        },
                    },
                ];
            }

            break;
        }
        case AssetType.AQ:
        case AssetType.DQ: {
            ret = [
                {
                    title: 'Transaction Type',
                    dataIndex: ['type', 'desc'],
                    width: '20%',
                },
                {
                    title: 'Value Date/Trade Date',
                    dataIndex: 'trade_date',
                    width: '20%',
                    render(value, record, index) {
                        return `${moment
                            .tz(record.value_date, record.time_zone)
                            .format('yyyy-MM-DD')}/${moment
                            .tz(record.trade_date, record.time_zone)
                            .format('yyyy-MM-DD')}`;
                    },
                },
                {
                    title: 'Shares',
                    dataIndex: ['ext_info', 'holding_amount'],
                    render(value) {
                        return FormatNumber(value, 2);
                    },
                },
                {
                    title: 'Cash',
                    dataIndex: ['ext_info', 'cash_amount'],
                    render(value) {
                        return FormatNumber(value, 2);
                    },
                },
            ];
            break;
        }
    }
    return ret;
};
export const getStaticPositonDetail = (item: PositionAssetDetail) => {
    let ret = [] as ColumnsType<PositionAssetDetail['position_statistics']>;
    switch (item.position.asset_type.code) {
        case AssetType.AQ:
            ret = [
                {
                    title: 'Trade Progress',
                    render(value, record, index) {
                        return (record.passed_trade_days / record.trade_days).toFixed(3);
                    },
                },
                {
                    title: '剩余最大可执行股数',
                    dataIndex: 'remaining_max_volume',
                    width: '20%',
                },
                {
                    title: '实际买入股数',
                    dataIndex: 'holding_amount',
                    width: '20%',
                },
                {
                    title: '实际买入金额',
                    dataIndex: 'cash_amount',
                    width: '20%',
                },
            ];
            break;
        case AssetType.DQ:
            ret = [
                {
                    title: 'Trade Progress',
                    render(value, record, index) {
                        return (record.passed_trade_days / record.trade_days).toFixed(3);
                    },
                },
                {
                    title: '剩余最大可执行股数',
                    dataIndex: 'remaining_max_volume',
                    width: '20%',
                },
                {
                    title: '实际卖出股数',
                    dataIndex: 'holding_amount',
                    width: '20%',
                },
                {
                    title: '实际卖出金额',
                    dataIndex: 'cash_amount',
                    width: '20%',
                    render(value, record, index) {
                        return `${FormatNumber(value, 2)}`;
                    },
                },
            ];
            break;
        case AssetType.ELN:
            ret = [
                {
                    title: 'Annualised Rate of Return',
                    dataIndex: 'annualised_rate_of_return',
                    width: '20%',
                    render(value, record, index) {
                        return `${FormatNumber(value, 2)}%`;
                    },
                },
            ];
            break;
        case AssetType.CorporateBond:
        case AssetType.GovernmentBond:
        case AssetType.Loan:
        case AssetType.Equities:
        case AssetType.FixedDeposit:
            ret = [
                {
                    title: 'Holding',
                    dataIndex: 'holding_amount',
                    width: '20%',
                    render(value, record, index) {
                        return `${FormatNumber(value, 2)}`;
                    },
                },
                {
                    title: 'Cash',
                    dataIndex: 'cash_amount',
                    width: '20%',
                    render(value, record, index) {
                        return `${FormatNumber(value, 2)}`;
                    },
                },
                {
                    title: 'Holding Value',
                    dataIndex: 'holding_value',
                    width: '20%',
                    render(value, record, index) {
                        return `${FormatNumber(value, 2)}`;
                    },
                },
            ];
            break;
        case AssetType.Fund:
            if (item.position.ext_info.fund_struct_type === FundStruct['Close End']) {
                ret = [
                    {
                        title: 'Paid In',
                        dataIndex: 'paid_in_amount',
                        width: '20%',
                    },
                    {
                        title: 'Fees',
                        dataIndex: 'fees',
                        width: '20%',
                    },
                    {
                        title: 'Cash',
                        dataIndex: 'cash_amount',
                        width: '20%',
                    },
                    {
                        title: 'Committed Capital',
                        dataIndex: 'committed_capital',
                        width: '20%',
                    },
                    {
                        title: 'Payable',
                        dataIndex: 'payable_amount',
                        width: '20%',
                    },
                ];
            } else {
                ret = [
                    {
                        title: 'Units',
                        dataIndex: 'units',
                        width: '20%',
                    },
                    {
                        title: 'Fees',
                        dataIndex: 'fees',
                        width: '20%',
                    },
                    {
                        title: 'Cash',
                        dataIndex: 'cash_amount',
                        width: '20%',
                    },
                ];
            }
            break;
    }
    return ret;
};
