import React, { useEffect, useState } from 'react';
import styles from './AppData.module.scss';
import { Col, Row } from 'antd';
import AppActiveUserData from '../Components/AppActiveUserData';
import moment from 'moment';
import useFetch from '../../../utils/Hooks/useFetch';
import {
    QuestionId,
    getDashboardData,
    getDashboardDataAsColTable,
} from '../../../service/requests/Dashboard';
import classnames from 'classnames';
import TableChart from '../Components/TableChart';
import { ColumnsType } from 'antd/es/table/Table';
import LineChart from '../Components/LineChart';

const AppData = () => {
    const [dauTime, setDauTime] = useState(moment().format('yyyy-MM-DD'));
    const [wauEndTime, setWauEndTime] = useState(moment().endOf('w').format('yyyy-MM-DD'));
    const [mauEndTime, setMauEndTime] = useState(moment().endOf('month').format('yyyy-MM-DD'));
    const [activeEndTimePeriod, setActiveEndTimePeriod] = useState(30);
    const [nowDate] = useState(moment().format('yyyy-MM-DD'));
    const [unactivePeriod, setUnactivePeriod] = useState(30);
    const [activePeriod, setActivePeriod] = useState(30);
    const columns: ColumnsType = [
        {
            title: '用户名',
            dataIndex: 'name',
        },
        {
            title: 'ID',
            dataIndex: 'customer_id',
        },
        { title: '使用天数', dataIndex: 'app_use_days' },
        { title: '状态', dataIndex: 'state' },
        { title: '最后一次登录时间', dataIndex: 'last_login_time' },
    ];
    const [dauData, setDatDataParam] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.AppDau,
            question_params: {
                date: moment(dauTime).format('yyyy-MM-DD'),
            },
        },
    });
    const [wauData, setWatDataParam] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.AppWau,
            question_params: {
                start_at: moment(wauEndTime).subtract(6, 'days').format('yyyy-MM-DD'),
                end_at: moment(wauEndTime).format('yyyy-MM-DD'),
            },
        },
    });
    const [mauData, setMauDataParam] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.AppMau,
            question_params: {
                start_at: moment(mauEndTime).startOf('months').format('yyyy-MM-DD'),
                end_at: moment(mauEndTime).format('yyyy-MM-DD'),
            },
        },
    });
    const [activeUserData, setActiveUserDataParam] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.AppUserActive,
            question_params: {
                start_at: moment()
                    .subtract(activeEndTimePeriod - 1, 'days')
                    .format('yyyy-MM-DD'),
                end_at: moment().format('yyyy-MM-DD'),
            },
        },
    });
    const [activeAccountRes, setActiveAccountParam] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.AppActiveAccount,
            question_params: {
                days: activePeriod,
            },
        },
    });
    const [unactiveAccountRes, setUnctiveAccountParam] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.AppUnactiveAccount,
            question_params: {
                days: unactivePeriod,
            },
        },
    });
    const [wauChartRes] = useFetch({
        interfaces: getDashboardDataAsColTable,
        initialParams: {
            question_id: QuestionId.AppWauChart,
            question_params: {
                start_at: moment()
                    .week(moment(nowDate).week() - 3)
                    .startOf('week')
                    .format('yyyy-MM-DD'),
                end_at: moment(nowDate).format('yyyy-MM-DD'),
            },
        },
    });
    const [wpvChartRes] = useFetch({
        interfaces: getDashboardDataAsColTable,
        initialParams: {
            question_id: QuestionId.AppWpvChart,
            question_params: {
                start_at: moment()
                    .week(moment(nowDate).week() - 3)
                    .startOf('week')
                    .format('yyyy-MM-DD'),
                end_at: moment(nowDate).format('yyyy-MM-DD'),
            },
        },
    });
    useEffect(() => {
        setDatDataParam((value) => ({
            ...value,
            question_params: {
                date: moment(dauTime).format('yyyy-MM-DD'),
            },
        }));
    }, [dauTime, setDatDataParam]);
    useEffect(() => {
        setWatDataParam((value) => ({
            ...value,
            question_params: {
                start_at: moment(wauEndTime).subtract(6, 'days').format('yyyy-MM-DD'),
                end_at: moment(wauEndTime).format('yyyy-MM-DD'),
            },
        }));
    }, [wauEndTime, setWatDataParam]);
    useEffect(() => {
        setMauDataParam((value) => ({
            ...value,
            question_params: {
                start_at: moment(mauEndTime).startOf('months').format('yyyy-MM-DD'),
                end_at: moment(mauEndTime).format('yyyy-MM-DD'),
            },
        }));
    }, [mauEndTime, setMauDataParam]);
    useEffect(() => {
        setActiveUserDataParam((value) => ({
            ...value,
            question_params: {
                start_at: moment()
                    .subtract(activeEndTimePeriod - 1, 'days')
                    .format('yyyy-MM-DD'),
                end_at: moment().format('yyyy-MM-DD'),
            },
        }));
    }, [activeEndTimePeriod, setActiveUserDataParam]);
    const formatValue = (num: number) => {
        return num > 0 ? `+${num}%` : `${num}%`;
    };
    const formatStyle = (num: number) => {
        return num < 0 ? 'fall' : 'rise';
    };
    const onActivePeriodChange = (num: number) => {
        setActivePeriod(num);
        setActiveAccountParam((value) => {
            return {
                ...value,
                question_params: {
                    days: num,
                },
            };
        });
    };
    const onUnactivePeriodChange = (num: number) => {
        setUnactivePeriod(num);
        setUnctiveAccountParam((value) => {
            return {
                ...value,
                question_params: {
                    days: num,
                },
            };
        });
    };
    const changeDauTime = (num: number) => {
        setDauTime((value) => {
            return moment(value).add(num, 'days') <= moment()
                ? moment(value).add(num, 'days').format('yyyy-MM-DD')
                : value;
        });
    };
    const changeWauTime = (num: number) => {
        const newNumber = num > 0 ? 7 : -7;
        setWauEndTime((value) => {
            return moment(value).add(newNumber, 'days').subtract(6, 'days') <= moment()
                ? moment(value).add(newNumber, 'days').format('yyyy-MM-DD')
                : value;
        });
    };
    const changeMauTime = (num: number) => {
        setMauEndTime((value) => {
            return moment(value).add(num, 'months').startOf('months') <= moment()
                ? moment(value).add(num, 'months').endOf('months').format('yyyy-MM-DD')
                : value;
        });
    };
    const changeActiveEndPeriod = (num: number) => {
        setActiveEndTimePeriod(num);
    };
    return (
        <>
            <div className={styles.title}>App数据</div>
            <Row gutter={16}>
                <Col span={6}>
                    {dauData.data && (
                        <AppActiveUserData
                            title="App DAU"
                            time={dauTime}
                            count={dauData.data[0].current_count}
                            unit="人"
                            dateTrigger="button"
                            onTrigger={changeDauTime}
                        >
                            <div className={styles.content}>
                                <span>较昨日</span>

                                <span
                                    className={classnames({
                                        [styles[formatStyle(dauData.data[0].pre_count_rate)]]: true,
                                    })}
                                >
                                    {formatValue(dauData.data[0].pre_count_rate)}
                                </span>
                                <span>较上周</span>
                                <span
                                    className={classnames({
                                        [styles[
                                            formatStyle(dauData.data[0].pre_week_count_rate)
                                        ]]: true,
                                    })}
                                >
                                    {formatValue(dauData.data[0].pre_week_count_rate)}
                                </span>
                            </div>
                        </AppActiveUserData>
                    )}
                </Col>
                <Col span={6}>
                    {wauData.data && (
                        <AppActiveUserData
                            title="App WAU"
                            time={`${moment(wauEndTime)
                                .subtract(6, 'days')
                                .format('yyyy.MM.DD')} - ${moment(wauEndTime).format(
                                'yyyy.MM.DD'
                            )}`}
                            count={wauData.data[0].current_count}
                            unit="人"
                            dateTrigger="button"
                            onTrigger={changeWauTime}
                        >
                            <div className={styles.content}>
                                <span>较上周</span>
                                <span
                                    className={classnames({
                                        [styles[formatStyle(wauData.data[0].pre_count_rate)]]: true,
                                    })}
                                >
                                    {formatValue(wauData.data[0].pre_count_rate)}
                                </span>
                                <span>较上月</span>
                                <span
                                    className={classnames({
                                        [styles[
                                            formatStyle(wauData.data[0].pre_month_count_rate)
                                        ]]: true,
                                    })}
                                >
                                    {formatValue(wauData.data[0].pre_month_count_rate)}
                                </span>
                            </div>
                        </AppActiveUserData>
                    )}
                </Col>
                <Col span={6}>
                    {mauData.data && (
                        <AppActiveUserData
                            title="App MAU"
                            time={`${moment(mauEndTime)
                                .startOf('months')
                                .format('yyyy.MM.DD')} - ${moment(mauEndTime).format(
                                'yyyy.MM.DD'
                            )}`}
                            count={mauData.data[0].current_count}
                            unit="人"
                            dateTrigger="button"
                            onTrigger={changeMauTime}
                        >
                            <div className={styles.content}>
                                <span>较上月</span>
                                <span
                                    className={classnames({
                                        [styles[formatStyle(mauData.data[0].pre_count_rate)]]: true,
                                    })}
                                >
                                    {formatValue(mauData.data[0].pre_count_rate)}
                                </span>
                            </div>
                        </AppActiveUserData>
                    )}
                </Col>
                <Col span={6}>
                    {activeUserData.data && (
                        <AppActiveUserData
                            title="客户活跃"
                            time={`${moment()
                                .subtract(activeEndTimePeriod - 1, 'days')
                                .format('yyyy.MM.DD')} - ${moment().format('yyyy.MM.DD')}`}
                            count={activeUserData.data[0].current_rate}
                            unit="%"
                            dateTrigger="select"
                            period={activeEndTimePeriod}
                            onTrigger={changeActiveEndPeriod}
                        >
                            <div className={styles.content}>
                                <span>较上周</span>
                                <span
                                    className={classnames({
                                        [styles[
                                            formatStyle(activeUserData.data[0].compare_pre_rate)
                                        ]]: true,
                                    })}
                                >
                                    {formatValue(activeUserData.data[0].compare_pre_rate)}
                                </span>
                                <span>较上月</span>
                                <span
                                    className={classnames({
                                        [styles[
                                            formatStyle(
                                                activeUserData.data[0].compare_pre_month_rate
                                            )
                                        ]]: true,
                                    })}
                                >
                                    {formatValue(activeUserData.data[0].compare_pre_month_rate)}
                                </span>
                            </div>
                        </AppActiveUserData>
                    )}
                </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '16px' }}>
                <Col span={12}>
                    {unactiveAccountRes.data && (
                        <TableChart
                            title="未活跃用户"
                            time={`${moment(nowDate)
                                .subtract(unactivePeriod - 1, 'days')
                                .format('yyyy.MM.DD')} - ${moment(nowDate).format('yyyy.MM.DD')}`}
                            count={unactiveAccountRes.data.length}
                            unit="人"
                            period={unactivePeriod}
                            onPeriodChange={onUnactivePeriodChange}
                            dataSource={unactiveAccountRes.data}
                            columns={columns}
                        />
                    )}
                </Col>
                <Col span={12}>
                    {activeAccountRes.data && (
                        <TableChart
                            title="活跃用户"
                            time={`${moment(nowDate)
                                .subtract(activePeriod - 1, 'days')
                                .format('yyyy.MM.DD')} - ${moment(nowDate).format('yyyy.MM.DD')}`}
                            count={activeAccountRes.data.length}
                            unit="人"
                            period={activePeriod}
                            onPeriodChange={onActivePeriodChange}
                            dataSource={activeAccountRes.data}
                            columns={columns}
                        />
                    )}
                </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '16px', marginBottom: '16px' }}>
                <Col span={12}>
                    {wauChartRes.data && (
                        <LineChart
                            title="APP WAU"
                            time={`${moment()
                                .week(moment(nowDate).week() - 3)
                                .startOf('week')
                                .format('yyyy.MM.DD')} - ${moment(nowDate).format('yyyy.MM.DD')}`}
                            data={wauChartRes.data}
                        />
                    )}
                </Col>
                <Col span={12}>
                    {wpvChartRes.data && (
                        <LineChart
                            title="APP WPV"
                            time={`${moment()
                                .week(moment(nowDate).week() - 3)
                                .startOf('week')
                                .format('yyyy.MM.DD')} - ${moment(nowDate).format('yyyy.MM.DD')}`}
                            data={wpvChartRes.data}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default AppData;
