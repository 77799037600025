import React from 'react';
const BankFee = () => {
    return (
        <>
            <iframe
                title="银行费率"
                scrolling="auto"
                src="https://lookcapital.sg.larksuite.com/wiki/wiklgKuAyY1zzkjokOAkiKU2FJd?source_type=message&from=message"
                key="iframekeyBankFee"
                width={'100%'}
                height={`100%`}
            />
        </>
    );
};
export default BankFee;
