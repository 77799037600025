import { POSITION_REFRESH, POSITION_TABINDEX } from '../../constants/constants';

function position(
    state = {
        state: {
            refresh: 0,
            index: 'family',
        },
        tabIndex: 'family',
    },
    action: any
) {
    const { type } = action;
    if (type === POSITION_REFRESH) {
        return {
            ...state,
            state: {
                index: action.state.index,
                refresh: action.state.refresh,
            },
        };
    } else if (type === POSITION_TABINDEX) {
        return {
            ...state,
            tabIndex: action.tabIndex,
        };
    } else {
        return state;
    }
}

export default position;
