import React, { useMemo } from 'react';
import styles from './index.module.scss';
import { UserAccountInfo } from '../../type';
import { BondType } from '../../../Quotation/request';
interface AccountSummaryProps {
    info?: UserAccountInfo;
    bondType?: BondType;
}
const AccountSummary = ({ info, bondType }: AccountSummaryProps) => {
    const dataList: { name: string; value: any }[] = useMemo(() => {
        if (info) {
            return [
                {
                    name: '用户',
                    value: info.user.name,
                },
                {
                    name: '交易账户',
                    value: `${info.account.accountType}${
                        info.account.vendorSubAccountId ? '-' + info.account.vendorSubAccountId : ''
                    }`,
                },
                {
                    name: '产品类型',
                    value: bondType === BondType.corporate ? '公司债' : '国债',
                },
                {
                    name: '现金余额',
                    value: `${info.balance_amount}${info.currency ?? ''}`,
                },
                {
                    name: '安全垫',
                    value: `${info.risk_rate}`,
                },
            ];
        }
        return [];
    }, [bondType, info]);
    return (
        <div className={styles.container}>
            {dataList.map((item, index) => {
                return (
                    <div className={styles.item} key={index}>
                        <div className={styles.value}>{item.value}</div>
                        <div className={styles.key}>{item.name}</div>
                    </div>
                );
            })}
        </div>
    );
};

export default AccountSummary;
