import React, { useMemo, useState } from 'react';
import { BondQuoteListRes, BondQuoteEntity, BondType } from '../../request';
import styles from './BondQuoteTable.module.scss';
import { Button, Table, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ColumnType, ColumnsType } from 'antd/es/table';

interface BondQuoteTableProps {
    type: BondType;
    data: BondQuoteListRes;
    onRemoveClick: (id: number) => void;
    onEditClick: (bond: BondQuoteEntity) => void;
    onChange: (iten: any) => void;
    tableHeight?: number;
    defaultSelectedKeys?: React.Key[];
    onSelectionChange?: (BondsQuodations: BondQuoteEntity[]) => void;
}

const BondQuoteTable = ({
    type,
    data,
    onRemoveClick,
    onEditClick,
    onChange,
    tableHeight,
    defaultSelectedKeys,
    onSelectionChange,
}: BondQuoteTableProps) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(defaultSelectedKeys || []);
    const [selectedItems, setSelectedItems] = useState<BondQuoteEntity[]>(() => {
        return data.bond_quotes?.filter((item) => defaultSelectedKeys?.includes(item.id!)) ?? [];
    });
    const columns = useMemo<ColumnsType<BondQuoteEntity>>(() => {
        let ret: ColumnsType<BondQuoteEntity> = [
            {
                title: 'ISIN',
                dataIndex: 'isin',
                width: 150,
                fixed: 'left',
            },
            {
                title: '信用评级 - S&P',
                dataIndex: 'credit_rating_sp',
                width: 150,
            },
            {
                title: ' 发行方-英',
                dataIndex: 'issuer_en',
                width: 225,
            },
            {
                title: ' 发行方-中',
                dataIndex: 'issuer_cn',
                width: 175,
            },
            {
                title: '指导价格%',
                dataIndex: 'ask_price',
                width: 125,
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: '指导含息价格%',
                dataIndex: 'ask_price_plus_accrued_interest',
                width: 125,
            },
            {
                title: '最差收益率%',
                dataIndex: 'YTW',
                width: 100,
            },
            {
                title: '到期收益率%',
                dataIndex: 'YTM',
                width: 125,
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: '年化票息%',
                dataIndex: 'coupon',
                width: 125,
                sorter: true,
                showSorterTooltip: false,
            },
            {
                title: '久期',
                dataIndex: 'duration',
                width: 100,
            },
            {
                title: '优先级',
                dataIndex: 'seniority',
                width: 125,
            },
            {
                title: '下一赎回日',
                dataIndex: 'next_call_date',
                width: 120,
            },
            {
                title: '到期日',
                dataIndex: 'maturity_date',
                width: 120,
            },
            {
                title: '到期时间',
                dataIndex: 'residual_maturity',
                width: 120,
            },
        ];
        if (type === BondType.government) {
            let fields = [
                'isin',
                'ask_price',
                'ask_price_plus_accrued_interest',
                'YTM',
                'coupon',
                'duration',
                'maturity_date',
                'residual_maturity',
            ];
            ret = ret.filter((i: ColumnType<BondQuoteEntity>) =>
                fields.includes(i.dataIndex as string)
            );
        }
        if (!onSelectionChange) {
            ret.push({
                title: '',
                render: (_, record) => {
                    return (
                        <div className={styles.btn_area}>
                            {type !== BondType.government && (
                                <Button
                                    className={styles.btn}
                                    onClick={() => {
                                        onEditClick(record);
                                    }}
                                >
                                    <Tooltip title="编辑">
                                        <EditOutlined />
                                    </Tooltip>
                                </Button>
                            )}

                            <Button
                                className={styles.btn}
                                onClick={() => {
                                    onRemoveClick(record.id!);
                                }}
                            >
                                <Tooltip title="删除">
                                    <DeleteOutlined />
                                </Tooltip>
                            </Button>
                        </div>
                    );
                },
                fixed: 'right',
                width: 80,
            });
        }
        return ret;
    }, [onEditClick, onRemoveClick, onSelectionChange, type]);
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        const unselectedKeys = data.bond_quotes
            .map((i) => i.id)
            .filter((i) => !newSelectedRowKeys.includes(i!));
        const selectionItem = data.bond_quotes.filter((i) => newSelectedRowKeys.includes(i.id!));
        //为了区分没有被选中的 item 遍历一下未选中的 item
        const hasSelectedItems = selectedItems.filter((i) => !unselectedKeys.includes(i.id!));
        let resultList = [
            ...hasSelectedItems, //找到筛选之前选中的
            ...selectionItem.filter((i) => selectedItems.findIndex((j) => j.id === i.id) === -1), //当前筛选条件下选中的
        ];
        setSelectedItems(resultList);
        setSelectedRowKeys(resultList.map((r) => r.id!));
        onSelectionChange && onSelectionChange(resultList);
    };
    return (
        <>
            <div className={styles.bonds_quotation_table}>
                <Table
                    columns={columns}
                    dataSource={data.bond_quotes}
                    rowKey={(record) => record.id!}
                    rowSelection={
                        onSelectionChange
                            ? {
                                  selectedRowKeys,
                                  onChange: onSelectChange,
                                  fixed: true,
                              }
                            : undefined
                    }
                    size="small"
                    scroll={{ x: 1000, y: tableHeight && tableHeight - 39 }} // table header height: 39
                    pagination={false}
                    onChange={(_, __, sorter) => {
                        let obj = {} as Record<string, string>;
                        if (!Array.isArray(sorter)) {
                            obj = sorter.order
                                ? {
                                      sort_order: sorter.order === 'ascend' ? 'asc' : 'desc',
                                      sort_field: sorter.field as string,
                                  }
                                : {
                                      sort_order: '',
                                      sort_field: '',
                                  };
                        }
                        onChange(obj);
                    }}
                    className={onSelectionChange ? styles.selectionTable : ''}
                    onRow={(record) => {
                        return onSelectionChange
                            ? {
                                  onClick: () => {
                                      if (selectedRowKeys.includes(record.id!)) {
                                          onSelectChange(
                                              selectedRowKeys.filter((k) => k !== record.id)
                                          );
                                      } else {
                                          onSelectChange([...selectedRowKeys, record.id!]);
                                      }
                                  },
                              }
                            : {};
                    }}
                    sticky
                />
            </div>
        </>
    );
};

export default BondQuoteTable;
