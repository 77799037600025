import React from 'react';
import styles from './index.module.scss';
import { Button } from 'antd';
import { ProductQuoteDetailType, QouteStatus } from '../../type';
import alertImg from '../../../../static/alert-circle.png';
import successImg from '../../../../static/check-circle.png';
const BondCalculate = ({ data, onOk }: { data?: ProductQuoteDetailType; onOk: () => void }) => {
    // const finalData = useMemo(() => {
    //     if (data?.cal_fields_by_real_time_price) {
    //         return data.cal_fields.map((item, index) => {
    //             return [item, data?.cal_fields_by_real_time_price[index]];
    //         });
    //     }
    //     //数据中 0、1 为一竖列,2、3为一竖列，4、5为一竖列
    //     return (data?.cal_fields ?? []).reduce((pre, cur, index) => {
    //         if (index % 2 === 0) {
    //             pre.push([cur]);
    //         } else {
    //             pre[pre.length - 1].push(cur);
    //         }
    //         return pre;
    //     }, [] as { title: string; value?: string; unit?: string; is_pass_check?: boolean; failed_check_message?: string }[][]);
    // }, [data]);
    const btnTitle = () => {
        switch (data?.status.code) {
            case QouteStatus.Communicateing:
                return '确认报价';
            case QouteStatus.Confirmed:
                return '您已确认报价信息';
            case QouteStatus.Canceled:
                return '该报价已撤销';
            case QouteStatus.Selecting:
                return '筛选中';
        }
        return '';
    };
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                {data?.cal_fields_by_real_time_price && (
                    <div className={styles.sectionTitle}>报价时股价计算</div>
                )}
                <div className={styles.result}>
                    {(data?.cal_fields ?? []).map((item, index) => (
                        <div className={styles.section} key={index}>
                            <div className={styles.item} key={item.title}>
                                <div className={styles.value}>
                                    {item.value ?? '--'} {item.unit}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className={styles.title}>{item.title}</div>
                                    {typeof item.is_pass_check !== 'undefined' && (
                                        <img
                                            style={{
                                                width: '16px',
                                                height: '16px',
                                                marginLeft: '8px',
                                            }}
                                            alt=""
                                            src={item.is_pass_check! ? successImg : alertImg}
                                        />
                                    )}
                                    {item.failed_check_message && (
                                        <div style={{ color: '#FF0000', marginLeft: '8px' }}>
                                            {item.failed_check_message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {data?.cal_fields_by_real_time_price && (
                    <div className={styles.sectionTitle}>实时股价计算</div>
                )}
                <div className={styles.result}>
                    {(data?.cal_fields_by_real_time_price ?? []).map((item, index) => (
                        <div className={styles.section} key={index}>
                            <div className={styles.item} key={item.title}>
                                <div className={styles.value}>
                                    {item.value ?? '--'} {item.unit}
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div className={styles.title}>{item.title}</div>
                                    {typeof item.is_pass_check !== 'undefined' && (
                                        <img
                                            style={{
                                                width: '16px',
                                                height: '16px',
                                                marginLeft: '8px',
                                            }}
                                            alt=""
                                            src={item.is_pass_check! ? successImg : alertImg}
                                        />
                                    )}
                                    {item.failed_check_message && (
                                        <div style={{ color: '#FF0000', marginLeft: '8px' }}>
                                            {item.failed_check_message}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {false && (
                    <>
                        <div className={styles.spline} />
                        <Button
                            id="Quote_Button_Confirm"
                            disabled={
                                data?.selected_quotes?.length === 0 ||
                                data?.status.code !== QouteStatus.Communicateing ||
                                data?.is_pass_check === false
                            }
                            className={styles.button}
                            onClick={onOk}
                        >
                            {btnTitle()}
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default BondCalculate;
