import { volcano } from '@ant-design/colors';
import { Result } from 'antd';
import React from 'react';
import DocumentTitle from 'react-document-title';

export default function NoAccessPage() {
    return (
        <DocumentTitle title="警告 - 无权限">
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Result
                    status="warning"
                    title={
                        <p>
                            您当前没有运营后台的权限。 如需使用，请联系管理员
                            <span style={{ color: volcano[7] }}>【吴臣羽】</span>
                            进行设置。
                        </p>
                    }
                />
            </div>
        </DocumentTitle>
    );
}
