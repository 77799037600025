import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import AccountItem from './FormItems/AccountItem';
import {
    GinkgoCurrencyType,
    GinkgoTransFormRefType,
    TransExchangeExtInfo,
    TransStatus,
    TransactionInfo,
} from '../../../type';
import DateItem from './FormItems/DateItem';
import { Form } from 'antd';
import LKRadioGroup, { LKRadioGroupProps } from '../../../../../components/LKRadioGroup';
import { useForm } from 'antd/lib/form/Form';
import { GinkgoInput, GinkgoInputNumber } from './FormItems/GinkgoFieldItems';

const EquitiesForm = forwardRef<GinkgoTransFormRefType, TransactionInfo>(
    (data: TransactionInfo, onRef) => {
        const [form] = useForm();
        useImperativeHandle(onRef, () => {
            return {
                getFieldsValues: async (validate) => {
                    if (validate === false) {
                        return new Promise((resolve) => {
                            return resolve(configValues(form.getFieldsValue()));
                        });
                    } else {
                        return form.validateFields().then(configValues);
                    }
                },
            };
        });

        const configValues = (values: any) => {
            return {
                ...values,
                client_id: data.client_id,
                transaction_type: data.type.code,
                id: data.id,
                ext_info: {
                    ...data.ext_info,
                    ...values.ext_info,
                },
                status_code: data.status.code,
            };
        };
        const [exchangeType, setExchangeType] = useState<number>();
        useEffect(() => {
            form.setFieldsValue({
                ...data,
            });
            setExchangeType((data.ext_info as TransExchangeExtInfo).exchange_type_code);
        }, [data, form]);

        const exchangeTypeData: LKRadioGroupProps['datasource'] = [
            {
                label: 'Settle OD',
                value: 1,
                width: '20%',
            },
            {
                label: 'Sepecific Buy',
                value: 2,
                width: '20%',
            },
            {
                label: 'Sepecific Sell',
                value: 3,
                width: '20%',
            },
        ];
        const [currentBuy, setCurrentBuy] = useState<string>('');
        const [currentSell, setCurrentSell] = useState<string>('');
        const [timezone, setTimezone] = useState(data.time_zone || '');
        const buyOptions: LKRadioGroupProps['datasource'] = useMemo(() => {
            let target =
                currentSell.length > 0
                    ? currentSell
                    : (data.ext_info as TransExchangeExtInfo).sell_currency;
            return Object.keys(GinkgoCurrencyType).map((item) => {
                return {
                    label: item,
                    value: item,
                    width: '10%',
                    disabled: item === target,
                };
            });
        }, [currentSell, data]);
        const sellOptions: LKRadioGroupProps['datasource'] = useMemo(() => {
            let target =
                currentBuy.length > 0
                    ? currentBuy
                    : (data.ext_info as TransExchangeExtInfo).buy_currency;
            return Object.keys(GinkgoCurrencyType).map((item) => {
                return {
                    label: item,
                    value: item,
                    width: '10%',
                    disabled: item === target,
                };
            });
        }, [currentBuy, data.ext_info]);
        const PlaceableReadOnly = useMemo(() => {
            return data.status.code !== TransStatus.Created;
        }, [data]);
        const TradedReadOnly = useMemo(() => {
            return data.status.code === TransStatus.Traded;
        }, [data]);
        const PlaceableRequired = useMemo(() => {
            return data.status.code === TransStatus.Created;
        }, [data]);
        const TradedRequired = useMemo(() => {
            return data.status.code === TransStatus.Placeable;
        }, [data]);

        useEffect(() => {
            if (data.status.code > TransStatus.Created) {
                form.setFieldsValue({
                    ...data,
                });
            }
        }, [data, form]);

        return (
            <Form labelCol={{ span: 6 }} labelWrap wrapperCol={{ span: 18 }} form={form}>
                <AccountItem
                    clientId={data.client_id}
                    name={['ext_info', 'account_id']}
                    form={form}
                    readOnlyValue={data.ext_info.account_id}
                    selectCallback={(bank) => {
                        setTimezone(bank.time_zone);
                    }}
                    width="calc(60% - 10px)"
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                />
                <Form.Item
                    label="Exchange Type"
                    name={['ext_info', 'exchange_type_code']}
                    rules={[{ required: PlaceableRequired, message: 'Exchange Type is required' }]}
                >
                    <LKRadioGroup
                        datasource={exchangeTypeData}
                        onChange={(value) => {
                            setExchangeType(value as number);
                        }}
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Buy Currency"
                    name={['ext_info', 'buy_currency']}
                    rules={[{ required: PlaceableRequired, message: 'Buy Currency is required' }]}
                >
                    <LKRadioGroup
                        datasource={buyOptions}
                        readOnly={PlaceableReadOnly}
                        onChange={(value) => {
                            setCurrentBuy(value as string);
                        }}
                    />
                </Form.Item>
                {(exchangeType === 2 || data.status.code > 1) && (
                    <Form.Item
                        label="Buy Amount"
                        name={['ext_info', 'buy_amount']}
                        rules={[
                            {
                                required: PlaceableRequired || TradedRequired,
                                message: 'Buy Amount is required',
                            },
                        ]}
                    >
                        <GinkgoInputNumber
                            style={{ width: 'calc(60% - 10px)' }}
                            readOnly={TradedReadOnly}
                        />
                    </Form.Item>
                )}

                <Form.Item
                    label="Sell Currency"
                    name={['ext_info', 'sell_currency']}
                    rules={[{ required: PlaceableRequired, message: 'Sell Currency is required' }]}
                >
                    <LKRadioGroup
                        datasource={sellOptions}
                        readOnly={PlaceableReadOnly}
                        onChange={(value) => {
                            setCurrentSell(value as string);
                        }}
                    />
                </Form.Item>
                {data.status.code > TransStatus.Created && (
                    <Form.Item
                        label="Client Rate"
                        name={['ext_info', 'rate']}
                        rules={[
                            {
                                required: TradedRequired,
                                message: 'Rate is required',
                            },
                        ]}
                    >
                        <GinkgoInputNumber
                            style={{ width: 'calc(60% - 10px)' }}
                            readOnly={TradedReadOnly}
                            addonAfter="Buy/Sell"
                        />
                    </Form.Item>
                )}
                {(exchangeType === 3 || data.status.code > 1) && (
                    <Form.Item
                        label="Sell Amount"
                        name={['ext_info', 'sell_amount']}
                        rules={[
                            {
                                required: PlaceableRequired || TradedRequired,
                                message: 'Sell Amount is required',
                            },
                        ]}
                    >
                        <GinkgoInputNumber
                            style={{ width: 'calc(60% - 10px)' }}
                            readOnly={TradedReadOnly}
                        />
                    </Form.Item>
                )}

                <Form.Item label="Custodian Trade ID" name={['ext_info', 'custodian_trade_id']}>
                    <GinkgoInput style={{ width: 'calc(60% - 10px)' }} readOnly={TradedReadOnly} />
                </Form.Item>

                <DateItem
                    label="Trade Date"
                    name="trade_date"
                    width="calc(60% - 10px)"
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                    timezone={timezone}
                />
                <DateItem
                    label="Value Date"
                    name="value_date"
                    width="calc(60% - 10px)"
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                    timezone={timezone}
                />
            </Form>
        );
    }
);

export default EquitiesForm;
