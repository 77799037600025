import { TableColumnType } from 'antd';
import {
    AssetType,
    CapitalCallFrequency,
    FundStruct,
    RedemptionFrequency,
    UnitType,
} from '../Transaction/type';
import { PositionAssetRecord } from './type';
import moment from 'moment-timezone';
import { createHashHistory } from 'history';
import React from 'react';
import { stopPropagation } from '../RecommendationComponent/util';
import { getOptionType } from '../Transaction/Detail/utils';
import { FormatNumber } from '../../utils';
import { OptionSide } from '../GKProductPrice/Form/data';
const history = createHashHistory();
const totalTable: TableColumnType<PositionAssetRecord>[] = [
    {
        title: 'Total',
        dataIndex: 'index',
        width: 80,
    },
    {
        title: 'AMOUNT',
        dataIndex: 'amount',
        width: 80,
    },
];

const assetTable: TableColumnType<PositionAssetRecord>[] = [
    {
        title: 'Asset Type',
        dataIndex: 'index',
        width: 80,
    },
    {
        title: 'AMOUNT',
        dataIndex: 'amount',
        width: 80,
    },
];

const accountTable: TableColumnType<PositionAssetRecord>[] = [
    {
        title: 'Account Type',
        dataIndex: 'index',
        width: 80,
    },
    {
        title: 'AMOUNT',
        dataIndex: 'amount',
        width: 80,
    },
];

const dqTable: TableColumnType<PositionAssetRecord>[] = [
    {
        title: 'AQ',
        dataIndex: 'index',
        width: 80,
    },
    {
        title: 'Underlying',
        dataIndex: ['ext_info', 'ticker'],
        width: 80,
    },
    {
        title: 'Account',
        dataIndex: 'account',
        width: 220,
    },
    {
        title: 'Trade Date',
        dataIndex: ['ext_info', 'trade_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Strike$',
        dataIndex: ['ext_info', 'strike_price'],
        width: 80,
        render(value) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'Knock out$',
        dataIndex: ['ext_info', 'knock_out_price'],
        width: 80,
        render(value) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'Value Date',
        dataIndex: ['ext_info', 'value_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Maturity Date',
        dataIndex: ['ext_info', 'maturity_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Total Observation Days',
        dataIndex: ['ext_info', 'trade_days'],
        width: 40,
    },
    {
        title: 'Ended Days',
        dataIndex: ['ext_info', 'passed_trade_days'],
        width: 40,
    },
    {
        title: 'Daily Shares',
        dataIndex: ['ext_info', 'day_volume'],
        width: 40,
    },
    {
        title: 'Leveraged Daily Shares',
        dataIndex: ['ext_info', 'boosted_day_volume'],
        width: 40,
    },
    {
        title: 'Tradable No. Shares',
        dataIndex: ['ext_info', 'expected_total_volume'],
        width: 40,
    },
    {
        title: 'Leveraged Tradable No. Shares',
        dataIndex: ['ext_info', 'expected_boosted_total_volume'],
        width: 40,
    },
    {
        title: 'Actually Traded No. Shares',
        dataIndex: ['ext_info', 'actually_volume'],
        width: 40,
    },
    {
        title: 'Actually Traded Amount',
        dataIndex: ['ext_info', 'actually_amount'],
        width: 40,
    },
    {
        title: 'ISIN',
        dataIndex: 'isin',
    },
];

const elnTable: TableColumnType<PositionAssetRecord>[] = [
    {
        title: 'ELN',
        dataIndex: 'index',
        width: 80,
    },
    {
        title: 'Underlying',
        dataIndex: ['ext_info', 'ticker'],
        width: 80,
    },
    {
        title: 'Account',
        dataIndex: 'account',
        width: 220,
    },
    {
        title: 'Trade Date',
        dataIndex: ['ext_info', 'trade_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Value Date',
        dataIndex: ['ext_info', 'value_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Maturity Date',
        dataIndex: ['ext_info', 'maturity_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Annualised Rate of Return',
        dataIndex: ['ext_info', 'annualised_rate_of_return'],
        width: 80,
        render(value) {
            return FormatNumber(value, 2) + '%';
        },
    },
    {
        title: 'Face Value',
        dataIndex: ['ext_info', 'face_value'],
        width: 80,
        render(value) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'Underlying Price at Transaction',
        dataIndex: ['ext_info', 'fill_price'],
        width: 80,
        render(value) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'Strike$',
        dataIndex: ['ext_info', 'strike_price'],
        width: 80,
        render(value) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'Issue$',
        dataIndex: ['ext_info', 'issue_price'],
        width: 80,
        render(value) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'Knock out$',
        dataIndex: ['ext_info', 'knock_out_price'],
        width: 80,
        render(value) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'ISIN',
        dataIndex: 'isin',
    },
];

const fcnTable: TableColumnType<PositionAssetRecord>[] = [
    {
        title: 'FCN',
        dataIndex: 'index',
        width: 80,
    },
    {
        title: 'Underlying',
        dataIndex: ['ext_info', 'underlyings'],
        width: 80,
        render(value) {
            return value.join(',');
        },
    },
    {
        title: 'Account',
        dataIndex: 'account',
        width: 160,
    },
    {
        title: 'Trade Date',
        dataIndex: ['ext_info', 'trade_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Value Date',
        dataIndex: ['ext_info', 'value_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Maturity Date',
        dataIndex: ['ext_info', 'maturity_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Annualized Coupon',
        dataIndex: ['ext_info', 'annualized_coupon'],
        width: 80,
    },
    {
        title: 'Amount',
        dataIndex: ['ext_info', 'fill_amount'],
        width: 80,
    },
    {
        title: 'Coupon Received',
        dataIndex: ['ext_info', 'coupon_received'],
        width: 80,
        render(value) {
            return value ?? '-';
        },
    },
    {
        title: 'Underlying Price at Transaction',
        dataIndex: ['ext_info', 'fill_price'],
        width: 80,
        render(value) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'Strike$',
        dataIndex: ['ext_info', 'strike_price'],
        width: 80,
    },
    {
        title: 'Knock out$',
        dataIndex: ['ext_info', 'knock_out_price'],
        width: 80,
    },
    {
        title: 'ISIN',
        dataIndex: 'isin',
    },
];

const rcnTable: TableColumnType<PositionAssetRecord>[] = [
    {
        title: 'Other',
        dataIndex: 'index',
        width: 80,
    },
    {
        title: 'Account',
        dataIndex: 'account',
        width: 220,
    },
    {
        title: 'Amount',
        dataIndex: ['ext_info', 'amount'],
        width: 80,
    },
    {
        title: 'Trade Date',
        dataIndex: ['ext_info', 'trade_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },

    {
        title: 'ISIN',
        dataIndex: 'isin',
    },
    {
        title: 'Asset Name',
        dataIndex: ['ext_info', 'asset_name'],
        width: 80,
    },
    {
        title: 'Description',
        dataIndex: ['ext_info', 'description'],
        width: 80,
    },
];

const optionsTable: TableColumnType<PositionAssetRecord>[] = [
    {
        title: 'Options',
        dataIndex: 'index',
        width: 80,
    },
    {
        title: 'Type',
        dataIndex: ['ext_info', 'option_type'],
        width: 80,
        render(value, recode) {
            return OptionSide[recode.ext_info.option_side] + ' ' + getOptionType(value);
        },
    },
    {
        title: 'Underlying',
        dataIndex: ['ext_info', 'ticker'],
        width: 80,
    },
    {
        title: 'Account',
        dataIndex: 'account',
        width: 220,
    },
    {
        title: 'Trade Date',
        dataIndex: ['ext_info', 'trade_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Value Date',
        dataIndex: ['ext_info', 'value_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Maturity Date',
        dataIndex: ['ext_info', 'maturity_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Number of Shares',
        dataIndex: ['ext_info', 'number_of_shares'],
        width: 80,
    },
    {
        title: 'Option Fee%',
        dataIndex: ['ext_info', 'option_fee_rate'],
        width: 80,
        render(value) {
            return FormatNumber(value, 2) + '%';
        },
    },
    {
        title: 'Option Fee$',
        dataIndex: ['ext_info', 'option_fee'],
        width: 80,
        render(value) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'Underlying Price at Transaction',
        dataIndex: ['ext_info', 'fill_price'],
        width: 80,
        render(value) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'Breakeven Price',
        dataIndex: ['ext_info', 'break_even_price'],
        width: 80,
        render(value) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'Strike$',
        dataIndex: ['ext_info', 'strike_price'],
        width: 80,
        render(value) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'Annualised Rate of Return',
        dataIndex: ['ext_info', 'annualised_rate_of_return'],
        width: 80,
        render(value) {
            return value || '-';
        },
    },
    {
        title: 'ISIN',
        dataIndex: 'isin',
    },
];

const bondTables: TableColumnType<PositionAssetRecord>[] = [
    {
        title: 'Bonds',
        dataIndex: 'index',
        width: 80,
    },
    {
        title: 'Proportion',
        dataIndex: ['ext_info', 'proportion'],
        width: 80,
        render(value) {
            return value + '%';
        },
    },
    {
        title: 'Type',
        dataIndex: ['asset_type', 'desc'],
        width: 80,
    },
    {
        title: 'Issuer',
        dataIndex: ['ext_info', 'issuer_en'],
        width: 80,
        render(value, record) {
            if (record.asset_type.code === AssetType.CorporateBond) {
                return value;
            } else {
                return '-';
            }
        },
    },
    {
        title: 'Ticker',
        dataIndex: ['ext_info', 'ticker'],
        width: 80,
    },
    {
        title: 'Account',
        dataIndex: 'account',
        width: 160,
    },
    {
        title: 'Maturity Date',
        dataIndex: ['ext_info', 'maturity_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Notional Amount',
        dataIndex: 'amount',
        width: 80,
    },
    {
        title: 'Present Value',
        dataIndex: ['ext_info', 'present_value'],
        width: 80,
    },
    {
        title: 'Settlement Amount',
        dataIndex: ['ext_info', 'cash_amount'],
        width: 80,
    },
    {
        title: 'Annualized Coupon',
        dataIndex: ['ext_info', 'coupon'],
        width: 80,
    },
    {
        title: 'Coupon Received',
        dataIndex: ['ext_info', 'coupon_received'],
        width: 80,
        render(value) {
            return value ?? '-';
        },
    },
    {
        title: 'Yield to Maturity',
        dataIndex: ['ext_info', 'ytm'],
        width: 80,
    },
    {
        title: 'Yield to Worst',
        dataIndex: ['ext_info', 'ytw'],
        width: 80,
    },
    {
        title: 'ISIN',
        dataIndex: 'isin',
    },
];
const stockTables: TableColumnType<PositionAssetRecord>[] = [
    {
        title: 'Stock',
        dataIndex: 'index',
        width: 80,
    },
    {
        title: 'Proportion',
        dataIndex: ['ext_info', 'proportion'],
        width: 80,
        render(value) {
            return value || '-';
        },
    },
    {
        title: 'Ticker',
        dataIndex: ['ext_info', 'ticker'],
        width: 80,
    },
    {
        title: 'Account',
        dataIndex: 'account',
        width: 220,
    },
    {
        title: 'Quantity',
        dataIndex: 'amount',
        width: 80,
    },
    {
        title: 'Cost',
        dataIndex: ['ext_info', 'cost'],
        width: 80,
        render(value, record, index) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'Current Stock Price',
        dataIndex: ['ext_info', 'market_price'],
        width: 80,
        render(value, record, index) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'Present Value',
        dataIndex: ['ext_info', 'present_value'],
        width: 80,
        render(value, record, index) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'Profit & Loss',
        dataIndex: ['ext_info', 'profit_or_loss'],
        width: 80,
        render(value, record, index) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'ISIN',
        dataIndex: 'isin',
    },
];

const fundTable: TableColumnType<PositionAssetRecord>[] = [
    {
        title: 'Fund',
        dataIndex: 'index',
        width: 80,
    },
    {
        title: 'Name',
        dataIndex: ['ext_info', 'asset_name'],
        width: 80,
    },
    {
        title: 'Account',
        dataIndex: 'account',
        width: 220,
    },
    {
        title: 'Fund Struct',
        dataIndex: ['ext_info', 'fund_struct_type'],
        width: 80,
        render(value) {
            return FundStruct[value || 1];
        },
    },
    {
        title: 'Cost Amount',
        dataIndex: ['ext_info', 'invest_cost_amount'],
        width: 80,
        render(value) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'Redemption Frequency',
        dataIndex: ['ext_info', 'redemption_frequency_type'],
        width: 80,
        render(value) {
            if (!value) return '-';
            return RedemptionFrequency[value as keyof typeof RedemptionFrequency];
        },
    },
    {
        title: 'Committed Capital',
        dataIndex: ['ext_info', 'committed_capital'],
        width: 80,
        render(value) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'Contributed Amount',
        dataIndex: ['ext_info', 'contributed_amount'],

        width: 80,
        render(value) {
            return FormatNumber(value, 3) || '-';
        },
    },
    {
        title: 'Capital Call Frequency',
        dataIndex: ['ext_info', 'capital_call_frequency_type'],
        width: 80,
        render(value) {
            if (!value) return '-';
            return CapitalCallFrequency[value as keyof typeof CapitalCallFrequency];
        },
    },
    {
        title: 'Unit Type',
        dataIndex: ['ext_info', 'fund_struct_type'],
        width: 80,
        render(value) {
            if (!value) return '-';
            return UnitType[value || 1];
        },
    },
    {
        title: 'Units',
        dataIndex: ['ext_info', 'fund_struct_type'],
        width: 80,
        render(value, record) {
            if (record.ext_info.fund_struct_type === 1) {
                return FormatNumber(record.amount, 3) || '-';
            }
            return '-';
        },
    },
    {
        title: 'NAV',
        dataIndex: ['ext_info', 'nav'],
        width: 80,
        render(value) {
            return value || '-';
        },
    },
    {
        title: 'ISIN',
        dataIndex: 'isin',
    },
];

const cashTable: TableColumnType<PositionAssetRecord>[] = [
    {
        title: 'Cash',
        dataIndex: 'index',
        width: 80,
    },
    {
        title: 'Currency',
        dataIndex: 'currency',
        width: 80,
    },
    {
        title: 'Account',
        dataIndex: 'account',
        width: 220,
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
    },
];

const loanTable: TableColumnType<PositionAssetRecord>[] = [
    {
        title: 'Loan',
        dataIndex: 'index',
        width: 80,
    },
    {
        title: 'Currency',
        dataIndex: 'currency',
        width: 80,
    },
    {
        title: 'Account',
        dataIndex: 'account',
        width: 220,
    },
    {
        title: 'Trade Date',
        dataIndex: ['ext_info', 'trade_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Value Date',
        dataIndex: ['ext_info', 'value_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Maturity Date',
        dataIndex: ['ext_info', 'maturity_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Principal Amount',
        dataIndex: ['ext_info', 'principal_amount'],
        width: 80,
    },
    {
        title: 'Interest Rate',
        dataIndex: ['ext_info', 'interest_rate'],
    },
];

const depositTable: TableColumnType<PositionAssetRecord>[] = [
    {
        title: 'Deposit',
        dataIndex: 'index',
        width: '40px',
    },
    {
        title: 'Currency',
        dataIndex: 'currency',
        width: 80,
    },
    {
        title: 'Account',
        dataIndex: 'account',
        width: 220,
    },
    {
        title: 'Trade Date',
        dataIndex: ['ext_info', 'trade_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Value Date',
        dataIndex: ['ext_info', 'value_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Maturity Date',
        dataIndex: ['ext_info', 'maturity_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Principal Amount',
        dataIndex: ['ext_info', 'principal_amount'],
        width: 80,
    },
    {
        title: 'Interest Rate',
        dataIndex: ['ext_info', 'interest_rate'],
        width: 80,
    },
    {
        title: 'Interest Amount',
        dataIndex: ['ext_info', 'interest_amount'],
        width: 80,
    },
    {
        title: 'Total Amount',
        dataIndex: ['ext_info', 'total_amount'],
    },
];
const ncdTable: TableColumnType<PositionAssetRecord>[] = [
    {
        title: 'NCD',
        dataIndex: 'ncd',
        width: 80,
    },
    {
        title: 'Issuer',
        dataIndex: 'issuer',
        width: 80,
    },
    {
        title: 'Currency',
        dataIndex: 'currency',
        width: 80,
    },
    {
        title: 'Account',
        dataIndex: 'account',
        width: 220,
    },
    {
        title: 'Trade Date',
        dataIndex: ['ext_info', 'trade_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Value Date',
        dataIndex: ['ext_info', 'value_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Maturity Date',
        dataIndex: ['ext_info', 'maturity_date'],
        width: 110,
        render(value, record, index) {
            if (!value) return '-';
            return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
        },
    },
    {
        title: 'Nominal Amount',
        dataIndex: 'nominal_amount',
        width: 80,
    },
    {
        title: 'Client Price',
        dataIndex: 'client_price',
        width: 80,
    },
    {
        title: 'Coupon Rate',
        dataIndex: 'coupon_rate',
        width: 80,
    },
    {
        title: 'ISIN',
        dataIndex: 'isin',
    },
];
const tables = {
    totalTable,
    assetTable,
    accountTable,
    dqTable,
    elnTable,
    fcnTable,
    rcnTable,
    optionsTable,
    bondTables,
    stockTables,
    fundTable,
    cashTable,
    loanTable,
    depositTable,
    ncdTable,
};

Object.values(tables).forEach((table) => {
    table.forEach((column, index) => {
        if (index === 0) {
            column.render = (value, record, index) => {
                return index + 1;
            };
            column.width = '40px';
        }
        if ((column.title as string).indexOf('DATE') > -1) {
            column.render = (value, record, index) => {
                if (!value) return '-';
                return moment.tz(value, record.time_zone).format('YYYY-MM-DD');
            };
        }
        if (column.title === 'Account') {
            column.render = (value, record, index) => {
                return (
                    <span
                        className="lk_link"
                        onClick={(event) => {
                            history.push(
                                `/app/assetManagement/position/subAccount/${record.account.account_id}/${record.client_id}/${record.client.name}`
                            );
                            stopPropagation(event);
                        }}
                    >{`${record.client?.name}(${record.client_id})-${record.account?.bank_name}-${record.account?.vendor_sub_account_id}`}</span>
                );
            };
        }
    });
});
// export enum AssetType {
//     Cash = 101001,
//     FixedDeposit = 101002,
//     NCD = 101003,
//     Equities = 102001,
//     AQ = 102002,
//     DQ = 102003,
//     Option = 102004,
//     CorporateBond = 103001,
//     GovernmentBond = 103002,
//     Fund = 104001,
//     PE = 104002,
//     ETF = 104003,
//     XAU = 105001,
//     REITs = 106001,
//     FCN = 107001,
//     ELN = 107002,
//     SDN = 107003,
//     RCN = 107004,
//     Loan = 201001,
//     Other = 901001,
// }
export const getColumnsFromAssetType = (assetType: AssetType, withClick: boolean) => {
    let finalList: TableColumnType<PositionAssetRecord>[] = [];
    switch (assetType) {
        case AssetType.Cash:
            finalList = cashTable;
            break;
        case AssetType.FixedDeposit:
            finalList = depositTable;
            break;
        case AssetType.NCD:
            finalList = ncdTable;
            break;
        case AssetType.Equities:
            finalList = stockTables;
            break;
        case AssetType.AQ:
            finalList = dqTable;
            break;
        case AssetType.DQ:
            let ret = [...dqTable];
            ret[0] = {
                ...ret[0],
                title: 'DQ',
            };
            finalList = ret;
            break;
        case AssetType.Option:
            finalList = optionsTable;
            break;
        case AssetType.CorporateBond:
        case AssetType.GovernmentBond:
            finalList = bondTables;
            break;
        case AssetType.Fund:
        case AssetType.PE:
        case AssetType.ETF:
            finalList = fundTable;
            break;
        case AssetType.FCN:
            finalList = fcnTable;
            break;
        case AssetType.ELN:
            finalList = elnTable;
            break;
        case AssetType.SDN:
        case AssetType.Other:
        case AssetType.RCN:
            finalList = rcnTable;
            break;
        case AssetType.Loan:
            finalList = loanTable;
            break;
        default:
            return [];
    }
    if (!withClick) {
        return finalList.map((item) => {
            if (item.title === 'Account') {
                let newItem = { ...item };
                newItem.render = (value, record, index) => {
                    return `${record.client?.name}(${record.client_id})-${record.account?.bank_name}-${record.account?.vendor_sub_account_id}`;
                };
                return newItem;
            }
            return item;
        });
    } else {
        return finalList;
    }
};
export default tables;
