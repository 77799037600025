import axios from '../tools';

// 数组第一个元素是生产id 第二个元素是测试id
const map = new Map([
    ['GlobalIndex', [379, 379]],
    ['Overview', [380, 397]],
    ['CustomersCount', [381, 398]],
    ['CustomersState', [387, 399]],
    ['NewCustomers', [390, 388]],
    ['TradeAmount', [426, 427]],
    ['TradeAmountChart', [429, 428]],
    ['TradeMonthCount', [404, 424]],
    ['TradeMonthAccount', [405, 425]],
    ['TradeWeekCount', [393, 422]],
    ['TradeWeekType', [394, 423]],
    ['ProductPositionCount', [384, 420]],
    ['ProductPositionType', [383, 421]],
    ['ProductStructuralCount', [386, 419]],
    ['ProductStructuralType', [385, 418]],
    ['ProductStatementCount', [431, 430]],
    ['ProductStatementType', [433, 432]],
    ['AppDau', [413, 412]],
    ['AppWau', [410, 411]],
    ['AppMau', [414, 415]],
    ['AppUserActive', [416, 417]],
    ['AppUnactiveAccount', [395, 389]],
    ['AppActiveAccount', [403, 400]],
    ['AppWauChart', [407, 406]],
    ['AppWpvChart', [409, 408]],
]);
const idx = process.env.REACT_ADMIN_ENV === 'dev' ? 1 : 0;
export enum QuestionId {
    GlobalIndex = map.get('GlobalIndex')![idx],
    Overview = map.get('Overview')![idx],
    CustomersCount = map.get('CustomersCount')![idx],
    CustomersState = map.get('CustomersState')![idx],
    NewCustomers = map.get('NewCustomers')![idx],
    TradeAmount = map.get('TradeAmount')![idx],
    TradeAmountChart = map.get('TradeAmountChart')![idx],
    TradeMonthCount = map.get('TradeMonthCount')![idx],
    TradeMonthAccount = map.get('TradeMonthAccount')![idx],
    TradeWeekCount = map.get('TradeWeekCount')![idx],
    TradeWeekType = map.get('TradeWeekType')![idx],
    ProductPositionCount = map.get('ProductPositionCount')![idx],
    ProductPositionType = map.get('ProductPositionType')![idx],
    ProductStructuralCount = map.get('ProductStructuralCount')![idx],
    ProductStructuralType = map.get('ProductStructuralType')![idx],
    ProductStatementCount = map.get('ProductStatementCount')![idx],
    ProductStatementType = map.get('ProductStatementType')![idx],
    AppDau = map.get('AppDau')![idx],
    AppWau = map.get('AppWau')![idx],
    AppMau = map.get('AppMau')![idx],
    AppUserActive = map.get('AppUserActive')![idx],
    AppUnactiveAccount = map.get('AppUnactiveAccount')![idx],
    AppActiveAccount = map.get('AppActiveAccount')![idx],
    AppWauChart = map.get('AppWauChart')![idx],
    AppWpvChart = map.get('AppWpvChart')![idx],
}

export const getDashboardData = (data: { question_id: number; question_params?: any }) => {
    return axios.post('/v1/bi/question/query_as_kvlist', { data });
};

export const getDashboardDataAsColTable = (data: {
    question_id: number;
    question_params?: any;
}) => {
    return axios.post('/v1/bi/question/query_as_col_table', { data });
};
