import React from 'react';
import styles from './GlobalIndex.module.scss';
import classnames from 'classnames';
import useFetch from '../../../utils/Hooks/useFetch';
import { QuestionId, getDashboardData } from '../../../service/requests/Dashboard';

interface IndexEntity {
    ticker: string;
    name: string;
    close_price: number;
    change: number;
    change_rate: number;
    date: string;
}

const GlobalIndex = () => {
    const [fetchState] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.GlobalIndex,
        },
    });

    const formatNumber = (num: number) => {
        return (num >= 0 ? '+' : '') + num.toFixed(2);
    };
    const getIndexStatus = (num: number) => {
        return num >= 0 ? 'rise' : 'fall';
    };
    const formatRate = (num: number) => {
        return `(${num >= 0 ? '+' : ''}${num.toFixed(2)}%)`;
    };
    return (
        <>
            {fetchState.data && (
                <div className={styles.index}>
                    {fetchState.data.map((i: IndexEntity) => (
                        <div
                            key={i.ticker}
                            className={classnames({
                                [styles['indexItem']]: true,
                                [styles[getIndexStatus(i.change_rate)]]: true,
                            })}
                        >
                            <div>{i.ticker}</div>
                            <div>{i.name}</div>
                            <div>{i.close_price}</div>
                            <div className={styles.fluctuation}>
                                <span>{formatNumber(i.change)} </span>
                                <span>{formatRate(i.change_rate)} </span>
                            </div>
                            <div className={styles.date}>{`更新日期: ${i.date}`}</div>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default GlobalIndex;
