import { Form, FormInstance, Input, Select } from 'antd';
import React from 'react';
import { BondType } from '../../request';

interface BondFormProps {
    form: FormInstance;
    type: BondType;
    modalType: string;
    creditRatings: string[];
    senioritys: string[];
}
const BondForm = (props: BondFormProps) => {
    const { form, creditRatings, senioritys, type, modalType } = props;
    return (
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <Form.Item label="ISIN" name="isin" rules={[{ required: true, message: '请输入ISIN' }]}>
                <Input disabled={modalType === 'edit'} />
            </Form.Item>
            {type === BondType.corporate && (
                <>
                    <Form.Item
                        label="发行方-中"
                        name="issuer_cn"
                        rules={[{ required: true, message: '请输入发行方-中' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="信用评级S&P"
                        name="credit_rating_sp"
                        rules={[{ required: true, message: '请选择信用评级S&P' }]}
                    >
                        <Select
                            options={creditRatings.map((r) => ({
                                label: r,
                                value: r,
                            }))}
                        />
                    </Form.Item>
                    <Form.Item
                        label="优先级"
                        name="seniority"
                        rules={[{ required: true, message: '请输入优先级' }]}
                    >
                        <Select
                            options={senioritys.map((r) => ({
                                label: r,
                                value: r,
                            }))}
                        />
                    </Form.Item>
                </>
            )}
        </Form>
    );
};

export default BondForm;
