import { green, orange } from '@ant-design/colors';
import { CheckOutlined } from '@ant-design/icons';
import { ColDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Button, List, message, Modal, Tabs, Tag } from 'antd';
import Title from 'antd/lib/typography/Title';
import copy from 'copy-to-clipboard';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LookButton from '../../../components/LookButton';
import { getAllCustomerLists } from '../../../service';
import { CustomList, queryEditEntryType, quotePreview } from '../../../service/type';
import { showError } from '../../RecommendationComponent/util';
import './index.less';
import {
    getMultiQuoteEntries,
    getProductQuotePreview,
    getMultiProductQuoteUrl,
    QuoteSheetList,
} from './requests';

export default function MultiCustomerQuote() {
    const [customerList, setCustomerList] = useState<CustomList['customer_list']>();
    const [customerSelected, setCustomerSelected] = useState<Array<{ id: number; name: string }>>(
        []
    );
    const [entries, setEntries] = useState<queryEditEntryType['entries']>();
    const [curProductType, setCurProductType] = useState('');

    const [pendingPreview, setPendingPreview] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    const [quotes, setQuotes] = useState<quotePreview['quotes']>();

    const [showLink, setShowLink] = useState(false);

    const tableCols = useMemo<ColDef[]>(
        () =>
            quotes?.titles.map<ColDef>((title, index) => ({
                headerName: title,
                valueGetter: (params: ValueGetterParams) => params.data[index].value,
                width: 96,
                resizable: true,
            })) ?? [],
        [quotes]
    );

    useEffect(() => {
        getAllCustomerLists({ searchKey: '' }).then((res) => {
            setCustomerList(res.data.customer_list);
        }, showError);
        getMultiQuoteEntries().then((res) => {
            setEntries(res.data.entries);
            setCurProductType(res.data.entries[0].product_type);
        }, showError);
    }, []);

    const handleSelectCustomer = useCallback(
        (customer: { id: number; name: string }) => {
            let index = customerSelected.findIndex((item) => item.id === customer.id);
            let newArray = customerSelected.slice();
            if (index !== -1) {
                newArray.splice(index, 1);
            } else {
                newArray.push({
                    id: customer.id,
                    name: customer.name,
                });
            }
            setCustomerSelected(newArray);
        },
        [customerSelected]
    );

    const customerIds = useMemo(() => customerSelected.map((item) => item.id), [customerSelected]);
    const editEntryUrl = useMemo(
        () => entries?.find((entry) => entry.product_type === curProductType)?.edit_entry_url ?? '',
        [curProductType, entries]
    );

    const handleClickPreview = useCallback(() => {
        setPendingPreview(true);
        getProductQuotePreview({
            customer_id_list: customerIds,
            product_type: curProductType,
            edit_entry_url: editEntryUrl,
        })
            .then((res) => {
                setQuotes(res.data.quotes);
                setShowPreview(true);
            }, showError)
            .finally(() => setPendingPreview(false));
    }, [curProductType, customerIds, editEntryUrl]);

    const [quoteList, setQuoteList] = useState<QuoteSheetList['sheet_customer_list']>();

    const linkTableCols = useMemo<ColDef[]>(
        () => [
            { field: 'customer_name', headerName: '客户名' },
            {
                flex: 1,
                headerName: '操作',
                cellRenderer: (params: ICellRendererParams) => {
                    let urlPrefix = {
                        dev: 'https://portal-test.ilookcap.com',
                        stage: 'https://portal-stage.ilookcap.com',
                        production: 'https://portal.ilookcap.com',
                    }[process.env.REACT_ADMIN_ENV ?? 'production'];
                    let data = params.data as QuoteSheetList['sheet_customer_list'][number];
                    let customerId =
                        customerSelected.find((item) => item.name === data.customer_name)?.id ?? 0;
                    return (
                        <Button
                            type="primary"
                            onClick={() => {
                                if (
                                    copy(
                                        `${urlPrefix}/order/options/${customerId}/${data.product_quote_sheet_id}`
                                    )
                                ) {
                                    message.success('复制链接成功');
                                } else {
                                    message.error('复制链接失败');
                                }
                            }}
                        >
                            复制报价单链接
                        </Button>
                    );
                },
            },
        ],
        [customerSelected]
    );

    const [pendingLink, setPendingLink] = useState(false);

    const handleShowLink = useCallback(() => {
        setPendingLink(true);
        getMultiProductQuoteUrl({
            customer_id_list: customerIds,
            product_type: curProductType,
            edit_entry_url: editEntryUrl,
        })
            .then((res) => {
                setQuoteList(res.data.sheet_customer_list);
                setShowPreview(false);
                setShowLink(true);
            }, showError)
            .finally(() => setPendingLink(false));
    }, [curProductType, customerIds, editEntryUrl]);

    console.log(customerSelected.length);

    return (
        <div className="Quote_Container">
            <div className="Quote_DocContainer">
                <Tabs activeKey={curProductType} onChange={(key) => setCurProductType(key)}>
                    {entries?.map((entry) => (
                        <Tabs.TabPane tab={entry.product_type} key={entry.product_type}>
                            <iframe src={entry.edit_entry_url} title={entry.product_type} />
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </div>
            <div className="Quote_SelectContainer">
                <LookButton
                    variant="filled"
                    color="#237804"
                    disabled={!customerSelected.length || pendingPreview}
                    onClick={handleClickPreview}
                >
                    生成预览
                </LookButton>
                <Title level={4} style={{ marginTop: '32px' }}>
                    选择报价客户
                </Title>
                <div className="Quote_TagContainer">
                    {customerSelected.length ? (
                        customerSelected.map((customer) => (
                            <Tag
                                color="blue"
                                closable
                                key={customer.id}
                                onClose={() => handleSelectCustomer(customer)}
                            >
                                {customer.name}
                            </Tag>
                        ))
                    ) : (
                        <div className="Quote_TagNone">未选择客户</div>
                    )}
                </div>
                <Title level={5} style={{ margin: '4px 0' }}>
                    客户列表
                </Title>
                <List
                    dataSource={customerList}
                    renderItem={(item) => (
                        <List.Item
                            onClick={() => handleSelectCustomer(item)}
                            className={`${
                                customerSelected.find((customer) => customer.id === item.id)
                                    ? 'Quote_ListItem--selected'
                                    : ''
                            }`}
                        >
                            <span>
                                {item.id} - {item.name}
                            </span>
                            <CheckOutlined />
                        </List.Item>
                    )}
                />
            </div>
            <Modal
                title="报价预览"
                closable={false}
                footer={null}
                visible={showPreview}
                onCancel={() => setShowPreview(false)}
                width={1240}
                destroyOnClose
            >
                <div className="ag-theme-alpine">
                    <AgGridReact
                        columnDefs={tableCols}
                        rowData={quotes?.cells}
                        domLayout="autoHeight"
                        onFirstDataRendered={({ columnApi }) => columnApi.autoSizeAllColumns()}
                    />
                </div>
                <div style={{ fontSize: 16, fontWeight: 500, marginTop: '16px' }}>
                    报价对象：{customerSelected.map((item) => item.name).join('，')}
                </div>
                <div className="Modal_Actions">
                    <LookButton
                        onClick={() => setShowPreview(false)}
                        color={orange[7]}
                        variant="filled"
                    >
                        返回编辑
                    </LookButton>
                    <LookButton
                        color={green[7]}
                        variant="filled"
                        onClick={handleShowLink}
                        disabled={pendingLink}
                    >
                        生成报价页
                    </LookButton>
                </div>
            </Modal>
            <Modal title="报价页链接" closable={false} footer={null} visible={showLink}>
                <div className="ag-theme-alpine">
                    <AgGridReact
                        columnDefs={linkTableCols}
                        rowData={quoteList}
                        domLayout="autoHeight"
                    />
                </div>
                <div className="Modal_Actions">
                    <LookButton
                        color={green[7]}
                        variant="filled"
                        onClick={() => {
                            setShowLink(false);
                            setShowPreview(true);
                        }}
                    >
                        返回报价预览
                    </LookButton>
                    <LookButton
                        color={orange[7]}
                        variant="filled"
                        onClick={() => {
                            setShowLink(false);
                            setShowPreview(false);
                        }}
                    >
                        退出
                    </LookButton>
                </div>
            </Modal>
        </div>
    );
}
