import axios from '../../service/tools';
import { Asset } from '../Transaction/type';
export interface ProductPriceListReq {
    account_id?: string;
    current_page?: number;
    page_size?: number;
    asset_type_code?: number;

    ext_info: {
        underlyings?: string[];
        update_time_type?: number;
        bank_ids?: string[];
        residual_maturity_from?: string;
        residual_maturity_to?: string;
        sort_field?: string;
        sort_order?: string;
        asset_name?: string;
        fund_name?: string;
        fund_manager?: string;
        filter_type?: number; //"AND(1, ""and""), OR(2, ""or"")"
    };
}
export interface ProductPriceType {
    total_count: number;
    total_pages: number;
    current_page: number;
    page_size: number;
    assets: Asset[];
    price_update_at?: number;
}
export interface FilterCriteria {
    assets: Asset[];
    banks: {
        id: string;
        name: string;
        short_name: string;
    }[];
    update_time_types: {
        code: number;
        desc: string;
    }[];
}
export interface ProductPriceAddReq {
    asset: Asset;
}
export const getProductPriceList = (data: ProductPriceListReq) => {
    return axios.post<ProductPriceType>('gk/v1/asset/price/query', { data });
};

export const getAccountAllProductPriceList = (data: ProductPriceListReq) => {
    return axios.post<ProductPriceType>('gk/v1/asset/quote/price/query_all', { data });
};

export const getAllProductPriceList = (data: ProductPriceListReq) => {
    return axios.post<ProductPriceType>('gk/v1/asset/price/query_all', { data });
};

export const queryFilterCriteria = (data: { asset_type_code: number }) => {
    return axios.post<FilterCriteria>('gk/v1/asset/price/filter_criteria/query', { data });
};

export const queryAccountFilterCriteria = (data: {
    asset_type_code: number;
    account_id: string;
}) => {
    return axios.post<FilterCriteria>('gk/v1/asset/quote/price/filter_criteria/query', { data });
};
export interface FundType {
    fund_name: string;
    fund_manager: string;
    id: number;
}
export const addProductPrice = (data: ProductPriceAddReq) => {
    return axios.post('gk/v1/asset/add', { data });
};

export const deleteProductPrice = (data: { isin: string }) => {
    return axios.post('gk/v1/asset/hide', { data });
};

export const updateProductPrice = (data: ProductPriceAddReq) => {
    return axios.post('gk/v1/asset/update', { data });
};

export const getBondCreditRatings = () => {
    return axios.post('gk/v1/asset/bond/credit_ratings', {});
};
export const searchFund = (data: { keyword: string }) => {
    return axios.post<{ list: FundType[] }>('gk/v1/asset/fund/search', { data });
};
export const getBondCreditPriorities = () => {
    return axios.post('gk/v1/asset/bond/priorities', {});
};
