// 0 English 1 chinese
const getTrans = (obj: Record<string, string[]>, idx: 0 | 1) => {
    const keys = Object.keys(obj);
    let ret = {} as any;
    keys.forEach((k) => {
        ret[k] = obj[k][idx];
    });
    return ret;
};

export { getTrans };
