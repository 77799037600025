import { Form } from 'antd';
import React from 'react';
import LKRadioGroup, { LKRadioGroupProps } from '../../../../../../components/LKRadioGroup';

const TenorItem = ({
    readOnly,
    required,
    type = 'year',
}: {
    readOnly?: boolean;
    required?: boolean;
    type?: 'year' | 'onlyMonth';
}) => {
    const dataSource: LKRadioGroupProps['datasource'] =
        type === 'year'
            ? [
                  {
                      label: '7D',
                      value: '7D',
                  },
                  {
                      label: '14D',
                      value: '14D',
                  },
                  {
                      label: '1M',
                      value: '1M',
                  },
                  {
                      label: '2M',
                      value: '2M',
                  },
                  {
                      label: '3M',
                      value: '3M',
                  },
                  {
                      label: '4M',
                      value: '4M',
                  },
                  {
                      label: '5M',
                      value: '5M',
                  },
                  {
                      label: '6M',
                      value: '6M',
                  },
                  {
                      label: '7M',
                      value: '7M',
                  },
                  {
                      label: '8M',
                      value: '8M',
                  },
                  {
                      label: '9M',
                      value: '9M',
                  },
                  {
                      label: '10M',
                      value: '10M',
                  },
                  {
                      label: '11M',
                      value: '11M',
                  },
                  {
                      label: '1Y',
                      value: '1Y',
                  },
              ]
            : [
                  {
                      label: '1M',
                      value: '1M',
                  },
                  {
                      label: '2M',
                      value: '2M',
                  },
                  {
                      label: '3M',
                      value: '3M',
                  },
                  {
                      label: '4M',
                      value: '4M',
                  },
                  {
                      label: '5M',
                      value: '5M',
                  },
                  {
                      label: '6M',
                      value: '6M',
                  },
                  {
                      label: '7M',
                      value: '7M',
                  },
                  {
                      label: '8M',
                      value: '8M',
                  },
                  {
                      label: '9M',
                      value: '9M',
                  },
                  {
                      label: '10M',
                      value: '10M',
                  },
                  {
                      label: '11M',
                      value: '11M',
                  },
                  {
                      label: '12M',
                      value: '12M',
                  },
                  {
                      label: '24M',
                      value: '24M',
                  },
              ];
    return (
        <Form.Item
            name={['ext_info', 'tenor']}
            label="Tenor"
            rules={[{ required: required, message: 'Tenor is required' }]}
        >
            <LKRadioGroup datasource={dataSource} itemWidth="12.5%" readOnly={readOnly} />
        </Form.Item>
    );
};

export default TenorItem;
