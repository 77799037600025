import React from 'react';
import LKRadioGroup from '../../../../../../components/LKRadioGroup';
import { BankType } from '../../../../type';

const BankItem = ({
    readOnly,
    onChange,
    value,
}: {
    readOnly?: boolean;
    onChange?: (value: keyof typeof BankType) => void;
    value?: keyof typeof BankType;
}) => {
    const dataSource = Object.keys(BankType).map((key) => ({
        label: key,
        value: BankType[key as keyof typeof BankType],
    }));
    return (
        <LKRadioGroup
            datasource={dataSource}
            value={value}
            itemWidth="12.5%"
            readOnly={readOnly}
            onChange={(value) => onChange && onChange(value as keyof typeof BankType)}
        />
    );
};

export default BankItem;
