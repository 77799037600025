import { DatePicker, DatePickerProps } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

type LKDatePickerProps = DatePickerProps & {
    onChange?: (value: number | undefined) => void;
    readOnly?: boolean;
    value?: number;
    defaultPickerValue?: number;
    addonBefore?: React.ReactNode;
    timezone?: string;
};
const LKDatePicker = (data: LKDatePickerProps) => {
    const [value, setValue] = useState<number | undefined>();
    const [timezone, setTimezone] = useState(data.timezone || moment.tz.guess());
    useEffect(() => {
        if (data.value) {
            setValue(moment(moment.tz(data.value, timezone).format('yyyy-MM-DD')).valueOf());
        }
    }, [data.value, timezone]);

    useEffect(() => {
        setTimezone((preTimezone) => {
            let newTimezone = data.timezone || moment.tz.guess();
            data.onChange &&
                data.onChange(
                    moment
                        .tz(moment.tz(data.value, preTimezone).format('yyyy-MM-DD'), newTimezone)
                        .valueOf()
                );
            return newTimezone;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.timezone]);

    return data.readOnly ? (
        <div className="LK_Form_ReadOnly">{moment(value).format('YYYY-MM-DD')}</div>
    ) : (
        <>
            {data.addonBefore}
            <DatePicker
                {...data}
                value={value ? moment(value) : undefined}
                defaultPickerValue={
                    data.defaultPickerValue ? moment(data.defaultPickerValue) : undefined
                }
                onChange={(value, dateStr) => {
                    if (value) {
                        setValue(moment(dateStr).valueOf());
                        data.onChange &&
                            data.onChange(
                                moment.tz(dateStr, timezone || moment.tz.guess()).valueOf()
                            );
                    } else {
                        setValue(undefined);
                        data.onChange && data.onChange(undefined);
                    }
                }}
            />
        </>
    );
};
export default LKDatePicker;
