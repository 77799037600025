import React, { useState } from 'react';
import styles from './index.module.scss';
import { Table } from 'antd';
import { Asset, AssetType } from '../../Transaction/type';
import { PriceTableHook } from '../PriceHook';

interface ProductsPriceTableProps {
    type: AssetType;
    data: Asset[];
    onRemoveClick: (asset: Asset) => void;
    onEditClick: (asset: Asset) => void;
    onCopy: (asset: Asset) => void;
    onFiledSort: (item: any) => void;
    tableHeight?: number;
    defaultSelectedKeys?: React.Key[];
    onSelectionChange?: (quodations: Asset[]) => void;
}
const ProductsPriceTable = ({
    type,
    data,
    onRemoveClick,
    onEditClick,
    onFiledSort,
    tableHeight,
    defaultSelectedKeys,
    onCopy,
    onSelectionChange,
}: ProductsPriceTableProps) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(defaultSelectedKeys || []);
    const [selectedItems, setSelectedItems] = useState<Asset[]>(() => {
        return data?.filter((item) => defaultSelectedKeys?.includes(item.isin!)) ?? [];
    });
    const { columns } = PriceTableHook({
        type,
        onEdit: onEditClick,
        onRemove: onRemoveClick,
        onCopy: onCopy,
        withoutAction: onSelectionChange ? true : false,
    });
    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        const unselectedKeys = data
            .map((i) => i.isin)
            .filter((i) => !newSelectedRowKeys.includes(i!));
        const selectionItem = data.filter((i) => newSelectedRowKeys.includes(i.isin!));
        //为了区分没有被选中的 item 遍历一下未选中的 item
        const hasSelectedItems = selectedItems.filter((i) => !unselectedKeys.includes(i.isin!));
        let resultList = [
            ...hasSelectedItems, //找到筛选之前选中的
            ...selectionItem.filter(
                (i) => selectedItems.findIndex((j) => j.isin === i.isin) === -1
            ), //当前筛选条件下选中的
        ];
        setSelectedItems(resultList);
        setSelectedRowKeys(resultList.map((r) => r.isin!));
        onSelectionChange && onSelectionChange(resultList);
    };

    return (
        <>
            <div className={styles.bonds_quotation_table}>
                <Table
                    columns={columns}
                    dataSource={data}
                    rowKey={(record) => record.isin!}
                    rowSelection={
                        onSelectionChange
                            ? {
                                  selectedRowKeys,
                                  onChange: onSelectChange,
                                  fixed: true,
                              }
                            : undefined
                    }
                    size="small"
                    scroll={{ x: 1000, y: tableHeight && tableHeight - 39 }} // table header height: 39
                    pagination={false}
                    onChange={(_, __, sorter) => {
                        let obj = {} as Record<string, string>;
                        if (!Array.isArray(sorter)) {
                            obj = sorter.order
                                ? {
                                      sort_order: sorter.order === 'ascend' ? 'asc' : 'desc',
                                      sort_field: sorter.field as string,
                                  }
                                : {
                                      sort_order: '',
                                      sort_field: '',
                                  };
                        }
                        onFiledSort(obj);
                    }}
                    className={` ${styles.customTable} ${
                        onSelectionChange ? styles.selectionTable : ''
                    }`}
                    onRow={(record) => {
                        return onSelectionChange
                            ? {
                                  onClick: () => {
                                      if (selectedRowKeys.includes(record.isin!)) {
                                          onSelectChange(
                                              selectedRowKeys.filter((k) => k !== record.isin)
                                          );
                                      } else {
                                          onSelectChange([...selectedRowKeys, record.isin!]);
                                      }
                                  },
                              }
                            : {};
                    }}
                    sticky
                />
            </div>
        </>
    );
};

export default ProductsPriceTable;
