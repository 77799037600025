import React, { ChangeEvent, useEffect, useState } from 'react';
import { BankFlow, SystemFlow } from '../../../type';
import { DatePicker, Input } from 'antd';
import moment, { Moment } from 'moment';
import styles from './index.module.less';
import { FormatNumber } from '../../../../../utils';

interface EditableCellProp {
    record: BankFlow;
    index: number;
    dataIndex: keyof BankFlow;
    type?: 'text' | 'date' | 'number';
    editing: boolean;
    children: React.ReactNode;
    handleBlur: (record: BankFlow, index: number) => void;
}
const EditableCell = (props: EditableCellProp) => {
    const {
        record,
        index,
        dataIndex,
        editing,
        children,
        handleBlur,
        type = 'text',
        ...restProps
    } = props;
    const [value, setValue] = useState<string | number | Moment | SystemFlow>();
    let childNode = children;
    useEffect(() => {
        if (record) {
            const temp = record[dataIndex];
            let newValue;
            switch (type) {
                case 'text':
                    newValue = temp;
                    break;
                case 'number':
                    newValue = FormatNumber(temp as number, 2);
                    break;
                case 'date':
                    newValue = temp ? moment(temp as number) : temp;
                    break;
            }
            setValue(newValue);
        }
    }, [record, dataIndex, type]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (type === 'number') {
            const t = e.target.value.charAt(0);
            let v = e.target.value
                .replace(/[^\d.]/g, '')
                .replace(/\.{2,}/g, '.')
                .replace(/^\./, '')
                .replace('.', '$#$')
                .replace(/\./, '')
                .replace('$#$', '.');
            if (t === '-') {
                v = '-' + v;
            }
            setValue(v);
        } else {
            setValue(e.target.value);
        }
    };
    const onDateChange = (changedValue: any) => {
        setValue(changedValue);
    };
    const onBlur = () => {
        let newValue;
        switch (type) {
            case 'text':
                newValue = value;
                break;
            case 'number':
                newValue = parseFloat((value as string).replace(/,/g, ''));
                break;
            case 'date':
                newValue = value ? (value as Moment).valueOf() : undefined;
                break;
        }
        const temp = { ...record, [dataIndex]: newValue };
        handleBlur(temp, index);
    };
    const renderForm = () => {
        switch (type) {
            case 'text':
            case 'number':
                return <Input value={value as string} onChange={onChange} onBlur={onBlur} />;
            case 'date':
                return (
                    <DatePicker
                        value={value as Moment}
                        onChange={onDateChange}
                        format="YYYY-MM-DD"
                        clearIcon={false}
                        suffixIcon={null}
                        onBlur={onBlur}
                    />
                );
        }
    };
    return editing ? (
        <td className={styles.cell}>{renderForm()}</td>
    ) : (
        <td {...restProps}>{childNode}</td>
    );
};

export default EditableCell;
