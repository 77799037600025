import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import AccountItem from './FormItems/AccountItem';
import {
    GinkgoTransFormRefType,
    TransDepositExtInfo,
    TransStatus,
    TransactionInfo,
    TransactionType,
    UpdateTransactionReq,
} from '../../../type';
import CurrencyItem from './FormItems/CurrencyItem';
import DateItem from './FormItems/DateItem';
import { Form } from 'antd';
import LKRadioGroup, { LKRadioGroupProps } from '../../../../../components/LKRadioGroup';
import RateInputItem, { RateInputType } from './FormItems/RateInputItem';
import { useForm } from 'antd/lib/form/Form';
import { GinkgoInput, GinkgoInputNumber } from './FormItems/GinkgoFieldItems';
import { math } from '../../../../../utils';
const DepositForm = forwardRef<GinkgoTransFormRefType, TransactionInfo>(
    (data: TransactionInfo, onRef) => {
        const [form] = useForm();
        useImperativeHandle(onRef, () => {
            return {
                getFieldsValues: async (validate) => {
                    if (validate === false) {
                        return new Promise((resolve) => {
                            return resolve(configValues(form.getFieldsValue()));
                        });
                    } else {
                        return form.validateFields().then(configValues);
                    }
                },
            };
        });
        const [currentCashType, setCurrentCashType] = useState<number>();
        const [timezone, setTimezone] = useState(data.time_zone || '');
        const configValues = (values: any) => {
            return {
                ...values,
                client_id: data.client_id,
                transaction_type: TransactionType['Place Deposit'],
                id: data.id,
                ext_info: {
                    ...data.ext_info,
                    ...values.ext_info,
                },
                status_code: data.status.code,
            };
        };
        // const { data: cashData, run } = useRequest(queryCash, {
        //     manual: true,
        // });
        useEffect(() => {
            form.setFieldsValue({
                ...data,
            });
            setCurrentCashType((data.ext_info as TransDepositExtInfo).principal_type_code);
        }, [data, form]);

        const rollTypeData: LKRadioGroupProps['datasource'] = [
            {
                label: 'Roll',
                value: 'Roll',
                width: '12.5%',
            },
            {
                label: 'Ask Before Roll',
                value: 'Ask Before Roll',
                width: '37.5%',
            },
            {
                label: 'Do Not Roll',
                value: 'Do Not Roll',
                width: '25%',
            },
        ];
        const PlaceableReadOnly = useMemo(() => {
            return data.status.code !== TransStatus.Created;
        }, [data]);
        const TradedReadOnly = useMemo(() => {
            return data.status.code === TransStatus.Traded;
        }, [data]);
        const PlaceableRequired = useMemo(() => {
            return data.status.code === TransStatus.Created;
        }, [data]);
        const TradedRequired = useMemo(() => {
            return data.status.code === TransStatus.Placeable;
        }, [data]);
        useEffect(() => {
            if (data.status.code > TransStatus.Created) {
                form.setFieldsValue({
                    ...data,
                });
            }
        }, [data, form]);
        // useEffect(() => {
        //     if (currency && account_id) {
        //         run({
        //             account_id: account_id,
        //             currency: currency,
        //         });
        //     }
        // }, [currency, account_id, run]);
        // useEffect(() => {
        //     if (data.account?.account_id && data.ext_info?.currency) {
        //         run({
        //             account_id: data.account.account_id,
        //             currency: data.ext_info.currency,
        //         });
        //     }
        // }, [data, run]);
        const handleFormChange = (
            changedValues: Record<string, any>,
            allValues: UpdateTransactionReq
        ) => {
            let changeKey = Object.keys(changedValues)[0];
            const extInfo = allValues.ext_info as TransDepositExtInfo;
            // 1. 如果修改的是dealer_rate或者spread，那么需要同步修改client_rate
            if (changeKey === 'ext_info') {
                changeKey = Object.keys(changedValues[changeKey])[0];
                if (
                    (changeKey === 'dealer_rate' || changeKey === 'spread') &&
                    extInfo?.dealer_rate &&
                    extInfo?.spread
                ) {
                    form.setFieldsValue({
                        ext_info: {
                            ...allValues.ext_info,
                            client_rate: math.format(
                                math.evaluate(`(${extInfo.dealer_rate} - ${extInfo.spread})`),
                                { notation: 'fixed' }
                            ),
                        },
                    });
                }
                // 2. 同步计算maturity_date
                if (changeKey === 'maturity_date' && allValues.value_date) {
                    // let endDate = getDateAfterTenor(allValues.value_date, extInfo.tenor);
                    let tenor = math.format(
                        math.evaluate(
                            `(${extInfo.maturity_date} - ${allValues.value_date}) / 86400000`
                        ),
                        { notation: 'fixed' }
                    );
                    form.setFieldsValue({
                        ext_info: {
                            ...allValues.ext_info,
                            tenor: `${tenor}D`,
                        },
                    });
                }
                // 3. 同步计算总金额
                if (changeKey === 'interest_amount' || changeKey === 'principal_amount') {
                    if (!extInfo?.interest_amount || !extInfo?.principal_amount) return;
                    let total_amount = math.format(
                        math.evaluate(
                            `(${extInfo?.interest_amount ?? '0'} + ${
                                extInfo?.principal_amount ?? '0'
                            })`
                        ),
                        { notation: 'fixed' }
                    );
                    form.setFieldsValue({
                        ext_info: {
                            ...allValues.ext_info,
                            total_amount,
                        },
                    });
                }
            }
            if (changeKey === 'value_date' && extInfo.maturity_date) {
                //计算tenor为maturity_date - value_date
                let tenor = math.format(
                    math.evaluate(
                        `(${extInfo.maturity_date} - ${allValues.value_date}) / 86400000`
                    ),
                    { notation: 'fixed' }
                );
                // let endDate = getDateAfterTenor(allValues.value_date!, extInfo.tenor);
                form.setFieldsValue({
                    ext_info: {
                        ...allValues.ext_info,
                        tenor: `${tenor}D`,
                    },
                });
            }
        };
        const allCashType: LKRadioGroupProps['datasource'] = [
            {
                label: 'All Cash',
                value: 1,
                width: '25%',
            },
            {
                label: 'Specific',
                value: 2,
                width: '25%',
            },
        ];
        return (
            <Form
                labelCol={{ span: 6 }}
                labelWrap
                wrapperCol={{ span: 18 }}
                form={form}
                onValuesChange={handleFormChange}
            >
                <AccountItem
                    clientId={data.client_id}
                    form={form}
                    readOnlyValue={data.ext_info.account_id}
                    selectCallback={(bank) => {
                        setTimezone(bank.time_zone);
                        // setAccount_id(bank.account_id);
                    }}
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                />
                <CurrencyItem
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                    onChange={(currency) => {
                        // setCurrrency(currency);
                    }}
                />
                <DateItem
                    label="Value Date"
                    name="value_date"
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                    timezone={timezone}
                />
                {/* <TenorItem readOnly={PlaceableReadOnly} required={PlaceableRequired} /> */}
                <Form.Item label="Tenor" name={['ext_info', 'tenor']}>
                    <GinkgoInput readOnly />
                </Form.Item>
                <DateItem
                    label="Maturity Date"
                    name={['ext_info', 'maturity_date']}
                    required={PlaceableRequired}
                    readOnly={PlaceableReadOnly}
                    timezone={timezone}
                />
                <Form.Item
                    label="Roll Type"
                    name={['ext_info', 'roll_type']}
                    rules={[{ required: PlaceableRequired, message: 'Roll Type is required' }]}
                >
                    <LKRadioGroup datasource={rollTypeData} readOnly={PlaceableReadOnly} />
                </Form.Item>
                <RateInputItem
                    type={RateInputType.Percent}
                    label="Spread"
                    name={['ext_info', 'spread']}
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                />
                <Form.Item
                    label="Principal Type"
                    name={['ext_info', 'principal_type_code']}
                    rules={[{ required: PlaceableRequired, message: 'Principal Type is required' }]}
                >
                    <LKRadioGroup
                        datasource={allCashType}
                        onChange={(value) => {
                            setCurrentCashType(value as number);
                            form.setFieldsValue({
                                ext_info: {
                                    ...form.getFieldValue('ext_info'),
                                    is_all_cash: value,
                                },
                            });
                        }}
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>
                {(data.status.code > TransStatus.Created || currentCashType === 2) && (
                    <Form.Item
                        label={'Principal Amount'}
                        name={['ext_info', 'principal_amount']}
                        rules={[
                            {
                                required: PlaceableRequired || TradedRequired,
                                message: 'Principal Amount is required',
                            },
                        ]}
                    >
                        <GinkgoInputNumber
                            style={{ width: 'calc(62.5% - 10px)' }}
                            readOnly={TradedReadOnly}
                        />
                    </Form.Item>
                )}
                <Form.Item label="Custodian Trade ID" name={['ext_info', 'custodian_trade_id']}>
                    <GinkgoInput
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <RateInputItem
                    type={RateInputType.NoAddonAfterPercent}
                    label="Dealer Rate"
                    name={['ext_info', 'dealer_rate']}
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                />

                <RateInputItem
                    label="Client Rate"
                    name={['ext_info', 'client_rate']}
                    readOnly
                    type={RateInputType.NoAddonAfterPercent}
                />
                <Form.Item
                    label="Interest Amount"
                    name={['ext_info', 'interest_amount']}
                    rules={[{ required: TradedRequired, message: 'Interest Amount is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <Form.Item label="Total Amount" name={['ext_info', 'total_amount']}>
                    <GinkgoInputNumber
                        readOnly={TradedReadOnly}
                        style={{ width: 'calc(62.5% - 10px)' }}
                    />
                </Form.Item>
                <DateItem
                    label="Trade Date"
                    name="trade_date"
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                    timezone={timezone}
                />
            </Form>
        );
    }
);

export default DepositForm;
