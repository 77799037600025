import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import AccountItem from './FormItems/AccountItem';
import {
    Asset,
    AssetType,
    GinkgoTransFormRefType,
    TransAQExtInfo,
    TransAdjustmentAssetType,
    TransAdjustmentExtInfo,
    TransStatus,
    TransactionInfo,
} from '../../../type';
import DateItem from './FormItems/DateItem';
import { Form } from 'antd';
import LKRadioGroup, { LKRadioGroupProps } from '../../../../../components/LKRadioGroup';
import { useForm } from 'antd/lib/form/Form';
import { GinkgoInput, GinkgoInputNumber } from './FormItems/GinkgoFieldItems';
import CurrencyItem from './FormItems/CurrencyItem';
import SearchUnderlying from './FormItems/SearchUnderlying';
import TextArea from 'antd/lib/input/TextArea';
import { searchProduct } from '../../../request';
import SearchIsinAdjustment from './FormItems/SearchIsinAdjustment';
import { useDebounce } from 'ahooks';

const AdjustmentForm = forwardRef<GinkgoTransFormRefType, TransactionInfo>(
    (data: TransactionInfo, onRef) => {
        const [form] = useForm();
        const [assetType, setAssetType] = useState<number>();
        const [selectAsset, setSelectAsset] = useState<Asset>();
        const [timezone, setTimezone] = useState(data.time_zone || '');
        const debounceValue = useDebounce(data, { wait: 200 });
        useImperativeHandle(onRef, () => {
            return {
                getFieldsValues: async (validate) => {
                    if (validate === false) {
                        return new Promise((resolve) => {
                            return resolve(configValues(form.getFieldsValue()));
                        });
                    } else {
                        return form.validateFields().then(configValues);
                    }
                },
            };
        });

        const handleOnBlur = (e: any) => {
            if (assetType === 3) return;
            if (e.target.value === '') return;
            let searchKey = e.target.value;
            searchProduct({
                isin: e.target.value,
                asset_types: [AssetType.GovernmentBond, AssetType.CorporateBond],
            }).then((res) => {
                if (res.data.assets && res.data.assets.length > 0) {
                    if (res.data.assets?.[0].isin !== searchKey) {
                        return;
                    }
                    setSelectAsset(res.data.assets?.[0]);
                } else {
                    setSelectAsset(undefined);
                }
            });
        };
        const configValues = (values: any) => {
            let ext_info =
                (data.ext_info as TransAdjustmentExtInfo).asset_type === values.ext_info.asset_type
                    ? data.ext_info
                    : {};
            return {
                ...values,
                client_id: data.client_id,
                transaction_type: data.type.code,
                id: data.id,
                ext_info: {
                    ...ext_info,
                    ...values.ext_info,
                },
                status_code: data.status.code,
            };
        };
        const sideTypeOptions: LKRadioGroupProps['datasource'] = [
            {
                label: 'Increase',
                value: 1,
                width: '12.5%',
            },
            {
                label: 'Decrease',
                value: 2,
                width: '12.5%',
            },
        ];
        const assetTypeOptions: LKRadioGroupProps['datasource'] = Object.keys(
            TransAdjustmentAssetType
        )
            .filter((key) => isNaN(Number(key)))
            .map((key) => ({
                label: key,
                value: TransAdjustmentAssetType[key as any],
                width: '12.5%',
            }));
        const PlaceableReadOnly = useMemo(() => {
            return data.status.code !== TransStatus.Created;
        }, [data]);
        const TradedReadOnly = useMemo(() => {
            return data.status.code === TransStatus.Traded;
        }, [data]);
        const PlaceableRequired = useMemo(() => {
            return data.status.code === TransStatus.Created;
        }, [data]);

        useEffect(() => {
            setAssetType((debounceValue.ext_info as any).asset_type);
            form.setFieldsValue({
                ...debounceValue,
            });
        }, [debounceValue, form]);
        useEffect(() => {
            if (selectAsset) {
                form.setFieldsValue({
                    ext_info: {
                        ...form.getFieldValue('ext_info'),
                        isin: selectAsset.isin,
                        asset_name: selectAsset.spec_name ?? selectAsset.name,
                    },
                });
            } else {
                form.setFieldsValue({
                    ext_info: {
                        ...form.getFieldValue('ext_info'),
                        asset_name: undefined,
                    },
                });
            }
        }, [form, selectAsset]);
        const underlying = useMemo(() => {
            if (data) {
                return (data?.ext_info as TransAQExtInfo)?.underlyings
                    ? (data?.ext_info as TransAQExtInfo).underlyings![0]
                    : undefined;
            }
        }, [data]);
        return (
            <Form labelCol={{ span: 6 }} labelWrap wrapperCol={{ span: 18 }} form={form}>
                <AccountItem
                    clientId={data.client_id}
                    name={['ext_info', 'account_id']}
                    form={form}
                    readOnlyValue={data.ext_info.account_id}
                    selectCallback={(bank) => {
                        setTimezone(bank.time_zone);
                    }}
                    width="calc(62.5% - 10px)"
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                />

                <DateItem
                    label="Trade Date"
                    name="trade_date"
                    width="calc(62.5% - 10px)"
                    readOnly={TradedReadOnly}
                    required={PlaceableRequired}
                    timezone={timezone}
                />

                <Form.Item
                    label="Asset Type"
                    name={['ext_info', 'asset_type']}
                    rules={[{ required: PlaceableRequired, message: 'Asset Type is required' }]}
                >
                    <LKRadioGroup
                        datasource={assetTypeOptions}
                        onChange={(value) => {
                            setAssetType(value as number);
                            setSelectAsset(undefined);
                            form.setFieldsValue({
                                ext_info: {
                                    ...form.getFieldValue('ext_info'),
                                    isin: undefined,
                                    asset_name: undefined,
                                },
                            });
                        }}
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>

                {assetType === AssetType.Cash && (
                    <CurrencyItem
                        readOnly={PlaceableReadOnly}
                        required={PlaceableRequired}
                        onChange={() => {}}
                    />
                )}

                {assetType === AssetType.Equities && (
                    <SearchUnderlying
                        withCurrency={false}
                        form={form}
                        needPrice={data === undefined}
                        readOnly={PlaceableReadOnly}
                        isin={underlying}
                        required={PlaceableRequired}
                        width="calc(62.5% - 10px)"
                        currentPrice={(price) => {
                            setSelectAsset(price);
                            // setCurrentPrice(price);
                            // handleChangePrice(price);
                        }}
                    />
                )}

                {(assetType === AssetType.CorporateBond || assetType === AssetType.Other) && (
                    <SearchIsinAdjustment
                        form={form}
                        canNull={assetType === AssetType.Other}
                        readOnly={PlaceableReadOnly}
                        required={PlaceableRequired}
                        width="calc(62.5% - 10px)"
                    />
                )}
                {assetType === AssetType.Equities && (
                    <Form.Item label="ISIN" name={['ext_info', 'isin']}>
                        <GinkgoInput style={{ width: 'calc(62.5% - 10px)' }} readOnly />
                    </Form.Item>
                )}

                {assetType === AssetType.Equities && (
                    <Form.Item
                        label="Asset Name"
                        name={['ext_info', 'asset_name']}
                        rules={[
                            {
                                required: PlaceableRequired && assetType !== AssetType.Equities,
                                message: 'Asset Name is required',
                            },
                        ]}
                    >
                        <GinkgoInput
                            style={{ width: 'calc(62.5% - 10px)' }}
                            readOnly={
                                TradedReadOnly ||
                                selectAsset !== undefined ||
                                assetType === AssetType.Equities
                            }
                            onBlur={handleOnBlur}
                        />
                    </Form.Item>
                )}

                <Form.Item
                    label="Side"
                    name={['ext_info', 'side']}
                    rules={[{ required: PlaceableRequired, message: 'Side is required' }]}
                >
                    <LKRadioGroup
                        datasource={sideTypeOptions}
                        // onChange={(value) => {
                        //     setAssetType(value as number);
                        // }}
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Amount"
                    name={['ext_info', 'amount']}
                    rules={[{ required: PlaceableRequired, message: 'Amount is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name={['ext_info', 'description']}
                    rules={[{ required: PlaceableRequired, message: 'Description is required' }]}
                >
                    <TextArea
                        style={{ width: 'calc(62.5% - 10px)', height: '150px' }}
                        readOnly={TradedReadOnly}
                        aria-readonly={TradedReadOnly}
                    />
                </Form.Item>
            </Form>
        );
    }
);

export default AdjustmentForm;
