import { Form } from 'antd';
import React from 'react';
import LKRadioGroup, { LKRadioGroupProps } from '../../../../../../components/LKRadioGroup';

const BondPriceType = ({
    readOnly,
    required,
    onChange,
}: {
    readOnly?: boolean;
    required?: boolean;
    onChange: (value: number) => void;
}) => {
    const dataSource: LKRadioGroupProps['datasource'] = [
        { value: 1, label: 'LMT' },
        { value: 2, label: 'MKT' },
        { value: 3, label: 'MKT Close' },
        { value: 4, label: 'MKT Open' },
        { value: 5, label: 'VWAP' },
        { value: 6, label: 'Others' },
    ];
    return (
        <Form.Item
            name={['ext_info', 'price_type']}
            label="Price Type"
            rules={[{ required: required, message: 'Price type is required' }]}
        >
            <LKRadioGroup
                datasource={dataSource}
                itemWidth="12.5%"
                readOnly={readOnly}
                onChange={(value) => onChange(value as number)}
            />
        </Form.Item>
    );
};

export default BondPriceType;
