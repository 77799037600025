import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, message, Pagination, Spin, Tabs, Modal, Upload } from 'antd';
import styles from './index.module.scss';
import {
    getQuoteOrderList,
    getQuoteMatchOrderList,
    getQuoteOrderTextTemplate,
} from '../../../service';
import {
    updateQuoteOrder,
    UpdateQuoteOrderData,
} from '../../../service/requests/HistoricalTransactions';
import { AgGridReact } from 'ag-grid-react';
import copy from 'copy-to-clipboard';
import { ColDef, ColumnApi, GridApi, ICellRendererParams } from 'ag-grid-community';
import { quoteOrderList } from '../../../service/type';
import { useForm } from 'antd/lib/form/Form';
import { showError } from '../../RecommendationComponent/util';
import useForceUpdate from '../../../utils/Hooks/useForceUpdate';
import {
    getSharedColumns,
    PRODUCT_DEFS,
    COLUMNS_TO_AUTOSIZE,
    getStockColumns,
    getFxColumns,
    getCashColumns,
    getLoanColumns,
    getDepositColumns,
} from './tableDef';
import LookButton from '../../../components/LookButton';
import { blue, green } from '@ant-design/colors';
import { useHistory, useParams } from 'react-router-dom';
import TranscationOptionsForm from '../components/TranscationOptionsForm';
import TranscationAQForm from '../components/TranscationAQForm';
import TranscationFCNForm from '../components/TranscationFCNForm';
import { AxiosResponse } from 'axios';
import { UploadOutlined } from '@ant-design/icons';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { uploadPdf } from './requests';
import { EventList, uploadEventTrack } from '../../../service/requests/EventTrack';

const PAGE_SIZE = 12;

const HistoricalTransactions = () => {
    const history = useHistory();
    const params = useParams<{ productType: string }>();
    const curTab = useMemo(() => {
        let index = PRODUCT_DEFS.findIndex((item) => item.type === params.productType);
        if (index !== -1) {
            return index;
        } else {
            return 0;
        }
    }, [params.productType]);

    const [gridApi, setGridApi] = useState<GridApi>();
    const [columnApi, setColumnApi] = useState<ColumnApi>();
    const [orderData, setOrderData] = useState<quoteOrderList['order_info_list'][number]>();
    const [curPage, setCurPage] = useState(1);
    const [visible, setVisible] = useState(false);
    const [modalTitle, setModalTitle] = useState('交易信息补充');
    const productDef = useMemo(() => PRODUCT_DEFS[curTab], [curTab]);

    const [historyData, setHistoryData] = useState<quoteOrderList>();
    const forceUpdate = useForceUpdate(() => {
        gridApi?.showLoadingOverlay();
        let promise: Promise<AxiosResponse<quoteOrderList>>;
        if (curTab === 0) {
            promise = getQuoteMatchOrderList({
                limit: PAGE_SIZE,
                offset: (curPage - 1) * PAGE_SIZE,
                asset_class: productDef.type,
            });
        } else {
            promise = getQuoteOrderList({
                limit: PAGE_SIZE,
                offset: (curPage - 1) * PAGE_SIZE,
                product_type: productDef.type,
            });
        }
        promise
            .then((res) => {
                setHistoryData(res.data);
                setTimeout(() => {
                    columnApi?.autoSizeColumns(COLUMNS_TO_AUTOSIZE);
                });
            })
            .catch(showError)
            .finally(() => gridApi?.hideOverlay());
    }, [curPage, curTab]);

    const getTextTemplate = useCallback(
        (order_id: number) => {
            getQuoteOrderTextTemplate({ order_id, product_type: productDef.type }).then((data) => {
                if (copy(data.data.email_text_template)) {
                    message.success('复制银行文本成功');
                } else {
                    message.error('复制银行文本失败');
                }
            }, showError);
        },
        [productDef.type]
    );

    const onTabChange = useCallback(
        (tabKey: string) => {
            uploadEventTrack(EventList.TransactionHistorySwitchType, 2, {
                product_type: PRODUCT_DEFS[parseInt(tabKey)].type,
            });
            setCurEditingId(undefined);
            history.replace(
                `/app/structuralProducts/historicalTransactions/${
                    PRODUCT_DEFS[parseInt(tabKey)].type
                }`
            );
        },
        [history]
    );

    const onPageChange = useCallback((page: number) => {
        setCurEditingId(undefined);
        setCurPage(page);
    }, []);

    const defaultColDef = useMemo<ColDef>(
        () => ({
            width: 112,
            sortable: true,
            resizable: true,
        }),
        []
    );

    const [curEditingId, setCurEditingId] = useState<number>();
    const [oldValues, setOldValues] = useState<quoteOrderList['order_info_list'][number]>();
    useEffect(() => {
        if (curEditingId === undefined) {
            setOldValues(undefined);
        }
    }, [curEditingId]);

    const [form] = useForm();
    const formArray = [
        <></>,
        <TranscationOptionsForm
            setTitle={setModalTitle}
            setVisible={setVisible}
            refreshData={() => forceUpdate()}
            originOrderData={orderData!}
        />,
        <TranscationAQForm
            setTitle={setModalTitle}
            setVisible={setVisible}
            refreshData={() => forceUpdate()}
            originOrderData={orderData!}
            type="accum"
        />,
        <TranscationAQForm
            setTitle={setModalTitle}
            setVisible={setVisible}
            refreshData={() => forceUpdate()}
            originOrderData={orderData!}
            type="decum"
        />,
        <TranscationFCNForm
            setTitle={setModalTitle}
            setVisible={setVisible}
            refreshData={() => forceUpdate()}
            originOrderData={orderData!}
        />,
    ];
    const [isUpdating, setIsUpdating] = useState(false);

    const [uploadOrderId, setUploadOrderId] = useState<number>();

    const actionCols = useMemo<ColDef[]>(
        () => [
            {
                headerName: '操作',
                width: 156,
                cellClass: 'cell-edit',
                resizable: false,
                pinned: 'right',
                cellRenderer: (params: ICellRendererParams) =>
                    curEditingId === params.node.__objectId ? (
                        <>
                            <Button
                                type="primary"
                                danger
                                onClick={() => setCurEditingId(undefined)}
                            >
                                取消
                            </Button>
                            <Button type="primary" onClick={() => form.submit()}>
                                提交
                            </Button>
                        </>
                    ) : (
                        <>
                            <LookButton
                                variant="filled"
                                onClick={() => {
                                    uploadEventTrack(EventList.TransactionHistoryAction, 2, {
                                        action_type: 'edit_record',
                                    });
                                    setOldValues(params.data);
                                    setCurEditingId(params.node?.__objectId);
                                }}
                                disabled={
                                    curEditingId !== undefined &&
                                    curEditingId !== params.node?.__objectId
                                }
                            >
                                编辑
                            </LookButton>
                            {true && (
                                <LookButton
                                    variant="filled"
                                    color={green[6]}
                                    onClick={() => {
                                        uploadEventTrack(EventList.TransactionHistoryAction, 2, {
                                            action_type: 'make_deal',
                                        });
                                        setOrderData(params.data);
                                        setVisible(true);
                                    }}
                                    disabled={
                                        (curEditingId !== undefined &&
                                            curEditingId !== params.node?.__objectId) ||
                                        params.data.status === 5
                                    }
                                >
                                    成交
                                </LookButton>
                            )}
                        </>
                    ),
            },
            {
                headerName: '获取确认文本',
                width: 156,
                cellClass: 'cell-copy cell-border-left',
                resizable: false,
                pinned: 'right',
                cellRenderer: (params: ICellRendererParams) => (
                    <>
                        <LookButton
                            className="btn-customer"
                            variant="filled"
                            color={green[6]}
                            disabled={curEditingId !== undefined}
                            onClick={() => {
                                uploadEventTrack(EventList.TransactionHistoryAction, 2, {
                                    action_type: 'copy_client_text',
                                });
                                const text = productDef.getClientTemplate!(params.data);
                                if (copy(text)) {
                                    message.success('复制客户文本成功');
                                } else {
                                    message.error('复制客户文本失败');
                                }
                            }}
                        >
                            客户
                        </LookButton>
                        <LookButton
                            className="btn-bank"
                            variant="filled"
                            color={blue[6]}
                            disabled={curEditingId !== undefined}
                            onClick={() => {
                                uploadEventTrack(EventList.TransactionHistoryAction, 2, {
                                    action_type: 'copy_bank_text',
                                });
                                getTextTemplate(params.data.order_id);
                            }}
                        >
                            银行
                        </LookButton>
                    </>
                ),
            },
            {
                headerName: '银行回执',
                width: 112,
                pinned: 'right',
                cellClass: 'cell-border-left',
                cellRenderer: (params: ICellRendererParams) => (
                    <Button
                        type="primary"
                        disabled={
                            params.data.status !== 5 ||
                            !(
                                params.data.matched_order_id ??
                                params.data.matched_order?.matched_order_id
                            )
                        }
                        onClick={() => {
                            let orderid =
                                params.data.matched_order_id ??
                                params.data.matched_order?.matched_order_id;
                            if (orderid) {
                                uploadEventTrack(EventList.TransactionHistoryAction, 2, {
                                    action_type: 'upload_pdf',
                                });
                                setUploadOrderId(orderid);
                            }
                        }}
                    >
                        上传
                    </Button>
                ),
            },
        ],
        [curEditingId, form, getTextTemplate, productDef]
    );

    const matchedOrderCols = useMemo<ColDef[]>(
        () => [
            {
                headerName: '银行回执',
                width: 112,
                pinned: 'right',
                cellRenderer: (params: ICellRendererParams) => (
                    <Button
                        disabled={
                            !(
                                params.data.matched_order_id ??
                                params.data.matched_order?.matched_order_id
                            )
                        }
                        type="primary"
                        onClick={() => {
                            let orderid =
                                params.data.matched_order_id ??
                                params.data.matched_order?.matched_order_id;
                            if (orderid) {
                                uploadEventTrack(EventList.TransactionHistoryAction, 2, {
                                    action_type: 'upload_pdf',
                                });
                                setUploadOrderId(orderid);
                            }
                        }}
                    >
                        上传
                    </Button>
                ),
            },
        ],
        []
    );

    const [pdfStr, setPdfStr] = useState<string>();
    const parsePDF = useCallback((props: UploadRequestOption) => {
        const reader = new FileReader();
        reader.onload = () => {
            const data = reader.result as string;
            setPdfStr(data);
            props.onSuccess?.(data);
        };
        reader.onerror = () => {
            props.onError?.(new Error('读取文件失败'));
        };
        if (typeof props.file !== 'string') {
            reader.readAsDataURL(props.file);
        }
    }, []);
    const [pdfUploading, setPdfUploading] = useState(false);
    const handleUploadPdf = useCallback(() => {
        if (uploadOrderId !== undefined && pdfStr !== undefined) {
            setPdfUploading(true);
            uploadPdf({
                file_encode: pdfStr,
                matched_order_id: uploadOrderId,
                order_type: productDef.type,
            })
                .then(() => {
                    message.success('上传成功');
                    setUploadOrderId(undefined);
                    setPdfStr(undefined);
                }, showError)
                .finally(() => setPdfUploading(false));
        } else {
            message.error('请先选择文件');
        }
    }, [uploadOrderId, pdfStr, productDef.type]);

    const columnDefs = useMemo<ColDef[]>(() => {
        switch (productDef.type) {
            case 'stock':
                return [...matchedOrderCols, ...getStockColumns()];
            case 'fx':
                return [...matchedOrderCols, ...getFxColumns()];
            case 'cash':
                return [...matchedOrderCols, ...getCashColumns()];
            case 'loan':
                return [...matchedOrderCols, ...getLoanColumns()];
            case 'deposit':
                return [...matchedOrderCols, ...getDepositColumns()];
            default:
                return [
                    ...actionCols,
                    ...getSharedColumns(curEditingId),
                    ...productDef.getOwnColumns!(curEditingId),
                ];
        }
    }, [actionCols, curEditingId, matchedOrderCols, productDef.getOwnColumns, productDef.type]);
    // 每次点击“编辑”、“取消”或“提交”后都重置表单
    useEffect(() => {
        form.resetFields();
    }, [form, curEditingId]);

    return (
        <>
            <div className={styles.header}>
                <div className={styles.title}>历史交易记录</div>
            </div>
            <Tabs onChange={onTabChange} activeKey={String(curTab)}>
                {PRODUCT_DEFS.map((item, index) => (
                    <Tabs.TabPane tab={item.name} key={String(index)} />
                ))}
            </Tabs>
            <Modal
                title={modalTitle}
                destroyOnClose
                footer={null}
                visible={visible}
                onCancel={() => setVisible(false)}
                centered
                okText="确认"
            >
                {formArray[curTab]}
            </Modal>
            <Modal
                title="上传银行回执单"
                destroyOnClose
                visible={uploadOrderId !== undefined}
                centered
                onCancel={() => {
                    setUploadOrderId(undefined);
                    setPdfStr(undefined);
                }}
                onOk={handleUploadPdf}
                confirmLoading={pdfUploading}
                cancelText="取消"
                okText="上传"
            >
                <Upload accept=".pdf" maxCount={1} customRequest={parsePDF}>
                    <Button icon={<UploadOutlined />}>点击上传</Button>
                </Upload>
            </Modal>
            <div className="ag-theme-alpine ht-table" style={{ width: '100%' }}>
                <Form
                    form={form}
                    initialValues={oldValues}
                    onFinish={(values) => {
                        if (oldValues) {
                            let data: UpdateQuoteOrderData = {
                                order_id: oldValues.order_id,
                                product_type: productDef.type,
                                strike_price_ratio: oldValues.strike_price_ratio,
                                qty: oldValues.qty,
                                status: oldValues.status,
                                option_premium_ratio: undefined,
                                limit_order_price: undefined,
                                limit_order_share: undefined,
                                knock_out: undefined,
                                knock_out_type: undefined,
                                sold_out_days: undefined,
                                trade_amount: undefined,
                                product: undefined,
                            };
                            for (let [key, value] of Object.entries(data)) {
                                if (values[key] != null) {
                                    (data as any)[key] = values[key];
                                } else if (value === undefined && key in oldValues) {
                                    (data as any)[key] = (oldValues as any)[key];
                                }
                            }
                            if (Number.isNaN(Number(data.limit_order_price))) {
                                delete data.limit_order_price;
                            }
                            setIsUpdating(true);
                            gridApi?.showLoadingOverlay();
                            updateQuoteOrder(data)
                                .then(() => message.success('调整成功'))
                                .catch(showError)
                                .finally(() => {
                                    forceUpdate();
                                    setIsUpdating(false);
                                    setCurEditingId(undefined);
                                });
                        }
                    }}
                >
                    <AgGridReact
                        animateRows
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowData={
                            curTab === 0 ||
                            curTab === 6 ||
                            curTab === 5 ||
                            curTab === 7 ||
                            curTab === 8
                                ? historyData?.orders
                                : historyData?.order_info_list
                        }
                        domLayout="autoHeight"
                        enableCellTextSelection
                        ensureDomOrder
                        suppressCellFocus
                        onGridReady={({ api, columnApi }) => {
                            setGridApi(api);
                            setColumnApi(columnApi);
                        }}
                        onFirstDataRendered={() => {
                            columnApi?.autoSizeColumns(COLUMNS_TO_AUTOSIZE);
                        }}
                        loadingOverlayComponent={Spin}
                        loadingOverlayComponentParams={{
                            size: 'large',
                            tip: isUpdating ? '更新中...' : '加载中...',
                        }}
                    />
                </Form>
            </div>
            <Pagination
                current={curPage}
                onChange={onPageChange}
                total={historyData?.total_count || 0}
                showTotal={(total) => `Total ${total} items`}
                style={{
                    display: (historyData?.total_count ?? 0) > PAGE_SIZE ? 'flex' : 'none',
                    justifyContent: 'flex-end',
                    margin: '10px 0',
                }}
                pageSize={PAGE_SIZE}
                showSizeChanger={false}
            />
        </>
    );
};

export default HistoricalTransactions;
