import React, { useEffect, useState } from 'react';

/**
 * 融合useEffect和useUpdate的Hook，适合作为初始化函数使用
 * @param effect 要执行的Effect Hook
 * @returns 更新函数，用于重新执行Effect Hook
 */
export default function useForceUpdate(
    effect: React.EffectCallback,
    deps: React.DependencyList = []
): () => void {
    const [updateFlag, setUpdateFlag] = useState(0);

    useEffect(effect, [updateFlag, ...deps]);

    return () => {
        setUpdateFlag(updateFlag + 1);
    };
}
