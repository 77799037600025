import { Button, message, Modal, Input as AntInput } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EventList, uploadEventTrack } from '../../service/requests/EventTrack';
import { UpdateVerifyCode, VerifyMember } from '../../service/requests/MemberVerify';

interface VerifyModalProps {
    containerId: string;
    visible: boolean;
    setVisible: (visible: boolean) => void;
    pageId: string;
}

const TimeExtendDelta = 1000 * 60 * 3; // 2分钟

export default function VerifyModal(props: VerifyModalProps) {
    const { containerId, visible, setVisible, pageId } = props;
    const [showTip, setShowTip] = useState(false);
    const [expiryTs, setExpiryTs] = useState(
        Number(sessionStorage.getItem(`${pageId}_expiryTs`)) || 0
    );

    const preventScroll = useCallback((e: Event) => {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }, []);

    useEffect(() => {
        const contentElem = document.getElementById('app-content');
        if (visible && contentElem) {
            contentElem.style.overflow = 'hidden';
        }
        return () => {
            if (contentElem) {
                contentElem.style.overflow = 'auto';
            }
        };
    }, [preventScroll, visible]);

    useEffect(() => {
        let intervalId: NodeJS.Timeout | undefined = undefined;
        if (expiryTs > Date.now()) {
            intervalId = setInterval(() => {
                if (Date.now() > expiryTs) {
                    setVisible(true);
                    intervalId && clearInterval(intervalId);
                }
            }, 1000);
        } else {
            setVisible(true);
        }
        return () => {
            sessionStorage.setItem(`${pageId}_expiryTs`, expiryTs.toString());
            intervalId && clearInterval(intervalId);
        };
    }, [expiryTs, pageId, setVisible]);

    const handleUserOperation = useCallback(() => {
        if (expiryTs > Date.now()) {
            setExpiryTs(Date.now() + TimeExtendDelta);
        }
    }, [expiryTs]);
    const handleMousemove = useCallback(() => {
        if (expiryTs > Date.now()) {
            setExpiryTs(Date.now() + TimeExtendDelta);
        }
    }, [expiryTs]);
    useEffect(() => {
        document.addEventListener('userOperation', handleUserOperation);
        return () => {
            document.removeEventListener('userOperation', handleUserOperation);
        };
    }, [handleUserOperation]);

    useEffect(() => {
        document.addEventListener('mousemove', handleMousemove);
        return () => {
            document.removeEventListener('mousemove', handleMousemove);
        };
    }, [handleMousemove]);

    const [verifyCode, setVerifyCode] = useState('');

    const handleVerify = useCallback(() => {
        uploadEventTrack(EventList.VerifyConfirm, 2);
        if (verifyCode) {
            VerifyMember(verifyCode).then(
                () => {
                    if (verifyCode === '123456') {
                        message.error('您的密码过于简单，请修改后使用!');
                        setShowTip(true);
                        setVisible(false);
                        setShowChange(true);
                    } else {
                        message.success('验证成功');
                        setExpiryTs(Date.now() + TimeExtendDelta);
                        setVisible(false);
                        setVerifyCode('');
                    }
                },
                (msg) => {
                    message.error('密码输入错误，请联系管理员进行修改！');
                }
            );
        } else {
            message.error('请输入密码');
        }
    }, [setVisible, verifyCode]);

    const [showChange, setShowChange] = useState(false);

    const formValues = useMemo(() => ({ oldPwd: '', newPwd: '', confirmPwd: '' }), []);
    const validateForm = useCallback(
        (values: typeof formValues) => {
            const errors: Partial<typeof formValues> = {};
            for (let [key, value] of Object.entries(values)) {
                if (!value) {
                    (errors as any)[key] = '请输入内容';
                }
            }

            if (values.newPwd && (values.newPwd.length < 6 || values.newPwd.length > 12)) {
                errors.newPwd = '密码应当在6-12位';
            }
            if (values.newPwd === values.oldPwd) {
                errors.newPwd = '新密码与旧密码相同，请重新修改';
            }
            if (values.confirmPwd && values.newPwd !== values.confirmPwd) {
                errors.confirmPwd = '两次输入的密码不一致';
            }
            return errors;
        },
        [formValues]
    );
    const submitForm = useCallback(
        (values: typeof formValues) => {
            UpdateVerifyCode(values.oldPwd, values.newPwd).then(
                () => {
                    message.success('更改成功');
                    setShowTip(false);
                    setExpiryTs(Date.now() + TimeExtendDelta);
                    setShowChange(false);
                    setVerifyCode('');
                },
                (msg) => {
                    message.error('密码输入错误，请联系管理员进行修改！');
                }
            );
        },
        [formValues]
    );

    return (
        <>
            <Modal
                destroyOnClose
                centered
                visible={visible && process.env.NODE_ENV !== 'development'}
                // visible={visible}
                getContainer={document.getElementById(containerId) ?? false}
                closable={false}
                maskClosable={false}
                maskStyle={{
                    backgroundColor: '#f0f2f5',
                }}
                zIndex={1001}
                title="安全验证"
                footer={
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '4px 8px',
                        }}
                    >
                        <Button
                            onClick={() => {
                                setVisible(false);
                                uploadEventTrack(EventList.VerifyChange, 2);
                                setShowChange(true);
                            }}
                        >
                            修改密码
                        </Button>
                        <Button type="primary" onClick={handleVerify}>
                            确认
                        </Button>
                    </div>
                }
            >
                <AntInput.Password
                    size="large"
                    placeholder="请输入密码"
                    value={verifyCode}
                    autoComplete="new-password"
                    onChange={(e) => setVerifyCode(e.target.value)}
                    onPressEnter={handleVerify}
                />
            </Modal>
            <Modal
                destroyOnClose
                centered
                zIndex={1001}
                visible={showChange}
                getContainer={document.getElementById(containerId) ?? false}
                closable={false}
                maskClosable={false}
                title="修改密码"
                footer={null}
            >
                {showTip && (
                    <div style={{ color: 'red', textAlign: 'center' }}>
                        您的密码过于简单，请修改后使用
                    </div>
                )}
                <Formik initialValues={formValues} validate={validateForm} onSubmit={submitForm}>
                    <Form labelCol={{ span: 4 }}>
                        <Form.Item label="旧密码" name="oldPwd">
                            <Input.Password name="oldPwd" placeholder="请输入您要更改的密码" />
                        </Form.Item>
                        <Form.Item label="新密码" name="newPwd">
                            <Input.Password
                                name="newPwd"
                                maxLength={12}
                                placeholder="6-12位，字母、数字、符号不限，区分大小写"
                            />
                        </Form.Item>
                        <Form.Item label="确认密码" name="confirmPwd">
                            <Input.Password
                                name="confirmPwd"
                                maxLength={12}
                                placeholder="请再次输入您的新密码"
                            />
                        </Form.Item>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                padding: '4px 8px',
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setShowChange(false);
                                    setVisible(true);
                                }}
                            >
                                返回
                            </Button>
                            <Button type="primary" htmlType="submit">
                                确认
                            </Button>
                        </div>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
}
