import { DeleteOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { LKRootState } from '../../../../../../store/store';
import { getFileName } from '../../../../../../utils';
import { RecordListModalType, TrackRecordFileItem } from '../Modal';
import './index.less';

const FileItem = ({
    data,
    type,
    deleteClick,
    loading,
    showUploadListInfo,
}: {
    data: TrackRecordFileItem;
    type: RecordListModalType;
    showUploadListInfo?: boolean;
    deleteClick?: () => void;
    loading?: boolean;
}) => {
    const loginData = useSelector((state: LKRootState) => state.login);
    return (
        <Spin spinning={loading === undefined ? false : loading}>
            <div className="filelist_item">
                <Button
                    className="filelist_item_name"
                    type="link"
                    onClick={() => window.open(data.url)}
                >
                    {getFileName(data.name)}
                </Button>
                {showUploadListInfo && <div className="filelist_item_time">{data.creatTime}</div>}
                {showUploadListInfo && (
                    <div className="filelist_item_user">{data.createrName ?? loginData?.name}</div>
                )}
                {/* <Button
            className="filelist_item_icon"
            onClick={() => downloadFile(data.url, data.name)}
        >
            <ArrowDownOutlined />
        </Button> */}
                {(type === RecordListModalType.Edit || type === RecordListModalType.Add) && (
                    <Button
                        style={{ color: 'white', backgroundColor: '#ff4d4f' }}
                        onClick={() => {
                            deleteClick && deleteClick();
                        }}
                    >
                        <DeleteOutlined />
                    </Button>
                )}
            </div>
        </Spin>
    );
};

export default FileItem;
