import React, { useCallback, useState, useEffect, useMemo } from 'react';
import styles from './index.module.scss';
import { Form, Select, Button } from 'antd';
import { CustomList } from '../../../service/type';
import { getAllCustomerLists } from '../../../service';
import { showError } from '../../RecommendationComponent/util';
import { useHistory } from 'react-router';
import { EventList, uploadEventTrack } from '../../../service/requests/EventTrack';
const { Option } = Select;

const SingleCustomerQuote = () => {
    const [customerList, setCustomerList] = useState<CustomList['customer_list']>([]);

    const handleSearch = useCallback((searchKey: string) => {
        getAllCustomerLists({
            searchKey,
        }).then((res) => {
            setCustomerList(res.data.customer_list);
        }, showError);
    }, []);
    useEffect(() => {
        handleSearch('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const options = useMemo(
        () =>
            customerList.map((item) => (
                <Option key={item.id} value={item.id}>
                    {item.name}
                </Option>
            )),
        [customerList]
    );
    const history = useHistory();
    const onFinish = useCallback(
        ({ customer_id }) => {
            uploadEventTrack(EventList.SingleQuoteSelectCustomer, 2);
            history.push(`${history.location.pathname}/${customer_id}/`);
        },
        [history]
    );
    return (
        <div className={styles.wrapper}>
            <div className={styles.title}>客户产品报价平台</div>
            <Form
                name="AddRealAccount"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
            >
                <Form.Item name="customer_id" rules={[{ required: true, message: '请选择客户' }]}>
                    <Select
                        showSearch
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={handleSearch}
                        placeholder={'在此输入客户ID'}
                        className={styles.input}
                    >
                        {options}
                    </Select>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" className={styles.submit}>
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default SingleCustomerQuote;
