import { Modal, Form, Button, Input } from 'antd';
import React, { useCallback } from 'react';
import { addRecomTag } from '../../service';
import { showError } from './util';

const { TextArea } = Input;
const CreateRecommendationListModal: React.FC<{
    visible: boolean;
    close: () => void;
    handleRefreshList: () => void;
    f_sec_type: string;
}> = ({ visible, close, f_sec_type, handleRefreshList }) => {
    const onFinish = useCallback(
        (values: { name: string; brief: string }) => {
            addRecomTag({
                f_sec_type,
                ...values,
            }).then(() => {
                handleRefreshList();
                close();
            }, showError);
        },
        [close, f_sec_type, handleRefreshList]
    );
    return (
        <Modal
            title="创建推荐列表"
            destroyOnClose
            footer={null}
            visible={visible}
            onCancel={close}
            centered
        >
            <Form
                name="createList"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
            >
                <Form.Item
                    label="列表名"
                    name="name"
                    rules={[{ required: true, message: '请输入列表名' }]}
                >
                    <Input
                        style={{
                            width: '100%',
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="列表描述"
                    name="brief"
                    rules={[{ required: true, message: '请输入描述' }]}
                >
                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateRecommendationListModal;
