import React, { useState } from 'react';
import RecordTags from '../RecordTags';
import './index.less';
import { EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import RecordListModal, { RecordListModalType } from '../Modal';
import FileItem from '../FileItem';
import { TrackRecordItem } from '../../request';
import moment from 'moment';
import { EventList, uploadEventTrack } from '../../../../../../service/requests/EventTrack';
const RecordItem = ({
    data,
    customer_id,
    isList,
    tags,
}: {
    data: TrackRecordItem;
    customer_id: number;
    isList?: boolean;
    tags?: string[];
}) => {
    const [visible, setVisible] = useState(false);
    const [type, setType] = useState<RecordListModalType>(RecordListModalType.View);
    return (
        <div className="record_item_content">
            {visible && (
                <RecordListModal
                    customer_id={customer_id}
                    isList={isList ?? false}
                    visibleState={[visible, setVisible]}
                    changeType={setType}
                    modalType={type}
                    refreshTags={tags}
                    data={data}
                />
            )}
            <div className="header">
                <div className="header_info">
                    <span className="time">
                        {moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')}
                    </span>
                    <span className="sp">/</span>
                    <span className="name">{data.creator.name}</span>
                    <span className="sp">/</span>
                    <RecordTags tags={data.tags} />
                </div>
                <Button
                    className="view_detail"
                    onClick={() => {
                        uploadEventTrack(EventList.CustomerListRecordDetail, 2);
                        setVisible(true);
                    }}
                >
                    <EyeOutlined />
                    查看详情
                </Button>
            </div>
            <div className="text_content">
                {/* {data.content.split('\n').map((item, index) => {
                    return (
                        <div key={index}>
                            {item}
                            <br />
                        </div>
                    );
                })} */}
                {data.content}
            </div>
            <div className="filelist">
                <div className="filelist_title">附件</div>
                <div className="filelist_content">
                    {(data?.attachments ?? []).slice(0, 3).map((item) => {
                        return (
                            <FileItem
                                type={RecordListModalType.View}
                                key={item.id}
                                data={{
                                    id: item.id,
                                    name: item.url,
                                    url: item.url,
                                    creatTime: moment(item.upload_at).format('YYYY-MM-DD HH:mm:ss'),
                                    createrName: item.uploader_member.name,
                                }}
                            />
                        );
                    })}
                    {(data?.attachments ?? []).length > 3 && (
                        <div className="more" onClick={() => setVisible(true)}>
                            {`查看剩余${data.attachments.length - 3}篇请点击查看详情`}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RecordItem;
