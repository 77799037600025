import React, { useEffect } from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import NotFound from './modules/pages/NotFound';
import Login from './modules/pages/Login';
import App from './App';
import { useGetToken } from './utils/hooks';
import { useSelector } from 'react-redux';
import AuditPosition from './modules/AssetManagement/Assets/AuditPosition';
// import VConsole from 'vconsole';
export default function Page() {
    //鉴权
    useGetToken({ redirectUrl: '/login' });
    let login = useSelector((state: any) => state.login);
    useEffect(() => {
        if (process.env.REACT_ADMIN_ENV === 'dev') {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            // const vconsole = new VConsole();
        }
    }, []);
    return (
        <Router>
            <Switch>
                <Route path="/auditposition" component={AuditPosition} />
                <Route path="/login" component={Login} />
                <Route path="/404" component={NotFound} />
                {!!login && (
                    <>
                        <Route exact path="/" render={() => <Redirect to="/app" push />} />
                        <Route path="/app" component={App} />
                    </>
                )}
                <Route component={NotFound} />
            </Switch>
        </Router>
    );
}
