import { useRequest } from 'ahooks';
import { Button, Divider, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { downloadPdfByHtml, getTransactionLetterInfo } from '../../request';
import moment from 'moment';
import { Asset, AssetType, TransTransferExtInfo } from '../../type';
import { FormatNumber } from '../../../../utils';
import { encode } from 'js-base64';

interface TransferPdfPreviewProps {
    transId: number;
    callback: () => void;
}
const TransferPdfPreview = (props: TransferPdfPreviewProps) => {
    const { transId, callback } = props;
    const ref = useRef<HTMLDivElement>(null);
    const [securitiesAssets, setSecuritiesAssets] = useState<Asset[]>([]);
    const [cashAssets, setCashAssets] = useState<Asset[]>([]);
    const { data: letterInfoData } = useRequest(getTransactionLetterInfo, {
        defaultParams: [{ trans_id: transId }],
    });
    const rowStyle: React.CSSProperties = {
        // display: 'inline-block',
    };
    const keyStyle: React.CSSProperties = {
        display: 'inline-block',
        width: '30%',
        fontSize: '14px',
    };
    const valueStyle: React.CSSProperties = {
        display: 'inline-block',
        width: '70%',
        fontSize: '14px',
        fontWeight: 'bold',
    };
    const fontBoldStyle: React.CSSProperties = {
        fontWeight: 'bold',
    };
    const sectionStyle: React.CSSProperties = {
        padding: '0 20px',
    };
    const signatureStyle: React.CSSProperties = {
        display: 'inline-block',
        width: '30%',
        marginTop: '60px',
        marginBottom: '20px',
        borderTop: '1px solid #ccc',
        paddingTop: '5px',
    };
    const marginTopStype: React.CSSProperties = {
        marginTop: '10px',
    };
    const securitiesColStyle: React.CSSProperties = {
        display: 'inline-block',
        width: '30%',
    };
    const securitiesSecondColStyle: React.CSSProperties = {
        display: 'inline-block',
        width: '50%',
    };
    const securitiesLastColStyle: React.CSSProperties = {
        display: 'inline-block',
        width: '20%',
        textAlign: 'right',
    };
    const cashColStyle: React.CSSProperties = {
        display: 'inline-block',
        width: '50%',
    };
    const cashLastColStyle: React.CSSProperties = {
        display: 'inline-block',
        width: '50%',
        textAlign: 'right',
    };
    useEffect(() => {
        if (letterInfoData) {
            const cashList = [
                AssetType.Cash,
                AssetType.FixedDeposit,
                AssetType.NCD,
                AssetType.Loan,
            ];
            setCashAssets(
                letterInfoData.data.transaction.assets.filter((t) =>
                    cashList.includes(t.asset_type.code)
                )
            );
            setSecuritiesAssets(
                letterInfoData.data.transaction.assets.filter(
                    (t) => !cashList.includes(t.asset_type.code)
                )
            );
        }
    }, [letterInfoData]);
    const onEditClick = () => {
        callback();
    };
    const onDonwloadClick = async () => {
        return downloadPdfByHtml({
            html_base64: encode(
                `<!DOCTYPE HTML><html><head><style>body {font-family:"Arial Unicode MS";}</style></head><body> <div style="padding: 20px;">${ref.current?.innerHTML}</div></body></html>`
            ),
            file_name: 'transfer_letter.pdf',
        }).then(
            () => {},
            (err) => {
                executeDownload(err, 'transfer_letter.pdf');
            }
        );
    };
    const executeDownload = (data: any, filename: string) => {
        if (!data) {
            return;
        }
        const blob = new Blob([data]);
        if (navigator.msSaveBlob) {
            try {
                navigator.msSaveBlob(data, filename);
            } catch (e) {
                console.log(e);
            }
        } else {
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };
    return (
        <Spin spinning={!letterInfoData}>
            {letterInfoData && (
                <>
                    <div ref={ref}>
                        <div style={rowStyle}>
                            <div style={keyStyle}>Date</div>
                            <div style={valueStyle}>
                                {letterInfoData.data.letter_info.date
                                    ? moment(letterInfoData.data.letter_info.date).format(
                                          'yyyy-MM-DD'
                                      )
                                    : ''}
                            </div>
                        </div>
                        <div style={rowStyle}>
                            <div style={keyStyle} />
                            <div style={valueStyle}>
                                {letterInfoData.data.letter_info.from_account_bank_name || ''}
                            </div>
                        </div>
                        <div style={rowStyle}>
                            <div style={keyStyle}>Attention</div>
                            <div style={valueStyle}>
                                {letterInfoData.data.letter_info.attention || ''}
                            </div>
                        </div>
                        <div>Dear Sirs/Madam,</div>
                        <div style={{ ...fontBoldStyle, ...marginTopStype }}>
                            Assets Transfer out instructions
                        </div>
                        <div style={{ ...rowStyle, ...marginTopStype }}>
                            <div style={keyStyle}>Account Name</div>
                            <div style={valueStyle}>
                                {(letterInfoData.data.transaction.ext_info as TransTransferExtInfo)
                                    .out_account_name || ''}
                            </div>
                        </div>
                        <div style={rowStyle}>
                            <div style={keyStyle}>Account Number</div>
                            <div style={valueStyle}>
                                {(letterInfoData.data.transaction.ext_info as TransTransferExtInfo)
                                    .out_account_no || ''}
                            </div>
                        </div>
                        <div style={marginTopStype}>
                            Please accept thie letter as my instruction to transfer assets
                        </div>
                        <div style={marginTopStype}>Beneficiary</div>
                        <div style={sectionStyle}>
                            <div style={rowStyle}>
                                <div style={keyStyle}>Beneficiary Bank</div>
                                <div style={valueStyle}>
                                    {letterInfoData.data.letter_info.to_account_bank_name || ''}
                                </div>
                            </div>
                            <div style={rowStyle}>
                                <div style={keyStyle}>Bank Identifier Code</div>
                                <div style={valueStyle}>
                                    {letterInfoData.data.letter_info.to_account_bank_id_code || ''}
                                </div>
                            </div>
                            <div style={rowStyle}>
                                <div style={keyStyle}>Beneficiary Account</div>
                                <div style={valueStyle}>
                                    {(letterInfoData.data.transaction
                                        .ext_info as TransTransferExtInfo).in_account_no || ''}
                                </div>
                            </div>
                            <div style={rowStyle}>
                                <div style={keyStyle}>Beneficiary Name</div>
                                <div style={valueStyle}>
                                    {(letterInfoData.data.transaction
                                        .ext_info as TransTransferExtInfo).in_account_name || ''}
                                </div>
                            </div>
                        </div>
                        {securitiesAssets.length > 0 && (
                            <>
                                <div style={marginTopStype}>Securities</div>
                                <div style={sectionStyle}>
                                    <div style={rowStyle}>
                                        <div style={securitiesColStyle}>ISIN/Other Identifier</div>
                                        <div style={securitiesSecondColStyle}>Name</div>
                                        <div style={securitiesLastColStyle}>Quantity</div>
                                    </div>
                                    <div style={fontBoldStyle}>
                                        {securitiesAssets.map((a) => (
                                            <div style={rowStyle}>
                                                <div style={securitiesColStyle}>{a.isin}</div>
                                                <div style={securitiesSecondColStyle}>{a.name}</div>
                                                <div style={securitiesLastColStyle}>
                                                    {a.qty ? FormatNumber(a.qty) : ''}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                        {cashAssets.length > 0 && (
                            <>
                                <div style={marginTopStype}>Cash</div>
                                <div style={sectionStyle}>
                                    <div style={rowStyle}>
                                        <div style={cashColStyle}>Currency</div>
                                        <div style={cashLastColStyle}>Amount</div>
                                    </div>
                                    <div style={fontBoldStyle}>
                                        {cashAssets.map((a) => (
                                            <div style={rowStyle}>
                                                <div style={cashColStyle}>{a.currency}</div>
                                                <div style={cashLastColStyle}>
                                                    {a.amount ? FormatNumber(a.amount, 2) : ''}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>
                        )}
                        <div style={marginTopStype}>Purpose</div>
                        <div style={sectionStyle}>
                            {letterInfoData.data.letter_info.purpose || ''}
                        </div>
                        <div style={marginTopStype}>Contact</div>
                        <div style={sectionStyle}>
                            <div style={rowStyle}>
                                <div style={keyStyle}>Contact Person</div>
                                <div style={valueStyle}>
                                    {letterInfoData.data.letter_info.contact_person || ''}
                                </div>
                            </div>
                            <div style={rowStyle}>
                                <div style={keyStyle}>Email Address</div>
                                <div style={valueStyle}>
                                    {letterInfoData.data.letter_info.email_address || ''}
                                </div>
                            </div>
                            <div style={rowStyle}>
                                <div style={keyStyle}>Group Email</div>
                                <div style={valueStyle}>
                                    {letterInfoData.data.letter_info.group_email || ''}
                                </div>
                            </div>
                            <div style={rowStyle}>
                                <div style={keyStyle}>Contact Number</div>
                                <div style={valueStyle}>
                                    {letterInfoData.data.letter_info.contact_number || ''}
                                </div>
                            </div>
                        </div>

                        <div style={marginTopStype}>Thank you for your kind attention.</div>
                        <div>Yours faithfully,</div>
                        <div>For and on behalf of</div>
                        <div style={signatureStyle}>
                            {(letterInfoData.data.transaction.ext_info as TransTransferExtInfo)
                                .out_account_name || ''}
                        </div>
                    </div>
                    <Divider />
                    <Button onClick={onEditClick}>Edit Letter</Button>
                    <Button onClick={onDonwloadClick}>Download Letter</Button>
                </>
            )}
        </Spin>
    );
};

export default TransferPdfPreview;
