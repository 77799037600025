import React, { useEffect } from 'react';
import { useState } from 'react';
import {
    getSubscribeInfo,
    ApplyInfoModel,
    identModel,
} from '../../../service/requests/ShareMeeting';
import { showError } from '../../RecommendationComponent/util';
import './index.less';
import { Button } from 'antd';
export default function SubscribeInfo() {
    const [infoList, setInfoList] = useState<Array<ApplyInfoModel>>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    useEffect(() => {
        getSubscribeInfo({ activityId: 0 }).then((res) => {
            // res.data.sort();
            res.data.forEach((item) => {
                var map = new Map<number, identModel>();
                item.fields.forEach((subitem) => {
                    map.set(subitem.id, subitem);
                });
                item.values = map;
            });
            setInfoList(res.data);
        }, showError);
    }, []);

    return (
        <>
            <div className="top-row">
                {infoList.map((item, index) => {
                    return (
                        <Button
                            type={currentIndex === index ? 'primary' : 'default'}
                            onClick={() => {
                                setCurrentIndex(index);
                            }}
                        >
                            {item.title}
                        </Button>
                    );
                })}
            </div>
            {infoList?.map((item, index) => {
                return (
                    <div
                        className="container"
                        key={index}
                        style={{ display: currentIndex === index ? 'block' : 'none' }}
                    >
                        {item.guests.map((info, index) => (
                            <div className="info-container" key={index}>
                                <div className="info-header">
                                    <img src={info.avatar} alt="" />
                                    <span>
                                        {`${info.name} / ${info.boundMobile} (${info.mobile}) `}
                                    </span>
                                    <span
                                        className={
                                            info.state === 'Submitted'
                                                ? 'state-submitted'
                                                : 'state-pending'
                                        }
                                    >
                                        {info.state}
                                    </span>
                                </div>
                                <div className="info-content">
                                    <p>关注内容：</p>
                                    <ul>
                                        {info.favouriteTopics?.map((id) => {
                                            var a = item.favouriteTopicOptions.find(
                                                (item) => item.id === id
                                            );
                                            return <li key={id}>{a?.label}</li>;
                                        })}
                                    </ul>
                                    <p>其他关心的问题：{info.otherFavouriteTopics}</p>
                                    <p>拓展问题</p>
                                    <ul>
                                        {Object.keys(info.values ?? []).map((key) => {
                                            var detail = item.values.get(Number.parseInt(key));
                                            if (detail?.type === '文本') {
                                                return (
                                                    <li>
                                                        {`${detail?.name}` +
                                                            '：' +
                                                            info.values![Number.parseInt(key)]}
                                                    </li>
                                                );
                                            } else {
                                                var a = detail?.options.find(
                                                    (item) =>
                                                        item.id ===
                                                        info.values![Number.parseInt(key)]
                                                );

                                                return (
                                                    <li>
                                                        <p>{`${detail?.name}:${a?.label}`}</p>
                                                    </li>
                                                );
                                            }
                                        })}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                );
            })}
        </>
    );
}
