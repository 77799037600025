import { SET_PAGE_STATE } from '../../constants/constants';
import { RoutePageState, SetPageStateAction } from '../../type';

export const setPageState = <T extends keyof RoutePageState>(
    key: T,
    value: RoutePageState[T]
): SetPageStateAction<T> => {
    return {
        type: SET_PAGE_STATE,
        payload: {
            key,
            value,
        },
    };
};
