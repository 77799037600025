import { ICellRendererParams, RowHeightParams } from 'ag-grid-community';
import '../CustomerList/index.less';
import { AgGridReact } from 'ag-grid-react';
import { Button, Checkbox, Col, Form, Input, message, Modal, Pagination, Row } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    getCustomerManageData,
    getICList,
    getRMList,
    RMList,
    setCustomerRMs,
} from '../../service/requests/CustomerManage';
import useFetch from '../../utils/Hooks/useFetch';
import { showError } from '../RecommendationComponent/util';
import './index.less';
import VerifyModal from '../../components/VerifyModal';
import { EventList, uploadEventTrack } from '../../service/requests/EventTrack';
import ShareDefs from '../CustomerList/CommonColumnDef';
import CustomerFilterBtns from '../../components/CustomerFilterBtns';
import TradersSetField from './component/TradersSetFeild';
import { CustomerData } from '../CustomerList/components/CustomerDetail/type';
import usePageState from '../../utils/Hooks/usePageState';
const PAGE_SIZE = 12;

export default function CustomerManage() {
    const { dispacth, selector } = usePageState();
    const [curPage, setCurPage] = useState(selector?.page ?? 1);
    const inputRef = useRef<Input>(null);
    const [fetchState, setParams] = useFetch({
        interfaces: getCustomerManageData,
        initialParams: {
            currentPage: curPage,
            pageSize: PAGE_SIZE,
        },
    });
    const [form] = Form.useForm();

    const [curEditRow, setCurEditRow] = useState(0);
    const [rmValues, setRmValues] = useState<number[]>([]);
    const checkData = useMemo(() => {
        let resultRm = fetchState.data?.pageData[curEditRow]?.rms.map((rm) => rm.id);
        let resultIc = fetchState.data?.pageData[curEditRow]?.ics.map((rm) => rm.id);
        let tradersMap: Map<string, number[]> = new Map();
        fetchState.data?.pageData[curEditRow]?.bankAccounts?.forEach((bank) => {
            tradersMap.set(
                bank.accountId,
                bank.traders?.map((item) => item.id)
            );
        });
        setRmValues(resultRm ?? []);
        return {
            rms: resultRm ?? [],
            ics: resultIc ?? [],
            traders: tradersMap,
        };
    }, [fetchState.data, curEditRow]);
    useEffect(() => {
        form.resetFields();
    }, [checkData, form]);

    const editRMEnble = useMemo(() => {
        return rmValues.length > 0;
    }, [rmValues]);
    const [rmList, setRmList] = useState<RMList>([]);
    const [icList, setIcList] = useState<RMList>([]);
    const [traderList, setTraderList] = useState<RMList>([]);
    useEffect(() => {
        getRMList().then((res) => {
            setRmList(res.data ?? []);
        }, showError);
        getICList().then((res) => {
            setIcList(res.data ?? []);
        }, showError);
        getRMList({ role: 8 }).then((res) => {
            setTraderList(res.data ?? []);
        });
    }, []);

    useEffect(() => {
        form.resetFields(['ics', 'rms']);
    }, [curEditRow, form, fetchState.data]);

    const defs = ShareDefs({
        actions: {
            headerName: '操作',
            pinned: 'right',
            width: 210,
            cellRenderer: (params: ICellRendererParams) => (
                <Button
                    disabled={params.data.isChildUser === 1 || params.data.state === '已注销'}
                    onClick={() => {
                        uploadEventTrack(EventList.CustomerManageEditRM, 2);
                        setCurEditRow(params.rowIndex);
                        setShowEditModal(true);
                    }}
                >
                    修改RM & IC & 交易员
                </Button>
            ),
        },
    });
    const [showEditModal, setShowEditModal] = useState(false);

    const [filterField, setFilterField] = useState('');
    const handlePaginationChange = useCallback(
        (page: number) => {
            setCurPage(page);
            dispacth({ page });
            setParams({
                pageSize: 12,
                currentPage: page,
                search_key: filterField,
            });
        },
        [dispacth, setParams, filterField]
    );
    const [showVerify, setShowVerify] = useState(false);

    const handleClickContent = useCallback(() => {
        document.dispatchEvent(new CustomEvent('userOperation'));
    }, []);
    const onFinish = useCallback(
        (values) => {
            let tradersObj = ((values.traders as Map<string, number[]>) ?? new Map()).entries();
            let obj: { [key: string]: number[] } = {};

            for (let [key, value] of tradersObj) {
                obj[key] = value;
            }
            setCustomerRMs({
                customerId: fetchState.data?.pageData[curEditRow].id ?? 0,
                memberIds: values.rms,
                icIds: values.ics,
                traders: obj,
            })
                .then(() => {
                    message.success('修改成功');
                    setParams({
                        currentPage: curPage,
                        pageSize: PAGE_SIZE,
                        search_key: filterField,
                    });
                    setShowEditModal(false);
                })
                .catch((err) => {
                    showError(err);
                    setShowEditModal(false);
                });
        },
        [filterField, curPage, setParams, fetchState.data, curEditRow]
    );
    const handleFilterChange = useCallback(
        (value: string) => {
            setFilterField(value);
            uploadEventTrack(EventList.CustomerManageUseFilter, 2, {
                searchKey: value,
            });
            setCurPage(1);
            dispacth({ page: 1 });
            setParams({
                currentPage: 1,
                pageSize: 12,
                search_key: value,
            });
        },
        [dispacth, setParams]
    );
    const buttonValueChangeSearchResult = useCallback(
        (e) => {
            if (inputRef.current) {
                inputRef.current.setValue(e);
            }
            handleFilterChange(e);
        },
        [handleFilterChange, inputRef]
    );
    const getRowHeight = useCallback((params: RowHeightParams) => {
        let data = params.data as CustomerData;
        if (data.bankAccounts && data.bankAccounts.length > 0) {
            return 48 * data.bankAccounts.length;
        }
        return 48;
    }, []);
    return (
        <div id="CustomerManage" className="CustomerManage" onClick={handleClickContent}>
            <VerifyModal
                visible={showVerify}
                setVisible={setShowVerify}
                containerId="CustomerManage"
                pageId="CustomerManage"
            />
            <div className="search">
                <Input.Search
                    placeholder="请输入昵称、客户ID等相关信息进行搜索"
                    ref={inputRef}
                    enterButton
                    allowClear
                    onSearch={handleFilterChange}
                />
            </div>
            <CustomerFilterBtns click={buttonValueChangeSearchResult} />
            <div className="ag-theme-alpine CustomerManage__TableContainer">
                <AgGridReact
                    animateRows
                    columnDefs={defs}
                    getRowHeight={getRowHeight}
                    defaultColDef={{ resizable: true, width: 96, minWidth: 68, suppressMenu: true }}
                    rowData={fetchState.data?.pageData}
                    ensureDomOrder
                />

                <Modal
                    width={800}
                    visible={showEditModal}
                    onCancel={() => setShowEditModal(false)}
                    title="修改客户所属RM & IC"
                    getContainer={document.getElementById('CustomerManage') ?? false}
                    forceRender
                    footer={null}
                >
                    {(fetchState.data?.pageData[curEditRow]?.relativeMobile ?? '').length > 0 && (
                        <div
                            style={{ marginBottom: '15px' }}
                        >{`将同时为客户${fetchState.data?.pageData[curEditRow]?.relativeName}修改所属RM和IC`}</div>
                    )}
                    <Form
                        form={form}
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 21 }}
                        initialValues={{ ...checkData }}
                        onFinish={onFinish}
                    >
                        <Form.Item label="所属RM" name="rms">
                            <Checkbox.Group
                                style={{ width: '100%' }}
                                onChange={(item) => setRmValues(item as number[])}
                            >
                                <Row>
                                    {rmList.map((rm) => (
                                        <Col span={4} key={rm.id}>
                                            <Checkbox value={rm.id} style={{ lineHeight: '32px' }}>
                                                {rm.name}
                                            </Checkbox>
                                        </Col>
                                    ))}
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                        <Form.Item label="所属IC" name="ics">
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row>
                                    {icList.map((rm) => (
                                        <Col span={4} key={rm.id}>
                                            <Checkbox value={rm.id} style={{ lineHeight: '32px' }}>
                                                {rm.name}
                                            </Checkbox>
                                        </Col>
                                    ))}
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>

                        <Form.Item label="交易员" name="traders">
                            {(fetchState.data?.pageData[curEditRow]?.bankAccounts ?? []).length >
                                0 && (
                                <TradersSetField
                                    traders={traderList}
                                    bankList={fetchState.data?.pageData[curEditRow]?.bankAccounts}
                                />
                            )}
                            {(fetchState.data?.pageData[curEditRow]?.bankAccounts ?? []).length ===
                                0 && (
                                <>
                                    <div>无银行账户不能设置交易员</div>
                                    <Checkbox.Group style={{ width: '100%' }} disabled>
                                        <Row>
                                            {traderList.map((rm) => (
                                                <Col span={4} key={rm.id}>
                                                    <Checkbox
                                                        value={rm.id}
                                                        style={{ lineHeight: '32px' }}
                                                    >
                                                        {rm.name}
                                                    </Checkbox>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                </>
                            )}
                        </Form.Item>
                        <Form.Item style={{ marginTop: '8px' }} wrapperCol={{ offset: 6 }}>
                            <Button type="primary" htmlType="submit" disabled={!editRMEnble}>
                                确定
                            </Button>
                            <Button
                                onClick={() => setShowEditModal(false)}
                                style={{ marginLeft: '30%' }}
                            >
                                取消
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
            {fetchState.data?.totalPages && fetchState.data.totalPages > 1 ? (
                <Pagination
                    current={curPage}
                    onChange={handlePaginationChange}
                    total={fetchState.data?.totalHits}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 16,
                        marginBottom: 16,
                    }}
                    pageSize={12}
                    showSizeChanger={false}
                />
            ) : null}
        </div>
    );
}
