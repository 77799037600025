import React from 'react';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import styles from './index.module.less';
import { LinkOutlined } from '@ant-design/icons';
import { AssetType, Flow } from '../../type';
import { FormatNumber } from '../../../../utils';
import { useHistory } from 'react-router-dom';

interface TransactionAccountFlowTable {
    settlementDate: string;
    data: Flow[];
}
const TransactionAccountFlowTable = (props: TransactionAccountFlowTable) => {
    const { settlementDate, data } = props;
    const history = useHistory();
    const columns: ColumnsType<Flow> = [
        {
            title: 'Settlement Date',
            render: () => <>{settlementDate}</>,
        },
        {
            title: 'Account',
            render: (_, record) => {
                if (record.account) {
                    return (
                        <>{`${record.account?.bank_name}-${record.account?.vendor_sub_account_id}`}</>
                    );
                } else {
                    return '-';
                }
            },
        },
        {
            title: 'Asset',
            dataIndex: ['asset', 'name'],
            render: (value, record) => {
                if (record.asset.asset_type.code === AssetType.Cash) return value;
                return (
                    <span
                        className="lk_link"
                        onClick={() => {
                            if (!record.account || !record.asset) return;
                            history.push(
                                `/app/assetManagement/position/detail/${record.account.account_id}/${record.asset.isin}`
                            );
                        }}
                    >
                        {value}
                    </span>
                );
            },
        },
        {
            title: 'Presume Amount',
            align: 'right',
            render: (_, record) => (
                <>
                    {Number.isNaN(record.presume_amount)
                        ? ''
                        : FormatNumber(record.presume_amount, 2)}
                </>
            ),
        },
        {
            title: 'Verified Amount',
            align: 'right',
            render: (_, record) => (
                <>
                    {Number.isNaN(record.verified_amount)
                        ? ''
                        : FormatNumber(record.verified_amount, 2)}
                </>
            ),
        },
        {
            title: '',
            render: (_, record) => (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        onClick={() => {
                            if (record.reconciliation_id) {
                                window.open(
                                    `/#/app/reconciliation/list/detail?id=${record.reconciliation_id}`,
                                    '_blank'
                                );
                            }
                        }}
                        disabled={record.reconciliation_id === null}
                    >
                        <LinkOutlined />
                    </Button>
                </div>
            ),
        },
    ];
    return (
        <div className={styles.flowTable}>
            <Table
                rowKey={(record) => record.id}
                columns={columns}
                dataSource={data}
                size="small"
                pagination={false}
            />
        </div>
    );
};

export default TransactionAccountFlowTable;
