import React, { useState } from 'react';
import { Button, message, Modal } from 'antd';
import { showError, stopPropagation } from './util';
import { useCallback } from 'react';
import { updateRecomTag } from '../../service';
import AddAssetModal from './AddAssetModal';
import { Input } from 'antd';
import { RecomProductList } from '../../service/type';
import { deleteRecomTag } from '../../service';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import EditTitle from './EditTitle';

const { confirm } = Modal;
const { TextArea } = Input;

const PanelHeader: React.FC<{
    recomProduct: RecomProductList[0];
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    handleRefreshList: () => void;
    setActive: () => void;
}> = ({ recomProduct, handleRefreshList, setActive }) => {
    const [addVisible, setAddVisible] = useState(false);
    const [value, setValue] = useState(recomProduct.brief);

    const handleSubmit = useCallback(() => {
        if (value !== recomProduct.brief) {
            updateRecomTag({
                tag_id: recomProduct.tag_id,
                name: recomProduct.name,
                brief: value,
            }).then(() => {
                message.success('修改成功');
                handleRefreshList();
            }, showError);
        }
    }, [handleRefreshList, recomProduct.brief, recomProduct.name, recomProduct.tag_id, value]);
    const handleAddClick: React.MouseEventHandler<HTMLElement> = useCallback((event) => {
        event.stopPropagation();
        setAddVisible(true);
    }, []);
    const handleDelete: React.MouseEventHandler<HTMLElement> = useCallback(
        (event) => {
            event.stopPropagation();
            confirm({
                title: '确认删除?',
                icon: <ExclamationCircleOutlined />,
                onOk() {
                    deleteRecomTag({ tag_id: recomProduct.tag_id }).then(
                        handleRefreshList,
                        showError
                    );
                },
            });
        },
        [recomProduct.tag_id, handleRefreshList]
    );

    return (
        <div
            style={{ width: '100%' }}
            onKeyPress={stopPropagation}
            onKeyUp={stopPropagation}
            onKeyDown={stopPropagation}
        >
            <div className="header-wrapper">
                <div onClick={stopPropagation}>
                    <AddAssetModal
                        tag_id={recomProduct.tag_id}
                        visible={addVisible}
                        setVisible={setAddVisible}
                        setActive={setActive}
                        handleRefreshList={handleRefreshList}
                    />
                </div>
                <span className="title">
                    <EditTitle recomProduct={recomProduct} handleRefreshList={handleRefreshList} />
                </span>
                <Button className="header-button" onClick={handleAddClick}>
                    添加产品
                </Button>
                <Button className="header-button" onClick={handleDelete}>
                    删除
                </Button>
            </div>
            <TextArea
                onClick={stopPropagation}
                autoSize
                value={value}
                onChange={(target) => setValue(target.target.value)}
                onBlur={handleSubmit}
                placeholder="点击输入描述"
                style={{
                    backgroundColor: '#E6E6E6',
                    borderRadius: '12px',
                    marginTop: 20,
                    minHeight: 50,
                }}
            />
        </div>
    );
};

export default PanelHeader;
