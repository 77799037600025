import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Button, Form, Select } from 'antd';
import { getCustomerList } from '../../../service';
import { CloseCircleFilled } from '@ant-design/icons';
import { CustomerManageData } from '../../../service/requests/CustomerManage';
import { useRequest } from 'ahooks';

const { Option } = Select;

const SearchCustomUserFormItem = (prop: {
    disabled?: boolean;
    labelStr?: string;
    clear?: () => void;
    defaultSearchValue?: string;
    name?: string;
    width?: string;
    userCallback: (user?: CustomerManageData['pageData'][0]) => void;
}) => {
    // const [customerList, setCustomerList] = useState<CustomerManageData['pageData']>([]);
    const [searchKey, setSearchKey] = useState('');
    const { data: customerList, run, cancel } = useRequest(getCustomerList, {
        manual: true,
        defaultParams: [
            {
                search_key: '',
                pageSize: 100,
                currentPage: 1,
            },
        ],
    });
    useEffect(() => {
        run({
            search_key: searchKey,
            pageSize: 100,
            currentPage: 1,
        });
        return () => {
            cancel();
        };
    }, [cancel, run, searchKey]);
    const handleSearch = (searchKey: string) => {
        setSearchKey(searchKey);
    };
    const options = useMemo(
        () =>
            (customerList?.data.pageData ?? []).map((item, index) => (
                <Option key={item.id} value={index}>
                    {item.name}
                </Option>
            )),
        [customerList]
    );
    const closeClick = useCallback(() => {
        prop.userCallback(undefined);
        prop.clear!();
    }, [prop]);
    return (
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <Form.Item
                style={{ width: prop.width ?? '100%' }}
                label={prop.labelStr ?? '交易用户'}
                name={prop.name ?? 'customer_id'}
                rules={[{ required: true, message: '请选择客户' }]}
                initialValue={prop.defaultSearchValue}
            >
                <Select
                    showSearch
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={handleSearch}
                    disabled={prop.disabled}
                    // clearIcon={}
                    placeholder={'在此输入客户ID'}
                    onSelect={(index: number) => {
                        if (index === undefined) {
                            prop.userCallback(undefined);
                        } else {
                            prop.userCallback((customerList?.data.pageData ?? [])[index]);
                        }
                    }}
                >
                    {options}
                </Select>
            </Form.Item>
            {prop.clear !== undefined && (
                <Form.Item>
                    <Button type="link" onClick={closeClick}>
                        <CloseCircleFilled />
                    </Button>
                </Form.Item>
            )}
        </div>
    );
};
export default SearchCustomUserFormItem;
