import React, { forwardRef, useEffect, useRef } from 'react';
import { Asset, AssetType } from '../../../Transaction/type';
import { Form, FormInstance } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import {
    GinkgoInput,
    GinkgoInputNumber,
} from '../../../Transaction/components/TransForm/Forms/FormItems/GinkgoFieldItems';
import LKRadioGroup from '../../../../components/LKRadioGroup';
import { getAllProductPriceList } from '../../request';
import { ModalType } from '../..';
import { searchProduct } from '../../../Transaction/request';

interface OptionFormProps {
    type: AssetType;
    modalType: ModalType;
    asset?: Asset;
}

const FundForm = forwardRef<FormInstance, OptionFormProps>((props, ref) => {
    const { asset } = props;
    const [form] = useForm();
    const formItemStyle: React.CSSProperties = {
        width: 'calc(75% - 8px)',
    };
    const [struct, setStruct] = React.useState<number>(1);
    const resultList = useRef<Asset[]>([]);
    const isinAssetList = useRef<Asset[]>([]);
    useEffect(() => {
        if (asset) {
            const extInfo = asset.ext_info as any;

            form.setFieldsValue({
                ...asset,
                ext_info: {
                    fund_struct_type: 1,
                    ...extInfo,
                },
            });
            setStruct(extInfo.fund_struct_type);
            if (asset.name) {
                validator(asset.name);
            }
            if (asset.isin) {
                isinValidator(asset.isin);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asset, form]);

    const structOptions = [
        {
            label: 'Open End',
            value: 1,
            width: '25%',
        },
        {
            label: 'Close End',
            value: 2,
            width: '25%',
        },
    ];
    const currencyOptions = [
        {
            label: 'USD',
            value: 'USD',
            width: '18.75%',
        },
        {
            label: 'EUR',
            value: 'EUR',
            width: '18.75%',
        },
        {
            label: 'HKD',
            value: 'HKD',
            width: '18.75%',
        },
        {
            label: 'CNY',
            value: 'CNY',
            width: '18.75%',
        },
    ];
    const redFOptions = [
        {
            label: 'Monthly',
            value: 1,
            width: '25%',
        },
        {
            label: 'Quarterly',
            value: 2,
            width: '25%',
        },
        {
            label: 'Annually',
            value: 3,
            width: '25%',
        },
    ];
    const ccfOptions = [
        {
            label: 'Quarterly',
            value: 1,
            width: '25%',
        },
        {
            label: 'Annually',
            value: 2,
            width: '25%',
        },
    ];
    const validator = (word: string) => {
        getAllProductPriceList({
            page_size: 50,
            current_page: 1,
            asset_type_code: AssetType.Fund,
            ext_info: {
                asset_name: word,
            },
        }).then((value) => {
            resultList.current = value.data.assets ?? [];
            form.validateFields(['name']);
        });
    };
    const isinValidator = (word: string) => {
        searchProduct({
            isin: word,
            asset_types: [],
        }).then((value) => {
            isinAssetList.current = value.data.assets ?? [];
            form.validateFields(['isin']);
        });
    };
    return (
        <>
            <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 17, offset: 1 }} ref={ref}>
                <Form.Item
                    label="Fund Struct"
                    name={['ext_info', 'fund_struct_type']}
                    rules={[{ required: true, message: 'Fund Struct is required.' }]}
                >
                    <LKRadioGroup
                        datasource={structOptions}
                        onChange={(value) => {
                            setStruct(value as number);
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name="isin"
                    label="ISIN"
                    rules={[
                        {
                            validator(rule, value, callback) {
                                if (
                                    value === undefined ||
                                    value === '' ||
                                    props.modalType === ModalType.Edit
                                )
                                    callback();
                                if (isinAssetList.current.length > 0) {
                                    switch (props.modalType) {
                                        case ModalType.Copy:
                                        case ModalType.Create:
                                            callback('ISIN already exists.');
                                            break;
                                        case ModalType.Edit:
                                            if (isinAssetList.current.length > 1) {
                                                callback('ISIN Name already exists.');
                                            } else if (
                                                isinAssetList.current.length === 1 &&
                                                isinAssetList.current[0].name !== asset?.name
                                            ) {
                                                callback('ISIN Name already exists.');
                                            } else {
                                                callback();
                                            }
                                            break;
                                    }
                                } else {
                                    callback();
                                }
                            },
                        },
                    ]}
                >
                    <GinkgoInput
                        style={formItemStyle}
                        readOnly={props.modalType === ModalType.Edit}
                        onBlur={(e) => isinValidator(e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    name="name"
                    rules={[
                        {
                            required: true,
                            validator(rule, value, callback) {
                                if (resultList.current.length > 0) {
                                    switch (props.modalType) {
                                        case ModalType.Copy:
                                        case ModalType.Create:
                                            callback('Fund Name already exists.');
                                            break;
                                        case ModalType.Edit:
                                            if (resultList.current.length > 1) {
                                                callback('Fund Name already exists.');
                                            } else if (
                                                resultList.current.length === 1 &&
                                                resultList.current[0].name !== asset?.name
                                            ) {
                                                callback('Fund Name already exists.');
                                            } else {
                                                callback();
                                            }
                                            break;
                                    }
                                } else {
                                    if ((value as string)?.length > 0) {
                                        callback();
                                    } else {
                                        callback('Fund Name is required.');
                                    }
                                }
                            },
                        },
                    ]}
                    label="Fund Name"
                >
                    <GinkgoInput style={formItemStyle} onBlur={(e) => validator(e.target.value)} />
                </Form.Item>
                <Form.Item
                    name={['ext_info', 'fund_manager']}
                    rules={[{ required: true, message: 'Fund Manager is required.' }]}
                    label="Fund manager"
                >
                    <GinkgoInput style={formItemStyle} />
                </Form.Item>
                <Form.Item
                    label="Currency"
                    name={'currency'}
                    rules={[{ required: true, message: 'Currency is required.' }]}
                >
                    <LKRadioGroup datasource={currencyOptions} />
                </Form.Item>
                {struct === 1 && (
                    <Form.Item
                        label="Redemption Frequency"
                        name={['ext_info', 'redemption_frequency_type']}
                        rules={[{ required: true, message: 'Redemption Frequency is required.' }]}
                    >
                        <LKRadioGroup datasource={redFOptions} />
                    </Form.Item>
                )}

                {struct > 1 && (
                    <Form.Item
                        name={['ext_info', 'capital_call_frequency_type']}
                        rules={[{ required: true, message: 'Capital Call Frequency is required.' }]}
                        label="Capital Call Frequency"
                    >
                        <LKRadioGroup datasource={ccfOptions} />
                    </Form.Item>
                )}
                {struct === 1 && (
                    <Form.Item
                        name={['ext_info', 'redemption_notice']}
                        rules={[{ required: true, message: 'Redemption Notice is required.' }]}
                        label="Redemption Notice"
                    >
                        <GinkgoInputNumber style={formItemStyle} addonAfter={'Days'} />
                    </Form.Item>
                )}
            </Form>
        </>
    );
});

export default FundForm;
