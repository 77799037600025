import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Button, Form, Modal, Radio, Spin, Table, TableColumnType, Tooltip, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useForm } from 'antd/lib/form/Form';
import { CustomerManageData } from '../../service/requests/CustomerManage';
import SearchCustomUserFormItem from '../widget/GeneralForm/SearchCustomUserFormItem';
import { UserAccountDetailInfo } from '../../service/requests/HistoricalTransactions';
import { useRequest } from 'ahooks';
import { addQouteRecord, copyQouteRecord, queryQouteList } from './request';
import { ProductQuoteResponseType } from './type';
import moment from 'moment';
import { EventList, uploadEventTrack } from '../../service/requests/EventTrack';
import { CopyOutlined } from '@ant-design/icons';
import AccountItem from '../Transaction/components/TransForm/Forms/FormItems/AccountItem';
import { AssetType } from '../Transaction/type';
import { showError } from '../RecommendationComponent/util';
import usePageState from '../../utils/Hooks/usePageState';

const GKQuote = () => {
    const { t } = useTranslation();
    const { dispacth, selector } = usePageState();
    const [currentPage, setCurrentPage] = React.useState(selector?.page ?? 1);
    const [pageSize, setPageSize] = React.useState(10);
    const [curBank, setCurBank] = useState<UserAccountDetailInfo>();
    const history = useHistory();
    const [customUser, setCustomUser] = useState<CustomerManageData['pageData'][0]>();
    const [form] = useForm();
    const [visible, setVisible] = useState(false);
    const [currentSelectQuote, setCurrentSelectQuote] = useState<number>();
    const [currentModalType, setCurrentModalType] = useState<'Add' | 'Copy'>('Add');
    const [addLoading, setAddLoading] = useState(false);
    const { data: quoteListData, loading, run, refresh } = useRequest(queryQouteList, {
        defaultParams: [
            {
                current_page: currentPage,
                page_size: pageSize,
            },
        ],
        onError(e: any) {
            showError({ msg: e.msg });
        },
    });
    const columns: TableColumnType<ProductQuoteResponseType['asset_quotes'][number]>[] = [
        {
            title: t('BondQuote_QuoteTime'),
            dataIndex: 'created_at',
            render: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
            width: 300,
        },
        {
            title: t('BondQuote_QuoteUser'),
            dataIndex: 'quoteUser',
            width: 200,
            render(value, record, index) {
                return record.member.name;
            },
        },
        {
            title: t('BondQuote_CustomerId_Name'),
            dataIndex: 'customerId',
            width: 200,
            render(value, record, index) {
                return `${record.client?.id}-${record.client?.name}`;
            },
        },
        {
            title: t('BondQuote_BankAccount'),
            dataIndex: 'bankAccount',
            width: 150,
            render(value, record, index) {
                return `${record.account.bank_name}${
                    record.account.vendor_sub_account_id
                        ? '-' + record.account.vendor_sub_account_id
                        : ''
                }`;
            },
        },
        {
            title: t('BondQuote_ProductType'),
            dataIndex: 'asset_type',
            width: 200,
            render(value) {
                return AssetType[value.code];
            },
        },
        {
            title: t('BondQuote_QuoteStatus'),
            dataIndex: 'status',
            width: 200,
            render(value, record, index) {
                return value.desc;
            },
        },
        {
            title: '',
            fixed: 'right',
            width: 80,
            render(value, record, index) {
                return (
                    <Button
                        className={styles.btn}
                        onClick={(e) => {
                            e.stopPropagation();
                            setCurrentModalType('Copy');
                            setVisible(true);
                            setCustomUser({
                                ...record.client,
                            });
                            setCurrentSelectQuote(record.id);
                            form.setFieldsValue({
                                client_id: record.client?.id,
                                account_id: record.account_id,
                                asset_type_code: record.asset_type.code,
                            });
                        }}
                    >
                        <Tooltip title="复制">
                            <CopyOutlined />
                        </Tooltip>
                    </Button>
                );
            },
        },
    ];
    const [tableHeight, setTableHeight] = React.useState(0);
    useEffect(() => {
        //获取屏幕高度;
        const element = document.getElementById('BondQuote_Container');
        if (element) {
            setTableHeight(element.clientHeight - 180);
        }
    }, []);
    const onFinish = (values: any) => {
        if (customUser && curBank) {
            setAddLoading(true);
            if (currentModalType === 'Copy' && currentSelectQuote) {
                copyQouteRecord({
                    ...values,
                    client_id: customUser.id,
                    asset_quote_id: currentSelectQuote,
                })
                    .then((res) => {
                        setVisible(false);
                        refresh();
                        message.success('复制成功');
                    })
                    .finally(() => setAddLoading(false));
            } else {
                addQouteRecord({
                    ...values,
                    client_id: customUser.id,
                })
                    .then((res) => {
                        setVisible(false);
                        history.push(
                            `${history.location.pathname}/detail?id=${res.data.asset_quote.id}`
                        );
                    })
                    .finally(() => setAddLoading(false));
            }
        }
    };
    return (
        <div className={styles.container} id="BondQuote_Container">
            <Button
                type="primary"
                className={styles.add}
                onClick={() => {
                    uploadEventTrack(EventList.BondQouteAddClick, 2);
                    setCurrentModalType('Add');
                    form.resetFields();
                    setVisible(true);
                }}
            >
                {t('BondQuote_AddBtn_Title')}
            </Button>
            <Spin spinning={loading} tip={t('loading')}>
                <Table
                    columns={columns}
                    dataSource={quoteListData?.data.asset_quotes}
                    pagination={{
                        pageSize: pageSize,
                        total: quoteListData?.data.total_count,
                        showSizeChanger: true,
                        showTotal: (total) => `${t('TotalPage')} ${total} `,
                        current: currentPage,
                        onChange(page, pageSize) {
                            setPageSize(pageSize || 10);
                            setCurrentPage(page);
                            dispacth({ page });
                            run({
                                current_page: page,
                                page_size: pageSize,
                            });
                        },
                    }}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                history.push(`${history.location.pathname}/detail?id=${record.id}`);
                            },
                        };
                    }}
                    rowKey="id"
                    size="small"
                    scroll={{ x: true, y: tableHeight }}
                />
            </Spin>
            <Modal
                title={currentModalType === 'Add' ? '新增报价' : '复制报价'}
                onOk={() => form.submit()}
                maskClosable={false}
                visible={visible}
                confirmLoading={addLoading}
                onCancel={() => setVisible(false)}
                okText={t('Confirm')}
                cancelText={t('Cancel')}
                destroyOnClose
                width={1024}
            >
                <Form
                    onFinish={onFinish}
                    form={form}
                    wrapperCol={{ span: 18 }}
                    labelCol={{ span: 6 }}
                >
                    <SearchCustomUserFormItem
                        name="client_id"
                        userCallback={(user) => {
                            setCustomUser(user);
                            form.setFieldsValue({ account_id: undefined });
                        }}
                    />
                    <AccountItem
                        label="交易账户"
                        name={'account_id'}
                        selectCallback={(originAccount, index) => {
                            setCurBank(originAccount);
                        }}
                        required
                        clientId={customUser?.id ?? 0}
                    />
                    <Form.Item
                        label="交易产品类型"
                        name="asset_type_code"
                        rules={[{ required: true, message: '请选择交易产品类型' }]}
                    >
                        <Radio.Group disabled={currentModalType === 'Copy'}>
                            <Radio value={AssetType.ELN} key={AssetType.ELN}>
                                ELN
                            </Radio>
                            <Radio value={AssetType.CorporateBond} key={AssetType.CorporateBond}>
                                CorporateBond
                            </Radio>
                            <Radio value={AssetType.GovernmentBond} key={AssetType.GovernmentBond}>
                                GovernmentBond
                            </Radio>
                            <Radio value={AssetType.AQ} key={AssetType.AQ}>
                                AQ
                            </Radio>
                            <Radio value={AssetType.DQ} key={AssetType.DQ}>
                                DQ
                            </Radio>
                            <Radio value={AssetType.FCN} key={AssetType.FCN}>
                                FCN
                            </Radio>
                            <Radio value={AssetType.Option} key={AssetType.Option}>
                                Option
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default GKQuote;
