import { Form } from 'antd';
import { InputNumberProps } from 'formik-antd';
import React, { useEffect, useMemo, useState } from 'react';
import { GinkgoInputNumber } from './GinkgoFieldItems';
import { math } from '../../../../../../utils';
export enum RateInputType {
    Percent = 'Percent',
    Bps = 'Bps',
    NoAddonAfterPercent = 'NoAddonAfterPercent',
    NoAddonAfterBps = 'NoAddonAfterBps',
}
interface RateInputItemProps {
    label: string;
    name: any;
    type: RateInputType;
    readOnly?: boolean;
    required?: boolean;
    onInput?: (value: any) => void;
}
const RateInputItem = ({ label, name, type, readOnly, required, onInput }: RateInputItemProps) => {
    return (
        <Form.Item
            label={label}
            name={name}
            rules={[{ required: required, message: label + ' is required' }]}
        >
            {<PercentAndBpsInput type={type} name={name} readOnly={readOnly} onInput={onInput} />}
        </Form.Item>
    );
};
type PercentAndBpsInputType = InputNumberProps & {
    value?: number;
    type: RateInputType;
    readOnly?: boolean;
    onChange?: (value: any) => void;
};
const PercentAndBpsInput = (data: PercentAndBpsInputType) => {
    const [value, setValue] = useState(
        data.type === RateInputType.Bps || data.type === RateInputType.NoAddonAfterBps
            ? data.value
            : data.value
            ? parseFloat(math.format(math.divide(data.value, 100), { precision: 10 }))
            : NaN
    );
    useEffect(() => {
        setValue(() => {
            return data.type === RateInputType.Bps || data.type === RateInputType.NoAddonAfterBps
                ? data.value
                : data.value
                ? parseFloat(math.format(math.divide(data.value, 100), { precision: 10 }))
                : NaN;
        });
    }, [data.type, data.value]);
    const afterStr = useMemo(() => {
        if (
            data.type === RateInputType.NoAddonAfterPercent ||
            data.type === RateInputType.NoAddonAfterBps
        ) {
            return '';
        }
        const rate = data.type === RateInputType.Bps ? '%' : 'bps';
        if (value) {
            return (
                math.format(
                    data.type === RateInputType.Bps
                        ? math.divide(value, 100)
                        : math.multiply(value, 100),
                    { precision: 10 }
                ) + rate
            );
        } else {
            return rate;
        }
    }, [data.type, value]);
    return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            {data.readOnly ? (
                <div>{`${isNaN(value ?? 0) ? 0 : value}${
                    data.type === RateInputType.Percent ||
                    data.type === RateInputType.NoAddonAfterPercent
                        ? '%'
                        : 'bps'
                }${afterStr.length > 0 ? ' / ' + afterStr : ''}`}</div>
            ) : (
                <>
                    <GinkgoInputNumber
                        readOnly={data.readOnly}
                        style={{ width: 'calc(25% - 10px)' }}
                        {...data}
                        value={value}
                        onChange={(value) => {
                            if (typeof value === 'number') {
                                setValue(value);
                                data.onChange!(
                                    data.type === RateInputType.Bps ||
                                        data.type === RateInputType.NoAddonAfterBps
                                        ? value
                                        : math.format(math.multiply(value, 100), { precision: 10 })
                                );
                            } else {
                                setValue(NaN);
                            }
                        }}
                        addonAfter={
                            data.type === RateInputType.Percent ||
                            data.type === RateInputType.NoAddonAfterPercent
                                ? '%'
                                : 'bps'
                        }
                    />
                    <div style={{ marginLeft: '8px' }}>{afterStr}</div>
                </>
            )}
        </div>
    );
};

export default RateInputItem;
