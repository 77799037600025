import { CloseCircleFilled, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { Button, Input, message, Modal, Pagination } from 'antd';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { MobileStateContext } from '../../App';
import { getCustomerList } from '../../service';
import useFetch from '../../utils/Hooks/useFetch';
import AddUserModalMobile from './components/Modal/AddUserModalMobile';
import AddUserModalPC from './components/Modal/AddUserModalPC';
import './index.less';
import VerifyModal from '../../components/VerifyModal';
import { EventList, uploadEventTrack } from '../../service/requests/EventTrack';
import AddAssociateModal from './components/Modal/AddAssociateModal';
import { associateCustomerMobile, CustomerManageData } from '../../service/requests/CustomerManage';
import { showError } from '../RecommendationComponent/util';
import UserCard from './components/UserCard';
import CustomerDetail from './components/CustomerDetail';
import { CustomerDetailCardType } from './components/CustomerDetail/type';
import CustomerFilterBtns from '../../components/CustomerFilterBtns';
import { useDispatch, useSelector } from 'react-redux';
import { LKRootState } from '../../store/store';
import { refreshDetailCard, setPageStatus } from '../../store/actions/actions';
import TopTabs from './components/TopTabs';
const { confirm } = Modal;
const TopTabsInfo = [
    {
        title: '客户信息',
        key: CustomerDetailCardType.Info,
    },
    {
        title: '客户记录',
        key: CustomerDetailCardType.Record,
    },
    {
        title: '客户动态',
        key: CustomerDetailCardType.Activity,
    },
    {
        title: '资产信息',
        key: CustomerDetailCardType.Asset,
    },
    {
        title: '持仓信息',
        key: CustomerDetailCardType.Position,
    },
    {
        title: '交易记录',
        key: CustomerDetailCardType.TranscationRecord,
    },
];
export default function CustomerList() {
    const initState = useSelector((state: LKRootState) => state.customer.pageStatus);
    // const inApp = useSelector((state: any) => state.config.inApp);
    const mobileState = useContext(MobileStateContext);
    const [showDetail, setShowDetail] = useState(initState?.showDetail ?? false);
    const [curIndex, setCurIndex] = useState<number | undefined>(initState?.curIndex ?? undefined);
    const [visible, setVisible] = useState(false);
    const [associateVisible, setAssociateVisible] = useState(false);
    const [curPage, setCurPage] = useState(initState?.curPage ?? 1);
    const [filterField, setFilterField] = useState<string>(initState?.filterField ?? '');
    const [defalutValue, setDefalutValue] = useState('');
    const [currentTab, setCurrentTab] = useState(CustomerDetailCardType.Info);
    const [editInitValues, setEditInitValues] = useState<
        CustomerManageData['pageData'][number] | undefined
    >();
    const dispatch = useDispatch();
    useEffect(() => {
        if (visible === false) {
            setEditInitValues(undefined);
        }
    }, [visible]);
    //通过redux来记录当前页面的各种状态
    useEffect(() => {
        dispatch(setPageStatus({ showDetail, curIndex, curPage, filterField }));
    }, [showDetail, curIndex, curPage, filterField, dispatch]);

    const [fetchState, setParams, refreshData] = useFetch({
        interfaces: getCustomerList,
        initialParams: {
            currentPage: curPage,
            pageSize: 12,
            search_key: filterField,
        },
        initialData: {
            totalHits: 0,
            hitsPerPage: 0,
            totalPages: 0,
            currentPage: 0,
            pageData: [],
        },
    });

    const inputRef = useRef<Input>(null);
    const handlePaginationChange = useCallback(
        (page: number) => {
            setCurPage(page);
            setParams({
                pageSize: 12,
                currentPage: page,
                search_key: filterField,
            });
        },
        [setParams, filterField]
    );
    const [curretnSelectData, setCurretnSelectData] = useState<CustomerManageData['pageData'][0]>();

    const handleFilterChange = useCallback(
        (value: string) => {
            setFilterField(value);
            uploadEventTrack(EventList.CustomerListUseFilter, 2, {
                searchKey: value,
            });
            setParams({
                currentPage: 1,
                pageSize: 12,
                search_key: value,
            });
        },
        [setParams]
    );

    const [showVerify, setShowVerify] = useState(false);

    const handleClickContent = useCallback(() => {
        document.dispatchEvent(new CustomEvent('userOperation'));
    }, []);

    const handleClickAddCustomer = useCallback(() => {
        uploadEventTrack(EventList.CustomerListAddCustomer, 2);
        setVisible(true);
    }, []);
    const associateMobile = useCallback(
        (name, mobile) => {
            let title = `确认将${name}-${mobile}与${curretnSelectData?.name}-${curretnSelectData?.mobile}关联吗？`;
            if (mobile.length === 0) {
                associateCustomerMobile({
                    parent_mobile: curretnSelectData?.mobile ?? '',
                    child_mobile: undefined,
                }).then((res) => {
                    message.success('取消关联成功');
                    setAssociateVisible(false);
                    refreshData();
                }, showError);
            } else {
                confirm({
                    title,
                    icon: <ExclamationCircleOutlined />,
                    content: '二者将共享所有资产信息，请谨慎操作！',
                    onOk() {
                        if (curretnSelectData) {
                            associateCustomerMobile({
                                parent_mobile: curretnSelectData?.mobile,
                                child_mobile: mobile,
                            }).then((res) => {
                                message.success('关联成功');
                                setAssociateVisible(false);
                                refreshData();
                            }, showError);
                        }
                    },
                    onCancel() {},
                });
            }
        },
        [curretnSelectData, refreshData]
    );
    const buttonValueChangeSearchResult = useCallback(
        (e) => {
            if (inputRef.current) {
                inputRef.current.setValue(e);
            }
            handleFilterChange(e.toString());
        },
        [inputRef, handleFilterChange]
    );

    const handleRefresh = useCallback(() => {
        dispatch(refreshDetailCard(CustomerDetailCardType.Info));
    }, [dispatch]);
    return (
        <div style={{ height: '100%' }}>
            <div
                id="CustomerList"
                className="CustomerList"
                onClick={handleClickContent}
                style={{
                    height:
                        mobileState.IsMobileMachine && showDetail ? '100%' : 'calc(100% - 60px)',
                }}
            >
                <VerifyModal
                    visible={showVerify}
                    setVisible={setShowVerify}
                    containerId="CustomerList"
                    pageId="CustomerList"
                />
                {associateVisible && (
                    <AddAssociateModal
                        confirm={associateMobile}
                        containerId="CustomerList"
                        defalutValue={defalutValue}
                        parentMobile={curretnSelectData?.mobile ?? ''}
                        setVisible={setAssociateVisible}
                        visible={associateVisible}
                    />
                )}
                {mobileState.IsMobileMachine ? (
                    <AddUserModalMobile
                        initValues={editInitValues}
                        visible={visible}
                        setVisible={setVisible}
                        handleRefreshList={handleRefresh}
                    />
                ) : (
                    <AddUserModalPC
                        initValues={editInitValues}
                        visible={visible}
                        containerId="CustomerList"
                        closeModal={() => setVisible(false)}
                        handleRefreshList={handleRefresh}
                    />
                )}
                <div
                    className="CustomerList-List"
                    style={
                        mobileState.IsMobileMachine
                            ? { width: '100%', display: showDetail ? 'none' : 'block' }
                            : showDetail
                            ? {
                                  minWidth: '312px',
                                  maxWidth: '312px',
                                  width: '312px',
                              }
                            : {
                                  minWidth: '880px',
                              }
                    }
                >
                    <div className="Header-Filter">
                        <Button
                            type="primary"
                            onClick={handleClickAddCustomer}
                            icon={<PlusOutlined />}
                        >
                            {mobileState.IsMobileMachine ? '' : '新增客户'}
                        </Button>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                columnGap: '8px',
                                flex: 1,
                            }}
                        >
                            <Input.Search
                                placeholder="请输入昵称、客户ID等相关信息进行搜索"
                                ref={inputRef}
                                defaultValue={filterField}
                                enterButton
                                allowClear
                                onSearch={handleFilterChange}
                            />
                        </div>
                    </div>
                    <CustomerFilterBtns
                        click={(item) => {
                            uploadEventTrack(EventList.CustomerListCheckStatus, 2, {
                                value: item,
                            });
                            buttonValueChangeSearchResult(item);
                        }}
                    />
                    <div
                        style={{
                            flex: '1 1 auto',
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'auto',
                            paddingRight: '12px',
                        }}
                    >
                        {fetchState.data?.pageData.map((item, index) => (
                            <UserCard
                                data={item}
                                key={item.id}
                                showDetail={showDetail}
                                isSelect={index === curIndex}
                                onSelect={() => {
                                    setShowDetail(true);
                                    setCurIndex(index);
                                    uploadEventTrack(EventList.CustomerListClickCard, 2, {
                                        customerId: item.id,
                                    });
                                }}
                                searchChild={(id) => buttonValueChangeSearchResult(id.toString())}
                            />
                        ))}
                    </div>
                </div>
                <div
                    className="CustomerList-Detail"
                    style={{
                        flex: '1',
                        minWidth: mobileState.IsMobileMachine ? '100%' : '1016px',
                        flexDirection: 'column',
                        display: `${showDetail ? 'flex' : 'none'}`,
                    }}
                >
                    <div className="CustomerList-Detail-Action">
                        {!mobileState.IsMobileMachine && (
                            <div className="CustomerList-Detail-Title">客户详情</div>
                        )}
                        {mobileState.IsMobileMachine && (
                            <TopTabs
                                labelList={TopTabsInfo}
                                onChange={(key) => setCurrentTab(key as CustomerDetailCardType)}
                            />
                        )}
                        <Button onClick={() => setShowDetail(false)} className="Action_Close">
                            <CloseCircleFilled />
                        </Button>
                    </div>
                    {curIndex !== undefined && fetchState.data?.pageData[curIndex] && (
                        <CustomerDetail
                            currentActiveKey={currentTab}
                            positionEdit={() => {
                                let url = `${window.location.origin}/#/app/customer/positionEditor?customer_id=${fetchState.data?.pageData[curIndex].id}`;
                                uploadEventTrack(EventList.CustomerListShowEdit, 2);
                                window.open(url);
                            }}
                            searchChild={(id) => buttonValueChangeSearchResult(id.toString())}
                            infoEdit={(customerInfo: CustomerManageData['pageData'][number]) => {
                                // uploadEventTrack(EventList.CustomerListShowDetail, 2);
                                setEditInitValues(customerInfo);
                                setVisible(true);
                            }}
                            contactChild={() => {
                                setDefalutValue(
                                    fetchState.data?.pageData[curIndex].relativeMobile ?? ''
                                );
                                setAssociateVisible(true);
                                setCurretnSelectData(fetchState.data?.pageData[curIndex]);
                            }}
                            banksInfo={fetchState.data?.pageData[curIndex].bankAccounts}
                            customerId={fetchState.data?.pageData[curIndex].id}
                        />
                    )}
                </div>
            </div>
            {fetchState.data?.totalPages &&
            fetchState.data.totalPages > 1 &&
            !(mobileState.IsMobileMachine && showDetail) ? (
                <div
                    style={{
                        height: '60px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    {!mobileState.IsMobileMachine && (
                        <div>{`当前${(curPage - 1) * 12 + 1} - ${
                            curPage * 12 > fetchState.data.totalHits
                                ? fetchState.data.totalHits
                                : curPage * 12
                        } / 总计${fetchState.data?.totalHits}`}</div>
                    )}
                    <Pagination
                        current={curPage}
                        onChange={handlePaginationChange}
                        total={fetchState.data?.totalHits}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginLeft: '10px',
                        }}
                        pageSize={12}
                        showSizeChanger={false}
                    />
                </div>
            ) : null}
        </div>
    );
}
