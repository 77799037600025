import { HostType } from '../config';
import { multiHostAxios } from '../tools';

export enum EventList {
    ClickMenuTab = 40001,
    CustomerListAddCustomer = 40101,
    CustomerListUseFilter = 40102,
    CustomerListShowDetail = 40103,
    CustomerDetailClickEdit = 40104,
    CustomerManageUseFilter = 40201,
    CustomerManageEditRM = 40202,
    PositionEditorAddUser = 40301,
    PositionEditorAddAcc = 40302,
    PositionEditorAdjustAsset = 40303,
    PositionEditorAccAction = 40304,
    SingleQuoteSelectCustomer = 40401,
    SingleQuoteSwitchType = 40402,
    SingleQuoteGenPreview = 40403,
    SingleQuoteGenQuoteUrl = 40404,
    TransactionHistorySwitchType = 40501,
    TransactionHistoryAction = 40502,
    TransactionFormMakeDeal = 40601,
    CustomerListShowEdit = 40603,
    CustomerListViewData = 40602,

    //我的客户
    CustomerListSearch = 40105,
    CustomerListCheckStatus = 40106,
    CustomerListFoldStatus = 40107,
    CustomerListInfoBottomOptionsClick = 40108,
    CustomerListTranscationFilter = 40109,
    CustomerListPostionFilter = 40110,
    CustomerListClickCard = 40111,
    CustomerListRecordAll = 40112,
    CustomerListRecordDetail = 40113,
    CustomerListRecordTagClick = 40114,
    CustomerListRecordADD = 40115,

    //客户管理

    //安全验证
    VerifyConfirm = 40701,
    VerifyChange = 40702,

    //银行费用录入
    BankFeeAddBank = 40801,

    //产品推荐-筛选产品类型
    ProductRecommendFilter = 40901,
    ProductRecommendAdd = 40902,

    //App操作记录
    AppRecordSearch = 41001,
    AppRecordProductFilter = 41002,

    //编辑产品
    EditProductSearch = 41101,
    EditProductAdd = 41102,

    //创建push
    AddPushFilter = 41201,

    //同步文章
    SyncArticle = 41501,

    //下单
    OrderAction = 41601,
    OrderEmailCopy = 41602,
    OrderEmailAction = 41603,

    //报价
    QuotationBondsTabClick = 41701,
    QuotationBondsButtonClick = 41702,
    QuotationBondsDataAction = 41703,
    QuotationProductPriceDataAction = 41704,

    //生成小程序链接 - 创建新链接
    MakeMiniShareLinkAdd = 41301,
    MakeMiniShareLinkCopy = 41302,

    //菜单点击webim
    MenuWebIMClick = 41401,

    //群聊配置菜单项点击
    IMManageIMClick = 41801,

    BondQouteAddClick = 41901,
    BondQouteDetailClick = 41902,
}

export function uploadEventTrack(event: EventList, event_type: 1 | 2, params?: object) {
    return multiHostAxios(HostType.PORTAL).post('/v2/misc/event_report', {
        data: [
            {
                sid: '',
                from: '',
                ts: Date.now(),
                params,
                event_id: event,
                event_type,
            },
        ],
    });
}
