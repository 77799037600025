import React from 'react';
import styles from './DetailTables.module.scss';
import { DetailField } from '../../service/requests/OrderRequest';
import { v4 as uuid } from 'uuid';

interface DetailTablesProps {
    details: Array<{
        header: string;
        fields: DetailField[];
    }>;
    title: string;
}

const DetailTables = ({ details, title }: DetailTablesProps) => {
    return (
        <div>
            <div>{title}</div>
            {details.map((d) => (
                <div key={uuid()} className={styles.table}>
                    <div className={styles.tableTitle}>{d.header}</div>
                    <div className={styles.detail}>
                        {d.fields.map((f) => (
                            <div key={uuid()} className={styles.row}>
                                <div className={styles.left}>{f.title}</div>
                                <div className={styles.right}>
                                    {f.display_value ? f.display_value : f.value}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DetailTables;
