/*
 * File: hooks.ts
 * Desc: 自定义hooks
 * File Created: 2020-08-24 22:45:40
 * Author: yezi
 * ------
 * Copyright 2020 - present, yezi
 */
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHasToken, setInapp, setLogin } from '../store/actions/actions';
interface ITurn {
    turnOn: () => void;
    turnOff: () => void;
    setSwitcher: React.Dispatch<React.SetStateAction<boolean>>;
}
/**
 * 布尔开关
 * @param init
 */
export function useSwitch(init: boolean = false): [boolean, ITurn] {
    const [switcher, setSwitcher] = useState(init);
    const turnOn = () => setSwitcher(true);
    const turnOff = () => setSwitcher(false);
    return [switcher, { turnOn, turnOff, setSwitcher }];
}

export const useGetToken = ({ redirectUrl }: { redirectUrl: string }) => {
    const dispatch = useDispatch();
    let login = useSelector((state: any) => state.login);
    useEffect(() => {
        let in_app = new URLSearchParams(window.location.search).get('in_app');
        dispatch(setInapp(in_app === 'true' ? true : false));
        if (in_app) {
            let timer = setTimeout(() => {
                let login = localStorage.getItem('login');
                if (login) {
                    dispatch(setLogin(JSON.parse(login)));
                    dispatch(setHasToken(true));
                    clearTimeout(timer);
                }
            }, 500);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [dispatch]);
    /** redux有没有login状态  只执行一次*/
    useMemo(() => {
        let localLogin;
        if (!login) {
            localLogin = localStorage.getItem('login');
            try {
                localLogin = !!localLogin && JSON.parse(localLogin);
            } catch (err) {
                console.error(err);
            }

            if (localLogin) {
                dispatch(setLogin(localLogin));
                dispatch(setHasToken(true));
            } else {
                if (new URLSearchParams(window.location.search).get('in_app')) {
                    return;
                }
                /** 本地有没有login状态 */
                window.location.hash = redirectUrl;
            }
        }
    }, [dispatch, login, redirectUrl]);
};
