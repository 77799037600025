import {
    Form,
    Input,
    DatePicker,
    InputNumber,
    Radio,
    InputProps,
    InputNumberProps,
    Select,
} from 'formik-antd';
import React from 'react';
import 'antd/lib/date-picker/style/css.js';

interface FormItemProps extends Record<string, any> {
    name: string;
    label: string;
    validate?: (value: any) => void;
    children?: React.ReactNode;
}

export const FormInput = (props: FormItemProps & InputProps) => {
    const { name, label, validate, children, ...properties } = props;
    return (
        <Form.Item name={props.name} label={props.label} validate={props.validate}>
            <Input name={props.name} style={{ width: 354 }} {...properties} />
        </Form.Item>
    );
};

export const FormSelect = (props: FormItemProps & React.ComponentProps<typeof Select>) => {
    const { name, label, children, ...properties } = props;

    return (
        <Form.Item name={name} label={label}>
            <Select name={name} style={{ width: 354 }} {...properties}>
                {children}
            </Select>
        </Form.Item>
    );
};

export const FormDatePicker = (props: FormItemProps) => {
    const { name, label, validate, children, ...properties } = props;
    return (
        <Form.Item name={props.name} label={props.label} validate={props.validate}>
            <DatePicker name={props.name} {...properties} />
        </Form.Item>
    );
};

export const formatNum = (num?: number) =>
    num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') ?? '';

export const FormInputNumber = (props: FormItemProps & InputNumberProps) => {
    const { name, label, validate, children, ...properties } = props;
    return (
        <Form.Item name={props.name} label={props.label} validate={props.validate}>
            <InputNumber name={props.name} style={{ width: 354 }} {...properties} />
            {children}
        </Form.Item>
    );
};

interface RadioGroupProps extends FormItemProps {
    values: Array<[string, string]>;
}

export const FormRadio = (props: RadioGroupProps) => {
    const { name, label, validate, children, ...properties } = props;

    return (
        <Form.Item name={props.name} label={props.label} validate={props.validate}>
            <Radio.Group name={props.name} {...properties}>
                {props.values.map((item) => (
                    <Radio name={props.name} value={item[0]} key={item[0]}>
                        {item[1]}
                    </Radio>
                ))}
            </Radio.Group>
        </Form.Item>
    );
};
