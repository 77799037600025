import { CaretDownOutlined } from '@ant-design/icons';
import { ColDef, ColumnApi, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { message, Popover, Tabs } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React, { useContext, useMemo, useState } from 'react';
import { MobileStateContext } from '../../App';
import { getAuthorityMemberList, updateRoleForMember } from '../../service';
import useForceUpdate from '../../utils/Hooks/useForceUpdate';
import AuthorityMemberControl, {
    AuthorityMember,
    AuthorityMemberData,
} from './AuthorityMemberControl';
import './AuthorityMemberCenter.less';

export default function AuthorityMemberCenter() {
    const { isMobile } = useContext(MobileStateContext);
    const [tabKey, setTabKey] = useState(0);
    const [memberControl, setMemberControl] = useState<AuthorityMemberControl>();

    const forceUpdate = useForceUpdate(() => {
        getAuthorityMemberList()
            .then((res) => {
                let data = res.data as AuthorityMemberData;
                if (data) {
                    setMemberControl(new AuthorityMemberControl(data));
                } else {
                    message.error('获取成员列表失败');
                }
            })
            .catch(() => {
                message.error('获取成员列表失败');
            });
    });

    const [roleNameToId, roleIdToName] = useMemo(() => {
        return memberControl?.getRolesData() ?? [];
    }, [memberControl]);

    const roles = useMemo(() => {
        if (roleNameToId) {
            return Object.keys(roleNameToId);
        }
    }, [roleNameToId]);

    const [columnApi, setColumnApi] = useState<ColumnApi>();

    const colDefs: ColDef[] = [
        { field: 'name', headerName: '姓名', width: 200 },
        { field: 'mobile', headerName: '手机号', width: 300 },
        {
            field: 'roleIds',
            headerName: '角色',
            minWidth: 300,
            flex: 1,
            wrapText: true,
            autoHeight: true,
            cellClass: 'cell-role-id',
            cellRenderer: (params: ICellRendererParams) => {
                let data = params.data as AuthorityMember;
                let roleNames: string[] = [];
                if (data.roleIds && roleIdToName) {
                    for (let id of data.roleIds as number[]) {
                        roleNames.push(roleIdToName[id]);
                    }
                }
                return (
                    <Popover
                        placement="bottom"
                        trigger="click"
                        content={
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {roles?.slice(1).map((name) => {
                                    let roleId = roleNameToId![name];
                                    if (roleId) {
                                        return (
                                            <Checkbox
                                                key={name}
                                                style={{ marginBottom: '5px', marginLeft: 0 }}
                                                checked={data.roleIds?.includes(roleId)}
                                                onChange={() => {
                                                    updateRoleForMember({
                                                        memberId: data.id,
                                                        roleId: roleId!,
                                                    })
                                                        .then(() => {
                                                            forceUpdate();
                                                            message.destroy();
                                                            message.success('角色设置成功');
                                                        })
                                                        .catch(() => {
                                                            message.error('角色设置失败');
                                                        });
                                                }}
                                            >
                                                {name}
                                            </Checkbox>
                                        );
                                    }
                                })}
                            </div>
                        }
                    >
                        {roleNames.length ? roleNames.join('，') : '无角色'}
                        <div style={{ display: 'inline-block', width: '5px' }} />
                        <CaretDownOutlined />
                    </Popover>
                );
            },
        },
    ];

    const tableData = useMemo(() => {
        if (roles) {
            return memberControl?.getMembersForRoleName(roles[tabKey]);
        }
    }, [memberControl, roles, tabKey]);

    return (
        <div style={{ height: '100%' }}>
            <Tabs
                style={isMobile ? {} : { display: 'inline-flex', marginRight: '20px' }}
                defaultActiveKey={tabKey.toString()}
                onChange={(key) => {
                    setTabKey(parseInt(key));
                }}
            >
                {roles?.map((name, index) => (
                    <Tabs.TabPane tab={name} key={index} />
                ))}
            </Tabs>
            {/* <AuthorityAddRoleControl onSuccess={() => forceUpdate()} roles={roleNameToId ?? {}} /> */}
            <div
                className="ag-theme-alpine"
                style={{
                    padding: isMobile ? '20px 0' : 0,
                    width: '100%',
                }}
            >
                <AgGridReact
                    reactUi
                    animateRows
                    columnDefs={colDefs}
                    defaultColDef={{ resizable: true, sortable: true }}
                    rowData={tableData}
                    domLayout="autoHeight"
                    pagination
                    paginationPageSize={12}
                    onGridReady={({ columnApi }) => setColumnApi(columnApi)}
                    onFirstDataRendered={() => isMobile && columnApi?.autoSizeAllColumns()}
                />
            </div>
        </div>
    );
}
