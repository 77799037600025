import { SET_HAS_TOKEN } from '../../constants/constants';

function token(
    state = {
        hasToken: false,
    },
    action: any
) {
    const { type, hasToken } = action;

    if (type === SET_HAS_TOKEN) {
        return {
            ...state,
            hasToken,
        };
    } else {
        return state;
    }
}

export default token;
