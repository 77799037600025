import React, { RefObject, useEffect, useCallback } from 'react';
import { Button, DatePicker, Form, Input, Popup, TextArea } from 'antd-mobile';
import { updateCustomerInfo } from '../../../../service';

import { CloseOutlined } from '@ant-design/icons';
import './AddUserModalMobile.less';
import { DatePickerRef } from 'antd-mobile/es/components/date-picker';
import moment from 'moment';
import MobileSelector from '../../../../components/MobileSelector';
import { createCustomer } from '../../../../service';
import { showError } from '../../../RecommendationComponent/util';
import { message } from 'antd';
import { CustomerManageData } from '../../../../service/requests/CustomerManage';

interface AddUserModalMobileProps {
    initValues?: CustomerManageData['pageData'][number];
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    handleRefreshList: () => void;
}

export default function AddUserModalMobile(props: AddUserModalMobileProps) {
    const { visible, setVisible, handleRefreshList } = props;
    const handleClose = useCallback(() => {
        setVisible(false);
    }, [setVisible]);
    useEffect(() => {
        console.log(props.initValues);
    }, [props.initValues]);

    const onFormFinish = useCallback(
        (values) => {
            if (props.initValues === undefined) {
                createCustomer({
                    ...values,
                    state: values.state,
                    source: values.source ?? '朋友介绍',
                    birthday: values.birthday
                        ? moment(values.birthday).format('YYYY-MM-DD')
                        : undefined,
                    passportExpiryDate: values.passportExpiryDate
                        ? moment(values.passportExpiryDate).format('YYYY-MM-DD')
                        : undefined,
                }).then(() => {
                    message.success('添加成功');
                    handleClose();
                    handleRefreshList();
                }, showError);
            } else {
                updateCustomerInfo({
                    id: props.initValues.id,
                    ...values,
                    birthday: values.birthday?.format('YYYY-MM-DD'),
                    passportExpiryDate: values.passportExpiryDate?.format('YYYY-MM-DD'),
                }).then(() => {
                    message.success('更新成功');
                    handleClose();
                    handleRefreshList();
                }, showError);
            }
        },
        [handleClose, handleRefreshList, props]
    );

    return (
        <Popup visible={visible} onMaskClick={handleClose} bodyClassName="AddUser-body">
            <div className="AddUser-header">
                <span className="AddUser-title">
                    {props.initValues !== undefined ? '修改客户信息' : '添加客户'}
                </span>
                <CloseOutlined onClick={handleClose} />
            </div>
            <Form
                layout="horizontal"
                className="AddUser-content"
                initialValues={{
                    ...props.initValues,
                    gender: '女',
                    source: '朋友介绍',
                    birthday:
                        props.initValues?.birthday &&
                        moment(props.initValues.birthday, 'YYYY-MM-DD'),
                    passportExpiryDate:
                        props.initValues?.passportExpiryDate &&
                        moment(props.initValues.passportExpiryDate, 'YYYY-MM-DD'),
                }}
                onFinish={onFormFinish}
            >
                <div className="AddUser-section">
                    <div className="AddUser-section-title">
                        <span>基本信息</span>
                    </div>
                    <div className="AddUser-section-fields">
                        <Form.Item
                            label="昵称"
                            name="name"
                            rules={[{ required: true, message: '不能为空' }]}
                        >
                            <Input placeholder="请输入昵称" />
                        </Form.Item>
                        <Form.Item
                            label="生日"
                            name="birthday"
                            trigger="onConfirm"
                            onClick={(_, pickerRef: RefObject<DatePickerRef>) => {
                                pickerRef.current?.open();
                            }}
                        >
                            <DatePicker min={new Date(0)} max={new Date()}>
                                {(value) =>
                                    value ? (
                                        <span>{moment(value).format('YYYY-MM-DD')}</span>
                                    ) : (
                                        <span className="AddUser-form-item-placeholder">
                                            请选择生日
                                        </span>
                                    )
                                }
                            </DatePicker>
                        </Form.Item>
                        <Form.Item label="性别" name="gender">
                            <MobileSelector
                                options={[
                                    { title: '男', value: '男' },
                                    { title: '女', value: '女' },
                                ]}
                                placeholder="请选择性别"
                            />
                        </Form.Item>
                        <Form.Item
                            label="手机"
                            name="mobile"
                            rules={[{ required: true, message: '不能为空' }]}
                        >
                            <Input placeholder="请输入手机号" />
                        </Form.Item>
                        <Form.Item
                            label="邮箱"
                            name="email"
                            rules={[{ required: true, message: '不能为空' }]}
                        >
                            <Input placeholder="请输入电子邮箱" />
                        </Form.Item>
                        <Form.Item
                            label="状态"
                            name="state"
                            rules={[{ required: true, message: '不能为空' }]}
                        >
                            <MobileSelector
                                options={[
                                    { title: '目标用户', value: '目标用户' },
                                    { title: '开户中', value: '开户中' },
                                    { title: '入金完成', value: '入金完成' },
                                    { title: '其他(非客户)', value: '其他(非客户)' },
                                    { title: '已注销', value: '已注销' },
                                ]}
                                placeholder="请选择状态"
                            />
                        </Form.Item>
                        <Form.Item label="来源" name="source">
                            <MobileSelector
                                options={[
                                    { title: '朋友介绍', value: '朋友介绍' },
                                    { title: '市场活动', value: '市场活动' },
                                    { title: '自主挖掘', value: '自主挖掘' },
                                    { title: '其他', value: '其他' },
                                ]}
                                defaultValue="朋友介绍"
                                placeholder="请选择来源"
                            />
                        </Form.Item>
                        <Form.Item label="居住地址" name="address">
                            <Input placeholder="请输入居住地址" />
                        </Form.Item>
                        <Form.Item label="新用户roadmap URL" name="roadmapUrl">
                            <Input placeholder="请输入新用户roadmap URL" />
                        </Form.Item>
                    </div>
                </div>
                <div className="AddUser-section">
                    <div className="AddUser-section-title">
                        <span>业务信息</span>
                    </div>
                    <div className="AddUser-section-fields">
                        <Form.Item label="姓名" name="formalName">
                            <Input placeholder="请输入姓名" />
                        </Form.Item>
                        <Form.Item label="国籍" name="nationality">
                            <Input placeholder="请输入国籍" />
                        </Form.Item>
                        <Form.Item label="护照号码" name="passportNumber">
                            <Input placeholder="请输入护照号码" />
                        </Form.Item>
                        <Form.Item
                            label="护照到期日"
                            name="passportExpiryDate"
                            trigger="onConfirm"
                            onClick={(_, pickerRef: RefObject<DatePickerRef>) => {
                                pickerRef.current?.open();
                            }}
                        >
                            <DatePicker min={new Date()} max={moment().add(20, 'years').toDate()}>
                                {(value) =>
                                    value ? (
                                        <span>{moment(value).format('YYYY-MM-DD')}</span>
                                    ) : (
                                        <span className="AddUser-form-item-placeholder">
                                            请选择护照到期日
                                        </span>
                                    )
                                }
                            </DatePicker>
                        </Form.Item>
                        <Form.Item label="护照颁发国" name="passportIssuingCountry">
                            <Input placeholder="请输入护照颁发国" />
                        </Form.Item>
                        <Form.Item label="邮寄地址" name="mailingAddress">
                            <Input placeholder="请输入邮寄地址" />
                        </Form.Item>
                        <Form.Item label="婚姻状况" name="martialStatus">
                            <Input placeholder="请输入婚姻状况" />
                        </Form.Item>
                        <Form.Item label="家庭成员" name="familyMembers">
                            <Input placeholder="请输入家庭人员数量" />
                        </Form.Item>
                        <Form.Item label="使用的其他机构" name="otherProviders">
                            <Input placeholder="请输入" />
                        </Form.Item>
                        <Form.Item label="其他ID" name="otherIdents">
                            <Input placeholder="请输入" />
                        </Form.Item>
                        <Form.Item label="最高学历" name="highestEducation">
                            <Input placeholder="请输入" />
                        </Form.Item>
                        <Form.Item label="工作经历" name="workHistory">
                            <TextArea placeholder="请输入" />
                        </Form.Item>
                    </div>
                </div>
                <div className="AddUser-submit">
                    <Button block type="submit" color="primary" className="AddUser-submit-button">
                        提交
                    </Button>
                </div>
            </Form>
        </Popup>
    );
}
