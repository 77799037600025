/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Table } from 'antd';
import React, { useCallback, useState } from 'react';
import { useMemo } from 'react';
import {
    RouteComponentProps,
    useHistory,
    useLocation,
    useParams,
    withRouter,
} from 'react-router-dom';
import { queryAsset, querySummaryAsset } from '../../../../service';
import { mapCurrencySymbols } from '../../../RecommendationComponent/util';
import ImportAssetModal from './EditAssetModals/ImportAssetModal';
import UpdateAssetAssetModal from './EditAssetModals/UpdateAssetModal';
import styles from './EditAssets.module.scss';
import useFetch from '../../../../utils/Hooks/useFetch';
import BuyAssetModal from './EditAssetModals/BuyAssetModal';
import DeleteSellAssetModal from './EditAssetModals/DeleteSellAssetModal';
import WithdrawRepayModal from './EditAssetModals/WithdrawRepayModal';
import { ColumnsType } from 'antd/lib/table';
import { QueryAsset } from '../../../../service/type';
import EditAccountModal from './EditAssetModals/EditAccountModal';

export interface AssetForSell {
    isin: string;
    quantity: number;
    name: string;
    costPrice: number;
    sub_asset_class: string;
    c_cost_price: number;
}

export interface AssetModelProps {
    visible: boolean;
    setVisible: (newValue: boolean) => void;
    uid: string;
    accountId: string;
    handleRefreshList: () => void;
}

const basePosition = {
    hold_value_currency: 'USD',
    hold_value: 0,
    percentage: 0,
    sub_positions: [],
};

interface EditAssetParams {
    type: string;
    uid: string;
    account_id: string;
}

export default function EditAssets() {
    const { type, uid, account_id } = useParams<EditAssetParams>();
    const history = useHistory();
    const { state } = useLocation();

    const [editType, setEditType] = useState('add');
    const [importAssetVisible, setImportAssetVisible] = useState(false);
    const [updateAssetsVisible, setUpdateAssetsVisible] = useState(false);
    const [updateAssetsType, setUpdateAssetsType] = useState('balance');
    const [showBuyAsset, setShowBuyAsset] = useState(false);
    const [showSellAsset, setShowSellAsset] = useState(false);
    const [showWithdrawRepay, setShowWithdrawRepay] = useState(false);
    const [showEditAccount, setShowEditAccount] = useState(false);

    //balance:入金
    //withdraw:出金
    //debit:贷款
    //repay:偿还
    const [fetchAssetListState, , setAssetListFreshData] = useFetch({
        interfaces: queryAsset,
        initialParams: { uid, account_id },
        initialData: {
            sec_cates: [],
        },
    });
    const assetList = fetchAssetListState.data?.sec_cates || [];
    const [fetchSummaryAssetState, , setSummaryAssetFreshData] = useFetch({
        interfaces: querySummaryAsset,
        initialParams: { uid, account_id },
        initialData: {} as any,
    });
    const assetSummary = fetchSummaryAssetState.data;
    const handleRefreshList = useCallback(() => {
        setSummaryAssetFreshData();
        setAssetListFreshData();
    }, [setAssetListFreshData, setSummaryAssetFreshData]);

    const cashPositions = useMemo(() => {
        return assetList.find((item) => item.sec_cate_id === 'cash')?.positions;
    }, [assetList]);
    const [cashColumns, cashDataSource] = useMemo(() => {
        if (cashPositions) {
            const balance = cashPositions.find((item) => item.isin === 'balance') || basePosition;
            const debit = cashPositions.find((item) => item.isin === 'debit') || basePosition;
            const deposit = cashPositions.find((item) => item.isin === 'deposit') || basePosition;

            const baseItemList: Array<{
                isin: string;
                symbol: string;
            }> = [];
            //取并集
            balance.sub_positions.forEach((element) => {
                baseItemList.push({
                    isin: element.isin,
                    symbol: element.symbol,
                });
            });
            debit.sub_positions.forEach((element) => {
                if (baseItemList.findIndex((target) => target.isin === element.isin) === -1) {
                    baseItemList.push({
                        isin: element.isin,
                        symbol: element.symbol,
                    });
                }
            });
            deposit.sub_positions.forEach((element) => {
                if (baseItemList.findIndex((target) => target.isin === element.isin) === -1) {
                    baseItemList.push({
                        isin: element.isin,
                        symbol: element.symbol,
                    });
                }
            });
            if (baseItemList.length === 0) {
                return [[], []];
            }
            const cashColumns = baseItemList.map((item) => ({
                title: (
                    <>
                        币种
                        <div style={{ display: 'inline-block', width: 20 }} />
                        金额
                    </>
                ),
                dataIndex: item.isin,
                width: 20,
            }));
            const cashDataSource: Array<Record<string, any>> = [
                {
                    index: 'balance',
                },
                {
                    index: 'debit',
                },
                {
                    index: 'deposit',
                },
            ];
            balance.sub_positions.forEach((target) => {
                cashDataSource[0][target.isin] = (
                    <>
                        {target.symbol}
                        <div style={{ display: 'inline-block', width: 20 }} />
                        {target.c_book_value}
                    </>
                );
            });
            debit.sub_positions.forEach((target) => {
                cashDataSource[1][target.isin] = (
                    <>
                        {target.symbol}
                        <div style={{ display: 'inline-block', width: 20 }} />
                        {target.c_book_value}
                    </>
                );
            });
            deposit.sub_positions.forEach((target) => {
                cashDataSource[2][target.isin] = (
                    <>
                        {target.symbol}
                        <div style={{ display: 'inline-block', width: 20 }} />
                        {target.c_book_value}
                    </>
                );
            });
            return [cashColumns, cashDataSource];
        } else {
            return [[], []];
        }
    }, [cashPositions]);

    const assetsForSell = useMemo(() => {
        let res: AssetForSell[] = [];
        for (let asset of assetList) {
            if (asset.sec_cate_id !== 'cash') {
                for (let position of asset.positions) {
                    // if (position.sub_asset_class !== 'struct') {
                    let name = position.name;
                    res.push({
                        isin: position.isin,
                        name,
                        quantity: position.c_avail_balance,
                        costPrice: position.cost_price,
                        sub_asset_class: position.sub_asset_class,
                        c_cost_price: position.c_cost_price,
                    });
                    // }
                }
            }
        }
        return res;
    }, [assetList]);

    return (
        <>
            <EditAccountModal
                visible={showEditAccount}
                setVisible={setShowEditAccount}
                account_id={account_id}
            />
            <ImportAssetModal
                uid={uid}
                account_id={account_id}
                visible={importAssetVisible}
                setVisible={setImportAssetVisible}
                handleRefreshList={handleRefreshList}
            />
            <UpdateAssetAssetModal
                uid={uid}
                account_id={account_id}
                visible={updateAssetsVisible}
                setVisible={setUpdateAssetsVisible}
                handleRefreshList={handleRefreshList}
                updateAssetsType={updateAssetsType}
            />
            <WithdrawRepayModal
                uid={uid}
                accountId={account_id}
                visible={showWithdrawRepay}
                setVisible={setShowWithdrawRepay}
                handleRefreshList={handleRefreshList}
                cashPositions={cashPositions}
                type={updateAssetsType}
            />
            <BuyAssetModal
                visible={showBuyAsset}
                setVisible={setShowBuyAsset}
                handleRefreshList={handleRefreshList}
                uid={uid}
                account_id={account_id}
            />
            <DeleteSellAssetModal
                visible={showSellAsset}
                setVisible={setShowSellAsset}
                uid={uid}
                accountId={account_id}
                handleRefreshList={handleRefreshList}
                assetChoices={assetsForSell}
                type={editType}
            />
            <Button
                type="primary"
                style={{ margin: '8px 16px 0 0' }}
                onClick={() => {
                    history.push({ pathname: '/app/customer/positionEditor', state: state });
                }}
            >
                返回
            </Button>
            <Button
                type="primary"
                style={{ margin: '8px 0' }}
                onClick={() => setShowEditAccount(true)}
            >
                编辑账户
            </Button>
            <div className={styles['assets-edit-assets-modal']}>
                <div className={styles['total-assets']}>
                    <span>
                        净资产：{mapCurrencySymbols(assetSummary?.asset?.currency)}{' '}
                        {assetSummary?.asset?.c_net_asset}
                    </span>
                    <span>
                        总资产：{mapCurrencySymbols(assetSummary?.asset?.currency)}{' '}
                        {assetSummary?.asset?.c_total_asset}
                    </span>
                    <span>
                        总负债：{mapCurrencySymbols(assetSummary?.asset?.currency)}{' '}
                        {assetSummary?.asset?.c_debit_amount}
                    </span>
                    <span>
                        净抵押值：{mapCurrencySymbols(assetSummary?.asset?.currency)}{' '}
                        {assetSummary?.asset?.c_net_margin_avail}
                    </span>
                </div>
                {assetList.map((asset) => {
                    if (asset.sec_cate_id === 'cash') {
                        return (
                            <div className={styles['cash-asset-wrapper']} key={asset.sec_cate_id}>
                                <div className={styles['asset-title']}>
                                    <div className={styles.name}>{asset.name}</div>
                                    <div className={styles.value}>
                                        {mapCurrencySymbols(asset.hold_value_currency)}
                                        {asset.c_hold_value}
                                    </div>
                                    {type === 'edit' && (
                                        <div className={styles.operation}>
                                            <Button
                                                className={styles.in}
                                                onClick={() => {
                                                    setUpdateAssetsVisible(true);
                                                    setUpdateAssetsType('balance');
                                                }}
                                            >
                                                入金
                                            </Button>
                                            <Button
                                                className={styles.out}
                                                onClick={() => {
                                                    setUpdateAssetsType('withdraw');
                                                    setShowWithdrawRepay(true);
                                                }}
                                            >
                                                出金
                                            </Button>
                                            <Button
                                                className={styles.loan}
                                                onClick={() => {
                                                    setUpdateAssetsVisible(true);
                                                    setUpdateAssetsType('debit');
                                                }}
                                            >
                                                贷款
                                            </Button>
                                            <Button
                                                className={styles.repay}
                                                onClick={() => {
                                                    setUpdateAssetsType('deposit');
                                                    setUpdateAssetsVisible(true);
                                                }}
                                            >
                                                存款
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                <Table
                                    columns={cashColumns}
                                    dataSource={cashDataSource}
                                    pagination={false}
                                    rowKey="index"
                                    scroll={{ x: true }}
                                />
                            </div>
                        );
                    }
                    return (
                        <div className={styles['asset-wrapper']} key={asset.sec_cate_id}>
                            <div className={styles['asset-title']}>
                                <div className={styles.name}>{asset.name}</div>
                                <div className={styles.value}>
                                    {mapCurrencySymbols(asset.hold_value_currency)}
                                    {asset.c_hold_value}
                                </div>
                                {type === 'edit' && (
                                    <div className={styles.operation}>
                                        <Button
                                            className={styles.in}
                                            onClick={() => {
                                                setImportAssetVisible(true);
                                                setEditType('add');
                                            }}
                                        >
                                            转入
                                        </Button>
                                        <Button
                                            className={styles.out}
                                            onClick={() => {
                                                setEditType('delete');
                                                setShowSellAsset(true);
                                            }}
                                        >
                                            转出
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <Table
                                columns={baseCloumn}
                                dataSource={asset.positions}
                                pagination={false}
                                rowKey="isin"
                                scroll={{ x: true }}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
}

const baseCloumn: ColumnsType<object> = [
    {
        title: '币种',
        dataIndex: 'book_value_currency',
        width: 80,
    },
    {
        title: '产品',
        dataIndex: 'name',
        width: 300,
        render: (value, record) => {
            let position = record as QueryAsset['sec_cates'][number]['positions'][number];
            if (position.sub_asset_class === 'struct') {
                return `${position.ext_info.type} ${value}`;
            } else {
                return value;
            }
        },
    },
    {
        title: '数量',
        width: 300,
        render: ({ c_aggr_balance }: { c_aggr_balance: string }) => {
            return c_aggr_balance.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
    },
    {
        title: '现价｜成本',
        width: 300,
        render: ({ c_cur_price, c_cost_price }: { c_cur_price: string; c_cost_price: string }) => {
            return `${c_cur_price} | ${c_cost_price}`;
        },
    },
    {
        title: '市值',
        dataIndex: 'c_hold_value',
        width: 300,
    },
    {
        title: 'USD市值',
        dataIndex: 'market_value_usd_complete',
        width: 300,
    },
];
