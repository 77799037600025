import { css } from '@emotion/css';
import { Form, FormInstance, Select } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    UserAccountDetailInfo,
    getUserAllAccount,
} from '../../../../../../service/requests/HistoricalTransactions';
const { Option } = Select;
const AccountItem = ({
    clientId,
    readOnly,
    form,
    readOnlyValue,
    selectCallback,
    required,
    name,
    width,
    allowAccountName,
    disableAccount,
    noName,
    label,
}: {
    clientId: number;
    allowAccountName?: string;
    disableAccount?: string;
    form?: FormInstance;
    label?: string;
    width?: string;
    readOnly?: boolean;
    required?: boolean;
    readOnlyValue?: string;
    noName?: boolean;
    name?: string | string[];
    selectCallback: (bankList: UserAccountDetailInfo, index?: number, isOnChange?: boolean) => void;
}) => {
    const [bankList, setBankList] = useState<UserAccountDetailInfo[]>([]);
    const selecIndex = useRef<number>(0);
    const [selectItem, setSelectItem] = useState<UserAccountDetailInfo>();
    useEffect(() => {
        getUserAllAccount({ customer_id: clientId.toString() }).then((res) => {
            setBankList(res.data);
            if (res.data.length === 1 && form) {
                form.setFieldsValue({
                    account_id: res.data[0].account_id,
                });
            }
            if (readOnlyValue) {
                const index = res.data.findIndex((bank) => bank.account_id === readOnlyValue);
                selecIndex.current = index;
                selectCallback(res.data[index], index);
                setSelectItem(res.data[index]);
            } else {
                selectCallback(res.data[selecIndex.current], selecIndex.current);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientId]);
    useEffect(() => {
        if (selectItem === undefined && readOnlyValue && bankList.length > 0) {
            const index = bankList.findIndex((bank) => bank.account_id === readOnlyValue);
            if (index === -1) return;
            selecIndex.current = index;
            selectCallback(bankList[index], index);
            setSelectItem(bankList[index]);
        }
    }, [readOnlyValue, selectItem, bankList, selectCallback]);
    const readOnlyContent = useMemo(() => {
        if (readOnlyValue && bankList.length > 0) {
            const index = bankList.findIndex((bank) => bank.account_id === readOnlyValue);
            if (index === -1) return '';
            let item = bankList[index];
            return `${item?.bank_name} - ${item?.account_name} - ${item?.vendor_sub_account_id}`;
        }
        return '';
    }, [bankList, readOnlyValue]);
    const onChange = (value: string) => {
        const index = bankList.findIndex((bank) => bank.account_id === value);
        selecIndex.current = index;
        selectCallback(bankList[index], index, true);
    };

    const options = useMemo(() => {
        const styles = css`
            border-bottom: 1px solid #e8e8e8;
        `;
        let finalList = [...bankList];
        if (typeof allowAccountName === 'string' && allowAccountName.length > 0) {
            finalList = finalList.filter((item) => item.account_name === allowAccountName);
            finalList = finalList.filter((item) => item.account_id !== disableAccount);
        }
        return finalList.map((item) => (
            <Option className={styles} value={item.account_id} key={item.account_id}>
                {item.bank_name + ' - ' + (item.vendor_sub_account_id || '')}
            </Option>
        ));
    }, [bankList, allowAccountName, disableAccount]);
    return (
        <Form.Item
            label={label ?? 'Account'}
            name={name ?? ['ext_info', 'account_id']}
            rules={[{ required: required, message: 'Account is required' }]}
        >
            {readOnly && selectItem ? (
                <div>{readOnlyContent}</div>
            ) : (
                <Select onSelect={onChange} style={{ width }}>
                    {options}
                </Select>
            )}
        </Form.Item>
    );
};

export default AccountItem;
