import { message, Modal } from 'antd';
import React, { useEffect, useMemo, useRef } from 'react';
import html2canvas from 'html2canvas';
import style from './index.module.scss';
import {
    AddTranscationProps,
    addTranscationRecord,
    requestTransById,
    TransRecordType,
} from '../AddTranscationModal/request';
import { showError } from '../../../../../RecommendationComponent/util';
interface CustomModalProps {
    visibleState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
}
interface MakeTransImg extends CustomModalProps {
    values?: AddTranscationProps;
    cancelClick?: () => void;
    resetFileds?: () => void;
    params?: {
        account_id: string;
        trans_id: string;
    };
    selectMap?: Map<string, any>;
}
type filedType = {
    key: string;
    value: string;
};
const MakeTranscationImgModal = ({
    visibleState,
    cancelClick,
    values,
    params,
    resetFileds,
    selectMap,
}: MakeTransImg) => {
    const [visible, setVisible] = visibleState; //是否显示
    const imgRef = useRef<HTMLDivElement>(null);
    const [transData, setTransData] = React.useState<TransRecordType>(); //交易记录
    const [hasConfirm, setHasConfirm] = React.useState<boolean>(false); //是否已经确认

    const addTrans = () => {
        if (values) {
            addTranscationRecord(values).then((res) => {
                if (res.data && res.data.trans_id) {
                    setHasConfirm(true);
                    resetFileds && resetFileds();
                    requestTransById({
                        trans_id: res.data.trans_id,
                        account_id: values.account_id,
                    }).then((res) => {
                        setTransData(res.data);
                    }, showError);
                }
            }, showError);
        }
    };
    useEffect(() => {
        if (params) {
            setHasConfirm(true);
            requestTransById({
                trans_id: params.trans_id,
                account_id: params.account_id,
            }).then((res) => {
                setTransData(res.data);
            }, showError);
        }
    }, [params]);
    const filedsList = useMemo(() => {
        if (transData) {
            let list: filedType[] = [];
            //从transData 中section中取出所有的字段
            transData.sections?.forEach((item) => {
                (item.data ?? []).forEach((field) => {
                    list.push({
                        key: field.title,
                        value: field.value,
                    });
                });
            });
            return list;
        } else {
            return [];
        }
    }, [transData]);

    function getImage() {
        const element = imgRef.current;
        if (element) {
            html2canvas(element).then((canvas) => {
                // 将 Canvas 画布对象保存到本地
                let imgData = canvas
                    .toDataURL('image/png')
                    .replace(/^data:image\/[^;]/, 'data:application/octet-stream');

                canvas.toBlob(async (blob) => {
                    if (blob) {
                        const data = [
                            new ClipboardItem({
                                'image/png': blob,
                            }),
                        ]; // https://w3c.github.io/clipboard-apis/#dom-clipboard-write
                        await navigator.clipboard
                            .write(data)
                            .then(
                                () => {
                                    console.log('Copied to clipboard successfully!');
                                },
                                () => {
                                    console.error('Unable to write to clipboard.');
                                }
                            )
                            .finally(() => {
                                message.success('生成图片成功，已复制到剪切板');
                                setVisible(false);
                            });
                    }
                });

                const link = document.createElement('a');
                link.download = `${values?.account_id}-${values?.product_type}.png`;
                link.href = imgData;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        }
    }
    const getValueWithKey = (key: string) => {
        if (selectMap) {
            if (selectMap.get(key)) {
                return (selectMap.get(key) as Map<string, any>).get(
                    values?.trans_detail_fields[key]?.toString() ?? ''
                );
            }
        }

        return values?.trans_detail_fields[key] ?? '';
    };
    return (
        <Modal
            width={500}
            visible={visible}
            okText={hasConfirm ? '复制图片' : '提交'}
            onCancel={() => {
                if (cancelClick && hasConfirm === false) {
                    cancelClick();
                } else {
                    setVisible(false);
                }
            }}
            className={style.content}
            cancelText="取消"
            title={hasConfirm ? '生成交易记录确认图片' : '交易记录新增确认'}
            onOk={() => {
                if (hasConfirm) {
                    getImage();
                } else {
                    addTrans();
                }
            }}
        >
            <div className={style.title}>
                {hasConfirm
                    ? '点击【复制图片】，可将下方图片复制到剪切板，便于发给客户进行交易确定'
                    : '确认添加后，客户可在App-交易记录中看到该条记录，展示以下内容'}
            </div>
            <div className={style.body} ref={imgRef}>
                {hasConfirm && (
                    <div className={style.header}>
                        {/* <img
                            // src={transData?.trans_detail_type_icon}
                            // src={testPng}
                            xmlns={transData?.trans_detail_type_icon}
                            // ref={logoRef}
                            // crossOrigin="anonymous"
                            // alt=""
                            className={style.img}
                        /> */}
                        <div className={style.action}>{transData?.trans_text}</div>
                        <div className={style.number}>{transData?.amount}</div>
                        <div className={style.currency}>{transData?.currency}</div>
                    </div>
                )}
                <div className={style.values}>
                    {hasConfirm
                        ? (filedsList ?? []).map((item: filedType) => (
                              <ValueRow value={item.value} label={item.key} />
                          ))
                        : Object.keys(values?.trans_detail_fields ?? {})
                              .filter(
                                  (item) =>
                                      values?.trans_detail_fields[item] &&
                                      values?.fieldTitleMap.get(item)
                              )
                              .map((item, index) => (
                                  <ValueRow
                                      key={item + index.toString()}
                                      label={values?.fieldTitleMap.get(item) ?? ''}
                                      value={getValueWithKey(item)}
                                  />
                              ))}
                </div>
            </div>
        </Modal>
    );
};
const ValueRow = ({ label, value }: { label: string; value: string | number }) => {
    return (
        <div className={style.valueBody} key={label}>
            <div className={style.key}>{label}</div>
            <div className={style.value}>{value}</div>
        </div>
    );
};

export type { CustomModalProps };
export default MakeTranscationImgModal;
