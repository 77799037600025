import { Table, Tabs } from 'antd';
import React, { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { queryAsset, querySummaryAsset } from '../../service';
import { QueryAsset, QuerySummaryAsset } from '../../service/type';
import { mapCurrencySymbols } from '../RecommendationComponent/util';
import AssetAllocation from './AssetAllocation';
import styles from './EditAssetsPlan.module.scss';

const basePosition = {
    hold_value_currency: 'USD',
    hold_value: 0,
    percentage: 0,
    sub_positions: [],
};
const { TabPane } = Tabs;

export default function EditAssetsPlan() {
    const { uid, copy_id: copyId, plan_name } = useParams<{
        uid: string;
        copy_id: string;
        plan_name: string;
    }>();

    const copy_id = useMemo(() => Number(copyId), [copyId]);

    const [assetList, setAssetList] = useState<QueryAsset['sec_cates']>([]);
    const [assetSummary, setAssetSummary] = useState<QuerySummaryAsset['asset'] | null>(null);
    useEffect(() => {
        queryAsset({ uid }).then((res) => {
            setAssetList(res.data.sec_cates);
        });
        querySummaryAsset({ uid }).then((res) =>
            setAssetSummary(
                res.data.asset ?? {
                    currency: 'USD',
                    net_asset: 0,
                    total_asset: 0,
                    debit_amount: 0,
                }
            )
        );
    }, [uid, copy_id]);
    const [cashColumns, cashDataSource] = useMemo(() => {
        const cash = assetList.find((item) => item.sec_cate_id === 'cash');
        if (cash) {
            const balance = cash.positions.find((item) => item.isin === 'balance') || basePosition;
            const debit = cash.positions.find((item) => item.isin === 'debit') || basePosition;
            const baseItemList: Array<{
                isin: string;
                symbol: string;
            }> = [];
            //取并集
            balance.sub_positions.forEach((element) => {
                baseItemList.push({
                    isin: element.isin,
                    symbol: element.symbol,
                });
            });
            debit.sub_positions.forEach((element) => {
                if (baseItemList.findIndex((target) => target.isin === element.isin) === -1) {
                    baseItemList.push({
                        isin: element.isin,
                        symbol: element.symbol,
                    });
                }
            });
            if (baseItemList.length === 0) {
                return [[], []];
            }
            const cashColumns = baseItemList.map((item) => ({
                title: (
                    <>
                        币种
                        <div style={{ display: 'inline-block', width: 20 }} />
                        金额
                    </>
                ),
                dataIndex: item.isin,
                width: 20,
            }));
            const cashDataSource: Array<Record<string, any>> = [
                {
                    index: 'balance',
                },
                {
                    index: 'debit',
                },
            ];
            balance.sub_positions.forEach((target) => {
                cashDataSource[0][target.isin] = (
                    <>
                        {target.symbol}
                        <div style={{ display: 'inline-block', width: 20 }} />
                        {target.c_book_value}
                    </>
                );
            });
            debit.sub_positions.forEach((target) => {
                cashDataSource[1][target.isin] = (
                    <>
                        {target.symbol}
                        <div style={{ display: 'inline-block', width: 20 }} />
                        {target.c_book_value}
                    </>
                );
            });
            return [cashColumns, cashDataSource];
        } else {
            return [[], []];
        }
    }, [assetList]);
    return (
        <>
            <div className={styles['assets-edit-assets-modal']}>
                <div className={styles['total-assets']}>
                    <span>
                        净资产：{mapCurrencySymbols(assetSummary?.currency)}{' '}
                        {assetSummary?.c_net_asset}
                    </span>
                    <span>
                        总资产：{mapCurrencySymbols(assetSummary?.currency)}{' '}
                        {assetSummary?.c_total_asset}
                    </span>
                    <span>
                        总负债：{mapCurrencySymbols(assetSummary?.currency)}{' '}
                        {assetSummary?.c_debit_amount}
                    </span>
                </div>
                <Tabs defaultActiveKey="1">
                    <TabPane tab={<span style={{ fontSize: 16 }}>用户持仓</span>} key="1">
                        {assetList.map((asset) => {
                            if (asset.sec_cate_id === 'cash') {
                                return cashDataSource.length ? (
                                    <div
                                        className={styles['cash-asset-wrapper']}
                                        key={asset.sec_cate_id}
                                    >
                                        <div className={styles['asset-title']}>
                                            <div className={styles.name}>{asset.name}</div>
                                            <div className={styles.value}>
                                                {mapCurrencySymbols(asset.hold_value_currency)}
                                                {asset.c_hold_value}
                                            </div>
                                        </div>
                                        <Table
                                            columns={cashColumns}
                                            dataSource={cashDataSource}
                                            pagination={false}
                                            rowKey="index"
                                            scroll={{ x: true }}
                                        />
                                    </div>
                                ) : null;
                            }
                            return asset.positions.length ? (
                                <div className={styles['asset-wrapper']} key={asset.sec_cate_id}>
                                    <div className={styles['asset-title']}>
                                        <div className={styles.name}>{asset.name}</div>
                                        <div className={styles.value}>
                                            {mapCurrencySymbols(asset.hold_value_currency)}
                                            {asset.c_hold_value}
                                        </div>
                                    </div>
                                    <Table
                                        columns={baseCloumn}
                                        dataSource={asset.positions}
                                        pagination={false}
                                        rowKey="isin"
                                        scroll={{ x: true }}
                                    />
                                </div>
                            ) : null;
                        })}
                    </TabPane>
                    <TabPane tab={<span style={{ fontSize: 16 }}>{plan_name}</span>} key="2">
                        <AssetAllocation uid={uid} copy_id={copy_id} />
                    </TabPane>
                </Tabs>
            </div>
        </>
    );
}

const baseCloumn = [
    {
        title: '币种',
        dataIndex: 'book_value_currency',
        width: 80,
    },
    {
        title: '产品',
        dataIndex: 'name',
        width: 300,
    },
    {
        title: '数量',
        width: 300,
        render: ({ c_aggr_balance }: { c_aggr_balance: string }) => {
            return c_aggr_balance.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
    },
    {
        title: '现价｜成本',
        width: 300,
        render: ({ c_cur_price, c_cost_price }: { c_cur_price: string; c_cost_price: string }) => {
            return `${c_cur_price} | ${c_cost_price}`;
        },
    },
    {
        title: '市值',
        dataIndex: 'c_hold_value',
        width: 300,
    },
    {
        title: 'USD市值',
        dataIndex: 'market_value_usd_complete',
        width: 300,
    },
];
