import { Button, Divider, Form, Input, Modal, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useEffect, useState } from 'react';
import styles from '../index.module.less';
import { GinkgoInput } from './FormItems/GinkgoFieldItems';
import LKDatePicker from '../../LKDatePicker';
import { useRequest } from 'ahooks';
import { getTransactionLetterInfo, saveTransactionLetterInfo } from '../../../request';
import { Asset, AssetType, TransTransferExtInfo } from '../../../type';
import Table, { ColumnsType } from 'antd/lib/table';
import { FormatNumber } from '../../../../../utils';

interface TransferPdfFormProps {
    transId: number;
    callback?: () => void;
}
const TransferPdfForm = (props: TransferPdfFormProps) => {
    const { transId, callback } = props;
    const [initValue, setInitValue] = useState<Record<string, string>>();
    const [securitiesAssets, setSecuritiesAssets] = useState<Asset[]>([]);
    const [cashAssets, setCashAssets] = useState<Asset[]>([]);
    const [form] = useForm();
    const { data: letterInfoData } = useRequest(getTransactionLetterInfo, {
        defaultParams: [{ trans_id: transId }],
    });
    const { loading: saveLoading, runAsync: save } = useRequest(saveTransactionLetterInfo, {
        manual: true,
    });
    const securitiesColumns: ColumnsType<Asset> = [
        {
            title: 'Asset Type',
            dataIndex: ['asset_type', 'desc'],
        },
        {
            title: 'ISIN',
            dataIndex: 'isin',
        },
        {
            title: 'Trasfer Amount',
            dataIndex: 'qty',
        },
    ];
    const cashColumns: ColumnsType<Asset> = [
        {
            title: 'Currency',
            dataIndex: 'currency',
        },
        {
            title: 'Trasfer Amount',
            render: (_, record) => (record.amount ? FormatNumber(record.amount, 2) : ''),
        },
    ];
    useEffect(() => {
        if (letterInfoData) {
            // init table value
            const cashList = [
                AssetType.Cash,
                AssetType.FixedDeposit,
                AssetType.NCD,
                AssetType.Loan,
            ];
            setCashAssets(
                letterInfoData.data.transaction.assets.filter((t) =>
                    cashList.includes(t.asset_type.code)
                )
            );
            setSecuritiesAssets(
                letterInfoData.data.transaction.assets.filter(
                    (t) => !cashList.includes(t.asset_type.code)
                )
            );
            // init form value
            const extInfo = letterInfoData.data.transaction.ext_info as TransTransferExtInfo;
            const letterInfo = letterInfoData.data.letter_info;
            let temp = {
                from_account_name: extInfo?.out_account_name || '',
                from_account_no: extInfo?.out_account_no || '',
                to_account_name: extInfo?.in_account_name || '',
                to_account_no: extInfo?.in_account_no || '',
                attention: letterInfo?.attention || '',
                contact_number: letterInfo?.contact_number || '',
                contact_person: letterInfo?.contact_person || '',
                date: letterInfo?.date || '',
                email_address: letterInfo?.email_address || '',
                group_email: letterInfo?.group_email || '',
                to_account_bank_id_code: letterInfo?.to_account_bank_id_code || '',
                to_account_bank_name: letterInfo?.to_account_bank_name || '',
                from_account_bank_name: letterInfo?.from_account_bank_name || '',
                purpose: letterInfo?.purpose || '',
            } as Record<string, string>;
            Object.keys({ temp }).forEach((i) => {
                if (!temp[i]) {
                    delete temp[i];
                }
            });
            setInitValue(temp);
        }
    }, [letterInfoData]);
    const onPreviewClick = () => {
        form.validateFields().then((value) => {
            const {
                from_account_name,
                from_account_no,
                to_account_name,
                to_account_no,
                ...rest
            } = value;
            save({ trans_id: transId, letter_info: rest }).then((res) => {
                callback && callback();
            });
        });
    };
    const onSaveClick = () => {
        form.validateFields().then((value) => {
            const {
                from_account_name,
                from_account_no,
                to_account_name,
                to_account_no,
                ...rest
            } = value;
            save({ trans_id: transId, letter_info: rest }).then((res) => {
                Modal.success({
                    content: 'save successfully!',
                });
            });
        });
    };
    return (
        <Spin spinning={!initValue}>
            {initValue && (
                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    colon={false}
                    className={styles.transferPdfForm}
                    initialValues={initValue}
                >
                    <Form.Item name="date" label="Date">
                        <LKDatePicker />
                    </Form.Item>
                    <Divider />
                    <div className={styles.sectionTitle}>From Account</div>
                    <Form.Item name="from_account_bank_name" label="Bank Name">
                        <GinkgoInput />
                    </Form.Item>
                    <Form.Item name="attention" label="Attention">
                        <GinkgoInput />
                    </Form.Item>
                    <Form.Item name="from_account_name" label="Account Name">
                        <GinkgoInput readOnly />
                    </Form.Item>
                    <Form.Item name="from_account_no" label="Account Number">
                        <GinkgoInput readOnly />
                    </Form.Item>
                    <Divider />
                    <div className={styles.sectionTitle}>To Account</div>
                    <Form.Item name="to_account_bank_name" label="Bank Name">
                        <GinkgoInput />
                    </Form.Item>
                    <Form.Item name="to_account_bank_id_code" label="Bank Identifier Code">
                        <GinkgoInput />
                    </Form.Item>
                    <Form.Item name="to_account_name" label="Account Name">
                        <GinkgoInput readOnly />
                    </Form.Item>
                    <Form.Item name="to_account_no" label="Account Number">
                        <GinkgoInput readOnly />
                    </Form.Item>
                    <Divider />
                    {securitiesAssets.length > 0 && (
                        <>
                            <div className={styles.sectionTitle}>Securities</div>
                            <div className={styles.pdfFormTable}>
                                <Table
                                    rowKey={(record) => record.isin}
                                    columns={securitiesColumns}
                                    dataSource={securitiesAssets}
                                    size="small"
                                    pagination={false}
                                />
                            </div>
                            <Divider />
                        </>
                    )}
                    {cashAssets.length > 0 && (
                        <>
                            <div className={styles.sectionTitle}>Cash</div>
                            <div className={styles.pdfFormTable}>
                                <Table
                                    rowKey={(record) => record.isin}
                                    columns={cashColumns}
                                    dataSource={cashAssets}
                                    size="small"
                                    pagination={false}
                                />
                            </div>
                            <Divider />
                        </>
                    )}
                    <Form.Item
                        name="purpose"
                        label="Purpose"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                        className={styles.purposeLabel}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Divider />
                    <div className={styles.sectionTitle}>Contact</div>
                    <Form.Item name="contact_person" label="Contact Person">
                        <GinkgoInput />
                    </Form.Item>
                    <Form.Item name="email_address" label="Email Address">
                        <GinkgoInput />
                    </Form.Item>
                    <Form.Item name="group_email" label="Group Email">
                        <GinkgoInput />
                    </Form.Item>
                    <Form.Item name="contact_number" label="Contact Number">
                        <GinkgoInput />
                    </Form.Item>
                    <Divider />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={onSaveClick} loading={saveLoading}>
                            Save
                        </Button>
                        <Button onClick={onPreviewClick} type="primary">
                            Preview Letter
                        </Button>
                    </div>
                </Form>
            )}
        </Spin>
    );
};

export default TransferPdfForm;
