import React, { useEffect } from 'react';
import AssetPositionTable from '../Component/AssetPositionTable';
import { Space, Spin } from 'antd';
import { queryAssetList, queryAssetSummary } from '../request';
import { PositionAssetList, PositionAssetSummary } from '../type';
import AssetCommonTable from '../Component/AssetCommonTable';
import { exportExcel as downloadExcel } from '../util';
import { LKRootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import moment from 'moment';

const AssetDetail = () => {
    const exportExcel = useSelector((state: LKRootState) => state.position.state);
    const [assetSummary, setAssetSummary] = React.useState<PositionAssetSummary>();
    const [assetList, setAssetList] = React.useState<PositionAssetList[]>();

    useEffect(() => {
        const tableIds =
            assetList?.map((item) => `Position_Asset_${item.asset_types[0].desc}`) ?? [];
        const handleExport = () => {
            let elements = document.querySelectorAll('.ant-table-measure-row');
            elements.forEach((element) => {
                element.remove();
            });
            downloadExcel(
                ['Position_Asset_Total_Table', 'Position_Asset_List_Table', ...tableIds],
                `Position-Asset-${moment().format('YYYYMMDD HHmmss')}`
            );
        };
        if (exportExcel.index === 'asset' && exportExcel.refresh > 0) {
            handleExport();
        }
    }, [assetList, exportExcel]);

    useEffect(() => {
        queryAssetSummary({}).then((res) => {
            setAssetSummary(res.data);
        });
        queryAssetList({}).then((res) => {
            setAssetList(res.data);
        });
    }, []);
    return (
        <Spin spinning={!(assetSummary !== undefined || assetList !== undefined)}>
            <Space direction="vertical" style={{ width: '100%', paddingBottom: '20px' }}>
                {assetSummary && (
                    <>
                        <AssetPositionTable
                            key={'total'}
                            id="Position_Asset_Total_Table"
                            data={[
                                {
                                    title: 'Total',
                                    amount:
                                        `${assetSummary?.total_amount} ${assetSummary?.currency}` ??
                                        '-',
                                },
                            ]}
                        />
                        <AssetPositionTable
                            key={'asset'}
                            id="Position_Asset_List_Table"
                            data={
                                assetSummary?.asset_position_percents?.map((item) => ({
                                    title:
                                        (item.asset_types[0] ?? '').desc.indexOf('Bond') > -1
                                            ? 'Bond'
                                            : item.asset_types[0].desc,
                                    amount: `${item.amount} ${assetSummary.currency}`,
                                })) ?? []
                            }
                        />
                    </>
                )}
                {assetList?.map((item, index) => {
                    return (
                        <AssetCommonTable
                            id={`Position_Asset_${item.asset_types[0].desc}`}
                            data={item}
                            key={index}
                            withClick
                        />
                    );
                })}
            </Space>
        </Spin>
    );
};

export default AssetDetail;
