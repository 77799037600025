import { CustomerManageData } from '../../service/requests/CustomerManage';
import { OrderDetail } from '../../service/requests/OrderRequest';
import { BondQuoteEntity, BondType } from '../Quotation/request';

export interface ProductQuoteResponseType {
    total_count: number;
    total_pages: number;
    current_page: number;
    page_size: number;
    product_quotes: QuoteHistoryListType[];
}
export enum QouteType {
    Screening = 1,
    Communicating = 2,
    Canceled = 3,
    Confirmed = 4,
}
interface QuoteHistoryListType {
    id: number;
    member_id: number;
    customer_id: number;
    account_id: string;
    product_type: BondType;
    status: QouteType;
    created_at: number;
    updated_at: number;
    member: MemberType;
    customer: CustomerType;
    account: AccountType;
}

interface MemberType {
    id: number;
    name: string;
    mobile: string;
    valid: boolean;
    huobanId: number;
    feishuId: string;
    feishuOpenId: string;
    jiraId: string;
    feishuAvatar: string;
}

type CustomerType = CustomerManageData['pageData'][0];

interface AccountType {
    accountId: string;
    uid: string;
    accountType: string;
    accountClass: number;
    accountName: string;
    bank_name: string;
    vendorSubAccountId: string;
    tag: number;
    displayName: string;
    status: number;
    boundAt: number;
    vendor_sub_account_id: string;
}
export interface ProductQuoteBondDetailType {
    id: number;
    account_id: string;
    quote_pool_id_list: number[];
    selected_quote_id_list: number[];
    price_increased_percentage: number;
    fields: {
        title: string;
        unit?: string;
        value: string;
        is_pass_check?: boolean;
        failed_check_message: string;
    }[];
    quote_pool: BondQuoteEntity[];
    selected_quotes: BondQuoteEntity[];
    minimum_transaction_amount: number;
    currency: string;
    bank_type: string;
    product_type: BondType;
    status: QouteType;
}
export interface UserAccountInfo {
    user: {
        name: string;
    };
    account: {
        accountName: string;
        accountType: string;
        vendorSubAccountId: string;
    };
    balance_amount: number;
    risk_rate: number;
    currency: string;
}
export enum BondQuoteDetailPageType {
    Preview = 'preview',
    Edit = 'edit',
}

export interface BondQuotesDetails {
    order_details: OrderDetail[];
}
