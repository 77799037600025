import axios from '../../../../service/tools';
const accountReview = (data: {
    customer_id: number;
    asset_type: string;
    start_time: string;
    end_time: string;
}) => {
    return axios.post('/v1/bi/account_review', { data });
};
export { accountReview };
