import { HostType } from '../../../service/config';
import { multiHostAxios } from '../../../service/tools';

const axios = multiHostAxios(HostType.NOTIFY);

export const getEventTypeList = () => {
    return axios.post<EventType[]>('/v1/admin/notify/EventType/list');
};

export const createEventType = (data: EventType) => {
    return axios.post('/v1/admin/notify/EventType/create', { data });
};

export const updateEventType = (data: EventType) => {
    return axios.post('/v1/admin/notify/EventType/update', { data });
};
