import React, { useCallback, useState, useEffect } from 'react';
import { Button, Space } from 'antd';
import './index.less';
import CreateRecommendationListModal from './CreateRecommendationListModal';
import { getRecomProductList } from '../../service';
import { RecomProductList } from '../../service/type';
import PanelComponent from './Panel';
import { EventList, uploadEventTrack } from '../../service/requests/EventTrack';

export interface CollapseItemType {
    name: string;
    sub_sec_cate_id: string;
    parent_sec_cate_id: string;
}

export default function Recommendation() {
    // const { f_sec_type } = useParams<{ f_sec_type: string }>();
    const [f_sec_type, setF_sec_type] = useState('stock');
    const [refreshList, setRefreshList] = useState(0);
    const handleRefreshList = useCallback(() => setRefreshList((num) => num + 1), []);
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [recomProductList, setRecomProductList] = useState<RecomProductList>([]);
    useEffect(() => {
        getRecomProductList({ f_sec_type }).then((response) => {
            setRecomProductList(response.data);
        });
    }, [f_sec_type, refreshList]);
    return (
        <div id="recommendation">
            {[
                { name: '股票', id: 'stock' },
                { name: '基金', id: 'fund' },
                { name: '债券', id: 'bond' },
                { name: '现金', id: 'cash' },
                { name: '其他', id: 'other' },
            ].map((item) => {
                return (
                    <Button
                        type={f_sec_type === item.id ? 'primary' : 'ghost'}
                        key={item.id}
                        onClick={() => {
                            uploadEventTrack(EventList.ProductRecommendFilter, 2, {
                                name: item.name,
                            });
                            setF_sec_type(item.id);
                        }}
                    >
                        {item.name}
                    </Button>
                );
            })}
            <div className="add-customer-wrapper">
                <CreateRecommendationListModal
                    f_sec_type={f_sec_type}
                    visible={createModalVisible}
                    handleRefreshList={handleRefreshList}
                    close={() => setCreateModalVisible(false)}
                />
                <Button
                    onClick={() => {
                        uploadEventTrack(EventList.ProductRecommendAdd, 2);
                        setCreateModalVisible(true);
                    }}
                >
                    创建推荐列表
                </Button>
            </div>
            <Space direction="vertical" style={{ width: '90%' }}>
                {recomProductList.map((recomProduct) => (
                    <PanelComponent
                        key={recomProduct.tag_id}
                        recomProduct={recomProduct}
                        handleRefreshList={handleRefreshList}
                    />
                ))}
            </Space>
        </div>
    );
}
