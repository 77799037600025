import { Tabs } from 'antd';
import React from 'react';
import style from './index.module.scss';
const TopTabs = ({
    labelList,
    onChange,
}: {
    labelList: { title: string; key: string }[];
    onChange: (activeKey: string) => void;
}) => {
    return (
        <div className={style.container}>
            <Tabs
                onChange={(key) => {
                    onChange(key);
                }}
            >
                {labelList.map((item) => {
                    return <Tabs.TabPane key={item.key} tab={item.title}></Tabs.TabPane>;
                })}
            </Tabs>
        </div>
    );
};

export default TopTabs;
