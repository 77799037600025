import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Table } from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { queryAllLtv, ILtv } from '@requests/LtvRequest';

import './index.scss';
import LtvModal from '../LtvModal';
import moment from 'moment';

export enum ModalType {
    Edit = 'edit',
    HISTORY = 'history',
}
const LtvTable = (data: TableProps<any>) => {
    const [showModal, setShowModal] = useState(false);
    const [type, setType] = useState('edit');
    const [asset, setAsset] = useState({});
    const [dataSource, setData] = useState<ILtv[] | []>([]);

    useEffect(() => {
        fetchLvtAll();
    }, []);

    async function fetchLvtAll() {
        const res = await queryAllLtv({});
        setData(res?.ltvs ?? []);
    }

    const columns: ColumnsType<ILtv> = useMemo(
        () => [
            {
                title: 'Client',
                dataIndex: ['client', 'name'],
                width: 240,
                render: (_, record: ILtv) => {
                    return (
                        <div>
                            {record.client.id}-{record.client.name}
                        </div>
                    );
                },
            },
            {
                title: 'Bank',
                dataIndex: ['account', 'bank_name'],
                width: 166,
                render: (_value, record: ILtv) => {
                    return <div>{_value || '-'}</div>;
                },
            },
            {
                title: 'Account',
                dataIndex: ['account', 'vendor_sub_account_id'],
                width: 240,
                render: (_value, record: ILtv) => {
                    return <div>{_value || '-'}</div>;
                },
            },
            {
                title: 'Available Loan Amount ($Million)',
                dataIndex: 'remaining_loanable_amount',
                width: 389,
                render(value: number) {
                    return isNaN(+Number(value).toFixed(3)) ? '0.000' : Number(value).toFixed(3);
                },
            },
            {
                title: 'Update Date',
                dataIndex: 'updated_at',
                width: 166,
                render(value) {
                    return value ? moment(value).format('YYYY.MM.DD') : '-';
                },
            },
            {
                title: 'Person In Charge',
                dataIndex: ['person_in_charge_ic', 'name'],
                width: 207,
                render: (_value, record: ILtv) => {
                    return <div>{_value || '-'}</div>;
                },
            },
            {
                title: '',
                width: 181,
                className: 'ltv-row-operation',
                render: (_, record: ILtv) => {
                    return (
                        <div>
                            <span
                                className="ltv-row-operation-btn"
                                onClick={() => {
                                    operation(ModalType.Edit, record);
                                }}
                            >
                                Edit
                            </span>
                            <span
                                className="ltv-row-operation-btn"
                                onClick={() => {
                                    operation(ModalType.HISTORY, record);
                                }}
                            >
                                History
                            </span>
                        </div>
                    );
                },
            },
        ],
        []
    );

    function operation(type: string, record: ILtv) {
        setType(type);
        setAsset(record);
        setShowModal(true);
    }

    function onConfirm() {
        setShowModal(false);
        fetchLvtAll();
    }

    function onCancel() {
        setShowModal(false);
    }

    return (
        <>
            <Table
                bordered
                size="small"
                pagination={false}
                dataSource={dataSource}
                columns={columns}
                className="ltv-table"
                rowClassName="ltv-table-row"
                rowKey={(record) => record.id}
            />

            <LtvModal
                show={showModal}
                type={type}
                asset={asset as ILtv}
                onConfirm={onConfirm}
                onCancel={onCancel}
            />
        </>
    );
};

export default LtvTable;
