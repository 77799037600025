import React from 'react';
import { Radio, RadioChangeEvent, RadioGroupProps } from 'antd';
import { v4 as uuid } from 'uuid';
import styles from './index.module.less';

export type LKRadioGroupProps = Omit<RadioGroupProps, 'onChange'> & {
    datasource: Array<{
        label: string;
        value: string | number | boolean;
        disabled?: boolean;
        btnStyle?: React.CSSProperties;
        width?: string;
    }>;
    onChange?: (value: string | number | boolean) => void;

    itemWidth?: string; // '25%';
    readOnly?: boolean;
    ebleReverse?: boolean;
};
const LKRadioGroup = (props: LKRadioGroupProps) => {
    const { datasource, onChange, itemWidth, ...rest } = props;
    const onValueChange = (e: RadioChangeEvent) => {
        if (props.ebleReverse && e.target.value === props.value) {
            onChange && onChange('');
            return;
        }
        onChange && onChange(e.target.value);
    };
    return props.readOnly ? (
        <div className="LK_Form_ReadOnly">
            {typeof props.value === 'number'
                ? datasource.find((item) => item.value === props.value)?.label
                : props.value}
        </div>
    ) : (
        <div className={styles.lkradio}>
            <Radio.Group {...rest} onChange={onValueChange}>
                <div className={styles.group}>
                    {datasource.map((i) => (
                        <div
                            key={uuid()}
                            className={styles.item}
                            style={{ width: i.width || itemWidth || '' }}
                        >
                            <Radio.Button disabled={i.disabled} value={i.value} style={i.btnStyle}>
                                {i.label}
                            </Radio.Button>
                        </div>
                    ))}
                </div>
            </Radio.Group>
        </div>
    );
};

export default LKRadioGroup;
