import React, { useCallback, useEffect, useState } from 'react';
import styles from './BondsQuotation.module.scss';
import { Button, Form, Modal, Pagination, Spin } from 'antd';
import {
    BondQuoteEntity,
    BondQuoteListReq,
    BondQuoteReq,
    BondType,
    addBondQuote,
    deleteBondQuote,
    getBondQuoteList,
    updateBondQuote,
} from '../request';
import moment from 'moment';
import { showError } from '../../RecommendationComponent/util';
import BondQuoteTable from '../Component/BondQuoteTable';
import exportXls, { ChangeBondsToExcelData } from '../../../utils/Hooks/useExportExcel';
import BondForm from '../Component/BondForm';
import { EventList, uploadEventTrack } from '../../../service/requests/EventTrack';
import { LKMultipleCheckbox } from '../../../components/LKMultipleCheckbox';
import { useRequest } from 'ahooks';
import ResidualMaturity from '../Component/ResidualMaturity';

const PAGE_SIZE = 30;
const enum ModalType {
    Create = 'create',
    Edit = 'edit',
}
interface BondsQuodationProps {
    type: BondType;
    tableHeight?: number;
    defaultSelectedKeys?: React.Key[];
    onSelectionChange?: (BondQuoteList: BondQuoteEntity[]) => void;
}
const BondsQuotation = ({
    type,
    tableHeight,
    defaultSelectedKeys,
    onSelectionChange,
}: BondsQuodationProps) => {
    const [filter, setFilter] = useState<Record<string, string | string[]>>();
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState<ModalType>();
    const [editBondId, setEditBondId] = useState<number>();
    const [curPage, setCurPage] = useState(1);
    const [formBtnLoading, setFormBtnLoading] = useState(false);
    const [form] = Form.useForm();
    const {
        data: bondsQuoteListData,
        params: bondsQuotationsParams,
        loading,
        run: refresh,
    } = useRequest(getBondQuoteList, {
        defaultParams: [
            {
                bond_type: type,
                current_page: onSelectionChange ? 0 : undefined,
                page_size: PAGE_SIZE,
            },
        ],
    });
    useEffect(() => {
        if (filter) {
            if (filter.current_page && parseInt(filter.current_page as string) !== curPage) {
                setCurPage(parseInt(filter.current_page as string));
            }
            const newParam = {
                ...bondsQuotationsParams[0]!,
                ...filter,
            };
            if (onSelectionChange) {
                newParam.current_page = 0;
            }
            if (!newParam.residual_maturity_from && !newParam.residual_maturity_to) {
                delete newParam.residual_maturity_from;
                delete newParam.residual_maturity_to;
            }
            if (!newParam.sort_field && !newParam.sort_order) {
                delete newParam.sort_field;
                delete newParam.sort_order;
            }
            refresh(newParam);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, refresh]);
    const onCreateClick = () => {
        uploadEventTrack(EventList.QuotationBondsButtonClick, 2, {
            button_name: 'add',
            bond_type: type,
        });
        setModalType(ModalType.Create);
        form.resetFields();
        setShowModal(true);
    };
    const onFilterChange = useCallback(
        (item: any) => {
            setFilter((value) => {
                if (value || item) {
                    let newValue = { ...value, ...item, current_page: '1' };
                    return newValue;
                }
                return undefined;
            });
        },
        [setFilter]
    );
    const onModalCancelClick = () => {
        setShowModal(false);
    };
    const onModalConfirmClick = () => {
        form.validateFields().then((values) => {
            const { isin, issuer_cn, credit_rating_sp, seniority } = values;
            let data = {} as BondQuoteReq;
            data = { bond_type: type, isin };
            if (type === BondType.corporate) {
                data = {
                    ...data,
                    issuer_cn,
                    credit_rating_sp,
                    seniority,
                };
            }
            setFormBtnLoading(true);
            if (modalType === ModalType.Create) {
                addBondQuote(data)
                    .then((res) => {
                        setShowModal(false);
                        refresh(bondsQuotationsParams[0]!);
                        Modal.success({
                            content: '新增债券报价成功！',
                        });
                    }, showError)
                    .finally(() => {
                        setFormBtnLoading(false);
                    });
            } else {
                data = {
                    ...data,
                    id: editBondId,
                };
                updateBondQuote(data)
                    .then((res) => {
                        setShowModal(false);
                        refresh(bondsQuotationsParams[0]!);
                        Modal.success({
                            content: '修改债券报价成功！',
                        });
                    }, showError)
                    .finally(() => {
                        setFormBtnLoading(false);
                    });
            }
        });
    };
    const handlePageChange = (page: number, pageSize?: number) => {
        setCurPage(page);
        const newParam = {
            ...bondsQuotationsParams[0]!,
            current_page: page,
            page_size: pageSize,
        };
        refresh(newParam);
    };
    const onRemoveClick = (id: number) => {
        Modal.confirm({
            title: '确认删除债券报价？',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
                uploadEventTrack(EventList.QuotationBondsDataAction, 2, {
                    button_name: 'remove',
                    bond_type: type === BondType.corporate ? 1 : 2,
                });
                deleteBondQuote({ id }).then((res) => {
                    refresh(bondsQuotationsParams[0]!);
                }, showError);
            },
        });
    };
    const onEditClick = (bond: BondQuoteEntity) => {
        uploadEventTrack(EventList.QuotationBondsDataAction, 2, {
            button_name: type,
            bond_type: type === BondType.corporate ? 1 : 2,
        });
        setModalType(ModalType.Edit);
        setEditBondId(bond.id);
        setShowModal(true);
        form.setFieldsValue({
            id: bond.id,
            isin: bond.isin,
            credit_rating_sp: bond.credit_rating_sp,
            issuer_cn: bond.issuer_cn,
            seniority: bond.seniority,
        });
    };
    const exportExcel = useCallback(() => {
        uploadEventTrack(EventList.QuotationBondsButtonClick, 2, {
            button_name: 'export_excel',
            bond_type: type,
        });
        const param = {
            ...filter,
            bond_type: type,
            current_page: 0,
        } as BondQuoteListReq;
        if (!param.residual_maturity_from && !param.residual_maturity_to) {
            delete param.residual_maturity_from;
            delete param.residual_maturity_to;
        }
        if (!param.sort_field && !param.sort_order) {
            delete param.sort_field;
            delete param.sort_order;
        }
        getBondQuoteList(param).then((res) => {
            if (res.data.bond_quotes?.length > 0) {
                exportXls(
                    `${type === BondType.corporate ? '公司债' : '国债'}-${moment().format(
                        'yyyyMMDDHHmmss'
                    )}`,
                    [ChangeBondsToExcelData(bondsQuoteListData!.data.bond_quotes!)]
                );
            } else {
                Modal.error({
                    content: '该筛选条件下无结果',
                });
            }
        }, showError);
    }, [bondsQuoteListData, filter, type]);

    return (
        <>
            <div className={styles.content}>
                {bondsQuoteListData && (
                    <>
                        <div className={styles.header}>
                            <div className={styles.filterArea}>
                                {type === BondType.corporate && (
                                    <LKMultipleCheckbox
                                        plainOptions={bondsQuoteListData?.data.credit_rating_drop_down_list!.map(
                                            (item) => {
                                                return {
                                                    value: item,
                                                    label: item,
                                                };
                                            }
                                        )}
                                        valueChange={(item) => {
                                            let obj: { [key: string]: string[] } = {};
                                            obj['credit_ratings'] = item;
                                            onFilterChange(obj);
                                        }}
                                        title="信用评级 - S&P"
                                    />
                                )}
                                123
                                <ResidualMaturity onChange={onFilterChange} />
                            </div>
                            {!onSelectionChange && (
                                <div className={styles.tableTop}>
                                    <div className={styles.updateTime}>
                                        价格更新时间:
                                        {moment(bondsQuoteListData.data.price_update_ts).format(
                                            'yyyy.MM.DD HH:mm'
                                        )}
                                    </div>
                                    <div className={styles.btnArea}>
                                        <Button type="primary" onClick={onCreateClick}>
                                            增加
                                        </Button>
                                        <Button type="primary" onClick={exportExcel}>
                                            导出Excel
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <Modal
                            visible={showModal}
                            closable={false}
                            maskClosable={false}
                            title={modalType === ModalType.Create ? '新增债券报价' : '修改债券报价'}
                            cancelText="取消"
                            okText="确定"
                            onCancel={onModalCancelClick}
                            onOk={onModalConfirmClick}
                            okButtonProps={{
                                loading: formBtnLoading,
                                disabled: formBtnLoading,
                            }}
                        >
                            <BondForm
                                form={form}
                                type={type}
                                modalType={modalType!}
                                creditRatings={
                                    bondsQuoteListData.data.credit_rating_drop_down_list!
                                }
                                senioritys={bondsQuoteListData.data.seniority_drop_down_list!}
                            />
                        </Modal>
                    </>
                )}
                <Spin tip="加载中" spinning={loading}>
                    {bondsQuoteListData && (
                        <>
                            <BondQuoteTable
                                type={type}
                                data={bondsQuoteListData.data}
                                onRemoveClick={onRemoveClick}
                                onEditClick={onEditClick}
                                onChange={onFilterChange}
                                tableHeight={tableHeight}
                                defaultSelectedKeys={defaultSelectedKeys}
                                onSelectionChange={onSelectionChange}
                            />
                            {!onSelectionChange && (
                                <Pagination
                                    className={styles.pagination}
                                    current={curPage}
                                    pageSize={PAGE_SIZE}
                                    total={bondsQuoteListData.data.total_count}
                                    onChange={handlePageChange}
                                    showSizeChanger={false}
                                    size="small"
                                />
                            )}
                        </>
                    )}
                </Spin>
            </div>
        </>
    );
};

export default BondsQuotation;
