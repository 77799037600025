import { LinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { ReactNode } from 'react';
import { RMICInfo } from '../../../service/requests/CustomerManage';

import { CustomerData } from './CustomerDetail/type';

const baseKeyLeftArray = [
    {
        key: 'id',
        title: 'ID',
    },
    {
        key: 'name',
        title: '昵称',
    },
    {
        key: 'nickName',
        title: 'App昵称',
    },
    {
        key: 'remarkName',
        title: 'App备注',
    },
    {
        key: 'mobile',
        title: '手机号',
    },
    {
        key: 'email',
        title: '邮箱',
    },
    {
        key: 'birthday',
        title: '生日',
    },

    {
        key: 'address',
        title: '居住地',
    },
];

const baseKeyRightArray: {
    key: string;
    title: string;
    isArray?: boolean;
    speicalGetter?: (bankList: CustomerData['bankAccounts']) => ReactNode;
    valueGetter?: (data: CustomerData, callback?: any) => ReactNode;
    titleGetter?: (data: CustomerData, callback?: any) => ReactNode;
}[] = [
    {
        key: 'rms',
        title: '所属RM',
        isArray: true,
    },
    {
        key: 'ics',
        title: '所属IC',
        isArray: true,
    },
    {
        key: 'traders',
        title: '交易员',
        speicalGetter: (bankList: CustomerData['bankAccounts']) => {
            let traders: CustomerData['traders'] = [];
            if (bankList && bankList.length > 0) {
            }
            bankList.forEach((item) => {
                if (item.traders) {
                    traders = traders.concat(item.traders);
                }
            });
            //去除重复
            const map = new Map();
            traders.forEach((item: RMICInfo) => {
                if (!map.has(item.id)) {
                    map.set(item.id, item);
                }
            });
            //以交易员为维度来展示每个交易员所管理的银行
            const result = Array.from(map.values()).map((trader: RMICInfo) => {
                let bankIds: string[] = [];
                bankList.forEach((item) => {
                    if (item.traders) {
                        item.traders.forEach((traderItem) => {
                            if (traderItem.id === trader.id) {
                                bankIds.push(
                                    item.bankName +
                                        (item.vendorAccountId ? `-${item.vendorAccountId}` : '')
                                );
                            }
                        });
                    }
                });
                trader.tradeBankAccountIds = bankIds;
                return trader;
            });
            return result.map((trader) => {
                return <div>{`${trader.name} (${trader.tradeBankAccountIds.join('、')})`}</div>;
            });
        },
    },
    {
        key: 'state',
        title: '状态',
    },
    {
        key: 'source',
        title: '来源',
    },
    {
        key: 'inviterMobile',
        title: '邀请人手机号',
    },
    {
        key: 'relativeMobile',
        title: '关联账户',
        titleGetter: (data: CustomerData) => {
            if (data.parentCustomerId) {
                return '关联主账号';
            }
            if (data.relativeCustomerId) {
                return '关联副账号';
            }
            return '关联账号';
        },
        valueGetter: (data: CustomerData, onClick: (relativeId: number) => void) => {
            let id = '无';
            if (data.parentCustomerId) {
                id = data.parentCustomerId.toString();
            }
            if (data.relativeCustomerId) {
                id = data.relativeCustomerId.toString();
            }
            if (id === '无') {
                return <div key={'none'}>无</div>;
            }
            return (
                <Button
                    type="link"
                    onClick={() => {
                        onClick(Number.parseInt(id));
                    }}
                >
                    {id}
                    <LinkOutlined />
                </Button>
            );
        },
    },
    {
        key: 'parentCustomerState',
        title: '关联主账号状态',
    },
];

const businessKeyRightArray = [
    {
        key: 'passportNumber',
        title: '护照号码',
    },
    {
        key: 'passportIssuingCountry',
        title: '护照颁发国',
    },
    {
        key: 'familyMembers',
        title: '家庭成员',
    },
    {
        key: 'otherIdents',
        title: '其他ID',
    },
    {
        key: 'highestEducation',
        title: '最高学历',
    },
];

const businessKeyLeftArray = [
    {
        key: 'nationality',
        title: '国籍',
    },
    {
        key: 'passportExpiryDate',
        title: '护照到期日',
    },
    {
        key: 'martialStatus',
        title: '婚姻状况',
    },
    {
        key: 'otherProviders',
        title: '使用的其他机构',
    },
    {
        key: 'workHistory',
        title: '工作经历',
    },
    {
        key: 'mailingAddress',
        title: '邮寄地址',
    },
];

const colorMap: {
    [key: string]: string;
} = {
    开户中: 'rgba(239, 168, 0, 1)',
    开户完成: 'rgba(239, 168, 0, 1)',
    入金完成: 'rgba(239, 168, 0, 1)',
    活跃用户: 'rgba(239, 168, 0, 1)',
    销售线索: 'rgba(58, 153, 255, 1)',
    目标用户: 'rgba(58, 153, 255, 1)',
    初步接触: 'rgba(58, 153, 255, 1)',
    持续跟进: 'rgba(58, 153, 255, 1)',
    '其他(非客户)': 'rgba(170, 170, 170, 1)',
    已注销: 'rgba(170, 170, 170, 1)',
};
const bgColorMap: {
    [key: string]: string;
} = {
    开户中: 'rgba(239, 168, 0, 0.1)',
    开户完成: 'rgba(239, 168, 0, 0.1)',
    入金完成: 'rgba(239, 168, 0, 0.1)',
    活跃用户: 'rgba(239, 168, 0, 0.1)',
    销售线索: 'rgba(58, 153, 255, 0.1)',
    目标用户: 'rgba(58, 153, 255, 0.1)',
    初步接触: 'rgba(58, 153, 255, 0.1)',
    持续跟进: 'rgba(58, 153, 255, 0.1)',
    '其他(非客户)': 'rgba(245, 245, 248, 1)',
    已注销: 'rgba(245, 245, 248, 1)',
};
export {
    baseKeyLeftArray,
    baseKeyRightArray,
    colorMap,
    bgColorMap,
    businessKeyLeftArray,
    businessKeyRightArray,
};
