import React, { useMemo, useState } from 'react';
import { Table } from 'antd';
import styles from './index.module.scss';
import tipImg from '../../../../static/quote_bond_tip.png';
import { ProductQuoteDetailType, QuoteEntity } from '../../type';
import { QouteHook } from '../../../GKProductPrice/PriceHook';
import { AssetType } from '../../../Transaction/type';
interface QuoteAllTableProps {
    data: ProductQuoteDetailType;
    radioType?: 'radio' | 'checkbox';
    selectedList: (selectedList: QuoteEntity[]) => void;
}
const QuoteAllTable = ({ data, selectedList, radioType = 'checkbox' }: QuoteAllTableProps) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(() => {
        return data.quote_pool?.filter((item) => item.is_selected).map((item) => item.isin!) ?? [];
    });
    const { columns } = QouteHook({
        type: data.asset_type.code,
        isSub: false,
    });
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys: React.Key[], selectedRows: QuoteEntity[]) => {
            setSelectedRowKeys(selectedRowKeys ?? []);
            let finalData = selectedRows.map((item) => {
                return (
                    data.selected_quotes?.find((finditem) => finditem.isin === item.isin) ?? item
                );
            });
            selectedList(finalData);
        },
        getCheckboxProps: (record: QuoteEntity) => ({
            disabled: data.status.code > 2,
            name: record.isin?.toString(),
        }),
    };
    const resultData = useMemo(() => {
        // let resultData = data.quote_pool?.sort((a, b) => {
        //     return parseFloat(b.coupon as string) - parseFloat(a.coupon as string);
        // });
        // return resultData;
        return data.quote_pool;
    }, [data.quote_pool]);
    return (
        <div className={styles.tableContainer}>
            <Table
                style={{ marginTop: '16px' }}
                columns={columns}
                dataSource={resultData}
                size="small"
                rowSelection={{
                    type: radioType,
                    fixed: true,
                    ...rowSelection,
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            if (data.status.code > 2) return;
                            if (radioType === 'radio') {
                                if (
                                    selectedRowKeys?.length > 0 &&
                                    record.isin === selectedRowKeys[0]
                                ) {
                                    return;
                                } else {
                                    rowSelection.onChange([record.isin!], [record]);
                                }
                                return;
                            }
                            if (selectedRowKeys?.includes(record.isin!)) {
                                rowSelection.onChange(
                                    selectedRowKeys?.filter((k) => k !== record.isin),
                                    selectedRowKeys
                                        ?.filter((k) => k !== record.isin)
                                        .map(
                                            (item) =>
                                                resultData?.find(
                                                    (findItem) => findItem.isin === item
                                                )!
                                        )
                                );
                            } else {
                                rowSelection.onChange(
                                    [...selectedRowKeys, record.isin!],
                                    [...selectedRowKeys, record.isin].map(
                                        (item) =>
                                            resultData?.find((findItem) => findItem.isin === item)!
                                    )
                                );
                            }
                        },
                    };
                }}
                sticky
                summary={() => (
                    <Table.Summary fixed="top">
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0} colSpan={columns.length + 1}>
                                <div className={styles.tips}>
                                    <img src={tipImg} alt="" />
                                    <div>
                                        {data.asset_type.code === AssetType.CorporateBond ||
                                        data.asset_type.code === AssetType.GovernmentBond
                                            ? '您可以调整下方列表选中产品，重新计算组合收益'
                                            : '只能选择一个推荐产品'}
                                    </div>
                                </div>
                            </Table.Summary.Cell>
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
                pagination={false}
                rowKey="isin"
                scroll={{ x: '100%' }}
            />
        </div>
    );
};

export default QuoteAllTable;
