export interface AuthorityMember {
    id: number;
    name: string;
    mobile: string;
    roleIds: number[] | null;
}

export type AuthorityMemberData = {
    id: number;
    name: string;
    members: AuthorityMember[];
}[];

export interface StrToNumObj {
    [key: string]: number;
}

interface NumToStrObj {
    [key: number]: string;
}

export default class AuthorityMemberControl {
    private data: AuthorityMemberData;

    constructor(data: AuthorityMemberData) {
        this.data = data;
    }

    /**
     * 因为两个方向的映射都会用到，所以这里建立两个object
     */
    getRolesData(): [StrToNumObj, NumToStrObj] {
        let nameToId: StrToNumObj = {},
            idToName: NumToStrObj = {};
        for (let data of this.data) {
            nameToId[data.name] = data.id;
            idToName[data.id] = data.name;
        }
        return [nameToId, idToName];
    }

    getMembersForRoleName(name: string): AuthorityMember[] {
        for (let data of this.data) {
            if (data.name === name) {
                return data.members;
            }
        }
        return [];
    }
}
