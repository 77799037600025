import React from 'react';
import GlobalIndex from './GlobalIndex';
import Overview from './Overview';
import Customer from './Customer';
import TradeData from './TradeData';
import AppData from './AppData';
import ProductData from './ProductData';

const Dashboard = () => {
    return (
        <>
            <GlobalIndex />
            <Overview />
            <Customer />
            <TradeData />
            <ProductData />
            <AppData />
        </>
    );
};

export default Dashboard;
