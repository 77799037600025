import NotifyType from './NotifyType';
import MsgTemplate from './MsgTemplate';
import NotifyNode from './NotifyNode';
import NotifyModel from './NotifyModel';

const NotifyCenterPages = {
    '/app/notifyCenter/notifyType': NotifyType,
    '/app/notifyCenter/msgTemplate': MsgTemplate,
    '/app/notifyCenter/notifyNode': NotifyNode,
    '/app/notifyCenter/notifyModel': NotifyModel,
};

export default NotifyCenterPages;
