import { ReactElement } from 'react';
import { CustomerManageData } from '../../../../service/requests/CustomerManage';

export enum CustomerDetailCardType {
    Info = 'info',
    Record = 'record',
    Activity = 'activity',
    Asset = 'asset',
    Position = 'position',
    TranscationRecord = 'transcationRecord',
}
export interface CustomerDetailCardProps {
    customer_id: number;
}
export type CustomerData = CustomerManageData['pageData'][number];
const dangerMap: {
    [key: string]: string;
} = {
    中风险: '#EFA800',
    高风险: '#ED3B00',
    低风险: '#389E0D',
};
export type CustomerElementType = {
    title: string;
    right: any;
    content: any;
    action: ReactElement[];
    key: CustomerDetailCardType;
};
const initCardType = new Map([
    [
        CustomerDetailCardType.Asset,
        {
            showError: false,
            refresh: () => {},
        },
    ],
    [
        CustomerDetailCardType.Position,
        {
            showError: false,
            refresh: () => {},
        },
    ],
    [
        CustomerDetailCardType.TranscationRecord,
        {
            showError: false,
            refresh: () => {},
        },
    ],
    [
        CustomerDetailCardType.Activity,
        {
            showError: false,
            refresh: () => {},
        },
    ],
    [
        CustomerDetailCardType.Record,
        {
            showError: false,
            refresh: () => {},
        },
    ],
    [
        CustomerDetailCardType.Info,
        {
            showError: false,
            refresh: () => {},
        },
    ],
]);
export { dangerMap, initCardType };
