import style from './index.module.scss';
import React, { useContext } from 'react';
import { CustomerManageData, RMICInfo } from '../../../../service/requests/CustomerManage';
import { HidableCell } from '../../CommonColumnDef';
import { LinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { stopPropagation } from '../../../RecommendationComponent/util';
import { bgColorMap, colorMap } from '../ConstValue';
import RMAvatar from '../RMAvatar';
import { useMemo } from 'react';
import { MobileStateContext } from '../../../../App';

const UserCard = ({
    data,
    onSelect,
    isSelect,
    searchChild,
    showDetail,
}: {
    data: CustomerManageData['pageData'][number];
    onSelect: () => void;
    isSelect: boolean;
    searchChild: (id: number) => void;
    showDetail: boolean;
}) => {
    const relativeId = useMemo(() => {
        if (data.relativeCustomerId) {
            return data.relativeCustomerId;
        }
        if (data.parentCustomerId) {
            return data.parentCustomerId;
        }
        return null;
    }, [data]);
    const mobileState = useContext(MobileStateContext);
    const traders = useMemo(() => {
        let traders: CustomerManageData['pageData'][number]['rms'] = [];
        if (data.bankAccounts) {
            data.bankAccounts.forEach((item) => {
                if (item.traders) {
                    traders = traders.concat(item.traders);
                }
            });
        }
        //去除重复
        const map = new Map();
        traders.forEach((item) => {
            if (!map.has(item.id)) {
                map.set(item.id, item);
            }
        });
        return Array.from(map.values());
    }, [data]);
    return (
        <div className={`${style.card} ${isSelect ? style.select : ''}`} onClick={onSelect}>
            <div className={style.id}>
                <div className={style.text}>{data.id}</div>
                <div
                    className={style.label}
                    style={{ color: colorMap[data.state], background: bgColorMap[data.state] }}
                >
                    {data.state}
                </div>
            </div>
            <div className={style.line} />
            <div className={style.content}>
                <div className={style.top}>
                    <img src={data.avatar} alt="" className={style.icon} />
                    <div className={style.name}>
                        {data.nickName}
                        {data.remarkName?.length > 0 && <span>{`(${data.remarkName})`}</span>}
                    </div>
                    {data.last_login_in_time_info?.length > 0 && !showDetail && (
                        <div className={style.time}>{data.last_login_in_time_info}</div>
                    )}
                </div>
                <div className={style.mid}>
                    <HidableCell value={data.mobile} hideIndexStart={6} />
                    {relativeId && !showDetail && (
                        <RelativeComponent
                            showDetail={showDetail}
                            isChild={data.parentCustomerId === null}
                            searchChild={searchChild}
                            relativeCustomerId={relativeId}
                        />
                    )}
                </div>
                {mobileState.IsMobileMachine === false && !showDetail && (
                    <div className={style.rmic}>
                        {data.rms?.length > 0 && (
                            <Avatars title="RM:" list={data.rms} showDetail={showDetail} />
                        )}
                        {data.ics?.length > 0 && (
                            <Avatars title="IC:" list={data.ics} showDetail={showDetail} />
                        )}
                        {traders.length > 0 && (
                            <Avatars title="交易员:" list={traders} showDetail={showDetail} />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
export const Avatars = ({
    list,
    showDetail,
    title,
}: {
    title: string;
    list: RMICInfo[];
    showDetail: boolean;
}) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {title}
            {Array.from(
                list.map((item) => (
                    <RMAvatar
                        key={item.id}
                        data={{ name: item.name, faceUrl: item.feishuAvatar }}
                        showName={!showDetail}
                    />
                ))
            )}
        </div>
    );
};
const RelativeComponent = ({
    isChild,
    searchChild,
    relativeCustomerId,
    showDetail,
}: {
    isChild: boolean;
    searchChild: (id: number) => void;
    relativeCustomerId: number;
    showDetail: boolean;
}) => {
    return (
        <>
            <div className={style.line} />
            <div className={`${style.icon} ${!isChild ? style.main : style.sub}`}>
                {!isChild ? '副' : '主'}
            </div>
            <Button
                type="link"
                onClick={(event: any) => {
                    stopPropagation(event);
                    searchChild(relativeCustomerId);
                }}
            >
                {<span>关联{isChild ? '副' : '主'}账号：</span>}
                {relativeCustomerId}
                <LinkOutlined />
            </Button>
        </>
    );
};
export default UserCard;
