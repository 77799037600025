import React, { useMemo, useState } from 'react';
import { Button, Collapse, Space, Table, Modal } from 'antd';
import PanelHeader from './PanelHeader';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AssetAllocPlan } from '../../../service/type';
import { deleteAssetAllocplanPosition } from '../../../service';
import { showError, stopPropagation } from '../../RecommendationComponent/util';
import { useCallback } from 'react';

const { confirm } = Modal;
const { Panel } = Collapse;

const PanelComponent: React.FC<{
    setRefreshList: () => void;
    packageValue: AssetAllocPlan['packages'][0];
    setEditingProduction: (production: string) => void;
}> = ({ setRefreshList, packageValue, setEditingProduction }) => {
    const columns = useMemo(() => {
        return [
            {
                title: '产品名',
                dataIndex: 'name',
            },
            {
                title: '市值',
                dataIndex: 'hold_value',
            },
            {
                title: '钱包占比',
                dataIndex: 'pkg_percent',
            },
            {
                title: '占比',
                dataIndex: 'plan_percent',
            },
            {
                title: '操作',
                key: 'action',
                width: 420,
                render: ({ position_id }: { position_id: string }) => (
                    <Space size="middle">
                        <Button
                            type="primary"
                            onClick={() => {
                                setEditingProduction(position_id);
                            }}
                        >
                            编辑
                        </Button>
                        <Button
                            type="primary"
                            danger
                            onClick={() => {
                                confirm({
                                    title: '确认删除?',
                                    icon: <ExclamationCircleOutlined />,
                                    onOk() {
                                        deleteAssetAllocplanPosition({
                                            position_id,
                                        }).then(setRefreshList, showError);
                                    },
                                });
                            }}
                        >
                            删除
                        </Button>
                    </Space>
                ),
            },
        ];
    }, [setEditingProduction, setRefreshList]);
    const [activeKey, setActiveKey] = useState<string[]>([]);
    const setActive = useCallback(() => {
        setActiveKey(['1']);
    }, []);
    return (
        <Collapse
            className="Asset-Allocation-Plan-Panel"
            style={{ marginTop: 18 }}
            activeKey={activeKey}
            onChange={(target) =>
                Array.isArray(target) ? setActiveKey(target) : setActiveKey([target])
            }
        >
            <Panel
                header={
                    <PanelHeader
                        onClick={stopPropagation}
                        packageValue={packageValue}
                        setRefreshList={setRefreshList}
                        setActive={setActive}
                    />
                }
                key={'1'}
            >
                <Table
                    columns={columns}
                    dataSource={packageValue.positions}
                    pagination={false}
                    rowKey="isin"
                    scroll={{ x: true }}
                />
            </Panel>
        </Collapse>
    );
};

export default PanelComponent;
