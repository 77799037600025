import moment from 'moment';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { CustomerActivityData, QueryActivity } from '../../../../service/requests/CustomerManage';
import style from './index.module.scss';
import { CustomerDetailCardProps } from '../CustomerDetail/type';
import { useDispatch } from 'react-redux';
import { setActivityModel } from '../../../../store/actions/actions';
import { showError as showErrorToast } from '../../../RecommendationComponent/util';
import { Button, Result } from 'antd';
let urlPrefix = {
    dev: 'https://baike-test.ilookcap.com/#/pages/detail/detail?id=',
    stage: 'https://baike-stage.ilookcap.com/#/pages/detail/detail?id=',
    production: 'https://baike-h5.ilookcap.com/#/pages/detail/detail?id=',
}[process.env.REACT_ADMIN_ENV ?? 'production'];
const DocItem = ({ time, link, title }: { time: string; title: string; link: string }) => {
    return (
        <a className={style.doc} href={`${urlPrefix}${link}`} target={'_blank'}>
            <span className={style.time}>{time}</span>
            <span className={style.docname}>{title}</span>
        </a>
    );
};

const CustomerActivity = ({ customer_id }: CustomerDetailCardProps) => {
    const [data, setData] = useState<CustomerActivityData['customer_dynamics']>();
    const dispatch = useDispatch();
    const [showError, setShowError] = useState(false);
    const request = useCallback(() => {
        QueryActivity({ customer_id: customer_id })
            .then((e) => {
                setData(e.data.customer_dynamics);
                setShowError(false);
                dispatch(setActivityModel(e.data.customer_dynamics));
            })
            .catch((e) => {
                showErrorToast(e.message);
                setShowError(true);
            });
    }, [customer_id, dispatch]);
    useEffect(() => {
        request();
    }, [customer_id, request]);
    const array = useMemo<ReactElement[]>(() => {
        if (data && data.otc_valid_position_stat) {
            return Object.keys(data.otc_valid_position_stat ?? {})
                .filter((item) => (data.otc_valid_position_stat[item] ?? 0) > 0)
                .map((item) => (
                    <div className={style.item} key={item}>
                        {item}：<span>{data.otc_valid_position_stat[item]}</span>
                    </div>
                ));
        }
        return [];
    }, [data]);
    return (
        <>
            {showError && (
                <Result
                    status="error"
                    title="加载失败，请点击重试"
                    extra={
                        <Button
                            type="primary"
                            onClick={() => {
                                request();
                            }}
                        >
                            重试
                        </Button>
                    }
                />
            )}
            {!showError && (
                <div className={style.content}>
                    <div className={style.top}>
                        <div className={style.left}>
                            <div className={style.title}>基本动态</div>
                            <div className={style.itemContent}>
                                <div className={style.item}>
                                    7日内登录次数：
                                    <span>{data?.online_days_in_week}</span>
                                </div>
                                <div className={style.item}>
                                    7日内成交次数：<span>{data?.deal_volume_in_week}</span>
                                </div>
                                <div className={style.item}>
                                    30日内登录次数：<span>{data?.online_days_in_month}</span>
                                </div>
                                <div className={style.item}>
                                    7日内下单次数：<span>{data?.order_volume_in_week}</span>
                                </div>
                            </div>
                        </div>
                        <div className={style.left}>
                            <div className={style.title}>执行中产品</div>

                            <div className={style.itemContent}>{array}</div>
                        </div>
                    </div>
                    <div className={style.bottom}>
                        <div className={style.title}>最近查看文章</div>
                        <div className={style.docContent}>
                            {(data?.latest_read_docs ?? []).map((item, index) => {
                                return (
                                    <DocItem
                                        time={moment(item.read_at).format('YYYY.MM.DD')}
                                        title={item.doc_title}
                                        link={item.doc_id}
                                        key={index}
                                    />
                                );
                            })}
                            <div className={style.line} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default CustomerActivity;
