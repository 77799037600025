import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, message, Modal } from 'antd';

import { AxiosResponse } from 'axios';
import React, { useCallback, useState } from 'react';
import { addAuthorityRole, deleteAuthorityRole, editAuthorityRole } from '../../service';
import { StrToNumObj } from './AuthorityMemberControl';
const { confirm } = Modal;
interface AddRoleControlProps {
    onSuccess: (res: AxiosResponse) => void;
    roles: StrToNumObj;
}

export default function AuthorityAddRoleControl(props: AddRoleControlProps) {
    const { onSuccess, roles } = props;
    const [roleName, setRoleName] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isAdd, setIsAdd] = useState(true);
    const [currentName, setCurrentName] = useState<{ name: string; id: number }>();
    const changeHandle = useCallback(() => {
        setIsSubmitting(true);
        if (isAdd) {
            addAuthorityRole({ name: roleName })
                .then((res) => {
                    onSuccess(res);
                    message.success('角色创建成功');
                    setShowModal(false);
                })
                .catch(() => {
                    message.error('角色创建失败');
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        } else {
            editAuthorityRole({ name: roleName, id: currentName!.id })
                .then((res) => {
                    onSuccess(res);
                    message.success('角色修改成功');
                    setShowModal(false);
                })
                .catch(() => {
                    message.error('角色修改失败');
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }
    }, [isAdd, currentName, roleName, onSuccess]);

    return (
        <>
            <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => {
                    setShowModal(true);
                    setIsAdd(true);
                }}
            >
                新建角色
            </Button>
            <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => {
                    setShowEditModal(true);
                    setIsAdd(false);
                }}
            >
                编辑角色
            </Button>
            {showModal && (
                <Modal
                    visible={showModal}
                    title={isAdd ? '新建角色' : `修改(${currentName?.name})`}
                    forceRender
                    onCancel={() => setShowModal(false)}
                    onOk={changeHandle}
                    confirmLoading={isSubmitting}
                >
                    <Input
                        placeholder={isAdd ? '输入角色名' : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setRoleName(e.target.value)
                        }
                        defaultValue={currentName?.name}
                    />
                </Modal>
            )}
            <Modal
                visible={showEditModal}
                title="编辑角色"
                onCancel={() => setShowEditModal(false)}
                onOk={() => {}}
                confirmLoading={isSubmitting}
            >
                {Object.keys(roles).map((item) => {
                    return (
                        <div style={{ display: 'flex', padding: '4px' }} key={roles[item]}>
                            {item}
                            <Button
                                type="primary"
                                style={{ marginLeft: 'auto' }}
                                onClick={() => {
                                    setShowEditModal(false);
                                    setShowModal(true);
                                    setCurrentName({ name: item, id: roles[item] });
                                }}
                            >
                                修改
                            </Button>
                            <Button
                                type="primary"
                                danger
                                onClick={() => {
                                    confirm({
                                        title: `确认删除${item}角色?`,
                                        icon: <ExclamationCircleOutlined />,
                                        onOk() {
                                            deleteAuthorityRole({ id: roles[item] })
                                                .then((res) => {
                                                    onSuccess(res);
                                                    message.success('角色删除成功');
                                                    setShowModal(false);
                                                })
                                                .catch(() => {
                                                    message.error('角色删除失败');
                                                })
                                                .finally(() => {
                                                    setIsSubmitting(false);
                                                });
                                        },
                                    });
                                }}
                            >
                                删除
                            </Button>
                        </div>
                    );
                })}
            </Modal>
        </>
    );
}
