import { Button, DatePicker, Form, Input, Modal, Radio, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import {
    SyncArticleListType,
    selectActiclePushHistory,
    SyncArtclePushHistory,
    createPush,
} from './type';
import { InfoCircleFilled } from '@ant-design/icons';
import styles from './index.module.scss';
import moment from 'moment-timezone';
import { useLatest } from 'ahooks';
import { useForm } from 'antd/lib/form/Form';
import { GinkgoInput } from '../Transaction/components/TransForm/Forms/FormItems/GinkgoFieldItems';
const SyncArticlePush = ({
    setVisible,
    article,
}: {
    setVisible: (visible: boolean) => void;
    article: SyncArticleListType;
}) => {
    const [form] = useForm();
    const [pushLoading, setPushLoading] = useState(false);
    const [pushList, setPushList] = useState<SyncArtclePushHistory['push_histories']>([]);
    const [hasSendMe, setHasSendMe] = useState(false);
    useEffect(() => {
        selectActiclePushHistory({
            doc_id: article.doc_id,
        }).then((value) => {
            if (value.data) {
                setPushList(value.data.push_histories);
            }
        });
    }, [article]);
    useEffect(() => {
        form.setFieldsValue({
            doc_title: article.doc_title,
            doc_url: article.doc_url,
        });
    }, [article, form]);
    const [pushOnce, setPushOnce] = useState<1 | 2>(1);
    const latestPushOnce = useLatest(pushOnce);
    const isSendMe = useRef<1 | 2>(1);
    const onFinish = (values: any) => {
        const { title, body, is_persistent, push_at } = values;
        setPushLoading(true);
        let uid = JSON.parse(localStorage.getItem('login') || '{}').uid;
        createPush({
            doc_id: article.doc_id,
            title,
            body,
            push_at: latestPushOnce.current === 1 || isSendMe.current === 2 ? 0 : push_at ?? 0,
            is_persistent,
            is_all_users: isSendMe.current,
            by_user_id: uid,
        })
            .then((value) => {
                if (isSendMe.current === 2) {
                    setHasSendMe(true);
                    message.success('预览成功');
                    return;
                }
                message.success('发送Push成功');
                setVisible(false);
            })
            .catch((err) => {
                message.error(err.msg);
            })
            .finally(() => {
                setPushLoading(false);
            });
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
            requiredMark={false}
            initialValues={{
                is_persistent: 1,
            }}
        >
            <Form.Item label="文章链接" name="doc_url">
                <GinkgoInput readOnly />
            </Form.Item>
            <Form.Item label="文章标题" name="doc_title">
                <GinkgoInput readOnly />
            </Form.Item>
            <Form.Item
                label="Push标题"
                name="title"
                rules={[{ required: true, message: '请输入Push标题' }]}
            >
                <Input placeholder="请输入Push标题" />
            </Form.Item>
            <Form.Item
                label="Push内容"
                name="body"
                rules={[
                    { required: true, message: '请输入Push内容' },
                    { max: 50, message: '最多输入50个字符' },
                ]}
            >
                <Input placeholder="请输入Push内容" />
            </Form.Item>
            <Form.Item name="push_at" hidden></Form.Item>
            <Form.Item
                // name="push_at"
                label="发送时间"
                extra="选择“立即发送”将在同步完成后立即发送，选择“定时发送”可选择发送时间"
            >
                <Radio.Group
                    value={pushOnce}
                    onChange={(e) => {
                        setPushOnce(e.target.value as 1 | 2);
                        // if (e.target.value === 2) {
                        //     form.setFieldsValue({
                        //         push_at: 2,
                        //     });
                        // }
                    }}
                >
                    <Radio value={1}>立即发送</Radio>
                    <Radio value={2}>定时发送</Radio>
                </Radio.Group>
            </Form.Item>
            {pushOnce === 2 && (
                <>
                    <DatePicker
                        defaultValue={moment()}
                        disabledDate={(momentN) => {
                            if (momentN.isBefore(moment())) {
                                return true;
                            }
                            return false;
                        }}
                        onChange={(moment) => {
                            if (moment) {
                                form.setFieldsValue({
                                    push_at: moment.valueOf(),
                                });
                            }
                        }}
                        allowClear={false}
                        inputReadOnly
                        showTime
                    />
                </>
            )}
            <Form.Item
                name="is_persistent"
                label="留存消息"
                extra="选择“是”后消息将在<App消息中心-系统通知>中留存"
            >
                <Radio.Group>
                    <Radio value={1}>是</Radio>
                    <Radio value={2}>否</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="Push 历史">
                {pushList.length === 0
                    ? '无'
                    : pushList.map((item) => {
                          return (
                              <div
                                  style={{
                                      display: 'flex',
                                  }}
                              >
                                  <div style={{ marginRight: '10px' }}>
                                      {moment(item.created_at).format('YYYY-MM-DD HH:mm')}
                                  </div>
                                  <div>{item.creator?.name}</div>
                              </div>
                          );
                      })}
            </Form.Item>
            <div style={{ textAlign: 'right' }}>
                <Button
                    type="primary"
                    loading={pushLoading}
                    onClick={() => {
                        isSendMe.current = 2;
                        form.submit();
                    }}
                >
                    预览
                </Button>
                <Button
                    type="primary"
                    loading={pushLoading}
                    onClick={() => {
                        const title = form.getFieldValue('title');
                        const body = form.getFieldValue('body');
                        Modal.confirm({
                            width: 500,
                            title: '这条push将推送给所有用户，点击确认发送后正式发送',
                            content: (
                                <div
                                    style={{ whiteSpace: 'pre-line' }}
                                >{`Push标题：${title}\nPush内容：${body}`}</div>
                            ),
                            cancelText: '取消',
                            okText: '确认发送',
                            onOk: () => {
                                isSendMe.current = 1;
                                form.submit();
                            },
                        });
                    }}
                >
                    发送Push
                </Button>
            </div>
            {hasSendMe && (
                <div className={styles.titleTipIcon} style={{ justifyContent: 'flex-end' }}>
                    <InfoCircleFilled />
                    已经发送预览
                </div>
            )}

            <div className={styles.titleTipIcon} style={{ justifyContent: 'flex-end' }}>
                <InfoCircleFilled />
                点击发送Push将发送给所有用户，预览将发送给自己进行测试
            </div>
        </Form>
    );
};

export default SyncArticlePush;
