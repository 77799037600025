import { Button, Image, Modal, Spin, Upload, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { UploadFile } from 'antd/lib/upload/interface';
import styles from './index.module.scss';
import ImgCrop from 'antd-img-crop';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import {
    CommonUploadFile,
    CommonUploadFileResponse,
    UploadFileType,
} from '../AssetManagement/BankFee/requests';
import { SyncPosterType, getPosterList } from './type';
import { EyeOutlined, SwapOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';

const ImagePickerModal = ({
    value,
    onChange,
}: {
    value?: string;
    onChange?: (value: string) => void;
}) => {
    const [uploadResponse, setUploadResponse] = useState<CommonUploadFileResponse>();
    const [posterList, setPosterList] = useState<SyncPosterType[]>([]);
    const [pdfStr, setPdfStr] = useState<{ url: string; name: string }>();
    const [previewImage, setPreviewImage] = useState('');
    const [previewSelect, setPreviewSelect] = useState(false);
    const [previewTitle, setPreviewTitle] = useState('');
    const [selectIndex, setSelectIndex] = useState<number>(0);
    const [showHover, setShowHover] = useState(false);
    const [uploadImgLoading, setUploadImgLoading] = useState(false);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    useEffect(() => {
        if (pdfStr) {
            setUploadImgLoading(true);
            CommonUploadFile({
                data_url: pdfStr.url,
                target_type: UploadFileType.DocPoster,
                file_name: pdfStr.name,
            })
                .then((res) => {
                    if (res.data) {
                        setUploadResponse(res.data);
                        setSelectIndex(0);
                        refreshPosterList();
                    }
                    setFileList((fileList) => {
                        return fileList.map((item) => {
                            if (item.status === 'uploading') {
                                return {
                                    ...item,
                                    status: 'done',
                                };
                            }
                            return item;
                        });
                    });
                })
                .catch((err) => {
                    message.error(err.msg);
                    setFileList((fileList) => {
                        return fileList.map((item) => {
                            if (item.status === 'uploading') {
                                return {
                                    ...item,
                                    status: 'error',
                                };
                            }
                            return item;
                        });
                    });
                })
                .finally(() => {
                    setUploadImgLoading(false);
                    setPdfStr(undefined);
                });
        }
    }, [onChange, pdfStr]);
    //     const handlePreview = async (file: UploadFile) => {
    //         if (!file.url && !file.preview) {
    //             file.preview = (await getBase64(file.originFileObj as RcFile)) as string;
    //         }

    //         setPreviewImage(file.url || (file.preview as string));
    //         setPreviewOpen(true);
    //         setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    //     };
    const parsePDF = useCallback((props: UploadRequestOption) => {
        const reader = new FileReader();
        reader.onload = () => {
            const data = reader.result as string;
            props.onSuccess?.(data);
        };
        reader.onerror = () => {
            props.onError?.(new Error('读取文件失败'));
        };
        if (typeof props.file !== 'string') {
            reader.readAsDataURL(props.file);
        }
    }, []);

    const uploadChange = useCallback((info: UploadChangeParam) => {
        if (info.fileList.length === 0) {
            setFileList([]);
        }
        if (info.fileList.length > 0) {
            let fileItem = info.fileList[0];
            setFileList([
                {
                    ...fileItem,
                    status: 'uploading',
                },
            ]);
        } else {
            setFileList([]);
        }
        if (info.file && info.file.status === 'done') {
            setPdfStr({
                name: info.file.name,
                url: info.file.response,
            });
        }
    }, []);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [selctImagesOpen, setSelctImagesOpen] = useState(false);
    const handleCancel = () => setPreviewOpen(false);
    useEffect(() => {
        if (value) {
            setFileList([
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: value,
                },
            ]);
        }
    }, [value]);
    const refreshPosterList = () => {
        getPosterList().then((value) => {
            setPosterList(value.data.posters);
        });
    };
    useEffect(() => {
        refreshPosterList();
    }, []);
    const handleConfirm = () => {
        if (posterList.length > 0) {
            onChange?.(posterList[selectIndex].poster_path);
            setSelctImagesOpen(false);
        }
    };
    return (
        <div>
            <Modal visible={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
            <Modal
                visible={selctImagesOpen}
                onCancel={() => setSelctImagesOpen(false)}
                width={1000}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <ImgCrop aspect={1.6} quality={0.8} showReset>
                            <Upload
                                // accept=".pdf"
                                onChange={uploadChange}
                                multiple={false}
                                maxCount={1}
                                showUploadList={false}
                                customRequest={parsePDF}
                                fileList={fileList}
                            >
                                <Button>+ 上传图片</Button>
                            </Upload>
                        </ImgCrop>
                        <Button type="primary" onClick={handleConfirm}>
                            确认
                        </Button>
                    </div>
                }
            >
                <Spin spinning={uploadImgLoading}>
                    <div
                        style={{
                            display: 'flex',
                            marginRight: '20px',
                            flexWrap: 'wrap',
                            height: '500px',
                            overflowY: 'auto',
                            gap: '10px',
                        }}
                    >
                        {posterList.map((item, index) => {
                            return (
                                <div
                                    style={{
                                        width: '175px',
                                        height: '155px',
                                        paddingTop: '10px',
                                        flexShrink: 0,
                                        flexGrow: 0,
                                        objectFit: 'cover',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        border: index === selectIndex ? '2px solid green' : 'none',
                                        backgroundColor:
                                            index === selectIndex ? '#DFFFDE' : 'transparent',
                                    }}
                                >
                                    <Image
                                        src={item.poster_path}
                                        onClick={() => setSelectIndex(index)}
                                        preview={false}
                                        style={{
                                            width: '160px',
                                            height: '100px',
                                            objectFit: 'cover',
                                        }}
                                    />
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            width: '155px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {item.name}
                                    </div>
                                    <div
                                        style={{
                                            textAlign: 'center',
                                            width: '155px',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            fontSize: '12px',
                                        }}
                                    >
                                        {moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Spin>
            </Modal>
            {value && (
                <div
                    style={{ position: 'relative', width: '160px', height: '100px' }}
                    onMouseEnter={() => {
                        setShowHover(true);
                    }}
                    onMouseLeave={() => {
                        setShowHover(false);
                    }}
                >
                    <Image
                        src={value}
                        preview={{
                            visible: previewSelect,
                            onVisibleChange: setPreviewSelect,
                        }}
                        style={{
                            objectFit: 'cover',
                            width: '160px',
                            height: '100px',
                        }}
                    />
                    {showHover && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '160px',
                                height: '100px',
                                backgroundColor: 'rgba(0,0,0,0.3)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <Button icon={<EyeOutlined />} onClick={() => setPreviewSelect(true)} />
                            <Button
                                icon={<SwapOutlined />}
                                onClick={() => {
                                    setSelctImagesOpen(true);
                                }}
                            />
                        </div>
                    )}
                </div>
            )}
            {!value && <Button onClick={() => setSelctImagesOpen(true)}> 选择图片</Button>}

            {uploadResponse && (
                <div className={`${styles.titleTip}`}>{`${uploadResponse.width} * ${
                    uploadResponse.height
                } ${(uploadResponse.size / 1024).toFixed(1)}KB`}</div>
            )}
        </div>
    );
};

export default ImagePickerModal;
