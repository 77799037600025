import React, { useCallback, useEffect, useState } from 'react';
import { Layout } from 'antd';
import ContentSwitch from './routes';
import { getAllowedMenuList } from './service';
import { showError } from './modules/RecommendationComponent/util';
import { AllowedMenuList } from './service/type.d';
import MenuContainer from './modules/Menu';
import './App.less';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
// import SwitchTranscationType from './modules/widget/SwitchTranscationType';
import LKBreadcrumb from './routes/LKBreadcrumb';
import Sider from 'antd/lib/layout/Sider';
import { LKHeader } from './modules/widget';
import { Header } from 'antd/lib/layout/layout';
import { hot } from 'react-hot-loader/root';
import './locale';
import moment from 'moment';

const { Content } = Layout;
const MENU_WIDTH = 200;
const BREAKPOINT_MOBILE = MENU_WIDTH + 800;
(function () {
    let running = false;
    let func = () => {
        if (running) return;
        running = true;
        requestAnimationFrame(() => {
            window.dispatchEvent(new CustomEvent('optimizedResize'));
            running = false;
        });
    };
    window.addEventListener('resize', func);
})();

const IsMobileInitialWidth = window.innerWidth < BREAKPOINT_MOBILE;

const IsMobileInitialMachine =
    navigator.userAgent.match(/Mobi/i) ||
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/iPhone/i)
        ? true
        : false;
type MobileState = {
    isMobile: boolean;
    IsMobileMachine: boolean;
};
export const MobileStateContext = React.createContext<MobileState>({
    IsMobileMachine: IsMobileInitialMachine,
    isMobile: IsMobileInitialWidth,
});

const App = () => {
    moment.updateLocale('en', {
        week: {
            dow: 1,
        },
    });
    const [isMobile, setIsMobile] = useState(IsMobileInitialWidth);
    const [showMenu, setShowMenu] = useState(!IsMobileInitialWidth);
    // const [bodyTranslateX, setBodyTranslateX] = useState(0);
    // const [bodyPaddingLeft, setBodyPaddingLeft] = useState(IsMobileInitialWidth ? 0 : 200);
    const handleWindowResize = useCallback(() => {
        const isMobileNow = window.innerWidth < BREAKPOINT_MOBILE;
        if (!isMobileNow || isMobileNow !== isMobile) {
            setShowMenu(!isMobileNow);
        }
        setIsMobile(isMobileNow);
    }, [isMobile]);

    useEffect(() => {
        window.addEventListener('optimizedResize', handleWindowResize);
        return () => window.removeEventListener('optimizedResize', handleWindowResize);
    }, [handleWindowResize]);

    // useEffect(() => {
    //     if (showMenu) {
    //         setBodyTranslateX(isMobile ? 200 : 0);
    //         setBodyPaddingLeft(isMobile ? 0 : 200);
    //     } else {
    //         setBodyTranslateX(0);
    //         setBodyPaddingLeft(0);
    //     }
    // }, [isMobile, showMenu]);

    const [allowedMenuList, setAllowedMenuList] = useState<AllowedMenuList>();

    //获取权限树
    useEffect(() => {
        getAllowedMenuList().then((res) => {
            let menus = res.data.filter((item) => item.display);
            setAllowedMenuList(menus || []);
        }, showError);
    }, []);
    const [collapsed, setCollapsed] = useState(false);

    return (
        <MobileStateContext.Provider
            value={{ IsMobileMachine: IsMobileInitialMachine, isMobile: isMobile }}
        >
            <Layout>
                <Header style={{ padding: 0, position: 'relative', zIndex: 1 }}>
                    <LKHeader visibleState={[showMenu, setShowMenu]} />
                </Header>
                <Layout
                    style={{
                        position: 'relative',
                        zIndex: 0,
                    }}
                >
                    <Sider
                        collapsible={!isMobile}
                        collapsed={collapsed}
                        onCollapse={(value) => setCollapsed(value)}
                        width={showMenu ? 200 : 0}
                    >
                        <MenuContainer
                            collapsedState={[collapsed, setCollapsed]}
                            allowedMenus={allowedMenuList}
                            onSelect={() => isMobile && setShowMenu(false)}
                        />
                    </Sider>

                    <Layout
                        style={{
                            transform: `translateX(0px)`,
                        }}
                    >
                        <LKBreadcrumb allowedMenuList={allowedMenuList} />

                        <Content
                            className="app_layout_content"
                            style={{
                                position: 'relative',
                                marginTop: '52px',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                            }}
                            id="app-content"
                        >
                            <ContentSwitch allowedMenuList={allowedMenuList} />
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </MobileStateContext.Provider>
    );
};

export default hot(App);
