import { red } from '@ant-design/colors';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Typography, Button, Modal, Tooltip, message } from 'antd';
import { Formik } from 'formik';
import { Form, Input, Select } from 'formik-antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useForceUpdate from '../../../utils/Hooks/useForceUpdate';
import { showError } from '../../RecommendationComponent/util';
import { getEventTypeList } from '../NotifyType/requests';
import { createMsgTemplate, getMsgTemplateList, updateMsgTemplate } from './requests';

export default function MsgTemplate() {
    const [showModal, setShowModal] = useState(false);

    const [eventTypeList, setEventTypeList] = useState<EventType[]>();
    const [rowData, setRowData] = useState<MsgTemplate[]>();
    const [curEditRow, setCurEditRow] = useState<MsgTemplate>();

    const modalTitle = useMemo(() => (curEditRow ? '编辑消息模版' : '添加消息模版'), [curEditRow]);

    const columns = useMemo<ColDef[]>(
        () => [
            { headerName: '事件类型', field: 'eventType.name' },
            { headerName: '通道类型', field: 'channel' },
            { headerName: '标题', field: 'title' },
            {
                headerName: '模版',
                field: 'content',
                flex: 1,
                cellRenderer: (params: ICellRendererParams) => (
                    <Tooltip title={params.value} placement="bottomLeft">
                        <span>{params.value}</span>
                    </Tooltip>
                ),
            },
            {
                headerName: '操作',
                cellRenderer: (params: ICellRendererParams) => (
                    <Button
                        type="primary"
                        onClick={() => {
                            setCurEditRow(params.data);
                            setShowModal(true);
                        }}
                    >
                        编辑
                    </Button>
                ),
            },
        ],
        []
    );

    const forceUpdate = useForceUpdate(() => {
        getEventTypeList().then((res) => setEventTypeList(res.data), showError);
        getMsgTemplateList().then((res) => setRowData(res.data), showError);
    }, []);

    useEffect(() => {
        if (!showModal) {
            setCurEditRow(undefined);
        }
    }, [showModal]);

    const handleFormValidate = useCallback(
        (values: any) => {
            let res: { [key: string]: string } = {};
            if (curEditRow) {
                if (!values.content) {
                    res.content = '不能为空';
                }
            } else {
                if (
                    rowData
                        ?.filter((row) => row.eventTypeId === values.eventTypeId)
                        .find((item) => item.channel === values.channel)
                ) {
                    res.eventTypeId = '同一事件类型和通道类型的组合下只能创建一个模版';
                    res.channel = '同一事件类型和通道类型的组合下只能创建一个模版';
                }
                for (let key of ['eventTypeId', 'channel', 'content']) {
                    if (!values[key]) {
                        res[key] = '不能为空';
                    }
                }
            }
            return res;
        },
        [curEditRow, rowData]
    );

    const handleFormFinish = useCallback(
        (values: any) => {
            if (curEditRow) {
                console.log(values);
                updateMsgTemplate(values)
                    .then(() => {
                        message.success('更新成功');
                        forceUpdate();
                    }, showError)
                    .finally(() => setShowModal(false));
            } else {
                createMsgTemplate(values)
                    .then(() => {
                        message.success('创建成功');
                        forceUpdate();
                    }, showError)
                    .finally(() => setShowModal(false));
            }
        },
        [curEditRow, forceUpdate]
    );

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                <Typography.Title level={4} style={{ margin: 0 }}>
                    消息模版
                </Typography.Title>
                <Button
                    type="primary"
                    style={{ marginLeft: 16 }}
                    onClick={() => setShowModal(true)}
                >
                    添加
                </Button>
            </div>
            <div style={{ marginTop: '16px', color: red[6] }}>
                *同一事件类型和通道类型的组合下只能创建一个模版
            </div>
            <div className="ag-theme-alpine" style={{ marginTop: 16 }}>
                <AgGridReact
                    columnDefs={columns}
                    rowData={rowData}
                    domLayout="autoHeight"
                    enableCellTextSelection
                />
            </div>
            <Modal
                title={modalTitle}
                visible={showModal}
                footer={null}
                onCancel={() => setShowModal(false)}
                destroyOnClose
            >
                <Formik
                    initialValues={curEditRow ?? { title: '', channel: '' }}
                    validate={handleFormValidate}
                    onSubmit={handleFormFinish}
                >
                    <Form labelCol={{ span: 4 }}>
                        {curEditRow === undefined && (
                            <>
                                <Form.Item label="事件类型" name="eventTypeId" required>
                                    <Select name="eventTypeId">
                                        {eventTypeList?.map((eventType) => (
                                            <Select.Option key={eventType.id} value={eventType.id}>
                                                {eventType.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item label="通道类型" name="channel" required>
                                    <Select name="channel">
                                        <Select.Option value="Feishu">Feishu</Select.Option>
                                        <Select.Option value="Push">Push</Select.Option>
                                        <Select.Option value="VoiceCall">VoiceCall</Select.Option>
                                    </Select>
                                </Form.Item>
                            </>
                        )}
                        <Form.Item label="标题" name="title">
                            <Input name="title" />
                        </Form.Item>
                        <Form.Item label="模版" name="content" required>
                            <Input.TextArea name="content" rows={4} />
                        </Form.Item>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin: '8px 0',
                            }}
                        >
                            <Button onClick={() => setShowModal(false)}>取消</Button>
                            <Button type="primary" htmlType="submit" style={{ marginLeft: 64 }}>
                                提交
                            </Button>
                        </div>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
}
