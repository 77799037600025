import { Button } from 'antd';
import React from 'react';
import { QouteStatus } from '../../type';
import { EventList, uploadEventTrack } from '../../../../service/requests/EventTrack';
interface BondQuoteDetailActionsProps {
    status: QouteStatus;
    save?: () => void;
    cancel?: () => void;
    asyncApp?: () => void;
    revoke?: () => void;
    sendEmail?: () => void;
}
const BondQuoteDetailActions = (parmas: BondQuoteDetailActionsProps) => {
    const upload = (text: string) => {
        uploadEventTrack(EventList.BondQouteDetailClick, 2, { title: text });
    };
    const getBtns = () => {
        if (parmas.status < 3) {
            return (
                <>
                    <Button
                        id="Quote_Button_Save"
                        onClick={() => {
                            upload('保存');
                            parmas.save && parmas.save();
                        }}
                        type="primary"
                    >
                        保存
                    </Button>
                    <Button
                        id="Quote_Button_Cancel"
                        onClick={() => {
                            upload('取消');
                            parmas.cancel && parmas.cancel();
                        }}
                        type="text"
                    >
                        取消
                    </Button>
                    <Button
                        id="Quote_Button_Sync"
                        onClick={() => {
                            upload('同步');
                            parmas.asyncApp && parmas.asyncApp();
                        }}
                        type="primary"
                    >
                        同步到APP
                    </Button>
                    <Button
                        id="Quote_Button_Delete"
                        onClick={() => {
                            upload('撤销');
                            parmas.revoke && parmas.revoke();
                        }}
                        style={{ marginLeft: '80px' }}
                        type="primary"
                        danger
                    >
                        撤销
                    </Button>
                </>
            );
        } else if (parmas.status === 4) {
            return (
                <>
                    {/* <Button
                        onClick={() => {
                            upload('发送下单邮件');
                            parmas.sendEmail && parmas.sendEmail();
                        }}
                        type="primary"
                    >
                        发送下单邮件
                    </Button> */}
                    <Button
                        id="Quote_Button_Delete"
                        onClick={() => {
                            upload('撤销');
                            parmas.revoke && parmas.revoke();
                        }}
                        style={{ marginLeft: '80px' }}
                        type="primary"
                        danger
                    >
                        撤销
                    </Button>
                </>
            );
        } else {
            return <Button disabled>已撤销</Button>;
        }
    };
    return <div>{getBtns()}</div>;
};

export default BondQuoteDetailActions;
