import { useDispatch, useSelector } from 'react-redux';
import { setPageState } from '../../store/actions/module/page_data';
import { LKRootState } from '../../store/store';
import { PageState } from '../../store/type';

const usePageState = () => {
    const pathName = window.location.hash.replace('#', '').replace(/\//g, '');
    const selector: PageState = useSelector((state: LKRootState) => {
        return state.pageState[pathName];
    });
    const realDispatch = useDispatch();
    const dispacth = (pageState: PageState) => {
        realDispatch(setPageState(pathName, pageState));
    };
    return { dispacth, selector };
};
export default usePageState;
