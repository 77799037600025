import React, { useState } from 'react';
import styles from './Customer.module.scss';
import { Col, Row } from 'antd';
import BarChart from '../Components/BarChart';
import useFetch from '../../../utils/Hooks/useFetch';
import { QuestionId, getDashboardData } from '../../../service/requests/Dashboard';
import moment from 'moment';
import TableChart from '../Components/TableChart';
import { ColumnsType } from 'antd/es/table/Table';

const Customer = () => {
    const [period, setPeriod] = useState(30);
    const [fetchState1] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.CustomersCount,
        },
    });
    const [fetchState2] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.CustomersState,
        },
    });
    const [fetchState3, setParams3] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.NewCustomers,
            question_params: {
                days: period,
            },
        },
    });
    const columns: ColumnsType = [
        {
            title: '用户名',
            dataIndex: 'name',
        },
        {
            title: 'ID',
            dataIndex: 'id',
        },
        { title: '手机号', dataIndex: 'mobile' },
        { title: '状态', dataIndex: 'state' },
        { title: '开户时间', dataIndex: 'uts' },
    ];
    const keys = ['潜客池', '开户中', '已入金'];
    const getData = (params: { state: string; count: number }[]) => {
        let ret: number[] = [];
        keys.forEach((k) => {
            if (k === '已入金') {
                k = '入金完成';
            }
            let arr = params.filter((i) => i.state === k);
            if (arr.length > 0) {
                ret.push(arr[0].count);
            }
        });
        return ret;
    };
    const onPeriodChange = (num: number) => {
        setPeriod(num);
        setParams3((value) => {
            return {
                ...value,
                question_params: {
                    days: num,
                },
            };
        });
    };
    return (
        <>
            <div className={styles.title}>客户数据</div>
            <Row gutter={16}>
                <Col span={8}>
                    {fetchState1.data && fetchState2.data && (
                        <BarChart
                            title="客户总数"
                            time={`更新时间 ${moment(fetchState1.data[0]['data_date']).format(
                                'yyyy-MM-DD HH:mm'
                            )}`}
                            count={fetchState1.data[0]['total_customer']}
                            unit="人"
                            keys={keys}
                            data={getData(fetchState2.data)}
                        />
                    )}
                </Col>
                <Col span={16}>
                    {fetchState3.data && (
                        <TableChart
                            title="新增开户"
                            time={`${moment()
                                .subtract(period, 'days')
                                .format('yyyy.MM.DD')} - ${moment().format('yyyy.MM.DD')}`}
                            count={fetchState3.data.length}
                            unit="人"
                            period={period}
                            onPeriodChange={onPeriodChange}
                            dataSource={fetchState3.data}
                            columns={columns}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};

export default Customer;
