import React, { useEffect, useState } from 'react';
import { Button, Col, Divider, Modal, Row, Table, message } from 'antd';
import styles from './index.module.less';
import TransactionBriefDetails from '../components/BriefDetails';
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    DeleteOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { v4 as uuid } from 'uuid';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useRequest } from 'ahooks';
import { getEmailContent, getEmailsByBankId, getTransactionsByIds, sendEmail } from '../request';
import {
    EmailAddress,
    SendEmailReq,
    TransTransferExtInfo,
    TransactionInfo,
    TransactionType,
} from '../type';
import { getTransBirefDetails } from '../Detail/utils';
import TransferAccountTable from '../components/TransferAccountTable';
import AssetTable from '../components/AssetTable';

interface TransOrderType {
    id: number;
    type: { code: number; desc: string };
    briefDetails: { title: string; value: string }[];
}
const TransactionEmail = () => {
    const location = useLocation();
    const history = useHistory();
    const { trans_id, bank_id } = qs.parse(location.search) as {
        trans_id: string;
        bank_id: string;
    };
    const [showModal, setShowModal] = useState(false);
    const [transList, setTransList] = useState<Array<TransOrderType>>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [from, setFrom] = useState('');
    const [to, setTo] = useState<string>();
    const [cc, setCc] = useState<string>();
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [curField, setCurField] = useState<'to' | 'cc'>();

    const { data: transData } = useRequest(getTransactionsByIds, {
        defaultParams: [
            {
                trans_ids: trans_id.split('_').map((i) => parseInt(i)),
            },
        ],
    });
    const { data: emailData } = useRequest(getEmailsByBankId, {
        defaultParams: [
            {
                bank_id,
                mail_type: 1,
            },
        ],
    });
    const { data: emailcontentData, run: getContent } = useRequest(getEmailContent, {
        manual: true,
    });
    useEffect(() => {
        if (transList.length > 0) {
            getContent({ trans_ids: transList.map((t) => t.id), mail_type: 1 });
        }
    }, [transList, getContent]);
    useEffect(() => {
        if (transData) {
            const temp = transData.data.transactions.map((t) => {
                return {
                    id: t.id,
                    type: t.type,
                    briefDetails: getTransBirefDetails(t),
                } as TransOrderType;
            });
            setTransList(temp);
        }
    }, [transData]);
    useEffect(() => {
        if (emailcontentData?.data) {
            const content = emailcontentData?.data.mail_content;
            setFrom(content.from);
            setTo((pre) => {
                return Array.from(
                    new Set((pre ? pre.split(',') : []).concat(content.to || []))
                ).toString();
            });
            setCc((pre) => {
                return Array.from(
                    new Set((pre ? pre.split(',') : []).concat(content.cc || []))
                ).toString();
            });
            setSubject(content.subject);
            setBody(content.body.replaceAll('\\n', '\n'));
        }
    }, [emailcontentData]);

    const columns: ColumnsType<EmailAddress> = [
        {
            title: 'Email',
            dataIndex: 'address',
        },
        {
            title: '',
            dataIndex: 'description',
        },
    ];
    const onSelecteChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const onModalOkClick = () => {
        if (curField === 'to') {
            selectedRowKeys.length > 0
                ? setTo(
                      (emailcontentData?.data.mail_content.to || [])
                          .concat(selectedRowKeys as string[])
                          .toString()
                  )
                : setTo(emailcontentData?.data.mail_content.to?.toString() || undefined);
        } else {
            selectedRowKeys.length > 0
                ? setCc(
                      (emailcontentData?.data.mail_content.cc || [])
                          .concat(selectedRowKeys as string[])
                          .toString()
                  )
                : setCc(emailcontentData?.data.mail_content.cc?.toString() || undefined);
        }
        setShowModal(false);
    };
    const onMoveClick = (idx: number, direction: 'up' | 'down') => {
        let newIdx = idx;
        direction === 'up' ? newIdx-- : newIdx++;
        let newArr = [...transList];
        let temp = newArr[newIdx];
        newArr[newIdx] = newArr[idx];
        newArr[idx] = temp;
        setTransList(newArr);
    };
    const onDeleteClick = (idx: number) => {
        Modal.confirm({
            content: '确认从该页面删除此条交易记录吗？',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                let newArr = [...transList];
                newArr.splice(idx, 1);
                if (newArr.length === 0) {
                    history.push(`/app/trade/transaction`);
                }
                setTransList(newArr);
            },
        });
    };
    const onEmailBtnClick = () => {
        Modal.confirm({
            content: 'confirm to send email?',
            onOk: async () => {
                const param: SendEmailReq = {
                    mail_type: 1,
                    from,
                    to: to!.split(','),
                    cc: cc!.split(','),
                    subject,
                    body,
                    body_type: 'TEXT',
                    trans_ids: transList.map((t) => t.id),
                };
                return sendEmail(param).then(
                    () => {
                        message.success({
                            content: 'Send email successfully.',
                        });
                        setTimeout(() => {
                            history.push(`/app/trade/transaction`);
                        }, 500);
                    },
                    () => {
                        // Modal.error({
                        //     content: 'Connection timeout, please try again.',
                        //     closable: false,
                        //     okType: 'primary',
                        //     onOk: () => {
                        //         onEmailBtnClick();
                        //     },
                        // });
                        message.error({
                            content: 'Connection timeout, please try again.',
                        });
                    }
                );
            },
        });
    };
    const renderTransferArea = (transactionItem: TransactionInfo) => {
        if (transactionItem?.type.code === TransactionType['Transfer']) {
            const {
                in_account_bank,
                in_account_name,
                in_account_no,
                out_account_bank,
                out_account_name,
                out_account_no,
            } = transactionItem.ext_info as TransTransferExtInfo;
            const from = {
                accountBank: out_account_bank || '',
                accountName: out_account_name || '',
                accountNo: out_account_no || '',
            };
            const to = {
                accountBank: in_account_bank || '',
                accountName: in_account_name || '',
                accountNo: in_account_no || '',
            };
            return (
                <>
                    <TransferAccountTable from={from} to={to} />
                    <AssetTable data={transactionItem.assets} />
                </>
            );
        }
    };
    return (
        <>
            <div className={styles.header}>{`${
                transData?.data.transactions[0].account.bank_name || ''
            } ${transData?.data.transactions[0].client.name || ''}`}</div>
            <Divider />
            <div className={styles.orders}>
                {transList.map((i, idx) => (
                    <div key={uuid()}>
                        <div className={styles.orderHeader}>
                            <Row>
                                <Col span={4}>
                                    <span className={styles.orderIdx}>{`order${idx + 1}`}</span>
                                </Col>
                                <Col span={4}>
                                    <span className={styles.type}>{i!.type.desc}</span>
                                </Col>
                                <Col span={4}>
                                    <span className={styles.action}>
                                        <span
                                            className={styles.actionBtn}
                                            onClick={() => {
                                                onMoveClick(idx, 'up');
                                            }}
                                        >
                                            {idx !== 0 && <ArrowUpOutlined />}
                                        </span>
                                        <span
                                            className={styles.actionBtn}
                                            onClick={() => {
                                                onMoveClick(idx, 'down');
                                            }}
                                        >
                                            {idx !== transList.length - 1 && <ArrowDownOutlined />}
                                        </span>
                                        <span
                                            className={styles.actionBtn}
                                            onClick={() => {
                                                onDeleteClick(idx);
                                            }}
                                        >
                                            <DeleteOutlined />
                                        </span>
                                    </span>
                                </Col>
                            </Row>
                        </div>
                        <TransactionBriefDetails data={i?.briefDetails!} />
                        <div className={styles.transferArea}>
                            {i.type.code === TransactionType['Transfer'] &&
                                renderTransferArea(
                                    transData?.data.transactions.find((t) => t.id === i.id)!
                                )}
                        </div>

                        <Divider />
                    </div>
                ))}
            </div>
            <div className={styles.email}>
                <div className={styles.emailContent}>
                    <div className={styles.emailField}>
                        <span className={styles.fieldName}>发件人:</span>
                        <span className={styles.fieldValue}>{from}</span>
                    </div>
                    <div className={styles.emailField}>
                        <span className={styles.fieldName}>收件人:</span>
                        <span className={styles.fieldValue}>{to}</span>
                        <span
                            className={styles.plusBtn}
                            onClick={() => {
                                setCurField('to');
                                setShowModal(true);
                                const temp = emailData?.data.mail_addresses
                                    .map((e) => e.address)
                                    .filter((e) => to!.split(',').includes(e));
                                setSelectedRowKeys([...temp!]);
                            }}
                        >
                            <PlusCircleOutlined />
                        </span>
                    </div>
                    <div className={styles.emailField}>
                        <span className={styles.fieldName}>抄送人:</span>
                        <span className={styles.fieldValue}>{cc}</span>
                        <span
                            className={styles.plusBtn}
                            onClick={() => {
                                setCurField('cc');
                                setShowModal(true);
                                const temp = emailData?.data.mail_addresses
                                    .map((e) => e.address)
                                    .filter((e) => cc!.split(',').includes(e));
                                setSelectedRowKeys([...temp!]);
                            }}
                        >
                            <PlusCircleOutlined />
                        </span>
                    </div>
                    <div className={styles.emailField}>
                        <span className={styles.fieldName}>主题:</span>
                        <span className={styles.fieldValue}>{subject}</span>
                    </div>
                    <div className={styles.emailField}>{body}</div>
                </div>
                <div className={styles.btn}>
                    <Button
                        type="primary"
                        onClick={() => {
                            onEmailBtnClick();
                        }}
                        disabled={!to}
                    >
                        Send Email
                    </Button>
                </div>
            </div>
            <div>
                <Modal
                    visible={showModal}
                    closable={false}
                    maskClosable
                    onOk={onModalOkClick}
                    destroyOnClose
                    onCancel={() => {
                        setShowModal(false);
                    }}
                    title="选择收件人"
                    width={800}
                >
                    <Table
                        columns={columns}
                        dataSource={emailData?.data.mail_addresses}
                        size="small"
                        pagination={false}
                        rowKey={(record) => record.address}
                        rowSelection={{
                            selectedRowKeys,
                            onChange: onSelecteChange,
                        }}
                        scroll={{ x: 750 }}
                    />
                </Modal>
            </div>
        </>
    );
};

export default TransactionEmail;
