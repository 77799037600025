import {
    Button,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Radio,
    Spin,
    Upload,
    Image,
} from 'antd';
import moment from 'moment';
import { UploadOutline } from 'antd-mobile-icons';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    CustomerManageData,
    TranscationProductType,
} from '../../../../../../service/requests/CustomerManage';
import { showError } from '../../../../../RecommendationComponent/util';
import {
    requestTransactionType,
    getTranscationFields,
    TranscationFormType,
    AddTranscationProps,
    parsePdfRecord,
    requestTransById,
    updateTransById,
} from './request';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import style from './index.module.scss';
import { CommonUploadFile, UploadFileType } from '../../../../../AssetManagement/BankFee/requests';
import { isImageURL } from '../../../../../../utils';
import SearchCustomUserFormItem from '../../../../../widget/GeneralForm/SearchCustomUserFormItem';
import { assetList } from '../../../../../../service/type';
import SelectAccountFormItem from '../../../../../widget/GeneralForm/SelectAccountFormItem';
import { UserAccountDetailInfo } from '../../../../../../service/requests/HistoricalTransactions';
import MakeTranscationImgModal, { CustomModalProps } from '../MakeTransactionImgModal';
import SearchProductFormItem from '../../../../../widget/GeneralForm/SearchProductFormItem';
interface AddTranscationModalProps extends CustomModalProps {
    productInfo?: {
        account_id: string; //账户id
        trans_id: string; //交易id
        product_type: string;
        trans_type: number;
        action: number;
    };
}
const AddTranscationModal = ({ visibleState, productInfo }: AddTranscationModalProps) => {
    const [visible, setVisible] = visibleState; //是否显示
    const [transactionType, setTransactionType] = React.useState<TranscationProductType[]>([]); //  交易类型
    const [formItems, setFormItems] = useState<TranscationFormType[]>([]); //表单项
    const productTypeIndex = useRef(0); //产品类型索引
    const transactionTypeIndex = useRef(0); //交易类型索引
    const [pdfUrl, setPdfUrl] = useState<string | undefined>(undefined); //pdf地址
    const [form] = Form.useForm();
    const [customUser, setCustomUser] = useState<CustomerManageData['pageData'][0]>(); //客户信息
    const [iframeHeight, setIframeHeight] = useState(0); //iframe高度
    const [curBank, setCurBank] = useState<UserAccountDetailInfo>(); //当前选中的银行账户
    const [formValues, setFormValues] = useState<AddTranscationProps>(); //表单值
    const formNameMap = useRef<Map<string, string>>(new Map()); //表单中文名和字段名的映射

    const [pdfUploading, setPdfUploading] = useState(false); // pdf上传中
    const [showMakeTransactionImgModal, setShowMakeTransactionImgModal] = useState(false); //是否显示制作交易凭证弹窗
    const [refreshKey, setRefreshKey] = useState(0); //刷新key
    const [curPostion, setCurPostion] = useState<assetList[number]>();
    //产品选项
    const productOptions = useMemo(() => {
        return transactionType.map((item, index) => (
            <Radio value={index} key={item.key}>
                {item.title}
            </Radio>
        ));
    }, [transactionType]);

    //交易类型选项
    const transactionOptions = useMemo(() => {
        if (!transactionType[productTypeIndex.current]) return null;
        return transactionType[productTypeIndex.current].trans_detail_type_fields?.map(
            (item, index) => (
                <Radio value={index} key={item.title}>
                    {item.title}
                </Radio>
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionType, productTypeIndex.current]);

    //交易类型发生变化后请求交易类型具体项

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const requestFormItems = (data?: TranscationProductType[]) => {
        if (!data) {
            data = transactionType;
        }
        let files = {
            product_type: data[productTypeIndex.current].key,
            trans_type: data[productTypeIndex.current].trans_detail_type_fields![
                transactionTypeIndex.current
            ].transType,
            action: data[productTypeIndex.current].trans_detail_type_fields![
                transactionTypeIndex.current
            ].action,
        };
        if (productInfo) {
            files = {
                ...productInfo,
            };
        }
        getTranscationFields(files).then((res) => {
            setFormItems(res.data.detail_fields);
            getHistoryInfo();
        }, showError);
    };
    const getHistoryInfo = useCallback(() => {
        if (productInfo) {
            requestTransById({
                trans_id: productInfo.trans_id,
                account_id: productInfo.account_id,
            }).then((res) => {
                let obj: { [key: string]: any } = {};
                let values = res.data?.ext_info?.add_trans_field_save ?? {};
                Object.keys(values).forEach((element) => {
                    if (moment(values[element], 'YYYY-MM-DD', true).isValid()) {
                        obj[element] = moment(values[element]);
                    } else {
                        obj[element] = values[element];
                    }
                });
                obj.assetIndex = values.underlying;
                form.setFieldsValue(obj);
            });
        }
    }, [form, productInfo]);
    //根据formItems渲染表单
    const renderFormItems = useMemo(() => {
        let map = new Map();
        let items = formItems.map((item) => {
            map.set(item.key, item.title);
            let formItem = undefined;
            switch (item.field_type) {
                case 'date_selection':
                    formItem = <DatePicker />;
                    break;
                case 'input_box_number':
                    formItem = <InputNumber />;
                    break;
                case 'input_box_text':
                    formItem = <Input />;
                    break;
                case 'radio':
                    formItem = (
                        <Radio.Group>
                            {item.options?.map((option) => (
                                <Radio value={option.value} key={option.title}>
                                    {option.title}
                                </Radio>
                            ))}
                        </Radio.Group>
                    );
                    break;
                default:
                    break;
            }
            if (item.title === '关联产品') {
                return (
                    <SearchProductFormItem
                        key={item.key}
                        formName="关联产品"
                        searchType={[]}
                        assetListCallback={(originAsset) => setCurPostion(originAsset)}
                    />
                );
            }
            return (
                <Form.Item
                    key={item.key}
                    label={item.title}
                    name={item.key}
                    rules={[{ required: item.required === 1, message: `请输入${item.title}` }]}
                    // rules={[{ required: false, message: `请输入${item.title}` }]}
                >
                    {formItem}
                </Form.Item>
            );
        });
        formNameMap.current = map;
        return items;
    }, [formItems]);
    //根据formitems中的select选项生产对应map可以映射到具体的title
    const selectMap: Map<string, string> = useMemo(() => {
        let map = new Map();

        formItems.forEach((item) => {
            if (item.field_type === 'radio') {
                let options = new Map();
                item.options?.forEach((option) => {
                    options.set(option.value, option.title);
                });
                map.set(item.key, options);
            }
        });
        return map;
    }, [formItems]);

    //表单默认值
    const defaultValues = useRef<{ [key: string]: string }>();
    useEffect(() => {
        const values: any = {};
        formItems.forEach((item) => {
            if (item.field_type === 'radio') {
                values[item.key] = item.options![0].value;
            } else if (item.field_type === 'date_selection') {
                values[item.key] = moment();
            }
        });
        defaultValues.current = values;
    }, [formItems]);

    //获取表单值
    useEffect(() => {
        requestTransactionType().then((res) => {
            setTransactionType(res.data.types);
            requestFormItems(res.data.types);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productInfo]);
    //获取modalContent的高度
    const modalContentHeight = () => {
        const modalContent = document.getElementById('modalContent');
        if (!modalContent) return 0;
        return modalContent.offsetHeight;
    };
    //上传pdf
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleUploadPdf = (pdfStr: string) => {
        CommonUploadFile({
            data_url: pdfStr,
            target_type: UploadFileType.Receipt,
        })
            .then((res) => {
                // message.success('上传成功');
                setPdfUrl(res.data.url);
                parseUrl(res.data.url);
                setIframeHeight(modalContentHeight());
            })
            .catch((err) => {
                setPdfUploading(false);
                message.error(err.message);
            });
    };
    //根据pdfurl解析表单字段
    const parseUrl = useCallback(
        (url: string) => {
            if (!url) return;

            parsePdfRecord({ url })
                .then((res) => {
                    if (!res.data) return;
                    const data = res.data.fieldsParsed;

                    let resultData: { [key: string]: any } = {};
                    formItems
                        .filter((item) => data[item.key])
                        .forEach((item) => {
                            resultData[item.key] = data[item.key];
                            //如果是字符串去除百分号
                            if (typeof data[item.key] === 'string') {
                                resultData[item.key] = data[item.key].replace('%', '');
                            }
                            //分析字符串如何是yyyy-mm-dd格式的就转化为moment
                            if (moment(data[item.key], 'YYYY-MM-DD', true).isValid()) {
                                resultData[item.key] = moment(data[item.key]);
                            }
                        });
                    resultData['assetIndex'] = resultData['underlying'];

                    form.setFieldsValue(resultData);
                }, showError)
                .finally(() => {
                    setPdfUploading(false);
                });
        },
        [form, formItems]
    );
    const parsePDF = useCallback(
        (props: UploadRequestOption) => {
            const reader = new FileReader();
            setPdfUploading(true);

            reader.onload = () => {
                const data = reader.result as string;
                handleUploadPdf(data);
                props.onSuccess?.(data);
            };
            reader.onerror = () => {
                props.onError?.(new Error('读取文件失败'));
                setPdfUploading(false);
            };
            if (typeof props.file !== 'string') {
                reader.readAsDataURL(props.file);
            }
        },
        [handleUploadPdf]
    );
    //提交表单
    const onFinish = useCallback(
        (values) => {
            //将values中的moment对象转换为字符串//YYYY-MM-DD
            for (let key in values) {
                if (values[key] instanceof moment) {
                    values[key] = values[key].format('YYYY-MM-DD');
                }
            }
            let underlying = curPostion?.isin ?? values.assetIndex;
            let prop: AddTranscationProps;
            if (productInfo) {
                prop = {
                    ...productInfo,
                    trans_detail_fields: {
                        ...values,
                        underlying: underlying,
                        underlyings: underlying,
                    },
                    fieldTitleMap: formNameMap.current,
                };
                updateTransById(prop).then((res) => {
                    message.success('修改成功');
                }, showError);
            } else {
                prop = {
                    product_type: transactionType[productTypeIndex.current].key,
                    action: transactionType[productTypeIndex.current].trans_detail_type_fields![
                        values.transcationrecord
                    ].action,
                    account_id: curBank!.account_id,
                    trans_type: transactionType[productTypeIndex.current].trans_detail_type_fields![
                        values.transcationrecord
                    ].transType,
                    trans_detail_fields: {
                        ...values,
                        underlying: underlying,
                        underlyings: underlying,
                    },
                    fieldTitleMap: formNameMap.current,
                };
                setFormValues(prop);
                setShowMakeTransactionImgModal(true);
            }
            setVisible(false);
        },
        [curBank, curPostion, productInfo, setVisible, transactionType]
    );
    return (
        <>
            {showMakeTransactionImgModal && (
                <MakeTranscationImgModal
                    cancelClick={() => {
                        setVisible(true);
                        setShowMakeTransactionImgModal(false);
                    }}
                    resetFileds={() => {
                        form.resetFields();
                        productTypeIndex.current = 0;
                        transactionTypeIndex.current = 0;
                        setPdfUrl(undefined);
                        setRefreshKey((pre) => pre + 1);
                        requestFormItems();
                    }}
                    selectMap={selectMap}
                    values={formValues}
                    visibleState={[showMakeTransactionImgModal, setShowMakeTransactionImgModal]}
                />
            )}
            <Modal
                key={refreshKey}
                visible={visible}
                className={style.content}
                maskClosable={false}
                destroyOnClose={false}
                width={pdfUrl ? '1040px' : '520px'}
                title={productInfo ? '编辑交易记录' : '新增交易记录'}
                onOk={() => {
                    form.submit();
                }}
                okText="提交"
                cancelText="取消"
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <Spin tip="解析中..." spinning={pdfUploading}>
                    <div id="modalContent" style={{ display: 'flex', width: '100%' }}>
                        <div style={{ flex: '1' }}>
                            {productInfo === undefined && (
                                <Upload accept=".pdf,image/*" maxCount={1} customRequest={parsePDF}>
                                    <Button type="primary">
                                        <UploadOutline />
                                        上传交易回执，自动解析
                                    </Button>
                                </Upload>
                            )}
                            <Form
                                style={{ marginTop: '20px' }}
                                initialValues={{
                                    product: 0,
                                    transcationrecord: 0,
                                    ...(defaultValues.current ?? {}),
                                }}
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 15 }}
                                onFinish={onFinish}
                                form={form}
                                labelWrap
                            >
                                {productInfo === undefined && (
                                    <>
                                        <SearchCustomUserFormItem
                                            userCallback={(user) => {
                                                setCustomUser(user);
                                                form.resetFields(['account_index']);
                                            }}
                                        />
                                        <SelectAccountFormItem
                                            selectCallback={(originAccount) => {
                                                setCurBank(originAccount);
                                            }}
                                            customer_id={customUser?.id ?? 0}
                                        />
                                        <Form.Item label="产品" name="product">
                                            <Radio.Group
                                                onChange={(value) => {
                                                    productTypeIndex.current = value.target.value;
                                                    requestFormItems();
                                                }}
                                            >
                                                {productOptions}
                                            </Radio.Group>
                                        </Form.Item>
                                        <Form.Item label="交易类型" name="transcationrecord">
                                            <Radio.Group
                                                onChange={(value) => {
                                                    transactionTypeIndex.current =
                                                        value.target.value;
                                                    requestFormItems();
                                                }}
                                            >
                                                {transactionOptions}
                                            </Radio.Group>
                                        </Form.Item>
                                    </>
                                )}
                                {renderFormItems}
                            </Form>
                        </div>
                        <div
                            style={{
                                height: '100%',
                                position: 'relative',
                                display: pdfUrl ? 'block' : 'none',
                                flex: '1',
                                borderLeft: `${pdfUrl ? '1' : '0'}px solid #ddd`,
                            }}
                        >
                            {isImageURL(pdfUrl ?? '') ? (
                                <Image
                                    id="pdf-preview"
                                    height={iframeHeight}
                                    alt="交易文件"
                                    src={pdfUrl}
                                    style={{
                                        width: '100%',
                                        display: 'block',
                                        objectFit: 'contain',
                                    }}
                                />
                            ) : (
                                <iframe
                                    id="pdf-preview"
                                    width={'100%'}
                                    height={iframeHeight - 42}
                                    style={{
                                        left: '0',
                                        right: '0',
                                        top: '0',
                                        border: 'none',
                                    }}
                                    title="交易文件"
                                    src={pdfUrl}
                                />
                            )}
                            <Button
                                type="primary"
                                style={{ display: 'block', margin: '10px auto 0' }}
                                onClick={() => {
                                    window.open(pdfUrl);
                                }}
                            >
                                在新页面打开文件
                            </Button>
                        </div>
                    </div>
                </Spin>
            </Modal>
        </>
    );
};

export default AddTranscationModal;
