import { Checkbox, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { CustomerManageData, RMList } from '../../../../service/requests/CustomerManage';

const TradersSetField = ({
    onChange,
    value,
    traders,
    id,
    bankList,
}: {
    onChange?: (value: Map<string, number[]>) => void;
    value?: Map<string, number[]>;
    traders: RMList;
    id?: string;
    bankList?: CustomerManageData['pageData'][0]['bankAccounts'];
}) => {
    useEffect(() => {
        console.log('value', value);
    }, [value]);

    const [bankValues, setBankValues] = useState<Map<string, number[]>>(() => {
        if (value) {
            return new Map(value);
        }
        return new Map();
    });

    const bankTraderHandle = (value: number[], bankId: string) => {
        console.log(value, bankId);
        const map = new Map(bankValues);
        map.set(bankId, value);
        setBankValues(map);
        if (onChange) {
            onChange(map);
        }
    };

    return (
        <div id={id} className="TradersSetFeild_Container">
            {bankList?.map((item) => {
                const bankValue = bankValues.get(item.accountId);
                return (
                    <div key={item.accountId}>
                        <div>
                            {item.bankName +
                                (item.vendorAccountId ? `-${item.vendorAccountId}` : '')}
                        </div>
                        <Checkbox.Group
                            style={{ width: '100%' }}
                            value={bankValue}
                            onChange={(value) => {
                                bankTraderHandle(
                                    value.map((item) => item as number),
                                    item.accountId
                                );
                            }}
                        >
                            <Row>
                                {traders.map((rm) => (
                                    <Col span={4} key={rm.id}>
                                        <Checkbox value={rm.id} style={{ lineHeight: '32px' }}>
                                            {rm.name}
                                        </Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        </Checkbox.Group>
                    </div>
                );
            })}
        </div>
    );
};

export default TradersSetField;
