import {
    Button,
    Modal,
    Form,
    Input,
    message,
    Collapse,
    DatePicker,
    Divider,
    Radio,
    Select,
} from 'antd';
import { Rule } from 'antd/lib/form';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useCallback } from 'react';
import { createCustomer, updateCustomerInfo } from '../../../../service';
import { CustomerManageData } from '../../../../service/requests/CustomerManage';
import { showError } from '../../../RecommendationComponent/util';
import styles from './AddUserModalPC.module.scss';

interface FormRowCell {
    headerName: string;
    field: string;
    formComponent?: JSX.Element;
    rules?: Rule[];
}

type FormRow = FormRowCell | FormRowCell[];

const AddUserModalPC: React.FC<{
    visible: boolean;
    closeModal: () => void;
    initValues?: CustomerManageData['pageData'][number];
    handleRefreshList: () => void;
    containerId: string;
}> = ({ visible, closeModal, handleRefreshList, initValues, containerId }) => {
    const formModel = useMemo<Array<{ title: string; rows: FormRow[] }>>(
        () => [
            {
                title: '基本信息',
                rows: [
                    [
                        {
                            headerName: '昵称',
                            field: 'name',
                            rules: [{ required: true, message: '不能为空' }],
                        },
                    ],
                    [
                        { headerName: '生日', field: 'birthday', formComponent: <DatePicker /> },
                        {
                            headerName: '性别',
                            field: 'gender',
                            formComponent: (
                                <Radio.Group>
                                    <Radio value="男">男</Radio>
                                    <Radio value="女">女</Radio>
                                </Radio.Group>
                            ),
                        },
                    ],
                    [
                        {
                            headerName: '手机',
                            field: 'mobile',
                            rules: [{ required: true, message: '不能为空' }],
                        },
                        {
                            headerName: '邮箱',
                            field: 'email',
                            rules: [{ required: true, message: '不能为空' }],
                        },
                    ],
                    [
                        {
                            headerName: '状态',
                            field: 'state',
                            rules: [{ required: true, message: '不能为空' }],
                            formComponent: (
                                <Select>
                                    <Select.Option value="目标用户">目标用户</Select.Option>
                                    <Select.Option value="开户中">开户中</Select.Option>
                                    <Select.Option value="入金完成">入金完成</Select.Option>
                                    <Select.Option value="其他(非客户)">其他(非客户)</Select.Option>
                                    <Select.Option value="已注销">已注销</Select.Option>
                                </Select>
                            ),
                        },
                        {
                            headerName: '来源',
                            field: 'source',
                            formComponent: (
                                <Select>
                                    <Select.Option value="朋友介绍">朋友介绍</Select.Option>
                                    <Select.Option value="市场活动">市场活动</Select.Option>
                                    <Select.Option value="自主挖掘">自主挖掘</Select.Option>
                                    <Select.Option value="其他">其他</Select.Option>
                                </Select>
                            ),
                        },
                    ],

                    [
                        {
                            headerName: '新用户roadmap URL',
                            field: 'roadmapUrl',
                        },
                        {
                            headerName: 'KYC 链接',
                            field: 'kycUrl',
                        },
                    ],
                    {
                        headerName: '居住地址',
                        field: 'address',
                    },
                    {
                        headerName: '客户画像',
                        field: 'remark',
                        formComponent: <Input.TextArea style={{ height: '100px' }} />,
                    },
                ],
            },
            {
                title: '业务信息',
                rows: [
                    [
                        { headerName: '姓名', field: 'formalName' },
                        { headerName: '国籍', field: 'nationality' },
                    ],
                    { headerName: '护照号码', field: 'passportNumber' },
                    [
                        {
                            headerName: '护照到期日',
                            field: 'passportExpiryDate',
                            formComponent: <DatePicker />,
                        },
                        { headerName: '护照颁发国', field: 'passportIssuingCountry' },
                    ],
                    { headerName: '邮寄地址', field: 'mailingAddress' },
                    [
                        { headerName: '婚姻状况', field: 'maritalStatus' },
                        { headerName: '家庭成员', field: 'familyMembers' },
                    ],
                    [
                        { headerName: '使用的其他机构', field: 'otherProviders' },
                        { headerName: '其他ID', field: 'otherIdents' },
                    ],
                    { headerName: '最高学历', field: 'highestEducation' },
                    {
                        headerName: '工作经历',
                        field: 'workHistory',
                        formComponent: <Input.TextArea />,
                    },
                ],
            },
        ],
        []
    );

    const onFinish = useCallback(
        (values) => {
            if (initValues === undefined) {
                createCustomer({
                    ...values,
                    birthday: values.birthday?.format('YYYY-MM-DD'),
                    passportExpiryDate: values.passportExpiryDate?.format('YYYY-MM-DD'),
                }).then(() => {
                    message.success('添加成功');
                    closeModal();
                    handleRefreshList();
                }, showError);
            } else {
                updateCustomerInfo({
                    id: initValues.id,
                    ...values,
                    birthday: values.birthday?.format('YYYY-MM-DD'),
                    passportExpiryDate: values.passportExpiryDate?.format('YYYY-MM-DD'),
                }).then(() => {
                    message.success('更新成功');
                    closeModal();
                    handleRefreshList();
                }, showError);
            }
        },
        [closeModal, handleRefreshList, initValues]
    );

    return (
        <Modal
            title={initValues !== undefined ? '修改客户信息' : '添加客户'}
            destroyOnClose
            footer={null}
            visible={visible}
            okText="确认"
            cancelText="取消"
            onCancel={closeModal}
            centered
            width={900}
            wrapClassName={styles['modal-wrapper']}
            getContainer={document.getElementById(containerId) ?? false}
        >
            <Form
                onFinish={onFinish}
                initialValues={{
                    ...initValues,
                    gender: '女',
                    source: '朋友介绍',
                    birthday: initValues?.birthday && moment(initValues.birthday, 'YYYY-MM-DD'),
                    passportExpiryDate:
                        initValues?.passportExpiryDate &&
                        moment(initValues.passportExpiryDate, 'YYYY-MM-DD'),
                }}
            >
                <Collapse
                    defaultActiveKey={[...Array(formModel.length).keys()].map((item) =>
                        String(item)
                    )}
                >
                    {formModel.map((model, index) => (
                        <Collapse.Panel header={model.title} key={String(index)}>
                            <table>
                                <tbody>
                                    {model.rows.map((row, index) => (
                                        <tr key={index}>
                                            {Array.isArray(row) ? (
                                                row.map((cell, index) => (
                                                    <React.Fragment key={index}>
                                                        <th>
                                                            {cell.rules && (
                                                                <span
                                                                    style={{
                                                                        color: 'red',
                                                                        marginRight: '10px',
                                                                    }}
                                                                >
                                                                    *
                                                                </span>
                                                            )}
                                                            {cell.headerName}
                                                        </th>
                                                        <td>
                                                            <Form.Item
                                                                name={cell.field}
                                                                rules={cell.rules}
                                                                className={styles['form-item']}
                                                            >
                                                                {cell.formComponent ?? <Input />}
                                                            </Form.Item>
                                                        </td>
                                                    </React.Fragment>
                                                ))
                                            ) : (
                                                <>
                                                    <th>
                                                        {row.rules && (
                                                            <span
                                                                style={{
                                                                    color: 'red',
                                                                    marginRight: '10px',
                                                                }}
                                                            >
                                                                *
                                                            </span>
                                                        )}
                                                        {row.headerName}
                                                    </th>
                                                    <td colSpan={3}>
                                                        <Form.Item
                                                            name={row.field}
                                                            rules={row.rules}
                                                            className={styles['form-item']}
                                                        >
                                                            {row.formComponent ?? <Input />}
                                                        </Form.Item>
                                                    </td>
                                                </>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Collapse.Panel>
                    ))}
                </Collapse>
                <div className={styles['modal-action-area']}>
                    <Divider style={{ margin: 0, height: '2px' }} />
                    <div className={styles['modal-actions']}>
                        <Button onClick={closeModal}>取消</Button>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

export default AddUserModalPC;
