import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React, { ReactElement, useState } from 'react';

const LKMultipleCheckbox = ({
    plainOptions,
    defaultCheckedList,
    valueChange,
    title,
    extend,
}: {
    plainOptions: { value: string; label: string }[];
    title?: string;
    defaultCheckedList?: string[];
    valueChange: (value: string[]) => void;
    extend?: ReactElement;
}) => {
    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(defaultCheckedList ?? []);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    const onChange = (list: CheckboxValueType[]) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < plainOptions.length);
        setCheckAll(list.length === plainOptions.length);
        valueChange(list.map((item) => item.toString()));
    };

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        setCheckedList(e.target.checked ? plainOptions.map((item) => item.value) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
        valueChange([]);
    };
    return (
        <>
            <div style={{ lineHeight: '40px' }}>
                <span style={{ fontSize: '14px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.85)' }}>
                    {title}：
                </span>
                <Checkbox
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                    key={'allKey'}
                >
                    全部
                </Checkbox>
                <Checkbox.Group
                    style={{ display: 'inline' }}
                    value={checkedList}
                    onChange={onChange}
                >
                    {plainOptions.map((item) => (
                        <Checkbox value={item.value} key={item.value}>
                            {item.label}
                        </Checkbox>
                    ))}
                </Checkbox.Group>
            </div>
        </>
    );
};

export { LKMultipleCheckbox };
