import axios from '../../../service/tools';
import { queryEditEntryType, quotePreview } from '../../../service/type';

export const getMultiQuoteEntries = () => {
    return axios.post<queryEditEntryType>('/v2/product/quote/query_edit_entry_v2');
};

interface GetQuoteParams {
    customer_id_list: number[];
    product_type: string;
    edit_entry_url: string;
}

export const getProductQuotePreview = (data: GetQuoteParams) => {
    return axios.post<quotePreview>('/v2/product/quote/preview', { data });
};

export interface QuoteSheetList {
    sheet_customer_list: Array<{
        customer_name: string;
        product_quote_sheet_id: number;
    }>;
}

export const getMultiProductQuoteUrl = (data: GetQuoteParams) => {
    return axios.post<QuoteSheetList>('/v2/product/quote/get_product_quote_url_v2', { data });
};
