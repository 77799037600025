import { Button, Form, message, Modal, Spin } from 'antd';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import LKUpload from '../../../components/LKUpload';
import { CustomerManageData } from '../../../service/requests/CustomerManage';
import { AlterSignInfoRes } from '../../../service/requests/Sing';
import { UploadFileType } from '../../AssetManagement/BankFee/requests';
import { CustomModalProps } from '../../CustomerList/components/CustomerDetail/Modal/MakeTransactionImgModal';
import {
    RecordListModalType,
    TrackRecordFileItem,
} from '../../CustomerList/components/Record/component/Modal';
import { showError } from '../../RecommendationComponent/util';
import SearchCustomUserFormItem from '../../widget/GeneralForm/SearchCustomUserFormItem';
import { addSign, SignScene, updateSign } from '../request';
interface SignAddModalProps extends CustomModalProps {
    editData?: AlterSignInfoRes['document_list'][0];
    finish?: () => void;
}
const SignAddModal = ({ visibleState, editData, finish }: SignAddModalProps) => {
    const [customUser, setCustomUser] = useState<CustomerManageData['pageData'][0]>(); //客户信息
    const [fileList, setFileList] = useState<TrackRecordFileItem[]>([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    useEffect(() => {
        if (fileList.length > 0) {
            form.validateFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileList]);
    const validatePwd = async (rule: {}, value: string) => {
        if (fileList.length === 0 && !editData) {
            throw new Error('请选择签署文件');
        }
    };
    useEffect(() => {
        if (editData) {
            let list = (editData.newly_signed?.upload_files ?? []).map((item) => {
                return {
                    id: item.id,
                    name: item.file_name,
                    url: item.url,
                    creatTime: item.upload_at.toString(),
                };
            });
            setFileList(list);
            form.resetFields();
        }
    }, [editData, form]);
    const onFinish = () => {
        setLoading(true);
        let promise: Promise<AxiosResponse<any>>;
        if (editData) {
            promise = updateSign({
                file_ids: fileList.map((item) => item.id),
                newly_signed_id: editData.newly_signed.newly_signed_id,
            });
        } else {
            promise = addSign({
                customer_id: customUser!.id,
                file_ids: fileList.map((item) => item.id),
                scene: SignScene.CustomerAgreement,
            });
        }
        promise
            .then((res) => {
                finish && finish();
                message.success(editData ? '编辑成功' : '新增成功');
                setCustomUser(undefined);
                setFileList([]);
                form.resetFields();
                visibleState[1](false);
            }, showError)
            .finally(() => setLoading(false));
    };
    return (
        <Modal
            title={editData ? '编辑签署文件' : '新增签署'}
            visible={visibleState[0]}
            footer={
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        justifyItems: 'center',
                    }}
                >
                    <Button
                        type="text"
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                            if (!loading) {
                                visibleState[1](false);
                            }
                        }}
                    >
                        取消
                    </Button>
                    <Spin spinning={loading}>
                        <Button onClick={() => form.submit()} type="primary">
                            确定
                        </Button>
                    </Spin>
                </div>
            }
            width={620}
            closable={false}
        >
            <Form
                style={{ marginTop: '20px' }}
                form={form}
                initialValues={{}}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 15 }}
                onFinish={onFinish}
                labelWrap
            >
                <Form.Item label="签署场景">
                    <div>服务协议</div>
                </Form.Item>
                <SearchCustomUserFormItem
                    labelStr="签署人"
                    disabled={!!editData}
                    defaultSearchValue={editData?.name}
                    userCallback={(user) => {
                        console.log(user);
                        setCustomUser(user);
                        form.resetFields(['account_index']);
                    }}
                />
                <Form.Item
                    label="签署文件"
                    required
                    rules={[{ validator: validatePwd }]}
                    name="pick_file"
                >
                    <LKUpload
                        defaultFiles={fileList}
                        target_type={UploadFileType.newly_signed_file}
                        modalType={RecordListModalType.Edit}
                        showUploadListInfo={false}
                        uploadBtnTitle="点击上传"
                        values={(files) => {
                            if (files === fileList) {
                                return;
                            }
                            setFileList(files);
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default SignAddModal;
