import { SET_LANG, IN_APP, SyncPageIndex } from '../../constants/constants';

export function setLang(lang) {
    return {
        type: SET_LANG,
        lang,
    };
}
export function setInapp(inApp) {
    return {
        type: IN_APP,
        inApp,
    };
}

export function setSyncPageIndex(syncPageIndex) {
    return {
        type: SyncPageIndex,
        syncPageIndex,
    };
}
