import { FileAddFilled } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import moment from 'moment';
import { CommonUploadFile, UploadFileType } from '../../modules/AssetManagement/BankFee/requests';
import { showError } from '../../modules/RecommendationComponent/util';
import {
    RecordListModalType,
    TrackRecordFileItem,
} from '../../modules/CustomerList/components/Record/component/Modal';
import FileItem from '../../modules/CustomerList/components/Record/component/FileItem';
interface LKUploadProps {
    target_type: UploadFileType;
    multiple?: boolean;
    showUploadListInfo?: boolean;
    modalType: RecordListModalType;
    uploadBtnTitle?: string;
    values: (files: Array<TrackRecordFileItem>) => void;
    defaultFiles?: Array<TrackRecordFileItem>;
}
const LKUpload = ({
    showUploadListInfo,
    target_type,
    multiple,
    modalType,
    uploadBtnTitle,
    values,
    defaultFiles,
}: LKUploadProps) => {
    const [filesMap, setFilesMap] = useState<Map<string, TrackRecordFileItem>>(new Map());
    useEffect(() => {
        const map = new Map();
        if (defaultFiles) {
            defaultFiles.forEach((item) => {
                map.set(item.name, item);
            });
        }
        setFilesMap(map);
    }, [defaultFiles]);
    const parsePDF = useCallback(
        (props: UploadRequestOption) => {
            const reader = new FileReader();
            const fileName = (props.file as any).name as string;
            //判断文件名是否重复
            if (filesMap.has(fileName)) {
                message.error('文件名重复');
                return;
            }
            setFilesMap((prev) => {
                const newMap = new Map(prev);
                newMap.set(fileName, {
                    loading: true,
                    name: fileName,
                    id: 0,
                    url: '',
                    creatTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                });
                values(Array.from(newMap.values()));
                return newMap;
            });
            reader.onload = () => {
                const data = reader.result as string;
                CommonUploadFile({
                    data_url: data,
                    file_name: fileName,
                    target_type: target_type,
                }).then((res) => {
                    setFilesMap((prev) => {
                        const newMap = new Map(prev);
                        newMap.set(fileName, {
                            name: fileName,
                            id: res.data.id!,
                            url: res.data.url,
                            creatTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                            loading: false,
                        });
                        values(Array.from(newMap.values()));
                        return newMap;
                    });
                }, showError);

                props.onSuccess?.(data);
            };
            reader.onerror = () => {
                props.onError?.(new Error('读取文件失败'));
            };
            if (typeof props.file !== 'string') {
                reader.readAsDataURL(props.file);
            }
        },
        [filesMap, target_type, values]
    );
    return (
        <div>
            <Upload
                multiple={multiple !== undefined ? multiple : true}
                maxCount={5}
                customRequest={parsePDF}
                itemRender={() => <></>}
            >
                <Button>
                    {uploadBtnTitle ?? '点击上传'}
                    <FileAddFilled />
                </Button>
            </Upload>
            {/* <div>附件</div> */}
            {Array.from(filesMap.values()).map((item) => {
                return (
                    <FileItem
                        data={item}
                        key={item.name}
                        type={modalType}
                        loading={item.loading}
                        showUploadListInfo={showUploadListInfo}
                        deleteClick={() => {
                            setFilesMap((prev) => {
                                const newMap = new Map(prev);
                                newMap.delete(item.name);
                                values(Array.from(newMap.values()));
                                return newMap;
                            });
                        }}
                    />
                );
            })}
        </div>
    );
};

export default LKUpload;
