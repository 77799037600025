import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import AccountItem from './FormItems/AccountItem';
import {
    GinkgoTransFormRefType,
    TransDepositExtInfo,
    TransStatus,
    TransactionInfo,
    TransactionType,
    UpdateTransactionReq,
} from '../../../type';
import CurrencyItem from './FormItems/CurrencyItem';
import DateItem from './FormItems/DateItem';
import { Form } from 'antd';
import RateInputItem, { RateInputType } from './FormItems/RateInputItem';
import { useForm } from 'antd/lib/form/Form';
import { GinkgoInput, GinkgoInputNumber } from './FormItems/GinkgoFieldItems';
import { math } from '../../../../../utils';
import moment from 'moment';
const LoanForm = forwardRef<GinkgoTransFormRefType, TransactionInfo>(
    (data: TransactionInfo, onRef) => {
        const [form] = useForm();
        const [timezone, setTimezone] = useState(data.time_zone || '');
        useImperativeHandle(onRef, () => {
            return {
                getFieldsValues: async (validate) => {
                    if (validate === false) {
                        return new Promise((resolve) => {
                            return resolve(configValues(form.getFieldsValue()));
                        });
                    } else {
                        return form.validateFields().then(configValues);
                    }
                },
            };
        });
        const configValues = (values: any) => {
            return {
                ...values,
                client_id: data.client_id,
                transaction_type: TransactionType['Take Loan'],
                id: data.id,
                ext_info: {
                    ...data.ext_info,
                    ...values.ext_info,
                },
                status_code: data.status.code,
            };
        };
        useEffect(() => {
            form.setFieldsValue({
                ...data,
            });
        }, [data, form]);

        const PlaceableReadOnly = useMemo(() => {
            return data.status.code !== TransStatus.Created;
        }, [data]);
        const TradedReadOnly = useMemo(() => {
            return data.status.code === TransStatus.Traded;
        }, [data]);
        const PlaceableRequired = useMemo(() => {
            return data.status.code === TransStatus.Created;
        }, [data]);
        const TradedRequired = useMemo(() => {
            return data.status.code === TransStatus.Placeable;
        }, [data]);
        useEffect(() => {
            if (data.status.code > TransStatus.Created) {
                form.setFieldsValue({
                    ...data,
                });
            }
        }, [data, form]);
        const handleFormChange = (
            changedValues: Record<string, any>,
            allValues: UpdateTransactionReq
        ) => {
            let changeKey = Object.keys(changedValues)[0];
            const extInfo = allValues.ext_info as TransDepositExtInfo;
            // 1. 如果修改的是dealer_rate或者spread，那么需要同步修改client_rate
            if (changeKey === 'ext_info') {
                changeKey = Object.keys(changedValues[changeKey])[0];
                if (changeKey === 'dealer_rate' || changeKey === 'spread') {
                    form.setFieldsValue({
                        ext_info: {
                            ...allValues.ext_info,
                            client_rate: math.format(
                                math.evaluate(
                                    `(${extInfo.dealer_rate ?? 0} + ${extInfo.spread ?? 0})`
                                ),
                                { notation: 'fixed' }
                            ),
                        },
                    });
                }
                // 2. 同步计算maturity_date
                if (changeKey === 'maturity_date' && allValues.value_date) {
                    let tenor = extInfo.maturity_date - (allValues.value_date ?? 0);
                    if (tenor >= 0) {
                        //tenor 由时间戳换为day
                        const days = Math.floor(tenor / (24 * 60 * 60 * 1000));
                        form.setFieldsValue({
                            ext_info: {
                                ...allValues.ext_info,
                                tenor: `${days} days`,
                            },
                        });
                    }
                }
                // 3. 同步计算总金额
                if (changeKey === 'interest_amount' || changeKey === 'principal_amount') {
                    // if (!extInfo?.interest_amount || !extInfo?.principal_amount) return;
                    form.setFieldsValue({
                        ext_info: {
                            ...allValues.ext_info,
                            total_amount: math.format(
                                math.evaluate(
                                    `(${extInfo?.interest_amount ?? '0'} + ${
                                        extInfo?.principal_amount ?? '0'
                                    })`
                                ),
                                { notation: 'fixed' }
                            ),
                        },
                    });
                }
            }
            if (changeKey === 'value_date' && extInfo.maturity_date) {
                let tenor = extInfo.maturity_date - (allValues.value_date ?? 0);
                if (tenor >= 0) {
                    //tenor 由时间戳换为day
                    const days = Math.floor(tenor / (24 * 60 * 60 * 1000));
                    form.setFieldsValue({
                        ext_info: {
                            ...allValues.ext_info,
                            tenor: `${days} days`,
                        },
                    });
                }
            }
        };

        return (
            <Form
                labelCol={{ span: 6 }}
                labelWrap
                wrapperCol={{ span: 18 }}
                form={form}
                initialValues={{
                    trade_date: moment().valueOf(),
                }}
                onValuesChange={handleFormChange}
            >
                <AccountItem
                    clientId={data.client_id}
                    form={form}
                    readOnlyValue={data.ext_info.account_id}
                    selectCallback={(bank) => {
                        setTimezone(bank.time_zone);
                    }}
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                />
                <CurrencyItem
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                    onChange={() => {}}
                />
                <DateItem
                    label="Value Date"
                    name="value_date"
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                    timezone={timezone}
                />
                <Form.Item label="Tenor" name={['ext_info', 'tenor']}>
                    <GinkgoInput readOnly />
                </Form.Item>
                <DateItem
                    label="Maturity Date"
                    name={['ext_info', 'maturity_date']}
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                    timezone={timezone}
                />
                <RateInputItem
                    type={RateInputType.Percent}
                    label="Spread"
                    name={['ext_info', 'spread']}
                    readOnly={PlaceableReadOnly}
                    required={PlaceableRequired}
                    onInput={(e) => {
                        // 手动触发onValuesChange方法
                        if (e.length === 0) {
                            let obj = {
                                ext_info: {
                                    spread: '0',
                                    client_rate: form.getFieldsValue().ext_info.dealer_rate,
                                },
                            };
                            form.setFieldsValue(obj);
                        }
                    }}
                />
                <Form.Item
                    label={'Principal Amount'}
                    name={['ext_info', 'principal_amount']}
                    rules={[
                        {
                            required: PlaceableRequired,
                            message: 'Principal Amount is required',
                        },
                    ]}
                >
                    <GinkgoInputNumber
                        readOnly={PlaceableReadOnly}
                        style={{ width: 'calc(62.5% - 10px)' }}
                    />
                </Form.Item>
                <Form.Item label="Custodian Trade ID" name={['ext_info', 'custodian_trade_id']}>
                    <GinkgoInput
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <RateInputItem
                    type={RateInputType.NoAddonAfterPercent}
                    label="Dealer Rate"
                    name={['ext_info', 'dealer_rate']}
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                    onInput={(e) => {
                        // 手动触发onValuesChange方法
                        if (e.length === 0) {
                            let obj = {
                                ext_info: {
                                    dealer_rate: '0',
                                    client_rate: form.getFieldsValue().ext_info.spread,
                                },
                            };
                            form.setFieldsValue(obj);
                        }
                    }}
                />

                <RateInputItem
                    label="Client Rate"
                    name={['ext_info', 'client_rate']}
                    readOnly
                    type={RateInputType.NoAddonAfterPercent}
                />
                <Form.Item
                    label="Interest Amount"
                    name={['ext_info', 'interest_amount']}
                    rules={[{ required: TradedRequired, message: 'Interest Amount is required' }]}
                >
                    <GinkgoInputNumber
                        style={{ width: 'calc(62.5% - 10px)' }}
                        readOnly={TradedReadOnly}
                    />
                </Form.Item>
                <Form.Item label="Total Amount" name={['ext_info', 'total_amount']}>
                    <GinkgoInputNumber
                        readOnly={TradedReadOnly}
                        style={{ width: 'calc(62.5% - 10px)' }}
                    />
                </Form.Item>
                <DateItem
                    label="Trade Date"
                    name="trade_date"
                    readOnly={TradedReadOnly}
                    required={TradedRequired}
                    timezone={timezone}
                />
            </Form>
        );
    }
);

export default LoanForm;
