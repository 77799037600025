/**
 * Created by hao.cheng on 2017/4/16.
 */
import React, { useCallback, useEffect } from 'react';
import { Button, Form, Input, message } from 'antd';
import { PwaInstaller } from '../widget';
import { RouteComponentProps } from 'react-router';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { getAllowedMenuList, login, loginWithCode } from '../../service';
import { useDispatch } from 'react-redux';
import { setHasToken, setLogin } from '../../store/actions/actions';
import md5 from 'js-md5';
import { md5key } from '../../service/config';
import { getQueryVariable } from '../../utils';

const FormItem = Form.Item;
type LoginProps = RouteComponentProps;

const Login = (props: LoginProps) => {
    const dispatch = useDispatch();
    const { history } = props;

    useEffect(() => {
        //验证是否需要登陆
        const code = getQueryVariable('code');
        if (code) {
            loginWithCode({
                code,
            })
                .then((res) => {
                    dispatch(setLogin(res.data.login));
                    dispatch(setHasToken(true));
                    localStorage.setItem('login', JSON.stringify(res.data.login));
                    const lastPathname = localStorage.getItem('lastPathname');
                    localStorage.removeItem('lastPathname');
                    window.location.href = window.location.origin + (lastPathname ?? '/');
                })
                .catch((err) => {
                    message.error(err.msg);
                });
        } else {
            // 验证是否需要登陆&&获取跳转飞书登陆链接
            getAllowedMenuList().then((res) => {
                console.log(res);
            });
        }
    }, [dispatch, history]);

    const handleSubmit = useCallback(
        (loginValues: { account_id: string; pwd: string }) => {
            login({
                account_id: loginValues.account_id,
                pwd: md5(loginValues.pwd + md5key).toLowerCase(),
            })
                .then((res) => {
                    dispatch(setLogin(res.data.login));
                    dispatch(setHasToken(true));
                    localStorage.setItem('login', JSON.stringify(res.data.login));
                    const lastPathname = localStorage.getItem('lastPathname');
                    localStorage.removeItem('lastPathname');
                    history.push(lastPathname || '/');
                })
                .catch((err) => {
                    message.error(err.msg);
                });
        },
        [dispatch, history]
    );

    return (
        <div className="login">
            <div className="login-form">
                <div className="login-logo">
                    <span>Ginkgo 客户管理</span>
                    <PwaInstaller />
                </div>
                <Form onFinish={handleSubmit} style={{ maxWidth: '300px' }}>
                    <FormItem
                        name="account_id"
                        rules={[{ required: true, message: '请输入用户名!' }]}
                    >
                        <Input prefix={<UserOutlined size={13} />} />
                    </FormItem>
                    <FormItem name="pwd" rules={[{ required: true, message: '请输入密码!' }]}>
                        <Input prefix={<LockOutlined size={13} />} type="password" />
                    </FormItem>
                    <FormItem>
                        <span className="login-form-forgot" style={{ float: 'right' }}>
                            忘记密码
                        </span>
                        <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                            style={{ width: '100%' }}
                        >
                            登录
                        </Button>
                    </FormItem>
                </Form>
            </div>
        </div>
    );
};

export default Login;
