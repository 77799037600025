import styles from './index.module.scss';
import React, { useCallback, useEffect } from 'react';
import { Button, message, Modal } from 'antd';
import { assetQueryPendingType, UnreviewAccount_list } from '../../../../../service/type';
import { FormatDate } from '../../../../../utils';
import { diffedPositionsItem } from '../DataAudit/DataAudit';
import { assetReview } from '../../../../../service';
import { showError } from '../../../../RecommendationComponent/util';

const { confirm } = Modal;

const Detail: React.FC<{
    pureData: assetQueryPendingType['pending_asset'];
    type: string;
    positionList: Array<diffedPositionsItem>;
    account: UnreviewAccount_list['account_summaries'][0];
    setFreshData: () => void;
    haveOverdue?: boolean;
}> = ({ pureData, positionList, type, account, setFreshData, haveOverdue }) => {
    useEffect(() => {}, []);
    const handleOk = useCallback(() => {
        if (haveOverdue ?? false) {
            showError({ msg: '请先审核完滞纳金再点击通过' });
        } else {
            confirm({
                title: '确认该数据准确无误?',
                onOk() {
                    assetReview({
                        account_id: account.account_id,
                        update_date: account.update_date,
                    }).then(() => {
                        message.success('核验成功');
                        setFreshData();
                    }, showError);
                },
                cancelText: '取消',
                okText: '确认',
            });
        }
    }, [account.account_id, account.update_date, setFreshData, haveOverdue]);
    return (
        <div className={`${styles.wrapper} ${styles[type]}`}>
            <div className={styles.summary}>
                <div className={styles['top-summary']}>
                    <div className={styles.title}>
                        {type === 'pending' ? '待审核数据' : '展示中数据'}
                    </div>
                    <div className={styles.time}>
                        数据获取时间:{FormatDate(pureData.created_at, 'yy/MM/dd')}
                    </div>
                    {type === 'pending' && (
                        <Button className={styles.button} type="primary" onClick={handleOk}>
                            通过
                        </Button>
                    )}
                    {account.is_upload_bank_receipt === 2 && type === 'pending' && (
                        <>
                            <Button
                                onClick={() => {
                                    window.open(
                                        window.location.origin +
                                            `/#/app/TranscationRecord/${account.customer_name}`
                                    );
                                }}
                            >
                                上传回执单
                            </Button>
                        </>
                    )}
                </div>
                <div className={styles['bottom-summary']}>
                    <div className={styles['bottom-item']}>净资产:{pureData.c_net_asset}</div>
                    <div className={styles['bottom-item']}>总负债:{pureData.c_debit_amount}</div>
                    <div className={styles['bottom-item']}>总资产:{pureData.c_total_asset}</div>
                </div>
            </div>
            <div className={styles['table-wrapper']}>
                <div className={styles.lane}>
                    <div className={styles['lane-item']}>产品名</div>
                    <div className={styles['lane-item']}>数量</div>
                    <div className={styles['lane-item']}>{type === 'pending' ? '变化' : ''}</div>
                </div>
                {positionList.map((item) => {
                    if (item.name === '港币现金') {
                        // console.log(item);
                    }
                    return (
                        <div
                            className={`${styles.lane} ${styles[item.diffType]} ${
                                item.diffType !== 'equal' ? styles.red : ''
                            }`}
                            key={item.isin}
                        >
                            <div className={`${styles['lane-item']} ${styles.name}`}>
                                {item.name + (item.delivery === 2 ? '(未交割)' : '')}
                            </div>
                            <div className={`${styles['lane-item']} ${styles.balance}`}>
                                {item.c_aggr_balance
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </div>
                            <div className={`${styles['lane-item']} ${styles.change}`}>
                                {(item.quantityChange
                                    ? item.quantityChange > 0
                                        ? '+' + item.quantityChange
                                        : item.quantityChange
                                    : ''
                                )
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </div>
                        </div>
                    );
                })}
            </div>
            {type === 'pending' && (
                <Button
                    className={styles.button}
                    type="primary"
                    style={{ marginLeft: 92, marginTop: 30 }}
                    onClick={handleOk}
                >
                    通过
                </Button>
            )}
        </div>
    );
};

export default Detail;
