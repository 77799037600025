import style from './index.module.scss';
import React, { useCallback, useEffect, useState } from 'react';
import PositionCharts from '../Charts';
import {
    CustomerAssetModel,
    QueryAsset,
    QueryEarning,
} from '../../../../service/requests/CustomerManage';
import { Button, DatePicker, Result, Spin, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import useFetch from '../../../../utils/Hooks/useFetch';
import { showError as showErrorToast } from '../../../RecommendationComponent/util';
import { useDispatch } from 'react-redux';
import { setAssetModel } from '../../../../store/actions/actions';
import { CustomerDetailCardProps } from '../CustomerDetail/type';
const { RangePicker } = DatePicker;
const momentFormat = 'YYYY-MM-DD';
const AssetData = [
    {
        title: '净资产',
        value: (data?: CustomerAssetModel['customer_asset_summary']) => {
            return data?.asset?.c_net_asset ?? '0';
        },
    },
    {
        title: '总负债',
        value: (data?: CustomerAssetModel['customer_asset_summary']) => {
            return data?.asset?.c_debit_amount ?? '0';
        },
    },
    {
        title: '总资产',
        value: (data?: CustomerAssetModel['customer_asset_summary']) => {
            return data?.asset?.c_total_asset ?? '0';
        },
    },
    {
        title: '净抵押值',
        value: (data?: CustomerAssetModel['customer_asset_summary']) => {
            return data?.asset?.c_net_margin_avail ?? '0';
        },
    },
];
const configColor = (num: string) => {
    if (Number.parseFloat(num) === 0) {
        return '#000';
    }
    return num.indexOf('-') > -1 ? '#4CD964' : '#ed3b00';
};
const ButtonData: { title: string }[] = [
    {
        title: '近一月',
    },
    {
        title: '近三月',
    },
    {
        title: '近六月',
    },
    {
        title: '近一年',
    },
    {
        title: '全部',
    },
];
let firstReload = false;
interface CustomerAssetProps extends CustomerDetailCardProps {
    resize: number;
    customer_id: number;
}
const CustomerAsset = ({ resize, customer_id }: CustomerAssetProps) => {
    const [endAt, setEndAt] = useState(moment());
    const [data, setData] = useState<CustomerAssetModel['customer_asset_summary']>();
    const [beginAt, setBeginAt] = useState(moment().subtract(3, 'M'));
    // const [defaultBegin, setDefaultBegin] = useState(moment());
    const [showError, setShowError] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [fetchState, setParams] = useFetch({
        interfaces: QueryEarning,
        initialParams: {
            customer_id,
            begin_at: beginAt.format(momentFormat),
            end_at: endAt.format(momentFormat),
        },
    });
    useEffect(() => {
        if (firstReload === true) {
            setParams({
                customer_id,
                begin_at: moment().subtract(3, 'M').format(momentFormat),
                end_at: moment().format(momentFormat),
            });
        }
        firstReload = true;
    }, [customer_id, setParams]);
    const dispatch = useDispatch();
    const [refreshKey, setRefreshKey] = useState(0);
    const request = useCallback(() => {
        setRefreshKey((e) => e + 1);
    }, []);

    useEffect(() => {
        let isMounted = true;
        setData(undefined);
        setShowLoading(true);
        QueryAsset({ customer_id: customer_id })
            .then((e) => {
                if (isMounted) {
                    dispatch(setAssetModel(e.data));
                    setData(e.data.customer_asset_summary);
                    setShowError(false);
                }
            })
            .catch((e) => {
                if (isMounted) {
                    showErrorToast(e);
                    setShowError(true);
                }
            })
            .finally(() => {
                if (isMounted) {
                    setShowLoading(false);
                }
            });
        return () => {
            isMounted = false;
        };
    }, [customer_id, dispatch, refreshKey]);
    useEffect(() => {
        if (fetchState.data?.start_date) {
            setBeginAt(moment(fetchState.data.start_date, momentFormat));
            // setDefaultBegin(moment(fetchState.data.start_date, momentFormat));
        }
    }, [fetchState.data]);
    const dateChange = useCallback(
        (e) => {
            let begin = e[0]?.format(momentFormat) ?? undefined;
            let end = e[1].format(momentFormat);
            setParams({
                customer_id,
                end_at: end,
                begin_at: begin,
            });
            if (e[0] !== undefined) {
                setBeginAt(e[0]);
            }
            setEndAt(e[1]);
        },
        [setParams, customer_id]
    );
    const click = useCallback(
        (index: number) => {
            let begin: undefined | moment.Moment = moment();
            let end = moment();
            if (index === 4) {
                begin = undefined;
            } else {
                let monthNum = [1, 3, 6, 12];
                begin = moment().subtract(monthNum[index], 'M');
            }
            dateChange([begin, end]);
        },
        [dateChange]
    );
    return (
        <>
            {showError && (
                <Result
                    status="error"
                    title="加载失败，请点击重试"
                    extra={
                        <Button
                            type="primary"
                            onClick={() => {
                                request();
                            }}
                        >
                            重试
                        </Button>
                    }
                />
            )}
            {!showError && (
                <Spin spinning={showLoading}>
                    <div className={style.content}>
                        <div className={style.left}>
                            <div className={style.detailInfo}>
                                {AssetData.map((item, index) => {
                                    return (
                                        <div className={style.detailInfoItem} key={index}>
                                            <div className={style.title}>{item.title}</div>
                                            <div className={style.value}>{item.value(data)}</div>
                                        </div>
                                    );
                                })}
                            </div>

                            <div className={style.leftBottom}>
                                <RangePicker
                                    className={style.date}
                                    onChange={dateChange}
                                    value={[beginAt, endAt]}
                                    format={'YYYY-MM-DD'}
                                    disabledDate={(curMoment) => {
                                        if (curMoment.isBefore(moment())) {
                                            return false;
                                        }
                                        return true;
                                    }}
                                />
                                <div className={style.buttonContainer}>
                                    {ButtonData.map((item, index) => {
                                        return (
                                            <Button
                                                size="small"
                                                type="primary"
                                                key={item.title}
                                                onClick={() => click(index)}
                                            >
                                                {item.title}
                                            </Button>
                                        );
                                    })}
                                </div>
                                <div className={style.rate}>
                                    <Tooltip
                                        title="收益率 = [ ∏ ( T日净资产 / T-1日净资产 ) - 1 ] * 100%"
                                        overlayClassName={style.tooltip}
                                    >
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                    <span style={{ marginLeft: '8px' }}>收益率：</span>
                                    <span
                                        className={style.ratio}
                                        style={{
                                            color: configColor(fetchState.data?.earn_rate ?? ''),
                                        }}
                                    >
                                        {fetchState.data?.earn_rate ?? 0}
                                    </span>
                                </div>
                                <div className={style.total}>
                                    <span className={style.totalTitle}>净资产变动：</span>
                                    {fetchState.data?.c_earn ?? 0}
                                    <span className={style.currency}> USD </span>
                                </div>
                            </div>
                        </div>
                        <div className={style.right}>
                            <PositionCharts data={data} resize={resize} />
                        </div>
                    </div>
                </Spin>
            )}
        </>
    );
};
export default CustomerAsset;
