import React, { useState } from 'react';
import styles from './index.module.scss';
import { Button, Modal, Table, message } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { EventList, uploadEventTrack } from '../../service/requests/EventTrack';
import { showError } from '../RecommendationComponent/util';
import { useHistory } from 'react-router-dom';
import {
    Order,
    DetailField,
    cancelOrder,
    getOrderDetail,
    getOrderList,
} from '../../service/requests/OrderRequest';
import DetailTables from '../../components/DetailTables';

const OrderList = () => {
    const history = useHistory();
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [showProcessModal, setShowProcessModal] = useState(false);
    const [detailFields, setDetailFields] = useState<DetailField[]>([]);
    const [tableHeader, setTableHeader] = useState('');
    const { data: orderData, params, run: refresh, loading } = useRequest(getOrderList, {
        defaultParams: [{ current_page: 1, page_size: 20 }],
    });
    const columns: ColumnsType<Order> = [
        {
            title: '更新时间',
            dataIndex: 'updated_at',
            render: (value) => moment(value).format('YYYY-MM-DD HH:mm:ss'),
            width: 165,
        },
        {
            title: '下单人',
            dataIndex: ['member', 'name'],
            width: 120,
        },
        {
            title: '客户id - 客户昵称',
            dataIndex: ['customer', 'id'],
            render: (_, record) => `${record.customer.id} - ${record.customer.name}`,
            width: 160,
        },
        {
            title: '下单银行 - 银行账户',
            dataIndex: ['account', 'accountName'],
            render: (_, record) =>
                `${record.account.accountType} - ${record.account.accountName} - ${
                    record.account.vendorSubAccountId || ''
                }`,
            width: 210,
        },
        {
            title: '产品类型 - 挂钩标的',
            dataIndex: ['product_type'],
            render: (_, record) => `${record.product_type} - ${record.underlying_name}`,
            width: 250,
        },
        {
            title: '订单状态',
            dataIndex: 'status',
            render: (value, record) => (
                <span style={{ color: getStatusColor(value) }}>{record.status_text}</span>
            ),
            width: 128,
        },
        {
            title: '操作',
            dataIndex: 'action',
            render: (_, record) => (
                <div className={styles.action}>
                    <Button
                        type="primary"
                        onClick={() => {
                            onDetailClick(record.order_id);
                        }}
                    >
                        查看详情
                    </Button>
                    {record.status <= 2 && (
                        <>
                            <Button
                                type="primary"
                                onClick={() => {
                                    onEmailClick(record.order_id);
                                }}
                            >
                                发送邮件
                            </Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    onCancelClick(record.order_id);
                                }}
                            >
                                撤销
                            </Button>
                        </>
                    )}
                </div>
            ),
            fixed: 'right',
            width: 280,
        },
    ];
    const getStatusColor = (status: number): string => {
        let color = 'black';
        // 1.已下单 2.已经确认 3.已撤销 4.私行处理中
        switch (status) {
            case 1:
                color = 'red';
                break;
            case 2:
                color = 'orange';
                break;
            case 3:
                color = 'black';
                break;
            case 4:
                color = 'green';
                break;
        }
        return color;
    };
    const onDetailClick = (id: number) => {
        uploadEventTrack(EventList.OrderAction, 2, {
            action_type: 'detail',
        });
        getOrderDetail({ order_id: id }).then((res) => {
            if (res.data) {
                setDetailFields(res.data.order_detail.detail_fields);
                setTableHeader(res.data.order_detail.title);
            }

            setShowDetailModal(true);
        }, showError);
    };
    const onEmailClick = (id: number) => {
        uploadEventTrack(EventList.OrderAction, 2, {
            action_type: 'email',
        });
        history.push(`/app/trade/order/email?order_id=` + id);
    };
    const onCancelClick = (id: number) => {
        Modal.confirm({
            title: `确认撤销下单`,
            onOk: () => {
                uploadEventTrack(EventList.OrderAction, 2, {
                    action_type: 'cancel',
                });
                cancelOrder({ order_id: id }).then((res) => {
                    refresh({ current_page: 1, page_size: 20 });
                    message.success('撤销成功');
                }, showError);
            },
        });
    };
    return (
        <>
            <div className={styles.btnArea}>
                <Button
                    icon={<InfoCircleOutlined />}
                    type="primary"
                    onClick={() => {
                        setShowProcessModal(true);
                    }}
                >
                    订单状态流转示例
                </Button>
            </div>
            <div>
                <Table
                    rowKey={(record) => record.order_id}
                    loading={loading}
                    columns={columns}
                    dataSource={orderData?.data.order_list}
                    pagination={{
                        current: params[0]?.current_page,
                        pageSize: params[0]?.page_size,
                        total: orderData?.data.total_count,
                        onChange: (page, pageSize) => {
                            refresh({
                                current_page: page,
                                page_size: pageSize,
                            });
                        },
                        showSizeChanger: false,
                        showTitle: false,
                    }}
                    scroll={{
                        x: 1000,
                        y: document.getElementById('app-content')?.offsetHeight! - 150,
                    }}
                    size="small"
                />
            </div>
            <div>
                <Modal
                    visible={showDetailModal}
                    closable={false}
                    footer={null}
                    onCancel={() => {
                        setShowDetailModal(false);
                    }}
                    width={400}
                >
                    {detailFields.length > 0 && (
                        <DetailTables
                            title="下单详情"
                            details={[
                                {
                                    header: tableHeader,
                                    fields: detailFields,
                                },
                            ]}
                        />
                    )}
                </Modal>
                <Modal
                    visible={showProcessModal}
                    closable={false}
                    footer={null}
                    onCancel={() => {
                        setShowProcessModal(false);
                    }}
                    width="70%"
                >
                    <div className={styles.processImg} />
                </Modal>
            </div>
        </>
    );
};

export default OrderList;
