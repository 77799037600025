import React from 'react';
import { Tabs } from 'antd';
import styles from './index.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { EventList, uploadEventTrack } from '../../service/requests/EventTrack';
import ProductsPriceTable from '../GKProductPrice';
import { AssetType } from '../Transaction/type';

const Quotation = () => {
    const history = useHistory();
    const location = useLocation();
    return (
        <>
            <div className={styles.container}>
                <Tabs
                    size="large"
                    defaultActiveKey={location.hash.slice(1) || 'sp-dq"'}
                    onTabClick={(key) => {
                        if (key.endsWith('bonds')) {
                            uploadEventTrack(EventList.QuotationBondsTabClick, 2, {
                                tab_name: key,
                            });
                        }
                        history.push(`#${key}`);
                    }}
                >
                    {/* <Tabs.TabPane tab="DQ" key="sp-dq-old">
                        <StructuralProductsQuodation type={StructuredProductType.DQ} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="AQ" key="sp-aq-old">
                        <StructuralProductsQuodation type={StructuredProductType.AQ} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="FCN" key="sp-fcn-old">
                        <StructuralProductsQuodation type={StructuredProductType.FCN} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="SDN" key="sp-sdn">
                        <StructuralProductsQuodation type={StructuredProductType.SDN} />
                    </Tabs.TabPane> */}

                    {/* <Tabs.TabPane tab="Option" key="sp-option-old">
                        <StructuralProductsQuodation type={StructuredProductType.OPTION} />
                    </Tabs.TabPane> */}
                    <Tabs.TabPane tab="DQ" key="sp-dq">
                        <ProductsPriceTable type={AssetType.DQ} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="AQ" key="sp-aq">
                        <ProductsPriceTable type={AssetType.AQ} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="FCN" key="sp-fcn">
                        <ProductsPriceTable type={AssetType.FCN} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Option" key="sp-option">
                        <ProductsPriceTable type={AssetType.Option} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="ELN" key="sp-eln">
                        <ProductsPriceTable type={AssetType.ELN} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Corporate Bond" key="corporate-bond">
                        <ProductsPriceTable type={AssetType.CorporateBond} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Government Bond" key="government-bond">
                        <ProductsPriceTable type={AssetType.GovernmentBond} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="FUND" key="sp-fund">
                        <ProductsPriceTable type={AssetType.Fund} />
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </>
    );
};
export default Quotation;
