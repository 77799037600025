import axios from '../../service/tools';
import {
    BondQuotesDetails,
    ProductQuoteBondDetailType,
    ProductQuoteResponseType,
    UserAccountInfo,
} from './type';
import { BondType } from '../Quotation/request';
const queryQouteList = (data: { current_page: number; page_size: number }) => {
    return axios.post<ProductQuoteResponseType>('v5/admin/product/quote/list', { data });
};
const addBondRecord = (data: {
    customer_id: number;
    account_id: string;
    product_type: BondType;
}) => {
    return axios.post<{ product_quote: { id: number } }>('v5/admin/product/quote/add', { data });
};
const queryBondRecord = (data: { product_quote_id: number }) => {
    return axios.post<{ product_quote_detail: ProductQuoteBondDetailType }>(
        'v5/admin/product/quote/detail',
        { data }
    );
};
const calculateBondPrice = (data: { product_quote_detail: ProductQuoteBondDetailType }) => {
    return axios.post<{ product_quote_detail: ProductQuoteBondDetailType }>(
        'v5/admin/product/quote/calculate_and_check',
        { data }
    );
};
// 同步到消息中心
const syncMsg = (data: { product_quote_id: string }) => {
    return axios.post('v5/admin/product/quote/sync_to_msg_center', { data });
};
//查询客户下某个账户的现金持仓摘要及安全垫
const queryCashPositionSummary = (data: { product_quote_id: string }) => {
    return axios.post<UserAccountInfo>('v5/admin/product/quote/account_position/summary', { data });
};
//v5/admin/product/quote/save
//保存产品报价
const saveBondRecord = (data: { product_quote_detail: ProductQuoteBondDetailType }) => {
    return axios.post('v5/admin/product/quote/save', { data });
};
//v5/admin/product/quote/cancel
//撤销报价
const revokeBondRecord = (data: { product_quote_id: string }) => {
    return axios.post('v5/admin/product/quote/cancel', { data });
};
//确认报价并发送Lark提醒
//v5/admin/product/quote/confirm
const qouteConfirm = (data: { product_quote_id: string }) => {
    return axios.post('v5/admin/product/quote/confirm', { data });
};
// 发送email页面 获取详细信息
const getBondQuotesDetails = (data: { product_quote_id: string }) => {
    return axios.post<BondQuotesDetails>('v5/admin/product/quote/info', { data });
};

export {
    queryQouteList,
    qouteConfirm,
    revokeBondRecord,
    addBondRecord,
    queryBondRecord,
    calculateBondPrice,
    syncMsg,
    queryCashPositionSummary,
    saveBondRecord,
    getBondQuotesDetails,
};
