import { EditOutlined } from '@ant-design/icons';
import { Input, message, Tag } from 'antd';
import React, { useCallback, useState } from 'react';
import { showError, stopPropagation } from './util';
import { updateRecomTag } from '../../service';
import { RecomProductList } from '../../service/type';

interface EditTitleType {
    recomProduct: RecomProductList[0];
    handleRefreshList: () => void;
}

const EditTitle: React.FC<EditTitleType> = ({ handleRefreshList, recomProduct }) => {
    const [inputVisible, setInputVisible] = useState(false);

    const handleInputConfirm = useCallback(
        (e) => {
            if (recomProduct.name !== e.target.value) {
                updateRecomTag({
                    tag_id: recomProduct.tag_id,
                    name: e.target.value,
                    brief: recomProduct.brief,
                }).then(() => {
                    message.success('修改成功');
                    handleRefreshList();
                }, showError);
            }
            setInputVisible(false);
        },
        [handleRefreshList, recomProduct.brief, recomProduct.name, recomProduct.tag_id]
    );

    return (
        <span onClick={stopPropagation} onKeyDown={stopPropagation}>
            {inputVisible && (
                <Input
                    autoFocus
                    type="text"
                    defaultValue={recomProduct.name || ''}
                    style={{ width: 200, height: 22 }}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                />
            )}
            {!inputVisible && (
                <Tag
                    onClick={() => {
                        setInputVisible(true);
                    }}
                    style={{
                        fontSize: 14,
                        border: 'none',
                        backgroundColor: 'transparent',
                        padding: 0,
                    }}
                >
                    {recomProduct.name}
                    <EditOutlined style={{ marginLeft: 9, color: '#1890FF' }} />
                </Tag>
            )}
        </span>
    );
};

export default EditTitle;
