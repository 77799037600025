import { Button, message, Spin, Typography } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React, { useEffect, useState } from 'react';
import { getMorningPostEditorUrl, getMorningPostPreview, publishMorningPost } from '../../service';
import './index.less';

export default function MorningPostHelper() {
    const [editorSrc, setEditorSrc] = useState('');
    const [previewSrc, setPreviewSrc] = useState('');
    const [docId, setDocId] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [editorLoading, setEditorLoading] = useState(false);
    const [publishLoading, setPublishLoading] = useState(false);

    useEffect(() => {
        getMorningPostEditorUrl()
            .then((res) => {
                if (res.data['feiShuDocUrl']) {
                    setEditorSrc(res.data['feiShuDocUrl']);
                } else {
                    message.error('未能获取到飞书文档链接');
                }
            })
            .catch((err) => {
                console.log(err);
                message.error('未能获取到飞书文档链接');
            });
    }, []);

    const initPreview = () => {
        setEditorLoading(true);
        getMorningPostPreview()
            .then((res) => {
                setEditorLoading(false);
                if (res.data['previewUrl'] && res.data['docId']) {
                    setPreviewSrc(res.data['previewUrl'] + `?timestamp=${new Date().getTime()}}`);
                    setDocId(res.data['docId']);
                    setShowPreview(true);
                } else {
                    message.error('未能获取到预览信息');
                }
            })
            .catch((err) => {
                setEditorLoading(false);
                message.error('未能获取到预览信息');
                console.log(err);
            });
    };

    const publishPost = () => {
        setPublishLoading(true);
        publishMorningPost({ docId })
            .then(() => {
                setPublishLoading(false);
                setShowPreview(false);
                message.success('发布成功');
            })
            .catch((err) => {
                setPublishLoading(false);
                message.error('发布失败');
                console.log(err);
            });
    };

    return (
        <Spin size="large" wrapperClassName="postEditorSpinWrapper" spinning={editorLoading}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography.Title level={2} style={{ margin: '10px 0' }}>
                        选择生成早报素材
                    </Typography.Title>
                    <Button
                        style={{
                            marginLeft: '20px',
                            padding: '6.4px 25px',
                            color: 'white',
                            backgroundColor: '#52cc6f',
                            borderRadius: '4px',
                        }}
                        onClick={initPreview}
                        size="large"
                    >
                        预览日报
                    </Button>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexGrow: 1,
                    }}
                >
                    <iframe
                        title="editor"
                        src={editorSrc}
                        width="100%"
                        style={{ border: 0, flexGrow: 1 }}
                    />
                </div>

                <Modal
                    visible={showPreview}
                    closable={false}
                    centered
                    width="50vw"
                    footer={null}
                    style={{
                        maxWidth: '800px',
                    }}
                    onCancel={() => setShowPreview(false)}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '80vh',
                        }}
                    >
                        <iframe
                            title="preview"
                            src={previewSrc}
                            width="100%"
                            style={{ border: 0, flexGrow: 1 }}
                        />
                        <div style={{ marginTop: '12px' }}>
                            <Button onClick={() => setShowPreview(false)} type="default">
                                返回编辑
                            </Button>
                            <div style={{ width: '24px', display: 'inline-block' }} />
                            <Button type="primary" onClick={publishPost} loading={publishLoading}>
                                发布日报
                            </Button>
                            <div style={{ width: '24px', display: 'inline-block' }} />
                            <Button type="default" href={previewSrc} target="_blank">
                                在新标签页中打开
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </Spin>
    );
}
