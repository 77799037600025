import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import styles from './TransferInFormItem.module.less';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GinkgoInput, GinkgoInputNumber, GinkgoSelect } from './GinkgoFieldItems';
import { MakeUuid } from '../../../../../../utils';
import { GinkgoCurrencyType, isCashIsin } from '../../../../type';
export interface TransferInFormItemProps {
    value?: any;
    onChange?: (value: any) => void;
    readOnly?: boolean;
}
type productItem = {
    key: string;
    isin?: string;
    name?: string;
    amount?: number;
    qty?: number;
    type: 'cash' | 'other';
};
const { Option } = Select;
const TransferInFormItem = (data: TransferInFormItemProps) => {
    const [dataList, setDataList] = useState<Array<productItem>>(() => {
        if (Array.isArray(data.value)) {
            return data.value.map((item) => {
                let type = '';
                if (isCashIsin(item.isin)) {
                    type = 'cash';
                } else {
                    type = 'other';
                }
                return {
                    ...item,
                    type,
                    key: MakeUuid(),
                };
            });
        }
        return [];
    });
    const options = Object.keys(GinkgoCurrencyType).map((key) => ({
        label: key,
        value: GinkgoCurrencyType[key as keyof typeof GinkgoCurrencyType],
    }));
    useEffect(() => {
        if (Array.isArray(data.value)) {
            let newArray = data.value.map((item) => {
                let type = '';
                if (isCashIsin(item.isin)) {
                    type = 'cash';
                } else {
                    type = 'other';
                }
                return {
                    ...item,
                    type: item.type ?? type,
                    key: item.key ?? MakeUuid(),
                };
            });
            setDataList(newArray);
        }
    }, [data.value]);
    const handleChange = useCallback(
        (value: any, key: string, parmas: string) => {
            const newDataList = dataList.map((item) => {
                if (item.key === key) {
                    return {
                        ...item,
                        [parmas]: value,
                    };
                }
                return item;
            });
            data.onChange && data.onChange(newDataList);
            setDataList(newDataList);
        },
        [data, dataList]
    );
    const content = useMemo(() => {
        return dataList.map((item) => {
            if (item.type === 'other') {
                return (
                    <div className={styles.flexContainer} key={item.key}>
                        <div className={styles.flexItem}>
                            <GinkgoInput
                                defaultValue={item.isin}
                                value={item.isin}
                                readOnly={data.readOnly}
                                onChange={(value) => {
                                    handleChange(value.currentTarget.value, item.key, 'isin');
                                }}
                            />
                        </div>
                        <div className={styles.flexItem}>
                            <GinkgoInput
                                defaultValue={item.name}
                                value={item.name}
                                readOnly={data.readOnly}
                                onChange={(value) => {
                                    handleChange(value.currentTarget.value, item.key, 'name');
                                }}
                            />
                        </div>
                        <div className={styles.flexItem}>
                            <GinkgoInputNumber
                                value={item.amount ?? item.qty}
                                style={{
                                    width: '70%',
                                    marginRight: '10px',
                                    textAlign: 'right',
                                }}
                                readOnly={data.readOnly}
                                defaultValue={item.qty}
                                onChange={(value) => {
                                    handleChange(value, item.key, 'qty');
                                }}
                            />
                            {!(data.readOnly || dataList.length === 1) && (
                                <Button
                                    onClick={() => {
                                        setDataList((pre) => {
                                            let newItems = pre.filter(
                                                (filter) => filter.key !== item.key
                                            );
                                            data.onChange && data.onChange(newItems);
                                            return newItems;
                                        });
                                    }}
                                >
                                    <MinusCircleOutlined />
                                </Button>
                            )}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className={styles.flexContainer} key={item.key}>
                        <div className={styles.flexItem} style={{ textAlign: 'center' }}>
                            -
                        </div>
                        <GinkgoSelect
                            className={styles.flexItem}
                            onChange={(value) => {
                                handleChange(value, item.key, 'isin');
                            }}
                            readOnly={data.readOnly}
                            value={item.isin}
                            defaultValue={item.isin}
                        >
                            {options.map((item) => {
                                return (
                                    <Option value={item.value} key={item.value}>
                                        {item.label}
                                    </Option>
                                );
                            })}
                        </GinkgoSelect>
                        <div className={styles.flexItem}>
                            <GinkgoInputNumber
                                value={item.amount ?? item.qty}
                                style={{
                                    width: '70%',
                                    marginRight: '10px',
                                    textAlign: 'right',
                                }}
                                readOnly={data.readOnly}
                                defaultValue={item.amount}
                                onChange={(value) => {
                                    handleChange(value, item.key, 'amount');
                                }}
                            />
                            {!(data.readOnly || dataList.length === 1) && (
                                <Button
                                    onClick={() => {
                                        setDataList((pre) => {
                                            let newItems = pre.filter(
                                                (filter) => filter.key !== item.key
                                            );
                                            data.onChange && data.onChange(newItems);
                                            return newItems;
                                        });
                                    }}
                                >
                                    <MinusCircleOutlined />
                                </Button>
                            )}
                        </div>
                    </div>
                );
            }
        });
    }, [data, dataList, handleChange, options]);
    return (
        <>
            <div className={`${styles.flexContainer} ${styles.header}`}>
                <div className={styles.flexItem}>ISIN</div>
                <div className={styles.flexItem}>Name</div>
                <div className={styles.flexItem}>Transfer Amount</div>
            </div>
            {content}
            {!data.readOnly && (
                <div style={{ marginTop: '20px' }}>
                    <Button
                        onClick={() => {
                            const key = MakeUuid();
                            setDataList((prev) => {
                                data.onChange && data.onChange([...prev, { type: 'other', key }]);
                                return [...prev, { type: 'other', key }];
                            });
                        }}
                    >
                        <PlusCircleOutlined />
                        Add Security
                    </Button>
                    <Button
                        onClick={() => {
                            const key = MakeUuid();
                            setDataList((prev) => {
                                data.onChange && data.onChange([...prev, { type: 'cash', key }]);
                                return [...prev, { type: 'cash', key }];
                            });
                        }}
                    >
                        <PlusCircleOutlined />
                        Add Currency
                    </Button>
                </div>
            )}
        </>
    );
};

export default TransferInFormItem;
