import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import './index.less';
import { Button, Modal, Pagination, Tooltip } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TooltipCell } from '../../Activity/DailyConfig';
import { AlterSignInfoRes, getAlterNativeSingInfo } from '../../../service/requests/Sing';
import { showError } from '../../RecommendationComponent/util';
import { EditOutlined, FilePdfOutlined } from '@ant-design/icons';
import { SignScene } from '../request';
import { useLatest } from 'ahooks';
import SignAddModal from '../Component/SignAddModal';

const NEWSPAGESIZE = 15;
const CustomerSign = ({ refreshCall }: { refreshCall?: (call: () => void) => void }) => {
    const [offset, setOffset] = useState(0);
    const [totalNum, setTotalNum] = useState(0);
    const addVisible = useState(false);
    const [infoList, setInfoList] = useState<AlterSignInfoRes['document_list']>([]);
    const [currentSelect, setCurrentSelect] = useState<AlterSignInfoRes['document_list'][0]>();
    const latestSelect = useLatest(currentSelect);
    const [visible, setVisible] = useState(false);
    const handlePaginationChange = useCallback((page: number) => {
        setOffset((page - 1) * NEWSPAGESIZE);
    }, []);
    const columnDefs = useMemo<ColDef[]>(
        () => [
            {
                headerName: '文件链接',
                field: 'sign_url',
                width: 240,
                cellRenderer: (params: ICellRendererParams) => (
                    <Tooltip title={params.value}>
                        <Button
                            type="link"
                            style={{ paddingLeft: '0', color: 'blue', textDecoration: 'underline' }}
                            onClick={() => window.open(params.value)}
                        >
                            {params.value}
                        </Button>
                    </Tooltip>
                ),
            },
            {
                headerName: '签署模板',
                field: 'template_title',
                width: 100,
                cellRenderer: (params: ICellRendererParams) => <TooltipCell text={params.value} />,
            },
            {
                headerName: '签署人',
                field: 'customer_id',
                width: 200,
                cellRenderer: (params: ICellRendererParams) => (
                    <TooltipCell text={`${params.data.name}-${params.value}`} />
                ),
            },

            {
                headerName: '创建时间',
                width: 200,
                field: 'created_at',
                cellRenderer: (params: ICellRendererParams) => {
                    if (!params.data.newly_signed.updated_at) return <div />;
                    let timeStr = moment(params.data.newly_signed.updated_at).format(
                        'YYYY-MM-DD HH:mm:ss'
                    );
                    return <div>{timeStr}</div>;
                },
            },
            {
                headerName: '签署状态',
                field: 'signed_status',
                width: 100,
                cellRenderer: (params: ICellRendererParams) => (
                    <div style={{ color: params.value === 1 ? 'greenyellow' : '' }}>
                        {params.value === 1 ? '已签署' : '待签署'}
                    </div>
                ),
            },
            {
                headerName: '签署时间',
                minWidth: 200,
                flex: 1,
                field: 'signed_at',
                cellRenderer: (params: ICellRendererParams) => {
                    if (!params.value) return <div />;
                    let timeStr = moment(params.value).format('YYYY-MM-DD HH:mm:ss');
                    return <div>{timeStr}</div>;
                },
            },
            {
                headerName: '操作',
                pinned: 'right',
                width: 140,
                cellRenderer: (params: ICellRendererParams) => (
                    <>
                        <Tooltip title="修改">
                            <Button
                                type="link"
                                disabled={params.data.signed_status === 1}
                                onClick={() => {
                                    setCurrentSelect(params.data);
                                    addVisible[1](true);
                                }}
                            >
                                <EditOutlined />
                            </Button>
                        </Tooltip>

                        <Tooltip title="查看上传文件">
                            <Button
                                type="link"
                                style={{ marginLeft: 0 }}
                                disabled={
                                    params.data.newly_signed === null ||
                                    params.data.newly_signed === undefined ||
                                    params.data.newly_signed.upload_files?.length === 0
                                }
                                onClick={() => {
                                    setVisible(true);
                                    setCurrentSelect(params.data);
                                }}
                            >
                                <FilePdfOutlined />
                            </Button>
                        </Tooltip>
                    </>
                ),
            },
        ],
        [addVisible]
    );
    useEffect(() => {
        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offset]);
    useEffect(() => {
        if (refreshCall) {
            refreshCall(refresh);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshCall]);
    const refresh = () => {
        getAlterNativeSingInfo({
            limit: NEWSPAGESIZE,
            offset,
            scene: SignScene.CustomerAgreement,
        }).then((res) => {
            setTotalNum(res.data.total_count);
            setInfoList(res.data.document_list);
        }, showError);
    };

    return (
        <>
            <Modal footer={null} visible={visible} onCancel={() => setVisible(false)}>
                <div className="Sign_File_list">
                    {latestSelect.current &&
                        (latestSelect.current.newly_signed?.upload_files ?? []).map((item) => {
                            return (
                                <Button
                                    type="link"
                                    key={item.file_name}
                                    className="signFileItem"
                                    onClick={() => window.open(item.url)}
                                >
                                    {item.file_name}
                                </Button>
                            );
                        })}
                </div>
            </Modal>
            <SignAddModal
                visibleState={addVisible}
                editData={currentSelect}
                finish={() => refresh()}
            />
            <div className="ag-theme-alpine">
                <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={{ resizable: true, width: 96, minWidth: 68, suppressMenu: true }}
                    rowData={infoList}
                    domLayout="autoHeight"
                    className="CustomerList__Table"
                />
            </div>
            {totalNum > NEWSPAGESIZE && infoList.length > 0 && (
                <Pagination
                    onChange={handlePaginationChange}
                    total={totalNum}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: 30,
                    }}
                    pageSize={NEWSPAGESIZE}
                    defaultCurrent={1}
                    showSizeChanger={false}
                />
            )}
        </>
    );
};

export default CustomerSign;
