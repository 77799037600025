export const SET_LANG = 'SET_LANG';
export const SET_LOGIN = 'SET_LOGIN';
export const REMOVE_LOGIN = 'REMOVE_LOGIN';
export const SET_HAS_TOKEN = 'SET_HAS_TOKEN';
export const SET_CAN_USE_GET_USER_MEDIA = 'SET_CAN_USE_GET_USER_MEDIA';
export const PORTAL_LOGIN = 'PORTAL_LOGIN';
export const TRACK_RECORD = 'TRACK_RECORD';
export const TRACK_RECORD_TAGS = 'TRACK_RECORD_TAGS';
export const TRACK_RECORD_LIST = 'TRACK_RECORD_LIST';
export const ALL_TRACK_RECORD_LIST = 'ALL_TRACK_RECORD_LIST';
export const SAVE_PAGE_STATUS = 'SAVE_PAGE_STATUS';
export const IN_APP = 'IN_APP';
export const SET_PAGE_STATE = 'SET_PAGE_STATE';
export const SET_ASSET_MODEL = 'SET_ASSET_MODEL';
export const SET_ACTIVITY_MODEL = 'SET_ACTIVITY_MODEL';
export const SET_POSITION_MODEL = 'SET_POSITION_MODEL';
export const SET_CUSTOMER_MODEL = 'SET_CUSTOMER_MODEL';
export const CUSTOMER_DETAIL_CARD_REFRESHKEY = 'CUSTOMER_DETAIL_CARD_REFRESHKEY';
export const TESTKEY = 'TESTKEY';
export const SyncPageIndex = 'SyncPageIndex';
export const RECONCILIATION_DETAIL = 'RECONCILIATION_DETAIL';
export const POSITION_REFRESH = 'POSITION_REFRESH';
export const POSITION_TABINDEX = 'POSITION_TABINDEX';
