import { Col, Form, Input, Row, Select } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import { Asset, AssetBondExtInfo, AssetType } from '../../../Transaction/type';
import { getBondCreditPriorities, getBondCreditRatings } from '../../request';
import { ModalType } from '../..';
import { useForm } from 'antd/es/form/Form';
import { FormInstance } from 'antd-mobile/es/components/form';
import { searchProduct } from '../../../Transaction/request';
import { showError } from '../../../RecommendationComponent/util';

interface BondFormProps {
    type: AssetType;
    modalType: ModalType;
    asset?: Asset;
}
const BondForm = forwardRef<FormInstance, BondFormProps>((props, ref) => {
    const { type, modalType, asset } = props;
    const [form] = useForm();
    const [senioritys, setSenioritys] = useState([]);
    const [creditRatings, setCreditRatings] = useState([]);
    const [showTips, setShowTips] = useState(false);
    useEffect(() => {
        getBondCreditPriorities().then((res) => {
            setSenioritys(res.data);
        });

        getBondCreditRatings().then((res) => {
            setCreditRatings(res.data);
        });
    }, []);
    useEffect(() => {
        if (asset) {
            const extInfo = asset.ext_info as AssetBondExtInfo;
            form.setFieldsValue({
                isin: asset.isin,
                ext_info: {
                    credit_rating_sp: extInfo.credit_rating_sp,
                    issuer_cn: extInfo.issuer_cn,
                    seniority: extInfo.seniority,
                },
            });
        }
    }, [asset, form]);
    const onBlur = (e: any) => {
        if (e.currentTarget.value === '') {
            return;
        }
        searchProduct({
            asset_types: [AssetType.CorporateBond, AssetType.GovernmentBond],
            isin: e.currentTarget.value,
        }).then((res) => {
            setShowTips(res.data.assets?.length > 0);
        }, showError);
    };
    return (
        // @ts-ignore
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} ref={ref}>
            <Form.Item label="ISIN" name="isin" rules={[{ required: true, message: '请输入ISIN' }]}>
                <Input disabled={modalType === ModalType.Edit} onBlur={onBlur} />
            </Form.Item>
            {showTips && (
                <Row>
                    <Col offset={6} span={18}>
                        <div style={{ color: 'red' }}>债券已存在</div>
                    </Col>
                </Row>
            )}
            {type === AssetType.CorporateBond && (
                <>
                    <Form.Item
                        label="发行方-中"
                        name={['ext_info', 'issuer_cn']}
                        rules={[{ required: true, message: '请输入发行方-中' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="信用评级S&P"
                        name={['ext_info', 'credit_rating_sp']}
                        rules={[{ required: true, message: '请选择信用评级S&P' }]}
                    >
                        <Select
                            options={creditRatings.map((r: string) => ({
                                label: r,
                                value: r,
                            }))}
                        />
                    </Form.Item>
                    <Form.Item
                        label="优先级"
                        name={['ext_info', 'seniority']}
                        rules={[{ required: true, message: '请输入优先级' }]}
                    >
                        <Select
                            options={senioritys.map((r: string) => ({
                                label: r,
                                value: r,
                            }))}
                        />
                    </Form.Item>
                </>
            )}
        </Form>
    );
});
export default BondForm;
