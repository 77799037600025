import { Form, FormInstance, Select } from 'antd';
import React, { ReactNode, useContext, useEffect } from 'react';
import { BankFlow } from '../../type';
import { GinkgoInputNumber } from '../../../Transaction/components/TransForm/Forms/FormItems/GinkgoFieldItems';
import styles from './index.module.scss';
export enum EditableCellType {
    Input = 'Input',
    Select = 'Select',
}

export interface EditableCellProps<Item> {
    title: React.ReactNode;
    editable?: boolean;
    children?: React.ReactNode;
    dataIndex: any;
    record: Item;
    addonAfter?: ReactNode;
    verifiedStatus?: number; //1 通过 2 不通过
    required?: boolean;
    handleSave?: (record: Item) => void;
    options?: { key: string; value: string }[];
    type?: EditableCellType;
}
const EditableRow = ({ index, ...props }: { index: number }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableContext = React.createContext<FormInstance<any> | null>(null);

const SystemEditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    addonAfter,
    required,
    verifiedStatus,
    handleSave,
    type,
    options,
    ...restProps
}: EditableCellProps<BankFlow>) => {
    const form = useContext(EditableContext)!;
    const toggleEdit = () => {
        // setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex as keyof BankFlow] });
    };

    useEffect(() => {
        if (form && record) {
            form.setFieldsValue(record);
        }
    }, [record, form]);
    // const save = async (id?: QuoteRelatedTargetAction) => {
    const save = async () => {
        try {
            const values = await form.getFieldsValue();
            toggleEdit();
            handleSave && handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    // const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    //     const t = e.target.value.charAt(0);
    //     let v = e.target.value
    //         .replace(/[^\d.]/g, '')
    //         .replace(/\.{2,}/g, '.')
    //         .replace(/^\./, '')
    //         .replace('.', '$#$')
    //         .replace(/\./, '')
    //         .replace('$#$', '.');
    //     if (t === '-') {
    //         v = '-' + v;
    //     }
    //     setValue(v);
    // };
    const onBlur = async () => {
        const values = await form.getFieldsValue();
        handleSave && handleSave({ ...record, ...values });
    };
    const renderContent = () => {
        let item: ReactNode;
        switch (type) {
            case EditableCellType.Input:
                item = (
                    <GinkgoInputNumber
                        // ref={inputRef}
                        style={{
                            color: verifiedStatus === 1 ? 'rgba(0, 0, 0, 0.85)' : 'red',
                            width: '100%',
                            textAlign: 'center',
                        }}
                        className={styles.editableCellInput}
                        precision={2}
                        // value={value as string}
                        onPressEnter={() => save()}
                        onBlur={onBlur}
                        // onChange={onChange}
                        key={typeof type}
                        required={required}
                        addonAfter={addonAfter}
                    />
                );
                break;
            case EditableCellType.Select:
                item = (
                    <Select onSelect={save} key={typeof type}>
                        {options?.map((item) => (
                            <Select.Option key={item.key} value={item.value}>
                                {item.key}
                            </Select.Option>
                        ))}
                    </Select>
                );
        }

        return (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                {item}
            </Form.Item>
        );
    };
    let childNode = children;
    if (editable) {
        childNode = editable ? (
            renderContent()
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 24 }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};
export { SystemEditableCell, EditableRow };
