import { Button, Divider } from 'antd';
import React from 'react';
import {
    GinkgoTransFormRefType,
    TransStatus,
    TransactionInfo,
    TransactionOrderType,
} from '../../../type';
import styles from '../index.module.less';
const TransFormFooter = (data: GinkgoTransFormRefType & TransactionInfo) => {
    const backContent = () => {
        switch (data.status.code) {
            case TransStatus.Created:
                return (
                    <Button type="primary" danger onClick={() => data.onDelete && data.onDelete()}>
                        delete
                    </Button>
                );
            case TransStatus.Placeable:
                return (
                    <Button onClick={() => data.onBackCreated && data.onBackCreated()}>
                        Back To Created
                    </Button>
                );
            case TransStatus.Traded:
                if (data.type.code === TransactionOrderType['Create Adjustment']) {
                    return (
                        <Button onClick={() => data.onBackCreated && data.onBackCreated()}>
                            Back To Created
                        </Button>
                    );
                }
                return (
                    <Button onClick={() => data.onBackPlaceable && data.onBackPlaceable()}>
                        Back To Placeable
                    </Button>
                );
            default:
                return <div />;
        }
    };
    const nextContent = () => {
        switch (data.status.code) {
            case TransStatus.Created:
                if (data.type.code === TransactionOrderType['Create Adjustment']) {
                    return (
                        <Button onClick={() => data.onTraded && data.onTraded()} type="primary">
                            Traded
                        </Button>
                    );
                }
                return (
                    <Button onClick={() => data.onPlaceable && data.onPlaceable()} type="primary">
                        Placeable
                    </Button>
                );
            case TransStatus.Placeable:
                return (
                    <Button onClick={() => data.onTraded && data.onTraded()} type="primary">
                        Traded
                    </Button>
                );
            default:
                return <div />;
        }
    };
    return (
        <>
            <Divider />
            <div className={styles.flexSpace}>
                {backContent()}
                {data.status.code !== TransStatus.Traded && (
                    <Button onClick={data.onSave}>save</Button>
                )}
                {nextContent()}
            </div>
        </>
    );
};

export default TransFormFooter;
