import React from 'react';
import styles from './TableChart.module.scss';
import { Select, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { v4 as uuid } from 'uuid';

interface BarChartProps {
    title: string;
    time: string;
    count: number;
    unit: string;
    period: number;
    onPeriodChange: (num: number) => void;
    dataSource: any[];
    columns: ColumnsType<any>;
}
const BarChart = (props: BarChartProps) => {
    const { title, time, count, unit, period, onPeriodChange, dataSource, columns } = props;
    const option = [
        { label: '近7天', value: 7 },
        { label: '近30天', value: 30 },
        { label: '近60天', value: 60 },
    ];
    return (
        <div className={styles.pannel}>
            <div className={styles.titleArea}>
                <div className={styles.pannelTitle}>{title}</div>
                <Select
                    options={option}
                    value={period}
                    onChange={(num: number) => {
                        onPeriodChange(num);
                    }}
                />
            </div>
            <div className={styles.time}>{time}</div>
            <div>
                <span className={styles.count}>{count}</span>
                <span className={styles.countUnit}>{unit}</span>
            </div>
            <div>
                <Table
                    className={styles.table}
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    size="middle"
                    rowKey={(record) => uuid()}
                />
            </div>
        </div>
    );
};

export default BarChart;
