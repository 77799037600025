import { TranscationProductType } from '../../../../../../service/requests/CustomerManage';
import axios, { miniAxios } from '../../../../../../service/tools';
export type TranscationFormType = {
    title: string; //在前端展示的字段
    field_type: 'input_box_text' | 'input_box_number' | 'date_selection' | 'radio'; // 表单渲染样式包含：单选、数字输入框、文字输入框、日期选择、关联产品选择器
    required: number; //是否必填
    key: string; //数据库存储的字段，即在新增交易接口中需要填写的字段
    options?: Array<{ title: string; value: string }>; //表单渲染样式为单选时的选项
};
export type GetTransFieldsProps = {
    product_type: string;
    trans_type: number;
    action: number;
};
export type AddTranscationProps = {
    product_type: string;
    account_id: string;
    trans_type: number;
    action: number;
    trans_detail_fields: {
        [key: string]: string | number | undefined;
    };
    fieldTitleMap: Map<string, string>;
};
interface DataItem {
    title: string;
    value: string;
    key: string;
}

interface Section {
    name: string;
    data: DataItem[];
    type: string;
}

interface TransRecordType {
    amount: string;
    asset_class: string;
    currency: string;
    delivery: number;
    display_template: string;
    has_product_detail: number;
    isin: string;
    name: null;
    other_fields: null;
    product_icon: null;
    sections: Section[];
    ext_info: {
        add_trans_field_save: {
            account_index: number;
            assetIndex: number;
            currency: string;
            customer_id: number;
            matched_price: number;
            matched_qty: number;
            product: number;
            settle_date: string;
            trade_date: string;
            trans_id: string;
            transcationrecord: number;
            underlying: string;
            underlyings: string;
            [key: string]: any;
        };
    };
    title: string;
    trans_detail_type: string;
    trans_detail_type_icon: string;
    trans_text: string;
}

const requestTransactionType = () => {
    return axios.post<{ types: TranscationProductType[] }>(
        '/v1/admin/asset/transaction_product_type/all'
    );
};
const getTranscationFields = (data: GetTransFieldsProps) => {
    return axios.post<{ detail_fields: TranscationFormType[] }>(
        '/v1/admin/asset/transaction_detail_fields',
        { data }
    );
};

const addTranscationRecord = (data: AddTranscationProps) => {
    return axios.post<{ trans_id: string }>('/v1/admin/asset/account_transaction/add', { data });
};

const requestTransById = (data: { trans_id: string; account_id: string }) => {
    return axios.post<TransRecordType>('/v1/admin/asset/account_trans_detail', { data });
};
const updateTransById = (data: AddTranscationProps) => {
    return axios.post('/v1/admin/asset/account_trans_detail/update', { data });
};
const parsePdfRecord = (data: { url: string }) => {
    return miniAxios.post<{ fieldsParsed: { [key: string]: string }; sourceText: string }>(
        '/v1/bankFile/parseOrderCertFileUrl',
        { data }
    );
};
const deleteTransRecord = (data: { account_id: string; trans_id: string }) => {
    return axios.post('/v1/admin/asset/account_trans_detail/delete', { data });
};
export type { TransRecordType };
export {
    requestTransactionType,
    addTranscationRecord,
    getTranscationFields,
    requestTransById,
    parsePdfRecord,
    deleteTransRecord,
    updateTransById,
};
