import style from './index.module.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, ValueGetterParams } from 'ag-grid-community';
import {
    BankInfo,
    CustomerPositionData,
    ExchangeModel,
    QuertPosition,
} from '../../../../service/requests/CustomerManage';
import { Button, message, Modal, Result, Spin, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { showError as showErrorToast } from '../../../RecommendationComponent/util';
import {
    deleteReceiptFile,
    newUploadPdf,
} from '../../../StructuralProducts/HistoricalTransactions/requests';
import { EventList, uploadEventTrack } from '../../../../service/requests/EventTrack';
import { CustomerDetailCardProps } from '../CustomerDetail/type';
const CustomerPosition = ({ customer_id }: CustomerDetailCardProps) => {
    const [curIndex, setCurIndex] = useState(0);
    const [data, setData] = useState<CustomerPositionData['sec_cates']>();
    const [visible, setVisible] = useState(false);
    const [addVisible, setAddVisible] = useState(false);
    const [pdfStr, setPdfStr] = useState<{ url: string; name: string }[]>([]);
    const [pdfUploading, setPdfUploading] = useState(false);

    const array = useMemo<{ title: string; value: string }[]>(() => {
        return (data ?? []).map((item) => ({
            title: item.name,
            value: `$ ${item.c_hold_value}`,
        }));
    }, [data]);
    const [showError, setShowError] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    const requestPosition = useCallback(() => {
        setRefreshKey((e) => e + 1);
    }, []);
    const [showLoading, setShowLoading] = useState(false);

    useEffect(() => {
        let isMounted = true;
        setData(undefined);
        setShowLoading(true);
        QuertPosition({ customer_id: customer_id })
            .then((e) => {
                if (isMounted) {
                    setData(e.data.sec_cates);
                    setShowError(false);
                }
            })
            .catch((e) => {
                if (isMounted) {
                    showErrorToast(e);
                    setShowError(true);
                }
            })
            .finally(() => {
                if (isMounted) {
                    setShowLoading(false);
                }
            });
        return () => {
            isMounted = false;
        };
    }, [customer_id, requestPosition, refreshKey]);
    const cashData = useMemo(() => {
        let bankArray: BankInfo[] = [];
        if (data) {
            let originData = data[0].positions;
            originData.forEach((cashTypeData) => {
                cashTypeData.sub_position_details?.forEach((detail) => {
                    detail.details.forEach((bankInfo) => {
                        bankInfo.cashType = cashTypeData.symbol;
                        bankArray.push(bankInfo);
                    });
                });
            });
        }
        return bankArray;
    }, [data]);
    const rowData = useMemo(() => {
        if (data) {
            if (curIndex === 0) {
                return cashData;
            } else {
                return data[curIndex].positions ?? [];
            }
        }
        return [];
    }, [data, curIndex, cashData]);

    const [selectIndex, setSelectIndex] = useState<number | undefined>(undefined);

    const currentSelect = useMemo(() => {
        if (selectIndex !== undefined && data) {
            return data[curIndex].positions[selectIndex];
        }
        return undefined;
    }, [data, selectIndex, curIndex]);
    useEffect(() => {
        if (data) {
            for (let i = 0; i < 5; i++) {
                let el = document.getElementById(`positionAction${i}`);
                if (el) {
                    if (data.length > i) {
                        let mid = data[i].c_hold_value.length + 2 > 8;
                        let small = data[i].c_hold_value.length + 2 > 12;
                        let vsmall = data[i].c_hold_value.length + 2 > 16;
                        el.style.fontSize = '16px';
                        if (mid) {
                            el.style.fontSize = '15px';
                        }
                        if (small) {
                            el.style.fontSize = '14px';
                        }
                        if (vsmall) {
                            el.style.fontSize = '12px';
                        }
                    }
                }
            }
        }
    }, [array, data]);
    const colDefs = useMemo<ColDef[]>(() => {
        if (!data) return [];
        const cashDefs = [
            { field: 'cashType', headerName: '类型', flex: 1 },
            {
                headerName: '银行',
                flex: 1,
                field: 'bank_name',
            },
            { field: 'vendor_sub_account_id', headerName: '账户', flex: 1 },
            { field: 'currency', headerName: '币种', flex: 1 },
            { field: 'c_total', headerName: '金额', flex: 1 },
        ];
        let allDefs: ColDef[] = [
            { field: 'book_value_currency', headerName: '币种', width: 40 },
            { field: 'name', headerName: '产品', width: 100 },
            { field: 'c_aggr_balance', headerName: '数量', width: 80 },
            {
                field: '',
                headerName: '现价 | 成本',
                width: 180,
                valueGetter: (params: ValueGetterParams) => {
                    return `${params.data.c_cur_price} | ${params.data.c_cost_price}`;
                },
            },
            { field: 'c_hold_value', headerName: '市值', width: 180 },
            {
                headerName: 'USD市值',
                flex: 1,
                minWidth: 120,
                valueGetter: (params: ValueGetterParams) => {
                    let index = params.data.hold_value_exchange?.findIndex(
                        (item: ExchangeModel) => {
                            return item.to_currency === 'USD';
                        }
                    );
                    return index > -1 ? params.data.hold_value_exchange[index].c_to_amount : '0';
                },
            },
        ];

        let productTypeName = data[curIndex].name;
        const stockFields = [
            {
                headerName: '锁定股数',
                width: 100,
                valueGetter: (params: ValueGetterParams) => {
                    return params.data.ext_info.lock_up_num;
                },
            },
            {
                headerName: '可交易股数',
                width: 100,
                valueGetter: (params: ValueGetterParams) => {
                    return params.data.ext_info.tradable_num;
                },
            },
        ];

        if (productTypeName === '股票') {
            allDefs = [...allDefs, ...stockFields];
        }

        return productTypeName !== '现金' ? allDefs : cashDefs;
    }, [curIndex, data]);
    const parsePDF = useCallback((props: UploadRequestOption) => {
        const reader = new FileReader();
        reader.onload = () => {
            const data = reader.result as string;
            props.onSuccess?.(data);
        };
        reader.onerror = () => {
            props.onError?.(new Error('读取文件失败'));
        };
        if (typeof props.file !== 'string') {
            reader.readAsDataURL(props.file);
        }
    }, []);
    const uploadChange = useCallback((info: UploadChangeParam) => {
        let array = info.fileList.map((item) => {
            return { name: item.name ?? '', url: item.response };
        });
        setPdfStr(array);
    }, []);
    const deleteReceipt = useCallback(
        (item) => {
            Modal.confirm({
                title: `确认将${item.file_name}删除?`,
                onOk: () => {
                    deleteReceiptFile({ id: item.id }).then((res) => {
                        requestPosition();
                        message.success('删除成功');
                    }, showErrorToast);
                },
            });
        },
        [requestPosition]
    );
    const handleUploadPdf = useCallback(() => {
        if (currentSelect !== undefined && pdfStr.length > 0) {
            setPdfUploading(true);
            let promiseArray = pdfStr.map((item) =>
                newUploadPdf({
                    file_encode: item.url,
                    isin: currentSelect.isin,
                    account_id: currentSelect.account_id,
                    file_name: item.name,
                })
            );
            Promise.all(promiseArray)
                .then(() => {
                    message.success('上传成功');
                    setAddVisible(false);
                    setPdfStr([]);
                    requestPosition();
                }, showErrorToast)
                .finally(() => setPdfUploading(false));
        } else {
            message.error('请先选择文件');
        }
    }, [currentSelect, pdfStr, requestPosition]);
    return (
        <>
            {showError && (
                <Result
                    status="error"
                    title="加载失败，请点击重试"
                    extra={
                        <Button
                            type="primary"
                            onClick={() => {
                                requestPosition();
                            }}
                        >
                            重试
                        </Button>
                    }
                />
            )}
            <Modal
                title="上传银行回执单"
                destroyOnClose
                visible={addVisible}
                centered
                onCancel={() => {
                    setAddVisible(false);
                    setSelectIndex(undefined);
                    setPdfStr([]);
                }}
                onOk={handleUploadPdf}
                confirmLoading={pdfUploading}
                cancelText="取消"
                okText="上传"
            >
                <Upload
                    // accept=".pdf"
                    onChange={uploadChange}
                    multiple
                    maxCount={10}
                    customRequest={parsePDF}
                >
                    <Button icon={<UploadOutlined />}>点击上传</Button>
                </Upload>
            </Modal>
            <Modal
                title="查看文件"
                visible={visible}
                footer={null}
                onCancel={() => {
                    setSelectIndex(undefined);
                    setVisible(false);
                }}
            >
                {currentSelect?.termsheets?.map((item, index) => (
                    <div style={{ display: 'flex', marginTop: '20px' }} key={index}>
                        <Button
                            type="link"
                            style={{ textAlign: 'left', flex: '1', color: 'blue' }}
                            onClick={() => {
                                window.open(item.file_url);
                            }}
                        >
                            {item.file_name}
                        </Button>
                        <Button
                            type="primary"
                            danger
                            onClick={() => {
                                deleteReceipt(item);
                            }}
                        >
                            删除
                        </Button>
                    </div>
                ))}
            </Modal>

            {!showError && (
                <Spin spinning={showLoading}>
                    <div className={style.content}>
                        <div className={style.actions}>
                            {array.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        id={`positionAction${index}`}
                                        className={`${style.item} ${
                                            curIndex === index ? style.select : ''
                                        }`}
                                        onClick={() => {
                                            uploadEventTrack(
                                                EventList.CustomerListPostionFilter,
                                                2,
                                                {
                                                    name: item.title,
                                                }
                                            );
                                            setCurIndex(index);
                                        }}
                                    >
                                        <div className={style.title}>{item.title}</div>
                                        <div className={style.value}>{item.value}</div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="ag-theme-alpine CustomerManage__TableContainer">
                            <AgGridReact
                                animateRows
                                columnDefs={colDefs}
                                defaultColDef={{
                                    resizable: true,
                                    minWidth: 68,
                                    suppressMenu: true,
                                }}
                                rowData={rowData}
                                domLayout="autoHeight"
                                enableCellTextSelection
                                ensureDomOrder
                            />
                        </div>
                    </div>
                </Spin>
            )}
        </>
    );
};
export default CustomerPosition;
