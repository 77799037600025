import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Table, TableColumnType } from 'antd';
import { EditableCell, EditableRow, EditableCellProps, EditableCellType } from '../EditableCell';
import { BondQuoteEntity, BondType } from '../../../Quotation/request';
import { BondQuoteTableColumnType, getColumns } from '../../constValue';
import { ProductQuoteBondDetailType } from '../../type';
interface QuoteAllTableProps {
    data: ProductQuoteBondDetailType;
    bondType: BondType;
    handleSelectCaluclate: (selectedList: BondQuoteEntity[]) => void;
}
type columnType = (TableColumnType<BondQuoteEntity> & {
    editable?: boolean;
    type?: EditableCellType;
    options?: { key: string; value: any }[];
    addonAfter?: ReactNode;
})[];
const QuoteSubTable = ({ data, bondType, handleSelectCaluclate }: QuoteAllTableProps) => {
    const [dataSource, setDataSource] = useState<BondQuoteEntity[]>(() => {
        // return (data.selected_quotes ?? []).map((item) => ({
        //     ...item,
        //     nominal_amount: item.nominal_amount ?? data.minimum_transaction_amount,
        //     order_type: typeof item.order_type === 'number' ? item.order_type : 2,
        //     limit_price: item.limit_price ?? 100,
        // }));
        return [];
    });
    type EditableTableProps = Parameters<typeof Table>[0];
    type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;
    useEffect(() => {
        let result = data.selected_quotes?.map((item) => {
            return {
                ...item,
                nominal_amount: item.nominal_amount ?? data.minimum_transaction_amount,
                order_type: typeof item.order_type === 'number' ? item.order_type : 2,
                limit_price: item.limit_price ?? 100,
            };
        });
        setDataSource(result);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.selected_quotes]);
    const originColumns = getColumns(bondType, BondQuoteTableColumnType.Select);
    const corColumns: columnType = [
        {
            title: `名义金额-${data.currency}`,
            dataIndex: 'nominal_amount',
            editable: data.status < 3,
            type: EditableCellType.Input,
            width: 150,
        },
        {
            title: `交割金额-${data.currency}`,
            dataIndex: 'delivery_amount',
            width: 150,
        },
        {
            title: '下单类型',
            dataIndex: 'order_type',
            editable: data.status < 3,
            type: EditableCellType.Select,
            options: [
                { key: '市价单', value: 1 },
                { key: '限价单', value: 2 },
            ],
            width: 150,
            render(value, record, index) {
                return value === 1 ? '市价单' : '限价单';
            },
        },
        {
            title: '限价%',
            dataIndex: 'limit_price',
            editable: data.status < 3,
            type: EditableCellType.Input,
            width: 150,
            render(value, record, index) {
                return record.order_type === 2 ? value : '--';
            },
        },
    ];
    const defaultColumns: columnType = [...corColumns, ...originColumns];
    const handleSave = (row: BondQuoteEntity) => {
        const newData = [...dataSource];
        const index = newData.findIndex((item) => row.bond_price_id === item.bond_price_id);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        handleSelectCaluclate(newData);
        setDataSource(newData);
    };

    const columns = defaultColumns?.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: EditableCellProps<BondQuoteEntity>) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                type: col.type,
                options: col.options,
                handleSave,
            }),
        };
    });
    const resultData = useMemo(() => {
        let resultData = dataSource?.sort((a, b) => {
            return parseFloat(b.coupon as string) - parseFloat(a.coupon as string);
        });
        return resultData;
    }, [dataSource]);
    return (
        <Table
            components={{
                body: {
                    cell: EditableCell,
                    row: EditableRow,
                },
            }}
            columns={columns as ColumnTypes}
            style={{ marginTop: '16px' }}
            dataSource={resultData}
            size="small"
            onRow={(record) => {
                return {};
            }}
            pagination={false}
            rowKey="bond_price_id"
            scroll={{ x: 'max-content' }}
        />
    );
};

export default QuoteSubTable;
