import { Modal, Form, InputNumber, Button, Select } from 'antd';
import React, { useCallback } from 'react';
import { addAssetAllocBalance } from '../../../service';
import { showError } from '../../RecommendationComponent/util';

const { Option } = Select;
const UpdateAssetAssetModal = ({
    visible,
    setVisible,
    pkg_id,
    handleRefreshList,
}: {
    visible: boolean;
    setVisible: (boolean: boolean) => void;
    handleRefreshList: () => void;
    pkg_id: string;
}) => {
    const onFinish = useCallback(
        (values: { amount: string; isin: string; type: string }) => {
            addAssetAllocBalance({ pkg_id, ...values }).then((res) => {
                setVisible(false);
                handleRefreshList();
            }, showError);
        },
        [pkg_id, setVisible, handleRefreshList]
    );

    return (
        <Modal
            title="添加资产"
            destroyOnClose
            footer={null}
            visible={visible}
            okText="确认"
            cancelText="取消"
            onCancel={() => {
                setVisible(false);
            }}
            width={700}
            centered
        >
            <Form
                name="updateasset"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
            >
                <Form.Item
                    label="币种"
                    name="isin"
                    rules={[{ required: true, message: '请选择币种' }]}
                >
                    <Select showArrow={false} filterOption={false} notFoundContent={null}>
                        <Option key="CNH" value="CNH">
                            人民币
                        </Option>
                        <Option key="HKD" value="HKD">
                            港币
                        </Option>
                        <Option key="USD" value="USD">
                            美元
                        </Option>
                        <Option key="SGD" value="SGD">
                            新币
                        </Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="变动方式"
                    name="type"
                    rules={[{ required: true, message: '请选择变动方式' }]}
                >
                    <Select showArrow={false} filterOption={false} notFoundContent={null}>
                        <Option key="balance" value="balance">
                            入金
                        </Option>
                        <Option key="withdraw" value="withdraw">
                            出金
                        </Option>
                        <Option key="debit" value="debit">
                            贷款
                        </Option>
                        <Option key="repay" value="repay">
                            偿还
                        </Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="数量"
                    name="amount"
                    rules={[{ required: true, message: '请输入数量' }]}
                >
                    <InputNumber min={0} style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default UpdateAssetAssetModal;
