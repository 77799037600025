import React, { useState } from 'react';
import LKRadioGroup from '../../../../components/LKRadioGroup';
import { Button, Divider } from 'antd';
import { TransactionOrderType } from '../../type';
import { getManagedClients, updateTrans } from '../../request';
import { showError } from '../../../RecommendationComponent/util';
import { useRequest } from 'ahooks';
import InitialClientSelection from '../InitialClientSelection';
interface AddTransProps {
    onClosed: (trans_id: number) => void;
}
const AddTrans = ({ onClosed }: AddTransProps) => {
    const [selectProduct, setSelectProduct] = useState<TransactionOrderType>();
    const [clientId, setClientId] = useState<number>();

    const [loading, setLoading] = useState(false);
    const productData = Object.keys(TransactionOrderType)
        .filter((key) => Number(TransactionOrderType[key as keyof typeof TransactionOrderType]))
        .map((key) => ({
            label: key,
            value: Number(TransactionOrderType[key as keyof typeof TransactionOrderType]),
        }));
    const { data: clientData } = useRequest(getManagedClients, {
        defaultParams: [{ is_need_bank: 1 }],
        onBefore() {
            setLoading(true);
        },
        onFinally() {
            setLoading(false);
        },
    });
    const handleBankChange = (e: any) => {
        setSelectProduct(e);
    };

    return (
        <>
            {clientData && (
                <InitialClientSelection
                    clients={clientData?.data.clients!}
                    onChange={(c) => {
                        setClientId(c?.id);
                    }}
                />
            )}
            <Divider />
            <LKRadioGroup
                itemWidth="15%"
                datasource={productData}
                defaultValue={[selectProduct]}
                onChange={handleBankChange}
            />
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    type="primary"
                    loading={loading}
                    disabled={clientId === undefined || selectProduct === undefined}
                    onClick={() => {
                        setLoading(true);
                        updateTrans({
                            transaction_type: selectProduct!,
                            client_id: clientId!,
                        })
                            .then((res) => {
                                onClosed(res.data.id);
                            })
                            .catch(showError)
                            .finally(() => setLoading(false));
                    }}
                >
                    Create Transactions
                </Button>
            </div>
        </>
    );
};
export default AddTrans;
