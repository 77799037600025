import { HostType } from '../config';
import portalAxios, { multiHostAxios } from '../tools';
import { CustomerManageData } from './CustomerManage';

export interface SimpleUser {
    uid: string;
    name: string;
}

export interface ValidGroupInfo {
    customer_num: number;
    customers: CustomerManageData['pageData'];
    state: string;
}

export interface ParamsMap {
    defaultValue: string;
    hint: string;
    key: string;
}
export interface LandingPage {
    name: string;
    /** 落地页地址 */
    lp: string;
    lp_prefix: string;
    paramsMap: Array<ParamsMap>;
}

interface PushTaskCreation {
    title: string;
    body: string;
    uids: string[];
    lp: string;
    is_all_users: number;
    push_at: number;
    by_user_id: string;
}

interface PushTask {
    users: SimpleUser[];
    status: number;
    title: string;
    body: string;
    task_id: number;
    push_at: number;
    by_user: SimpleUser;
}

const axios = multiHostAxios(HostType.PUSH);

export const searchUserVague = (data: { keyword: string }) => {
    return axios.post<SimpleUser[]>('/v1/admin/user/vague_query', { data });
};

export const getLandingPageList = () => {
    return axios.post<{ lps: LandingPage[] }>('/v1/admin/lp_list', { data: {} });
};

export const createPushTask = (data: PushTaskCreation) => {
    return axios.post('/v1/admin/push_task/add', { data });
};

export const getPushHistory = (data: { offset: number; limit: number }) => {
    return axios.post<{ tasks: PushTask[]; size: number }>('/v1/admin/push_task_list', { data });
};

export const cancelPushTask = (data: { task_id: string }) => {
    return axios.post('/v1/admin/push_task/cancel', { data });
};

export const getCustomerStatus = () => {
    return portalAxios.post<ValidGroupInfo[]>('/v2/admin/customer/valid_group', {});
};
