import React, { useEffect, useMemo, useState } from 'react';
import LKRadioGroup from '../../../../components/LKRadioGroup';
import { Button, Divider, Modal, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import InitialClientSelection from '../InitialClientSelection';
import { Bank, TransactionInfo } from '../../type';
import { useRequest } from 'ahooks';
import { getTransactionsByclientbank, getManagedClients } from '../../request';
import { formatDate } from '../../Detail/utils';

interface EmailModalProps {
    visible: boolean;
    onCancel: () => void;
}

const EmailModal = (props: EmailModalProps) => {
    const { visible, onCancel } = props;
    const history = useHistory();
    const [banks, setBanks] = useState<Bank[]>([]);
    const [bankId, setBankId] = useState<string | undefined>();
    const [clientId, setClientId] = useState<number>();
    const [transList, setTransList] = useState<TransactionInfo[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const { data: clientData, loading } = useRequest(getManagedClients, {
        defaultParams: [{ is_need_bank: 1 }],
    });
    const { data: transData, loading: tableLoading, run } = useRequest(
        getTransactionsByclientbank,
        {
            manual: true,
        }
    );
    const filterBanks = useMemo(() => {
        return banks.map((b) => ({
            label: b.short_name,
            value: b.id,
        }));
    }, [banks]);
    const columns: ColumnsType<TransactionInfo> = [
        {
            title: 'Trade Type',
            dataIndex: ['type', 'desc'],
        },
        {
            title: 'Account',
            dataIndex: 'account',
            render: (_, record) => (
                <>{`${record.account.bank_name}-${record.account.account_name}-${
                    record.account.vendor_sub_account_id || ''
                }`}</>
            ),
        },
        {
            title: 'Sent Email Date',
            dataIndex: 'email_send_date',
            render: (value) => <>{formatDate(value)}</>,
        },
    ];
    useEffect(() => {
        if (transData) {
            setTransList(transData.data.transactions);
        }
    }, [transData]);
    const onBankChange = (value: any) => {
        setBankId(value);
        run({
            client_id: clientId!,
            bank_id: value,
        });
        setSelectedRowKeys([]);
    };
    const onSelecteChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const onPreviewClick = () => {
        history.push(
            `/app/trade/transaction/email?bank_id=${bankId}&trans_id=${selectedRowKeys.join('_')}`
        );
    };

    return (
        <Modal
            visible={visible}
            footer={null}
            title="Choose Client/Bank/Placeable Transactions"
            maskClosable
            destroyOnClose
            onCancel={onCancel}
            width={1240}
        >
            <Spin spinning={loading}>
                {clientData && (
                    <>
                        <InitialClientSelection
                            clients={clientData?.data.clients!}
                            onChange={(c) => {
                                setClientId(c?.id);
                                setBankId(undefined);
                                setBanks(c?.banks || []);
                                setTransList([]);
                            }}
                        />
                        <Divider />
                        {filterBanks.length > 0 ? (
                            <div>
                                <LKRadioGroup
                                    datasource={filterBanks}
                                    value={bankId}
                                    onChange={onBankChange}
                                    itemWidth="6.25%"
                                />
                            </div>
                        ) : (
                            'no bank under the selected client.'
                        )}
                        <Divider />
                        <Table
                            loading={tableLoading}
                            columns={columns}
                            size="small"
                            dataSource={transList}
                            pagination={false}
                            rowKey={(record) => record.id}
                            rowSelection={{
                                selectedRowKeys,
                                onChange: onSelecteChange,
                            }}
                        />
                        {selectedRowKeys.length} selected
                        <Divider />
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={onCancel}>Cancel</Button>
                            <Button
                                type="primary"
                                disabled={selectedRowKeys.length === 0}
                                onClick={onPreviewClick}
                            >
                                Preview Email
                            </Button>
                        </div>
                    </>
                )}
            </Spin>
        </Modal>
    );
};

export default EmailModal;
