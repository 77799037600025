import {
    CellClassParams,
    CellClassRules,
    ColDef,
    ICellRendererParams,
    RowDragEndEvent,
    RowDragMoveEvent,
    RowNode,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { Button, Divider, message, ModalProps, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { showError } from '../../RecommendationComponent/util';
import DissociatedOperationList from './DissociatedOperationList';
import {
    Authority,
    AuthorityOperation,
    AuthorityTreeData,
    deleteMenuAuthority,
    getAuthorityTree,
    setMenuOrder,
} from './requests';
import useForceUpdate from '../../../utils/Hooks/useForceUpdate';
import LookButton from '../../../components/LookButton';
import { green } from '@ant-design/colors';
import AddMenuModal from './AddMenuModal';
import './index.less';
import EditMenuModal from './EditMenuModal';
import EditAPIModal from './EditAPIModal';

export interface DefaultModalProps extends ModalProps {
    setVisible: React.Dispatch<React.SetStateAction<boolean>>;
    refreshData: () => void;
}

export interface AuthorityTableRow {
    id: number;
    name: string;
    entrance: string;
    parentId: number;
    hierarchy: string[];
    children: Authority[];
    operations: AuthorityOperation[];
}

export default function AuthorityTree() {
    const [authorityTree, setAuthorityTree] = useState<AuthorityTreeData>();
    const forceUpdate = useForceUpdate(() => {
        getAuthorityTree().then((res) => setAuthorityTree(res.data), showError);
    }, []);

    const [rowData, setRowData] = useState<AuthorityTableRow[]>([]);

    useEffect(() => {
        if (authorityTree) {
            const newData: AuthorityTableRow[] = [];
            for (let authority of authorityTree.authorities) {
                newData.push({
                    id: authority.id,
                    name: authority.name,
                    entrance: authority.entrance,
                    parentId: authority.parentId,
                    children: authority.children ?? [],
                    hierarchy: [authority.name],
                    operations: authority.operations,
                });
                for (let authChild of authority.children ?? []) {
                    newData.push({
                        id: authChild.id,
                        name: authChild.name,
                        entrance: authChild.entrance,
                        parentId: authChild.parentId,
                        children: authority.children ?? [],
                        hierarchy: [authority.name, authChild.name],
                        operations: authChild.operations,
                    });
                }
            }

            setRowData(newData);
        }
    }, [authorityTree]);

    const handleDeleteMenu = useCallback(
        (id: number) => {
            deleteMenuAuthority({ id }).then(() => {
                message.success('删除成功');
                forceUpdate();
            }, showError);
        },
        [forceUpdate]
    );

    const [nodeToMoveFrom, setNodeToMoveFrom] = useState<RowNode>();
    const [nodeToReplace, setNodeToReplace] = useState<RowNode>();

    const cellClassRules = useMemo<CellClassRules>(
        () => ({
            'hover-over': (params: CellClassParams) => params.node === nodeToReplace,
        }),
        [nodeToReplace]
    );

    const columnDefs = useMemo<ColDef[]>(
        () => [
            {
                field: 'operations',
                headerName: '关联API',
                flex: 1,
                minWidth: 320,
                autoHeight: true,
                cellClassRules: cellClassRules,
                cellRenderer: (params: ICellRendererParams) => {
                    const value = params.value as AuthorityOperation[];
                    if (value.length) {
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    padding: '4px 0',
                                }}
                            >
                                {value.map((item, index) => (
                                    <div key={item.uri} style={{ lineHeight: '28px' }}>
                                        {index > 0 && <Divider type="vertical" />}
                                        <Tooltip title={item.uri}>
                                            <span>{item.name}</span>
                                        </Tooltip>
                                    </div>
                                ))}
                            </div>
                        );
                    }
                    return null;
                },
            },
            {
                headerName: '操作',
                pinned: 'right',
                resizable: true,
                width: 412,
                minWidth: 412,
                cellRenderer: (params: ICellRendererParams) => (
                    <div className="btn-group">
                        {params.data.hierarchy.length === 1 && (
                            <Button
                                type="primary"
                                onClick={() => {
                                    setCurEditMenu(params.data);
                                    setShowAddMenu(true);
                                }}
                            >
                                添加二级菜单
                            </Button>
                        )}
                        <Button
                            type="primary"
                            onClick={() => {
                                setCurEditMenu(params.data);
                                setShowEditMenu(true);
                            }}
                        >
                            编辑菜单
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                setCurEditMenu(params.data);
                                setShowEditAPI(true);
                            }}
                        >
                            编辑API
                        </Button>
                        <Button
                            type="primary"
                            danger
                            onClick={() => {
                                handleDeleteMenu(params.data.id);
                            }}
                        >
                            删除
                        </Button>
                    </div>
                ),
            },
        ],
        [handleDeleteMenu, cellClassRules]
    );

    const autoGroupColumnDef = useMemo<ColDef>(
        () => ({
            headerName: '一级菜单/二级菜单',
            width: 320,
            rowDrag: true,
            cellClassRules: cellClassRules,
        }),
        [cellClassRules]
    );

    const handleRowDragMove = useCallback((event: RowDragMoveEvent) => {
        const movingNode = event.node;
        const overNode = event.overNode;
        setNodeToMoveFrom(movingNode);

        const nodeInSameLevel = overNode && movingNode.data.parentId === overNode.data.parentId;
        const shouldMoveRow = movingNode !== overNode && nodeInSameLevel;

        if (shouldMoveRow) {
            setNodeToReplace(overNode);
        } else if (movingNode === overNode) {
            setNodeToMoveFrom(undefined);
            setNodeToReplace(undefined);
        }
    }, []);

    const handleRowDragEnd = useCallback(
        (event: RowDragEndEvent) => {
            if (nodeToReplace) {
                if (nodeToMoveFrom?.data.hierarchy.length === 1) {
                    const nodes = rowData.filter((row) => row.hierarchy.length === 1);
                    const fromIndex = nodes.indexOf(nodeToMoveFrom.data);
                    const toIndex = nodes.indexOf(nodeToReplace?.data);
                    nodes.splice(fromIndex, 1);
                    nodes.splice(toIndex, 0, nodeToMoveFrom.data);
                    event.api.showLoadingOverlay();
                    setMenuOrder({
                        parentId: 0,
                        childIds: nodes.map((node) => node.id),
                    })
                        .then(() => {
                            message.success('调整成功');
                            forceUpdate();
                        }, showError)
                        .finally(() => event.api.hideOverlay());
                } else {
                    const nodes = rowData.filter(
                        (row) => row.hierarchy[0] === nodeToMoveFrom?.data.hierarchy[0]
                    );
                    const fromIndex = nodes.indexOf(nodeToMoveFrom?.data);
                    const toIndex = nodes.indexOf(nodeToReplace?.data);
                    nodes.splice(fromIndex, 1);
                    nodes.splice(toIndex, 0, nodeToMoveFrom?.data);
                    event.api.showLoadingOverlay();
                    setMenuOrder({
                        parentId: nodes[0].id,
                        childIds: nodes.slice(1).map((node) => node.id),
                    })
                        .then(() => {
                            message.success('调整成功');
                            forceUpdate();
                        }, showError)
                        .finally(() => event.api.hideOverlay());
                }
                setNodeToMoveFrom(undefined);
                setNodeToReplace(undefined);
            }
        },
        [forceUpdate, nodeToMoveFrom, nodeToReplace, rowData]
    );

    const [curEditMenu, setCurEditMenu] = useState<AuthorityTableRow>();
    const [showAddMenu, setShowAddMenu] = useState(false);
    const [showEditMenu, setShowEditMenu] = useState(false);
    const [showEditAPI, setShowEditAPI] = useState(false);

    return (
        <div style={{ padding: '16px 0' }} className="AuthorityTree">
            {(authorityTree?.dissociatedOperations ?? []).length > 0 && (
                <DissociatedOperationList operations={authorityTree!.dissociatedOperations} />
            )}
            <AddMenuModal
                visible={showAddMenu}
                setVisible={setShowAddMenu}
                parentMenu={curEditMenu}
                refreshData={forceUpdate}
            />
            <EditMenuModal
                visible={showEditMenu}
                setVisible={setShowEditMenu}
                menu={curEditMenu}
                topLevelMenus={rowData.filter((row) => row.parentId === 0)}
                refreshData={forceUpdate}
            />
            <EditAPIModal
                visible={showEditAPI}
                setVisible={setShowEditAPI}
                menu={curEditMenu}
                refreshData={forceUpdate}
                unlinkedOperations={authorityTree?.dissociatedOperations}
            />
            <LookButton
                variant="filled"
                color={green[7]}
                style={{ margin: '8px 0' }}
                onClick={() => {
                    setCurEditMenu(undefined);
                    setShowAddMenu(true);
                }}
            >
                添加一级菜单
            </LookButton>
            <div className="ag-theme-alpine">
                <AgGridReact
                    domLayout="autoHeight"
                    columnDefs={columnDefs}
                    rowData={rowData}
                    treeData
                    getDataPath={(data: AuthorityTableRow) => data.hierarchy}
                    autoGroupColumnDef={autoGroupColumnDef}
                    onRowDragMove={handleRowDragMove}
                    onRowDragEnd={handleRowDragEnd}
                    animateRows
                    enableCellTextSelection
                    enableCellChangeFlash
                />
            </div>
        </div>
    );
}
