import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { buildAxiosSetting, HostType, REPORT_URL } from './config';
import { v4 as uuid } from 'uuid';
import store from '../store/store';
import NProgress from 'nprogress';
import { message } from 'antd';
NProgress.configure({ showSpinner: false });

export const checkIsTokenExisted = () => {
    let hasToken = false;
    const login = JSON.parse(localStorage.getItem('login') || '{}');

    /** if has token */
    if (Object.keys(login).length > 0 && login.token) {
        hasToken = true;
    }

    return {
        hasToken,
    };
};
function initAxios(setting: { host: string; client_type: number }): AxiosInstance {
    const instance = axios.create({
        // timeout: 50000,
        baseURL: setting.host,
    });

    /** 所有接口都为POST,后续有get需要改配置 */
    const addBasicConfig = (config: AxiosRequestConfig) => {
        const login = JSON.parse(localStorage.getItem('login') || '{}');
        const portalLogin = JSON.parse(localStorage.getItem('AppLoginInfo') || '{}');
        const state = store.getState();
        /** add req_id & local data */
        config.data = {
            ...config.data,
            req_id: uuid().slice(0, 16),
            local: {
                client_type: setting.client_type,
                locale: state.config.lang,
                device_id: 'chrome',
                app_ver: '1',
            },
        };
        /** if has token */
        if (Object.keys(login).length > 0) {
            config.data = {
                ...config.data,
                login: login,
            };
        }
        if (setting.host.indexOf('portal') > -1 && Object.keys(portalLogin).length > 0) {
            config.data = {
                ...config.data,
                login: portalLogin,
            };
        }
    };

    // backend interface docs
    instance.interceptors.request.use(
        (config) => {
            /** 打点不展示progress进度条 */
            if (config.url !== REPORT_URL) {
                NProgress.start();
            }

            /** add req_id & local data */
            addBasicConfig(config);
            return config;
        },
        (err) => {
            return Promise.reject(err);
        }
    );

    instance.interceptors.response.use(
        (response) => {
            if (response.config?.url !== REPORT_URL) {
                NProgress.done();
            }
            const code = response.data.code;
            /** 如果不存在，强制跳转login */
            // if (code === -11 || code === -10 || code === -9) {
            //     localStorage.removeItem('login');
            //     window.location.pathname = 'login';

            //     return Promise.reject(response.data);
            // }
            // /** 如果不存在，强制跳转login */

            if (code === -10 && response.data.data.url) {
                debugger;
                localStorage.removeItem('login');
                //本地环境将 redirect_uri 改为 http://localhost:3006
                if (process.env.NODE_ENV === 'development') {
                    window.location.href =
                        'https://open.larksuite.com/open-apis/authen/v1/user_auth_page_beta?app_id=cli_a4a83aa752b8d02f&redirect_uri=http%3A%2F%2Flocalhost:3006&state=';
                } else {
                    window.location.href = response.data.data.url;
                }
                return Promise.reject(response.data);
            }

            if (code !== 0) {
                return Promise.reject(response.data);
            } else {
                return response.data;
            }
        },
        (error) => {
            if (error?.response?.data?.msg) message.error(error.response.data.msg);

            NProgress.done();
            return Promise.reject(error);
        }
    );

    return instance;
}

const multiHostAxiosBuilder = () => {
    let axiosInstances: { [key in HostType]?: AxiosInstance } = {};
    return (hostType: HostType = HostType.PB): AxiosInstance => {
        if (axiosInstances[hostType] === undefined) {
            axiosInstances[hostType] = initAxios(buildAxiosSetting(hostType));
        }
        return axiosInstances[hostType]!;
    };
};

/**
 * 支持自定义Host地址的Axios，创建的Axios实例会缓存
 * 默认Host地址为HostType.PB
 */
export const multiHostAxios = multiHostAxiosBuilder();

const pbAxios = multiHostAxios(HostType.PB);
const miniAxios = multiHostAxios(HostType.MINI);
export { pbAxios, miniAxios };
export default pbAxios;
