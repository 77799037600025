import axios from '../tools';
export type NoticeAddReqType = {
    isin: string;
    notice: string;
    duration_type?: 1 | 2;
    duration_value: number;
    name?: string;
    id?: number;
    ticker?: number;
    status?: 0 | 1;
    expired_at?: number;
    created_at?: number;
};
/**
 * 增加详情页公告
 * @param data
 * @returns
 */
export const noticeAdd = (data: NoticeAddReqType) => {
    return axios.post('/v1/admin/security/notice/add', {
        data,
    });
};
/**
 * 更新详情页公告
 * @param data
 * @returns
 */
export const noticeUpdate = (data: NoticeAddReqType) => {
    return axios.post('/v1/admin/security/notice/update', {
        data,
    });
};
/**
 * 详情页公告list
 * @param data
 * @returns
 */
export const noticeList = (data: { offset?: number; limit?: number }) => {
    return axios.post<{ total_count: number; notices: Array<NoticeAddReqType> }>(
        '/v1/admin/security/notice/list',
        { data }
    );
};
/**
 * 详情页公告删除
 * @param data
 * @returns
 */
export const noticeDelete = (data: { id: number }) => {
    return axios.post('/v1/admin/security/notice/delete', { data });
};
