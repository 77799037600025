import React, { useEffect, useMemo, useState } from 'react';
import { Button, Modal, Spin, Table, Upload, message } from 'antd';
import styles from './index.module.less';
import { UploadOutlined, FileOutlined, DeleteOutlined } from '@ant-design/icons';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import {
    deleteTermsheet,
    getTermsheetDetail,
    saveTermsheetDetail,
    uploadTermsheet,
} from '../../request';
import { ColumnsType } from 'antd/lib/table';
import { TransFcnExtInfo, TransactionInfo, TransactionType } from '../../type';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import DateEditableCell from './components/DateEditCell';

interface UploadTermSheetModalProps {
    transId: number;
    transInfo: TransactionInfo;
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const UploadTermSheetModalContent = (props: UploadTermSheetModalProps) => {
    const { transId, transInfo, showModal, setShowModal } = props;
    const [uploadLoading, setUploadLoading] = useState(false);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [periodList, setPeriodList] = useState<any[]>([]);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [detail, setDetail] = useState<any>();
    const [tempDetail, setTempDetail] = useState<any>();
    const uploadProps = {
        name: 'file',
        accept: '.pdf',
        fileList,
        showUploadList: false,
        onChange: (info: UploadChangeParam) => {
            setFileList(info.fileList);
            setUploadLoading(info.file.status === 'uploading');
            if (info.file.status === 'done') {
                setTempDetail(info.file.response);
                handlePeriodList(transInfo.type.code, info.file.response);
            }
        },
        customRequest: (option: any) => {
            const reader = new FileReader();
            reader.onload = () => {
                const data_url = reader.result as string;
                uploadTermsheet({
                    data_url,
                    trans_id: transId,
                    file_name: option.file.name,
                }).then(
                    (res) => {
                        option.onSuccess(res.data);
                    },
                    (err) => {
                        if (err.code === 100009) {
                            option.onError();
                            message.error(
                                'Failed to get settlement dates, please re-upload the termsheet.'
                            );
                            setFileList([]);
                        }
                    }
                );
            };
            reader.onerror = () => {
                message.error('Failed to read file.');
                option.onError('Failed to read file.');
            };
            if (typeof option.file !== 'string') {
                reader.readAsDataURL(option.file);
            }
        },
    };

    const hasFile = useMemo(() => {
        return fileList.length >= 1;
    }, [fileList]);
    const isUploaded = useMemo(() => {
        let ret = false;
        if (detail?.termsheet_info?.name) {
            ret = true;
        }
        return ret;
    }, [detail]);
    useEffect(() => {
        if (showModal) {
            getDetail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);
    const getDetail = () => {
        setFetchLoading(true);
        getTermsheetDetail({ trans_id: transId })
            .then((res) => {
                setDetail(res.data);
                setTempDetail(res.data);
                handlePeriodList(transInfo.type.code, res.data);
            })
            .finally(() => {
                setFetchLoading(false);
            });
    };
    const getColumns = (type: number) => {
        let ret: (ColumnsType<any>[number] & {
            editable?: boolean;
            dataIndex: string;
            timezone?: string;
        })[] = [];
        switch (type) {
            case TransactionType['Buy AQ']:
            case TransactionType['Buy DQ']:
                ret = [
                    {
                        title: 'Period',
                        dataIndex: 'idx',
                        render: (_, __, index) => index + 1,
                    },
                    {
                        title: 'Start Date',
                        dataIndex: 'start_date',
                        render: (value) => (value ? moment(value).format('yyyy.MM.DD') : ''),
                    },
                    {
                        title: 'End Date',
                        dataIndex: 'end_date',
                        render: (value) => (value ? moment(value).format('yyyy.MM.DD') : ''),
                    },
                    {
                        title: 'No. Trading Days',
                        dataIndex: 'observation_days',
                    },
                    {
                        title: 'Settlement Date',
                        dataIndex: 'settle_date',
                        render: (value) => (value ? moment(value).format('yyyy.MM.DD') : ''),
                    },
                ];
                break;
            case TransactionType['Buy FCN']:
                ret = [
                    {
                        title: '',
                        dataIndex: 'idx',
                        render: (_, __, index) => index + 1,
                        align: 'center',
                    },
                    {
                        title: 'Observation Date',
                        dataIndex: 'observation_date',
                        render: (value) => (value ? moment(value).format('yyyy.MM.DD') : ''),
                        editable: true,
                        align: 'center',
                        timezone: transInfo.time_zone,
                    },
                    {
                        title: 'Settlement Date',
                        dataIndex: 'coupon_date',
                        render: (value) => (value ? moment(value).format('yyyy.MM.DD') : ''),
                        editable: true,
                        align: 'center',
                        timezone: transInfo.time_zone,
                    },
                ];
                break;

            default:
                break;
        }
        return ret;
    };

    const getEditableColumns = (
        columns: (ColumnsType<any>[number] & {
            editable?: boolean;
            dataIndex: string;
            timezone?: string;
        })[]
    ) => {
        return columns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: (record: any, idx: number) => ({
                    record,
                    editing: true,
                    dataIndex: col.dataIndex,
                    timezone: col.timezone,
                    index: idx,
                    handleChange: (record: any, idx: number) => {
                        const newPeriodList = [...periodList];
                        newPeriodList.splice(idx, 1, record);

                        setPeriodList(newPeriodList);
                        const newDetail = {
                            coupon_dates: newPeriodList.map((p) => p.coupon_date).filter((p) => p),
                            observation_dates: newPeriodList
                                .map((p) => p.observation_date)
                                .filter((p) => p),
                        } as any;
                        newDetail.maturity_date =
                            newDetail.coupon_dates.length > 0
                                ? Math.max(...newDetail.coupon_dates)
                                : null;
                        newDetail.observation_date_of_maturity =
                            newDetail.observation_dates.length > 0
                                ? Math.max(...newDetail.observation_dates)
                                : null;
                        setTempDetail({
                            ...newDetail,
                            termsheet_info: { ...tempDetail.termsheet_info },
                        });
                    },
                }),
            };
        });
    };
    const handlePeriodList = (type: number, detail: any) => {
        switch (type) {
            case TransactionType['Buy AQ']:
            case TransactionType['Buy DQ']:
                setPeriodList(detail.observation_periods || []);
                break;
            case TransactionType['Buy FCN']:
                if (detail.coupon_dates && detail.coupon_dates.length > 0) {
                    const temp = detail.coupon_dates.map((cd: number, idx: number) => {
                        return {
                            coupon_date: cd,
                            observation_date: detail.observation_dates[idx] || '',
                        };
                    });
                    setPeriodList(temp);
                } else {
                    const extInfo = transInfo.ext_info as TransFcnExtInfo;
                    const tenor = extInfo.tenor!;
                    const num = parseInt(tenor);
                    const list = Array.from({ length: num }).map((_, idx) => {
                        return {
                            coupon_date: undefined,
                            observation_date: undefined,
                        };
                    });
                    setPeriodList(list);
                }
                break;
            default:
                break;
        }
    };
    const onTempRemoveClick = () => {
        setFileList([]);
        setTempDetail((preDetail: any) => {
            let newDetail = { ...preDetail };
            Object.keys(newDetail).forEach((key) => {
                newDetail[key] = null;
            });
            handlePeriodList(transInfo.type.code, newDetail);
            return newDetail;
        });
    };
    const onRemoveClick = () => {
        Modal.confirm({
            icon: false,
            title: (
                <span className={styles.removeTips}>
                    {
                        'Confirm remove this termsheet? \nAll associated transactions will be removed!'
                    }
                </span>
            ),
            okText: 'Confirm',
            onOk: () => {
                deleteTermsheet({ trans_id: transId }).then((res) => {
                    getDetail();
                });
            },
        });
    };
    const onConfirmClick = () => {
        saveTermsheetDetail({ termsheet_parsing_info: tempDetail, trans_id: transId }).then(
            (res) => {
                setShowModal(false);
                message.success(
                    'Successfully parse out settlement dates! Transactions will be generated.'
                );
            }
        );
    };
    return (
        <div className={styles.content}>
            <Spin spinning={fetchLoading}>
                {detail !== undefined ? (
                    <>
                        <div className={styles.upload}>
                            <Upload {...uploadProps}>
                                <Button
                                    loading={uploadLoading}
                                    disabled={hasFile || isUploaded}
                                    icon={
                                        hasFile || isUploaded ? (
                                            <FileOutlined />
                                        ) : (
                                            <UploadOutlined />
                                        )
                                    }
                                >
                                    {detail?.termsheet_info?.name ||
                                        fileList?.[0]?.name ||
                                        'Upload Termsheet'}
                                </Button>
                            </Upload>
                            {hasFile && (
                                <span onClick={onTempRemoveClick} className={styles.deleteIcon}>
                                    <Button icon={<DeleteOutlined />} />
                                </span>
                            )}
                        </div>
                        <div className={styles.tips}>
                            {hasFile || isUploaded
                                ? 'Upload successfully.'
                                : 'Please upload a termsheet (.pdf) , which will be used to parse out the settlement dates.'}
                        </div>
                        {(transInfo.type.code === TransactionType['Buy FCN'] ||
                            hasFile ||
                            isUploaded) && (
                            <Table
                                columns={
                                    transInfo.type.code === TransactionType['Buy FCN']
                                        ? (getEditableColumns(
                                              getColumns(transInfo.type.code) as (ColumnsType<
                                                  any
                                              >[number] & {
                                                  editable?: boolean;
                                                  dataIndex: string;
                                                  timezone?: string;
                                              })[]
                                          ) as ColumnsType<any>)
                                        : getColumns(transInfo.type.code)
                                }
                                dataSource={periodList}
                                pagination={false}
                                size="small"
                                scroll={{ y: 200 }}
                                rowKey={() => uuid()}
                                components={{
                                    body: {
                                        cell: DateEditableCell,
                                    },
                                }}
                            />
                        )}

                        <div className={styles.btns}>
                            {isUploaded && <Button onClick={onRemoveClick}>Remove</Button>}
                            {(!isUploaded ||
                                transInfo.type.code === TransactionType['Buy FCN']) && (
                                <Button type="primary" onClick={onConfirmClick}>
                                    Confirm
                                </Button>
                            )}
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </Spin>
        </div>
    );
};

export default UploadTermSheetModalContent;
