import React, { useEffect, useState } from 'react';
import styles from './Overview.module.scss';
import useFetch from '../../../utils/Hooks/useFetch';
import { QuestionId, getDashboardData } from '../../../service/requests/Dashboard';
import { Col, Row } from 'antd';
import moment from 'moment';
import { v4 as uuid } from 'uuid';

interface OverviewData {
    name: string;
    time: string;
    value: number;
}

const Overview = () => {
    const [data, setData] = useState<OverviewData[]>([]);
    const [fetchState] = useFetch({
        interfaces: getDashboardData,
        initialParams: {
            question_id: QuestionId.Overview,
        },
    });

    useEffect(() => {
        if (fetchState.data) {
            setData([
                {
                    name: '总资产',
                    time: fetchState.data[0]['data_date'],
                    value: fetchState.data[0]['total_asset'],
                },
                {
                    name: '净资产',
                    time: fetchState.data[0]['data_date'],
                    value: fetchState.data[0]['total_net_asset'],
                },
                {
                    name: '负债',
                    time: fetchState.data[0]['data_date'],
                    value: fetchState.data[0]['total_debit_amount'],
                },
                {
                    name: '持仓市值',
                    time: fetchState.data[0]['data_date'],
                    value: fetchState.data[0]['total_hold_value'],
                },
            ]);
        }
    }, [fetchState.data]);

    const formatNumber = (num: number) => {
        return Math.abs(num)
            .toString()
            .replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    };

    const numberTransformer = (num: number) => {
        let ret = {} as any,
            i;
        const k = 10000,
            sizes = ['', '万', '亿', '万亿'];
        num = Math.abs(num);
        if (num < k) {
            ret.value = num;
            ret.unit = '';
        } else {
            i = Math.floor(Math.log(num) / Math.log(k));
            ret.value = (num / Math.pow(k, i)).toFixed(2);
            ret.unit = sizes[i];
        }
        return ret;
    };
    return (
        <>
            <div className={styles.title}>全局概览</div>
            <Row gutter={16}>
                {data.length > 0 &&
                    data.map((i) => (
                        <Col key={uuid()} span={6}>
                            <div className={styles.overviewItem}>
                                <div className={styles.name}>{i.name}</div>
                                <div className={styles.time}>{`数据时间 ${moment(i.time).format(
                                    'yyyy.MM.DD'
                                )}`}</div>
                                <div>
                                    <span className={styles.formatValue}>
                                        {numberTransformer(i.value).value}
                                    </span>
                                    <span className={styles.formatUnit}>
                                        {numberTransformer(i.value).unit}
                                    </span>
                                    <span className={styles.currency}>USD</span>
                                </div>
                                <div className={styles.value}>{formatNumber(i.value)}</div>
                            </div>
                        </Col>
                    ))}
            </Row>
        </>
    );
};

export default Overview;
