import React, { useMemo } from 'react';
import { Radio } from 'antd';
import { LKMultipleCheckbox } from '../../../../components/LKMultipleCheckbox';
interface QuotationMenuProp<T> {
    dataList?: { [key: string]: Array<T> };
    cellValue: (cellData: T) => any;
    cellLable: (cellData: T) => string;
    onChange: (result: { [key: string]: string[] | string }) => void;
}

const titleMap: { [key: string]: string } = {
    product_types: '产品',
    company_names: '标的',
    bank_names: '银行',
    update_times: 'Update Date',
    underlyings: 'Underlying',
    bank_types: '银行',
    credit_ratings: '信用评级',
    order_by: '排序方式',
    bank_ids: 'Bank',
};
const QuotationMenu = <T extends unknown>({
    dataList,
    cellValue,
    onChange,
    cellLable,
}: QuotationMenuProp<T>) => {
    const memoData = useMemo(() => {
        if (dataList && dataList['update_times']) {
            if (typeof dataList['update_times'][0] === 'string') {
                return {
                    ...dataList,
                    update_times: ['全部', ...dataList['update_times']],
                };
            } else {
                return {
                    ...dataList,
                    update_times: [{ label: '全部', value: 0 }, ...dataList['update_times']],
                };
            }
        }
        return dataList;
    }, [dataList]);

    return (
        <div>
            {Object.keys(dataList ?? {}).map((key) => {
                if (key === 'update_times') {
                    return (
                        <div style={{ lineHeight: '40px' }} key="update_times">
                            <span
                                style={{
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    color: 'rgba(0, 0, 0, 0.85)',
                                }}
                            >
                                {titleMap[key]}：
                            </span>
                            <Radio.Group
                                defaultValue={'全部'}
                                onChange={(value) => {
                                    let obj: { [key: string]: string } = {};
                                    obj['update_time'] =
                                        value.target.value === 0 ? undefined : value.target.value;
                                    onChange(obj);
                                }}
                            >
                                {memoData![key].map((item, index) => (
                                    <Radio
                                        key={index}
                                        value={
                                            typeof item === 'string' ? item : cellValue(item as T)
                                        }
                                    >
                                        {typeof item === 'string' ? item : cellLable(item as T)}
                                    </Radio>
                                ))}
                            </Radio.Group>
                        </div>
                    );
                }
                return (
                    <LKMultipleCheckbox
                        key={key}
                        plainOptions={dataList![key].map((item) => {
                            let a = {
                                label: cellLable(item),
                                value: cellValue(item),
                            };
                            return a;
                        })}
                        valueChange={(item) => {
                            let obj: { [key: string]: string[] } = {};
                            obj[key] = item;
                            onChange(obj);
                        }}
                        title={titleMap[key]}
                    />
                );
            })}
        </div>
    );
};

export default QuotationMenu;
