import { Input } from 'antd';
import React from 'react';
const { Search } = Input;
const FundFilter = ({ onChange }: { onChange: (data: any) => void }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Fund Name-Fund Manager</span>
            <Search style={{ width: '60%', marginLeft: '8px' }} allowClear onSearch={onChange} />
        </div>
    );
};

export default FundFilter;
