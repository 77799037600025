import React, { useMemo } from 'react';
import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { AllowedMenuList } from '../../service/type';
import style from './index.module.scss';
type Props = {
    allowedMenuList?: AllowedMenuList;
};

export default function LKBreadcrumb({ allowedMenuList }: Props) {
    const location = useLocation();

    const pathSnippets = location.pathname.split('/').filter((i) => i);
    const [breadcrumbNameMap, haschildren] = useMemo(() => {
        ///没有在菜单中出现的路由且需要再面包屑中展示的，在下方手动加入
        let set: Record<string, string> = {
            '/app/customer/positionEditor/adjustAsset': '持仓调整',
            '/app/customer/positionEditor/read': '持仓编辑查看',
            '/app/customer/positionEditor/editList': '编辑记录',
            '/app/customer/positionEditor/edit': '持仓编辑详情',
            '/app/customer/list/trackRecord': '客户记录',
            '/app/recommendation': '产品推荐',
            '/app/trade/BondQuote/detail': '编辑报价',
            '/app/trade/quote/detail': '编辑报价',
            '/app/trade/order/email': '发送邮件',
            '/app/trade/BondQuote/email': '发送邮件',
            '/app/activity/articleSync/detail': '更新同步',
            '/app/trade/transaction/detail': '交易详情',
            '/app/trade/transaction/email': '发送邮件',
            '/app/assetManagement/position/detail': 'Position Detail',
            // subAccount
            '/app/assetManagement/position/subAccount': 'SubAccount Detail',
            '/app/reconciliation/list/detail': 'Reconciliation Detail',
        };
        // let set: Record<string, string> = {};

        let haschildren: Record<string, string> = {};
        allowedMenuList?.forEach((element) => {
            set[element.entrance] = element.name;
            if (element.children !== null && element.children.length > 0) {
                haschildren[element.entrance] = element.name;
                element.children.forEach((item) => {
                    set[item.entrance] = item.name;
                });
            }
        });
        return [set, haschildren];
    }, [allowedMenuList]);

    const extraBreadcrumbItems = useMemo(() => {
        let baseArray: Array<JSX.Element> = [];
        pathSnippets.forEach((_, index) => {
            const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
            if (url !== null && breadcrumbNameMap[url] !== undefined) {
                if (breadcrumbNameMap[url] === '交易记录') {
                    // baseArray = [];
                }
                const contains =
                    [
                        '持仓编辑查看',
                        '持仓编辑详情',
                        '客户详情',
                        '持仓调整',
                        '客户记录',
                        'SubAccount Detail',
                        'Reconciliation Detail',
                    ].find((item) => item === breadcrumbNameMap[url]) !== undefined;
                let ele =
                    haschildren[url] !== undefined ||
                    contains ||
                    index === pathSnippets.length - 1 ? (
                        breadcrumbNameMap[url]
                    ) : (
                        <Link to={url}>{breadcrumbNameMap[url]}</Link>
                    );
                baseArray.push(
                    <Breadcrumb.Item key={url + breadcrumbNameMap[url]}>{ele}</Breadcrumb.Item>
                );
            }
        });

        return baseArray;
    }, [pathSnippets, haschildren, breadcrumbNameMap]);

    return (
        <>
            {
                <div className={style.content}>
                    <Breadcrumb className={style.bread}>{extraBreadcrumbItems}</Breadcrumb>
                </div>
            }
        </>
    );
}
