import { ColDef, ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Typography, Button, Collapse, Modal, message, Popconfirm } from 'antd';
import { Formik } from 'formik';
import { Checkbox, Form, Input, InputNumber, Select } from 'formik-antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useForceUpdate from '../../../utils/Hooks/useForceUpdate';
import { showError } from '../../RecommendationComponent/util';
import {
    addNodeReceiver,
    addNotifyNode,
    deleteNodeReceiver,
    getNotifyNodeList,
    updateNodeReceiver,
} from './requests';
import './index.less';

export default function NotifyNode() {
    const [notifyNodeList, setNotifyNodeList] = useState<NotifyNode[]>();

    const [curEditNodeId, setCurEditNodeId] = useState<number>(0);
    const [curEditReceiver, setCurEditReceiver] = useState<NotifyNodeReceiver>();
    const [showEditReceiver, setShowEditReceiver] = useState(false);

    useEffect(() => {
        if (!showEditReceiver) {
            setCurEditReceiver(undefined);
        }
    }, [showEditReceiver]);

    const [showAddNode, setShowAddNode] = useState(false);

    const forceUpdate = useForceUpdate(() => {
        getNotifyNodeList().then((res) => setNotifyNodeList(res.data), showError);
    }, []);

    const columns = useMemo<ColDef[]>(
        () => [
            { headerName: '通道类型', field: 'channel' },
            {
                headerName: 'JsonPath',
                field: 'jsonPath',
                valueFormatter: (params: ValueFormatterParams) => (params.value ? '是' : '否'),
            },
            { headerName: '地址标识', field: 'ident' },
            { headerName: '超时放弃（秒）', field: 'expire' },
            {
                headerName: '操作',
                cellRenderer: (params: ICellRendererParams) => (
                    <>
                        <Button
                            type="primary"
                            onClick={() => {
                                setCurEditNodeId(params.data.nodeId);
                                setCurEditReceiver(params.data);
                                setShowEditReceiver(true);
                            }}
                        >
                            编辑
                        </Button>
                        <Popconfirm
                            title="确定删除该通知对象吗？"
                            okText="确定"
                            cancelText="取消"
                            onConfirm={() =>
                                deleteNodeReceiver({ id: params.data.id }).then(() => {
                                    message.success('删除成功');
                                    forceUpdate();
                                }, showError)
                            }
                        >
                            <Button type="primary" danger>
                                删除
                            </Button>
                        </Popconfirm>
                    </>
                ),
            },
        ],
        [forceUpdate]
    );

    const handleFormValidate = useCallback((values) => {
        let errors: Record<string, string> = {};
        if (!values.channel) {
            errors.channel = '请选择一项';
        }
        if (!values.ident) {
            errors.ident = '请输入内容';
        }
        return errors;
    }, []);

    const handleFormSubmit = useCallback(
        (values) => {
            values = {
                nodeId: curEditNodeId,
                ...values,
            };
            if (curEditReceiver) {
                updateNodeReceiver(values)
                    .then(() => {
                        message.success('更新成功');
                        forceUpdate();
                    }, showError)
                    .finally(() => setShowEditReceiver(false));
            } else {
                addNodeReceiver(values)
                    .then(() => {
                        message.success('添加成功');
                        forceUpdate();
                    }, showError)
                    .finally(() => setShowEditReceiver(false));
            }
        },
        [curEditNodeId, curEditReceiver, forceUpdate]
    );

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                <Typography.Title level={4} style={{ margin: 0 }}>
                    通知节点
                </Typography.Title>
                <Button
                    type="primary"
                    style={{ marginLeft: 16 }}
                    onClick={() => setShowAddNode(true)}
                >
                    添加
                </Button>
            </div>
            <Collapse defaultActiveKey={['0']} style={{ marginTop: 16 }}>
                {notifyNodeList?.map((notifyNode, index) => (
                    <Collapse.Panel
                        header={
                            <div className="Notify_PanelHeader">
                                <span>{notifyNode.name}</span>
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setCurEditNodeId(notifyNode.id);
                                        setShowEditReceiver(true);
                                    }}
                                >
                                    添加通知对象
                                </Button>
                            </div>
                        }
                        key={String(index)}
                    >
                        <div className="ag-theme-alpine">
                            <AgGridReact
                                columnDefs={columns}
                                rowData={notifyNode.receivers}
                                domLayout="autoHeight"
                                enableCellTextSelection
                            />
                        </div>
                    </Collapse.Panel>
                ))}
            </Collapse>
            <Modal
                title={curEditReceiver ? '编辑通知对象' : '添加通知对象'}
                footer={null}
                destroyOnClose
                visible={showEditReceiver}
                onCancel={() => setShowEditReceiver(false)}
            >
                <Formik
                    initialValues={curEditReceiver ?? { jsonPath: false, expire: 0 }}
                    validate={handleFormValidate}
                    onSubmit={handleFormSubmit}
                >
                    <Form labelCol={{ span: 6 }}>
                        <Form.Item label="所属节点" name="node">
                            <span>
                                {notifyNodeList?.find((item) => item.id === curEditNodeId)?.name}
                            </span>
                        </Form.Item>
                        <Form.Item label="通道类型" name="channel" required>
                            <Select name="channel">
                                <Select.Option value="Feishu">Feishu</Select.Option>
                                <Select.Option value="Push">Push</Select.Option>
                                <Select.Option value="VoiceCall">VoiceCall</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="JsonPath" name="jsonPath" required>
                            <Checkbox name="jsonPath" />
                        </Form.Item>
                        <Form.Item label="地址标识" name="ident" required>
                            <Input name="ident" />
                        </Form.Item>
                        <Form.Item label="超时放弃" name="expire">
                            <InputNumber name="expire" addonAfter="秒" min={0} />
                        </Form.Item>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin: '8px 0',
                            }}
                        >
                            <Button onClick={() => setShowEditReceiver(false)}>取消</Button>
                            <Button type="primary" htmlType="submit" style={{ marginLeft: 64 }}>
                                提交
                            </Button>
                        </div>
                    </Form>
                </Formik>
            </Modal>
            <Modal
                title="添加通知节点"
                visible={showAddNode}
                onCancel={() => setShowAddNode(false)}
                footer={null}
                destroyOnClose
            >
                <Formik
                    initialValues={{ name: '' }}
                    validate={(values) => {
                        if (!values.name) return { name: '请输入名称' };
                    }}
                    onSubmit={(values) => {
                        addNotifyNode(values)
                            .then(() => {
                                message.success('创建节点成功');
                                forceUpdate();
                            }, showError)
                            .finally(() => setShowAddNode(false));
                    }}
                >
                    <Form labelCol={{ span: 4 }}>
                        <Form.Item name="name" label="节点名称" required>
                            <Input name="name" />
                        </Form.Item>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin: '8px 0',
                            }}
                        >
                            <Button onClick={() => setShowAddNode(false)}>取消</Button>
                            <Button type="primary" htmlType="submit" style={{ marginLeft: 64 }}>
                                提交
                            </Button>
                        </div>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
}
