import { BaseReqParam } from '..';
import axios from '../tools';
export interface AlterNativeSingReq extends BaseReqParam {
    scene: string;
}
export interface AlterSignInfoRes {
    total_count: number;
    document_list: {
        admin_id: number;
        created_at: number;
        envelope_id: string;
        id: number;
        name: string;
        nickname: string;
        notice_count: number;
        notice_enable: number;
        sign_url: string;
        signed_at: number;
        signed_status: number;
        template_description: string;
        template_id: number;
        template_title: string;
        uid: string;
        newly_signed: NewlySigned;
    }[];
}
interface NewlySigned {
    newly_signed_id: number;
    scene_id: number;
    customer_id: number;
    file_ids: number[];
    template_id: number;
    creator_id: number;
    updater_id: number;
    created_at: number;
    updated_at: number;
    upload_files: {
        id: number;
        type: string;
        url: string;
        uploader: string;
        class: string;
        upload_at: number;
        uploader_member: {
            id: number;
            name: string;
            mobile: string;
            valid: boolean;
            huobanId: number;
            feishuId: string;
            feishuOpenId: string;
            jiraId: string;
            feishuAvatar: string;
        };
        file_name: string;
    }[];
}
const getAlterNativeSingInfo = (data: AlterNativeSingReq) => {
    return axios.post<AlterSignInfoRes>('/v1/admin/esign/document_list', { data });
};

export { getAlterNativeSingInfo };
