import React, { useEffect, useState } from 'react';

import LKCheckbox from '../../../../../../components/LKCheckbox';
import LKDatePicker from '../../../LKDatePicker';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

const BondValidity = ({
    readOnly,
    dayChange,
    value,
    onChange,
    isDayOrder,
    timezone,
}: {
    readOnly?: boolean;
    isDayOrder?: number;
    value?: any;
    onChange?: (value: any) => void;
    dayChange: (value: boolean) => void;
    timezone?: string;
}) => {
    const [daySelect, setDaySelect] = useState(false);
    const [currentValue, setCurrentValue] = useState<CheckboxValueType[]>([]);
    useEffect(() => {
        setDaySelect(isDayOrder === 1 ? true : false);
        setCurrentValue(isDayOrder === 1 ? ['Day Order'] : []);
    }, [isDayOrder]);
    return (
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            {(readOnly === false || daySelect) && (
                <div style={{ width: 'calc(25%)' }}>
                    <LKCheckbox
                        readOnly={readOnly}
                        value={currentValue}
                        datasource={[{ label: 'Day Order', value: 'Day Order', width: '100%' }]}
                        onChange={(value) => {
                            setCurrentValue(value);
                            setDaySelect(value.length > 0);
                            dayChange(value.length > 0);
                        }}
                    />
                </div>
            )}
            {!daySelect && (
                <LKDatePicker
                    style={{ width: 'calc(37.5% - 10px)' }}
                    readOnly={readOnly}
                    value={value}
                    onChange={(value) => {
                        onChange && onChange(value);
                    }}
                />
            )}
        </div>
    );
};

export default BondValidity;
