import { PORTAL_LOGIN, REMOVE_LOGIN, SET_LOGIN } from '../../constants/constants';

function login(login = null, action: any) {
    const { type, newLoginData, portalLogin } = action;

    if (type === SET_LOGIN) {
        return {
            ...newLoginData,
        };
    } else if (type === REMOVE_LOGIN) {
        return {
            ...newLoginData,
        };
    } else if (type === PORTAL_LOGIN) {
        return {
            ...portalLogin,
        };
    } else {
        return login;
    }
}

export default login;
