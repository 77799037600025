import React from 'react';
import { EventList, uploadEventTrack } from '../../../../../../service/requests/EventTrack';
import style from './index.module.scss';
const RecordTags = ({
    tags,
    touchBack,
}: {
    tags: Array<string>;
    touchBack?: (tag: string) => void;
}) => {
    return (
        <div className={style.tags}>
            {tags.map((item) => {
                return (
                    <span
                        className={`${style.tag} ${touchBack ? style.hover : ''}`}
                        key={item}
                        onClick={() => {
                            uploadEventTrack(EventList.CustomerListRecordTagClick, 2, {
                                tag_name: item,
                            });
                            touchBack && touchBack(item);
                        }}
                    >
                        #{item}
                    </span>
                );
            })}
        </div>
    );
};

export default RecordTags;
