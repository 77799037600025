import React from 'react';
import AssetCommonTable from './CommonTable';
export interface AssetPositionTableProps {
    title: string;
    amount: string;
    onClick?: (record: any) => void;
}
const AssetPositionTable = ({
    data,
    id,
}: {
    data: Array<AssetPositionTableProps>;
    id?: string;
}) => {
    return (
        <AssetCommonTable
            dataSource={data}
            size="small"
            bordered
            id={id}
            rowKey={(record) => record.title}
            columns={[
                {
                    title: '-',
                    dataIndex: 'title',
                    width: 150,
                    render(value, record) {
                        if (record.onClick) {
                            return <div onClick={() => record.onClick!(record)}>{value}</div>;
                        } else {
                            return value;
                        }
                    },
                },
                {
                    title: 'Amount',
                    dataIndex: 'amount',
                },
            ]}
            style={{
                width: '500px',
            }}
        />
    );
};

export default AssetPositionTable;
