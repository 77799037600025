import { MagicubeTableData } from '../../components/MagicubeTable/type';
import { BondQuoteListRes, BondQuoteEntity, BondType } from '../../modules/Quotation/request';
import { Asset, AssetType } from '../../modules/Transaction/type';
import { AllTranscationType } from '../../service/requests/CustomerManage';
import ExportExcel from '../custom_js_excel';
import { ExcelExportHook, PriceTableHook } from '../../modules/GKProductPrice/PriceHook';

interface Ioption {
    fileName?: string;
    datas?: Idata[];
}

//data的类型
interface Idata {
    sheetData: any[];
    sheetName?: string;
    sheetFilter?: any[];
    sheetHeader?: string[];
    columnWidths?: number[];
}

const exportXls = (name: string, data: Idata[]) => {
    const option: Ioption = {};
    option.fileName = name;
    option.datas = [...data];

    const toExcel = ExportExcel(option);
    toExcel.saveExcel(); //保存
};
const ChangeMagicubeToExcelData: (magicubes: MagicubeTableData[]) => Idata = (dataList) => {
    let excelCells: Idata[] = dataList.map((item) => {
        // let sheetData:Array<Array<string>>
        let sheetData: Array<Array<string>> = item.rows.map((row) => {
            return row.cells.map((cell) => {
                return cell.value;
            });
        });
        //生成1-10的数组
        let sheetHeader = Array.from({ length: 10 }, (v, k) => (k === 0 ? item.title : ''));

        //在sheetdata的第一行插入表头
        sheetData.unshift(sheetHeader);
        return {
            sheetData: sheetData,
        };
    });
    return mergeTables(excelCells);
};

const ChangeTranscationToExcelData: (
    dataList: AllTranscationType['account_asset_trans']
) => Idata = (dataList: AllTranscationType['account_asset_trans']) => {
    let excelCells = dataList.map((item) => {
        return {
            交易日期: item.trade_date,
            银行账号: item.account_name,
            产品种类: item.product_type_text,
            交易类型: item.trans_type_text,
            交易内容: item.trans_content,
            成交数量: item.c_matched_qty,
            成交价格: item.c_matched_price,
        };
    });
    return {
        sheetData: excelCells,
        sheetHeader: [
            '交易日期',
            '银行账号',
            '产品种类',
            '交易类型',
            '交易内容',
            '成交数量',
            '成交价格',
        ],
    };
};
const ChangeProducPriceToExcelData: (dataList: Asset[], type: AssetType) => Idata = (
    dataList: Asset[],
    type: AssetType
) => {
    let excelCells: any[] = [];
    let header: string[] = [];

    excelCells = dataList.map((item) => {
        return ExcelExportHook({
            list: PriceTableHook({ type: type }).columns!,
            asset: item,
        });
    });
    header = Object.keys(excelCells[0]);
    return {
        sheetData: excelCells,
        sheetHeader: header,
    };
};
const ChangeBondsToExcelData: (dataList: BondQuoteListRes['bond_quotes']) => Idata = (
    dataList: BondQuoteListRes['bond_quotes']
) => {
    const isCorporate = dataList[0].bond_type === BondType.corporate;
    let excelCells = dataList.map((item: BondQuoteEntity) => {
        const {
            isin,
            credit_rating_sp,
            issuer_en,
            issuer_cn,
            ask_price,
            ask_price_plus_accrued_interest,
            YTW,
            YTM,
            coupon,
            duration,
            seniority,
            next_call_date,
            maturity_date,
            residual_maturity,
        } = item;
        return isCorporate
            ? {
                  isin,
                  credit_rating_sp,
                  issuer_en,
                  issuer_cn,
                  ask_price,
                  ask_price_plus_accrued_interest,
                  YTW,
                  YTM,
                  coupon,
                  duration,
                  seniority,
                  next_call_date,
                  maturity_date,
                  residual_maturity,
              }
            : {
                  isin,
                  ask_price,
                  ask_price_plus_accrued_interest,
                  YTM,
                  coupon,
                  duration,
                  maturity_date,
                  residual_maturity,
              };
    });
    const header = isCorporate
        ? [
              'ISIN',
              '信用评级S&P',
              '发行方-英',
              '发行方-中',
              '指导价格%',
              '指导含息价格%',
              '最差收益率%',
              '到期收益率%',
              '年化票息%',
              '久期',
              '优先级',
              '下一赎回日',
              '到期日',
              '到期时间',
          ]
        : [
              'ISIN',
              '指导价格%',
              '指导含息价格%',
              '到期收益率%',
              '年化票息%',
              '久期',
              '到期日',
              '到期时间',
          ];

    return {
        sheetData: excelCells,
        sheetHeader: header,
    };
};
const maxSingleRowSize = 20;
const maxSingleCellSize = 10;
const fillTableWithEmptyStr = (table: Idata) => {
    let { sheetData } = table;
    for (let row = 0; row < maxSingleRowSize; row++) {
        for (let cell = 0; cell < maxSingleCellSize; cell++) {
            if (sheetData[row] === undefined) {
                sheetData[row] = [];
            }
            let cellValue = sheetData[row][cell];
            if (!cellValue) {
                sheetData[row][cell] = '';
            }
        }
    }
};

const mergeTables: (tables: Idata[]) => Idata = (tables) => {
    let newSheet: Idata = {
        sheetData: [],
    };

    for (let index = 0; index < tables.length; index++) {
        const table = tables[index];
        fillTableWithEmptyStr(table);
        let { sheetData } = table;

        let odd = index % 2;
        let newSheetData = newSheet.sheetData;
        if (odd) {
            for (let i = 0; i < sheetData.length; i++) {
                const rowIndex = Math.floor(index / 2) * 20 + i;
                const row = sheetData[i];
                let newRow = newSheetData[rowIndex];
                if (newRow) {
                    newRow = newRow.concat(row);
                } else {
                    newRow = row;
                }
                newSheetData[rowIndex] = newRow;
                newSheet.sheetData = newSheetData;
            }
        } else {
            newSheetData = [...newSheetData, ...sheetData];
            newSheet.sheetData = newSheetData;
        }
    }
    return newSheet;
};
export {
    ChangeTranscationToExcelData,
    ChangeMagicubeToExcelData,
    ChangeBondsToExcelData,
    ChangeProducPriceToExcelData,
};
export default exportXls;
