import { CustomerManageData } from '../../service/requests/CustomerManage';
import { OrderDetail } from '../../service/requests/OrderRequest';
import { Asset, TransELNExtInfo } from '../Transaction/type';

export interface ProductQuoteResponseType {
    total_count: number;
    total_pages: number;
    current_page: number;
    page_size: number;
    asset_quotes: QuoteHistoryListType[];
}
//SELECTING,COMMUNICATEING,CANCELED,CONFIRMED
export enum QouteStatus {
    Selecting = 1,
    Communicateing = 2,
    Canceled = 3,
    Confirmed = 4,
}
export interface GKEnumType {
    code: number;
    desc: string;
}
interface QuoteHistoryListType {
    id: number;
    member_id: number;
    customer_id: number;
    account_id: string;
    asset_type: GKEnumType;
    status: GKEnumType;
    created_at: number;
    updated_at: number;
    member: MemberType;
    client: CustomerType;
    account: AccountType;
}

interface MemberType {
    id: number;
    name: string;
    mobile: string;
    valid: boolean;
    huobanId: number;
    feishuId: string;
    feishuOpenId: string;
    jiraId: string;
    feishuAvatar: string;
}

type CustomerType = CustomerManageData['pageData'][0];

interface AccountType {
    accountId: string;
    uid: string;
    accountType: string;
    accountClass: number;
    accountName: string;
    bank_name: string;
    vendorSubAccountId: string;
    tag: number;
    displayName: string;
    status: number;
    boundAt: number;
    vendor_sub_account_id: string;
}
interface FieldType {
    title: string;
    unit?: string;
    value: string;
    is_pass_check?: boolean;
    failed_check_message: string;
}
export interface ProductQuoteDetailType {
    id: number;
    account_id: string;
    account_balance: number;
    quote_pool_id_list: number[];
    selected_quote_id_list: number[];
    price_increased_percentage: number;
    cal_fields: FieldType[];
    cal_fields_by_real_time_price: FieldType[];
    quote_pool: QuoteEntity[];
    selected_quotes: QuoteEntity[];
    minimum_transaction_amount: string;
    currency: string;
    bank_type: string;
    is_pass_check: boolean;
    asset_type: GKEnumType;
    status: GKEnumType;
}
export interface QuoteEntity {
    id?: number;
    isin: string;
    asset?: Asset;
    ext_info: TransELNExtInfo;
    created_at?: number;
    is_selected?: boolean;
    updated_at?: number;
}
export interface UserAccountInfo {
    user: {
        name: string;
    };
    account: {
        accountId: string;
        accountName: string;
        accountType: string;
        vendorSubAccountId: string;
    };
    balance_amount: number;
    risk_rate: number;
    currency: string;
}
export enum BondQuoteDetailPageType {
    Preview = 'preview',
    Edit = 'edit',
}

export interface BondQuotesDetails {
    order_details: OrderDetail[];
}
export enum QuoteRelatedTargetAction {
    Save = 'Quote_Button_Save',
    Cancel = 'Quote_Button_Cancel',
    Sync = 'Quote_Button_Sync',
    Delete = 'Quote_Button_Delete',
    Confirm = 'Quote_Button_Confirm',
}
