import { ColDef, ICellRendererParams, RowClassParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Button, InputNumber, message, Typography } from 'antd';
import React, { CSSProperties, useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { getCategorizedPositions, updateAccountPosition } from '../../../../service';
import { FormatNumber } from '../../../../utils';
import ClickOutside from '../../../../utils/ClickOutside';
import useForceUpdate from '../../../../utils/Hooks/useForceUpdate';
import { showError } from '../../../RecommendationComponent/util';
import './AdjustAsset.less';

interface UrlParams {
    uid: string;
    customer_id: string;
    name: string;
}

type SecurityPositions = {
    assetClass: string;
    isin: string;
    name: string;
    totalAmount: number;
    accountPositionSummaries: {
        accountClass: number;
        accountId: string;
        accountName: string;
        accountType: string;
        qty: number;
    }[];
}[];

interface CategorizedPositions {
    balance: SecurityPositions;
    balance_undelivered?: SecurityPositions;
    debit: SecurityPositions;
    debit_undelivered?: SecurityPositions;
    security: SecurityPositions;
}

interface Row {
    isin: string;
    type: string;
    name?: string;
    account: string;
    account_id?: string;
    origin?: number;
    quantity: number;
    shouldAlert?: boolean;
    lastOfType?: boolean;
}

export default function AdjustAsset() {
    const { uid, customer_id, name } = useParams<UrlParams>();
    const [rowData, setRowData] = useState<Row[]>([]);
    const { state } = useLocation();

    const forceUpdate = useForceUpdate(() => {
        getCategorizedPositions({ uid: uid }).then((res) => {
            let result: Row[] = [];
            for (const [type, value] of Object.entries(res.data as CategorizedPositions)) {
                if (Array.isArray(value)) {
                    for (const security of value as SecurityPositions) {
                        let shouldAlert = false;
                        let children: Row[] = [];

                        let sec_name = security.name;
                        switch (type) {
                            case 'balance':
                                sec_name += '-现金';
                                break;
                            case 'debit':
                                sec_name += '-贷款';
                                break;
                            case 'balance_undelivered':
                                sec_name += '-现金（在途）';
                                break;
                            case 'debit_undelivered':
                                sec_name += '-贷款（在途）';
                                break;
                            default:
                                break;
                        }

                        if (security.accountPositionSummaries.length >= 2) {
                            let currentOrigin = 0;
                            for (let position of security.accountPositionSummaries) {
                                if (currentOrigin === 0) {
                                    currentOrigin = position.accountClass;
                                } else if (
                                    currentOrigin !== position.accountClass &&
                                    !shouldAlert
                                ) {
                                    shouldAlert = true;
                                }
                                children.push({
                                    isin: security.isin,
                                    type,
                                    account: `${position.accountType}-${position.accountName}`,
                                    account_id: position.accountId,
                                    origin: position.accountClass,
                                    quantity: position.qty,
                                });
                            }
                            result.push({
                                isin: security.isin,
                                type,
                                name: sec_name,
                                account: '总计',
                                quantity: security.totalAmount,
                                shouldAlert: shouldAlert,
                            });
                            for (let child of children) {
                                result.push({
                                    shouldAlert: shouldAlert,
                                    ...child,
                                });
                            }
                            result[result.length - 1].lastOfType = true;
                        } else {
                            let position = security.accountPositionSummaries[0];
                            result.push({
                                isin: security.isin,
                                type,
                                name: sec_name,
                                account: `${position.accountType}-${position.accountName}`,
                                account_id: position.accountId,
                                origin: position.accountClass,
                                quantity: position.qty,
                                shouldAlert: false,
                                lastOfType: true,
                            });
                        }
                    }
                }
            }
            setRowData(result);
        }, showError);
    }, [uid]);

    function QuantityCell(props: { data: Row }) {
        const { data } = props;
        const [showInput, setShowInput] = useState(false);
        const [inputValue, setInputValue] = useState(data.quantity);

        const handleUpdate = () => {
            if (inputValue !== data.quantity) {
                updateAccountPosition({
                    isin: data.isin,
                    type: data.type,
                    account_id: data.account_id ?? '',
                    qty_delta:
                        data.type === 'debit'
                            ? `${data.quantity - inputValue}`
                            : `${inputValue - data.quantity}`,
                })
                    .then(() => {
                        message.success('更新成功');
                        forceUpdate();
                    })
                    .catch((err) => {
                        showError(err);
                        setInputValue(data.quantity);
                    })
                    .finally(() => {
                        setShowInput(false);
                    });
            } else {
                setShowInput(false);
            }
        };

        return (
            <ClickOutside
                className="quantity-content"
                onClickOutside={() => {
                    if (showInput) {
                        handleUpdate();
                    }
                }}
            >
                {showInput ? (
                    <InputNumber
                        defaultValue={data.quantity}
                        className="quantity-input"
                        onChange={(value) => setInputValue(value)}
                        onPressEnter={() => handleUpdate()}
                    />
                ) : data.type === 'balance' || data.type === 'debit' ? (
                    <span>{FormatNumber(data.quantity, 2)}</span>
                ) : (
                    <span>{FormatNumber(data.quantity)}</span>
                )}
                <Button
                    type="primary"
                    className="btn-adjust"
                    onClick={() => {
                        if (showInput) {
                            handleUpdate();
                        } else {
                            setShowInput(true);
                        }
                    }}
                >
                    调整
                </Button>
            </ClickOutside>
        );
    }

    const columnDefs: ColDef[] = [
        {
            field: 'name',
            headerName: '产品',
            width: 400,
        },
        { field: 'account', headerName: '账户', width: 250 },
        {
            field: 'origin',
            headerName: '来源',
            width: 150,
            valueGetter: (params) => {
                if (params.data.origin) {
                    return params.data.origin === 1 ? '人工录入' : '银行同步';
                }
            },
        },
        {
            field: 'quantity',
            headerName: '数量',
            flex: 1,
            minWidth: 260,
            cellClass: 'quantity-cell',
            cellRenderer: (params: ICellRendererParams) => {
                const data = params.data as Row;
                return data.origin === 1 && !data.type.includes('undelivered') ? (
                    <QuantityCell data={data} />
                ) : data.type.includes('balance') || data.type.includes('debit') ? (
                    <span>{FormatNumber(data.quantity, 2)}</span>
                ) : (
                    <span>{FormatNumber(data.quantity)}</span>
                );
            },
        },
    ];

    const history = useHistory();

    const getRowStyle = useCallback<(params: RowClassParams) => CSSProperties>(
        (params) => ({
            background: params.data.shouldAlert ? '#feeae9' : 'white',
            borderBottomStyle: !params.data.lastOfType ? 'none' : 'solid',
            borderColor: '#b9b9b9',
            fontSize: '16px',
        }),
        []
    );

    return (
        <div className="body">
            <div className="header">
                <Typography.Title level={4} className="title">
                    {`${customer_id} - ${name} 持仓调整`}
                </Typography.Title>
                <Button
                    type="primary"
                    className="btn-finish"
                    onClick={() =>
                        message.success('调整成功').then(() =>
                            history.push({
                                pathname: '/app/customer/positionEditor',
                                state: state,
                            })
                        )
                    }
                >
                    返回
                </Button>
            </div>
            <div className="ag-theme-alpine">
                <AgGridReact
                    reactUi
                    rowHeight={52}
                    animateRows
                    rowData={rowData}
                    enableCellTextSelection
                    ensureDomOrder
                    columnDefs={columnDefs}
                    defaultColDef={{ resizable: true }}
                    domLayout="autoHeight"
                    getRowStyle={getRowStyle as any}
                />
            </div>
        </div>
    );
}
