import React, { useEffect, useMemo, useState } from 'react';
import { Col, Divider, Modal, Row, Button } from 'antd';
import styles from './index.module.less';
import TransactionDetailDateInfo from '../components/DetailDateInfo';
import TransactionBriefDetails from '../components/BriefDetails';
import TransactionAccountFlowTable from '../components/AccountFlowTable';
import TransferAccountTable from '../components/TransferAccountTable';
import AssetTable from '../components/AssetTable';
import { useRequest } from 'ahooks';
import { getTransactionDetail } from '../request';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import {
    TransCreateType,
    TransDepositExtInfo,
    TransStatus,
    TransTransferExtInfo,
    TransactionInfo,
    TransactionType,
    TransferType,
} from '../type';
import { FilePdfOutlined, FormOutlined, MailOutlined, CalendarOutlined } from '@ant-design/icons';
import TransForm from '../components/TransForm';
import { formatDate, getTransBirefDetails } from './utils';
import TransferPdfForm from '../components/TransForm/Forms/TransferPdfForm';
import TransferPdfPreview from '../components/TransferPdfPreview';
import UploadTermSheetModalContent from '../components/UploadTermSheetModal';

export interface AssetEntity {
    isin: string;
    assetType: string;
    name: string;
    amount: string;
}
const TranscationDetail = () => {
    const history = useHistory();
    const location = useLocation();
    const [transInfo, setTransInfo] = useState<TransactionInfo>();
    const [showModal, setShowModal] = useState(false);
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [isPreview, setIsPreview] = useState(false);
    const { trans_id } = qs.parse(location.search) as {
        trans_id: string;
    };
    const { data: transDetailData, refresh } = useRequest(getTransactionDetail, {
        defaultParams: [{ trans_id: parseInt(trans_id) }],
    });
    const dateData = useMemo(() => {
        if (transInfo) {
            return [
                { title: 'Trans. Status', value: transInfo.status.desc },
                { title: 'Create Date', value: formatDate(transInfo.created_at) },
                { title: 'Placeable Date', value: formatDate(transInfo.placeable_date) },
                {
                    title: 'Trade Date',
                    value: formatDate(transInfo.trade_date, transInfo.time_zone),
                },
                {
                    title: 'Value Date',
                    value: formatDate(transInfo.value_date, transInfo.time_zone),
                },
                {
                    title: 'Settle Date',
                    value: formatDate(transInfo.settlement_date, transInfo.time_zone),
                },
                { title: 'Verify Date', value: formatDate(transInfo.verify_date) },
            ];
        }
    }, [transInfo]);
    const briefDetailData = useMemo(() => {
        if (transInfo) {
            return getTransBirefDetails(transInfo);
        }
    }, [transInfo]);
    const showActionIcon = useMemo(() => {
        return transInfo?.create_type.code === 1 && transInfo?.status.code !== TransStatus.Deleted;
    }, [transInfo]);
    const showCustodianList = [
        TransactionType['Place Deposit'],
        TransactionType['Deposit Interest'],
        TransactionType['Deposit Maturity'],
        TransactionType['Take Loan'],
        TransactionType['Foreign Exchange'],
        TransactionType['Buy Bond'],
        TransactionType['Sell Bond'],
        TransactionType['Buy Equities'],
        TransactionType['Sell Equities'],
        TransactionType['Buy ELN'],
        TransactionType['Buy AQ'],
        TransactionType['Buy DQ'],
        TransactionType['Buy FCN'],
        TransactionType['Buy Option'],
        TransactionType['Sell Option'],
    ];
    const showUploadTermsheetList = [
        TransactionType['Buy AQ'],
        TransactionType['Buy DQ'],
        TransactionType['Buy FCN'],
    ];
    useEffect(() => {
        if (transDetailData) {
            setTransInfo(transDetailData.data.transaction);
        }
    }, [transDetailData]);
    const onEditClick = () => {
        setShowModal(true);
    };
    const onEmailClick = () => {
        history.push(
            `/app/trade/transaction/email?bank_id=${transInfo?.account.account_type}&trans_id=${trans_id}`
        );
    };
    const onPdfClick = () => {
        setIsPreview(false);
        setShowPdfModal(true);
    };
    const onUploadClick = () => {
        setShowUploadModal(true);
    };
    const showEmailIcon = () => {
        let ret = false;
        let unEmailCode = [
            TransactionType['Fund Contribute'],
            TransactionType['Fund Distribute'],
            TransactionType['Subscribe Fund'],
            TransactionType['Fund Redeem'],
        ];
        if (unEmailCode.includes(transInfo!.type.code)) return false;
        if (transInfo!.status.code === TransStatus.Placeable) {
            if (transInfo!.type.code === TransactionType['Transfer']) {
                const extInfo = transInfo!.ext_info as TransTransferExtInfo;
                if (extInfo.transfer_type_code === TransferType.BetweenSubAccounts) {
                    ret = true;
                }
            } else {
                ret = true;
            }
        }
        return ret;
    };
    const showPdfIcon = () => {
        let ret = false;
        if (transInfo!.type.code === TransactionType['Transfer']) {
            const extInfo = transInfo!.ext_info as TransTransferExtInfo;
            if (
                extInfo.transfer_type_code === TransferType.In ||
                extInfo.transfer_type_code === TransferType.Out
            ) {
                ret = true;
            }
        }
        return ret;
    };
    const showUploadSheetIcon = () => {
        let ret = false;
        if (
            showUploadTermsheetList.includes(transInfo!.type.code) &&
            transInfo!.status.code >= TransStatus.Traded &&
            transInfo!.status.code <= TransStatus.Verified
        ) {
            ret = true;
        }
        return ret;
    };
    const renderTransferArea = () => {
        if (transInfo?.type.code === TransactionType['Transfer']) {
            const {
                in_account_bank,
                in_account_name,
                in_account_no,
                out_account_bank,
                out_account_name,
                out_account_no,
            } = transInfo.ext_info as TransTransferExtInfo;
            const from = {
                accountBank: out_account_bank || '',
                accountName: out_account_name || '',
                accountNo: out_account_no || '',
            };
            const to = {
                accountBank: in_account_bank || '',
                accountName: in_account_name || '',
                accountNo: in_account_no || '',
            };
            return (
                <>
                    <TransferAccountTable from={from} to={to} />
                    <AssetTable data={transInfo.assets} />
                </>
            );
        }
    };
    return (
        <>
            {transInfo && (
                <>
                    <div className={styles.header}>
                        <div className={styles.headerLeft}>
                            <div className={styles.transType}>
                                <span>{transInfo.type.desc}</span>
                                {showActionIcon && (
                                    <>
                                        <span
                                            className={styles.icon}
                                            onClick={() => {
                                                onEditClick();
                                            }}
                                        >
                                            <Button icon={<FormOutlined />} />
                                        </span>
                                        {showEmailIcon() && (
                                            <span
                                                className={styles.icon}
                                                onClick={() => {
                                                    onEmailClick();
                                                }}
                                            >
                                                <Button icon={<MailOutlined />} />
                                            </span>
                                        )}
                                        {showPdfIcon() && (
                                            <span
                                                className={styles.icon}
                                                onClick={() => {
                                                    onPdfClick();
                                                }}
                                            >
                                                <Button icon={<FilePdfOutlined />} />
                                            </span>
                                        )}
                                        {showUploadSheetIcon() && (
                                            <span
                                                className={styles.icon}
                                                onClick={() => {
                                                    onUploadClick();
                                                }}
                                            >
                                                <Button icon={<CalendarOutlined />} />
                                            </span>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className={styles.client}>{transInfo.client.name}</div>
                        </div>
                        {transInfo && (
                            <div className={styles.headerRight}>
                                <TransactionDetailDateInfo data={dateData!} />
                            </div>
                        )}
                    </div>
                    <Divider />
                    <div className={styles.briefDetail}>
                        <div className={styles.detailHeader}>
                            <Row>
                                <Col span={6}>
                                    <div className={styles.type}>
                                        <span>{`${transInfo.create_type.desc} `}</span>
                                        {transInfo.create_type.code === TransCreateType.Event && (
                                            <span className={styles.trigger}>Trigger by</span>
                                        )}
                                    </div>
                                </Col>
                                <Col span={6}>
                                    {transInfo.create_type.code === TransCreateType.Event && (
                                        <span
                                            className="lk_link"
                                            onClick={() => {
                                                if (!transInfo.account || !transInfo.assets) return;
                                                history.push(
                                                    `/app/assetManagement/position/detail/${transInfo.account.account_id}/${transInfo.assets[0].isin}`
                                                );
                                            }}
                                        >
                                            {transInfo.assets?.[0].name}
                                        </span>
                                    )}
                                    {transInfo.create_type.code === TransCreateType.Order &&
                                        transInfo.email_send_date && (
                                            <div className={styles.extra}>{`${formatDate(
                                                transInfo.email_send_date
                                            )} Sent Email`}</div>
                                        )}
                                </Col>
                                <Col span={6} />
                                <Col span={6}>
                                    {showCustodianList.includes(transInfo.type.code) && (
                                        <div className={styles.custodian}>
                                            {`Custodian No. ${
                                                (transInfo.ext_info &&
                                                    (transInfo.ext_info as TransDepositExtInfo)
                                                        .custodian_trade_id) ||
                                                ''
                                            }`}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </div>
                        <TransactionBriefDetails data={briefDetailData!} />
                    </div>
                    {renderTransferArea()}
                    <Divider />
                    {transInfo.flows.length > 0 && (
                        <div className={styles.flow}>
                            <div className={styles.title}>Account Flow</div>
                            <TransactionAccountFlowTable
                                data={transInfo.flows}
                                settlementDate={formatDate(transInfo.settlement_date)}
                            />
                        </div>
                    )}

                    <div>
                        <Modal
                            visible={showModal}
                            footer={null}
                            destroyOnClose
                            onCancel={() => {
                                refresh();
                                setShowModal(false);
                            }}
                            width={1240}
                        >
                            <TransForm
                                trans_id={parseInt(trans_id)}
                                onClose={() => {
                                    refresh();
                                    setShowModal(false);
                                }}
                            />
                        </Modal>
                        <Modal
                            visible={showPdfModal}
                            footer={null}
                            destroyOnClose
                            onCancel={() => {
                                setShowPdfModal(false);
                            }}
                            width={600}
                        >
                            {isPreview ? (
                                <TransferPdfPreview
                                    transId={parseInt(trans_id)}
                                    callback={() => {
                                        setIsPreview(false);
                                    }}
                                />
                            ) : (
                                <TransferPdfForm
                                    transId={parseInt(trans_id)}
                                    callback={() => {
                                        setIsPreview(true);
                                    }}
                                />
                            )}
                        </Modal>
                        <Modal
                            visible={showUploadModal}
                            onCancel={() => {
                                setShowUploadModal(false);
                            }}
                            destroyOnClose
                            title="Setting the Settlement Date"
                            width={720}
                            footer={false}
                        >
                            <UploadTermSheetModalContent
                                transId={parseInt(trans_id)}
                                transInfo={transInfo!}
                                showModal={showUploadModal}
                                setShowModal={setShowUploadModal}
                            />
                        </Modal>
                    </div>
                </>
            )}
        </>
    );
};

export default TranscationDetail;
