import React from 'react';
import styles from './AppActiveUserData.module.scss';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { Select } from 'antd';

interface AppActiveUserData {
    title: string;
    time: string;
    count: number;
    unit: string;
    dateTrigger?: 'button' | 'select';
    period?: number;
    onTrigger?: (num: number) => void;
    children?: React.ReactNode;
}
const AppActiveUserData = (props: AppActiveUserData) => {
    const { title, time, count, unit, period, dateTrigger, onTrigger, children } = props;
    const option = [
        { label: '近7天', value: 7 },
        { label: '近30天', value: 30 },
        { label: '近60天', value: 60 },
    ];
    return (
        <div className={styles.pannel}>
            <div className={styles.titleArea}>
                <div className={styles.name}>{title}</div>
                {dateTrigger === 'select' && (
                    <Select
                        options={option}
                        value={period}
                        onChange={(value) => {
                            onTrigger && onTrigger(value);
                        }}
                    />
                )}
            </div>

            <div className={styles.time}>
                {dateTrigger === 'button' && (
                    <LeftOutlined
                        className={classnames({
                            [styles['icon']]: true,
                            [styles['left']]: true,
                        })}
                        onClick={() => {
                            onTrigger && onTrigger(-1);
                        }}
                    />
                )}
                {time}
                {dateTrigger === 'button' && (
                    <RightOutlined
                        className={classnames({
                            [styles['icon']]: true,
                            [styles['right']]: true,
                        })}
                        onClick={() => {
                            onTrigger && onTrigger(1);
                        }}
                    />
                )}
            </div>
            <div>
                <span className={styles.count}>{count}</span>
                <span className={styles.countUnit}>{unit}</span>
            </div>
            {children}
        </div>
    );
};

export default AppActiveUserData;
