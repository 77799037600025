import { MagicubeTableData } from '../../components/MagicubeTable/type';
import { QuotationParmasFinishType } from '../../components/MagicubeTable/MagicubeTableEdit/TableEditFilterHeader';
import { BaseReqParamPageSize } from '../../service';
import axios from '../../service/tools';

export enum StructuredProductType {
    DQ = 'DQ',
    AQ = 'AQ',
    FCN = 'FCN',
    SDN = 'SDN',
    OPTION = '期权',
}

/**
 * 获取结构化产品参数
 * @param
 * @returns
 */
export const getQuotationAddParmasType = () => {
    return axios.post<QuotationParmasType>('/v4/admin/product/quote/business_info_when_add', {});
};

/**
 * 获取结构化产品参数
 * @param
 * @returns
 */
export const getQuotationParmasType = (data: { product_type: string }) => {
    return axios.post<QuotationResType>('/v4/admin/product/quote/conditions', { data });
};
export type QuotationResType = {
    bank_names: Array<string>;
    company_names: Array<string>;
    update_times: Array<string>;
    product_types?: Array<string>;
};
export type QuotationParmasType = {
    bank_names: Array<string>;
    company_names: Array<string>;
    update_time: string | Array<string>;
    product_types: Array<string>;
};
type QuotationGetTableReq = QuotationParmasType & {
    current_page?: number;
    page_size?: number;
};
export interface QuoteTablesInfo extends BaseReqParamPageSize {
    tables: MagicubeTableData[];
    quoteV4s: QuotationParmasFinishType[];
}
/**
 * 获取结构化产品报价单表格
 * @param
 * @returns
 */
export const getQuoteTables = (data: QuotationGetTableReq) => {
    return axios.post<QuoteTablesInfo>('/v4/admin/product/quote/query', { data });
};

type QuoteAddTableType = QuotationParmasFinishType & {
    table_id?: number;
    id?: number;
};
/**
 * 增加报价单table
 * @param
 * @returns
 */
export const QuoteAddTable = (data: { product_quote_v4: QuoteAddTableType }) => {
    data.product_quote_v4.id = undefined;
    return axios.post('/v4/admin/product/quote/add', { data });
};

/**
 * 删除报价单
 * @param
 * @returns
 */
export const QuoteDeleteTable = (data: { table_id: number }) => {
    return axios.post('/v4/admin/product/quote/delete', { data });
};

export type BondQuoteReq = {
    isin: string;
    bond_type: BondType;
    id?: number;
    credit_rating_sp?: string;
    issuer_cn?: string;
    seniority?: string;
};
export type BondQuoteEntity = {
    id?: number;
    bond_type: BondType;
    isin: string;
    credit_rating_sp: string;
    bond_price_id: number;
    issuer_en: string;
    issuer_cn: string;
    ask_price: number;
    ask_price_plus_accrued_interest: number;
    accrued_interest: number;
    YTW: number;
    YTM: number;
    coupon: string | number;
    duration: number;
    seniority: string;
    next_call_date: string;
    maturity_date: string;
    created_at: number;
    updated_at: number;
    residual_maturity: string;
    snapshot_id: number;
    group_id: string;
    snapshot_index: number;
    nominal_amount: number;
    nominal_amount_currency: string;
    order_type: number;
    limit_price: number;
    is_selected: boolean;
};

export type BondQuoteListReq = {
    current_page?: number;
    page_size?: number;
    credit_ratings?: string[];
    bond_type: BondType;
    residual_maturity_from?: string;
    residual_maturity_to?: string;
    sort_field?: 'coupon' | 'YTM' | 'ask_price';
    sort_order?: 'asc' | 'desc';
};

export type BondQuoteListRes = {
    total_count: number;
    total_pages: number;
    current_page: number;
    page_size: number;
    bond_quotes: BondQuoteEntity[];
    credit_rating_drop_down_list: string[];
    seniority_drop_down_list: string[];
    price_update_ts: number;
};

export enum BondType {
    corporate = 'Corporate Bond',
    government = 'Government Bond',
}

export const addBondQuote = (data: BondQuoteReq) => {
    return axios.post('/v1/admin/product_price/bond/add', { data });
};

export const updateBondQuote = (data: BondQuoteReq) => {
    return axios.post('/v1/admin/product_price/bond/update', { data });
};

export const getBondQuoteList = (data: BondQuoteListReq) => {
    return axios.post<BondQuoteListRes>('v1/admin/product_price/bond/query_list', { data });
};

export const deleteBondQuote = (data: { id: number }) => {
    return axios.post('/v1/admin/product_price/bond/delete', { data });
};
