import { AccountSummary } from '../../service/type';
import { Account, Asset, AssetType, TransactionInfo } from '../Transaction/type';

export enum VerifiedStatus {
    Verified = 2,
    Unverified = 1,
}

export enum InsertType {
    System = 1,
    Manual = 2,
}
export interface ReconciliationRecord {
    id: number;
    account_id: string;
    isin: string;
    bank_id: string;
    asset_type: {
        desc: string;
    };
    currency: string;
    trade_date: number;
    value_date: number;
    statement_date: number;
    presume_amount: number;
    verified_amount: number;
    insert_type: InsertType;
    description: string;
    account: AccountSummary;
    created_at: number;
    logic_status: number;
    updated_at: number;
    system_verify_amount: number;
    bank_position_change: number;
    bank_verify_amount: number;
}
export interface ReconciliationDetail {
    id: number;
    account_id: string;
    status: VerifiedStatus;
    statement_date: number;
    prev_statement_date: number;
    bank_position: number;
    prev_bank_position: number;
    bank_position_change: number;
    bank_verify_amount: number;
    system_verify_amount: number;
    asset: Asset;
    asset_name: string;
    asset_type: {
        code: number;
        desc: string;
    };
    account: Account;
    reconciliation_flows: ReconciliationFlow[];
    currency: string;
    isin: string;
}

export interface ReconciliationRes {
    reconciliations: ReconciliationRecord[];
    total_count: number;
    total_pages: number;
    current_page: number;
    page_size: number;
}

export interface BankFlow {
    id: number;
    account_id: string;
    isin: string;
    bank_id: string;
    asset_type: AssetType;
    currency: string;
    trade_date?: number;
    value_date?: number;
    statement_date?: number;
    presume_amount?: number;
    verified_amount?: number;
    description?: string;
    created_at?: number;
    updated_at?: number;
    insert_type: InsertType;
    flow?: SystemFlow;
}

export interface SystemFlow {
    id: number;
    account_id: string;
    trans_id: number;
    position_type: string;
    isin: string;
    presume_amount: number;
    transaction: TransactionInfo;
    verified_amount: number;
    create_member_id: number;
    created_at: number;
    updated_at: number;
}

export interface ReconciliationFlow {
    id: number;
    bank_flow_id?: number;
    bank_flow: BankFlow;
    flow?: SystemFlow;
}
