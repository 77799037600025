import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import AccountItem from './FormItems/AccountItem';
import {
    AccountInfo,
    Asset,
    GinkgoTransFormRefType,
    TransStatus,
    TransTransferExtInfo,
    TransactionInfo,
    TransactionType,
    TransferType,
    isCashIsin,
} from '../../../type';
import DateItem from './FormItems/DateItem';
import { Divider, Form } from 'antd';
import LKRadioGroup, { LKRadioGroupProps } from '../../../../../components/LKRadioGroup';
import { useForm } from 'antd/lib/form/Form';
import { GinkgoInput } from './FormItems/GinkgoFieldItems';
import TransferInFormItem from './FormItems/TransferInFormItem';
const TransferForm = forwardRef<GinkgoTransFormRefType, TransactionInfo>(
    (data: TransactionInfo, onRef) => {
        const [form] = useForm();
        const [upAccountSelect, setUpAccountSelect] = useState<AccountInfo>();
        const [downAccountSelect, setDownAccountSelect] = useState<AccountInfo>();
        useImperativeHandle(onRef, () => {
            return {
                getFieldsValues: async (validate) => {
                    if (validate === false) {
                        return new Promise((resolve) => {
                            return resolve(configFormValues(form.getFieldsValue()));
                        });
                    } else {
                        return form.validateFields().then((values) => {
                            return configFormValues(values);
                        });
                    }
                },
            };
        });
        const configFormValues = (values: any) => {
            let ext_info = values.ext_info as TransTransferExtInfo;
            if (ext_info.transfer_type_code === TransferType.In) {
                ext_info.in_account_id = upAccountSelect?.account_id;
                ext_info.in_account_name = upAccountSelect?.accout_name;
                ext_info.in_account_bank = upAccountSelect?.account_bank_name;
                ext_info.in_account_no = upAccountSelect?.account_no;
            } else if (ext_info.transfer_type_code === TransferType.Out) {
                ext_info.out_account_id = upAccountSelect?.account_id;
                ext_info.out_account_name = upAccountSelect?.accout_name;
                ext_info.out_account_bank = upAccountSelect?.account_bank_name;
                ext_info.out_account_no = upAccountSelect?.account_no;
            } else {
                ext_info.in_account_id = downAccountSelect?.account_id;
                ext_info.in_account_name = downAccountSelect?.accout_name;
                ext_info.in_account_bank = downAccountSelect?.account_bank_name;
                ext_info.in_account_no = downAccountSelect?.account_no;
                ext_info.out_account_id = upAccountSelect?.account_id;
                ext_info.out_account_name = upAccountSelect?.accout_name;
                ext_info.out_account_bank = upAccountSelect?.account_bank_name;
                ext_info.out_account_no = upAccountSelect?.account_no;
            }
            let assets = values.assets as Asset[];
            let new_assets: any[] = [];
            if (assets && assets.length > 0) {
                new_assets = assets.map((item: Asset) => {
                    return {
                        ...item,
                        asset_type_code:
                            item.asset_type?.code ?? (isCashIsin(item.isin) ? 101001 : undefined),
                    };
                });
            }
            ext_info.transfer_type = undefined;
            return {
                ...values,
                client_id: data.client_id,
                id: data.id,
                status_code: data.status.code,
                transaction_type: TransactionType.Transfer,
                assets: new_assets,
            };
        };
        const [currentTransferType, setCurrentTransferType] = React.useState<
            TransferType | undefined
        >(() => {
            let ext_info = data.ext_info as TransTransferExtInfo;
            if (typeof ext_info.transfer_type === 'number') {
                return ext_info.transfer_type;
            } else {
                return ext_info.transfer_type?.code;
            }
        });
        const [currentScopeType, setCurrentScopeType] = React.useState<TransferType | undefined>(
            () => {
                let ext_info = data.ext_info as TransTransferExtInfo;
                return ext_info.transfer_scope_code;
            }
        );
        const [timezone, setTimezone] = useState(data.time_zone || '');
        useEffect(() => {
            let ext_info = data.ext_info as TransTransferExtInfo;
            let upAccount: AccountInfo | undefined = undefined;
            let downAccount: AccountInfo | undefined = undefined;
            if (ext_info && ext_info.transfer_type_code === TransferType.In) {
                upAccount = {
                    account_id: ext_info.in_account_id,
                    accout_name: ext_info.in_account_name,
                    account_no: ext_info.in_account_no,
                    account_bank_name: ext_info.in_account_bank,
                };
                downAccount = {
                    account_id: ext_info.out_account_id,
                    accout_name: ext_info.out_account_name,
                    account_no: ext_info.out_account_no,
                    account_bank_name: ext_info.out_account_bank,
                };
            } else if (
                ext_info &&
                (ext_info.transfer_type_code === TransferType.Out ||
                    ext_info.transfer_type_code === TransferType.BetweenSubAccounts)
            ) {
                downAccount = {
                    account_id: ext_info.in_account_id,
                    accout_name: ext_info.in_account_name,
                    account_no: ext_info.in_account_no,
                    account_bank_name: ext_info.in_account_bank,
                };
                upAccount = {
                    account_id: ext_info.out_account_id,
                    accout_name: ext_info.out_account_name,
                    account_no: ext_info.out_account_no,
                    account_bank_name: ext_info.out_account_bank,
                };
            }
            if (upAccount?.account_id) {
                setUpAccountSelect(upAccount);
            }
            if (downAccount?.account_id) {
                setDownAccountSelect(downAccount);
            }
            form.setFieldsValue({
                ...data,
                account_up_id: upAccount?.account_id,
                account_down_id: downAccount?.account_id,
            });
        }, [data, form]);
        const transferTypeData: LKRadioGroupProps['datasource'] = [
            {
                label: 'In',
                value: 1,
                width: '12.5%',
            },
            {
                label: 'Out',
                value: 2,
                width: '12.5%',
            },
            {
                label: 'Between SubAccounts',
                value: 3,
                width: '50%',
            },
        ];
        const scopeTypeData: LKRadioGroupProps['datasource'] = [
            {
                label: 'All Asset',
                value: 1,
                width: '25%',
            },
            {
                label: 'Specific',
                value: 2,
                width: '25%',
            },
        ];
        const PlaceableReadOnly = useMemo(() => {
            return data.status?.code !== TransStatus.Created;
        }, [data]);
        const TradedReadOnly = useMemo(() => {
            return data.status?.code === TransStatus.Traded;
        }, [data]);
        const PlaceableRequired = useMemo(() => {
            return data.status?.code === TransStatus.Created;
        }, [data]);
        const TradedRequired = useMemo(() => {
            return data.status?.code === TransStatus.Placeable;
        }, [data]);
        const contentWidget = useMemo(() => {
            switch (currentTransferType) {
                case TransferType.In:
                    return (
                        <>
                            <h1>From Account</h1>
                            <Form.Item
                                label="Account Name"
                                name={['ext_info', 'out_account_name']}
                                rules={[
                                    {
                                        required: PlaceableRequired,
                                        message: 'Account Name is required',
                                    },
                                ]}
                            >
                                <GinkgoInput readOnly={PlaceableReadOnly} />
                            </Form.Item>
                            <Form.Item
                                label="Account Number"
                                name={['ext_info', 'out_account_no']}
                                rules={[
                                    {
                                        required: PlaceableRequired,
                                        message: 'Account Number is required',
                                    },
                                ]}
                            >
                                <GinkgoInput readOnly={PlaceableReadOnly} />
                            </Form.Item>
                            <Form.Item
                                label="Bank Name"
                                name={['ext_info', 'out_account_bank']}
                                rules={[
                                    {
                                        required: PlaceableRequired,
                                        message: 'Bank Name is required',
                                    },
                                ]}
                            >
                                <GinkgoInput readOnly={PlaceableReadOnly} />
                            </Form.Item>
                        </>
                    );
                case TransferType.Out:
                    return (
                        <>
                            <h1>To Account</h1>
                            <Form.Item
                                label="Account Name"
                                name={['ext_info', 'in_account_name']}
                                rules={[
                                    {
                                        required: PlaceableRequired,
                                        message: 'Account Name is required',
                                    },
                                ]}
                            >
                                <GinkgoInput readOnly={PlaceableReadOnly} />
                            </Form.Item>
                            <Form.Item
                                label="Account Number"
                                name={['ext_info', 'in_account_no']}
                                rules={[
                                    {
                                        required: PlaceableRequired,
                                        message: 'Account Number is required',
                                    },
                                ]}
                            >
                                <GinkgoInput readOnly={PlaceableReadOnly} />
                            </Form.Item>
                            <Form.Item
                                label="Bank Name"
                                name={['ext_info', 'in_account_bank']}
                                rules={[
                                    {
                                        required: PlaceableRequired,
                                        message: 'Bank Name is required',
                                    },
                                ]}
                            >
                                <GinkgoInput readOnly={PlaceableReadOnly} />
                            </Form.Item>
                        </>
                    );
                case TransferType.BetweenSubAccounts:
                    return (
                        <>
                            <h1>To Account</h1>
                            <AccountItem
                                clientId={data.client_id}
                                allowAccountName={upAccountSelect?.accout_name}
                                disableAccount={upAccountSelect?.account_id}
                                selectCallback={(bank) => {
                                    setDownAccountSelect({
                                        account_bank_name: bank.bank_name,
                                        account_id: bank.account_id,
                                        account_no: bank.vendor_sub_account_id,
                                        accout_name: bank.account_name,
                                    });
                                }}
                                name={'account_down_id'}
                                readOnlyValue={downAccountSelect?.account_id}
                                readOnly={PlaceableReadOnly}
                                required={PlaceableRequired}
                            />
                        </>
                    );
                default:
                    return <></>;
            }
        }, [
            PlaceableReadOnly,
            PlaceableRequired,
            currentTransferType,
            data.client_id,
            downAccountSelect,
            upAccountSelect,
        ]);
        const assetForm = useMemo(() => {
            switch (currentTransferType) {
                case TransferType.In:
                    return <TransferInFormItem readOnly={TradedReadOnly} />;
                case TransferType.Out:
                    // return (
                    //     <TransferOutFormItem
                    //         account_id={upAccountSelect?.account_id}
                    //         readOnly={TradedReadOnly}
                    //     />
                    // );
                    return <TransferInFormItem readOnly={TradedReadOnly} />;
                case TransferType.BetweenSubAccounts:
                    // return (
                    //     <TransferOutFormItem
                    //         account_id={upAccountSelect?.account_id}
                    //         readOnly={TradedReadOnly}
                    //     />
                    // );
                    return <TransferInFormItem readOnly={TradedReadOnly} />;
                default:
                    return <></>;
            }
        }, [TradedReadOnly, currentTransferType]);
        return (
            <Form labelCol={{ span: 6 }} labelWrap wrapperCol={{ span: 12, offset: 1 }} form={form}>
                <AccountItem
                    clientId={data.client_id}
                    selectCallback={(bank, index, isOnChange) => {
                        if (isOnChange) {
                            form.setFieldsValue({
                                account_down_id: undefined,
                            });
                            setDownAccountSelect(undefined);
                        }
                        setUpAccountSelect({
                            account_bank_name: bank.bank_name,
                            account_id: bank.account_id,
                            account_no: bank.vendor_sub_account_id,
                            accout_name: bank.account_name,
                        });
                        setTimezone(bank.time_zone);
                    }}
                    name={'account_up_id'}
                    readOnlyValue={upAccountSelect?.account_id}
                    required={PlaceableRequired}
                    readOnly={PlaceableReadOnly}
                />
                <Form.Item
                    label="Transfer Type"
                    name={['ext_info', 'transfer_type_code']}
                    rules={[{ required: PlaceableRequired, message: 'Transfer Type is required' }]}
                >
                    <LKRadioGroup
                        datasource={transferTypeData}
                        onChange={(value) => {
                            setCurrentTransferType(value as TransferType);
                            form.setFieldsValue({
                                ext_info: {
                                    transfer_type_code: value,
                                },
                                assets: undefined,
                            });
                        }}
                        readOnly={PlaceableReadOnly}
                    />
                </Form.Item>
                {currentTransferType === TransferType.BetweenSubAccounts && (
                    <Form.Item
                        label="Scope"
                        name={['ext_info', 'transfer_scope_code']}
                        rules={[{ required: PlaceableRequired, message: 'Scope is required' }]}
                    >
                        <LKRadioGroup
                            datasource={scopeTypeData}
                            readOnly={PlaceableReadOnly}
                            onChange={(value) => {
                                setCurrentScopeType(value as number);
                            }}
                        />
                    </Form.Item>
                )}
                <DateItem
                    name="trade_date"
                    label="Trade Date"
                    required={PlaceableRequired || TradedRequired}
                    readOnly={TradedReadOnly}
                    timezone={timezone}
                />
                {currentTransferType !== undefined && (
                    <>
                        <Divider />
                        {contentWidget}
                        {(currentTransferType < 3 ||
                            (currentScopeType === 2 && currentTransferType === 3)) && (
                            <>
                                <h1>Assets</h1>
                                <Form.Item
                                    name={'assets'}
                                    wrapperCol={{ span: 24 }}
                                    rules={[
                                        {
                                            required: true,
                                            validator(rule, value, callback) {
                                                if (
                                                    typeof value === 'object' &&
                                                    value?.length > 0
                                                ) {
                                                    let number = 0;

                                                    value.forEach((item: any) => {
                                                        if (
                                                            item.type === 'cash' ||
                                                            item.type === true ||
                                                            isCashIsin(item.isin)
                                                        ) {
                                                            if (!item.isin) {
                                                                callback('ISIN  are required');
                                                                return;
                                                            }
                                                            if (!item.amount) {
                                                                callback('Amount are required');
                                                                return;
                                                            }
                                                        } else {
                                                            if (!item.isin) {
                                                                callback('ISIN  are required');
                                                                return;
                                                            }
                                                            if (!item.qty) {
                                                                callback('Amount are required');
                                                                return;
                                                            }
                                                            if (!item.name) {
                                                                callback('Name are required');
                                                                return;
                                                            }
                                                        }
                                                        if (item.amount) {
                                                            number += item.amount;
                                                        }
                                                        if (item.qty) {
                                                            number += item.qty;
                                                        }
                                                    });
                                                    if (number === 0) {
                                                        callback('Assets can not be zero');
                                                    }
                                                }
                                                if (value.length === 0) {
                                                    callback('Asset is required');
                                                } else {
                                                    callback();
                                                }
                                            },
                                        },
                                    ]}
                                >
                                    {assetForm}
                                </Form.Item>
                            </>
                        )}
                    </>
                )}
            </Form>
        );
    }
);

export default TransferForm;
