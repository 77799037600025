import ActicleDetail from '@src/modules/SyncArticle/Detail';
import EditAssetsPlan from '../modules/AssetAllocation/EditAssetsPlan';
import AdjustAsset from '../modules/AssetManagement/Assets/AdjustAsset/AdjustAsset';
import EditAssets from '../modules/AssetManagement/Assets/components/EditAssets';
import EditList from '../modules/AssetManagement/Assets/EditList';
import BondQuoteDetail from '../modules/BondQuote/BondQuoteDetail';
import BondQuoteEmail from '../modules/BondQuote/BondQuoteEmail';
import TrackRecordList from '../modules/CustomerList/components/Record/RecordList';
import UserDetail from '../modules/CustomerList/components/UserDetail/UserDetail';
import QuoteDetail from '../modules/GKQuote/QuoteDetail';
import OrderEmail from '../modules/Order/OrderEmail';
import FamilySubAccount from '../modules/Position/FamilySubAccount';
import PositionDetailPage from '../modules/PositionDetail';
import ReconciliationDetail from '../modules/Reconciliation/Detail';
import CustomerProductQuotation from '../modules/StructuralProducts/CustomerQuotationPlatform/CustomerProductQuotation';
import TranscationDetail from '../modules/Transaction/Detail';
import TransactionEmail from '../modules/Transaction/Email';
import BannerDetail from '@src/modules/BannerConfig/Detail';

interface RegularRoute {
    path: string;
    title: string;
    component: () => JSX.Element;
}

/**
 * 普通页面路由，不通过菜单跳转，也不受权限控制
 */
const regularRoutes: RegularRoute[] = [
    {
        path: '/app/customer/positionEditor/adjustAsset/:uid/:customer_id/:name',
        title: '持仓调整',
        component: AdjustAsset,
    },
    {
        path: '/app/trade/BondQuote/detail',
        title: '编辑报价',
        component: BondQuoteDetail,
    },
    {
        path: '/app/trade/quote/detail',
        title: '编辑报价',
        component: QuoteDetail,
    },
    {
        path: '/app/trade/BondQuote/email',
        title: '发送邮件',
        component: BondQuoteEmail,
    },
    {
        path: '/app/customer/list/trackRecord/:id/:tag',
        title: '客户记录',
        component: TrackRecordList,
    },
    {
        path: '/app/customer/positionEditor/editList/:uid/:account_id',
        title: '编辑账户资产',
        component: EditList,
    },
    {
        path: '/app/customer/positionEditor/:type/:uid/:account_id',
        title: '编辑账户资产',
        component: EditAssets,
    },

    {
        path: '/app/assetManagement/assetAllocation/edit/:plan_name/:uid/:copy_id',
        title: '编辑资产配置方案',
        component: EditAssetsPlan,
    },
    {
        path: '/app/customer/list/detail/:id',
        title: '客户列表',
        component: UserDetail,
    },
    {
        path: '/app/structuralProducts/customerQuotationPlatform/:id',
        title: '客户报价平台',
        component: CustomerProductQuotation,
    },
    {
        path: '/app/trade/order/email',
        title: '发送邮件',
        component: OrderEmail,
    },
    {
        path: '/app/trade/transaction/detail',
        title: '交易详情',
        component: TranscationDetail,
    },

    {
        path: '/app/reconciliation/list/detail',
        title: 'Reconciliation Detail',
        component: ReconciliationDetail,
    },
    {
        path: '/app/trade/transaction/email',
        title: '发送邮件',
        component: TransactionEmail,
    },
    {
        path: '/app/assetManagement/position/subAccount/:account_id/:customer_id/:client_name',
        title: '账户详情',
        component: FamilySubAccount,
    },
    {
        path: '/app/assetManagement/position/detail/:account_id/:isin',
        title: '持仓详情',
        component: PositionDetailPage,
    },
    {
        path: '/app/activity/articleSync/detail',
        title: '同步新文章',
        component: ActicleDetail,
    },
    {
        path: '/app/activity/banner/detail',
        title: 'Banner配置',
        component: BannerDetail,
    },
];

export default regularRoutes;
