import React, { ReactNode } from 'react';
import styles from './index.module.less';
import { v4 as uuid } from 'uuid';

interface TransactionBriefDetailsProps {
    data: { title: string; value: string; width?: string; node?: ReactNode }[];
}
const TransactionBriefDetails = (props: TransactionBriefDetailsProps) => {
    const { data } = props;
    return (
        <div className={styles.briefDetails}>
            {data.map((i) => (
                <div
                    key={uuid()}
                    className={styles.briefDetailItem}
                    style={i.width ? { width: i.width } : {}}
                >
                    <div className={styles.title}>{i.title}</div>
                    <div className={styles.value}>{i.node ?? i.value}</div>
                </div>
            ))}
        </div>
    );
};

export default TransactionBriefDetails;
