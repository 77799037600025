import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import React from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import './index.less';

export default function Paginate(props: ReactPaginateProps) {
    return (
        <ReactPaginate
            className="pagination"
            activeClassName="active"
            previousLabel={<LeftOutlined />}
            nextLabel={<RightOutlined />}
            {...props}
        />
    );
}
