import axios from '../tools';
export type ConfirmOverdueReqType = {
    position_id: string;
    date: string;
    confirm: number;
    memo?: string;
};
/**
 * 审核滞纳金
 * @param data
 * @returns
 */
export const auditOverdue = (data: ConfirmOverdueReqType) => {
    return axios.post('/v1/admin/asset/confirm_overdue_payment', {
        data,
    });
};
