import pbAxios from '../../../service/tools';

const OrderTypeMap: Record<string, number> = {
    fx: 2,
    cash: 3,
    loan: 4,
    deposit: 5,
};
// /api
export const deleteReceiptFile = (data: { id: number }) => {
    return pbAxios.post('/v1/admin/delete_termsheet', {
        data,
    });
};
export const deleteNewReceiptFile = (data: { id: number }) => {
    return pbAxios.post('/v1/admin/delete_receipt_file', {
        data,
    });
};

export const deleteSignedFile = (data: { id: number }) => {
    return pbAxios.post('/v1/admin/signed_file/delete', {
        data,
    });
};

export const uploadPdf = (data: {
    file_encode: string;
    matched_order_id?: number;
    order_type: string;
    file_name?: string;
    trans_id?: string;
}) => {
    return pbAxios.post('/v1/admin/upload_receipt_file', {
        data: {
            ...data,
            order_type: OrderTypeMap[data.order_type] ?? 1,
        },
    });
};
export const newUploadPdf = (data: {
    file_encode: string;
    account_id?: string;
    isin: string;
    file_name?: string;
}) => {
    return pbAxios.post('/v1/admin/upload_termsheet', { data });
};

export const singedUploadPdf = (data: { file_encode: string; uid: string; file_name?: string }) => {
    return pbAxios.post('/v1/admin/signed_file/upload', { data });
};
