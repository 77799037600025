import { Button } from 'antd';
import React from 'react';
import './index.less';
const CustomerFilterBtns = ({ click }: { click: (value: string) => void }) => {
    return (
        <div className="CustomerList-hotWords">
            {['目标用户', '开户中', '入金完成', '邀请注册', '已注销'].map((item, index) => (
                <Button
                    type="primary"
                    className="CustomerList-hotWords-button"
                    key={`CustomerList-hotWords-button-${index}`}
                    onClick={() => click(item)}
                >
                    {item}
                </Button>
            ))}
        </div>
    );
};

export default CustomerFilterBtns;
