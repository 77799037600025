import {
    Button,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Radio,
    RadioChangeEvent,
    Row,
    Select,
} from 'antd';
import React, { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { addRealAccount, getBankList } from '../../../../service';
import { BankList } from '../../../../service/type';
import { showError } from '../../../RecommendationComponent/util';
import { AssetItem, getAssetClasses } from '../requests';
const { Option } = Select;

interface FormFields {
    account_type: string;
    account_name: string;
    vendor_account_id: string;
    account_class: number;
    custodian_fee: number;
    asset_class: string[];
}

const AddRealAccount = ({
    setRefreshList,
    userUid,
    close,
    copy_id,
}: {
    setRefreshList: () => void;
    userUid: string;
    close: () => void;
    copy_id: number;
}) => {
    const [bankList, setBankList] = useState<BankList['items']>([]);
    const [assetClasses, setAssetClasses] = useState<AssetItem[]>([]);
    useEffect(() => {
        getBankList().then((res) => setBankList(res.data.items));
        getAssetClasses().then((res) => setAssetClasses(res.data.items), showError);
    }, []);

    const onFinish = useCallback(
        (values: FormFields) => {
            const assetClassFees: Record<string, string> = {};
            for (const assetClass of values.asset_class) {
                assetClassFees[assetClass] = values.custodian_fee.toString();
            }
            addRealAccount({
                uid: userUid,
                copy_id,
                ...values,
                attr_class: {
                    custodian_fee: values.custodian_fee,
                },
                asset_class_attr: {
                    custodian_fee: assetClassFees,
                },
            }).then(() => {
                setRefreshList();
                close();
            }, showError);
        },
        [close, copy_id, setRefreshList, userUid]
    );

    const onSelectBank = useCallback((value) => {
        console.log(value);
    }, []);

    const [accountClass, setAccountClass] = useState<number>(1);
    const handleChangeClass = useCallback((e: RadioChangeEvent) => {
        setAccountClass(e.target.value);
    }, []);

    return (
        <Form
            name="AddRealAccount"
            labelCol={{ span: 6 }}
            labelWrap
            wrapperCol={{ offset: 1, span: 16 }}
            onFinish={onFinish}
        >
            <Form.Item
                initialValue={1}
                label="账户类型"
                name="account_class"
                rules={[{ required: true }]}
            >
                <Radio.Group onChange={handleChangeClass}>
                    <Radio value={1}>人工录入</Radio>
                    <Radio value={2}>银行同步</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                label="银行名"
                name="account_type"
                rules={[{ required: true, message: '请选择银行' }]}
            >
                <Select onChange={onSelectBank}>
                    {bankList.map((item) => (
                        <Option value={item.value} key={item.value}>
                            {item.text}
                        </Option>
                    ))}
                </Select>
            </Form.Item>

            <Form.Item
                label="开户名"
                name="account_name"
                rules={[{ required: true, message: '请输入开户名' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="账户号"
                name="vendor_account_id"
                rules={[{ required: accountClass === 2, message: '请输入账户号' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item initialValue={1} label="账户标签" name="tag" rules={[{ required: true }]}>
                <Radio.Group>
                    <Radio value={1}>常规</Radio>
                    <Radio value={2}>专户</Radio>
                    <Radio value={3}>全委</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="显示名称" name="display_name">
                <Input />
            </Form.Item>
            <Divider style={{ marginTop: '32px' }} />
            <p style={{ textAlign: 'center' }}>账户管理费</p>
            <Form.Item
                label="托管费率"
                name="custodian_fee"
                rules={[{ required: true, message: '请输入托管费率' }]}
            >
                <InputNumber addonAfter="%" min={0} />
            </Form.Item>

            <Form.Item
                label="Fee-waiving asset class"
                name="asset_class"
                rules={[{ required: true, message: '请选择asset class' }]}
            >
                <Checkbox.Group>
                    <Row>
                        {assetClasses.map((assetClass) => (
                            <Col span={12} key={assetClass.value}>
                                <Checkbox value={assetClass.value} style={{ lineHeight: '28px' }}>
                                    {assetClass.text}
                                </Checkbox>
                            </Col>
                        ))}
                    </Row>
                </Checkbox.Group>
            </Form.Item>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button type="default" onClick={() => close()}>
                    取消
                </Button>
                <Button type="primary" htmlType="submit">
                    提交
                </Button>
            </div>
        </Form>
    );
};

export default AddRealAccount;
