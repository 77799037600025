import { Button, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { GinkgoSelect } from './GinkgoFieldItems';
import { Asset, AssetType } from '../../../../type';
import { getAllProductPriceList } from '../../../../../GKProductPrice/request';
import { useRequest } from 'ahooks';
const { Option } = Select;
const SearchNewFund = ({
    readOnly,
    onChange,
}: {
    readOnly?: boolean;
    onChange: (asset?: Asset) => void;
}) => {
    const [searchKey, setSearchKey] = useState<string>('');
    const { data: quoteListData, params: quotationsParams, cancel, run } = useRequest(
        getAllProductPriceList,
        {
            defaultParams: [
                {
                    current_page: 1,
                    page_size: 50,
                    asset_type_code: AssetType.Fund,
                    ext_info: {
                        asset_name: '',
                    },
                },
            ],
        }
    );
    useEffect(() => {
        searchKey &&
            run({
                current_page: 1,
                page_size: 50,
                asset_type_code: AssetType.Fund,
                ext_info: {
                    asset_name: searchKey,
                },
            });
        return () => {
            cancel();
        };
    }, [cancel, run, searchKey]);

    useEffect(() => {
        console.log(quotationsParams);
    }, [quotationsParams]);
    return (
        <>
            <Form.Item label="Fund Name" required={!readOnly}>
                <div style={{ display: 'flex' }}>
                    <Form.Item
                        name={['ext_info', 'asset_name']}
                        rules={[{ required: true, message: 'Fund Name is required' }]}
                        style={{ width: 'calc(62.5% - 10px)' }}
                    >
                        <GinkgoSelect
                            showSearch
                            readOnly={readOnly}
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            notFoundContent={null}
                            placeholder={'Enter to search'}
                            onSearch={(value) => setSearchKey(value)}
                            onSelect={(value: string) => {
                                onChange(
                                    quoteListData?.data?.assets?.find((item) => item.name === value)
                                );
                            }}
                        >
                            {(quoteListData?.data?.assets ?? []).map((asset) => {
                                return (
                                    <Option key={asset.isin} value={asset.name}>
                                        {asset.name}
                                    </Option>
                                );
                            })}
                        </GinkgoSelect>
                    </Form.Item>
                    {readOnly ? null : (
                        <Button
                            style={{ marginLeft: '20px' }}
                            onClick={() => {
                                window.open(`/#/app/trade/quotation#sp-fund`, '_blank');
                            }}
                        >
                            New Fund
                        </Button>
                    )}
                </div>
            </Form.Item>
        </>
    );
};

export default SearchNewFund;
